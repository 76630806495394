import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { LoadingIndicator, ErrorMessage, InfoCard } from '../../../../../common';
import { usePartner } from '../../../partnerContext';
import ReferralItem from './referralItem';

const StyledInfoCard = styled(InfoCard)`
  max-width: unset;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin: 3.9rem 0;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin: 2.8rem 0;
`;

const LoadingErrorContainer = styled.div`
  grid-column: -1/1;
`;

const Container = styled.div`
  display: grid;
  width: 100%;

  ${({ hasOnlyOneReferralItem }) =>
    hasOnlyOneReferralItem
      ? css`
          grid-template-rows: 1fr;
        `
      : css`
          grid-template-rows: max-content min-content max-content;
        `}

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content 1fr;
    grid-column-gap: 2rem;

    ${({ hasOnlyOneReferralItem }) =>
      hasOnlyOneReferralItem &&
      css`
        grid-template-columns: 1fr;
      `}
  }
`;

const Description = styled.p`
  color: ${({ theme }) => theme.dark300};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.429;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    grid-column-start: span 2;
  }
`;

const ReferralCard = () => {
  const { t } = useTranslation();
  const { partnerProgram, partnerProgramLoading, partnerProgramError } = usePartner();

  return partnerProgram?.partnerProgramLines && partnerProgram.partnerProgramLines.length > 0 ? (
    <StyledInfoCard title={t('partner:dashboard.card.referralLink')} withToggle>
      <Container hasOnlyOneReferralItem={partnerProgram?.partnerProgramLines.length === 1}>
        {partnerProgramLoading && !partnerProgram && (
          <LoadingErrorContainer>
            <StyledLoadingIndicator />
          </LoadingErrorContainer>
        )}
        {partnerProgramError && (
          <LoadingErrorContainer>
            <StyledErrorMessage error={partnerProgramError} />
          </LoadingErrorContainer>
        )}
        <Description>{t('partner:dashboard.card.referralDescription')}</Description>
        {partnerProgram?.partnerProgramLines.map((partnerProgramLine) => (
          <ReferralItem
            key={partnerProgramLine.id}
            code={partnerProgramLine.code}
            musicLicenseName={partnerProgramLine.musicLicense.name}
          />
        ))}
      </Container>
    </StyledInfoCard>
  ) : null;
};

export default ReferralCard;
