import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../../../../common';
import { useSpotifyImport } from '../../../spotifyImportContext';

const Container = styled.div`
  display: grid;
  gap: 1.6rem;
  justify-items: start;
`;

const Info = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.dark300};
  line-height: 1.4;
`;

const Bold = styled.span`
  font-weight: 600;
`;

const Description = ({ userName }) => {
  const { t } = useTranslation();
  const { logout } = useSpotifyImport();

  return (
    <Container>
      <Info>
        {t('integrations:spotify.import.accountCredentials')}
        <Bold>{userName}</Bold>.
      </Info>
      <Info>{t('integrations:spotify.import.instructions')}</Info>
      <Button
        highlightColor="grapefruitRed"
        onClick={logout}
        title={t('integrations:spotify.import.logoutButtonLabel')}
      >
        {t('integrations:spotify.import.logoutButtonLabel')}
      </Button>
    </Container>
  );
};

export default Description;
