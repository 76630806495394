const getHistoryCollectionName = (musicHistoryValue, t) => {
  switch (musicHistoryValue.tunifyColor.toLowerCase()) {
    case 'green':
      return `${musicHistoryValue.calendarName ?? ''} - ${musicHistoryValue.categoryName ?? ''}`;
    case 'blue':
      return `${musicHistoryValue.collectionName ?? ''} - ${musicHistoryValue.categoryName ?? ''}`;
    case 'orange':
      if (musicHistoryValue.searchText) {
        return t('musicManagement:musicHistory.searchResult');
      }
      return musicHistoryValue.collectionName ?? '';
    default:
      return musicHistoryValue.collectionName ?? '';
  }
};

export default getHistoryCollectionName;
