import { Navigate, Route, useLocation } from 'react-router';

import { lazy } from 'react';
import { AddonsBuyProvider } from '../../../modules/addons';
import { PurchaseProvider } from '../../../modules/purchase';

const CreateOrganizationPage = lazy(() =>
  import('../../../modules/organizations/create')
);
const EditOrganizationPage = lazy(() =>
  import('../../../modules/organizations/edit')
);
const CreateLocationPage = lazy(() =>
  import('../../../modules/locations/create')
);
const EditLocationPage = lazy(() => import('../../../modules/locations/edit'));
const RenewSubscriptions = lazy(() =>
  import('../../../modules/purchase/renewSubscriptions/renewPurchase')
);
const PurchasePage = lazy(() =>
  import('../../../modules/purchase/purchase/purchasePage')
);
const SaleOrderPage = lazy(() => import('../../../modules/purchase/saleOrder').then((module) => ({
  default: module.SaleOrderPage
})));
const EditZonePage = lazy(() => import('../../../modules/zones/edit'));
const ChangeSubscription = lazy(
  () => import('../../../modules/change').then((module) => ({
    default: module.ChangeSubscription,
  })),
);
const UpgradeSubscription = lazy(() =>
  import('../../../modules/upgrade/upgradeSubscription/upgradeSubscription')
);
const AddonsBuyPage = lazy(() => import('../../../modules/addons/buy/page'));

const StatePreservingNavigate = ({ ...props }) => {
  const location = useLocation();
  return <Navigate {...props} state={location.state} />
};

export const OrganizationRouter = [
  <Route path="create" element={<CreateOrganizationPage />} />,
  <Route path=":organizationId">
    <Route path="edit" element={<EditOrganizationPage />} />
    <Route path="renew-subscriptions" element={<RenewSubscriptions />} />
    <Route path="sale-orders/:saleOrderId" element={<SaleOrderPage />} />
    <Route path="locations">
      <Route path="create" element={<CreateLocationPage />} />
      <Route path=":locationId">
        <Route path="edit" element={<EditLocationPage />} />
        <Route path="zones/:zoneId">
          <Route path="edit" element={<EditZonePage />} />
          <Route path="change" element={<ChangeSubscription />} />
          <Route path="upgrade" element={<UpgradeSubscription />} />
          <Route path="purchase">
            <Route
              path=":currentStep"
              element={
                <PurchaseProvider>
                  <PurchasePage />
                </PurchaseProvider>
              }
            />
            <Route
              path="add-ons/:currentStep"
              element={
                <AddonsBuyProvider>
                  <AddonsBuyPage />
                </AddonsBuyProvider>
              }
            />
            <Route
              path="wire-transfer"
              element={<StatePreservingNavigate to="/payment-feedback/wire-transfer" replace />}
            />
          </Route>
        </Route>
        {/* Deprecated routes */}
        <Route path="renew-subscriptions" element={<RenewSubscriptions />} />
        <Route path="sale-orders/:saleOrderId" element={<SaleOrderPage />} />
      </Route>
    </Route>
  </Route>,
];
