import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 4.4rem;
  background-color: ${({ theme }) => theme.uiBackground};
  border-radius: 8px;
  font-size: 1.4rem;
`;

const TableMessage = ({ children, className }) => {
  return <Container className={className}>{children}</Container>;
};

export default TableMessage;
