import { useMemo, useState } from 'react';

import { useSessionStorage } from '../../../common';
import {
  EXPERIMENTAL_ROLE_PERMISSIONS,
  PARTNER_MANAGER_ROLE_PERMISSIONS,
  SUPPORT_ADMIN_ROLE_PERMISSIONS,
  hasAllRequiredPermissions,
} from '../../../global/auth';
import { useAuth } from '../../../global/auth/newAuthProvider';
import { AddonsOverviewContext } from './addonsOverviewContext';

const AddonsOverviewProvider = ({ children }) => {
  const [selectedAddonsCategory, setSelectedAddonsCategory] = useState({
    id: 'broadcasts',
    i18nKey: 'addons:categories.broadcasts',
    isDisabled: false,
  });
  const { user: userInfo } = useAuth();

  const [impersonationId] = useSessionStorage('impersonationId');
  const isSupportAdmin = hasAllRequiredPermissions(
    userInfo,
    SUPPORT_ADMIN_ROLE_PERMISSIONS
  );
  const isPartnerManager = hasAllRequiredPermissions(
    userInfo,
    PARTNER_MANAGER_ROLE_PERMISSIONS
  );
  const isExperimental = hasAllRequiredPermissions(
    userInfo,
    EXPERIMENTAL_ROLE_PERMISSIONS
  );

  const addonsCategories = useMemo(
    () => [
      {
        id: 'broadcasts',
        i18nKey: 'addons:categories.broadcasts',
        isDisabled: false,
      },
      {
        id: 'musicManagement',
        i18nKey: 'addons:categories.musicManagement',
        isDisabled: !isExperimental,
      },
    ],
    [isExperimental]
  );

  const contextValue = useMemo(
    () => ({
      addonsCategories,
      selectedAddonsCategory,
      setSelectedAddonsCategory,
      canBuyNonePaidAddons:
        (isSupportAdmin || isPartnerManager) && impersonationId,
    }),
    [
      selectedAddonsCategory,
      isSupportAdmin,
      isPartnerManager,
      impersonationId,
      addonsCategories,
    ]
  );

  return (
    <AddonsOverviewContext.Provider value={contextValue}>
      {children}
    </AddonsOverviewContext.Provider>
  );
};

export default AddonsOverviewProvider;
