import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/react-hooks';

import { GET_PARTNER_COMMISSION_INVOICE_PDF } from '../../../api';
import {
  GridTable,
  formatDate,
  downloadBase64String,
  formatNumber,
  Button,
  useMediaSize,
} from '../../../../../common';
import { ReactComponent as RawDownloadIcon } from '../../../../../assets/icons/download.svg';
import theme from '../../../../../global/style/theme';

const RowContainer = styled(GridTable.Row)`
  border-radius: unset;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.stroke};

  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: 'invoiceNumber download' 'amount download';

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    grid-auto-flow: row;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
    grid-template-areas: 'creationDate invoiceNumber amount invoiceDate download';
  }
`;

const DownloadIcon = styled(RawDownloadIcon)`
  width: 2rem;
  height: 2rem;
`;

const DonwloadContainer = styled(GridTable.Value)`
  grid-area: download;
  justify-self: flex-end;
`;

const CreationDate = styled(GridTable.Value)`
  grid-area: creationDate;
  color: ${({ theme }) => theme.dark300};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.143;
`;
const InvoiceNumber = styled(GridTable.Value)`
  grid-area: invoiceNumber;
  color: ${({ theme }) => theme.dark300};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.143;
`;
const CommissionAmount = styled(GridTable.Value)`
  grid-area: amount;
  color: ${({ theme }) => theme.dark500};
  font-family: 'Inter', sans-serif;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.5;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    color: ${({ theme }) => theme.dark300};
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.143;
  }
`;
const InvoiceDate = styled(GridTable.Value)`
  grid-area: invoiceDate;
  color: ${({ theme }) => theme.dark300};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.143;
`;

const CommisionOverviewRow = ({ commissionInvoice }) => {
  const { t, i18n } = useTranslation();
  const [isDownloadSuccess, setIsDownloadSuccess] = useState(false);

  const { isWiderThanMedium } = useMediaSize();

  const [getInvoicePdf, { loading, error }] = useLazyQuery(
    GET_PARTNER_COMMISSION_INVOICE_PDF,
    {
      onCompleted: ({ partnerCommissionInvoicePdf }) => {
        setIsDownloadSuccess(true);
        const fileName = `${commissionInvoice.number}.pdf`.split('/').join('_');
        downloadBase64String(partnerCommissionInvoicePdf, fileName);
      },
      onError: () => {
        setIsDownloadSuccess(false);
      },
      variables: { partnerCommissionInvoiceId: Number(commissionInvoice.id) },
    }
  );

  return (
    <RowContainer key={commissionInvoice.id}>
      {isWiderThanMedium && (
        <CreationDate>
          {formatDate(commissionInvoice.createDate, i18n.language, {
            outputFormat: { day: '2-digit', month: 'long', year: 'numeric' },
          })}
        </CreationDate>
      )}
      <InvoiceNumber>{commissionInvoice.number || ' '}</InvoiceNumber>
      <CommissionAmount>
        € {formatNumber(commissionInvoice.totalCommission)}
      </CommissionAmount>
      {isWiderThanMedium && (
        <InvoiceDate>
          {commissionInvoice.invoiceDate
            ? formatDate(commissionInvoice.invoiceDate, i18n.language, {
                outputFormat: {
                  day: '2-digit',
                  month: 'long',
                  year: 'numeric',
                },
              })
            : t('partner:dashboard.waitOnApproval')}
        </InvoiceDate>
      )}
      <DonwloadContainer>
        <Button
          emphasis="icon"
          size="medium"
          highlightColor="white"
          isDisabled={commissionInvoice.state !== 'posted'}
          isFail={error}
          isLoading={loading}
          isSuccess={isDownloadSuccess}
          loadingIndicatorColor="primary"
          onClick={getInvoicePdf}
          successIndicatorColor={theme.plantGreen}
          title={t('common:button.title.download', {
            value: t('partner:dashboard.commission'),
          })}
          type="button"
        >
          <DownloadIcon color={theme.dark300} />
        </Button>
      </DonwloadContainer>
    </RowContainer>
  );
};

export default CommisionOverviewRow;
