import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useClipboard from 'react-use-clipboard';
import QRCodeStyling from 'qr-code-styling';

import { BasicButton, useMediaSize } from '../../../../../common';
import config from '../../../../../config';
import theme from '../../../../../global/style/theme';
import { ReactComponent as RawDownloadIcon } from '../../../../../assets/icons/download.svg';
import { ReactComponent as RawSuccessIcon } from '../../../../../assets/icons/smallCheckmark.svg';
import { ReactComponent as RawCopyIcon } from '../../../../../assets/icons/copy.svg';

const { BASE_URL } = config;

const Container = styled.div`
  margin: 1rem 0;
`;

const Description = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.dark300};
  line-height: 1.4;
`;

const CodeString = styled(Description)`
  text-align: center;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    text-align: left;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    text-align: center;
  }
`;

const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: min-content min-content;
  gap: 2rem;
  align-items: center;
  padding: 1.4rem 0;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    padding: 0;
    gap: 1rem;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    padding: 1.4rem 0;
    gap: 2rem;
  }
`;

const StyledBasicButton = styled(BasicButton)`
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: 0.8rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    background-color: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.stroke};
    border-radius: 4px;
    padding: 1rem;
    gap: 0;
    min-height: 3.8rem;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    background-color: transparent;
    border: none;
    padding: 0;
    gap: 0.8rem;
    min-height: unset;
  }

  & p {
    border-bottom: 1px solid ${({ theme }) => theme.stroke};
    color: ${({ theme }) => theme.dark400};

    @media only screen and (min-width: ${({ theme }) =>
        theme.breakpoints.medium}px) {
      display: none;
    }

    @media only screen and (min-width: ${({ theme }) =>
        theme.breakpoints.large}px) {
      display: block;
    }
  }
`;

const Box = styled.div`
  background-color: ${({ theme }) => theme.uiBackground};
  border: 1px solid ${({ theme }) => theme.stroke};
  border-radius: 4px;
  padding: 1rem;
  display: grid;
  grid-template-rows: 1fr min-content min-content;
  gap: 1rem;
  justify-items: center;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    grid-template-rows: 1fr;
    grid-template-columns: min-content 1fr min-content;
    align-items: center;
    justify-items: unset;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    grid-template-rows: 1fr min-content min-content;
    grid-template-columns: 1fr;
    justify-items: center;
  }
`;

const Title = styled.p`
  color: ${({ theme }) => theme.dark500};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.143;
  margin: 1.6rem 0;
`;

const QrWrapper = styled.span`
  display: grid;
  align-items: center;
`;

const QRCodeCanvas = ({ link, size = 'normal', qrCode }) => {
  const ref = useRef(null);

  useEffect(() => {
    qrCode.append(ref.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  useEffect(() => {
    qrCode.update({
      data: link,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link]);

  return <QrWrapper ref={ref} />;
};

const ReferralItem = ({ code, musicLicenseName }) => {
  const { t } = useTranslation();

  const link = `${BASE_URL}/onboard/invite?registration-code=${code}`;

  const [isCopied, copyToClipboard] = useClipboard(link, {
    successDuration: 4000,
  });

  const {
    isNarrowerThanMedium,
    isWiderThanLarge,
    isNarrowerThanLarge,
    isWiderThanMedium,
  } = useMediaSize();

  const qrCode = new QRCodeStyling({
    width: 180,
    height: 180,
    backgroundOptions: {
      color: '#fbfbfb',
    },
  });

  const qrCodeMedium = new QRCodeStyling({
    width: 140,
    height: 140,
    backgroundOptions: {
      color: '#fbfbfb',
    },
  });

  const qrCodeSmall = new QRCodeStyling({
    width: 80,
    height: 80,
    backgroundOptions: {
      color: '#fbfbfb',
    },
  });

  const downloadQRCode = useCallback(() => {
    qrCode.download({
      name: 'qr-code',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Title>{musicLicenseName}</Title>
      <Box>
        <div>
          {isWiderThanLarge && (
            <QRCodeCanvas link={link} size="medium" qrCode={qrCodeMedium} />
          )}
          {isNarrowerThanMedium && (
            <QRCodeCanvas link={link} size="normal" qrCode={qrCode} />
          )}
          {isNarrowerThanLarge && isWiderThanMedium && (
            <QRCodeCanvas link={link} size="small" qrCode={qrCodeSmall} />
          )}
        </div>
        <CodeString>{link}</CodeString>
        <ButtonWrapper>
          <StyledBasicButton
            onClick={copyToClipboard}
            title={t('common:button.title.copyLink')}
          >
            {isCopied ? (
              <RawSuccessIcon
                width="1.6rem"
                height="1.1rem"
                color={theme.dark300}
              />
            ) : (
              <RawCopyIcon color={theme.dark300} />
            )}
            <p>{t('common:button.copy')}</p>
          </StyledBasicButton>
          <StyledBasicButton
            onClick={downloadQRCode}
            title={t('common:button.title.download', {
              value: t('glossary:QRCode'),
            })}
          >
            <RawDownloadIcon
              width="1.6rem"
              height="1.6rem"
              color={theme.dark300}
            />
            <p>{t('common:button.download')}</p>
          </StyledBasicButton>
        </ButtonWrapper>
      </Box>
    </Container>
  );
};

export default ReferralItem;
