import {
  isFuture,
  getProductSubscriptionLineOfSubscriptionLines,
  getAddonSubscriptionLinesOfSubscriptionLines,
} from '../../../../common';
import { getEquivalentOfProduct } from './getEquivalentOfProduct';
import { getEquivalentOfAddon } from './getEquivalentOfAddon';

const determineSubCreatePolicyForProductCartItem = (zone) => {
  if (isFuture(zone.lastSubscription.endDate)) {
    return { subCreatePolicy: 'renew', originSubscriptionId: Number(zone.lastSubscription.id) };
  }
  // If the current subscription's end date is in the past, set no subCreate policy.
  // The back-end will interpret this as none existing.
  return { subCreatePolicy: undefined, originSubscriptionId: undefined };
};

const createProductCartItem = (zone, availableProducts, userWantsForever) => {
  const originalProductSubscriptionLine = getProductSubscriptionLineOfSubscriptionLines(
    zone.lastSubscription.subscriptionLines
  );

  const newProduct = getEquivalentOfProduct(
    originalProductSubscriptionLine.product,
    availableProducts,
    userWantsForever
  );

  return {
    locationId: Number(zone.location.id),
    zoneId: Number(zone.id),
    productId: Number(newProduct.id),
    productQuantity: 1,
    ...determineSubCreatePolicyForProductCartItem(zone),
  };
};

const createAddonCartItems = (zone, availableAddons, userWantsForever) => {
  const originalAddonSubscriptionLines = getAddonSubscriptionLinesOfSubscriptionLines(
    zone.lastSubscription.subscriptionLines
  );

  const addonCartItems = originalAddonSubscriptionLines.map((originalAddonSubscriptionLine) => {
    const newAddon = getEquivalentOfAddon(originalAddonSubscriptionLine.product, availableAddons, userWantsForever);

    return {
      locationId: Number(zone.location.id),
      zoneId: Number(zone.id),
      productId: Number(newAddon.id),
      productQuantity: 1,
    };
  });

  return addonCartItems;
};

export const buildCartItems = (
  selectedZones,
  originalZonesWithARenewableSubscription,
  availableProducts,
  availableAddons,
  userWantsForever
) => {
  const cartItems = [];

  selectedZones.forEach((selectedZone) => {
    const originalZone = originalZonesWithARenewableSubscription.find((zone) => zone.id === selectedZone.id);

    const addFor = (forForever) => {
      const productCartItem = createProductCartItem(originalZone, availableProducts, forForever);
      cartItems.push(productCartItem);
      const addonCartItems = createAddonCartItems(originalZone, availableAddons, forForever);
      if (addonCartItems.length > 0) {
        cartItems.push(...addonCartItems);
      }
    }

    if (!userWantsForever || userWantsForever === 'either') {
      addFor(false);
    }
    if (userWantsForever) {
      addFor(true);
    }
  });

  return cartItems;
};
