import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Modal, SelectCard } from '../../../../../common';
import { usePlaylists } from '../../playlistsProvider';

const Instructions = styled.p`
  font-size: 1.4rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.dark400};
`;

const Label = styled.p`
  font-size: 1.6rem;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Filters = styled.div`
  display: grid;
  gap: 0.4rem;
`;

const SongInfoSelectionModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  const { dispatch, lastSongValue, customPlaylists } = usePlaylists();

  const [selectedFilter, setSelectedFilter] = useState('');

  const filterOptions = useMemo(
    () => [
      { id: 'year', name: t('musicManagement:playlists.songInfo.year') },
      { id: 'bpm', name: t('musicManagement:playlists.songInfo.bpm') },
      {
        id: 'genreDescription',
        name: t('musicManagement:playlists.songInfo.genre'),
      },
      { id: 'mood', name: t('musicManagement:playlists.songInfo.mood') },
      {
        id: 'dancingStyle',
        name: t('musicManagement:playlists.songInfo.dancingStyle'),
      },
      {
        id: 'duration',
        name: t('musicManagement:playlists.songInfo.duration'),
      },
    ],
    [t]
  );

  const selectFilter = useCallback(
    (filterId) => {
      const { id } = filterOptions.find((filter) => filter.id === filterId);
      setSelectedFilter(id);
    },
    [filterOptions]
  );

  const confirmFilter = useCallback(() => {
    if (selectedFilter) {
      dispatch({
        lastSongValue: selectedFilter,
      });
      onClose();
    }
  }, [selectedFilter, dispatch, onClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={confirmFilter}
      title={t('musicManagement:playlists.songInfoSelection.title')}
    >
      {customPlaylists && customPlaylists.length > 0 && (
        <Instructions>
          {t('musicManagement:playlists.songInfoSelection.instructions')}
        </Instructions>
      )}
      <Filters>
        {filterOptions.map((filterOption) => (
          <SelectCard
            key={filterOption.id}
            activeSelectCardId={selectedFilter || lastSongValue}
            id={filterOption.id}
            onClick={() => selectFilter(filterOption.id)}
            title={<Label>{filterOption.name}</Label>}
          ></SelectCard>
        ))}
      </Filters>
    </Modal>
  );
};

export default SongInfoSelectionModal;
