import gql from 'graphql-tag';

export const GET_ORGANIZATION_DETAILS = gql`
  query getOrganizationDetails($organizationId: ID!, $locationId: ID!, $zoneId: ID!) {
    organization(id: $organizationId) {
      id
      name
      city
      zip
      streetName
      streetNumber
      vat
      countryId
    }
    location(id: $locationId) {
      id
      name
    }
    zone(id: $zoneId) {
      id
      name
      location {
        id
        name
      }
      currentSubscription {
        id
        endDate
        upsellDiscount {
          discount
        }
        subscriptionLines {
          id
          product {
            id
            isAddon
            shortName
            name
            type
            subscriptionTemplate {
              id
            }
          }
        }
      }
      lastSubscription {
        id
        endDate
        subscriptionLines {
          id
          product {
            id
            name
            isAddon
            shortName
          }
        }
      }
    }
  }
`;

export const GET_ZONES_WITH_AN_ACTIVE_SUBSCRIPTION = gql`
  query getZonesWithAnActiveSubscription {
    zonesWithAnActiveSubscription {
      id
      name
      location {
        id
        name
      }
      currentSubscription {
        id
        endDate
        upsellDiscount {
          discount
        }
        subscriptionLines {
          id
          product {
            id
            shortName
            isAddon
            type
            subscriptionTemplate {
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_AVAILABLE_PAID_ADDONS = gql`
  query getAvailablePaidAddons($organizationId: ID!) {
    availablePaidAddons(organizationId: $organizationId) {
      id
      shortName
      price
      saleOk
      subscriptionTemplate {
        id
        recurringInterval
      }
      productCategory {
        id
        whatIsThisLink
        features
      }
    }
  }
`;

export const GET_AVAILABLE_ADDONS = gql`
  query getAvailableAddons($organizationId: ID!, $productCatalogue: String!) {
    availableAddons(organizationId: $organizationId, productCatalogue: $productCatalogue) {
      id
      shortName
      price
      saleOk
      subscriptionTemplate {
        id
        recurringInterval
      }
      productCategory {
        id
        whatIsThisLink
        features
      }
    }
  }
`;

export const GET_PAYMENT_OPTIONS = gql`
  query getPaymentOptions($productIds: [ID!]!, $countryId: ID!) {
    paymentOptions(productIds: $productIds, countryId: $countryId) {
      id
      name
      image
      pm
      brand
      paymentAcquirerId
      isWireTransfer
    }
  }
`;

export const PREPARE_PAYMENT = gql`
  mutation preparePayment(
    $ingenicoParams: NewPaymentInput!
    $saleOrderId: Int!
    $paymentAcquirerId: Int!
    $paymentIconId: Int!
  ) {
    preparePayment(
      ingenicoParams: $ingenicoParams
      saleOrderId: $saleOrderId
      paymentAcquirerId: $paymentAcquirerId
      paymentIconId: $paymentIconId
    ) {
      id
      reference
      amount
      currency
      wireTransferReference
      iban
      bic
      ingenicoShaIn
      ogonePspid
      ogoneAlias
      ogoneAliasUsage
      ownerCountryCode
    }
  }
`;

export const ADD_ITEMS_TO_CART = gql`
  mutation addItemsToCart($organizationId: ID!, $cartItems: [CartItemInput!]!) {
    addItemsToCart(organizationId: $organizationId, cartItems: $cartItems) {
      id
      amountUntaxed
      amountTax
      amountTotal
      saleOrderLines {
        priceUnit
        cartLineId
        zone {
          id
        }
      }
    }
  }
`;

export const CONFIRM_SALE_ORDER_WITHOUT_PAYMENT = gql`
  mutation confirmSaleOrderWithoutPayment($saleOrderId: ID!) {
    confirmSaleOrderWithoutPayment(saleOrderId: $saleOrderId)
  }
`;
