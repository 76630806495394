import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  getProductSubscriptionLineOfSubscriptionLines,
  getAddonSubscriptionLinesOfSubscriptionLines,
  getTunifyProductColor,
} from '../../../../common';
import { usePurchase } from '../purchaseContext';
import { ReactComponent as AngleDown } from '../../../../assets/icons/angleDown.svg';
import { ReactComponent as AngleUp } from '../../../../assets/icons/angleUp.svg';
import theme from '../../../../global/style/theme';

const Container = styled.div`
  display: none;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    width: 100%;
    background-color: ${({ theme }) => theme.dark500};
    border-radius: 4px;
    padding: 2.4rem;
    display: grid;
    gap: 2rem;
  }
`;

const MobileContainer = styled.div`
  width: calc(100% + 3.2rem);
  padding: 0 1.6rem 1.6rem 1.6rem;
  display: grid;
  gap: 2rem;
  border-top: 1px solid ${({ theme }) => theme.stroke};
  margin: 0 -1.6rem;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    width: calc(100% + 6.4rem);
    padding: 0 3.2rem 1.6rem 3.2rem;
    margin: 0 -3.2rem;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    display: none;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.6rem;
  cursor: pointer;
`;

const Title = styled.h4`
  font-size: 1rem;
  color: ${({ theme }) => theme.dark300};
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.18rem;
`;

const Subtitle = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.white};
  font-weight: 600;
  line-height: 1.4;
`;

const MobileSubtitle = styled(Subtitle)`
  color: ${({ theme }) => theme.dark400};
`;

const Text = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.dark200};
  line-height: 1.4;
`;

const getTextPiece = (text, color) => (
  <span key={text} style={{ color: color ? getTunifyProductColor(color) : undefined }}>
    {text}&nbsp;
  </span>
);

const OrganizationDetails = () => {
  const { t } = useTranslation();
  const [isContentVisible, setContentVisible] = useState(false);
  const { currentLocation, currentZone } = usePurchase();

  const subscription = useMemo(() => {
    if (currentZone?.currentSubscription?.subscriptionLines) {
      const productSubscriptionLine = getProductSubscriptionLineOfSubscriptionLines(
        currentZone.currentSubscription.subscriptionLines
      );
      return productSubscriptionLine.product.name;
    }
    if (currentZone?.lastSubscription?.subscriptionLines) {
      const productSubscriptionLine = getProductSubscriptionLineOfSubscriptionLines(
        currentZone.lastSubscription.subscriptionLines
      );
      return productSubscriptionLine.product.name;
    }
    return '-';
  }, [currentZone]);

  const addons = useMemo(() => {
    if (currentZone?.currentSubscription?.subscriptionLines) {
      const addonSubscriptionLines = getAddonSubscriptionLinesOfSubscriptionLines(
        currentZone.currentSubscription.subscriptionLines
      );
      return addonSubscriptionLines.map((line) => line.product.shortName);
    }
    if (currentZone?.lastSubscription?.subscriptionLines) {
      const addonSubscriptionLines = getAddonSubscriptionLinesOfSubscriptionLines(
        currentZone.lastSubscription.subscriptionLines
      );
      return addonSubscriptionLines.map((line) => line.product.shortName);
    }
    return [];
  }, [currentZone?.lastSubscription, currentZone?.currentSubscription]);

  const subscriptionNameWithStyling = useMemo(
    () =>
      subscription.split(' ').map((piece) => {
        if (piece.toLowerCase().includes('orange')) {
          return getTextPiece(piece, 'orange');
        }
        if (piece.toLowerCase().includes('blue')) {
          return getTextPiece(piece, 'blue');
        }
        if (piece.toLowerCase().includes('green')) {
          return getTextPiece(piece, 'green');
        }
        return getTextPiece(piece);
      }),
    [subscription]
  );

  const toggleContent = useCallback(() => {
    setContentVisible((prev) => !prev);
  }, []);

  return (
    <>
      <Container>
        <Title>{t('subscriptions:currentSubscription')}</Title>
        <div>
          <Subtitle>{currentZone?.name}</Subtitle>
          <Text>{currentLocation?.name}</Text>
        </div>
        <div>
          <Subtitle>{subscriptionNameWithStyling}</Subtitle>
          {addons.map((name, index) => (
            <Text key={index}>{name}</Text>
          ))}
        </div>
      </Container>
      <MobileContainer>
        <Header onClick={toggleContent}>
          <Title>{t('subscriptions:currentSubscription')}</Title>
          {isContentVisible ? (
            <AngleUp width="1.2rem" height="1.2rem" color={theme.dark300} />
          ) : (
            <AngleDown width="1.2rem" height="1.2rem" color={theme.dark300} />
          )}
        </Header>
        {isContentVisible ? (
          <>
            <div>
              <MobileSubtitle>{currentZone?.name}</MobileSubtitle>
              <Text>{currentLocation?.name}</Text>
            </div>
            <div>
              <MobileSubtitle>{subscriptionNameWithStyling}</MobileSubtitle>
              {addons.map((name, index) => (
                <Text key={index}>{name}</Text>
              ))}
            </div>
          </>
        ) : null}
      </MobileContainer>
    </>
  );
};

export default OrganizationDetails;
