import styled from 'styled-components';
import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { set, getMonth, addWeeks } from 'date-fns';

import {
  CheckboxCard,
  formatNumber,
  getTunifyProductColor,
  formatDate,
} from '../../../../../common';
import { usePurchase } from '../../purchaseContext';
import { getEquivalentOfAddon } from '../../utils';

const Options = styled.div`
  display: grid;
  gap: 1.2rem;
`;

const SubLabel = styled.div`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.dark300};
  line-height: 1.4;
`;

const Label = styled.h3`
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & > span {
    color: ${({ theme }) => theme.dark300};
    font-weight: 500;
  }
`;

const SideInfo = styled.span`
  font-size: 1.2rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.dark300};
  padding-top: 0.8rem;

  & > span {
    color: ${({ theme }) => theme.dark500};
    font-weight: 600;
  }
`;

const ValidUntilLabel = styled.p`
  color: ${({ theme }) => theme.dark500};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.143;
  margin: 1rem 0;

  & span {
    color: ${({ theme }) => theme.dark300};
  }
`;

const ZonesWithoutSubscription = ({ zonesWithoutSubscriptions }) => {
  const { t, i18n } = useTranslation();
  const {
    availableAdditionalZones,
    selectedProduct,
    organizationDetails,
    handleSelectAdditionalZone,
    availableAddons,
    userInput: { selectedAdditionalZones, selectedAddons },
  } = usePurchase();

  const handleSelect = useCallback(
    (zoneId) => {
      const additionalZone = availableAdditionalZones.find(
        (az) => az.id === zoneId
      );
      handleSelectAdditionalZone(additionalZone);
    },
    [handleSelectAdditionalZone, availableAdditionalZones]
  );

  const addonsToBuy = useMemo(
    () =>
      selectedAddons.map((addon) =>
        getEquivalentOfAddon(
          addon,
          availableAddons,
          selectedProduct.subscriptionTemplate.id
        )
      ),
    [selectedAddons, availableAddons, selectedProduct]
  );

  const totalPrice = useMemo(
    () =>
      selectedProduct.price +
      addonsToBuy.reduce((acc, curr) => acc + curr.price, 0),
    [addonsToBuy, selectedProduct]
  );

  const periodText = useMemo(() => {
    const { recurringRuleType, recurringInterval } =
      selectedProduct.subscriptionTemplate;

    return recurringRuleType === 'monthly'
      ? t('glossary:monthWithCount', { count: recurringInterval })
      : t('glossary:weekWithCount', { count: recurringInterval });
  }, [t, selectedProduct]);

  const futureExpireDate = useMemo(() => {
    const { recurringRuleType, recurringInterval } =
      selectedProduct.subscriptionTemplate;
    return recurringRuleType === 'monthly'
      ? set(new Date(), { month: getMonth(new Date()) + recurringInterval })
      : addWeeks(new Date(), recurringInterval);
  }, [selectedProduct.subscriptionTemplate]);

  const zonesWithoutSubscription = useMemo(
    () =>
      zonesWithoutSubscriptions.map((zone) => {
        const location = organizationDetails.locations.find(
          (loc) => loc.id === zone.location.id
        );
        return (
          <CheckboxCard
            key={zone.id}
            activeCheckboxCardId={selectedAdditionalZones.map(
              (additionalZone) => additionalZone.id
            )}
            id={zone.id}
            onClick={handleSelect}
            title={
              <Label>
                {location.name} <span>| {zone.name}</span>
              </Label>
            }
          >
            <SubLabel>
              Tunify{' '}
              <span
                style={{
                  color: getTunifyProductColor(
                    selectedProduct.color?.name?.toLowerCase() || ''
                  ),
                }}
              >
                {selectedProduct.color?.name || '-'}
              </span>{' '}
              {periodText}, {selectedProduct.musicLicense.name}
              {selectedAddons.length > 0 &&
                ` + ${addonsToBuy.map((addon) => addon.shortName).join(' + ')}`}
            </SubLabel>
            <ValidUntilLabel>
              <span>{t('subscriptions:thValidUntil')} </span>
              {formatDate(futureExpireDate, i18n.language)}
            </ValidUntilLabel>
            <SideInfo>
              <span>€ {formatNumber(totalPrice, i18n.language)}</span>{' '}
              {t('glossary:exclusiveVat')}
            </SideInfo>
          </CheckboxCard>
        );
      }),
    [
      futureExpireDate,
      organizationDetails.locations,
      selectedAdditionalZones,
      handleSelect,
      selectedProduct.color?.name,
      selectedProduct.musicLicense.name,
      periodText,
      selectedAddons.length,
      addonsToBuy,
      t,
      i18n.language,
      totalPrice,
      zonesWithoutSubscriptions,
    ]
  );

  return <Options>{zonesWithoutSubscription}</Options>;
};

export default ZonesWithoutSubscription;
