import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CheckboxField } from '../fields';
import { NavButton } from '../button';

const TermsAndConditionsContainer = styled.div`
  * > p {
    font-size: 12px;
  }
`;

export const TermsAndConditions = ({ onChange, className }) => {
  const { t } = useTranslation();

  const termsAndConditionsLabel = React.useMemo(() => {
    const translationValue = t('purchase:terms.and.conditions');

    const [, hyperLink, text] =
      /<a\s+[^>]*href\s*=\s*["']([^"']*)["'][^>]*>(.*?)<\/a>/g.exec(
        translationValue
      ) ?? [];

    const [prefix, postfix] = translationValue.split(/<a[^>]*>.*?<\/a>/g);

    return { hyperLink, text, remainderText: { prefix, postfix } };
  }, [t]);

  return (
    <TermsAndConditionsContainer className={className}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <div style={{ paddingBottom: '1rem' }}>
          <CheckboxField onChange={onChange} />
        </div>
        <div style={{ display: 'flex', gap: 4 }}>
          {termsAndConditionsLabel.remainderText.prefix && (
            <p style={{ paddingBottom: '1rem' }}>
              {termsAndConditionsLabel.remainderText.prefix}{' '}
            </p>
          )}
          <p
            style={{ borderBottom: '2px solid #75acff', paddingBottom: '1rem' }}
            onClick={() =>
              termsAndConditionsLabel.hyperLink &&
              window.open(termsAndConditionsLabel.hyperLink, '_blank')
            }
          >
            {termsAndConditionsLabel.text ?? 'Terms and conditions'}
          </p>
          {termsAndConditionsLabel.remainderText.postfix && (
            <p> {termsAndConditionsLabel.remainderText.postfix}</p>
          )}
        </div>
      </div>
    </TermsAndConditionsContainer>
  );
};
