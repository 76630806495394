import styled, { css } from 'styled-components';

import { PieChart } from '../../../common';
import theme from '../../../global/style/theme';

const PieCard = styled.div``;

const PieChartWrapper = styled.div`
  height: 10.4rem;
  width: 10.4rem;
`;

const ChartContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr min-content;
  row-gap: 1.6rem;
`;

const TopWrapper = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  gap: 3rem;
`;

const Grid = styled.div`
  display: grid;
`;

const LengendContainer = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-gap: 1rem;
  grid-column: span 2;
  grid-auto-flow: column;
  justify-content: space-around;
  grid-template-columns: repeat(4, 1fr);
`;

const LegendItem = styled.div`
  display: grid;
  justify-content: center;
`;

const LegendTitleNumber = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 4px;
  display: grid;
  align-items: center;
  justify-content: center;

  ${({ $index, theme }) => {
    if ($index === 0) {
      return css`
        background-color: ${theme.lavenderPurple};
        font-weight: 600;
        font-size: 1.4rem;
        color: ${theme.white};
      `;
    }
    return css`
      font-size: 1.2rem;
      line-height: 1.33;
      color: ${theme.dark200};
    `;
  }}
`;

const LegendTitleLabel = styled.div`
  padding-left: 0.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ $index, theme }) => {
    if ($index === 0) {
      return css`
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 1.5;
        color: ${theme.dark500};
      `;
    }
    return css`
      font-size: 1.2rem;
      line-height: 1.33;
      color: ${theme.dark200};
    `;
  }}
`;

const LegendTitleItem = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
`;

const LegendColor = styled.div`
  background-color: ${({ $color }) => $color};
  height: 0.4rem;
  width: 3.2rem;
  margin-bottom: 1rem;
`;

const LegendPercentage = styled.p`
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.dark500};
`;

const LegendLabel = styled.p`
  font-size: 1.2rem;
  line-height: 1.33;
  color: ${({ theme }) => theme.dark200};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const pieColors = [theme.lavenderPurple, theme.berryBlue, theme.grapefruitRed, theme.babyBlue];

const ReportPieChart = ({ data }) => {
  return (
    <PieCard>
      <ChartContainer>
        <TopWrapper>
          <PieChartWrapper>
            <PieChart data={data} colors={pieColors} />
          </PieChartWrapper>
          <Grid>
            {data.slice(0, -1).map((item, index) => (
              <LegendTitleItem key={item.id + index}>
                <LegendTitleNumber $index={index}>
                  <span>{index + 1}</span>
                </LegendTitleNumber>
                <LegendTitleLabel $index={index}>{item.id}</LegendTitleLabel>
              </LegendTitleItem>
            ))}
          </Grid>
        </TopWrapper>
        <LengendContainer>
          {data.map((item, index) => (
            <LegendItem key={item.id + index}>
              <LegendColor $color={pieColors[index]} />
              <LegendPercentage>{`${Math.round(item.value)}%`}</LegendPercentage>
              <LegendLabel>{item.id}</LegendLabel>
            </LegendItem>
          ))}
        </LengendContainer>
      </ChartContainer>
    </PieCard>
  );
};

export default ReportPieChart;
