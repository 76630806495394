import { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import ReactSlider from 'react-slider';

const Container = styled.div`
  width: 100%;
  height: 6.6rem;
  display: grid;
  align-content: center;
  font-size: 1.2rem;
`;

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 0.9rem;
  margin: 1.8rem 0;
`;

const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  border-radius: ${({ index }) => {
    if (index === 1) {
      return '0';
    }
    return '0.45rem';
  }};
  background: ${({ index, color, theme }) => {
    if (index === 1) {
      return color ?? theme.babyBlue;
    }
    return theme.background;
  }};
`;

const ThumbContainer = styled.div`
  width: 0;
  position: absolute;
  ${({ hasThumbLabels, index }) =>
    hasThumbLabels && index !== 1
      ? css`
          top: -2.45rem;
        `
      : css`
          top: -0.45rem;
        `}
`;

const ThumbWrapper = styled.div`
  position: relative;
`;

const StyledThumb = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  line-height: 1;
  color: ${({ color, theme }) => color ?? theme.dark400};
  cursor: grab;
  white-space: nowrap;
  font-size: 1.4rem;

  ${({ hasThumbLabels }) =>
    hasThumbLabels &&
    css`
      gap: 0.6rem;
    `}

  & span {
    ${({ index }) => {
      if (index === 1) {
        return css`
          padding-left: 1rem;
        `;
      }
      return css`
        padding-right: 1rem;
      `;
    }}

    background: rgb(255, 255, 255);
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 1) 95%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  ${({ firstLeft, lastRight, index }) => {
    if (index === 1) {
      if (lastRight) {
        return css`
          width: 100%;
          align-items: flex-start;
          flex-direction: column-reverse;
        `;
      } else {
        return css`
          width: 100%;
          align-items: flex-end;
          flex-direction: column-reverse;
          left: -100%;
        `;
      }
    }
    if (firstLeft) {
      return css`
        width: 100%;
        align-items: flex-end;
        left: -100%;
      `;
    }
  }}
`;

const Marker = styled.div`
  width: 0.7rem;
  height: 1.8rem;
  border-radius: 0.25rem;
  background-color: ${({ color, theme }) => color ?? theme.waterBlue};
`;

const StyledMark = styled.div`
  width: 0.3rem;
  height: 100%;
  background-color: ${({ theme }) => theme.white};
`;

const LabelContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
`;

const SectionContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ children }) => `repeat(${children.length}, 1fr)`};
  align-items: center;
`;

const Label = styled.span`
  color: ${({ theme }) => theme.dark400};

  ${({ index }) => {
    if (index === 1) {
      return css`
        justify-self: center;
      `;
    }
    if (index === 2) {
      return css`
        justify-self: flex-end;
      `;
    }
  }}
`;

const SectionLabel = styled.span`
  justify-self: center;
  color: ${({ selected, theme }) => (selected ? theme.dark400 : theme.dark400)};
`;

const MinLabel = styled.p`
  justify-self: start;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.dark200};
  margin-top: -0.6rem;
`;

const MaxLabel = styled.p`
  justify-self: end;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.dark200};
  margin-bottom: -0.65rem;
`;

const renderMark = (markProps) => <StyledMark {...markProps} />;

const Slider = ({
  color,
  disabled,
  thumbLabels = [],
  sliderLabels = [],
  sectionLabels = [],
  marks,
  min = 0,
  max = 100,
  ...props
}) => {
  const renderThumb = useCallback(
    (thumbProps, state) => (
      <ThumbContainer
        {...thumbProps}
        hasThumbLabels={thumbLabels.length > 0}
        index={state.index}
      >
        <ThumbWrapper>
          <StyledThumb
            firstLeft={
              thumbLabels[state.index] && min + (max - min) / 2 < props.value[0]
            }
            lastRight={
              thumbLabels[state.index] && max - (max - min) / 2 > props.value[1]
            }
            color={color}
            index={state.index}
            hasThumbLabels={thumbLabels.length > 0}
          >
            <span>{thumbLabels[state.index] ?? ''}</span>
            <Marker color={color} index={state.index} />
          </StyledThumb>
        </ThumbWrapper>
      </ThumbContainer>
    ),
    [color, thumbLabels, min, max, props?.value]
  );

  const renderTrack = useCallback(
    (trackProps, state) => (
      <StyledTrack {...trackProps} color={color} index={state.index} />
    ),
    [color]
  );

  const newMarks = useMemo(
    () =>
      sectionLabels.length > 0
        ? sectionLabels
            .map((_, index) => {
              if (sectionLabels.length - 1 === index) {
                return null;
              }
              const piece = max / sectionLabels.length;
              return piece * (index + 1);
            })
            .filter(Boolean)
        : null,
    [sectionLabels, max]
  );

  return (
    <Container>
      {sliderLabels.length > 0 ? (
        <LabelContainer>
          {sliderLabels.map((label, index) => (
            <Label index={index} key={label}>
              {label}
            </Label>
          ))}
        </LabelContainer>
      ) : null}
      {sectionLabels.length > 0 ? (
        <SectionContainer>
          {sectionLabels.map((label, labelIndex) => {
            const firstValue = props.value[0];
            const diffValues = props.value[1] - props.value[0];
            const selectedArray = Array.from(
              Array(diffValues),
              (_, currentValuePosition) => firstValue + currentValuePosition
            );
            return (
              <SectionLabel
                key={label}
                selected={selectedArray.includes(labelIndex)}
              >
                {label}
              </SectionLabel>
            );
          })}
        </SectionContainer>
      ) : null}
      {thumbLabels.length > 0 ? <MaxLabel>{max}</MaxLabel> : null}
      <StyledSlider
        disabled={disabled}
        max={max}
        min={min}
        renderMark={renderMark}
        renderThumb={renderThumb}
        renderTrack={renderTrack}
        {...props}
        marks={newMarks ?? marks}
      />
      {thumbLabels.length > 0 ? <MinLabel>{min}</MinLabel> : null}
    </Container>
  );
};

export default Slider;
