import { useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { addMonths } from 'date-fns';

import { formatDate, formatNumber, parseDateString, CheckboxField } from '../../../../../common';
import { useSaleOrder } from '../../saleOrderContext';
import { determineSubCreatePolicyForCartItem } from '../../utils';

const ItemContainer = styled.div`
  display: grid;
  gap: 1rem;
  padding-top: 1rem;
`;

const ItemTitle = styled.p`
  font-size: 1.6rem;
  line-height: 1.4;
  font-weight: 600;
  color: ${({ theme }) => theme.white};

  & > span {
    color: ${({ theme }) => theme.dark300};
    font-weight: 500;
  }
`;

const Text = styled.p`
  font-size: 1.4rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.dark100};

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    padding-left: 3.2rem;
  }
`;

const RightText = styled(Text)`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.white};

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    justify-self: flex-end;
    white-space: nowrap;
  }
`;

const OrderRow = ({ saleOrderLine }) => {
  const { t, i18n } = useTranslation();
  const { cartIsChanging, addItemToCart, removeItemFromCart, alteredSaleOrder } = useSaleOrder();

  const { location, zoneWithDetailedSubscriptions, product, priceUnit } = saleOrderLine;

  const { musicLicense, color, subscriptionTemplate, isAddon, shortName } = product;

  const productPrice = useMemo(() => Number(priceUnit).toFixed(2), [priceUnit]);

  const alteredSaleOrderLineEquivalent = useMemo(
    () => alteredSaleOrder.saleOrderLines.find((sol) => sol.zone.id === zoneWithDetailedSubscriptions.id),
    [alteredSaleOrder, zoneWithDetailedSubscriptions]
  );

  // The subscription end date if the user actually buys this new subscription
  const subscriptionEndDate = useMemo(() => {
    const { lastSubscription } = zoneWithDetailedSubscriptions;

    let startDateString;

    if (lastSubscription) {
      startDateString = lastSubscription.endDate;
    } else {
      startDateString = new Date().toISOString();
    }

    const amountOfMonths = saleOrderLine.product.subscriptionTemplate.recurringInterval;

    const date = addMonths(parseDateString(startDateString), amountOfMonths);

    return date;
  }, [zoneWithDetailedSubscriptions, saleOrderLine]);

  const handleCheckBoxChange = useCallback(() => {
    if (alteredSaleOrderLineEquivalent) {
      removeItemFromCart(alteredSaleOrderLineEquivalent.cartLineId);
    } else {
      addItemToCart({
        locationId: Number(location.id),
        zoneId: Number(zoneWithDetailedSubscriptions.id),
        productId: Number(product.id),
        productQuantity: 1,
        ...determineSubCreatePolicyForCartItem(zoneWithDetailedSubscriptions),
      });
    }
  }, [
    alteredSaleOrderLineEquivalent,
    addItemToCart,
    removeItemFromCart,
    location,
    zoneWithDetailedSubscriptions,
    product,
  ]);

  return (
    <ItemContainer>
      <CheckboxField
        disabled={cartIsChanging}
        onChange={handleCheckBoxChange}
        checked={alteredSaleOrderLineEquivalent}
        label={
          <ItemTitle>
            {location.name} <span>| {zoneWithDetailedSubscriptions.name}</span>
          </ItemTitle>
        }
      />
      <div>
        <Text>{musicLicense?.name ?? ''}</Text>
        <Text>{isAddon ? shortName : `Tunify ${color.name}`}</Text>
        <Text>{t('glossary:monthWithCount', { count: subscriptionTemplate.recurringInterval })}</Text>
        <Text>{formatDate(subscriptionEndDate, i18n.language)}</Text>
      </div>
      <RightText>€ {formatNumber(productPrice, i18n.language)}</RightText>
    </ItemContainer>
  );
};

export default OrderRow;
