import { withExperimental } from '../../common/hocs';

const ExperimentalWrapper = ({ experimentalKey, children }) => {
  const InnerExperimentalChildren = () => children;

  InnerExperimentalChildren.EXPERIMENTAL = experimentalKey;

  const EnhancedExperimentalChildren = withExperimental(InnerExperimentalChildren);

  return <EnhancedExperimentalChildren />;
};

export default ExperimentalWrapper;
