import styled, { css } from 'styled-components';
import Switch from 'react-switch';
import theme from '../../../global/style/theme';

const StyledSwitch = styled(Switch)`
  ${({ margin }) => css`
    margin: ${margin};
  `};
`;

const Label = styled.span`
  color: #8798ad;
`;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;

const MainLabel = styled.label`
  color: ${({ theme }) => theme.berryBlue};
`;

const SwitchField = ({
  height = 14,
  width = 34,
  handleDiameter = 10,
  onChange,
  checked,
  checkedLabel,
  unCheckedLabel,
  isDisabled = false,
  className,
  name,
  label,
  margin = '0 0.55rem',
}) => (
  <SwitchContainer className={className}>
    {unCheckedLabel && <Label>{unCheckedLabel}</Label>}
    <StyledSwitch
      checked={checked}
      disabled={isDisabled}
      handleDiameter={handleDiameter}
      height={height}
      margin={margin}
      name={name}
      offColor={theme.dark200}
      onChange={onChange}
      onColor={theme.plantGreen}
      uncheckedIcon={false}
      width={width}
    />
    {checkedLabel && <Label>{checkedLabel}</Label>}
    {label && <MainLabel>{label}</MainLabel>}
  </SwitchContainer>
);

export default SwitchField;
