import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { capitalize } from '..';
import LoadingIndicator from '../loadingIndicator';
import BasicButton from './basicButton';

import buttonTypes from './buttonTypes';
import sizes from './sizes';

const Container = styled(BasicButton)`
  ${({ $size, $emphasis }) => sizes[$emphasis][$size]}
  position: relative;
  z-index: 1;
  transition: 0.2s color ease-in-out, 0.2s background-color ease-in-out;

  ${({ $emphasis }) => buttonTypes[$emphasis]}

  ${({ $shouldCapitalizeText }) =>
    $shouldCapitalizeText &&
    css`
      text-transform: capitalize;
    `}
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  transform: scale(0.8);
`;

const NavButton = ({
  emphasis = 'secondary',
  highlightColor = 'waterBlue',
  highlightGradient,
  size = 'large',
  onClick,
  isLoading = false,
  isDisabled = false,
  type = 'button',
  className,
  children,
  shouldCapitalizeText = false,
  title = '',
  value,
  to,
}) => {
  const memoizedIsDisabled = useMemo(() => isDisabled || isLoading, [isDisabled, isLoading]);

  return (
    <Link to={to}>
      <Container
        $emphasis={emphasis}
        $highlightColor={highlightColor}
        $highlightGradient={highlightGradient}
        $shouldCapitalizeText={shouldCapitalizeText}
        $size={size}
        className={className}
        disabled={memoizedIsDisabled}
        onClick={!isLoading ? onClick : undefined}
        title={capitalize(title)}
        type={type}
        value={value}
      >
        {!isLoading ? (
          <>{typeof children === 'string' ? <p>{children}</p> : children}</>
        ) : (
          <StyledLoadingIndicator size={size} thickness="thin" color="white" />
        )}
      </Container>
    </Link>
  );
};

export default NavButton;
