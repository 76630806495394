import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import styled from 'styled-components';

import { Modal, ErrorMessage } from '../../../../../common';
import { Button } from '../../../../../common/oldButton';
import { ReactComponent as RawAddIcon } from '../../../../../assets/icons/add.svg';
import { ZoneReminderTable } from './reminderTable';
import { updateZonesCacheAfterMutation } from './cache';
import { CHANGE_MUSIC_PROFILE_OF_ZONES } from './api';

const steps = ['musicProfile'];

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;

  & > :not(:first-child) {
    margin-left: 1rem;
  }
`;

const AddIcon = styled(RawAddIcon)`
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 1rem;
  & path {
    fill: ${({ theme }) => theme.white};
  }
`;

const ZoneProfileReminderModal = ({
  onClose,
  isOpen,
  zones,
  loadingZones,
  errorZones,
  errorMusicProfiles,
  musicProfiles,
  loadingMusicProfiles,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(steps[0]);
  const [changedZones, setChangedZones] = useState([]);

  const [
    changeMusicProfileOfZones,
    {
      loading: changeMusicProfileOfZonesLoading,
      error: changeMusicProfileOfZonesError,
    },
  ] = useMutation(CHANGE_MUSIC_PROFILE_OF_ZONES, {
    update: updateZonesCacheAfterMutation,
    onCompleted: () => {
      setChangedZones([]);
      onClose();
    },
  });

  const handleSave = useCallback(async () => {
    const normalizedEntries = changedZones.map((changedZone) =>
      changedZone.musicProfileId === '-1'
        ? { zoneId: changedZone.zoneId, musicProfileId: null }
        : changedZone
    );
    await changeMusicProfileOfZones({
      variables: { entries: normalizedEntries },
    });
  }, [changeMusicProfileOfZones, changedZones]);

  const changeMusicProfileOfZone = useCallback(
    (zoneId, musicProfileId) => {
      const alreadyChangedZone = changedZones.some(
        (changedZone) => changedZone.zoneId === zoneId
      );
      const originalMusicProfile = zones.find(
        (z) => z.id === zoneId
      ).musicProfile;
      const selectedMusicProfileIsTheSame =
        (!originalMusicProfile && musicProfileId === '-1') ||
        originalMusicProfile?.id === musicProfileId;

      if (alreadyChangedZone) {
        if (selectedMusicProfileIsTheSame) {
          setChangedZones((prev) => [
            ...prev.filter((z) => z.zoneId !== zoneId),
          ]);
        } else {
          setChangedZones((prev) => [
            ...prev.filter((z) => z.zoneId !== zoneId),
            { zoneId, musicProfileId },
          ]);
        }
      } else if (!selectedMusicProfileIsTheSame) {
        setChangedZones((prev) => [...prev, { zoneId, musicProfileId }]);
      }
    },
    [zones, changedZones]
  );

  const handleGoNext = useCallback(() => {
    const currentIndex = steps.findIndex((currentStep) => currentStep === step);
    if (step === steps[steps.length - 1]) {
      handleSave();
    } else {
      setStep(steps[currentIndex + 1]);
    }
  }, [step, handleSave]);

  return (
    <Modal
      footer={false}
      isOpen={isOpen}
      onClose={onClose}
      title={t('musicManagement:configureMusicProfile.title')}
    >
      {changeMusicProfileOfZonesError ? (
        <ErrorMessage error={changeMusicProfileOfZonesError} />
      ) : null}
      <p>{t('musicManagement:configureMusicProfile.reminderInfo')}</p>
      <ZoneReminderTable
        changedZones={changedZones}
        changeMusicProfileOfZone={changeMusicProfileOfZone}
        musicProfiles={musicProfiles}
        musicProfilesError={errorMusicProfiles}
        musicProfilesLoading={loadingMusicProfiles}
        zones={zones}
        zonesError={errorZones}
        zonesLoading={loadingZones}
      />
      <Footer>
        <Button
          emphasis="primary"
          onClick={onSubmit}
          title={t('musicManagement:addMusicProfile.title')}
          type="button"
        >
          <AddIcon />
          {t('musicManagement:addMusicProfile.title')}
        </Button>
        <Button
          isDisabled={changedZones.length === 0}
          isLoading={changeMusicProfileOfZonesLoading}
          onClick={handleGoNext}
        >
          {t('common:button.save')}
        </Button>
      </Footer>
    </Modal>
  );
};

export default ZoneProfileReminderModal;
