import { createContext, useContext } from 'react';

const PartnerContext = createContext({
  partner: null,
  loading: true,
  error: null,
});

PartnerContext.displayName = 'PartnerContext';

const usePartner = () => useContext(PartnerContext);

export { PartnerContext, usePartner };
