class Auth0Session {
  setSession(session) {
    const { accessToken, accessTokenExpiresAt, idToken, idTokenExpiresAt } =
      session;
    this._accessToken = accessToken;
    this._accessTokenExpiresAt = accessTokenExpiresAt;
    this._idToken = idToken;
    this._idTokenExpiresAt = idTokenExpiresAt;
  }

  clearSession() {
    this._accessToken = null;
    this._accessTokenExpiresAt = null;
    this._idToken = null;
    this._idTokenExpiresAt = null;
  }

  /**
   * Is the front-end Auth0 session valid, aka the user is logged in and has a valid ID token.
   *
   * @returns {boolean} If the front-end session is valid
   */
  isIdTokenValid() {
    const cutOffTimeStamp = new Date().getTime();
    return cutOffTimeStamp < this._idTokenExpiresAt;
  }

  /**
   * Is the back-end Auth0 session valid, aka the user is logged in and has a valid accesstoken.
   * Adds 60 seconds to the current timestamp to mitigate sync problems between the app and the API.
   *
   * @returns {boolean} If the session is valid
   */
  isAccessTokenValid() {
    const cutOffTimeStamp = new Date().getTime() + 60000;
    return cutOffTimeStamp < this._accessTokenExpiresAt;
  }

  get accessToken() {
    return this._accessToken;
  }

  get idToken() {
    return this._idToken;
  }
}

export default new Auth0Session();
