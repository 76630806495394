import developmentConfig from './development';
import acceptanceConfig from './acceptance';
import migrationConfig from './migration';
import productionConfig from './production';

const { REACT_APP_ENV } = process.env;

const config = () => {
  switch (REACT_APP_ENV) {
    case 'acceptance':
      return acceptanceConfig;
    case 'migration':
      return migrationConfig;
    case 'production':
      return productionConfig;
    default:
      return developmentConfig;
  }
};

export default config();
