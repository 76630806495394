import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as AlertActiveIcon } from '../assets/icons/alertActiveWhite.svg';
import { ReactComponent as CloseIcon } from '../assets/icons/close.svg';
import { ReactComponent as Logo } from '../assets/icons/logoMobile.svg';
import { ReactComponent as MenuIcon } from '../assets/icons/menu.svg';
import { ReactComponent as ProfileIcon } from '../assets/icons/profileWithGradientMobile.svg';
import { ReactComponent as ProfileImpersonationIcon } from '../assets/icons/profileWithGradientMobileImpersonation.svg';
import { BasicButton, useSessionStorage } from '../common';
import {
  SUPPORT_ROLE_PERMISSIONS,
  hasAllRequiredPermissions,
} from '../global/auth';
import { useAuth } from '../global/auth/newAuthProvider';
import theme from '../global/style/theme';
import ShareImpersonationLink from './sideBar/shareImpersonationLink';

const Container = styled.header`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.dark500};
  padding: 0 1.6rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  z-index: 300;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    display: none;
  }
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
`;

const Right = styled.div`
  justify-self: end;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const ProfileLink = styled(Link)`
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.white};
  border-radius: 4px;
`;

const StyledBasicButton = styled(BasicButton)`
  padding: 1rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    display: none;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    display: grid;
  }
`;

const MobileTopBar = ({
  hasNotifications,
  isNotificationsOpen,
  setNotificationsOpen,
  isSideBarDrawerOpen,
  setSideBarDrawerOpen,
}) => {
  const { user: userInfo } = useAuth();

  const [impersonationId] = useSessionStorage('impersonationId');

  const isSupport = hasAllRequiredPermissions(
    userInfo,
    SUPPORT_ROLE_PERMISSIONS
  );

  const toggleNotificationsPopup = useCallback(() => {
    if (!isSideBarDrawerOpen && !isNotificationsOpen) {
      setSideBarDrawerOpen(true);
    }
    setNotificationsOpen((prev) => !prev);
  }, [
    setNotificationsOpen,
    isNotificationsOpen,
    setSideBarDrawerOpen,
    isSideBarDrawerOpen,
  ]);

  const toggleSideBarDrawer = useCallback(() => {
    if (isNotificationsOpen && isSideBarDrawerOpen) {
      setNotificationsOpen(false);
    }
    setSideBarDrawerOpen((prev) => !prev);
  }, [
    setSideBarDrawerOpen,
    setNotificationsOpen,
    isNotificationsOpen,
    isSideBarDrawerOpen,
  ]);

  const closeSideBarDrawer = useCallback(() => {
    setSideBarDrawerOpen(false);
  }, [setSideBarDrawerOpen]);

  return (
    <Container>
      <Left>
        <StyledBasicButton onClick={toggleSideBarDrawer}>
          {isSideBarDrawerOpen ? (
            <CloseIcon width="1.6rem" height="1.6rem" color={theme.white} />
          ) : (
            <MenuIcon width="1.6rem" height="1.6rem" />
          )}
        </StyledBasicButton>
        <Link to="/">
          <Logo width="10rem" height="3.5rem" />
        </Link>
      </Left>
      <Right>
        {isSupport ? <ShareImpersonationLink /> : null}
        {hasNotifications ? (
          <StyledBasicButton onClick={toggleNotificationsPopup}>
            <AlertActiveIcon width="2rem" height="2rem" />
          </StyledBasicButton>
        ) : null}
        <ProfileLink to="/profile" onClick={closeSideBarDrawer}>
          {!!impersonationId ? <ProfileImpersonationIcon /> : <ProfileIcon />}
        </ProfileLink>
      </Right>
    </Container>
  );
};

export default MobileTopBar;
