import { useMemo, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { formatDate, isFuture, Button } from '../../../../../common';
import { ReactComponent as EditIcon } from '../../../../../assets/icons/edit.svg';
import { ReactComponent as ProfileIcon } from '../../../../../assets/icons/profile.svg';
import { ReactComponent as PhoneIcon } from '../../../../../assets/icons/contact/phone.svg';
import theme from '../../../../../global/style/theme';

const Container = styled.div`
  align-items: center;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
    'header header header edit'
    'summary summary summary summary'
    'info info info info'
    'support support support support';
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.stroke};
  background-color: ${({ theme }) => theme.white};
  cursor: pointer;
  padding: 1.6rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    grid-gap: 1rem 3rem;
    grid-template-columns: 1fr 1fr min-content;
    grid-template-areas:
      'header info edit'
      'summary info edit'
      'summary support edit';
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    align-items: flex-start;
    grid-template-columns: 2fr 1fr 1fr min-content;
    grid-template-areas:
      'header info support edit'
      'summary info support edit';
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    align-items: flex-start;
    grid-template-columns: 2fr 1fr 1fr min-content;
    grid-template-areas:
      'header info support edit'
      'summary info support edit';
  }
`;

const EditButton = styled(Button)`
  grid-area: edit;
  justify-self: center;
  align-self: flex-end;
`;

const HeaderContainer = styled.div`
  grid-area: header;
  grid-auto-flow: column;
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-gap: 1rem;
`;

const DateText = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.143;
  color: ${({ theme }) => theme.dark300};

  ${({ isInFuture, theme }) => css`
    color: ${isInFuture ? theme.mintGreen : theme.grapefruitRed};
  `}
`;
const TimeText = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.143;
  color: ${({ theme }) => theme.dark300};

  ${({ isInFuture, theme }) => css`
    color: ${isInFuture ? theme.mintGreen : theme.grapefruitRed};
  `}
`;

const ContentCategory = styled.p`
  color: ${({ theme }) => theme.dark300};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.143;
`;

const Summary = styled.p`
  grid-area: summary;

  color: ${({ theme }) => theme.dark500};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.143;

  padding-bottom: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.stroke};

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    border-bottom: unset;
  }
`;

const InfoContainer = styled.div`
  grid-area: info;
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: min-content 1fr;
  margin: 1rem 0;

  padding-bottom: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.stroke};

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    margin: 0;
    padding-bottom: 0;
    border-bottom: unset;
    /* border-left: 1px solid ${({ theme }) => theme.stroke}; */
    /* padding-left: 1rem; */
  }
`;

const StyledProfileIcon = styled(ProfileIcon)`
  grid-row: span 3;
`;

const ContactName = styled.p`
  color: ${({ theme }) => theme.dark400};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.143;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ContactPhone = styled.p`
  color: ${({ theme }) => theme.dark300};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.143;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ContactMail = styled.p`
  color: ${({ theme }) => theme.dark300};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.143;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SupportContainer = styled.div`
  grid-area: support;
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: min-content 1fr;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    padding-bottom: 0;
    border-bottom: unset;
    /* border-left: 1px solid ${({ theme }) => theme.stroke};
    padding-left: 1rem; */
  }
`;

const ResultCard = ({
  scheduledCall,
  contactCategories,
  openEditContactModal,
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const contactCategory = useMemo(
    () =>
      contactCategories.find(({ id }) => id === scheduledCall.contactCategoryId)
        ?.name,
    [contactCategories, scheduledCall]
  );

  const handleImpersonation = useCallback(() => {
    const { contactPersonId: userId } = scheduledCall;
    navigate(
      `/impersonation/user/${userId}?from=/customer-success/history?contactId=${scheduledCall.id}`
    );
  }, [scheduledCall, navigate]);

  const handleEdit = useCallback(
    (event) => {
      event.stopPropagation();
      openEditContactModal(scheduledCall);
    },
    [openEditContactModal, scheduledCall]
  );

  const { date, time } = useMemo(() => {
    return {
      date: formatDate(scheduledCall.deadlineDate, i18n.language),
      time: formatDate(scheduledCall.deadlineDate, i18n.language, {
        showTime: true,
      }),
    };
  }, [scheduledCall.deadlineDate, i18n]);

  const isInFuture = isFuture(scheduledCall.deadlineDate);

  return (
    <Container onClick={handleImpersonation}>
      <HeaderContainer>
        <DateText isInFuture={isInFuture}>{date}</DateText>
        <TimeText isInFuture={isInFuture}>{time}</TimeText>
        <ContentCategory>{contactCategory}</ContentCategory>
      </HeaderContainer>
      <Summary>{scheduledCall.summary}</Summary>
      <EditButton
        emphasis="icon"
        highlightColor="transparent"
        size="medium"
        onClick={handleEdit}
        title={t('common:button.title.editContact')}
      >
        <EditIcon width="1.6rem" heigth="1.6rem" color={theme.dark300} />
      </EditButton>
      <InfoContainer>
        <StyledProfileIcon width="1.6rem" height="1.6rem" />
        <ContactName
          title={`${scheduledCall.contactPersonFirstName} ${scheduledCall.contactPersonLastName}`}
        >{`${scheduledCall.contactPersonFirstName} ${scheduledCall.contactPersonLastName}`}</ContactName>
        <ContactPhone title={scheduledCall.contactPersonPhone}>
          {scheduledCall.contactPersonPhone}
        </ContactPhone>
        <ContactMail title={scheduledCall.contactPersonEmailAddress}>
          {scheduledCall.contactPersonEmailAddress}
        </ContactMail>
      </InfoContainer>
      <SupportContainer>
        <PhoneIcon width="1.6rem" height="1.6rem" color={theme.dark300} />
        <ContactName>{scheduledCall.agentName ?? '-'}</ContactName>
      </SupportContainer>
    </Container>
  );
};

export default ResultCard;
