import { useState, useEffect, useCallback, useMemo } from 'react';

// Globally track the nodes previously inserted so each is only inserted once
const scriptUrls = {};

const ReactDependentScript = ({
  scripts,
  children,
  loadingComponent,
  errorComponent,
}) => {
  const unloadedScripts = useMemo(
    () => scripts.filter((script) => !scriptUrls[script]),
    [scripts]
  );
  const [loadingCount, setLoadingCount] = useState(unloadedScripts.length);
  const [error, setError] = useState(null);

  const handleError = useCallback((event) => {
    delete scriptUrls[event.target.src];
    setError(true);
  }, []);

  const handleLoad = useCallback(() => {
    setLoadingCount((prev) => prev - 1);
  }, []);

  useEffect(() => {
    unloadedScripts.forEach((script) => {
      const src = script;
      scriptUrls[src] = 1;
      const scriptNode = document.createElement('script');
      scriptNode.type = 'text/javascript';
      scriptNode.src = src;
      scriptNode.addEventListener('load', handleLoad);
      scriptNode.addEventListener('error', handleError);
      document.body.appendChild(scriptNode);
    });
  }, [handleError, handleLoad, scripts, unloadedScripts]);

  if (error) {
    return errorComponent;
  }
  if (loadingCount === 0) {
    return children;
  }
  return loadingComponent;
};

export default ReactDependentScript;
