import { useState, useCallback, useEffect } from 'react';

const useClearError = (error) => {
  const [localError, setLocalError] = useState(error);

  useEffect(() => {
    setLocalError(error);
  }, [error]);

  const clearError = useCallback(() => {
    setLocalError(null);
  }, []);

  return [localError, clearError];
};

export default useClearError;
