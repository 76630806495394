import styled, { css } from 'styled-components';
import { Controller } from 'react-hook-form';

import Label from '../common/label';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InnerContainer = styled.div`
  display: inline-block;
  position: relative;
  display: flex;
  width: fit-content;
  align-items: center;

  ${({ $label }) =>
    $label &&
    css`
      margin-top: 0.6rem;
    `}
`;

const Box = styled.div`
  border: ${({ $inverse }) => ($inverse ? '2px' : '1px')} solid ${({ theme }) => theme.stroke};
  border-radius: ${({ borderRadius }) => `${borderRadius}px`};
  margin: 0 2px;
  width: ${({ boxWidth }) => `${boxWidth}px`};
  height: ${({ boxHeight }) => `${boxHeight}px`};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ fontSize }) => fontSize};
  position: relative;
  color: ${({ theme }) => theme.dark500};
  font-weight: 700;
  background-color: ${({ theme, $inverse }) => ($inverse ? 'transparent' : theme.white)};
`;

const Separator = styled.span`
  width: 1rem;
  height: 0.2rem;
  background-color: ${({ theme }) => theme.stroke};
  margin: 0 0.4rem;
`;

const StringInput = styled.input`
  font-family: 'Inter', sans-serif;
  position: absolute;
  border: none;
  font-size: ${({ fontSize }) => fontSize};
  height: ${({ boxHeight }) => `${boxHeight}px`};
  font-weight: 300;
  text-align: center;
  background-color: transparent;
  outline: none;
  width: ${({ boxWidth }) => `${boxWidth}px`};
  top: 0;
  caret-color: ${({ theme }) => theme.dark500};
  text-align: center;
  left: 0;
  left: ${({ selectedIndex, seperatorStep, boxWidth }) =>
    `${
      selectedIndex * boxWidth +
      (selectedIndex % seperatorStep) * 2 +
      (boxWidth - 5.4) * Math.floor(selectedIndex / seperatorStep)
    }px`};
  opacity: ${({ hideInput }) => (hideInput ? 0 : 1)};

  /* Chrome, Safari, Edge, Opera */
  &-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  display: flex;
  width: fit-content;
  align-items: center;
`;

const VerificationCodeField = ({
  length = 6,
  seperatorStep = 2,
  boxHeight = 48,
  boxWidth = 32,
  fontSize = '1.4rem',
  borderRadius = 4,
  className,
  disabled,
  label,
  required,
  name,
  control,
  inverse,
}) => {
  const CODE_LENGTH = new Array(length).fill(0);

  return (
    <Container className={className}>
      {label ? (
        <Label disabled={disabled} required={required}>
          {label}
        </Label>
      ) : null}
      <InnerContainer $label={label}>
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value, ...restField } }) => {
            const handleChange = (e) => {
              if (value.length < length) {
                onChange(`${value}${e.target.value}`);
              }
            };

            const handleKeyDown = (e) => {
              if (e.keyCode === 8) {
                onChange(`${value.slice(0, -1)}`);
              }
            };

            const values = value.split('');

            const selectedIndex = values.length < CODE_LENGTH.length ? values.length : CODE_LENGTH.length - 1;
            const hideInput = !(values.length < CODE_LENGTH.length);

            return (
              <>
                <StringInput
                  boxHeight={boxHeight}
                  boxWidth={boxWidth}
                  disabled={disabled}
                  fontSize={fontSize}
                  hideInput={hideInput}
                  selectedIndex={selectedIndex}
                  seperatorStep={seperatorStep}
                  value=""
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  {...restField}
                />
                {CODE_LENGTH.map((v, index) => (
                  <Wrapper key={index}>
                    <Box
                      borderRadius={borderRadius}
                      boxHeight={boxHeight}
                      boxWidth={boxWidth}
                      fontSize={fontSize}
                      $inverse={inverse}
                    >
                      {values[index]}
                    </Box>
                    {index % seperatorStep !== 0 && index + 1 !== CODE_LENGTH.length ? <Separator /> : null}
                  </Wrapper>
                ))}
              </>
            );
          }}
        />
      </InnerContainer>
    </Container>
  );
};

export default VerificationCodeField;
