import theme from '../../global/style/theme';

const SIZE = {
  small: '1rem',
  smallMedium: '1.5rem',
  medium: '2rem',
  large: '3rem',
};

const THICKNESS = {
  thin: '2',
  medium: '4',
  thick: '6',
};

const COLOR = {
  neutral: theme.dark500,
  primary: theme.waterBlue,
  white: theme.white,
};

export { SIZE, THICKNESS, COLOR };
