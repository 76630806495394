const { useState, useRef } = require('react');

const useMockMutation = (result, { makeError = false, loadingTime = 2000, onCompleted } = {}) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const mutationCb = useRef(() => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);

      if (makeError) {
        setError({ message: 'Something went wrong' });
      } else {
        onCompleted(result);
      }
    }, loadingTime);
  }, [loadingTime, makeError, result, onCompleted]);

  return [mutationCb.current, { loading, error }];
};

export default useMockMutation;
