import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { NavButton, Button } from '../../../common';

const Bottom = styled.div`
  position: sticky;
  bottom: 0;
  min-height: 7rem;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: end;
  gap: 2rem;
  background-color: ${({ theme }) => theme.white};
  z-index: 1;
  border-top: 1px solid ${({ theme }) => theme.stroke};
  padding: 0 1.6rem;
  max-height: 5rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    padding: 0 5.4rem;
  }
`;

const Footer = ({
  onCancel,
  onCancelDisabled,
  onSave,
  onSaveDisabled,
  isSubmitting,
}) => {
  const { t } = useTranslation();

  return (
    <Bottom>
      <NavButton
        isDisabled={onCancelDisabled}
        onClick={onCancel}
        emphasis="secondary"
        highlightColor="grapefruitRed"
        to="/add-ons"
        title={t('common:button.title.cancel')}
      >
        {t('common:button.cancel')}
      </NavButton>
      <Button
        highlightColor="plantGreen"
        onClick={onSave}
        isLoading={isSubmitting}
        isDisabled={onSaveDisabled}
        title={t('common:button.title.save')}
        type="submit"
      >
        {t('common:button.save')}
      </Button>
    </Bottom>
  );
};

export default Footer;
