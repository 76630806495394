import { useCallback } from 'react';
import styled, { css } from 'styled-components';

const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.8rem;
  user-select: none;
  border: solid 2px ${({ theme }) => theme.stroke};
  border-radius: 4px;
  font-size: 1.6rem;
  background: none;
  transition: border-color 0.2s ease-in-out;
  color: ${({ theme }) => theme.dark500};
  outline: none;
  cursor: pointer;
  background: ${({ theme }) =>
    `linear-gradient(0deg, ${theme.gradient['fade'][0]} 0%,  ${theme.gradient['fade'][1]} 100%)`};

  ${({ isActive }) => {
    if (isActive) {
      return css`
        font-weight: 600;
        border-color: ${({ theme }) => theme.waterBlue};
      `;
    }
  }}

  &:active,
  &:focus,
  &:hover {
    ${({ isActive }) => {
      if (!isActive) {
        return css`
          border-color: ${({ theme }) => theme.dark200};
        `;
      }
    }}
  }
`;
const SelectButton = ({ id, isActive, onClick, children, className }) => {
  const handleOnClick = useCallback(() => {
    onClick(id);
  }, [id, onClick]);

  return (
    <Container
      className={className}
      isActive={isActive}
      onClick={handleOnClick}
      type="button"
    >
      {children}
    </Container>
  );
};

export default SelectButton;
