import { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';

import theme from '../../../global/style/theme';
import { Button } from '../../../common';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as AngleDown } from '../../../assets/icons/angleDown.svg';
import { ReactComponent as AngleUp } from '../../../assets/icons/angleUp.svg';
import { ReactComponent as LocationLarge } from '../../../assets/icons/locationLarge.svg';

const Container = styled.div`
  background-color: ${({ theme }) => theme.white};
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.stroke};
  padding: 1.2rem;
  margin: 1.4rem 0;
  display: grid;
  grid-gap: 1rem;
  background-color: ${({ theme }) => theme.white};
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;

  ${({ $isOpen, $darkMode }) =>
    $isOpen &&
    $darkMode &&
    css`
      background-color: ${({ theme }) => theme.dark500};
      border-color: ${({ theme }) => theme.dark500};
    `}

  ${({ $isOpen, $darkMode }) =>
    $isOpen &&
    !$darkMode &&
    css`
      border-width: 2px;
      border-color: ${({ theme }) => theme.waterBlue};
    `}
`;

const Name = styled.p`
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.4;
  color: ${({ theme }) => theme.dark500};
  max-width: 25rem;
  transition: color 0.2s ease-in-out;

  ${({ $isOpen, $darkMode }) =>
    $isOpen &&
    $darkMode &&
    css`
      color: ${({ theme }) => theme.white};
    `}

  @media only screen and (min-width: ${({ theme }) =>
    theme.breakpoints.medium}px) {
    max-width: unset;
    padding-left: 0.6rem;
  }
`;

const Address = styled.p`
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.5;
  color: ${({ theme }) => theme.dark300};
  margin-top: 0.4rem;
  transition: color 0.2s ease-in-out;

  ${({ $isOpen, $darkMode }) =>
    $isOpen &&
    $darkMode &&
    css`
      color: ${({ theme }) => theme.dark200};
    `}

  @media only screen and (min-width: ${({ theme }) =>
    theme.breakpoints.medium}px) {
    padding-left: 0.6rem;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr min-content;
  grid-auto-flow: column;
  align-items: center;
  cursor: pointer;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    grid-template-columns: min-content 1fr min-content;
    padding: 0.8rem 1.4rem;
  }
`;

const LogoWrapper = styled.div`
  align-self: center;
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  padding: 2rem;
  display: grid;
  justify-content: center;
  align-content: center;
  cursor: pointer;

  ${({ theme, isOpen }) =>
    isOpen
      ? css`
          border: 2px solid ${theme.waterBlue};
        `
      : css`
          border: 1px solid ${theme.stroke};
        `};
`;

const TitleContainer = styled.div`
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    grid-template-columns: max-content 1fr;
    grid-gap: 1rem;
  }
`;

const StyledLocationLarge = styled(LocationLarge)`
  display: none;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    display: flex;
  }
`;

const ButtonWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 0.4rem;
`;

const LocationCard = ({
  location,
  organization,
  isOpen,
  toggleLocationCard,
  hasEditButton = true,
  darkMode = false,
  children,
}) => {
  const navigate = useNavigate();

  const handleEditLocation = useCallback(
    (event) => {
      event.stopPropagation();
      navigate(
        `/organizations/${
          organization?.id ?? location.organization.id
        }/locations/${location.id}/edit`
      );
    },
    [navigate, location, organization]
  );

  return (
    <Container $isOpen={isOpen} $darkMode={darkMode}>
      <Grid onClick={toggleLocationCard}>
        <StyledLocationLarge width="3rem" height="3rem" />
        <TitleContainer>
          <Name $isOpen={isOpen} $darkMode={darkMode}>
            {location.name}
          </Name>
          <Address
            $isOpen={isOpen}
            $darkMode={darkMode}
          >{`${location.streetName} ${location.streetNumber}, ${location.zip} ${location.city}`}</Address>
        </TitleContainer>
        <ButtonWrapper>
          {hasEditButton && (
            <Button
              emphasis="icon"
              highlightColor="transparent"
              onClick={handleEditLocation}
            >
              <EditIcon
                width="1.6rem"
                height="1.6rem"
                color={isOpen && darkMode ? theme.white : theme.dark300}
              />
            </Button>
          )}
          <LogoWrapper isOpen={isOpen}>
            {isOpen ? (
              <AngleUp
                width="1.2rem"
                height="1.2rem"
                color={isOpen && darkMode ? theme.white : theme.dark200}
              />
            ) : (
              <AngleDown
                width="1.2rem"
                height="1.2rem"
                color={isOpen && darkMode ? theme.white : theme.dark200}
              />
            )}
          </LogoWrapper>
        </ButtonWrapper>
      </Grid>
      {isOpen && children}
    </Container>
  );
};

export default LocationCard;
