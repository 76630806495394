import gql from 'graphql-tag';

export const GET_SUBSCRIPTIONS_FOR_ORGANIZATION_AND_ORGANIZATIONS = gql`
  query getSubscriptionsForOrganizationAndOrganizations($withOrganization: Boolean!, $organizationId: ID!) {
    organizations {
      id
      name
    }
    allProducts {
      id
      isForever
      shortName
    }
    organization(id: $organizationId) @include(if: $withOrganization) {
      ...organizationDetails
    }
    paginatedOrganizations: organizations(limit: 1) @skip(if: $withOrganization) {
      ...organizationDetails
    }
  }

  fragment organizationDetails on Organization {
    id
    name
    vat
    streetName
    streetNumber
    zip
    city
    countryId
    locations {
      id
      name
      streetName
      streetNumber
      zip
      city
    }
    zones {
      id
      name
      locationId
      currentSubscription {
        id
        subscriptionLines {
          id
          product {
            id
            isAddon
            shortName
            name
          }
        }
      }
      lastSubscription {
        id
        endDate
        hasForeverRenewalOn
        subscriptionLines {
          id
          product {
            id
            isAddon
            shortName
            name
          }
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_ID_BY_ZONE_ID = gql`
  query getOrganizationIdByZoneId($zoneId: ID!) {
    organizationIdByZoneId(zoneId: $zoneId)
  }
`;

export const GET_PLAYER_LINK = gql`
  query getPlayerLink($zoneId: ID!) {
    playerLink(zoneId: $zoneId)
  }
`;

export const CREATE_CODE = gql`
  mutation createCode($zoneId: ID!) {
    createCode(zoneId: $zoneId) {
      id
      code
    }
  }
`;

export const GET_LAST_SEEN_PLAYER_DATES = gql`
  query getLastSeenPlayerDates($zoneIds: [ID!]!) {
    lastSeenPlayerDates(zoneIds: $zoneIds) {
      players {
        id
        timestamp
      }
    }
  }
`;

export const GET_USER_IDS_FROM_EXTERNAL_ZONE_IDS = gql`
  query getUserIdsFromExternalZoneIds($zoneIds: [ID!]!) {
    userIdsFromExternalZoneIds(zoneIds: $zoneIds) {
      externalZoneId
      zoneId
    }
  }
`;

export const GET_LAST_PLAYED_SONGS = gql`
  query getMusicLastPlayedSongs($zoneIds: [ID!]!) {
    musicLastPlayedSongs(zoneIds: $zoneIds) {
      lastPlayedSongs {
        id
        songTitle
        songArtist
        tunifyColor
        collectionName
        calendarName
        categoryName
        searchText
      }
    }
  }
`;
