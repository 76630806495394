import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Modal } from '../../../../../../common';
import { Button } from '../../../../../../common/oldButton';
import { Slider } from '../../../../shared';

const Description = styled.p``;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;

  & > :not(:first-child) {
    margin-left: 1rem;
  }

  margin-top: 1rem;
`;

const CollectionSettingsModal = ({ isOpen, onClose, title }) => {
  const { t } = useTranslation();

  return (
    <Modal footer={false} isOpen={isOpen} onClose={onClose} title={title}>
      <Description>
        {t('musicManagement:tunifyBlue.collectionSettings.info', {
          collectionName: title,
        })}
      </Description>
      {/* Those are template sliders. They will be updated or removed when data implementation is possible */}
      <Slider
        defaultValue={[10, 90]}
        max={100}
        thumbLabels={['Nice', 'Super Nice']}
      />
      <Slider
        defaultValue={[0, 2]}
        max={3}
        sectionLabels={['Gezellig', 'Dynamisch', 'Rustig']}
      />
      <Slider
        defaultValue={[1, 3]}
        marks={[1, 2, 3]}
        max={4}
        sliderLabels={['-', 'Tempo', '+']}
      />
      <Footer>
        <Button
          emphasis="secondary"
          flavor="link"
          onClick={onClose}
          type="button"
        >
          {t('common:button.cancel')}
        </Button>
        <Button
          emphasis="secondary"
          flavor="flat"
          title={t('common:button.title.save')}
          type="submit"
        >
          {t('common:button.save')}
        </Button>
      </Footer>
    </Modal>
  );
};

export default CollectionSettingsModal;
