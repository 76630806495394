import { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ReactComponent as WarningIcon } from '../../assets/icons/warning.svg';
import { extractErrorCodeFromApolloError } from '..';
import { Button } from '../button';

const StyledWarningIcon = styled(WarningIcon)`
  min-width: 1.5rem;
  min-height: 1.5rem;
`;

const Wrapper = styled.div`
  ${({ withPadding }) =>
    withPadding
      ? css`
          padding: 0 1.5rem;

          @media only screen and (min-width: ${({ theme }) =>
              theme.breakpoints.medium}px) {
            padding: 0 3rem;
          }
          @media only screen and (min-width: ${({ theme }) =>
              theme.breakpoints.large}px) {
            padding: 0 4rem;
          }
          @media only screen and (min-width: ${({ theme }) =>
              theme.breakpoints.extraLarge}px) {
            padding: 0 4.8rem;
          }
        `
      : ''}
`;

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  gap: 1.6rem;
  background-color: ${({ theme }) => theme.grapefruitRed};
  border-radius: 8px;
  padding: 1.5rem;

  & > p {
    color: ${({ theme }) => theme.white};
    font-family: 'Inter', sans-serif;
    font-size: 1.4rem;
    line-height: 1.4;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    grid-template-columns: min-content 1fr min-content;
  }
`;

const StyledButton = styled(Button)`
  white-space: nowrap;
  color: ${({ theme }) => theme.white};
  border-color: ${({ theme }) => theme.white};
  margin-bottom: -0.4rem;

  &:hover {
    color: ${({ theme }) => theme.white};
  }
`;

const ButtonWrapper = styled.div`
  grid-column-start: 2;
  width: fit-content;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    grid-column-start: unset;
    width: unset;
  }
`;

const ErrorMessage = ({
  error,
  className,
  withButton = false,
  withPadding = false,
  buttonText,
  onClick,
}) => {
  const { t } = useTranslation();

  const message = useMemo(() => {
    if (typeof error === 'string') {
      return error;
    }
    const graphQLErrorCode = extractErrorCodeFromApolloError(error);
    if (graphQLErrorCode) {
      return t(`error:${graphQLErrorCode}`);
    }
    // Custom error in withMapsScript HOC
    if (error.name === 'LOAD_SCRIPT_ERROR') {
      return t('error:LOAD_SCRIPT_ERROR');
    }
    // Custom error in ErrorBoundary
    if (error.name === 'RENDER_ERROR') {
      return t('error:RENDER_ERROR');
    }
    // Custom error in auth module / registration page
    if (error.name === 'GET_INDUSTRIES_ERROR') {
      return t('error:GET_INDUSTRIES_ERROR');
    }
    // Custom error in spotify module
    if (error.name === 'SPOTIFY_IMPORT_UNAUTHORIZED') {
      return t('error:SPOTIFY_IMPORT_UNAUTHORIZED');
    }
    // Custom error in spotify module
    if (error.name === 'SPOTIFY_IMPORT_TOO_MANY_REQUESTS') {
      return t('error:SPOTIFY_IMPORT_TOO_MANY_REQUESTS');
    }
    if (error.name === 'GET_SPOTIFY_USER_ERROR') {
      return t('error:GET_SPOTIFY_USER_ERROR');
    }
    if (error.name === 'GET_SPOTIFY_PLAYLIST_ERROR') {
      return t('error:GET_SPOTIFY_PLAYLIST_ERROR');
    }
    // thrown by auth0Client in `auth0Provider.jsx`
    if (error.name === 'Error') {
      return t('error:NETWORK_ERROR');
    }
    // Auth0 errors
    if (error.code) {
      return t(`error:${error.code}`);
    }
    if (error.error) {
      return t(`error:${error.error}`);
    }
    return t('error:DEFAULT_ERROR');
  }, [error, t]);

  return (
    <Wrapper withPadding={withPadding}>
      <Container className={className}>
        <StyledWarningIcon />
        <p>{message}</p>
        {withButton ? (
          <ButtonWrapper>
            <StyledButton size="medium" emphasis="secondary" onClick={onClick}>
              {buttonText ?? t('global:checkNow')}
            </StyledButton>
          </ButtonWrapper>
        ) : null}
      </Container>
    </Wrapper>
  );
};

export default ErrorMessage;
