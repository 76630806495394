import { useTranslation } from 'react-i18next';

import { GridTable, useMediaSize } from '../../../../../../common';
import MusicManagementTableRow from './reminderTableRow';

const ZoneProfileReminderTable = ({
  zonesLoading,
  zonesError,
  zones,
  musicProfiles,
  musicProfilesLoading,
  musicProfilesError,
  changeMusicProfileOfZone,
  changedZones,
}) => {
  const { t } = useTranslation();

  const { isWiderThanSmall } = useMediaSize();

  return (
    <GridTable templateColumns={isWiderThanSmall ? '3fr 2fr' : '3fr 1fr'}>
      {zones?.length === 0 && <GridTable.Message>{t('addons:overview.table.broadcasts.noZones')}</GridTable.Message>}
      {zones?.map((zone) => (
        <MusicManagementTableRow
          key={zone.id}
          changedZones={changedZones}
          musicProfiles={musicProfiles}
          musicProfilesError={musicProfilesError}
          musicProfilesLoading={musicProfilesLoading}
          onChangeMusicProfileOfZone={changeMusicProfileOfZone}
          zone={zone}
        />
      ))}
      <GridTable.LoadingIndicator isLoading={zonesLoading} />
      <GridTable.ErrorMessage error={zonesError || musicProfilesError} />
    </GridTable>
  );
};

export default ZoneProfileReminderTable;
