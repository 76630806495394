import { useMutation, useQuery } from '@apollo/react-hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { addMinutes, formatISO9075 } from 'date-fns';
import { useCallback, useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as Yup from 'yup';

import { useSearchParams } from 'react-router-dom';
import {
  DateField,
  ErrorMessage,
  Modal,
  SelectField,
  StringField,
  SwitchField,
  TextField,
  TimeField,
  parseTimeStringWithDate,
} from '../../../../common';
import {
  SUPPORT_ADMIN_ROLE_PERMISSIONS,
  hasAllRequiredPermissions,
} from '../../../../global/auth';
import { useAuth } from '../../../../global/auth/newAuthProvider';
import {
  ARCHIVE_CONTACT_CALL,
  EDIT_CONTACT_CALL,
  GET_CONTACT_CATEGORIES_AND_CHANNELS,
  GET_ORGANIZATIONS_AND_LOCATIONS_OF_USER,
} from '../api';
import { useCustomerSuccessHistory } from '../historyContext';

const Form = styled.form`
  display: grid;
  grid-gap: 2rem;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 1rem;
  width: 100%;
`;

const DuoColumn = styled.div`
  display: grid;
  grid-gap: 2.4rem 1.6rem;
  grid-template-columns: repeat(2, 1fr);
`;

const SectionLabel = styled.div`
  color: ${({ theme }) => theme.dark200};
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.6;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  width: fit-content;
  padding-top: 4.2rem;
`;

const RecallContainer = styled.div`
  display: grid;
  grid-gap: 2rem;
`;

const CloseContactForm = ({ isOpen, toggle, historyEntry, title }) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { setHasMore } = useCustomerSuccessHistory();
  const [, setSearchParams] = useSearchParams();

  const isSupportAdmin = hasAllRequiredPermissions(
    user,
    SUPPORT_ADMIN_ROLE_PERMISSIONS
  );

  const schema = Yup.object().shape({
    summary: Yup.string().required(
      t('common:form.validation.summary.required')
    ),
    contactCategoryId: Yup.object()
      .nullable(true)
      .required(t('common:form.validation.contactCategory.required')),
    contactChannelId: Yup.object()
      .nullable(true)
      .required(t('common:form.validation.contactChannel.required')),
    firstName: Yup.string(),
    lastName: Yup.string(),
    phone: Yup.string().required(
      t('common:form.validation.customerSuccessPhone.required')
    ),
    emailAddress: Yup.string()
      .email(t('common:form.validation.emailAddress.type'))
      .required(
        t('common:form.validation.customerSuccessEmailAddress.required')
      ),
    contactDate: Yup.string().required(
      t('common:form.validation.date.required')
    ),
    contactTime: Yup.string().required(
      t('common:form.validation.hour.required')
    ),
    locationId: Yup.object().nullable(true),
    hasFutureContact: Yup.boolean().optional(),
    futureContactDate: Yup.date().when('hasFutureContact', {
      is: true,
      then: (fieldSchema) => fieldSchema.required(t('common:form.validation.date.required')),
      otherwise: (fieldSchema) => fieldSchema.nullable(),
    }).typeError(t('common:form.validation.date.required')),
    futureContactTime: Yup.string()
      .when('hasFutureContact', {
        is: true,
        then: (fieldSchema) => fieldSchema.required(t('common:form.validation.hour.required')),
        otherwise: (fieldSchema) => fieldSchema.nullable(),
      })
      .matches(/^([0-1]\d|2[0-3]):?[0-5]\d$/, t('common:form.validation.hour.required'))
      .typeError(t('common:form.validation.hour.required')),
    futureContactCategoryId: Yup.object()
      .nullable(true),
    callAssignment: Yup.string().optional(),
  });

  const {
    loading: contactCategoriesAndChannelsLoading,
    data: contactCategoriesAndChannelsData,
    error: contactCategoriesAndChannelsError,
  } = useQuery(GET_CONTACT_CATEGORIES_AND_CHANNELS, {
    fetchPolicy: 'cache-first',
  });

  const {
    loading: organizationsLoading,
    data: organizationsData,
    error: organizationsError,
  } = useQuery(GET_ORGANIZATIONS_AND_LOCATIONS_OF_USER, {
    variables: {
      userId: Number(historyEntry.contactPersonId),
    },
  });

  const locationOptions = useMemo(() => {
    if (!organizationsData) return [];
    const { organizationsOfUser } = organizationsData;
    const sortedOrganizations = organizationsOfUser.sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    const options = sortedOrganizations.reduce((acc, curr) => {
      const sortedLocations = curr.locations.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      const entries = sortedLocations.map((location) => ({
        value: location.id,
        label: `${curr.name} - ${location.name}`,
      }));
      return [...acc, ...entries];
    }, []);

    return options;
  }, [organizationsData]);

  const contactCategoriesOptions = useMemo(
    () =>
      contactCategoriesAndChannelsData?.contactCategories.map((category) => ({
        value: Number(category.id),
        label: category.name,
      })),
    [contactCategoriesAndChannelsData?.contactCategories]
  );

  const contactChannelOptions = useMemo(
    () =>
      contactCategoriesAndChannelsData?.contactChannels.map((channel) => ({
        value: Number(channel.id),
        label: channel.name,
      })),
    [contactCategoriesAndChannelsData?.contactChannels]
  );

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
    reset,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      summary: historyEntry.summary,
      contactCategoryId:
        contactCategoriesOptions?.find(
          (option) => option.value === Number(historyEntry.contactCategoryId)
        ) ?? null,
      contactChannelId:
        contactChannelOptions?.find(
          (option) => option.value === Number(historyEntry.contactChannelId)
        ) ?? null,
      firstName: historyEntry.contactPersonFirstName,
      lastName: historyEntry.contactPersonLastName,
      phone: historyEntry.contactPersonPhone,
      emailAddress: historyEntry.contactPersonEmailAddress,
      contactDate: new Date(),
      contactTime: new Date(),
      locationId: historyEntry?.location?.id
        ? locationOptions?.find(
            (option) =>
              option.value.toString() === historyEntry.location.id.toString()
          )
        : null,
      hasFutureContact: historyEntry.hasFutureContact ?? false,
      futureContactDate: historyEntry.futureContactDate ?? null,
      futureContactTime: historyEntry.futureContactTime ?? null,
      futureContactCategoryId:
        contactCategoriesOptions?.find(
          (option) => option.value === historyEntry.futureContactCategoryId
        ) ?? null,
      callAssignment: historyEntry.callAssignment ?? '',
    },
  });

  useEffect(() => {
    if (contactChannelOptions && contactCategoriesOptions && locationOptions) {
      reset({
        ...getValues(),
        contactCategoryId: historyEntry?.contactCategoryId
          ? contactCategoriesOptions.find(
              (option) =>
                option.value === Number(historyEntry.contactCategoryId)
            )
          : null,
        contactChannelId: historyEntry?.contactChannelId
          ? contactChannelOptions.find(
              (option) => option.value === Number(historyEntry.contactChannelId)
            )
          : null,
        locationId:
          parseInt(historyEntry?.location?.id) === -1
            ? null
            : locationOptions.find(
                (option) => option.value === historyEntry?.location?.id
              ),
      });
    }
  }, [
    contactCategoriesOptions,
    contactChannelOptions,
    historyEntry.contactCategoryId,
    historyEntry.contactChannelId,
    historyEntry?.location,
    historyEntry?.location?.id,
    locationOptions,
    reset,
    getValues,
  ]);

  const hasFutureContact = watch('hasFutureContact');

  const [editContactCall, { loading: requestLoading, error: requestError }] =
    useMutation(EDIT_CONTACT_CALL, {
      onCompleted: () => {
        // The refetch query will only fetch the 0 ... LIMIT results,
        // we have to inform the infinite list that there are possibly more contact calls to be fetched
        setHasMore(true);
        toggle();
      },
      refetchQueries: ['getContactCalls'],
    });

  const onConfirm = useCallback(
    (values) => {
      let deadlineDate = null;
      const futureDate =
        typeof values.futureContactDate === 'string'
          ? new Date(values.futureContactDate)
          : values.futureContactDate;
      if (values.hasFutureContact) {
        const date = parseTimeStringWithDate(
          values.futureContactTime.replace(':', ''),
          futureDate
        );
        deadlineDate = formatISO9075(
          addMinutes(date, date.getTimezoneOffset())
        );
      }
      editContactCall({
        variables: {
          contactCallId: historyEntry.id,
          contactCall: {
            summary: values.summary,
            contactCategoryId: Number(values.contactCategoryId.value),
            contactChannelId: Number(values.contactChannelId.value),
            contactPersonFirstName: values.firstName,
            contactPersonLastName: values.lastName,
            contactPersonPhone: values.phone,
            contactPersonEmailAddress: values.emailAddress,
            contactPersonId: Number(historyEntry.contactPersonId),
            // No deadline date so "stage" gets set to 'CLOSED'
            deadlineDate: null,
            locationId: values?.locationId?.value
              ? Number(values.locationId.value)
              : null,
            agentId: user.impersonatorId,
            stage: 'CLOSED',
          },
          hasFutureContactCall: values.hasFutureContact ?? false,
          futureContactCall: {
            summary: values.callAssignment,
            contactCategoryId: Number(
              values.futureContactCategoryId?.value ?? -1
            ),
            contactChannelId: Number(values.contactChannelId?.value),
            contactPersonFirstName: values.firstName,
            contactPersonLastName: values.lastName,
            contactPersonPhone: values.phone,
            contactPersonEmailAddress: values.emailAddress,
            // `userId` will be the id of the impersonated user (when in impersonated session)
            contactPersonId: Number(user.id),
            locationId: Number(values.locationId),
            deadlineDate,
            agentId: user.impersonatorId,
            stage: 'OPEN',
          },
        },
      });
      setSearchParams({});
    },
    [
      editContactCall,
      historyEntry.contactPersonId,
      historyEntry.id,
      user.impersonatorId,
      setSearchParams,
      user.id,
    ]
  );

  const [archiveContactCall, { loading: archiveLoading, error: archiveError }] =
    useMutation(ARCHIVE_CONTACT_CALL, {
      refetchQueries: ['getContactCalls'],
      onCompleted: ({ archiveContactCall: isSuccess }) => {
        if (isSuccess) {
          toggle();
          setSearchParams({});
        }
      },
    });

  const handleArchiveContactCall = useCallback(() => {
    const { id } = historyEntry;
    archiveContactCall({
      variables: {
        id: Number(id),
      },
    });
  }, [archiveContactCall, historyEntry]);

  const changeHasFutureContact = useCallback(
    (isChecked) => {
      setValue('hasFutureContact', isChecked);
    },
    [setValue]
  );

  const handleCloseModal = useCallback(() => {
    toggle();
    setSearchParams({});
  }, [setSearchParams, toggle]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCloseModal}
      title={title || t('customerSuccess:contact.title')}
      onConfirm={handleSubmit(onConfirm)}
      confirmText={t('customerSuccess:contact.close')}
      withDelete={!!isSupportAdmin}
      deleteLoading={archiveLoading}
      deleteDisabled={archiveError}
      requestLoading={requestLoading}
      onDelete={handleArchiveContactCall}
    >
      {/* No `onSubmit` necessary, because form does not contain a submit button. */}
      <Form>
        <TextField
          control={control}
          error={errors?.summary?.message}
          label={t('common:form.label.summary')}
          name="summary"
          rows={4}
          placeholder={t('common:form.placeholder.logContactSummary')}
        />
        <SelectField
          control={control}
          error={errors?.locationId?.message}
          isLoading={organizationsLoading}
          label={t('common:form.label.location')}
          name="locationId"
          options={locationOptions}
          placeholder={t('common:form.placeholder.location')}
        />
        <DuoColumn>
          <SelectField
            control={control}
            error={errors?.contactCategoryId?.message}
            isLoading={contactCategoriesAndChannelsLoading}
            label={t('common:form.label.contactCategory')}
            name="contactCategoryId"
            options={contactCategoriesOptions}
            placeholder={t('common:form.placeholder.contactCategory')}
          />
          <SelectField
            control={control}
            error={errors?.contactChannelId?.message}
            isLoading={contactCategoriesAndChannelsLoading}
            label={t('common:form.label.contactChannel')}
            name="contactChannelId"
            options={contactChannelOptions}
            placeholder={t('common:form.placeholder.contactChannel')}
          />
        </DuoColumn>
        <DuoColumn>
          <DateField
            control={control}
            error={errors?.contactDate?.message}
            label={t('common:form.label.date')}
            name="contactDate"
            placeholder={t('common:form.placeholder.date')}
            disablePastDaySelection
            disableWeekendDaySelection
          />
          <TimeField
            control={control}
            error={errors?.contactTime?.message}
            label={t('common:form.label.hour')}
            name="contactTime"
            placeholder={t('common:form.placeholder.hour')}
          />
        </DuoColumn>
        <SectionLabel>{t('common:form.group.contactDetails')}</SectionLabel>
        <DuoColumn>
          <StringField
            register={register}
            error={errors?.firstName?.message}
            label={t('common:form.label.firstName')}
            name="firstName"
            placeholder={t('common:form.placeholder.customerSuccessFirstName')}
          />
          <StringField
            register={register}
            error={errors?.lastName?.message}
            label={t('common:form.label.lastName')}
            name="lastName"
            placeholder={t('common:form.placeholder.customerSuccessLastName')}
          />
          <StringField
            register={register}
            error={errors?.phone?.message}
            label={t('common:form.label.phone')}
            name="phone"
            placeholder={t('common:form.placeholder.customerSuccessPhone')}
          />
          <StringField
            register={register}
            error={errors?.emailAddress?.message}
            label={t('common:form.label.emailAddress')}
            name="emailAddress"
            placeholder={t(
              'common:form.placeholder.customerSuccessEmailAddress'
            )}
          />
        </DuoColumn>
        <RecallContainer>
          <SectionLabel>{t('common:form.group.planNewContact')}</SectionLabel>
          <SwitchField
            checked={hasFutureContact}
            label={t('common:form.label.hasFutureContact')}
            onChange={changeHasFutureContact}
          />
          <DuoColumn>
            <DateField
              control={control}
              disabled={!hasFutureContact}
              disablePastDaySelection
              disableWeekendDaySelection
              error={errors?.futureContactDate?.message}
              label={t('common:form.label.date')}
              name="futureContactDate"
              placeholder={t('common:form.placeholder.date')}
            />
            <TimeField
              control={control}
              disabled={!hasFutureContact}
              error={errors?.futureContactTime?.message}
              label={t('common:form.label.hour')}
              name="futureContactTime"
              placeholder={t('common:form.placeholder.hour')}
            />
          </DuoColumn>
          <SelectField
            control={control}
            disabled={!hasFutureContact}
            error={errors?.futureContactCategoryId?.message}
            label={t('common:form.label.contactCategory')}
            name="futureContactCategoryId"
            options={contactCategoriesOptions}
            placeholder={t('common:form.placeholder.contactCategory')}
          />
          <TextField
            control={control}
            disabled={!hasFutureContact}
            error={errors?.callAssignment?.message}
            label={t('common:form.label.callAssignment')}
            name="callAssignment"
            rows={4}
            placeholder={t('common:form.placeholder.callAssignment')}
          />
        </RecallContainer>
        {(requestError ||
          contactCategoriesAndChannelsError ||
          organizationsError ||
          archiveError) && (
          <StyledErrorMessage
            error={
              requestError ||
              contactCategoriesAndChannelsError ||
              organizationsError ||
              archiveError
            }
          />
        )}
      </Form>
    </Modal>
  );
};

export default CloseContactForm;
