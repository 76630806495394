export const determineAvailableSubscriptionTemplates = (
  productCatalogue,
  selectedMusicLicenseIds,
  selectedColorIds,
  userWantsForeverProduct
) => {
  const availableProducts = productCatalogue.filter((product) => {
    return userWantsForeverProduct === 'either' || product.isForever === userWantsForeverProduct;
  });

  const productsWithSelectedMusicLicenseId = availableProducts.filter(
    (product) => selectedMusicLicenseIds.includes(product.musicLicense.id)
  );

  const productsWithSelectedColorId = productsWithSelectedMusicLicenseId.filter(
    (product) => selectedColorIds.includes(product.color.id)
  );

  const availableSubscriptionTemplatesById = productsWithSelectedColorId.reduce((acc, curr) => {
    if (!acc[curr.subscriptionTemplate.id]) {
      return { ...acc, [curr.subscriptionTemplate.id]: curr.subscriptionTemplate };
    }
    return acc;
  }, {});

  const availableSubscriptionTemplates = Object.keys(availableSubscriptionTemplatesById).map(
    (subscriptionTemplateId) => availableSubscriptionTemplatesById[subscriptionTemplateId]
  );

  return availableSubscriptionTemplates;
};
