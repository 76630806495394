import styled from 'styled-components';

const Container = styled.div`
  display: grid;
  row-gap: 1.2rem;
`;

const Row = styled.div`
  display: grid;
  column-gap: 1rem;
  grid-template-columns: 10rem 1fr 4rem;
  align-items: center;

  & > span:first-child {
    font-size: 1.4rem;
    color: ${({ theme }) => theme.dark400};
  }

  & > span:last-child {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.dark200};
    text-align: right;
  }
`;

const Chart = styled.div`
  display: flex;
  height: 3.2rem;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.uiBackground};
`;

const FilledPortion = styled.div`
  background: ${({ theme, color }) => `linear-gradient(
            135deg,
            ${theme.gradient[color][0]} 0%,
            ${theme.gradient[color][1]} 100%
          )`};
  width: ${({ percentageFilled }) => `calc(100% * ${percentageFilled})`};
  border-radius: 2px;
`;

const UnfilledPortion = styled.div`
  width: ${({ percentageFilled }) => `calc(100% * ${percentageFilled})`};
`;

const CustomBarChart = ({ data }) => {
  return (
    <Container>
      {data.map((reportValue) => (
        <Row key={reportValue.id}>
          <span>{reportValue.name}</span>
          <Chart>
            <FilledPortion color={reportValue.color} percentageFilled={reportValue.value / 100} />
            <UnfilledPortion percentageFilled={1 - reportValue.value / 100} />
          </Chart>
          <span>{Math.round(reportValue.value)}%</span>
        </Row>
      ))}
    </Container>
  );
};

export default CustomBarChart;
