import styled from 'styled-components';

import { ReactComponent as Asterix } from './asterix.svg';

const LabelText = styled.p`
  transition: color 0.2s ease-in-out;
  color: ${({ disabled, theme }) => (disabled ? theme.dark300 : theme.dark500)};
  font-size: 1.4rem;
  line-height: 1.4;
  padding-bottom: 0.4rem;
`;

const Label = ({ children, htmlFor, required, disabled, className }) => (
  <label htmlFor={htmlFor} className={className} disabled={disabled}>
    <LabelText disabled={disabled}>
      {children}
      {required ? (
        <>
          &nbsp;
          <Asterix />
        </>
      ) : null}
    </LabelText>
  </label>
);

export default Label;
