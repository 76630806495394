const determineCheapestPricePerMonthForAddonCategory = (addonCategory) => {
  return addonCategory.reduce((acc, curr) => {
    const { subscriptionTemplate } = curr;
    const pricePerMonth = curr.price / subscriptionTemplate.recurringInterval;
    return acc < pricePerMonth && acc !== null ? acc : pricePerMonth;
  }, null);
};

const determineAddonCategoriesToShow = (availableAddons) => {
  const addonCategories = availableAddons.reduce((acc, curr) => {
    if (acc[curr.shortName]) {
      return { ...acc, ...{ [curr.shortName]: [...acc[curr.shortName], curr] } };
    }
    return { ...acc, ...{ [curr.shortName]: [curr] } };
  }, {});

  const addonCategoriesToShow = Object.keys(addonCategories).map((addonCategory) => {
    const cheapestPricePerMonth = determineCheapestPricePerMonthForAddonCategory(addonCategories[addonCategory]);

    // We choose one of the possible addons for every addon category and overwrite the price
    // with the cheapest option. This is solely for viewing purposes, in next steps we will decide
    // the correct addon.
    return { ...addonCategories[addonCategory][0], price: cheapestPricePerMonth };
  });

  return addonCategoriesToShow;
};

export default determineAddonCategoriesToShow;
