import styled from 'styled-components';

const Container = styled.div`
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Card = ({ children, className }) => {
  return <Container className={className}>{children}</Container>;
};

export default Card;
