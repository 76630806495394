import { useState, useCallback } from 'react';
import styled from 'styled-components';

import StringField from '.';
import { ReactComponent as RawEyeIcon } from '../../../assets/icons/eye.svg';
import { ReactComponent as RawEyeCrossedIcon } from '../../../assets/icons/eyeCrossed.svg';

const Container = styled.div`
  width: 100%;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: -3rem;
  margin-right: 2rem;
  cursor: pointer;
`;

const PasswordField = (rest) => {
  const [type, setType] = useState('password');

  const toggleFieldType = useCallback(() => {
    type === 'password' ? setType('text') : setType('password');
  }, [type, setType]);

  return (
    <Container>
      <StringField {...rest} type={type} />
      <IconWrapper onClick={toggleFieldType}>
        {type === 'password' ? <RawEyeCrossedIcon /> : <RawEyeIcon />}
      </IconWrapper>
    </Container>
  );
};

export default PasswordField;
