export const getEquivalentOfProduct = (currentProduct, availableProducts, userWantsForever) => {
  const equivalentProducts = availableProducts.filter((availableProduct) => {
    const colorTest = availableProduct.color.id === currentProduct.color.id;
    const musicLicenseTest = availableProduct.musicLicense.id === currentProduct.musicLicense.id;
    const recurringIntervalTest =
      availableProduct.subscriptionTemplate.recurringInterval === currentProduct.subscriptionTemplate.recurringInterval;
    const foreverTest = availableProduct.isForever === userWantsForever;
    return colorTest && musicLicenseTest && recurringIntervalTest && foreverTest;
  });

  if (equivalentProducts.length === 0) {
    throw new Error(
      `Did not find a [${userWantsForever ? 'forever' : 'fixed'}] equivalent of product with id [${currentProduct.id}]`
    );
  }

  if (equivalentProducts.length > 1) {
    throw new Error(
      `Found multiple [${userWantsForever ? 'forever' : 'fixed'}] equivalents of product with id [${currentProduct.id}]`
    );
  }

  return equivalentProducts[0];
};
