import { forwardRef } from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
`;

const Page = forwardRef(({ title, children, className }, ref) => {
  return (
    <>
      <Helmet>
        <title>{title} | MyTunify</title>
      </Helmet>
      <Container ref={ref} className={className}>
        {children}
      </Container>
    </>
  );
});

export default Page;
