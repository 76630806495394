import { useCallback } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/react-hooks';

import { SelectablePlaylistButton } from '../../../shared';
import { useTunifyGreen } from '../tunifyGreenProvider';
import { CategoryTypes } from '../types';
import { CheckboxField } from '../../../../../common';
import { UPDATE_CALENDAR_VISIBILITY } from '../api';
import { useUpdateChannelCacheAfterUpdate } from '../cache';

const StyledSelectablePlaylistButton = styled(SelectablePlaylistButton)`
  padding: 0.85rem 0.5rem 0.85rem 3rem;

  &:after {
    width: 100%;
  }
`;

const Calendar = ({ calendar, channel }) => {
  const { dispatch, selectedCalendar, musicProfile } = useTunifyGreen();

  const handleUpdateChannelCacheAfterUpdate = useUpdateChannelCacheAfterUpdate({
    musicProfileId: musicProfile.tunifyMusicProfileUserId,
    calendarGroupId: channel.id,
    calendarId: calendar.calendarId,
  });

  const [updateCalendarVisibility] = useMutation(UPDATE_CALENDAR_VISIBILITY, {
    update: handleUpdateChannelCacheAfterUpdate,
  });

  const handleOpenChannel = useCallback(() => {
    dispatch({
      selectedCalendarType: CategoryTypes.TUNIFY,
      selectedCalendar: calendar,
    });
  }, [calendar, dispatch]);

  const handleClickCheckbox = useCallback((event) => event.stopPropagation(), []);

  const handleChangeCheckbox = useCallback(() => {
    updateCalendarVisibility({
      variables: {
        musicProfileId: musicProfile.tunifyMusicProfileUserId,
        calendarId: calendar.calendarId,
        visible: !calendar.properties.visible,
      },
    });
  }, [musicProfile, calendar, updateCalendarVisibility]);

  return (
    <StyledSelectablePlaylistButton
      onClick={handleOpenChannel}
      selected={selectedCalendar?.calendarId === calendar.calendarId}
      type="button"
      value={calendar.calendarId}
    >
      <p>{calendar.name}</p>
      <CheckboxField
        onChange={handleChangeCheckbox}
        onClick={handleClickCheckbox}
        checked={calendar.properties.visible ?? false}
      />
    </StyledSelectablePlaylistButton>
  );
};

export default Calendar;
