import styled from 'styled-components';

import { GridTable, LoadingIndicator, AudioButton } from '../../../common';
import { ReactComponent as RawErrorIcon } from '../../../assets/icons/close.svg';

const ErrorIcon = styled(RawErrorIcon)`
  width: 1rem;
  height: 1rem;

  & path {
    fill: ${({ theme }) => theme.sweetOrange};
  }
`;

const AudioTableData = ({ file }) => {
  return (
    <GridTable.Value>
      {file.state === 'error' && <ErrorIcon />}
      {file.state === 'processing' && <LoadingIndicator size="small" />}
      {file.state === 'available' && <AudioButton src={file.url} />}
    </GridTable.Value>
  );
};

export default AudioTableData;
