import { useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Button } from '..';

const Centered = styled.div`
  height: 100vh;
  @supports (height: 100svh) {
    height: 100svh;
  }
  display: grid;
  justify-content: center;
  align-items: center;
`;

const StaticWidthContainer = styled.div`
  display: grid;
  gap: 3.6rem;
  padding: 1.6rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    width: 40rem;
  }
`;

const Text = styled.p`
  font-size: 1.6rem;
  line-height: 1.4;
`;

const ButtonGroup = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-rows: 1fr 1fr;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    grid-template-columns: 3fr 2fr;
  }
`;

const ErrorBody = ({ className }) => {
  const { t } = useTranslation();

  const handleRefresh = useCallback(() => {
    window.location.reload();
  }, []);

  const handleContactClick = useCallback(() => {
    // In favor of the styled component polymorphism, just create this helper tag
    // Gives us more flexibilty for the dynamic email template
    const emailLink = document.createElement('a');
    emailLink.href = `mailto:${t('config:customerSupportEmail')}?subject=${t(
      'error:RENDER_ERROR_SUPPORT_EMAIL_SUBJECT'
    )}`;

    emailLink.click();
  }, [t]);

  return (
    <Centered className={className}>
      <StaticWidthContainer>
        <Text>{t('error:RENDER_ERROR')}</Text>
        <ButtonGroup>
          <Button onClick={handleRefresh} title={t('common:button.refresh')}>
            {t('common:button.refresh')}
          </Button>
          <Button
            highlightGradient="sunset"
            onClick={handleContactClick}
            title={t('common:button.title.contact')}
          >
            {t('common:button.contact')}
          </Button>
        </ButtonGroup>
      </StaticWidthContainer>
    </Centered>
  );
};

export default ErrorBody;
