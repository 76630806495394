import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { InfoCard } from '../../../common';

import { PartnerDetailsCard, StatsCard, ReferralCard, CommissionOverviewCard } from './cards';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, min-content);
  grid-gap: 2rem;
  padding: 0.8rem;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    grid-template-rows: repeat(3, min-content);
    padding: 1.6rem 2.4rem;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    padding: 1.6rem 4.6rem;
  }
`;

const StyledInfoCard = styled(InfoCard)`
  max-width: unset;
`;

const CommissionTab = () => {
  const { t } = useTranslation();
  return (
    <Grid>
      <StatsCard />
      <PartnerDetailsCard />
      <ReferralCard />
      <StyledInfoCard title={t('partner:dashboard.commissionOverview')}>
        <CommissionOverviewCard />
      </StyledInfoCard>
    </Grid>
  );
};

export default CommissionTab;
