import styled from 'styled-components';

const ErrorText = styled.p`
  color: ${({ theme }) => theme.grapefruitRed};
  font-size: 1.2rem;
  line-height: 1.3;
  margin-top: 0.4rem;
`;

const ErrorMessage = ({ children, className }) => <ErrorText className={className}>{children}</ErrorText>;

export default ErrorMessage;
