import { useTranslation } from 'react-i18next';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import styled from 'styled-components';

import { GridTable, useMediaSize } from '../../../common';
import { usePartner } from '../partnerContext';
import ReferredCustomerTableRow from './referredCustomerTableRow';
import { LIMIT } from '../partnerProvider';

const Header = styled(GridTable.Header)`
  margin: 2rem 1.2rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.stroke};
`;

const Head = styled(GridTable.Head)`
  color: ${({ theme }) => theme.dark500};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.143;
`;

const ReferredCustomersTable = () => {
  const { t } = useTranslation();
  const {
    partnerReferredCustomers,
    partnerReferredCustomersLoading,
    partnerReferredCustomersError,
    fetchMoreReferredCustomers,
    hasMoreReferredCustomers,
  } = usePartner();

  const { isWiderThanSmall, isWiderThanLarge } = useMediaSize();

  const [infiniteRef] = useInfiniteScroll({
    loading: partnerReferredCustomersLoading,
    // Due to refetch we cannot know if there is more after the initial query,
    // we have to additionally check for this
    hasNextPage:
      partnerReferredCustomers?.length >= LIMIT && hasMoreReferredCustomers,
    onLoadMore: fetchMoreReferredCustomers,
  });

  return (
    <GridTable ref={infiniteRef}>
      <>
        {isWiderThanSmall && (
          <Header
            templateColumns={
              isWiderThanLarge ? 'repeat(5, 1fr)' : '2fr 2fr 1fr'
            }
          >
            {isWiderThanLarge && (
              <Head>
                {t('partner:referredCustomers.tableHeader.closingDate')}
              </Head>
            )}
            <Head>{t('partner:referredCustomers.tableHeader.name')}</Head>
            <Head>
              {t('partner:referredCustomers.tableHeader.subscription')}
            </Head>
            {isWiderThanLarge && (
              <Head>{t('partner:referredCustomers.tableHeader.endDate')}</Head>
            )}
            <Head>{t('partner:referredCustomers.tableHeader.status')}</Head>
          </Header>
        )}
        {(!partnerReferredCustomers ||
          partnerReferredCustomers?.length === 0) &&
          !partnerReferredCustomersLoading && (
            <GridTable.Message>
              {t('partner:referredCustomers.table.noReferredCustomers')}
            </GridTable.Message>
          )}
        {partnerReferredCustomers
          ?.filter((p) => p.currentSubscription || p.lastSubscription)
          .map((referredCustomer) => (
            <ReferredCustomerTableRow
              key={referredCustomer.id}
              referredCustomer={referredCustomer}
            />
          ))}
        <GridTable.LoadingIndicator
          isLoading={partnerReferredCustomersLoading}
        />
        <GridTable.ErrorMessage error={partnerReferredCustomersError} />
      </>
    </GridTable>
  );
};

export default ReferredCustomersTable;
