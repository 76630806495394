import { parseJSON, parseISO } from 'date-fns';

export const parseDateString = (dateString) => {
  let date;

  const utcDate = parseJSON(dateString);
  const isoDate = parseISO(dateString);

  if (utcDate.toString() !== 'Invalid Date') {
    date = utcDate;
  } else if (isoDate.toString() !== 'Invalid Date') {
    date = isoDate;
  } else {
    console.error('Unknown date format');
  }

  return date;
};
