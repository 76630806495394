import { useEffect, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import * as Yup from 'yup';

import {
  extendedVATCheck,
  CheckboxField,
  StringField,
  SelectField,
  SwitchField,
} from '../../../../common';
import { CHECK_VAT, EDIT_ORGANIZATION } from '../api';
import config from '../../../../config';
import { usePurchase } from '../purchaseContext';
import { checkIfOrganizationDetailsAreTheSame } from '../utils';

const { ENABLE_VIES_VAT_CHECK, ENABLE_EXTENDED_VAT_CHECK } = config;

const FormContainer = styled.div`
  display: grid;
  gap: 2rem;
`;

const Description = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.dark300};
  line-height: 1.4;
  margin-top: -1rem;
`;

const StreetRow = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 1rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    grid-gap: 1.5rem;
  }
`;

const ZipRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 1rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    grid-gap: 1.5rem;
  }
`;

const StyledSwitchField = styled(SwitchField)`
  margin-top: 3rem;
`;

const OrganizationDetailsForm = ({
  onSubmit,
  apolloClient,
  organization,
  countries,
  vatCountryCodes,
  setGoToNextStepAction,
  setGoToNextStepError,
  goToNextStep,
}) => {
  const { t } = useTranslation();

  const schema = Yup.object().shape({
    name: Yup.string().required(
      t('common:form.validation.organizationName.required')
    ),
    streetName: Yup.string().required(
      t('common:form.validation.streetName.required')
    ),
    streetNumber: Yup.string().required(
      t('common:form.validation.streetNumber.required')
    ),
    zip: Yup.string().required(t('common:form.validation.zip.required')),
    city: Yup.string().required(t('common:form.validation.city.required')),
    country: Yup.object()
      .nullable(true)
      .required(t('common:form.validation.country.required')),
    isVatLiable: Yup.boolean(),
    vat: Yup.string().when(['isVatLiable'], {
      is: true,
      then: Yup.string()
        .test(
          'isVatValid',
          t('common:form.validation.vat.test'),
          async (value, ctx) => {
            if (!value) return true; // The required test will handle this and show a corresponding error

            if (ENABLE_EXTENDED_VAT_CHECK) {
              return extendedVATCheck(value);
            }

            if (ENABLE_VIES_VAT_CHECK) {
              const country = countries.find(
                ({ id }) => Number(id) === Number(ctx.parent.country?.value)
              );

              if (!country) {
                return false;
              }

              const { data, errors } = await apolloClient.mutate({
                mutation: CHECK_VAT,
                variables: {
                  countryCode: country.code,
                  vatNumber: value.trim().toUpperCase(),
                },
              });

              if (errors) return false;

              return data?.isVatNumberValidForCountryCode;
            }
          }
        )
        .required(t('common:form.validation.vat.required')),
    }),
    hasOptionalFields: Yup.boolean(),
    extraAddressInfo: Yup.string(),
    invoiceEmail: Yup.string(),
    ref: Yup.string(),
  });

  const countryOptions = useMemo(
    () =>
      countries.map((country) => ({
        value: Number(country.id),
        label: country.name,
      })),
    [countries]
  );

  const {
    register,
    control,
    setValue,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      id: organization.id,
      name: organization.name,
      streetName: organization.streetName,
      streetNumber: organization.streetNumber,
      zip: organization.zip,
      city: organization.city,
      isVatLiable: !!organization.vat,
      vat: organization.vat,
      country: '',
      hasOptionalFields:
        !!organization.extraAddressInfo ||
        !!organization.invoiceEmail ||
        !!organization.ref,
      extraAddressInfo: organization.extraAddressInfo,
      invoiceEmail: organization.invoiceEmail,
      ref: organization.ref,
    },
  });

  useEffect(() => {
    setGoToNextStepAction(() => () => {
      handleSubmit(onSubmit)();
    });
    return () => {
      setGoToNextStepError(null);
      setGoToNextStepAction(() => goToNextStep);
    };
  }, [
    setGoToNextStepAction,
    setGoToNextStepError,
    goToNextStep,
    handleSubmit,
    onSubmit,
  ]);

  useEffect(() => {
    const selectedCountryFound = countries.find(
      ({ id }) => Number(id) === organization?.countryId
    );
    if (selectedCountryFound) {
      setValue('country', {
        label: selectedCountryFound.name,
        value: selectedCountryFound.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries, organization?.countryId]);

  const country = watch('country');
  const isVatLiable = watch('isVatLiable');
  const hasOptionalFields = watch('hasOptionalFields');

  const selectedCountry = useMemo(
    () =>
      country?.value
        ? countries.find(({ id }) => Number(id) === Number(country.value))
        : null,
    [countries, country]
  );

  const isVatCountry = useMemo(
    () =>
      selectedCountry ? vatCountryCodes.includes(selectedCountry?.code) : false,
    [selectedCountry, vatCountryCodes]
  );

  const [showVatInputs, setShowVatInputs] = useState(isVatCountry);

  useEffect(() => {
    if (
      isVatCountry &&
      !organization?.vat &&
      organization?.countryId &&
      country?.value &&
      Number(organization.countryId) === Number(country.value)
    ) {
      setShowVatInputs(true);
      setValue('isVatLiable', false);
    } else {
      if (!isVatCountry) {
        setShowVatInputs(false);
        setValue('isVatLiable', false);
      } else {
        setShowVatInputs(true);
        setValue('isVatLiable', true);
      }
    }
  }, [
    isVatCountry,
    country.value,
    organization?.vat,
    organization.countryId,
    setValue,
  ]);

  const handleToggleOptionalFields = useCallback(
    (isChecked) => {
      setValue('hasOptionalFields', isChecked);
    },
    [setValue]
  );

  const openOptionalFields = useCallback(() => {
    setValue('hasOptionalFields', true);
  }, [setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormContainer>
        <StringField
          register={register}
          error={errors?.name?.message}
          label={t('common:form.label.organizationName')}
          name="name"
          placeholder={t('common:form.placeholder.organizationName')}
        />
        <StreetRow>
          <StringField
            register={register}
            error={errors?.streetName?.message}
            label={t('common:form.label.streetName')}
            name="streetName"
            placeholder={t('common:form.placeholder.streetName')}
          />
          <StringField
            register={register}
            error={errors?.streetNumber?.message}
            label={t('common:form.label.streetNumber')}
            name="streetNumber"
            placeholder={t('common:form.placeholder.streetNumber')}
          />
        </StreetRow>
        <ZipRow>
          <StringField
            register={register}
            error={errors?.zip?.message}
            label={t('common:form.label.zip')}
            name="zip"
            placeholder={t('common:form.placeholder.zip')}
          />
          <StringField
            register={register}
            error={errors?.city?.message}
            label={t('common:form.label.city')}
            name="city"
            placeholder={t('common:form.placeholder.city')}
          />
        </ZipRow>
        <SelectField
          control={control}
          error={errors?.country?.message}
          label={t('common:form.label.country')}
          name="country"
          options={countryOptions}
          placeholder={t('common:form.placeholder.country')}
        />
        {showVatInputs && (
          <CheckboxField
            register={register}
            error={errors?.isVatLiable?.message}
            label={t('common:form.label.isVatLiable')}
            name="isVatLiable"
          />
        )}
        {showVatInputs && isVatLiable && (
          <StringField
            register={register}
            error={errors?.vat?.message}
            label={t('common:form.label.vatNumber')}
            name="vat"
            placeholder={t('common:form.placeholder.vatNumber', {
              value: selectedCountry?.code,
            })}
          />
        )}
        <StyledSwitchField
          checked={hasOptionalFields}
          isDisabled={false}
          label={t('organizations:editOrganizationOptionalFieldsLabel')}
          onChange={handleToggleOptionalFields}
        />
        <Description>
          {t('organizations:editOrganizationExtraFieldsNote')}
        </Description>
        <StringField
          register={register}
          disabled={!hasOptionalFields}
          onContainerClick={openOptionalFields}
          error={errors?.extraAddressInfo?.message}
          label={t('common:form.label.extraAddressInfo')}
          name="extraAddressInfo"
          placeholder={t('common:form.placeholder.extraAddressInfo')}
        />
        <StringField
          register={register}
          disabled={!hasOptionalFields}
          onContainerClick={openOptionalFields}
          error={errors?.ref?.message}
          label={t('common:form.label.ref')}
          name="ref"
          placeholder={t('common:form.placeholder.ref')}
        />
        <StringField
          register={register}
          disabled={!hasOptionalFields}
          onContainerClick={openOptionalFields}
          error={errors?.invoiceEmail?.message}
          label={t('common:form.label.invoiceEmail')}
          name="invoiceEmail"
          placeholder={t('common:form.placeholder.invoiceEmail')}
        />
      </FormContainer>
    </form>
  );
};

const OrganizationDetailsStep = ({
  setGoToNextStepAction,
  setGoToNextStepLoading,
  setGoToNextStepError,
  goToNextStep,
}) => {
  const apolloClient = useApolloClient();
  const {
    countries,
    vatCountryCodes,
    organizationDetails,
    updateOrganizationDetails,
  } = usePurchase();

  const [editOrganization] = useMutation(EDIT_ORGANIZATION, {
    onCompleted: ({ editOrganization: newOrganizationValues }) => {
      updateOrganizationDetails(newOrganizationValues);
      setGoToNextStepLoading(false);
      setGoToNextStepError(null);
      goToNextStep();
    },
    onError: (err) => {
      setGoToNextStepLoading(false);
      setGoToNextStepError(err);
    },
  });

  const normalize = useCallback((values) => {
    const normalizedValues = { ...values };
    if (!values.isVatLiable) {
      normalizedValues.vat = '';
    } else {
      normalizedValues.vat = values.vat.trim().toUpperCase();
    }
    delete normalizedValues.isVatLiable;
    if (!values.hasOptionalFields) {
      normalizedValues.extraAddressInfo = '';
      normalizedValues.invoiceEmail = '';
      normalizedValues.ref = '';
    }
    delete normalizedValues.hasOptionalFields;
    normalizedValues.countryId = Number(values.country.value);
    delete normalizedValues.country;
    return normalizedValues;
  }, []);

  const handleSubmit = useCallback(
    (formValues) => {
      const normalizedFormValues = normalize(formValues);
      if (
        checkIfOrganizationDetailsAreTheSame(
          organizationDetails,
          normalizedFormValues
        )
      ) {
        goToNextStep();
      } else {
        const { id, ...newOrganizationDetails } = normalizedFormValues;
        setGoToNextStepLoading(true);
        editOrganization({
          variables: {
            organizationId: id,
            organizationDetails: newOrganizationDetails,
          },
        });
      }
    },
    [
      normalize,
      organizationDetails,
      editOrganization,
      setGoToNextStepLoading,
      goToNextStep,
    ]
  );

  return (
    <OrganizationDetailsForm
      apolloClient={apolloClient}
      countries={countries}
      onSubmit={handleSubmit}
      organization={organizationDetails}
      vatCountryCodes={vatCountryCodes}
      setGoToNextStepAction={setGoToNextStepAction}
      setGoToNextStepError={setGoToNextStepError}
      goToNextStep={goToNextStep}
    />
  );
};

export default OrganizationDetailsStep;
