import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import DayOpeningHours from './dayOpeningHours';

const Title = styled.h4`
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.5;
  padding-top: 2rem;
`;

const Header = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 3rem 7.6rem 7.6rem min-content;
  padding-bottom: 1rem;

  & > span {
    font-size: 1.4rem;
    font-weight: 600;
    color: ${({ theme }) => theme.dark200};
  }
`;

const OpeningHours = styled.div`
  display: grid;
  gap: 1rem;
`;

// The switch is not shown at the moment because the Tunify API is not ready
// const SwitchContainer = styled.div`
//   display: grid;
//   grid-template-columns: max-content 1fr;
//   gap: 1rem;
//   margin-bottom: 1rem;
// `;

const OpeningHoursForm = ({ errors, control }) => {
  const { t } = useTranslation();

  // The switch is not shown at the moment because the Tunify API is not ready
  // const googleSynced = watch('openingHours.googleSynced');
  // const handleSwitch = useCallback(
  //   (isSet) => {
  //     setValue('openingHours.googleSynced', isSet);
  //   },
  //   [setValue]
  // );

  return (
    <>
      <Title>{t('glossary:openingHours')}</Title>
      {/* The switch is not shown at the moment because the Tunify API is not ready */}
      {/* <SwitchContainer>
        <SwitchField checked={googleSynced} name="openingHours.googleSynced" onChange={handleSwitch} />
        <span>{t('locations:syncGoogle')}</span>
      </SwitchContainer> */}
      <div>
        <Header>
          <span />
          <span>{t('glossary:from')}</span>
          <span>{t('glossary:to')}</span>
        </Header>
        <OpeningHours>
          <DayOpeningHours day={1} errors={errors} control={control} />
          <DayOpeningHours day={2} errors={errors} control={control} />
          <DayOpeningHours day={3} errors={errors} control={control} />
          <DayOpeningHours day={4} errors={errors} control={control} />
          <DayOpeningHours day={5} errors={errors} control={control} />
          <DayOpeningHours day={6} errors={errors} control={control} />
          <DayOpeningHours day={7} errors={errors} control={control} />
        </OpeningHours>
      </div>
    </>
  );
};

export default OpeningHoursForm;
