import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { GridTable, useMediaSize } from '../../../../../../common';
import { Dropdown as DropdownField } from '../../../../../../common/oldFields/dropdownField';

const StyledDropdownField = styled(DropdownField)`
  margin: 0;
`;

const StyledTableData = styled(GridTable.Value)`
  display: flex;
  align-items: center;

  & > p {
    margin: 0 0 0 1rem;

    > span {
      font-style: italic;
      color: ${({ theme }) => theme.dark300};
    }
  }
`;

const ZoneRow = ({
  zone,
  musicProfiles,
  musicProfilesLoading,
  musicProfilesError,
  onChangeMusicProfileOfZone,
  changedZones,
}) => {
  const { t } = useTranslation();

  const { isWiderThanSmall } = useMediaSize();

  const profileOptions = useMemo(
    () =>
      musicProfiles
        ? [
            ...musicProfiles.map((profile) => ({
              id: profile.id,
              name: profile.name,
            })),
            {
              id: '-1',
              name: t(
                'broadcasts:card:actions.zoneOverview.modal.profile.noProfileOption'
              ),
            },
          ]
        : [],
    [musicProfiles, t]
  );

  const handleChangeProfile = useCallback(
    (profileId) => {
      const { id } = profileOptions.find((profile) => profile.id === profileId);

      onChangeMusicProfileOfZone(zone.id, id);
    },
    [zone, profileOptions, onChangeMusicProfileOfZone]
  );

  const currentOptionId = useMemo(() => {
    const alreadyChangedZone = changedZones.find(
      (changedZone) => changedZone.zoneId === zone.id
    );
    if (alreadyChangedZone) {
      return alreadyChangedZone.musicProfileId;
    }

    if (!zone.musicProfile) return '-1';

    const profile = profileOptions.find(
      (pro) => pro.id === zone.musicProfile.id
    );
    if (!profile) return '-1';

    return profile.id;
  }, [profileOptions, zone.musicProfile, changedZones, zone.id]);

  return (
    <GridTable.Row>
      <StyledTableData>
        <p>
          {zone.name} <br />
          <span>
            {zone.location.name} - {zone.location.organization.name}
          </span>
        </p>
      </StyledTableData>
      {isWiderThanSmall && (
        <GridTable.Value>
          <StyledDropdownField
            currentOptionId={currentOptionId}
            disabled={musicProfilesError}
            isLoading={musicProfilesLoading}
            onChange={handleChangeProfile}
            options={profileOptions}
            placeholder={t(
              'broadcasts:card.actions.zoneOverview.modal.profile.placeholder'
            )}
          />
        </GridTable.Value>
      )}
    </GridTable.Row>
  );
};

export default ZoneRow;
