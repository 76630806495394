import shortid from 'shortid';
import { parseTimeString } from '../../utils/date';

const transformOpeningPeriod = (openingPeriod) => {
  const { open, close } = openingPeriod;
  return {
    id: shortid.generate(),
    open: parseTimeString(open),
    close: parseTimeString(close),
  };
};

const createOpeningPeriods = (openingType) => {
  if (openingType === 'closed') {
    return [{ open: null, close: null, id: shortid.generate() }];
  }
  return [{ open: parseTimeString('0000'), close: parseTimeString('0000'), id: shortid.generate() }];
};

const transformOpeningDay = (openingDay) => {
  const { day, openingType, openingPeriods } = openingDay;
  return {
    day,
    openingType,
    openingPeriods:
      openingType === 'withOpeningPeriods'
        ? openingPeriods.map(transformOpeningPeriod)
        : createOpeningPeriods(openingType),
  };
};

export const transformApiResult = (openingHours) => {
  return {
    googleSynced: openingHours.googleSynced,
    openingDays: openingHours.openingDays.map(transformOpeningDay),
  };
};
