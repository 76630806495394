import { useTranslation } from 'react-i18next';

import { Page } from '../../../common';
import AddonsOverviewProvider from './addonsOverviewProvider';
import Overview from './overview';

const AddonsOverviewPage = () => {
  const { t } = useTranslation();

  return (
    <AddonsOverviewProvider>
      <Page title={t('addons:title')}>
        <Overview />
      </Page>
    </AddonsOverviewProvider>
  );
};

export default AddonsOverviewPage;
