import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import {
  ErrorMessage,
  LoadingIndicator,
  SelectCard,
  SwitchField,
} from '../../../common';
import { useRenewPurchase } from './renewPurchaseContext';
import { ReactComponent as InfoIcon } from '../../../assets/icons/smallInfo.svg';
import { MaxChildHeightCase, MaxChildHeightContainer } from '../../../common/maxChildHeightContainer';

const Label = styled.h2`
  font-size: 1.4rem;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Options = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Image = styled.img`
  border-radius: 5%;
`;

const Text = styled.div`
  font-size: 1.2rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.dark200};
`;

const SelectCardWrapper = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  gap: 1rem;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 1rem;
`;

const TogglePartContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

const InvisibleContainer = styled.div`
  ${({ $hidden}) => $hidden && css`
    visibility: hidden;
  `}
`;

const InfoDiv = styled.div`
  display: flex;
  font-size: 1.4rem;
  line-height: 1.4;
`;

const BlackenedInfoIcon = styled(InfoIcon)`
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 1.4rem;
  margin-top: 0.28rem;
  path {
    stroke: currentColor;
  }
`;

const PaymentOptions = () => {
  const { t } = useTranslation();
  const {
    isForever,
    switchIsForever,
    selectedPaymentOption,
    selectPaymentOption,
    cartLoading,
    availablePaymentOptions,
    paymentOptionsLoading,
    paymentOptionsError,
  } = useRenewPurchase();

  const handleSelect = useCallback(
    (id) => {
      if (id !== selectedPaymentOption?.id) {
        const newPaymentOption = availablePaymentOptions.find(
          (paymentOption) => paymentOption.id === id
        );
        selectPaymentOption(newPaymentOption);
      }
    },
    [selectPaymentOption, availablePaymentOptions, selectedPaymentOption]
  );

  const selectOptions = useMemo(
    () =>
      availablePaymentOptions.map((paymentOption) => (
        <SelectCard
          key={paymentOption.id}
          activeSelectCardId={selectedPaymentOption?.id}
          id={paymentOption.id}
          onClick={handleSelect}
          title={
            <SelectCardWrapper>
              <Image src={`data:image/png;base64,${paymentOption.image}`} />
              <Label>{paymentOption.name}</Label>
            </SelectCardWrapper>
          }
        ></SelectCard>
      )),
    [availablePaymentOptions, handleSelect, selectedPaymentOption]
  );

  const pageContent = useMemo(
    () => (
      <>
        {paymentOptionsError && <ErrorMessage error={paymentOptionsError} />}
        {paymentOptionsLoading && <LoadingIndicator />}
        {!paymentOptionsLoading && !paymentOptionsError && (
            <Options>{selectOptions}</Options>
        )}

        <MaxChildHeightContainer>
          <MaxChildHeightCase visible={selectedPaymentOption?.supportsRecurringBilling}>
            <TogglePartContainer>
              <SwitchField
                checked={isForever}
                isDisabled={paymentOptionsLoading || cartLoading}
                onChange={switchIsForever}
                label={t('subscriptions:isForeverLabel')}
              />
              <Text>{t('subscriptions:isForeverNote')}</Text>
            </TogglePartContainer>
          </MaxChildHeightCase>
          <MaxChildHeightCase visible={!selectedPaymentOption?.supportsRecurringBilling}>
            <InfoDiv>
              <BlackenedInfoIcon />
              {t('subscriptions:recurringBillingUnsupported')}
            </InfoDiv>
            <InvisibleContainer $hidden={!selectedPaymentOption?.isWireTransfer}>
              <StyledErrorMessage
                error={t('subscriptions:warningIsWireTransfer')}
              />
            </InvisibleContainer>
          </MaxChildHeightCase>
        </MaxChildHeightContainer>
      </>
    ),
    [
      switchIsForever,
      isForever,
      paymentOptionsError,
      paymentOptionsLoading,
      selectOptions,
      cartLoading,
      t,
      selectedPaymentOption,
    ]
  );
  return pageContent;
};

export default PaymentOptions;
