export const determineSelectedProduct = (
  productCatalogue,
  selectedMusicLicenseIds,
  selectedColorIds,
  selectedSubscriptionTemplateIds,
  userWantsForeverProduct
) => {
  const availableProducts = productCatalogue.filter((product) => {
    const musicLicenseTest = selectedMusicLicenseIds.includes(product.musicLicense.id);
    const colorTest = selectedColorIds.includes(product.color.id);
    const subscriptionTemplateTest = selectedSubscriptionTemplateIds.includes(product.subscriptionTemplate.id);

    return colorTest && musicLicenseTest && subscriptionTemplateTest;
  });

  if (availableProducts.length === 0) {
    throw new Error(
      [
        `Did not find an available product for musicLicenseId`,
        `[${selectedMusicLicenseIds}], colorId [${selectedColorIds}] and`,
        `subscriptionTemplateId [${selectedSubscriptionTemplateIds}]`,
      ].join(' ')
    );
  }

  const specificProducts = availableProducts.filter(
    product => userWantsForeverProduct === 'either' || product.isForever === userWantsForeverProduct
  );

  if (specificProducts.length > 1 && userWantsForeverProduct !== 'either') {
    throw new Error(
      [
        `Found multiple [${specificProducts.length}] products for musicLicenseId`,
        `[${selectedMusicLicenseIds}], colorId [${selectedColorIds}] and`,
        `subscriptionTemplateId [${selectedSubscriptionTemplateIds}]`,
      ].join(' ')
    );
  }

  return specificProducts[0];
};
