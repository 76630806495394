import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { MusicListSelectionModal } from '../../../../../common';
import { ReactComponent as Settings } from '../../../../../assets/icons/settings.svg';
import { ReactComponent as Add } from '../../../../../assets/icons/add.svg';
import { ReactComponent as HamburgerMenu } from '../../../../../assets/icons/menu.svg';
import { CollectionSettingsModal, CollectionSongsModal } from '../modals';
import { CategoryTypes, CollectionTypes } from '../types';
import { useTunifyBlue } from '../tunifyBlueProvider';

const Container = styled.div`
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  padding: 0 0.5rem 0 0.8rem;
  display: grid;
  grid-template-columns: 1fr min-content min-content;
  align-items: center;
`;

const Title = styled.div``;

const Button = styled.button`
  cursor: pointer;
  padding: 1rem 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  background-color: transparent;
  border: none;

  & svg path {
    fill: ${({ theme }) => theme.dark300};
    transition: fill 250ms linear;
  }

  &:hover {
    & svg path {
      fill: ${({ theme }) => theme.waterBlue};
    }
  }
`;

const AddButton = styled(Button)`
  & svg path {
    fill: ${({ theme }) => theme.waterBlue};
  }
`;

const flows = ['MOOD', 'PLAYLIST'];

const Collection = ({ musicCollection, selectedChannelType }) => {
  const { t } = useTranslation();

  const { tunifyChannels } = useTunifyBlue();

  const [areSettingsOpen, setOpenSettings] = useState(false);
  const [areSongsOpen, setOpenSongs] = useState(false);
  const [isAddCollectionOpen, setIsAddCollectionOpen] = useState(false);

  const handleOpenSettings = useCallback(() => {
    setOpenSettings(true);
  }, []);

  const handleOpenSongs = useCallback(() => {
    setOpenSongs(true);
  }, []);

  const handleCloseSongs = useCallback(() => {
    setOpenSongs(false);
  }, []);

  const handleCloseSettings = useCallback(() => {
    setOpenSettings(false);
  }, []);

  const handleAddCollection = useCallback(() => {
    setIsAddCollectionOpen(true);
  }, []);

  const handleCloseCollection = useCallback(() => {
    setIsAddCollectionOpen(false);
  }, []);

  return (
    <Container>
      <Title>{musicCollection.title}</Title>
      {musicCollection.type.toUpperCase() === CollectionTypes.CONTEXT ? (
        <Button onClick={handleOpenSettings}>
          <Settings height="1rem" width="1rem" />
        </Button>
      ) : null}
      {musicCollection.type.toUpperCase() === CollectionTypes.PLAYLIST ? (
        <Button onClick={handleOpenSongs}>
          <HamburgerMenu height="0.7rem" width="1rem" />
        </Button>
      ) : null}
      {selectedChannelType === CategoryTypes.CUSTOM ? (
        <AddButton onClick={handleAddCollection}>
          <Add height="0.9rem" width="0.9rem" />
        </AddButton>
      ) : null}
      {areSettingsOpen ? (
        <CollectionSettingsModal
          isOpen={areSettingsOpen}
          musicCollection={musicCollection}
          onClose={handleCloseSettings}
          title={musicCollection.title}
        />
      ) : null}
      {areSongsOpen ? (
        <CollectionSongsModal
          isOpen={areSongsOpen}
          musicCollection={musicCollection}
          onClose={handleCloseSongs}
          title={musicCollection.title}
        />
      ) : null}
      {isAddCollectionOpen ? (
        <MusicListSelectionModal
          flows={flows}
          isOpen={isAddCollectionOpen}
          onClose={handleCloseCollection}
          title={t('musicManagement:tunifyBlue.channels.addChannel')}
          tunifyChannels={tunifyChannels}
        />
      ) : null}
    </Container>
  );
};

export default Collection;
