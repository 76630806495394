import { useCallback } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/react-hooks';

import { SelectablePlaylistButton } from '../../../shared';
import { useTunifyBlue } from '../tunifyBlueProvider';
import { CategoryTypes } from '../types';
import { CheckboxField } from '../../../../../common';
import { UPDATE_MUSIC_CHANNEL_VISIBILITY } from '../api';
import { useUpdateChannelCacheAfterUpdate } from '../cache';

const StyledSelectablePlaylistButton = styled(SelectablePlaylistButton)`
  padding: 0.85rem 0.5rem 0.85rem 3rem;

  &:after {
    width: 100%;
  }
`;

const MusicChannel = ({ musicChannel, channel }) => {
  const { dispatch, selectedChannel, musicProfile } = useTunifyBlue();

  const handleUpdateChannelCacheAfterUpdate = useUpdateChannelCacheAfterUpdate({
    musicProfileId: musicProfile.tunifyMusicProfileUserId,
    musicChannelGroupId: channel.id,
    musicChannelId: musicChannel.id,
  });

  const [updateMusicChannelVisibility] = useMutation(UPDATE_MUSIC_CHANNEL_VISIBILITY, {
    update: handleUpdateChannelCacheAfterUpdate,
  });

  const handleOpenChannel = useCallback(() => {
    dispatch({
      selectedChannelType: CategoryTypes.TUNIFY,
      selectedChannel: musicChannel,
    });
  }, [musicChannel, dispatch]);

  const handleClickCheckbox = useCallback((event) => event.stopPropagation(), []);

  const handleChangeCheckbox = useCallback(() => {
    updateMusicChannelVisibility({
      variables: {
        musicProfileId: musicProfile.tunifyMusicProfileUserId,
        musicChannelGroupId: channel.id,
        musicChannelId: musicChannel.id,
        visible: !musicChannel.properties.visible,
      },
    });
  }, [musicProfile, musicChannel, updateMusicChannelVisibility, channel]);

  return (
    <StyledSelectablePlaylistButton
      onClick={handleOpenChannel}
      selected={selectedChannel?.id === musicChannel.id}
      type="button"
      value={musicChannel.id}
    >
      <p>{musicChannel.name}</p>
      <CheckboxField
        onChange={handleChangeCheckbox}
        onClick={handleClickCheckbox}
        checked={musicChannel.properties.visible ?? false}
      />
    </StyledSelectablePlaylistButton>
  );
};

export default MusicChannel;
