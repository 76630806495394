import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import {
  ErrorMessage,
  Page,
  GoBackButton,
  StepOverview,
  StepCard,
} from '../../../common';
import OrganizationDetails from './organizationDetails';
import Footer from './footer';
import { usePurchaseSteps } from './usePurchaseSteps';
import { useAddonsBuy } from './addonsBuyContext';

const StyledPage = styled(Page)`
  display: grid;
  grid-auto-rows: min-content 1fr min-content;
  height: 100%;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    grid-template-rows: 1fr 8rem;
    grid-template-columns: 1fr 2fr;
  }
`;

const Left = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  justify-items: start;
  gap: 2rem;
  background-color: ${({ theme }) => theme.white};
  padding: 1.6rem;
  border: 1px solid ${({ theme }) => theme.stroke};

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    padding: 3.2rem;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    grid-area: 1 / 1 / span 2 / 1;
    overflow-y: auto;
    padding: 6.4rem 3.2rem 10rem 3.2rem;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    padding: 6.4rem 4.8rem 10rem 4.8rem;
  }
`;

const LeftWrapper = styled.div`
  width: 100%;
  display: grid;
`;

const Right = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  overflow-y: auto;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    padding: 1.6rem;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    padding: 3.2rem;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    grid-area: 1 / 2 / 1 / 2;
    padding: 7.2rem 3.2rem;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    padding: 7.2rem;
  }
`;

const Bottom = styled.div`
  min-height: 7rem;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background-color: ${({ theme }) => theme.dark500};
  z-index: 1;
  padding: 0 1.6rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    grid-area: 2 / 2 / 2 / 2;
    padding: 0 3.2rem;
  }
`;

const Title = styled.h1`
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1.4;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    font-size: 2.8rem;
  }
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-bottom: 1.6rem;
`;

const BuyPage = () => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const {
    goToNextStepError,
    canProceedToNextStep,
    goToNextStepAction,
    goToNextStepLoading,
    currentStep,
    hasPreviousStep,
    hasNextStep,
    goToPreviousStep,
    stepsInfo,
  } = usePurchaseSteps();
  const { availableAddons, handleSelectAddon } = useAddonsBuy();

  // This prevents rerenders that would otherwise result in infinite loops
  const dynamicHandleSelectAddonRef = useRef(handleSelectAddon);
  dynamicHandleSelectAddonRef.current = handleSelectAddon;

  useEffect(() => {
    if (currentStep === 0) {
      const addonToSelectId = searchParams.get('addonId');
      const addonToSelect =
        addonToSelectId &&
        availableAddons.find(
          (addon) => addon.productCategory.id === addonToSelectId
        );
      if (addonToSelect) {
        dynamicHandleSelectAddonRef.current(addonToSelect);
      }
    }
  }, [availableAddons, currentStep, searchParams]);

  return (
    <StyledPage title={t('subscriptions:buySubscription')}>
      <Left>
        <GoBackButton
          to="/add-ons"
          label={t('common:button.cancel')}
          title={t('common:button.title.cancel')}
        />
        <Title>{t('subscriptions:buySubscription')}</Title>
        <LeftWrapper>
          <OrganizationDetails />
          <StepOverview stepsInfo={stepsInfo} currentStep={currentStep} />
        </LeftWrapper>
      </Left>
      <Right>
        {goToNextStepError ? (
          <StyledErrorMessage error={goToNextStepError} />
        ) : null}
        <StepCard
          currentStep={currentStep + 1}
          totalSteps={stepsInfo.length}
          title={stepsInfo[currentStep]?.title}
          description={stepsInfo[currentStep]?.description}
        >
          {stepsInfo[currentStep].Component}
        </StepCard>
      </Right>
      <Bottom>
        <Footer
          canProceedToNextStep={canProceedToNextStep}
          goToNextStep={goToNextStepAction}
          goToNextStepLoading={goToNextStepLoading}
          goToPreviousStep={goToPreviousStep}
          hasNextStep={hasNextStep}
          hasPreviousStep={hasPreviousStep}
        />
      </Bottom>
    </StyledPage>
  );
};

export default BuyPage;
