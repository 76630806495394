import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  ErrorMessage,
  LoadingIndicator,
  useSessionStorage,
} from '../../../common';
import {
  PARTNER_MANAGER_ROLE_PERMISSIONS,
  SUPPORT_ADMIN_ROLE_PERMISSIONS,
  hasAllRequiredPermissions,
} from '../../../global/auth';
import { useAuth } from '../../../global/auth/newAuthProvider';
import { AddonsBuyContext } from './addonsBuyContext';
import {
  AddonsBuyAction,
  AddonsBuyReducer,
  initialAddonsBuyState,
} from './addonsBuyReducer';
import {
  ADD_ITEMS_TO_CART,
  GET_AVAILABLE_ADDONS,
  GET_AVAILABLE_PAID_ADDONS,
  GET_ORGANIZATION_DETAILS,
  GET_PAYMENT_OPTIONS,
  GET_ZONES_WITH_AN_ACTIVE_SUBSCRIPTION,
} from './api';
import { buildCartItems } from './utils';

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin-top: 2rem;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 2rem;
`;

const AddonsBuyProvider = ({ children }) => {
  const { organizationId, locationId, zoneId, currentStep } = useParams();
  const navigate = useNavigate();
  const { user: userInfo } = useAuth();

  const [impersonationId] = useSessionStorage('impersonationId');
  const isSupportAdmin = hasAllRequiredPermissions(
    userInfo,
    SUPPORT_ADMIN_ROLE_PERMISSIONS
  );
  const isPartnerManager = hasAllRequiredPermissions(
    userInfo,
    PARTNER_MANAGER_ROLE_PERMISSIONS
  );

  const [state, dispatch] = useReducer(AddonsBuyReducer, initialAddonsBuyState);

  useEffect(() => {
    if ((isSupportAdmin || isPartnerManager) && impersonationId) {
      if (currentStep > 1 && state.userInput.selectedAddons.length === 0) {
        // User navigated directly to a step through the URL without completing the necessary steps
        navigate(
          `/organizations/${organizationId}/locations/${locationId}/zones/${zoneId}/purchase/add-ons/0`,
          {
            replace: true,
          }
        );
      }
    } else if (
      Number(currentStep) !== 0 &&
      state.userInput.selectedAddons.length === 0
    ) {
      // User navigated directly to a step through the URL without completing the necessary steps
      navigate(
        `/organizations/${organizationId}/locations/${locationId}/zones/${zoneId}/purchase/add-ons/0`,
        {
          replace: true,
        }
      );
    }
  }, [
    currentStep,
    navigate,
    locationId,
    organizationId,
    zoneId,
    state.userInput.selectedAddons,
    impersonationId,
    isPartnerManager,
    isSupportAdmin,
  ]);

  const {
    loading: organizationDetailsLoading,
    error: organizationDetailsError,
    data: organizationDetailsData,
  } = useQuery(GET_ORGANIZATION_DETAILS, {
    variables: { organizationId, locationId, zoneId },
    // This is necessary because otherwise Apollo will "remember" the previous results
    fetchPolicy: 'network-only',
    onCompleted: (dataResult) => {
      dispatch({
        type: AddonsBuyAction.FETCHED_ORGANIZATION_DETAILS,
        payload: {
          organizationDetails: dataResult.organization,
          zone: dataResult.zone,
        },
      });
    },
  });

  // Addon catalogue for regular customers
  const [
    getAvailablePaidAddons,
    { loading: availablePaidAddonsLoading, error: availablePaidAddonsError },
  ] = useLazyQuery(GET_AVAILABLE_PAID_ADDONS, {
    variables: { organizationId },
    onCompleted: (data) => {
      dispatch({
        type: AddonsBuyAction.FETCHED_ADDON_CATALOGUE,
        payload: { addonCatalogue: data.availablePaidAddons },
      });
    },
  });

  // Addon catalogue for support (this allows to choose from free addons)
  const { loading: availableAddonsLoading, error: availableAddonsError } =
    useQuery(GET_AVAILABLE_ADDONS, {
      skip: !((isSupportAdmin || isPartnerManager) && impersonationId),
      variables: {
        organizationId,
        productCatalogue: state.selectedAddonCatalogue?.id,
      },
      onCompleted: (data) => {
        if (data) {
          // The onCompleted callback is executed before the query even fires,
          // must be an Apollo bug...
          dispatch({
            type: AddonsBuyAction.FETCHED_ADDON_CATALOGUE,
            payload: { addonCatalogue: data.availableAddons },
          });
        }
      },
    });

  useEffect(() => {
    if (!((isSupportAdmin || isPartnerManager) && impersonationId)) {
      // When the viewer has not the support role,
      // or the viewer is not impersonating someone,
      // always fetch the regular, paid addons catalogue in advance.
      getAvailablePaidAddons();
    }
  }, [
    getAvailablePaidAddons,
    isSupportAdmin,
    impersonationId,
    isPartnerManager,
  ]);

  const { loading: paymentOptionsLoading, error: paymentOptionsError } =
    useQuery(GET_PAYMENT_OPTIONS, {
      skip:
        !state.organizationDetails ||
        state.userInput.selectedAddons.length === 0 ||
        state.selectedAddonCatalogue?.id !== 'paid', // We only need the payment options if we selected the paid catalogue
      variables: {
        countryId: state.organizationDetails?.countryId,
        productIds: state.userInput.selectedAddons.map((addon) => addon.id),
      },
      onCompleted: (data) => {
        if (data) {
          // The onCompleted callback is executed before the query even fires,
          // must be an Apollo bug...
          dispatch({
            type: AddonsBuyAction.FETCHED_PAYMENT_OPTIONS,
            payload: { paymentOptions: data.paymentOptions },
          });
        }
      },
    });

  const { loading: availableZonesLoading, error: availableZonesError } =
    useQuery(GET_ZONES_WITH_AN_ACTIVE_SUBSCRIPTION, {
      onCompleted: (dataResult) => {
        const additionalZones = dataResult.zonesWithAnActiveSubscription.filter(
          (zone) => zone.id !== zoneId
        );
        dispatch({
          type: AddonsBuyAction.FETCHED_ADDITIONAL_ZONES,
          payload: { additionalZones },
        });
      },
    });

  // There is a bug with useMutation where loading is always false.
  const [addItemsToCartLoadingCount, setAddItemsToCartLoadingCount] = useState(0);
  const [
    addItemsToCart,
    { error: addItemsToCartError },
  ] = useMutation(ADD_ITEMS_TO_CART);

  const onSelectAddonCatalogue = useCallback((addonCatalogue) => {
    dispatch({
      type: AddonsBuyAction.SELECT_ADDON_CATALOGUE,
      payload: { addonCatalogue },
    });
  }, []);

  const handleSelectAddon = useCallback(
    (addon) => {
      if (
        state.userInput.selectedAddons.find(
          (selectedAddon) => selectedAddon.id === addon.id
        )
      ) {
        dispatch({
          type: AddonsBuyAction.DESELECT_ADDON,
          payload: { addon },
        });
      } else {
        dispatch({
          type: AddonsBuyAction.SELECT_ADDON,
          payload: { addon },
        });
      }
    },
    [state.userInput.selectedAddons]
  );

  const handleSelectZone = useCallback(
    (zone) => {
      if (
        state.userInput.selectedAdditionalZones.find(
          (selectedZone) => selectedZone.id === zone.id
        )
      ) {
        dispatch({
          type: AddonsBuyAction.DESELECT_ZONE,
          payload: { zone },
        });
      } else {
        dispatch({
          type: AddonsBuyAction.SELECT_ZONE,
          payload: { zone },
        });
      }
    },
    [state.userInput.selectedAdditionalZones]
  );

  const handleSelectAllZones = useCallback(() => {
    dispatch({
      type: AddonsBuyAction.SELECT_ALL_ZONES,
    });
  }, []);

  const handleDeselectAllZones = useCallback(() => {
    dispatch({
      type: AddonsBuyAction.DESELECT_ALL_ZONES,
    });
  }, []);

  const handleSelectPaymentOption = useCallback((paymentOption) => {
    dispatch({
      type: AddonsBuyAction.SELECT_PAYMENT_OPTION,
      payload: { paymentOption },
    });
  }, []);

  const buildSaleOrder = useCallback(() => {
    if (state.saleOrderNeedsToBeFetched) {
      const currentZone = organizationDetailsData.zone;
      const totalSelectedZones = [
        ...state.userInput.selectedAdditionalZones,
        currentZone,
      ];

      setAddItemsToCartLoadingCount(count => count + 1);
      addItemsToCart({
        variables: {
          organizationId,
          cartItems: buildCartItems(
            totalSelectedZones,
            state.userInput.selectedAddons,
            state.addonCatalogue
          ),
        },
      }).then(({ data }) => {
        dispatch({
          type: AddonsBuyAction.FETCHED_SALE_ORDER,
          payload: { saleOrder: data.addItemsToCart },
        });
      }).finally(() => {
        setAddItemsToCartLoadingCount(count => count - 1);
      });
    }
  }, [
    state.saleOrderNeedsToBeFetched,
    addItemsToCart,
    organizationId,
    organizationDetailsData,
    state.addonCatalogue,
    state.userInput,
  ]);

  const contextValue = useMemo(
    () => ({
      organizationDetails: state.organizationDetails,
      currentLocation: organizationDetailsData?.location,
      currentZone: organizationDetailsData?.zone,
      saleOrder: state.saleOrder,
      availableAddons: state.addonCatalogue,
      handleSelectAddon,
      selectedAddons: state.userInput.selectedAddons,
      availableZonesLoading,
      availableZonesError,
      handleSelectZone,
      selectedAdditionalZones: state.userInput.selectedAdditionalZones,
      availableAdditionalZones: state.availableAdditionalZones,
      handleDeselectAllZones,
      handleSelectAllZones,
      paymentOptionsLoading,
      paymentOptionsError,
      paymentOptions: state.paymentOptions,
      handleSelectPaymentOption,
      selectedPaymentOption: state.userInput.selectedPaymentOption,
      buildSaleOrder,
      saleOrderLoading: addItemsToCartLoadingCount > 0,
      saleOrderError: addItemsToCartError,
      addonCatalogueLoading: availableAddonsLoading,
      addonCatalogueError: availableAddonsError,
      handleSelectAddonCatalogue: onSelectAddonCatalogue,
      selectedAddonCatalogue: state.selectedAddonCatalogue,
    }),
    [
      state,
      handleSelectAddon,
      handleSelectZone,
      handleDeselectAllZones,
      handleSelectAllZones,
      paymentOptionsLoading,
      paymentOptionsError,
      handleSelectPaymentOption,
      availableZonesLoading,
      availableZonesError,
      buildSaleOrder,
      addItemsToCartError,
      addItemsToCartLoadingCount,
      organizationDetailsData,
      availableAddonsError,
      availableAddonsLoading,
      onSelectAddonCatalogue,
    ]
  );

  const isLoading = organizationDetailsLoading || availablePaidAddonsLoading;
  const hasError = organizationDetailsError || availablePaidAddonsError;

  const content = useMemo(() => {
    if ((isLoading || !state.organizationDetails) && !hasError)
      return <StyledLoadingIndicator />;
    if (hasError) return <StyledErrorMessage error={hasError} />;
    return children;
  }, [isLoading, hasError, children, state.organizationDetails]);

  return (
    <AddonsBuyContext.Provider value={contextValue}>
      {content}
    </AddonsBuyContext.Provider>
  );
};

export default AddonsBuyProvider;
