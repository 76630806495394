import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const CheckServiceWorkerUpdateReady = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.swUpdateReady) {
      window.swUpdateReady = false;
      window.location.reload();
    }
  }, [location]);

  return null;
};

export default CheckServiceWorkerUpdateReady;
