import { useCallback } from 'react';
import styled, { css } from 'styled-components';

import { capitalize, useToggle, CheckboxField } from '../../../../../common';
import { SelectablePlaylistButton } from '../../../shared';

import { useTunifyBlue } from '../tunifyBlueProvider';
import { CategoryTypes } from '../types';

const StyledSelectablePlaylistButton = styled(SelectablePlaylistButton)`
  padding: 0.85rem 0.5rem 0.85rem 1.5rem;

  &:after {
    width: 100%;
  }

  ${({ isOver, theme }) =>
    isOver &&
    css`
      background-color: ${theme.dark400};
    `}
`;

const Channel = ({ channel }) => {
  const { dispatch, selectedCategory } = useTunifyBlue();

  const [isSelected, toggleIsSelected] = useToggle(channel?.selected ?? false);

  const handleClickListItem = useCallback(() => {
    dispatch({
      selectedChannelType: CategoryTypes.CUSTOM,
      selectedChannel: channel,
    });
  }, [dispatch, channel]);

  const handleClickCheckbox = useCallback(
    (event) => event.stopPropagation(),
    []
  );

  return (
    <StyledSelectablePlaylistButton
      key={channel.id}
      onClick={handleClickListItem}
      selected={selectedCategory?.id === channel.id}
      type="button"
      value={channel.id}
    >
      <p>{capitalize(channel.title)}</p>
      <CheckboxField
        onChange={toggleIsSelected}
        onClick={handleClickCheckbox}
        checked={isSelected}
      />
    </StyledSelectablePlaylistButton>
  );
};

export default Channel;
