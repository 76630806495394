import { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import { ErrorMessage, CheckboxField } from '../../../../../common';
import PlaylistBody from './playlistBody';
import { useSpotifyImport } from '../../spotifyImportContext';

const Container = styled.div`
  margin-top: 3rem;
  display: grid;
  grid-template-rows: min-content 1fr;
  gap: 1.6rem;
`;

const StyledCheckboxField = styled(CheckboxField)`
  justify-self: end;
  padding-right: 1.8rem;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 2rem;
`;

const PlaylistTable = () => {
  const { t } = useTranslation();
  const {
    isAuthenticated,
    toggleAllPlaylistCheckBoxes,
    selectedPlaylists,
    playlists,
    hasMorePlaylists,
    loadMorePlaylists,
    playlistsLoadingMore,
    playlistStatusesError,
    playlistStatuses,
    playlistStatusesLoading,
  } = useSpotifyImport();


  const playlistStatusesByIds = useMemo(
    () =>
      playlistStatuses.reduce((acc, curr) => {
        acc[curr.playlistId] = curr;
        return acc;
      }, {}),
    [playlistStatuses]
  );

  // We do not take the disabled playlist rows in account
  const filteredPlaylists = useMemo(
    () =>
      playlists.filter(
        (playlist) =>
          playlistStatusesByIds[playlist.id] &&
          playlistStatusesByIds[playlist.id].status !== 'WAITING' &&
          playlistStatusesByIds[playlist.id].status !== 'BUSY'
      ),
    [playlistStatusesByIds, playlists]
  );

  const isChecked =
    playlists.length > 0 &&
    playlistStatuses.length > 0 &&
    selectedPlaylists.length === filteredPlaylists.length;

  const isDisabled = Boolean(playlistStatusesError) || playlistStatusesLoading;

  const [infiniteRef] = useInfiniteScroll({
    loading: playlistsLoadingMore,
    hasNextPage: hasMorePlaylists,
    onLoadMore: loadMorePlaylists,
    scrollContainer: 'parent',
  });

  return isAuthenticated ? (
    <>
      {playlistStatusesError ? (
        <StyledErrorMessage error={playlistStatusesError} />
      ) : null}
      <Container>
        {playlists.length > 0 ? (
          <StyledCheckboxField
            reverse
            label={t('integrations:spotify.import.selectAll')}
            disabled={isDisabled}
            onChange={toggleAllPlaylistCheckBoxes}
            checked={isChecked}
            color="waterBlue"
          />
        ) : null}
        <PlaylistBody
          ref={infiniteRef}
          playlistStatusesByIds={playlistStatusesByIds}
        />
      </Container>
    </>
  ) : null;
};

export default PlaylistTable;
