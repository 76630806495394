import gql from 'graphql-tag';

export const GET_ZONES = gql`
  query getOrganizationsWithZones {
    organizations {
      id
      name
      zones {
        id
        name
        location {
          id
          name
        }
        currentSubscription {
          id
          subscriptionLines {
            id
            product {
              id
              isAddon
              shortName
              name
            }
          }
        }
      }
    }
  }
`;

export const CREATE_CUSTOM_PLAYLIST = gql`
  mutation createCustomPlaylist($zoneId: ID!, $playlist: CustomPlaylistInput!) {
    createCustomPlaylist(zoneId: $zoneId, playlist: $playlist) {
      id
      title
      description
    }
  }
`;

export const EDIT_CUSTOM_PLAYLIST = gql`
  mutation editCustomPlaylist($zoneId: ID!, $playlist: CustomPlaylistInput!) {
    editCustomPlaylist(zoneId: $zoneId, playlist: $playlist)
  }
`;

export const GET_TUNIFY_CHANNEL_GROUPS = gql`
  query getTunifyChannelGroups($musicProfileId: ID!, $language: String!) {
    tunifyChannelGroups(musicProfileId: $musicProfileId, language: $language) {
      id
      name
      properties {
        visible
      }
      musicChannels {
        id
        name
        musicChannelGroupId
        properties {
          visible
        }
        musicCollections {
          id
          title
          selected
          type
        }
      }
    }
  }
`;

export const UPDATE_MUSIC_CHANNEL_GROUP_VISIBILITY = gql`
  mutation updateMusicChannelGroupVisibility(
    $musicProfileId: ID!
    $musicChannelGroupId: ID!
    $visible: Boolean!
    $childrenIds: [String!]
  ) {
    updateMusicChannelGroupVisibility(
      musicProfileId: $musicProfileId
      musicChannelGroupId: $musicChannelGroupId
      visible: $visible
      childrenIds: $childrenIds
    ) {
      visible
    }
  }
`;

export const UPDATE_MUSIC_CHANNEL_VISIBILITY = gql`
  mutation updateMusicChannelVisibility(
    $musicProfileId: ID!
    $musicChannelGroupId: ID!
    $musicChannelId: ID!
    $visible: Boolean!
  ) {
    updateMusicChannelVisibility(
      musicProfileId: $musicProfileId
      musicChannelGroupId: $musicChannelGroupId
      musicChannelId: $musicChannelId
      visible: $visible
    ) {
      visible
    }
  }
`;
