import gql from 'graphql-tag';

export const GET_ZONES = gql`
  query getZones {
    zones {
      id
      name
      location {
        id
        name
        organization {
          id
          name
        }
      }
      messageProfile {
        id
      }
    }
  }
`;

export const GET_MUSIC_REGIONS = gql`
  query getMusicRegions {
    musicRegions {
      id
      name
    }
  }
`;

export const CREATE_MUSIC_PROFILE = gql`
  mutation createMusicProfile($name: String!, $musicRegionId: ID!, $musicLicenseId: ID!) {
    createMusicProfile(name: $name, musicRegionId: $musicRegionId, musicLicenseId: $musicLicenseId) {
      id
      name
      brandId
      tunifyMusicProfileUserId
      externalRegionId
      musicLicenseId
    }
  }
`;

export const GET_ALL_PRODUCTS = gql`
  query getAllProducts {
    allProducts {
      id
      isForever
      shortName
      musicLicense {
        id
        name
        description
        selectionOrder
      }
    }
  }
`;

export const CHANGE_MUSIC_PROFILE_OF_ZONES = gql`
  mutation changeMusicProfileOfZones($entries: [ChangeMusicProfileOfZoneInputData!]!) {
    changeMusicProfileOfZones(entries: $entries) {
      myTunifyZoneId
      musicProfileId
    }
  }
`;
