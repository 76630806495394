import styled from 'styled-components';
import useClipboard from 'react-use-clipboard';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useSessionStorage } from '../../common/hooks';
import { BasicButton } from '../../common';
import config from '../../config';
import { ReactComponent as SmallCheckMarkIcon } from '../../assets/icons/smallCheckmark.svg';
import { ReactComponent as RawCopyIcon } from '../../assets/icons/copy.svg';

const { BASE_URL } = config;

const StyledBasicButton = styled(BasicButton)`
  padding: 1rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    display: none;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    display: grid;
  }
`;

const StyledSmallCheckMarkIcon = styled(SmallCheckMarkIcon)`
  width: 1.9rem;
  height: 1.4rem;

  & path {
    fill: ${({ theme }) => theme.white};
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    width: 1.7rem;
    height: 1.3rem;

    & path {
      fill: ${({ theme }) => theme.dark200};
    }
  }
`;

const CopyIcon = styled(RawCopyIcon)`
  width: 1.9rem;
  height: 1.9rem;

  & path {
    fill: ${({ theme }) => theme.white};
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    width: 1.7rem;
    height: 1.7rem;

    & path {
      fill: ${({ theme }) => theme.dark200};
    }
  }
`;

const ShareImpersonationLink = () => {
  const { t } = useTranslation();
  const [impersonationId] = useSessionStorage('impersonationId');
  const location = useLocation();
  const [isCopied, copyToClipboard] = useClipboard(
    `${BASE_URL}/impersonation/user/${impersonationId}?from=${location.pathname}`,
    {
      successDuration: 4000,
    }
  );

  return impersonationId ? (
    <StyledBasicButton
      onClick={copyToClipboard}
      title={t('global:shareImpersonationLinkTitle')}
    >
      {isCopied ? <StyledSmallCheckMarkIcon /> : <CopyIcon />}
    </StyledBasicButton>
  ) : null;
};

export default ShareImpersonationLink;
