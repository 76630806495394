import styled from 'styled-components';

import {
  HistoryContactResultsCard,
  HistorySubscriptionResultsCard,
} from './cards';
import { useCustomerSuccessHistory } from './historyContext';

const Grid = styled.div`
  display: grid;
  grid-template-rows: min-content;
  overflow-y: auto;
`;

const CustomerSuccessHistoryPage = () => {
  const { historyCategories, selectedHistoryCategory } =
    useCustomerSuccessHistory();
  return (
    <Grid>
      {selectedHistoryCategory === historyCategories[0].id && (
        <HistoryContactResultsCard />
      )}
      {selectedHistoryCategory === historyCategories[1].id && (
        <HistorySubscriptionResultsCard />
      )}
    </Grid>
  );
};

export default CustomerSuccessHistoryPage;
