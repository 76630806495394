import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';

import { NewPlayerCodeModal } from '../modals';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit.svg';
import { ReactComponent as RawPlayIcon } from '../../../../assets/icons/simplePlayControls/play.svg';
import { ReactComponent as ZoneLargeIcon } from '../../../../assets/icons/zoneLarge.svg';
import { ReactComponent as MusicNoteIcon } from '../../../../assets/icons/musicNote.svg';
import {
  Button,
  capitalize,
  useToggle,
  BasicButton,
  LoadingIndicator,
} from '../../../../common';
import theme from '../../../../global/style/theme';
import { GET_PLAYER_LINK, GET_LAST_PLAYED_SONGS } from '../../api';

const Container = styled.div`
  display: grid;
  grid-template-rows: min-content 1fr;
  gap: 1.4rem;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.white};
  padding: 1.8rem 1.4rem 1.4rem 1.4rem;
  color: ${({ theme }) => theme.dark600};
`;

const Top = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;
  gap: 1.6rem;
`;

const EditWrapper = styled.div`
  justify-self: end;
  grid-area: 1 / 3;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    justify-self: unset;
    grid-area: unset;
  }
`;

const EditButton = styled(BasicButton)`
  border-radius: 50%;
  padding: 1rem;
`;

const Bottom = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  align-items: center;
  gap: 1.2rem;
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.stroke};
  color: ${({ theme }) => theme.dark500};
  border-radius: 4px;
  padding: 1.4rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Name = styled.div`
  grid-column: span 2;
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: min-content 1fr;
  align-items: center;
  word-break: break-all;

  & p {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.4;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    grid-column: unset;
  }
`;

const PlayerCodeButtonWrapper = styled.div`
  justify-self: start;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    justify-self: end;
  }
`;

const PlayerCodeButton = styled(Button)`
  width: fit-content;
  color: ${({ theme }) => theme.dark400};
  border-color: ${({ theme }) => theme.stroke};

  &:hover {
    color: ${({ theme }) => theme.dark500};
  }
`;

const PlayerButtonWrapper = styled.div`
  justify-self: start;
`;

const PlayerButton = styled(Button)`
  height: 3.8rem;
  padding-left: 0.4rem;
  display: grid;
  grid-template-columns: min-content 1fr;
  border-width: 1px;
  align-content: center;
  white-space: nowrap;
  color: ${({ theme }) => theme.dark400};
  border-color: ${({ theme }) => theme.stroke};

  ${({ isLoading }) =>
    isLoading &&
    css`
      padding: 0 1.2rem;
    `}

  &:hover {
    border-width: 1px;
    color: ${({ theme }) => theme.dark500};
    border-color: ${({ theme }) => theme.dark300};
  }
`;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.waterBlue};
  margin-right: 1rem;
  min-height: 2.8rem;
  min-width: 2.8rem;
`;

const PlayIcon = styled(RawPlayIcon)`
  width: 1rem;
  height: 1rem;
  margin-left: 0.15rem;
`;

const LastSeenText = styled.p`
  font-size: 1.4rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.dark300};

  & > span {
    color: ${({ theme }) => theme.dark500};
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    grid-column: span 2;
  }
`;

const LastPlayedWrapper = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  gap: 1.6rem;
  border-radius: 0.4rem;
  border: 1px solid ${({ theme }) => theme.stroke};
  background-color: ${({ theme }) => theme.uiBackground};
  padding: 1.2rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    grid-column: span 2;
  }
`;

const LastPlayedInfo = styled.div`
  display: grid;
`;

const LastPlayedSongTitle = styled.p`
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LastPlayedArtist = styled.p`
  font-size: 1.4rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.dark300};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ZoneRow = ({
  zone,
  organizationId,
  locationId,
  zoneId,
  locationName,
  hasActiveSubscription,
  lastSeenDate,
  mappingExternalZoneIds,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showPopup, toggleShowPopup] = useToggle();

  const [getPlayerLink, { loading }] = useLazyQuery(GET_PLAYER_LINK, {
    variables: { zoneId: Number(zoneId) },
    onCompleted: ({ playerLink }) => {
      window.open(playerLink, '_blank', 'noopener noreferrer');
    },
    fetchPolicy: 'network-only',
  });

  const { data: dataLastPlayedSongs, loading: loadingLastPlayedSongs } =
    useQuery(GET_LAST_PLAYED_SONGS, {
      variables: {
        zoneIds: [
          mappingExternalZoneIds?.find(
            (externalZone) =>
              Number(externalZone.externalZoneId) === Number(zoneId)
          )?.zoneId,
        ],
      },
      skip:
        !zoneId ||
        !mappingExternalZoneIds ||
        !mappingExternalZoneIds?.find(
          (externalZone) =>
            Number(externalZone.externalZoneId) === Number(zoneId)
        )?.zoneId,
    });

  return (
    <Container>
      <Top>
        <Name>
          <ZoneLargeIcon /> <p>{zone.name}</p>
        </Name>
        <EditWrapper>
          <EditButton
            onClick={() =>
              navigate(
                `/organizations/${organizationId}/locations/${locationId}/zones/${zoneId}/edit`
              )
            }
            title={t('common:button.title.edit', {
              value: t('common:button.title.zone'),
            })}
          >
            <EditIcon width="1.6rem" height="1.6rem" color={theme.dark300} />
          </EditButton>
        </EditWrapper>
      </Top>
      <Bottom>
        <LastSeenText>
          {t('subscriptions:thLastOpened')}&nbsp;&nbsp;&nbsp;
          <span>{lastSeenDate ?? '-'}</span>
        </LastSeenText>
        <LastPlayedWrapper>
          <MusicNoteIcon width="3rem" height="3rem" />
          {loadingLastPlayedSongs ? (
            <LoadingIndicator size="medium" />
          ) : (
            <LastPlayedInfo>
              <LastPlayedSongTitle>
                {
                  dataLastPlayedSongs?.musicLastPlayedSongs
                    ?.lastPlayedSongs?.[0]?.songTitle
                }
              </LastPlayedSongTitle>
              <LastPlayedArtist>
                {dataLastPlayedSongs?.musicLastPlayedSongs?.lastPlayedSongs?.[0]
                  ?.songArtist ?? t('dashboard:noMusicPlayedYet')}
              </LastPlayedArtist>
            </LastPlayedInfo>
          )}
        </LastPlayedWrapper>
        {hasActiveSubscription ? (
          <PlayerButtonWrapper>
            <PlayerButton
              emphasis="outline"
              size="medium"
              title={capitalize(
                t('common:button.title.goTo', {
                  value: t('common:button.title.player'),
                })
              )}
              onClick={() => getPlayerLink()}
              isLoading={loading}
            >
              <Circle>
                <PlayIcon color={theme.waterBlue} />
              </Circle>
              {t('common:button.goToPlayer')}
            </PlayerButton>
          </PlayerButtonWrapper>
        ) : (
          <div />
        )}
        {zone.currentSubscription ? (
          <PlayerCodeButtonWrapper>
            <PlayerCodeButton
              size="small"
              emphasis="secondary"
              onClick={toggleShowPopup}
            >
              {t('common:button.playerCode')}
            </PlayerCodeButton>
          </PlayerCodeButtonWrapper>
        ) : (
          <div />
        )}
      </Bottom>
      {showPopup ? (
        <NewPlayerCodeModal
          isOpen={showPopup}
          locationName={locationName}
          toggle={toggleShowPopup}
          zone={zone}
        />
      ) : null}
    </Container>
  );
};

export default ZoneRow;
