import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { getTunifyProductGradient } from '../../../common';
import PieChart from './reportPieChart';
import { Card } from '../shared';
import { mapApiDataToChartData, mapTunifyColorData, mapTunifyApplicationData } from './utils';
import CustomBarChart from './customBarChart';

const Grid = styled.div`
  display: grid;
  gap: 1.6rem;
  grid-template-columns: 1fr;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.small}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const PieChartWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const List = styled.div`
  display: grid;
  gap: 2.4rem;
  grid-template-columns: 1fr;
  padding: 1rem 0;
`;

const ListItem = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: min-content 1fr;
`;

const ListIndex = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  & > span {
    font-size: 1.4rem;
    color: ${({ $highlightGradient, theme }) => ($highlightGradient ? theme.white : theme.dark400)};
  }

  ${({ $highlightGradient, theme }) =>
    !$highlightGradient &&
    css`
      border: 2px solid ${theme.stroke};
    `}

  background: ${({ theme, $highlightGradient }) =>
    $highlightGradient
      ? `linear-gradient(
            135deg,
            ${theme.gradient[$highlightGradient][0]} 0%,
            ${theme.gradient[$highlightGradient][1]} 100%
          )`
      : 'transparent'};
`;

const ListContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > h4 {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.2;
  }

  & > span {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.dark300};
  }

  & > div {
    display: flex;
    align-items: center;
    gap: 0.6rem;

    & > span {
      font-size: 1.2rem;
      color: ${({ theme }) => theme.dark300};
    }
  }
`;

const TinyDivider = styled.div`
  width: 1px;
  height: 80%;
  background-color: ${({ theme }) => theme.stroke};
`;

const Description = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.dark400};
  padding-top: 1rem;
`;

const PlayedHours = styled.p`
  font-size: 1.4rem;
  font-weight: 600;
  padding-top: 1rem;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.stroke};
  margin: 2.4rem 0;
`;

const MusicReport = ({ musicReportData }) => {
  const { t } = useTranslation();

  const musicMoods = useMemo(
    () => mapApiDataToChartData(musicReportData.musicMoodReport.musicMoods, 'moods', t),
    [musicReportData, t]
  );
  const musicGenres = useMemo(
    () => mapApiDataToChartData(musicReportData.musicGenreReport.musicGenres, 'genres', t),
    [musicReportData, t]
  );
  const musicDecades = useMemo(
    () => mapApiDataToChartData(musicReportData.musicDecadeReport.musicDecades),
    [musicReportData]
  );
  const musicColors = useMemo(
    () => mapTunifyColorData(musicReportData.musicColorReport.musicColors),
    [musicReportData]
  );
  const musicApplications = useMemo(
    () => mapTunifyApplicationData(musicReportData.musicApplicationReport.musicApplications),
    [musicReportData]
  );

  return (
    <Grid>
      <Card title={t('musicManagement:musicReport.musicMoods.title')}>
        {musicMoods.length === 0 ? (
          <Description>{t('musicManagement:musicReport.noData')}</Description>
        ) : (
          <PieChartWrapper>
            <PieChart data={musicMoods} />
          </PieChartWrapper>
        )}
      </Card>

      <Card title={t('musicManagement:musicReport.musicGenres.title')}>
        {musicGenres.length === 0 ? (
          <Description>{t('musicManagement:musicReport.noData')}</Description>
        ) : (
          <PieChartWrapper>
            <PieChart data={musicGenres} />
          </PieChartWrapper>
        )}
      </Card>

      <Card title={t('musicManagement:musicReport.musicDecades.title')}>
        {musicDecades.length === 0 ? (
          <Description>{t('musicManagement:musicReport.noData')}</Description>
        ) : (
          <PieChartWrapper>
            <PieChart data={musicDecades} />
          </PieChartWrapper>
        )}
      </Card>

      <Card
        highlightGradient={getTunifyProductGradient('green')}
        title={t('musicManagement:musicReport.musicAgendas.title')}
      >
        {musicReportData.musicAgendaReport.musicAgendas.length === 0 ? (
          <Description>{t('musicManagement:musicReport.noData')}</Description>
        ) : (
          <List>
            {musicReportData.musicAgendaReport.musicAgendas.map((musicAgenda, index) => (
              <ListItem key={musicAgenda.id}>
                <ListIndex $highlightGradient={index === 0 ? getTunifyProductGradient('green') : undefined}>
                  <span>{index + 1}</span>
                </ListIndex>
                <ListContent>
                  <h4>{musicAgenda.name}</h4>
                  <span>{t('glossary:playedAmountOfTimes', { value: musicAgenda.value })}</span>
                </ListContent>
              </ListItem>
            ))}
          </List>
        )}
      </Card>

      <Card
        highlightGradient={getTunifyProductGradient('blue')}
        title={t('musicManagement:musicReport.musicChannels.title')}
      >
        {musicReportData.musicChannelReport.musicChannels.length === 0 ? (
          <Description>{t('musicManagement:musicReport.noData')}</Description>
        ) : (
          <List>
            {musicReportData.musicChannelReport.musicChannels.map((musicChannel, index) => (
              <ListItem key={musicChannel.id}>
                <ListIndex $highlightGradient={index === 0 ? getTunifyProductGradient('blue') : undefined}>
                  <span>{index + 1}</span>
                </ListIndex>
                <ListContent>
                  <h4>{musicChannel.name}</h4>
                  <span>{t('glossary:playedAmountOfTimes', { value: musicChannel.value })}</span>
                </ListContent>
              </ListItem>
            ))}
          </List>
        )}
      </Card>

      <Card
        highlightGradient={getTunifyProductGradient('orange')}
        title={t('musicManagement:musicReport.musicPlaylists.title')}
      >
        {musicReportData.musicPlaylistReport.musicPlaylists.length === 0 ? (
          <Description>{t('musicManagement:musicReport.noData')}</Description>
        ) : (
          <List>
            {musicReportData.musicPlaylistReport.musicPlaylists.map((musicPlaylist, index) => (
              <ListItem key={musicPlaylist.id}>
                <ListIndex $highlightGradient={index === 0 ? getTunifyProductGradient('orange') : undefined}>
                  <span>{index + 1}</span>
                </ListIndex>
                <ListContent>
                  <h4>{musicPlaylist.name}</h4>
                  <span>{t('glossary:playedAmountOfTimes', { value: musicPlaylist.value })}</span>
                </ListContent>
              </ListItem>
            ))}
          </List>
        )}
      </Card>

      <Card title={t('musicManagement:musicReport.musicSongs.title')}>
        {musicReportData.musicSongReport.musicSongs.length === 0 ? (
          <Description>{t('musicManagement:musicReport.noData')}</Description>
        ) : (
          <List>
            {musicReportData.musicSongReport.musicSongs.map((musicSong, index) => (
              <ListItem key={musicSong.id}>
                <ListIndex>
                  <span>{index + 1}</span>
                </ListIndex>
                <ListContent>
                  <h4>{musicSong.songTitle ?? ''}</h4>
                  <div>
                    <span>{musicSong.songArtist ?? ''}</span>
                    <TinyDivider />
                    <span>{t('glossary:playedAmountOfTimes', { value: musicSong.value })}</span>
                  </div>
                </ListContent>
              </ListItem>
            ))}
          </List>
        )}
      </Card>

      <Card title={t('musicManagement:musicReport.musicArtists.title')}>
        {musicReportData.musicArtistReport.musicArtists.length === 0 ? (
          <Description>{t('musicManagement:musicReport.noData')}</Description>
        ) : (
          <List>
            {musicReportData.musicArtistReport.musicArtists.map((musicArtist, index) => (
              <ListItem key={musicArtist.id}>
                <ListIndex>
                  <span>{index + 1}</span>
                </ListIndex>
                <ListContent>
                  <h4>{musicArtist.name}</h4>
                  <span>{t('glossary:playedAmountOfTimes', { value: musicArtist.value })}</span>
                </ListContent>
              </ListItem>
            ))}
          </List>
        )}
      </Card>

      <Card title={t('musicManagement:musicReport.tunifyServices.title')}>
        {musicApplications.length === 0 ? (
          <Description>{t('musicManagement:musicReport.noData')}</Description>
        ) : (
          <>
            <PlayedHours>
              {t('musicManagement:musicReport.musicApplications.totalPlayedHours', {
                value: Math.round(
                  musicReportData.musicColorReport.musicColors.reduce((acc, curr) => acc + curr.playDuration, 0)
                ),
              })}
            </PlayedHours>
            <Divider />
            <CustomBarChart data={musicColors} />
            <Divider />
            <CustomBarChart data={musicApplications} />
          </>
        )}
      </Card>
    </Grid>
  );
};

export default MusicReport;
