import { useMemo, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { useToggle, LocationCard } from '../../../../common';
import Body from './tableBody';
import {
  GET_LAST_SEEN_PLAYER_DATES,
  GET_USER_IDS_FROM_EXTERNAL_ZONE_IDS,
} from '../../api';

const LocationTable = ({
  organization,
  locationsLength,
  location,
  allProducts,
  zones,
  expandAllZones,
}) => {
  const [isExpand, toggleIsExpand] = useToggle();

  const zonesOfLocation = useMemo(
    () => zones.filter(({ locationId }) => locationId === Number(location.id)),
    [zones, location]
  );

  const { data: dataNewZoneIds } = useQuery(
    GET_USER_IDS_FROM_EXTERNAL_ZONE_IDS,
    {
      variables: {
        zoneIds: zonesOfLocation.map((zone) => Number(zone.id)),
      },
      skip: !isExpand || zonesOfLocation.map((zone) => zone.id).length === 0,
    }
  );

  const { data } = useQuery(GET_LAST_SEEN_PLAYER_DATES, {
    variables: {
      zoneIds: dataNewZoneIds?.userIdsFromExternalZoneIds?.map((zone) =>
        Number(zone.zoneId)
      ),
    },
    skip:
      !isExpand ||
      !dataNewZoneIds?.userIdsFromExternalZoneIds ||
      dataNewZoneIds?.userIdsFromExternalZoneIds?.map((zone) => zone.zoneId)
        .length === 0,
  });

  useEffect(() => {
    if (expandAllZones && !isExpand) {
      toggleIsExpand();
    } else if (!expandAllZones && isExpand) {
      toggleIsExpand();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandAllZones]);

  // useEffect(() => {
  //   if (locationsLength <= 2 && !isExpand) {
  //     toggleIsExpand();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <LocationCard
      darkMode
      location={location}
      organization={organization}
      isOpen={isExpand}
      toggleLocationCard={toggleIsExpand}
    >
      <Body
        allProducts={allProducts}
        isExpand={isExpand}
        lastSeenPlayerDates={data?.lastSeenPlayerDates?.players}
        mappingExternalZoneIds={dataNewZoneIds?.userIdsFromExternalZoneIds}
        location={location}
        organization={organization}
        zones={zones}
      />
    </LocationCard>
  );
};

export default LocationTable;
