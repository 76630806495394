import { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../../oldButton';
import { SelectablePlaylistButton } from '../../../../../modules/musicManagement/shared';
import { ReactComponent as ArrowRight } from '../../../../../assets/icons/arrowRight.svg';
import theme from '../../../../../global/style/theme';

const Back = styled(Button)`
  background-color: transparent;
  color: ${({ theme }) => theme.dark300};
  display: flex;
  align-items: center;
  text-transform: none;
  padding: 0;

  &:hover,
  &:focus {
    & path {
      fill: ${({ theme }) => theme.dark300};
    }
  }

  & p {
    font-size: 0.95rem;
    font-weight: 400;
    padding-left: 0.3rem;
  }
`;

const Body = styled.div`
  border-top: 0.15rem solid ${({ theme }) => theme.stroke};
  margin-bottom: 2rem;
`;

const StyledSelectablePlaylistButton = styled(SelectablePlaylistButton)`
  padding: 1rem 0.8rem 1rem 1rem;

  &:after {
    width: 100%;
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${({ theme }) => theme.babyBlue};
      & p {
        font-weight: 800;
        color: ${({ theme }) => theme.white};
      }
    `}
`;

const ArrowLeft = styled(ArrowRight)`
  transform: rotate(180deg);
`;

const ChannelStep = ({
  setChannelId,
  channelId,
  goBack,
  next,
  isSubChannel,
  setSubChannelId,
  subChannelId,
  setCollectionId,
  isCollection,
  collectionId,
  tunifyChannels,
}) => {
  const { t } = useTranslation();

  const handleClickChannel = useCallback(
    (event) => {
      if (isSubChannel) {
        setSubChannelId(event?.currentTarget?.value);
      } else if (isCollection) {
        setCollectionId(event?.currentTarget?.value);
      } else {
        setChannelId(event?.currentTarget?.value);
      }
      next();
    },
    [
      setChannelId,
      next,
      setSubChannelId,
      isSubChannel,
      setCollectionId,
      isCollection,
    ]
  );

  const channels = useMemo(() => {
    if (isSubChannel) {
      return tunifyChannels.find((channel) => channel.id === channelId)
        ?.musicChannels;
    }
    if (isCollection) {
      return tunifyChannels
        .find((channel) => channel.id === channelId)
        .musicChannels.find((channel) => channel.id === subChannelId)
        ?.musicCollections;
    }
    return tunifyChannels;
  }, [tunifyChannels, isSubChannel, channelId, isCollection, subChannelId]);

  return (
    <>
      <p>{t('musicManagement:tunifyBlue.addCollection.infoChannel')}</p>
      <Back emphasis="primary" flavor="icon" onClick={goBack} type="button">
        <ArrowLeft color={theme.dark300} height="0.7rem" width="0.7rem" />
        <p>{t('common:button.back')}</p>
      </Back>
      <Body>
        {channels.map((channel) =>
          typeof channel?.properties !== 'object' ||
          channel?.properties?.visible ? (
            <StyledSelectablePlaylistButton
              key={channel.id}
              onClick={handleClickChannel}
              selected={collectionId === channel.id}
              value={channel.id}
            >
              <p>{channel?.name ?? channel?.title}</p>
              {!isCollection ? (
                <ArrowRight
                  color={theme.dark300}
                  height="0.7rem"
                  width="0.7rem"
                />
              ) : null}
            </StyledSelectablePlaylistButton>
          ) : null
        )}
      </Body>
    </>
  );
};

export default ChannelStep;
