import { useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useLocation, useParams } from 'react-router-dom';

import { SelectCard, LoadingIndicator } from '../../../../common';
import { usePurchase } from '../purchaseContext';

const Label = styled.h2`
  font-size: 1.8rem;
  line-height: 1.5;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SubLabel = styled.div`
  font-size: 1.4rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.dark300};
`;

const Options = styled.div`
  display: grid;
  gap: 1.2rem;
`;

const MusicLicenseStep = ({ goToNextStep, goToPreviousStep }) => {
  const {
    availableMusicLicenses,
    handleSelectMusicLicense,
    isLoading,
    userInput: { selectedMusicLicense },
  } = usePurchase();
  const location = useLocation();
  const { currentStep } = useParams();

  useEffect(() => {
    if (!selectedMusicLicense) {
      if (availableMusicLicenses.length > 0) {
        const initialSelectedMusicLicense = availableMusicLicenses.reduce((acc, curr) =>
          acc.selectionOrder < curr.selectionOrder ? acc : curr
        );
        handleSelectMusicLicense(initialSelectedMusicLicense);
      }
    }
    if (availableMusicLicenses.length === 1) {
      const previousStep = location.state.from;
      if (currentStep > previousStep) {
        goToNextStep();
      } else {
        goToPreviousStep();
      }
    }
  }, [
    availableMusicLicenses,
    handleSelectMusicLicense,
    selectedMusicLicense,
    goToNextStep,
    goToPreviousStep,
    currentStep,
    location,
  ]);

  const handleSelect = useCallback(
    (id) => {
      if (id !== selectedMusicLicense?.id) {
        const musicLicense = availableMusicLicenses.find((ml) => ml.id === id);
        handleSelectMusicLicense(musicLicense);
      }
    },
    [selectedMusicLicense, handleSelectMusicLicense, availableMusicLicenses]
  );

  const selectOptions = useMemo(
    () =>
      availableMusicLicenses.map((musicLicense) => (
        <SelectCard
          key={musicLicense.id}
          activeSelectCardId={selectedMusicLicense?.id}
          id={musicLicense.id}
          onClick={handleSelect}
          title={<Label>{musicLicense.name}</Label>}
        >
          <SubLabel>{musicLicense.description}</SubLabel>
        </SelectCard>
      )),
    [availableMusicLicenses, handleSelect, selectedMusicLicense]
  );

  return isLoading ? <LoadingIndicator /> : <Options>{selectOptions}</Options>;
};

export default MusicLicenseStep;
