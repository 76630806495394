import { useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { CheckboxCard, formatNumber, LinkButton } from '../../../../../common';
import { ReactComponent as InfoIcon } from '../../../../../assets/icons/smallInfo.svg';

const SubLabel = styled.div`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.dark300};
  line-height: 1.4;

  & > span {
    font-weight: 600;
    color: ${({ theme }) => theme.dark500};
  }
`;

const Label = styled.h2`
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ExtraInfo = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  gap: 1.2rem;
`;

const Wrapper = styled.div`
  display: grid;
  gap: 1.2rem;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const AddonCard = ({ addon, handleSelectAddon, isSelected }) => {
  const { t, i18n } = useTranslation();

  const onSelectAddon = useCallback(() => {
    handleSelectAddon(addon);
  }, [addon, handleSelectAddon]);

  return (
    <CheckboxCard isSelected={isSelected} onClick={onSelectAddon} title={<Label>{addon.shortName}</Label>}>
      <Wrapper>
        <SubLabel>
          <span>€ {formatNumber(addon.price, i18n.language)}</span> / {t('glossary:month')} {t('glossary:exclusiveVat')}
        </SubLabel>
        <ExtraInfo>
          <InfoIcon />
          <LinkButton emphasis="secondary" size="medium" href={addon.productCategory.whatIsThisLink}>
            {t('glossary:whatIsThis')}
          </LinkButton>
        </ExtraInfo>
      </Wrapper>
    </CheckboxCard>
  );
};

export default AddonCard;
