import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Page, InfoCard } from '../../../common';
import QualifiersTable from './qualifiersTable';
import WinnersTable from './winnersTable';
import PartnerProvider from '../partnerProvider';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, min-content);
  grid-gap: 2rem;
  padding: 0.8rem;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    padding: 1.6rem 2.4rem;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    padding: 1.6rem 4.6rem;
  }
`;

const StyledInfoCard = styled(InfoCard)`
  max-width: unset;
`;

const PartnerRegistrationsPage = () => {
  const { t } = useTranslation();

  return (
    <PartnerProvider>
      <Page title={t('partner:registrations.title')}>
        <Grid>
          <StyledInfoCard title={t('partner:qualifiers')} withToggle>
            <QualifiersTable />
          </StyledInfoCard>
          <StyledInfoCard title={t('partner:winners')} withToggle>
            <WinnersTable />
          </StyledInfoCard>
        </Grid>
      </Page>
    </PartnerProvider>
  );
};

export default PartnerRegistrationsPage;
