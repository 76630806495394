import gql from 'graphql-tag';

const GET_ORGANIZATION_DETAILS = gql`
  query getOrganizationDetails(
    $organizationId: ID!
    $locationId: ID!
    $zoneId: ID!
  ) {
    vatCountryCodes
    countries {
      id
      name
      code
    }
    organization(id: $organizationId) {
      id
      name
      city
      zip
      streetName
      streetNumber
      vat
      countryId
      extraAddressInfo
      invoiceEmail
      ref
      zones {
        id
        name
        location {
          id
        }
        currentSubscription {
          id
          endDate
          subscriptionLines {
            id
            product {
              id
              name
              isAddon
              musicLicense {
                id
                name
              }
              color {
                id
                name
              }
              subscriptionTemplate {
                id
                name
                recurringInterval
                recurringRuleType
                selectionOrder
                showOrder
                isPrepaid
              }
            }
          }
        }
        lastSubscription {
          id
          endDate
        }
      }
      locations {
        id
        name
      }
      activeSubscriptions {
        id
        zoneId
      }
    }
    location(id: $locationId) {
      id
      name
      streetName
      streetNumber
      zip
      city
      phone
      googlePlaceCode
    }
    zone(id: $zoneId) {
      id
      name
      location {
        id
      }
      currentSubscription {
        id
        subscriptionLines {
          id
          product {
            id
            isAddon
            shortName
            name
          }
        }
      }
      lastSubscription {
        id
        endDate
        subscriptionLines {
          id
          product {
            id
            name
            isAddon
            shortName
          }
        }
      }
    }
  }
`;

const GET_AVAILABLE_PAID_PRODUCTS = gql`
  query getAvailablePaidProducts($organizationId: ID!) {
    organization(id: $organizationId) {
      id
      availablePaidProducts {
        id
        price
        shortName
        subscriptionTemplate {
          id
          name
          recurringInterval
          recurringRuleType
          selectionOrder
          showOrder
          isPrepaid
        }
        color {
          id
          name
          description
          features
          selectionOrder
          description
        }
        musicLicense {
          id
          name
          description
          selectionOrder
        }
        isForever
      }
    }
  }
`;

const GET_AVAILABLE_PRODUCTS = gql`
  query getAvailableProducts($organizationId: ID!, $productCatalogue: String!) {
    availableProducts(
      organizationId: $organizationId
      productCatalogue: $productCatalogue
    ) {
      id
      price
      shortName
      subscriptionTemplate {
        id
        name
        recurringInterval
        recurringRuleType
        selectionOrder
        showOrder
        isPrepaid
      }
      color {
        id
        name
        description
        features
        selectionOrder
        description
      }
      musicLicense {
        id
        name
        description
        selectionOrder
      }
      isForever
    }
  }
`;

const EDIT_ORGANIZATION = gql`
  mutation editOrganization(
    $organizationId: ID!
    $organizationDetails: EditOrganizationInput!
  ) {
    editOrganization(
      organizationId: $organizationId
      organization: $organizationDetails
    ) {
      id
      name
      streetName
      streetNumber
      zip
      city
      countryId
      vat
      extraAddressInfo
      invoiceEmail
      ref
    }
  }
`;

const GET_PAYMENT_OPTIONS = gql`
  query getPaymentOptions($productIds: [ID!]!, $countryId: ID!) {
    paymentOptions(productIds: $productIds, countryId: $countryId) {
      id
      name
      image
      pm
      brand
      paymentAcquirerId
      isWireTransfer
      supportsRecurringBilling
    }
  }
`;

const ADD_ITEMS_TO_CART = gql`
  mutation addItemsToCart($organizationId: ID!, $cartItems: [CartItemInput!]!) {
    addItemsToCart(organizationId: $organizationId, cartItems: $cartItems) {
      id
      amountUntaxed
      amountTax
      amountTotal
      saleOrderLines {
        priceUnit
        cartLineId
      }
    }
  }
`;

const SWITCH_CART_ITEMS = gql`
  mutation switchCartItems(
    $organizationId: ID!
    $cartItemIdsToRemove: [String!]!
    $cartItemsToAdd: [CartItemInput!]!
    $cartId: ID!
  ) {
    removeItemsFromCart(
      organizationId: $organizationId
      cartItemIds: $cartItemIdsToRemove
      cartId: $cartId
    ) {
      id
    }
    addItemsToCart(
      organizationId: $organizationId
      cartItems: $cartItemsToAdd
      cartId: $cartId
    ) {
      id
      amountUntaxed
      amountTax
      amountTotal
      saleOrderLines {
        priceUnit
        cartLineId
        zone {
          id
        }
      }
    }
  }
`;

const REMOVE_ITEMS_FROM_CART = gql`
  mutation removeItemsFromCart(
    $organizationId: ID!
    $cartItemIds: [String!]!
    $cartId: ID!
  ) {
    removeItemsFromCart(
      organizationId: $organizationId
      cartItemIds: $cartItemIds
      cartId: $cartId
    ) {
      id
      amountUntaxed
      amountTax
      amountTotal
      saleOrderLines {
        priceUnit
        cartLineId
      }
    }
  }
`;

const PREPARE_PAYMENT = gql`
  mutation preparePayment(
    $ingenicoParams: NewPaymentInput!
    $saleOrderId: Int!
    $paymentAcquirerId: Int!
    $paymentIconId: Int!
  ) {
    preparePayment(
      ingenicoParams: $ingenicoParams
      saleOrderId: $saleOrderId
      paymentAcquirerId: $paymentAcquirerId
      paymentIconId: $paymentIconId
    ) {
      id
      reference
      amount
      currency
      wireTransferReference
      iban
      bic
      ingenicoShaIn
      ogonePspid
      ogoneAlias
      ogoneAliasUsage
      ownerCountryCode
    }
  }
`;

const CHECK_VAT = gql`
  mutation checkVat($countryCode: String!, $vatNumber: String!) {
    isVatNumberValidForCountryCode(
      countryCode: $countryCode
      vatNumber: $vatNumber
    )
  }
`;

const CONFIRM_SALE_ORDER_WITHOUT_PAYMENT = gql`
  mutation confirmSaleOrderWithoutPayment($saleOrderId: ID!) {
    confirmSaleOrderWithoutPayment(saleOrderId: $saleOrderId)
  }
`;

const GET_AVAILABLE_PAID_ADDONS = gql`
  query getAvailablePaidAddons($organizationId: ID!) {
    availablePaidAddons(organizationId: $organizationId) {
      id
      shortName
      price
      saleOk
      subscriptionTemplate {
        id
        recurringInterval
      }
      productCategory {
        id
        whatIsThisLink
        features
      }
    }
  }
`;

const GET_AVAILABLE_ADDONS = gql`
  query getAvailableAddons($organizationId: ID!, $productCatalogue: String!) {
    availableAddons(
      organizationId: $organizationId
      productCatalogue: $productCatalogue
    ) {
      id
      shortName
      price
      saleOk
      subscriptionTemplate {
        id
        recurringInterval
      }
      productCategory {
        id
        whatIsThisLink
        features
      }
    }
  }
`;

export {
  GET_ORGANIZATION_DETAILS,
  GET_AVAILABLE_PAID_PRODUCTS,
  CHECK_VAT,
  EDIT_ORGANIZATION,
  PREPARE_PAYMENT,
  REMOVE_ITEMS_FROM_CART,
  ADD_ITEMS_TO_CART,
  GET_PAYMENT_OPTIONS,
  SWITCH_CART_ITEMS,
  GET_AVAILABLE_PRODUCTS,
  CONFIRM_SALE_ORDER_WITHOUT_PAYMENT,
  GET_AVAILABLE_PAID_ADDONS,
  GET_AVAILABLE_ADDONS,
};
