import { Component } from 'react';

import ErrorBody from './errorBody';
import ErrorBodyRoot from './errorBodyRoot';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (window.swUpdateReady) {
      window.swUpdateReady = false;
      window.location.reload();
    } else {
      // You can also log error messages to an error reporting service here
      console.error('[RENDER ERROR]: Error while rendering', error, errorInfo);
    }
  }

  render() {
    if (this.state.hasError) {
      // Error path
      if (this.props.root) {
        return <ErrorBodyRoot className={this.props.className} />;
      }
      return <ErrorBody className={this.props.className} />;
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
