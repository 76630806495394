import { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { CheckboxField } from '../../../../../../common';
import { Slider } from '../../../../shared';
import { useTunifyBlue } from '../../tunifyBlueProvider';

const CheckboxWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1.5rem;
  column-gap: 1rem;
  padding: 1rem 0;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const LineWrapper = styled.div`
  padding: 0.5rem 0;
  border-bottom: 0.1rem solid ${({ theme }) => theme.stroke};
`;

const contextTypes = {
  ChangeableIntervalParameter: 'ChangeableIntervalParameter',
  ChangeableSelectionParameter: 'ChangeableSelectionParameter',
};

const selectionTypes = {
  TEXT_CHECKBOX_SELECTION: 'TEXT_CHECKBOX_SELECTION',
  TEXT_SLIDER_RANGE_SELECTION: 'TEXT_SLIDER_RANGE_SELECTION',
};

const MemoizedCheckbox = ({
  value,
  disable,
  onChange,
  checkboxIndex,
  selected,
}) => {
  const handleChangeCheckbox = useCallback(() => {
    onChange(checkboxIndex);
  }, [checkboxIndex, onChange]);

  return (
    <CheckboxField
      disable={disable}
      label={value}
      onChange={handleChangeCheckbox}
      checked={selected}
    />
  );
};

const ChangeableParameter = ({ changeableParameter, index, disable }) => {
  const { changeableParameters, dispatch } = useTunifyBlue();

  const handleChangeSlider = useCallback(
    (value) => {
      // Need a deep clone, otherwise state does not trigger changes
      const newParams = JSON.parse(JSON.stringify(changeableParameters));
      newParams[index] = {
        ...newParams[index],
        lowerSelection: value[0],
        upperSelection: value[1],
      };
      dispatch({
        changeableParameters: newParams,
      });
    },
    [changeableParameters, index, dispatch]
  );

  const handleChangeCheckboxSlider = useCallback(
    ([leftPosition, rightPosition]) => {
      // Need a deep clone, otherwise state does not trigger changes
      const newParams = JSON.parse(JSON.stringify(changeableParameters));
      const min = leftPosition;
      const diff = rightPosition - leftPosition;
      const selectedArray = new Array(diff)
        .fill(1)
        .map((_, currentPosition) => min + currentPosition);
      newParams[index].value = newParams[index].value.map((prevValue, i) => ({
        value: prevValue.value,
        selected: selectedArray.includes(i),
      }));
      dispatch({
        changeableParameters: newParams,
      });
    },
    [changeableParameters, index, dispatch]
  );

  const handleChangeCheckbox = useCallback(
    (checkboxIndex) => {
      // Need a deep clone, otherwise state does not trigger changes
      const newParams = JSON.parse(JSON.stringify(changeableParameters));
      newParams[index].value = newParams[index].value.map((prevValue, i) => ({
        value: prevValue.value,
        selected:
          i === checkboxIndex ? !prevValue.selected : prevValue.selected,
      }));
      dispatch({
        changeableParameters: newParams,
      });
    },
    [changeableParameters, index, dispatch]
  );

  const thumLabels = useMemo(
    () =>
      changeableParameter.type === contextTypes.ChangeableIntervalParameter &&
      changeableParameter.minimum > 7
        ? [
            `${changeableParameter.lowerSelection} ${changeableParameter.metadataDisplayMinimum}`,
            `${changeableParameter.upperSelection} ${changeableParameter.metadataDisplayMaximum}`,
          ]
        : undefined,
    [changeableParameter]
  );

  const sectionLabels = useMemo(
    () =>
      changeableParameter.type === contextTypes.ChangeableSelectionParameter &&
      changeableParameter.metadataDisplayType ===
        selectionTypes.TEXT_SLIDER_RANGE_SELECTION
        ? changeableParameter.value.map(({ value }) => value)
        : undefined,
    [changeableParameter]
  );

  const sliderValue = useMemo(
    () =>
      changeableParameter.type === contextTypes.ChangeableIntervalParameter
        ? [
            changeableParameter.lowerSelection,
            changeableParameter.upperSelection,
          ]
        : undefined,
    [changeableParameter]
  );

  const sliderLabels = useMemo(
    () =>
      changeableParameter.type === contextTypes.ChangeableIntervalParameter &&
      changeableParameter.minimum <= 7
        ? [
            changeableParameter.metadataDisplayMinimum,
            changeableParameter.name,
            changeableParameter.metadataDisplayMaximum,
          ]
        : undefined,
    [changeableParameter]
  );

  const marks = useMemo(
    () =>
      changeableParameter.type === contextTypes.ChangeableIntervalParameter &&
      changeableParameter.minimum <= 7
        ? new Array(
            changeableParameter.maximum - changeableParameter.minimum - 1
          )
            .fill(1)
            .map((_, i) => changeableParameter.minimum + i + 1)
        : undefined,
    [changeableParameter]
  );

  const valueOfSelectionSlider = useMemo(() => {
    if (
      changeableParameter.type === contextTypes.ChangeableSelectionParameter &&
      changeableParameter.metadataDisplayType ===
        selectionTypes.TEXT_SLIDER_RANGE_SELECTION
    ) {
      const firstSelected = changeableParameter.value.findIndex(
        ({ selected }) => selected === true
      );
      const lastSelected =
        changeableParameter.value
          ?.map(({ selected }) => selected)
          .lastIndexOf(true) + 1;
      return [firstSelected, lastSelected];
    }
    return null;
  }, [changeableParameter]);

  if (changeableParameter.type === contextTypes.ChangeableIntervalParameter) {
    return (
      <LineWrapper>
        <Slider
          disable={disable}
          marks={marks}
          max={changeableParameter.maximum}
          min={changeableParameter.minimum}
          onChange={handleChangeSlider}
          sliderLabels={sliderLabels}
          step={changeableParameter.step}
          thumbLabels={thumLabels}
          value={sliderValue}
        />
      </LineWrapper>
    );
  }

  if (changeableParameter.type === contextTypes.ChangeableSelectionParameter) {
    if (
      changeableParameter.metadataDisplayType ===
      selectionTypes.TEXT_SLIDER_RANGE_SELECTION
    ) {
      return (
        <LineWrapper>
          <Slider
            disable={disable}
            max={changeableParameter.value.length}
            minDistance={1}
            onChange={handleChangeCheckboxSlider}
            sectionLabels={sectionLabels}
            value={valueOfSelectionSlider}
          />
        </LineWrapper>
      );
    }

    if (
      changeableParameter.metadataDisplayType ===
      selectionTypes.TEXT_CHECKBOX_SELECTION
    ) {
      return (
        <LineWrapper>
          <CheckboxWrapper>
            {changeableParameter.value.map(
              ({ value, selected }, checkboxIndex) => (
                <MemoizedCheckbox
                  key={value}
                  checkboxIndex={checkboxIndex}
                  disable={disable}
                  onChange={handleChangeCheckbox}
                  selected={selected}
                  value={value}
                />
              )
            )}
          </CheckboxWrapper>
        </LineWrapper>
      );
    }
  }

  return null;
};

export default ChangeableParameter;
