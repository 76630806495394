import { createTimeString } from '../../utils/date';

const transformOpeningPeriod = (openingPeriod) => {
  const { open, close } = openingPeriod;
  return {
    open: createTimeString(open),
    close: createTimeString(close),
  };
};

const transformOpeningPeriods = (openingType, openingPeriods) => {
  if (openingType === 'closed' || openingType === 'open24h') {
    return null;
  }

  return openingPeriods.map(transformOpeningPeriod);
};

const determineOpeningType = (openingPeriods) => {
  const isClosed = openingPeriods.every(
    ({ open, close }) => open === null && close === null
  );

  if (isClosed) {
    return 'closed';
  }

  const isopen24h = openingPeriods.every(
    ({ open, close }) =>
      createTimeString(open) === '0000' && createTimeString(close) === '0000'
  );

  if (isopen24h) {
    return 'open24h';
  }

  return 'withOpeningPeriods';
};

const transformOpeningDay = (openingDay) => {
  const { day, openingPeriods } = openingDay;

  const openingType = determineOpeningType(openingPeriods);

  const filteredOpeningPeriods = openingPeriods.filter(
    (period) =>
      openingType === 'withOpeningPeriods' &&
      period.open !== null &&
      period.close !== null
  );

  return {
    day,
    openingType,
    openingPeriods: transformOpeningPeriods(
      openingType,
      filteredOpeningPeriods
    ),
  };
};

export const transformFormResult = (openingHours) => {
  return {
    googleSynced: openingHours.googleSynced,
    openingDays: openingHours.openingDays.map(transformOpeningDay),
  };
};
