import gql from 'graphql-tag';

const GET_COUNTRIES_AND_INDUSTRIES = gql`
  query getCountriesAndIndustries {
    countries {
      id
      name
      code
    }
    industries {
      id
      name
    }
  }
`;

const SEARCH_CUSTOMERS = gql`
  query searchCustomers($pagination: PaginationArgs!, $order: [OrderArgs!]!, $searchPackage: SearchPackageArgs!) {
    searchCustomers(pagination: $pagination, order: $order, searchPackage: $searchPackage) {
      organization {
        id
        name
      }
      location {
        id
        name
        city
      }
      contact {
        id
        name
        phone
        type
      }
      action {
        id
        name
        date
      }
    }
  }
`;

export { GET_COUNTRIES_AND_INDUSTRIES, SEARCH_CUSTOMERS };
