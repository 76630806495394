import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Page } from '../../../common';
import ReferredCustomersTable from './referredCustomersTable';
import PartnerProvider from '../partnerProvider';

const StyledPage = styled(Page)`
  padding: 0.8rem;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    padding: 1.6rem 2.4rem;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    padding: 1.6rem 4.6rem;
  }
`;

const Container = styled.div`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.stroke};
  opacity: 1;
  background-color: ${({ theme }) => theme.white};
`;

const PartnerReferredCustomersPage = () => {
  const { t } = useTranslation();

  return (
    <PartnerProvider>
      <StyledPage title={t('partner:referredCustomers.title')}>
        <Container>
          <ReferredCustomersTable />
        </Container>
      </StyledPage>
    </PartnerProvider>
  );
};

export default PartnerReferredCustomersPage;
