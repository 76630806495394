import {
  differenceInMinutes,
  formatDistanceToNow as formatDistanceToNowFns,
} from 'date-fns';
import { de, enUS, nlBE, fr } from 'date-fns/locale';

import { parseDateString } from './parseDateString';

const languagesObject = {
  'nl-be': nlBE,
  'nl-nl': nlBE,
  'en-us': enUS,
  'fr-be': fr,
  'fr-fr': fr,
  'de-de': de,
};

export const formatDate = (
  dateToFormat,
  locale,
  providedOptions = {
    showTime: false,
    outputFormat: { day: '2-digit', month: '2-digit', year: 'numeric' },
  }
) => {
  if (dateToFormat === '') {
    return '-';
  }

  let options;

  if (providedOptions.showTime) {
    options = {
      ...providedOptions.outputFormat,
      hour: 'numeric',
      minute: 'numeric',
    };
  } else {
    options = { ...providedOptions.outputFormat };
  }
  // dateToFormat can be either a date string or a Date object
  const date =
    typeof dateToFormat === 'string'
      ? parseDateString(dateToFormat)
      : dateToFormat;

  const formattedDate = new Intl.DateTimeFormat(locale, options).format(date);

  return formattedDate;
};

export const formatDayOfWeek = (dateToFormat, locale) => {
  if (!dateToFormat) {
    return '-';
  }

  const formattedDate = new Intl.DateTimeFormat(locale, {
    weekday: 'long',
  }).format(dateToFormat);

  return formattedDate;
};

export const formatDistanceToNow = (dateToFormat, t, locale) => {
  if (dateToFormat === '') {
    return '-';
  }

  // dateToFormat can be either a date string or a Date object
  const date =
    typeof dateToFormat === 'string'
      ? parseDateString(dateToFormat)
      : dateToFormat;

  const minutes = differenceInMinutes(new Date(), date);

  if (minutes < 5) {
    return t('common:timeDistance.nowActive');
  }

  return formatDistanceToNowFns(date, {
    locale: languagesObject[locale.toLowerCase()],
  });
};
