/**
 * All possible roles provided by Auth0
 *
 * @see {@link https://manage.auth0.com/dashboard/eu/my-tunify/roles}
 */

export const OWNER_ROLE_PERMISSIONS = [
  'cancel:subscription_renewal',
  'check:vat',
  'create:cart',
  'create:location',
  'create:organization',
  'create:payment',
  'create:zone',
  'create:zone_code',
  'delete:device',
  'delete:location',
  'delete:organization',
  'delete:subscription',
  'delete:user',
  'delete:zone',
  'edit:cart',
  'edit:location',
  'edit:zone',
  'edit:organization',
  'edit:subscription',
  'edit:user',
  'reactivate:subscription_renewal',
  'read:color',
  'read:country',
  'read:device',
  'read:invoice',
  'read:language',
  'read:location',
  'read:music_license',
  'read:music_region',
  'read:organization',
  'read:payment',
  'read:payment_option',
  'read:product',
  'read:sale_order',
  'read:subscription',
  'read:subscription_template',
  'read:user',
  'read:zone',
  'read:zone_code',
  'read:time_zone',
  'couple:zone',
  'apply:referral_reward',
  'read:customer_code',
  'import:spotify_playlist',
  'read:spotify_playlist_status',
  'create:broadcast',
  'create:message_profile',
  'edit:message_profile',
  'read:message_profile',
  'delete:message_profile',
  'delete:broadcast',
  'edit:broadcast',
  'read:broadcast',
  'read:file',
  'read:player_link',
  'upload:file',
  'read:music_management',
  'edit:music_management',
  'create:music_management',
  'delete:music_management',
];

export const SUPPORT_ROLE_PERMISSIONS = [
  'impersonate',
  'search:user',
  'read:contact_category',
  'read:contact_channel',
  'read:contact_call',
  'create:contact_call',
  'edit:contact_call',
  'create:lead',
  'read:location_of_user',
  'read:organization_of_user',
];

export const ACCOUNTANT_ROLE_PERMISSIONS = [
  'edit:user',
  'read:organization',
  'read:invoice',
  'read:language',
  'read:user',
];

export const SUPPORT_ADMIN_ROLE_PERMISSIONS = [
  'impersonate',
  'delete:contact_call',
  'create:contact_call',
  'edit:contact_call',
  'read:contact_call',
  'read:contact_category',
  'read:contact_channel',
  'create:lead',
  'read:free_product',
  'confirm:sale_order_without_payment',
  'read:location_of_user',
  'read:organization_of_user',
  'search:user',
];

export const PARTNER_ROLE_PERMISSIONS = ['read:partner_program', 'read:partner_commission'];

export const PARTNER_MANAGER_ROLE_PERMISSIONS = [
  'edit:role',
  'read:role',
  'read:partner_program',
  'read:partner_organization',
  'read:free_product',
  'confirm:sale_order_without_payment',
];

export const EXPERIMENTAL_ROLE_PERMISSIONS = ['use:experimental'];
