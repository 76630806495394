import gql from 'graphql-tag';

const GET_PARTNERS = gql`
  query getPartners($offset: Int!, $limit: Int!) {
    partners(offset: $offset, limit: $limit) {
      id
      ownerId
      name
      nextCommissionDate
      winnerCustomersCount: customersCount(filter: "winner")
      qualifierCustomersCount: customersCount(filter: "qualifier")
      referredCustomersCount: customersCount(filter: "referred")
    }
  }
`;

const GET_COMMISSION_INVOICES = gql`
  query getCommissionInvoices($offset: Int!, $limit: Int!) {
    commissionInvoices(offset: $offset, limit: $limit) {
      id
      createDate
      invoiceDate
      number
      totalCommission
      state
      organization {
        id
        name
      }
    }
  }
`;

const GET_COMMISSION_INVOICE_PDF = gql`
  query getCommissionInvoicePdf($commissionInvoiceId: ID!) {
    commissionInvoicePdf(commissionInvoiceId: $commissionInvoiceId)
  }
`;

export { GET_PARTNERS, GET_COMMISSION_INVOICES, GET_COMMISSION_INVOICE_PDF };
