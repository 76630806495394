import { useMemo, useCallback, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CaretIcon } from '../../../assets/icons/angleDown.svg';

import { useOnClickOutside, LoadingIndicator, useToggle } from '../..';
import ErrorMessage from '../common/errorMessage';

import Label from '../common/label';

const PRIMARY = '#BFC5D2';
const PRIMARY_ACTIVE = '#4A75BB';
const PRIMARY_HOVER = '#4A75BB';

const InputWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
`;

const Container = styled.div`
  font-family: 'Inter', sans-serif;
  cursor: pointer;
  font-size: 0.875rem;
  height: 2.5rem;
  line-height: 1.2rem;
  padding-bottom: 0;
  padding-top: 0;
  position: relative;

  width: 100%;
  &:invalid {
    box-shadow: none;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      background-color: ${({ theme }) => theme.white};
      color: ${({ theme }) => theme.stroke};
      border-color: ${({ theme }) => theme.stroke};
      &:hover {
        border-color: ${({ theme }) => theme.stroke};
      }
    `}
`;

const Button = styled.button`
  font-family: 'Inter', sans-serif;
  border-radius: 2px;
  border: 1px solid ${PRIMARY};
  color: #363734;
  word-break: break-word;
  text-align: left;
  background: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  display: flex;
  justify-content: space-between;
  transition: background-color 0.333s ease-in-out,
    border-color 0.333s ease-in-out;
  &:hover,
  &:focus,
  &:active {
    border: 1px solid ${PRIMARY_ACTIVE};
    outline: none;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      background-color: ${({ theme }) => theme.white};
      color: ${({ theme }) => theme.stroke};
      border-color: ${({ theme }) => theme.stroke};
      &:hover {
        border-color: ${({ theme }) => theme.stroke};
      }
    `}
`;

const Body = styled.div`
  position: absolute;
  z-index: 10; /* Make sure the body is placed above other elements */
  width: 100%;
  margin-top: 1px;
  min-width: 15rem;
  background: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.waterBlue};
  max-height: 15rem;
  overflow-y: scroll;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
`;

const Option = styled.button`
  width: 100%;
  text-align: left;
  transition: background-color 100ms ease-in-out, color 250ms ease-in-out;
  display: block;
  border: none;
  background: none;
  cursor: pointer;
  padding: 1rem;
  color: ${({ theme }) => theme.berryBlue};

  &:not(:last-of-type) {
  }

  &:hover {
    color: ${PRIMARY_HOVER};
    background-color: ${({ theme }) => theme.uiBackground};
  }

  &:focus {
    outline: none;
    background-color: ${({ theme }) => theme.waterBlue};
    color: ${({ theme }) => theme.white};
  }
`;

const Empty = styled.div`
  padding: 1rem;
  cursor: default;
`;

const Placeholder = styled.span`
  font-family: 'Inter', sans-serif;
  transition: color 0.333s ease-in-out;
  color: ${({ disabled, theme }) => (disabled ? theme.dark200 : theme.dark300)};
  font-style: italic;
`;

const StyledCaretIcon = styled(CaretIcon)`
  min-width: 1rem;
  height: 0.5rem;
  margin-left: 1rem;
  & path {
    transition: fill 0.333s ease-in-out;
    fill: ${({ $isOpen, disabled, theme }) => {
      if ($isOpen) return theme.waterBlue;
      if (disabled) return theme.stroke;
      return theme.dark300;
    }};
  }
`;

const SelectedOption = styled.span`
  transition: color 0.333s ease-in-out;
  color: ${({ disabled, theme }) => (disabled ? theme.dark200 : theme.dark600)};
`;

const Dropdown = ({
  options = [],
  onChange,
  currentOptionId,
  className,
  label,
  placeholder,
  isLoading = false,
  disabled,
  error,
}) => {
  const [isOpen, toggleIsOpen] = useToggle(false);
  const dropdownRef = useRef(null);
  const { t } = useTranslation();

  const handleOnChange = useCallback(
    (id) => {
      onChange(id);
      toggleIsOpen();
    },
    [onChange, toggleIsOpen]
  );

  const onClickOutside = useCallback(() => {
    toggleIsOpen(false);
  }, [toggleIsOpen]);

  const selectedOption = useMemo(
    () =>
      !isLoading &&
      options &&
      currentOptionId &&
      options.find(({ id }) => id === currentOptionId)?.name,
    [currentOptionId, isLoading, options]
  );

  const renderOptions = useMemo(() => {
    if (!isLoading) {
      const availableOptions = options.filter(
        ({ id }) => id !== currentOptionId
      );
      if (availableOptions.length > 0) {
        return availableOptions.map((option) => (
          <Option key={option.id} onClick={() => handleOnChange(option.id)}>
            {option.name}
          </Option>
        ));
      }
      return <Empty> {t('glossary:noOptionsAvailable')}</Empty>;
    }
  }, [currentOptionId, handleOnChange, isLoading, options, t]);

  useOnClickOutside(dropdownRef, onClickOutside);

  return (
    <InputWrapper className={className}>
      {label && <Label disabled={disabled}>{label}</Label>}
      <Container ref={dropdownRef} disabled={disabled || isLoading}>
        <Button
          disabled={disabled || isLoading}
          onClick={toggleIsOpen}
          type="button"
        >
          {(selectedOption || selectedOption === 0) && !isLoading ? (
            (
              <SelectedOption disabled={disabled}>
                {selectedOption}
              </SelectedOption>
            ) || <Placeholder disabled={disabled}>{placeholder}</Placeholder>
          ) : (
            <Placeholder disabled={disabled}>{placeholder}</Placeholder>
          )}
          {isLoading ? (
            <LoadingIndicator color="primary" size="small" thickness="thin" />
          ) : (
            <StyledCaretIcon $isOpen={isOpen} disabled={disabled} />
          )}
        </Button>
        {isOpen && <Body>{renderOptions}</Body>}
      </Container>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </InputWrapper>
  );
};

export default Dropdown;
