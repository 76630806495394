import { determineSubCreatePolicyForProductCartItem } from './determineSubCreatePolicyForProductCartItem';
import { getEquivalentOfAddon } from './getEquivalentOfAddon';
import { determineAvailableAddonCategoriesForZone } from '../../../../common';

const createProductCartItem = (zone, selectedProduct) => {
  return {
    locationId: Number(zone.location.id),
    zoneId: Number(zone.id),
    productId: Number(selectedProduct.id),
    productQuantity: 1,
    ...determineSubCreatePolicyForProductCartItem(zone),
  };
};

const createAddonCartItems = (
  zone,
  subscriptionTemplateId,
  selectedAddons,
  availableAddons,
  skipAddonFiltering
) => {
  const availableAddonCategories = determineAvailableAddonCategoriesForZone(
    zone,
    selectedAddons,
    skipAddonFiltering
  );

  const addonCartItems = availableAddonCategories.map((addonCategory) => {
    const addon = getEquivalentOfAddon(
      addonCategory,
      availableAddons,
      subscriptionTemplateId
    );

    return {
      locationId: Number(zone.location.id),
      zoneId: Number(zone.id),
      productId: Number(addon.id),
      productQuantity: 1,
    };
  });

  return addonCartItems;
};

export const buildCartItems = (
  selectedZones,
  selectedProduct,
  selectedAddons,
  availableAddons,
  skipAddonFiltering
) => {
  const cartItems = [];

  selectedZones.forEach((selectedZone) => {
    const productCartItem = createProductCartItem(
      selectedZone,
      selectedProduct
    );
    cartItems.push(productCartItem);
    const addonCartItems = createAddonCartItems(
      selectedZone,
      selectedProduct.subscriptionTemplate.id,
      selectedAddons,
      availableAddons,
      skipAddonFiltering
    );
    if (addonCartItems.length > 0) {
      cartItems.push(...addonCartItems);
    }
  });

  return cartItems;
};
