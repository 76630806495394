import { useMemo, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useOnClickOutside, useToggle } from '../../../../common';
import { ReactComponent as CaretIcon } from '../../../../assets/icons/angleDown.svg';
import { ReactComponent as RawMusicManagementIcon } from '../../../../assets/icons/navigation/equalizer.svg';
import { DropdownOptionTypes } from '../types';
import ProfileOption from './profileOption';
import ZoneOption from './zoneOption';

const Container = styled.div`
  padding-bottom: 0.5rem;
  border-bottom: 2px solid ${({ theme }) => theme.dark200};
  position: relative;
`;

const Button = styled.button`
  word-break: break-word;
  text-align: left;
  border: none;
  background: none;
  cursor: ${({ hasOptions }) => (hasOptions ? 'pointer' : 'default')};
  padding: 0;
  display: flex;
  align-items: center;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    font-size: 2rem;
  }
`;

const InnerDropdownContainer = styled.div`
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
  min-height: fit-content;
  max-height: 25rem;
  margin-left: -5px;
  margin-top: 1rem;
  min-width: 20rem;
  overflow: auto;
  padding: 0 0.2rem;
  position: absolute;
  width: 103%;
  z-index: 1000;
`;

const OrganizationIcon = styled(RawMusicManagementIcon)`
  min-width: 2rem;
  width: 2rem;
  margin-right: 1rem;
`;

const StyledCaretIcon = styled(CaretIcon)`
  margin-left: 1rem;
  width: 1rem;
  height: 1rem;
  & path {
    fill: ${({ $isOpen, theme }) =>
      $isOpen ? theme.waterBlue : theme.dark300};
  }
`;

const InnerDropdownGroupLabel = styled.p`
  font-size: 0.8rem;
  font-weight: 500;
  color: ${({ theme }) => theme.dark300};
  padding: 0 1rem;
  text-transform: uppercase;
`;

const EmptyLabel = styled.p`
  padding: 0 1rem;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  & p {
    margin: 0.2rem;
  }
`;

const LabelDetails = styled.p`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.dark300};
`;

const Dropdown = ({
  options,
  onChange,
  currentProfile,
  zonesWithoutProfile,
  profileType,
}) => {
  const { t } = useTranslation();
  const [isOpen, toggleIsOpen] = useToggle(false);
  const dropdownRef = useRef(null);

  const onClickOutside = useCallback(() => {
    if (isOpen) toggleIsOpen();
  }, [isOpen, toggleIsOpen]);

  useOnClickOutside(dropdownRef, onClickOutside);

  const label = useMemo(
    () =>
      [...options, ...zonesWithoutProfile].find(
        ({ id }) => id === currentProfile?.id
      )?.name,
    [currentProfile?.id, options, zonesWithoutProfile]
  );

  const handleOnChange = useCallback(
    (id, type) => {
      onChange(id, type);
      toggleIsOpen();
    },
    [onChange, toggleIsOpen]
  );

  const renderProfileOptions = useMemo(() => {
    return options
      .filter(({ id }) => id !== currentProfile?.id)
      .map((profile) => (
        <ProfileOption
          key={profile?.id}
          handleOnChange={handleOnChange}
          profile={profile}
        />
      ));
  }, [currentProfile?.id, handleOnChange, options]);

  const renderZoneOptions = useMemo(() => {
    return zonesWithoutProfile
      ?.filter(({ id }) => id !== currentProfile?.id)
      .map((zone) => (
        <ZoneOption
          key={zone?.id}
          handleOnChange={handleOnChange}
          zone={zone}
        />
      ));
  }, [currentProfile?.id, handleOnChange, zonesWithoutProfile]);

  const hasOptions = useMemo(
    () => renderProfileOptions?.length > 0 && renderZoneOptions?.length > 0,
    [renderProfileOptions?.length, renderZoneOptions?.length]
  );

  const DropdownIcon = useMemo(() => {
    if (profileType === DropdownOptionTypes.ZONE) return null;
    return <OrganizationIcon />;
  }, [profileType]);

  return (
    <Container ref={dropdownRef}>
      <Button hasOptions={hasOptions} onClick={toggleIsOpen} type="button">
        {DropdownIcon}
        <LabelContainer>
          <p>{label}</p>
          {currentProfile?.location && currentProfile.location.organization && (
            <LabelDetails>
              {[
                currentProfile.location.organization.name,
                currentProfile?.location.name,
              ].join(' | ')}
            </LabelDetails>
          )}
        </LabelContainer>
        {hasOptions && <StyledCaretIcon $isOpen={isOpen} />}
      </Button>
      {isOpen && (
        <InnerDropdownContainer>
          {options?.some(({ id }) => id !== currentProfile?.id) ? (
            renderProfileOptions
          ) : (
            <EmptyLabel>
              {t('musicManagement:dropdown.noProfilesAvailable')}
            </EmptyLabel>
          )}
          <InnerDropdownGroupLabel>
            {t('musicManagement:dropdown.zonesWithoutProfile')}
          </InnerDropdownGroupLabel>
          {zonesWithoutProfile?.some(({ id }) => id !== currentProfile?.id) ? (
            renderZoneOptions
          ) : (
            <EmptyLabel>
              {t('musicManagement:dropdown.noZonesAvailable')}
            </EmptyLabel>
          )}
        </InnerDropdownContainer>
      )}
    </Container>
  );
};

export default Dropdown;
