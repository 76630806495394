import { useMemo, useReducer, useCallback } from 'react';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import styled from 'styled-components';

import { normalizeSearchValues } from './utils';
import { LoadingIndicator, ErrorMessage } from '../../../common';
import { CustomerSuccessSearchContext } from './searchContext';
import { GET_COUNTRIES_AND_INDUSTRIES, SEARCH_CUSTOMERS } from './api';
import {
  searchReducer,
  initialSearchState,
  Action,
  CUSTOMER_SEARCH_LIMIT,
  DEFAULT_SORT_VALUE,
  DEFAULT_SORT_ORDER,
} from './searchReducer';

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin-top: 3rem;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 3rem;
`;

const CustomerSuccessSearchProvider = ({ children }) => {
  const [state, dispatch] = useReducer(searchReducer, initialSearchState);

  const { loading, error } = useQuery(GET_COUNTRIES_AND_INDUSTRIES, {
    onCompleted: (dataResult) => {
      dispatch({ type: Action.INITIAL_QUERY_COMPLETED, payload: dataResult });
    },
    fetchPolicy: 'cache-first',
  });

  const [
    searchCustomers,
    {
      loading: searchCustomersLoading,
      error: searchCustomersError,
      called: searchCustomersCalled,
    },
  ] = useLazyQuery(SEARCH_CUSTOMERS, {
    onCompleted: (dataResult) => {
      dispatch({
        type: Action.NEW_SEARCH_RESULTS,
        payload: dataResult.searchCustomers,
      });
    },
    fetchPolicy: 'network-only',
  });

  const handleSearchCustomers = useCallback(
    (values) => {
      const normalizedSearchParams = normalizeSearchValues(values);
      dispatch({ type: Action.SEARCH, payload: normalizedSearchParams });
      const searchParams = {
        searchPackage: normalizedSearchParams,
        pagination: { offset: 0, limit: CUSTOMER_SEARCH_LIMIT },
        order: [{ value: DEFAULT_SORT_VALUE, order: DEFAULT_SORT_ORDER }],
      };
      searchCustomers({
        variables: {
          pagination: searchParams.pagination,
          searchPackage: searchParams.searchPackage,
          order: searchParams.order,
        },
      });
    },
    [searchCustomers]
  );

  const handleLoadMoreCustomers = useCallback(() => {
    if (!searchCustomersCalled) return;
    if (searchCustomersError) return; // Don't refetch when an error occured

    searchCustomers({
      variables: {
        ...state.searchParams,
        pagination: {
          ...state.searchParams.pagination,
          offset: state.searchParams.pagination.offset,
        },
      },
    });
  }, [searchCustomers, state, searchCustomersCalled, searchCustomersError]);

  const handleChangeOrder = useCallback(
    (orderKey) => {
      if (!searchCustomersCalled) return;

      const currentOrder = state.searchParams.order[0];
      let newOrder;
      if (currentOrder.value === orderKey) {
        newOrder = [
          {
            value: orderKey,
            order: currentOrder.order === 'ASC' ? 'DESC' : 'ASC',
          },
        ];
      } else {
        newOrder = [{ value: orderKey, order: 'ASC' }];
      }

      dispatch({ type: Action.CHANGE_ORDER, payload: newOrder });
      searchCustomers({
        variables: {
          ...state.searchParams,
          pagination: { offset: 0, limit: CUSTOMER_SEARCH_LIMIT },
          order: newOrder,
        },
      });
    },
    [state, searchCustomers, searchCustomersCalled]
  );

  const content = useMemo(() => {
    if (loading) return <StyledLoadingIndicator />;
    if (error) return <StyledErrorMessage error={error} />;
    return children;
  }, [children, loading, error]);

  const contextValue = useMemo(
    () => ({
      state,
      searchCustomers: handleSearchCustomers,
      loadMoreCustomers: handleLoadMoreCustomers,
      changeOrder: handleChangeOrder,
      searchCustomersCalled,
      searchCustomersLoading,
      searchCustomersError,
    }),
    [
      state,
      handleSearchCustomers,
      handleChangeOrder,
      searchCustomersError,
      searchCustomersLoading,
      searchCustomersCalled,
      handleLoadMoreCustomers,
    ]
  );

  return (
    <CustomerSuccessSearchContext.Provider value={contextValue}>
      {content}
    </CustomerSuccessSearchContext.Provider>
  );
};

export default CustomerSuccessSearchProvider;
