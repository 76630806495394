import { useState, useCallback, useMemo, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import {
  DndContext,
  PointerSensor,
  KeyboardSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { useMutation } from '@apollo/react-hooks';
import { motion, AnimatePresence } from 'framer-motion';

import { useMediaSize, LoadingIndicator, StringField } from '../../../common';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrowRight.svg';
import { ReactComponent as CrossIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as InfoBasicIcon } from '../../../assets/icons/infoBasic.svg';
import { ReactComponent as ProfileIcon } from '../../../assets/icons/profile.svg';
import { ReactComponent as MusicNoteIcon } from '../../../assets/icons/musicNoteLarge.svg';
import { CustomPlaylists } from './customPlaylists';
import { TunifyLists } from './tunifyLists';
import PlaylistDetail from './playlistDetail';
import { ListButton } from '../shared';
import { usePlaylists } from './playlistsProvider';
import {
  Tabs,
  DroppableTypes,
  PlaylistModalModes,
  PlaylistTypes,
} from './types';
import { customCollisionDetectionStrategy } from './utilities';
import { SAVE_CUSTOM_PLAYLIST } from './api';
import MusicCollection from './tunifyLists/musicCollection';
import theme from '../../../global/style/theme';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
  background-color: ${({ theme }) => theme.white};

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    grid-template-columns: 26rem 1fr;
  }
`;

const Left = styled.div`
  width: 100%;
  max-height: calc(100vh - 10.8rem);
  display: grid;
  grid-auto-rows: min-content;
  background-color: ${({ theme }) => theme.white};
  padding: 1rem;
  overflow-x: hidden;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    max-height: calc(100vh - 6.6rem);
    padding: 1.6rem;
  }
`;

const SideWrapper = styled.div`
  display: grid;
  grid-template-columns: 100% 100%;
  overflow: hidden;
`;

const ListContainer = styled(motion.div)`
  width: 100%;
  display: grid;
`;

const ListContainerLeft = styled(ListContainer)`
  height: fit-content;
`;

const ListContainerRight = styled(ListContainer)`
  grid-template-rows: min-content 1fr;
`;

const Right = styled.div`
  width: 100%;
  height: 100%;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    padding: 1.6rem 1.6rem 1.6rem 0;
  }
`;

const RightBox = styled.div`
  width: 100%;
  height: 100%;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.stroke};
    background-color: ${({ theme }) => theme.uiBackground};
  }
`;

const SearchField = styled.div`
  position: relative;
  &:hover {
    & svg {
      color: ${({ theme }) => theme.waterBlue};
    }
  }
`;

const StyledStringField = styled(StringField)`
  width: 100%;

  & input {
    height: 4.6rem;
    border-radius: 2.5rem;

    &:hover,
    &:focus {
      border-color: ${({ theme }) => theme.waterBlue};
    }
  }
`;

const SearchPositionWrapper = styled.div`
  ${({ $showSearchOptions }) =>
    $showSearchOptions
      ? css`
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 10;
          background-color: ${({ theme }) => theme.white};
          padding: 1.6rem;
        `
      : ''}
`;

const SearchWrapper = styled.div`
  outline: none;
  padding: 1rem 1rem 0.6rem 1rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    padding: 0;
  }
`;

const SearchOptions = styled.div`
  width: 100%;
  display: grid;
  gap: 0.6rem;
  padding-top: 1.6rem;
`;

const StyledListButton = styled(ListButton)`
  border: 2px solid ${({ theme }) => theme.stroke};
  transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out;
  grid-template-columns: 1fr;
  border-radius: 8px;
  padding: 1.2rem;
  color: ${({ theme }) => theme.dark400};
  grid-template-columns: min-content 1fr;

  &:hover {
    border-color: ${({ theme }) => theme.dark600};
    color: ${({ theme }) => theme.dark600};
  }

  ${({ focused }) =>
    focused &&
    css`
      border-color: ${({ theme }) => theme.dark500};
    `}
`;

const iconCss = css`
  width: 1.8rem;
  height: 1.8rem;
  position: absolute;
  top: 1.3rem;
  right: 1.5rem;
  cursor: pointer;
`;

const StyledSearchIcon = styled(SearchIcon)`
  ${iconCss}
`;

const StyledCrossIcon = styled(CrossIcon)`
  ${iconCss}
  width: 1.2rem;
  height: 1.2rem;
  top: 1.6rem;
  right: 1.8rem;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin: 1rem 0;
`;

const SearchInfo = styled.p`
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  padding: 1.4rem 1rem 0 1rem;

  & span {
    color: ${({ theme }) => theme.dark400};
    font-size: 1.3rem;
    padding-left: 0.6rem;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    padding: 2rem 1.6rem 0 1.6rem;
  }
`;

const TunifyListHeader = styled.div`
  min-height: 4.6rem;
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  padding-left: 0.6rem;

  & p {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.4;
    padding-left: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const BackButton = styled.button`
  width: 3.6rem;
  height: 100%;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: none;
  display: grid;
  align-items: center;
  justify-content: center;
`;

const StyledArrowRight = styled(ArrowRight)`
  transform: rotate(180deg);
`;

const TunifyListContainer = styled.div`
  height: fit-content;
  display: grid;
  grid-auto-rows: min-content;
  overflow-y: auto;
  border: 1px solid ${({ theme }) => theme.stroke};
  background-color: ${({ theme }) => theme.uiBackground};
  border-radius: 8px;
`;

const Bold = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.dark500};
  padding-left: 0.6rem;
`;

const startTransitionValue = { x: '0%' };
const endTransitionValue = { x: '-100%' };
const transition = { ease: 'easeInOut', duration: 0.3 };

const Playlists = ({ tab, setTab }) => {
  const { t } = useTranslation();

  const {
    dispatch,
    selectedZone,
    selectedPlaylist,
    tempSearchValue,
    searchValue,
    addSongsToCustomPlaylist,
    loadingSearchSongs,
    tunifyLists,
    loadingTunifyLists,
    songs,
    selectedPlaylistType,
    selectedTunifyList,
    songsAndArtists,
    refetchSearchSongs,
    customPlaylists,
    favouritePlaylist,
  } = usePlaylists();

  const [showSearchOptions, setShowSearchOptions] = useState(false);
  const [collapsibleType, setCollapsibleType] = useState('custom');
  const [searchOptionsIndex, setSearchOptionsIndex] = useState(-1);
  const [templateSearchValue, setTemplateSearchValue] = useState('');

  const { isNarrowerThanMedium, isWiderThanMedium } = useMediaSize();

  useEffect(() => {
    if (tab === Tabs.SEARCH && selectedPlaylist?.id) {
      dispatch({
        selectedPlaylist: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, dispatch]);

  const onChangeCb = useCallback(
    (event) => {
      dispatch({
        tempSearchValue: event?.currentTarget?.value ?? '',
      });
      setTemplateSearchValue('');
    },
    [dispatch]
  );

  const handleShowOptions = useCallback(() => {
    setShowSearchOptions(true);
  }, []);

  const handleHideOptions = useCallback((event) => {
    const clickedOnOptions = event.currentTarget.contains(event.relatedTarget);

    if (!clickedOnOptions) {
      setShowSearchOptions(false);
    }
  }, []);

  const handleClickOptions = useCallback((event) => {
    event.stopPropagation();
  }, []);

  const handleKeyDownSearch = useCallback(
    (event) => {
      const artistsLength = songsAndArtists?.artists?.length ?? 0;
      const songsLength = songsAndArtists?.songs?.length ?? 0;
      const optionsLength = artistsLength + songsLength;
      let searchSongsType = '';

      switch (event.key) {
        case 'ArrowUp':
          event.preventDefault();
          setSearchOptionsIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : optionsLength - 1
          );
          break;
        case 'ArrowDown':
          event.preventDefault();
          setSearchOptionsIndex((prevIndex) =>
            optionsLength - 1 > prevIndex ? prevIndex + 1 : 0
          );
          break;
        case 'Enter':
          event.target.blur();

          if (templateSearchValue) {
            const currentArtists =
              songsAndArtists?.artists?.map((artist) => artist?.source) ?? [];
            const currentSongs =
              songsAndArtists?.songs?.map((song) => song?.source) ?? [];
            if (
              currentArtists.find((artist) => artist === templateSearchValue)
            ) {
              searchSongsType = 'autocompletionOnGroup';
            }
            if (currentSongs.find((song) => song === templateSearchValue)) {
              searchSongsType = 'autocompletionOnSong';
            }
          }

          dispatch({
            searchValue: templateSearchValue || tempSearchValue,
            tempSearchValue: templateSearchValue || tempSearchValue,
            selectedPlaylistType: PlaylistTypes.SEARCH,
            selectedPlaylist: {
              title: (
                <Trans
                  components={{ bold: <Bold /> }}
                  i18nKey="musicManagement:playlists.searchResultsFor"
                  defaults="Resultaten voor: <bold>{{result}}</bold>"
                  values={{ result: templateSearchValue || tempSearchValue }}
                />
              ),
            },
            songs:
              searchValue === (templateSearchValue || tempSearchValue)
                ? songs
                : [],
            searchSongsIsSuggestion: false,
            searchSongsType,
          });

          if (searchValue === (templateSearchValue || tempSearchValue)) {
            refetchSearchSongs();
          }
          setShowSearchOptions(false);
          setSearchOptionsIndex(-1);
          setTemplateSearchValue('');
          break;
        default:
          break;
      }
    },
    [
      songsAndArtists,
      dispatch,
      tempSearchValue,
      templateSearchValue,
      songs,
      searchValue,
      refetchSearchSongs,
    ]
  );

  useEffect(() => {
    const currentSongs = [
      ...(songsAndArtists?.artists ?? []),
      ...(songsAndArtists?.songs ?? []),
    ];
    const currentSong = currentSongs[searchOptionsIndex];
    setTemplateSearchValue(currentSong?.source ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchOptionsIndex]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = useCallback(
    ({ active }) => {
      dispatch({
        activeId: active?.data?.current?.songId,
      });
    },
    [dispatch]
  );

  const handleDragOver = useCallback(
    (event) => {
      dispatch({
        overId: event?.over?.id,
      });
    },
    [dispatch]
  );

  const [saveCustomPlaylist] = useMutation(SAVE_CUSTOM_PLAYLIST);

  const handleDragEnd = useCallback(
    (event) => {
      const { active, over } = event;

      if (over?.data?.current?.type === DroppableTypes.PLAYLIST) {
        if (over?.id === DroppableTypes.CREATE_PLAYLIST) {
          const songIds = [active.data.current.songId];
          dispatch({
            chosenSongIds: songIds,
            modalMode: PlaylistModalModes.CREATE_WITH_SONGS,
          });
        } else if (over?.data?.current?.listId) {
          const playlistId = parseInt(over.data.current.listId, 10);
          const songIds = [active.data.current.songId];
          dispatch({
            chosenPlaylistId: playlistId,
            chosenSongIds: songIds,
          });
          const playlists = [...customPlaylists, favouritePlaylist];
          const currentPlaylistTimestamp = playlists.find(
            (playlist) => Number(playlist.id) === Number(playlistId)
          )?.timestamp;
          if (currentPlaylistTimestamp) {
            addSongsToCustomPlaylist({
              variables: {
                zoneId: selectedZone.id,
                playlistId,
                timestamp: currentPlaylistTimestamp,
                songIds,
                duplicateHandling: 'ERROR',
              },
            });
          }
        }
      } else if (
        active.id !== over.id &&
        selectedPlaylistType === PlaylistTypes.CUSTOM
      ) {
        const oldIndex = songs.findIndex((song) => song.id === active?.id);
        const newIndex = songs.findIndex((song) => song.id === over?.id);
        const sortedSongs = arrayMove(songs, oldIndex, newIndex);
        dispatch({
          songs: sortedSongs,
        });
        saveCustomPlaylist({
          variables: {
            zoneId: selectedZone.id,
            playlistId: selectedPlaylist.id,
            playlist: {
              id: selectedPlaylist.id,
              title: selectedPlaylist.title,
              audioFiles: sortedSongs.map((i) => ({
                id: i.songId,
                title: i.title,
              })),
            },
          },
        });
      }

      dispatch({
        overId: null,
        activeId: null,
      });
    },
    [
      addSongsToCustomPlaylist,
      selectedZone,
      dispatch,
      songs,
      selectedPlaylist,
      saveCustomPlaylist,
      selectedPlaylistType,
      customPlaylists,
      favouritePlaylist,
    ]
  );

  const handleDragCancel = useCallback(() => {
    dispatch({
      overId: null,
      activeId: null,
    });
  }, [dispatch]);

  const handleCloseTunifyList = useCallback(() => {
    dispatch({
      selectedTunifyList: null,
    });
  }, [dispatch]);

  const handleClickSong = useCallback(
    (event) => {
      const value = event?.currentTarget?.value;
      dispatch({
        selectedPlaylist: {
          title: (
            <Trans
              components={{ bold: <Bold /> }}
              i18nKey="musicManagement:playlists.searchResultsFor"
              defaults="Resultaten voor: <bold>{{result}}</bold>"
              values={{ result: value }}
            />
          ),
        },
        selectedPlaylistType: PlaylistTypes.SEARCH,
        searchSongsIsSuggestion: false,
        searchSongsType: 'autocompletionOnSong',
        searchValue: value,
      });

      if (searchValue === value) {
        refetchSearchSongs();
      }
      setShowSearchOptions(false);
    },
    [dispatch, searchValue, refetchSearchSongs]
  );

  const handleClickArtist = useCallback(
    (event) => {
      const value = event?.currentTarget?.value;
      dispatch({
        selectedPlaylist: {
          title: (
            <Trans
              components={{ bold: <Bold /> }}
              i18nKey="musicManagement:playlists.searchResultsFor"
              defaults="Resultaten voor: <bold>{{result}}</bold>"
              values={{ result: value }}
            />
          ),
        },
        selectedPlaylistType: PlaylistTypes.SEARCH,
        searchSongsIsSuggestion: false,
        searchSongsType: 'autocompletionOnGroup',
        searchValue: value,
      });

      if (searchValue === value) {
        refetchSearchSongs();
      }
      setShowSearchOptions(false);
    },
    [dispatch, searchValue, refetchSearchSongs]
  );

  const handleClosePlaylist = useCallback(() => {
    dispatch({
      selectedPlaylist: null,
      tempSearchValue: '',
    });
  }, [dispatch]);

  const animateListContainerLeft = useMemo(
    () => ({ x: !selectedTunifyList ? '0%' : '-100%' }),
    [selectedTunifyList]
  );

  return (
    <DndContext
      collisionDetection={customCollisionDetectionStrategy}
      onDragCancel={handleDragCancel}
      onDragEnd={handleDragEnd}
      onDragOver={handleDragOver}
      onDragStart={handleDragStart}
      sensors={sensors}
    >
      <Container>
        {(!isNarrowerThanMedium || !selectedPlaylist?.id) &&
        tab !== Tabs.SEARCH ? (
          <Left>
            {!isNarrowerThanMedium ? (
              <SearchPositionWrapper $showSearchOptions={showSearchOptions}>
                <SearchWrapper
                  onBlur={handleHideOptions}
                  onFocus={handleShowOptions}
                  tabIndex="0"
                >
                  <SearchField>
                    <StyledStringField
                      name="search"
                      onChange={onChangeCb}
                      onKeyDown={handleKeyDownSearch}
                      placeholder={t(
                        'musicManagement:playlists.search.subTitle'
                      )}
                      value={templateSearchValue || tempSearchValue}
                    />
                    {templateSearchValue || tempSearchValue ? (
                      <StyledCrossIcon
                        color={theme.dark300}
                        onClick={handleClosePlaylist}
                      />
                    ) : (
                      <StyledSearchIcon color={theme.dark300} />
                    )}
                  </SearchField>
                  {showSearchOptions &&
                  (songsAndArtists?.artists?.length > 0 ||
                    songsAndArtists?.songs?.length > 0) ? (
                    <SearchOptions onClick={handleClickOptions}>
                      {songsAndArtists?.artists?.map((artist, index) => (
                        <StyledListButton
                          key={artist.id}
                          focused={searchOptionsIndex === index}
                          onClick={handleClickArtist}
                          type="button"
                          value={artist.source}
                        >
                          <ProfileIcon
                            width="2rem"
                            height="2rem"
                            color={theme.dark400}
                          />
                          <p>{artist.source}</p>
                        </StyledListButton>
                      ))}
                      {songsAndArtists?.songs?.map((song, index) => (
                        <StyledListButton
                          key={song.id}
                          focused={
                            searchOptionsIndex ===
                            index + (songsAndArtists?.artists?.length ?? 0)
                          }
                          onClick={handleClickSong}
                          type="button"
                          value={song.source}
                        >
                          <MusicNoteIcon
                            width="2rem"
                            height="2rem"
                            color={theme.dark300}
                          />
                          <p>{song.source}</p>
                        </StyledListButton>
                      ))}
                    </SearchOptions>
                  ) : null}
                </SearchWrapper>
              </SearchPositionWrapper>
            ) : null}

            <SideWrapper>
              <ListContainerLeft
                animate={animateListContainerLeft}
                initial={startTransitionValue}
                transition={transition}
              >
                <CustomPlaylists
                  collapsibleType={collapsibleType}
                  name={t('musicManagement:playlists.myLists')}
                  setCollapsibleType={setCollapsibleType}
                />
                {!loadingTunifyLists ? (
                  tunifyLists?.map((list) => (
                    <TunifyLists
                      key={list.id}
                      collapsibleType={collapsibleType}
                      id={list.id}
                      lists={list.musicChannels}
                      name={list.name}
                      setCollapsibleType={setCollapsibleType}
                    />
                  ))
                ) : (
                  <StyledLoadingIndicator color="primary" size="small" />
                )}
              </ListContainerLeft>
              <AnimatePresence>
                {selectedTunifyList ? (
                  <ListContainerRight
                    animate={endTransitionValue}
                    exit={startTransitionValue}
                    initial={startTransitionValue}
                    transition={transition}
                  >
                    <TunifyListHeader>
                      <BackButton onClick={handleCloseTunifyList} type="button">
                        <StyledArrowRight
                          color={theme.dark600}
                          height="1.6rem"
                          width="1.6rem"
                        />
                      </BackButton>
                      <p>{selectedTunifyList.musicChannel.name}</p>
                    </TunifyListHeader>
                    <TunifyListContainer>
                      {selectedTunifyList.musicCollections.map(
                        (musicCollection) => (
                          <MusicCollection
                            key={musicCollection.id}
                            musicChannelId={selectedTunifyList.musicChannel.id}
                            musicCollection={musicCollection}
                          />
                        )
                      )}
                    </TunifyListContainer>
                  </ListContainerRight>
                ) : null}
              </AnimatePresence>
            </SideWrapper>
          </Left>
        ) : null}
        {!isNarrowerThanMedium ||
        selectedPlaylist?.id ||
        tab === Tabs.SEARCH ? (
          <Right>
            <RightBox>
              {tab === Tabs.SEARCH ? (
                <SearchWrapper
                  onBlur={handleHideOptions}
                  onFocus={handleShowOptions}
                  tabIndex="0"
                >
                  <SearchField>
                    <StyledStringField
                      name="search"
                      onChange={onChangeCb}
                      onKeyDown={handleKeyDownSearch}
                      placeholder={t(
                        'musicManagement:playlists.search.subTitle'
                      )}
                      value={templateSearchValue || tempSearchValue}
                    />
                    {templateSearchValue || tempSearchValue ? (
                      <StyledCrossIcon
                        color={theme.dark300}
                        onClick={handleClosePlaylist}
                      />
                    ) : (
                      <StyledSearchIcon color={theme.dark300} />
                    )}
                  </SearchField>
                  {showSearchOptions &&
                  (songsAndArtists?.artists?.length > 0 ||
                    songsAndArtists?.songs?.length > 0) ? (
                    <SearchOptions onClick={handleClickOptions}>
                      {songsAndArtists?.artists?.map((artist, index) => (
                        <StyledListButton
                          key={artist.id}
                          focused={searchOptionsIndex === index}
                          onClick={handleClickArtist}
                          type="button"
                          value={artist.source}
                        >
                          <ProfileIcon
                            width="2rem"
                            height="2rem"
                            color={theme.dark400}
                          />
                          <p>{artist.source}</p>
                        </StyledListButton>
                      ))}
                      {songsAndArtists?.songs?.map((song, index) => (
                        <StyledListButton
                          key={song.id}
                          focused={
                            searchOptionsIndex ===
                            index + (songsAndArtists?.artists?.length ?? 0)
                          }
                          onClick={handleClickSong}
                          type="button"
                          value={song.source}
                        >
                          <MusicNoteIcon
                            width="2rem"
                            height="2rem"
                            color={theme.dark300}
                          />
                          <p>{song.source}</p>
                        </StyledListButton>
                      ))}
                    </SearchOptions>
                  ) : null}
                </SearchWrapper>
              ) : null}
              {selectedPlaylist ? (
                <PlaylistDetail setTab={setTab} />
              ) : (
                <>
                  {!loadingSearchSongs &&
                  !(
                    showSearchOptions &&
                    (songsAndArtists?.artists?.length > 0 ||
                      songsAndArtists?.songs?.length > 0)
                  ) ? (
                    <SearchInfo>
                      <InfoBasicIcon color={theme.dark300} />
                      <span>
                        {isWiderThanMedium
                          ? t('musicManagement:playlists.search.info')
                          : t('musicManagement:playlists.search.infoMobile')}
                      </span>
                    </SearchInfo>
                  ) : null}
                </>
              )}
            </RightBox>
          </Right>
        ) : null}
      </Container>
    </DndContext>
  );
};

export default Playlists;
