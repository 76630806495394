import { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';

import { useTranslation } from 'react-i18next';
import { SelectField, Button, BasicButton } from '../../../common';
import { ReactComponent as RawEditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as RawCloseIcon } from '../../../assets/icons/close.svg';
import theme from '../../../global/style/theme';

const Wrapper = styled.div`
  display: grid;
  gap: 0.4rem;
  margin-bottom: 2rem;
`;

const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;

  ${({ isClickable }) =>
    isClickable &&
    css`
      cursor: pointer;
    `}

  ${({ $withoutBorder }) =>
    !$withoutBorder &&
    css`
      border-bottom: 2px solid ${({ theme }) => theme.stroke};
    `}
`;

const Title = styled.h2`
  font-size: 1.6rem;
  font-weight: 600;

  ${({ $soft }) =>
    $soft &&
    css`
      color: ${({ theme }) => theme.dark300};
      font-weight: 400;
    `}
`;

const IconButton = styled(BasicButton)`
  padding: 1.2rem;
`;

const Container = styled.div`
  display: grid;
  gap: 1.6rem;
  align-items: end;
  padding: 2.4rem 1.6rem;
  border: 1px solid ${({ theme }) => theme.stroke};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.white};

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    grid-template-columns: 3fr 2fr min-content;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 1.6rem;
  align-items: center;
  justify-content: space-between;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    display: grid;
    gap: 1.6rem;
    align-items: center;
    grid-template-columns: max-content max-content;
    grid-column: span 2;
    justify-content: end;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    grid-column: unset;
  }
`;

const Label = styled.div`
  transition: color 0.2s ease-in-out;
  color: ${({ disabled, theme }) => (disabled ? theme.dark300 : theme.dark500)};
  font-size: 1.4rem;
  line-height: 1.4;
  padding-bottom: 0.4rem;
`;

const InputLookALike = styled.div`
  width: 100%;
  height: 4.8rem;
  outline: none;
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.stroke};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  line-height: 1.2;
  padding: 0 1.6rem;
  color: ${({ theme }) => theme.dark500};
  background-color: transparent;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out,
    color 0.2s ease-in-out;
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;
  background: ${({ theme }) =>
    `linear-gradient(0deg, ${theme.gradient['fade'][0]} 0%,  ${theme.gradient['fade'][1]} 100%)`};

  &:hover {
    border-color: ${({ theme }) => theme.dark300};
  }

  &:active,
  :focus {
    border-color: ${({ theme }) => theme.waterBlue};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      color: ${({ theme }) => theme.dark100};
      border-color: ${({ theme }) => theme.stroke};

      &:hover {
        border-color: ${({ theme }) => theme.stroke};
      }
    `}
`;

const StyledButton = styled(Button)`
  & p {
    font-size: 1.2rem;
    text-transform: lowercase;
    color: ${({ theme }) => theme.waterBlue};
  }
`;

const Filter = ({
  selectedPeriodId,
  periodOptions,
  toggleZoneSelectionModal,
  selectedZones,
  hasAllZonesSelected,
  musicReportLoading,
  tempSelectedZones,
  hasAllTempZonesSelected,
  showReport,
  setTempZoneIds,
}) => {
  const { t } = useTranslation();
  const [showFilter, setShowFilter] = useState(false);
  const [period, setPeriod] = useState(
    periodOptions.find((option) => option.value === selectedPeriodId)
  );

  const resetFilters = useCallback(() => {
    setPeriod(
      periodOptions.find((option) => option.value === selectedPeriodId)
    );
    setTempZoneIds(selectedZones.map((zone) => zone.id));
  }, [periodOptions, selectedPeriodId, selectedZones, setTempZoneIds]);

  const closeFilter = useCallback(() => {
    setShowFilter(false);
    resetFilters();
  }, [resetFilters]);

  const handleShowReport = useCallback(() => {
    showReport(
      period,
      tempSelectedZones.map((zone) => zone.id)
    );
    setShowFilter(false);
  }, [showReport, period, tempSelectedZones]);

  const changeTempPeriod = useCallback((value) => {
    setPeriod(value);
  }, []);

  return (
    <Wrapper>
      {showFilter ? (
        <>
          <InfoContainer $withoutBorder>
            <Title $soft>
              {t('musicManagement:musicReport.filter.setFilters')}
            </Title>
            <IconButton onClick={closeFilter}>
              <RawCloseIcon
                width="1.2rem"
                height="1.2rem"
                color={theme.dark300}
              />
            </IconButton>
          </InfoContainer>
          <Container>
            <div>
              <Label disabled={musicReportLoading}>
                {t('musicManagement:musicReport.filter.zones')}
              </Label>
              <InputLookALike disabled={musicReportLoading}>
                <span>
                  {hasAllTempZonesSelected
                    ? t('musicManagement:musicReport.filter.allZones')
                    : tempSelectedZones.map((zone) => zone.name).join(', ')}
                </span>
                <StyledButton
                  emphasis="secondary"
                  size="small"
                  isDisabled={musicReportLoading}
                  onClick={toggleZoneSelectionModal}
                >
                  {t('common:button.select')}
                </StyledButton>
              </InputLookALike>
            </div>
            <SelectField
              value={period}
              disabled={musicReportLoading}
              label={t('musicManagement:musicReport.filter.period')}
              onChange={changeTempPeriod}
              options={periodOptions}
            />
            <ButtonContainer>
              <Button
                emphasis="secondary"
                highlightColor="lavenderPurple"
                isDisabled={musicReportLoading}
                onClick={resetFilters}
              >
                {t('musicManagement:musicReport.filter.resetFilters')}
              </Button>
              <Button
                isDisabled={musicReportLoading}
                onClick={handleShowReport}
              >
                {t('musicManagement:musicReport.filter.showReport')}
              </Button>
            </ButtonContainer>
          </Container>
        </>
      ) : (
        <InfoContainer isClickable onClick={() => setShowFilter(true)}>
          <Title>
            {hasAllZonesSelected || selectedZones.length === 0
              ? t('musicManagement:musicReport.filter.allZones')
              : selectedZones.map((zone) => zone.name).join(', ')}
            ,{' '}
            {periodOptions
              ?.find((option) => option.value === selectedPeriodId)
              ?.label?.toLowerCase() ?? ''}
          </Title>
          <IconButton>
            <RawEditIcon width="1.6rem" height="1.6rem" color={theme.dark300} />
          </IconButton>
        </InfoContainer>
      )}
    </Wrapper>
  );
};

export default Filter;
