import { useCallback, useMemo, useRef } from 'react';
import styled from 'styled-components';
import shortid from 'shortid';

import { getPercentage, getValue, getInitialPercentage } from './helpers';

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  row-gap: 0.2rem;
  width: 100%;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

const SliderContainer = styled.div`
  height: 2px;
  width: calc(100% - 2rem);
  background-color: ${({ theme }) => theme.stroke};
  position: relative;
  margin-left: 0.9rem;
`;

const Grab = styled.div`
  background-color: ${({ theme }) => theme.waterBlue};
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1.6rem;
  height: 1.6rem;
  user-select: none;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.13, 0.57, 0.43, 1.28);
  touch-action: none;

  ${({ initialPercentage }) => `left: ${initialPercentage}`}
`;

const Steps = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: ${({ theme }) => theme.dark200};
  padding: 0 0.6rem;
`;

const Step = styled.span`
  cursor: pointer;
  user-select: none;
  font-size: 1.4rem;
`;

const Slider = ({ initial, max, onChange }) => {
  const initialPercentage = useMemo(() => getInitialPercentage(initial - 1, max - 1, max), [initial, max]);

  const sliderRef = useRef(null);
  const grabRef = useRef(null);
  const difference = useRef(null);
  const currentValue = useRef(initial - 1);

  const steps = useRef(
    Array(max)
      .fill(0)
      .map(() => ({ id: shortid.generate() })),
    [max]
  );

  const handleDragging = useCallback(
    (event) => {
      event.stopPropagation();
      // touch events have another prop to read the `clientX`
      const normalizedEvent = event.type === 'touchmove' ? event.changedTouches[0] : event;
      let newX = normalizedEvent.clientX - difference.current - sliderRef.current.getBoundingClientRect().left;

      const end = sliderRef.current.offsetWidth - grabRef.current.offsetWidth;
      const start = 0;

      if (newX < start) {
        newX = 0;
      }
      if (newX > end) {
        newX = end;
      }

      // `click` event will occur if the user clicks a step number directly
      const newPercentage =
        event.type === 'click' ? getInitialPercentage(newX, end, max) : getPercentage(newX, end, max);
      // Will return `undefined` if the current position does not 'snap'
      const newValue = getValue(newPercentage, max - 1) ?? currentValue.current;

      if (currentValue.current !== newValue) {
        currentValue.current = newValue ?? currentValue.current;
        // Step '0' is used as a step so add 1
        onChange(currentValue.current + 1);
      }
      // Update the css
      grabRef.current.style.left = `${newPercentage}%`;
    },
    [max, onChange]
  );

  const handleMouseUp = useCallback(() => {
    document.removeEventListener('mouseup', handleMouseUp);
    document.removeEventListener('mousemove', handleDragging);
  }, [handleDragging]);

  const handleMouseDown = useCallback(
    (event) => {
      difference.current = event.clientX - grabRef.current.getBoundingClientRect().left;

      document.addEventListener('mouseup', handleMouseUp);
      document.addEventListener('mousemove', handleDragging);
    },
    [handleDragging, handleMouseUp]
  );

  const handleTouchStart = useCallback((event) => {
    difference.current = event.changedTouches[0].clientX - grabRef.current.getBoundingClientRect().left;
  }, []);

  return (
    <Container onClick={handleDragging}>
      <SliderContainer ref={sliderRef}>
        <Grab
          ref={grabRef}
          initialPercentage={`${initialPercentage}%`}
          onMouseDown={handleMouseDown}
          onTouchEnd={handleMouseUp}
          onTouchMove={handleDragging}
          onTouchStart={handleTouchStart}
        />
      </SliderContainer>
      <Steps>
        {steps.current.map((step, index) => (
          <Step key={step.id}>{index + 1}</Step>
        ))}
      </Steps>
    </Container>
  );
};

export default Slider;
