import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '..';
import {
  hasAllRequiredPermissions,
  OWNER_ROLE_PERMISSIONS,
  SUPPORT_ROLE_PERMISSIONS,
} from '../../global/auth';
import { useAuth } from '../../global/auth/newAuthProvider';
import { useSessionStorage } from '../hooks';

const Centered = styled.div`
  height: 100vh;
  @supports (height: 100svh) {
    height: 100svh;
  }
  display: grid;
  justify-content: center;
  align-items: center;
`;

const StaticWidthContainer = styled.div`
  display: grid;
  gap: 3.6rem;
  padding: 1.6rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    width: 40rem;
  }
`;

const Text = styled.p`
  font-size: 1.6rem;
  line-height: 1.4;
`;

const ButtonGroup = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-rows: 1fr 1fr;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    grid-template-columns: 3fr 2fr;
  }
`;

const ErrorBody = ({ className }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { user: userInfo } = useAuth();
  const [impersonationId] = useSessionStorage('impersonationId');
  const isOwner = hasAllRequiredPermissions(userInfo, OWNER_ROLE_PERMISSIONS);
  const isSupport = hasAllRequiredPermissions(
    userInfo,
    SUPPORT_ROLE_PERMISSIONS
  );
  const firstRender = useRef(true);

  const supportEmail = useMemo(() => t('config:customerSupportEmail'), [t]);

  useEffect(() => {
    if (!firstRender?.current) {
      // Reload when user tries to navigate to other page with menu
      window.location.reload();
    } else {
      firstRender.current = false;
    }
  }, [location]);

  const handleRedirect = useCallback(() => {
    if ((isOwner && !isSupport) || (isSupport && !!impersonationId)) {
      navigate(-1);
      window.location.reload();
    } else if (isSupport && !impersonationId) {
      navigate('/customer-success/search', { replace: true });
      window.location.reload();
    } else {
      navigate('/invoices', { replace: true });
      window.location.reload();
    }
  }, [navigate, isOwner, impersonationId, isSupport]);

  const handleContactClick = useCallback(() => {
    // In favor of the styled component polymorphism, just create this helper tag
    // Gives us more flexibilty for the dynamic email template
    const emailLink = document.createElement('a');
    emailLink.href = `mailto:${supportEmail}?subject=${t(
      'error:RENDER_ERROR_SUPPORT_EMAIL_SUBJECT'
    )}`;

    emailLink.click();
  }, [t, supportEmail]);

  const redirectButtonText = useMemo(() => {
    if ((isOwner && !isSupport) || (isSupport && !!impersonationId)) {
      return t('common:button.title.back');
    }
    if (isSupport && !impersonationId) {
      return t('common:button.goToCustomerSuccess');
    }
    return t('common:button.goToInvoices');
  }, [t, isOwner, isSupport, impersonationId]);

  const redirectButtonTitle = useMemo(() => {
    if ((isOwner && !isSupport) || (isSupport && !!impersonationId)) {
      return t('common:button.title.back');
    }
    if (isSupport && !impersonationId) {
      return t('common:button.title.goTo', {
        value: t('common:button.title.customerSuccess'),
      });
    }
    return t('common:button.title.goTo', {
      value: t('common:button.invoices'),
    });
  }, [t, isOwner, isSupport, impersonationId]);

  return (
    <Centered className={className}>
      <StaticWidthContainer>
        <Text>{t('error:RENDER_ERROR')}</Text>
        <ButtonGroup>
          <Button onClick={handleRedirect} title={redirectButtonTitle}>
            {redirectButtonText}
          </Button>
          <Button
            highlightGradient="sunset"
            onClick={handleContactClick}
            title={t('common:button.title.contact')}
          >
            {t('common:button.contact')}
          </Button>
        </ButtonGroup>
      </StaticWidthContainer>
    </Centered>
  );
};

export default ErrorBody;
