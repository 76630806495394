import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { LinkButton } from '../../../../common';

const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.stroke};
  border-radius: 8px;
  display: grid;
  justify-items: center;
  align-items: center;
  padding: 2rem;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  & > svg {
    width: 20%;
  }
  padding: 0 2rem 1rem 2rem;
`;

const Title = styled.h4`
  font-size: 2rem;
  line-height: 1.5;
  padding-bottom: 0.8rem;
`;

const NotAvailableAddonCard = ({ addon }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <IconContainer
        dangerouslySetInnerHTML={{ __html: addon.productCategory.features }}
      />
      <Title>{addon.shortName}</Title>
      <LinkButton
        emphasis="secondary"
        size="medium"
        href={addon.productCategory.whatIsThisLink}
      >
        {t('glossary:availableSoon')}
      </LinkButton>
    </Container>
  );
};

export default NotAvailableAddonCard;
