import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { GridTable, formatDate, getTunifyProductColor, getHistoryCollectionName } from '../../../../common';

const DesktopRow = styled(GridTable.Row)`
  display: none;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    display: grid;
    color: ${({ theme }) => theme.dark400};
  }
`;

const TabletRow = styled(GridTable.Row)`
  display: none;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.small}px) {
    display: grid;
    grid-template-columns: 6rem 1fr 1fr;
    color: ${({ theme }) => theme.dark400};
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    display: none;
  }
`;

const MobileRow = styled(GridTable.Row)`
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.small}px) {
    display: none;
  }
`;

const ColorCircle = styled.span`
  min-width: 0.8rem;
  min-height: 0.8rem;
  display: inline-block;
  border-radius: 50%;
  margin-right: 0.6rem;
  background-color: ${({ color }) => getTunifyProductColor(color)};
`;

const StyledTabledataCollection = styled(GridTable.Value)`
  display: flex;
  align-items: center;
  overflow: hidden;
  & p {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const TabletPair = styled.div`
  display: grid;
`;

const MobilePair = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: 2rem;
  color: ${({ theme }) => theme.dark300};
  padding-bottom: 1rem;
`;

const SmallValue = styled(GridTable.Value)`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.dark300};
`;

const MobileTitle = styled(GridTable.Value)`
  font-weight: 600;
`;

const MusicHistoryRow = ({ musicHistoryValue }) => {
  const { t, i18n } = useTranslation();

  const collectionName = useMemo(() => getHistoryCollectionName(musicHistoryValue, t), [musicHistoryValue, t]);

  return (
    <>
      <DesktopRow templateColumns="6rem 1fr 1fr 1fr">
        <GridTable.Value>{formatDate(musicHistoryValue.timestamp, i18n.language, { showTime: true })}</GridTable.Value>
        <GridTable.Value>{musicHistoryValue.songTitle ?? ''}</GridTable.Value>
        <GridTable.Value>{musicHistoryValue.songArtist ?? ''}</GridTable.Value>
        <StyledTabledataCollection>
          {musicHistoryValue.tunifyColor ? <ColorCircle color={musicHistoryValue.tunifyColor} /> : ''}
          <p>{collectionName}</p>
        </StyledTabledataCollection>
      </DesktopRow>
      <TabletRow>
        <GridTable.Value>{formatDate(musicHistoryValue.timestamp, i18n.language, { showTime: true })}</GridTable.Value>
        <TabletPair>
          <GridTable.Value>{musicHistoryValue.songTitle ?? ''}</GridTable.Value>
          <SmallValue>{musicHistoryValue.songArtist ?? ''}</SmallValue>
        </TabletPair>
        <StyledTabledataCollection>
          {musicHistoryValue.tunifyColor ? <ColorCircle color={musicHistoryValue.tunifyColor} /> : ''}
          <p>{collectionName}</p>
        </StyledTabledataCollection>
      </TabletRow>
      <MobileRow>
        <MobilePair>
          <GridTable.Value>
            {formatDate(musicHistoryValue.timestamp, i18n.language, { showTime: true })}
          </GridTable.Value>
          <StyledTabledataCollection>
            {musicHistoryValue.tunifyColor ? <ColorCircle color={musicHistoryValue.tunifyColor} /> : ''}
            <p>{collectionName}</p>
          </StyledTabledataCollection>
        </MobilePair>
        <TabletPair>
          <MobileTitle>{musicHistoryValue.songTitle ?? ''}</MobileTitle>
          <SmallValue>{musicHistoryValue.songArtist ?? ''}</SmallValue>
        </TabletPair>
      </MobileRow>
    </>
  );
};

export default MusicHistoryRow;
