import { useCallback, useMemo } from 'react';
import Select from 'react-select';
import { Controller } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import Label from '../common/label';
import ErrorMessage from '../common/errorMessage';
import theme from '../../../global/style/theme';

const baseStyle = (overwrite) => ({
  control: (style, state) => ({
    ...style,
    minHeight: '4.8rem',
    borderRadius: '4px',
    '&:hover': !state.isFocused
      ? {
          borderColor: theme.dark300,
        }
      : null,
    cursor: 'text',
    transition: 'border-color 0.2s ease-in-out',
    boxShadow: 'none',
    fontSize: '1.4rem',
    lineHeight: '1.4',
    fontWeight: '500',
    backgroundColor: 'transparent',
    background: `linear-gradient(0deg, ${theme.gradient['fade'][0]} 0%,  ${theme.gradient['fade'][1]} 100%)`,
    border: state.isDisabled
      ? `2px solid ${theme.stroke}`
      : state.isFocused
      ? `2px solid ${theme.waterBlue}`
      : `2px solid ${theme.stroke}`,
  }),
  input: (style) => ({
    ...style,
    fontWeight: '500',
    lineHeight: '1.4',
    fontSize: '1.4rem',
    '&:focus': {
      border: 'none',
      outline: 'none',
    },
    '&:hover': {
      border: 'none',
      outline: 'none',
    },
    '&:active': {
      border: 'none',
      outline: 'none',
    },
  }),
  container: (style) => ({
    ...style,
    '&:focus': {
      border: 'none',
      outline: 'none',
    },
    '&:hover': {
      border: 'none',
      outline: 'none',
    },
    '&:active': {
      border: 'none',
      outline: 'none',
    },
  }),
  valueContainer: (style) => ({
    ...style,
    paddingLeft: '1.6rem',
  }),
  indicatorSeparator: () => ({}),
  placeholder: (style, state) => ({
    ...style,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    color: state.isDisabled ? theme.stroke : theme.dark300,
    fontWeight: '500',
    lineHeight: '1.4',
    fontSize: '1.4rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  option: (style, state) => ({
    ...style,
    padding: '1.4rem 1.6rem',
    cursor: 'pointer',
    '&:hover': {
      color: theme.dark400,
      backgroundColor: theme.background,
    },
    transition: 'background-color 0.2s ease-in-out, color 0.2s ease-in-out',
    color: state.isSelected ? theme.dark500 : theme.dark300,
    backgroundColor: 'transparent',
    fontSize: '1.4rem',
    fontWeight: '500',
  }),
  indicatorsContainer: (style) => ({
    ...style,
    cursor: 'pointer',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: theme.dark500,
  }),
  menu: (styles) => ({
    ...styles,
    border: `2px solid ${theme.waterBlue}`,
    boxShadow: 'none',
    fontSize: '1.4rem',
    zIndex: 2000,
  }),
  ...overwrite,
});

const SelectField = ({
  disabled,
  error,
  label,
  options,
  required,
  name,
  isLoading,
  className,
  control,
  sort = false,
  styleOverwrite,
  ...restProps
}) => {
  const { t } = useTranslation();

  // React-select only accepts a function
  const noOptionsMessageCb = useCallback(
    () => t('glossary:noOptionsAvailable'),
    [t]
  );

  // React-select only accepts a function
  const loadingMessageCb = useCallback(() => t('glossary:loading'), [t]);

  const sortedOptions = useMemo(() => {
    let newOptions = options ?? [];
    if (options && sort) {
      newOptions = options.sort((a, b) =>
        a.label > b.label ? 1 : b.label > a.label ? -1 : 0
      );
    }
    return newOptions;
  }, [options, sort]);

  const styles = useMemo(() => baseStyle(styleOverwrite), [styleOverwrite]);

  return (
    <div className={className}>
      {label ? (
        <Label required={required} disabled={disabled}>
          {label}
        </Label>
      ) : null}
      {control ? (
        <Controller
          control={control}
          name={name}
          render={({ field }) => {
            return (
              <Select
                isDisabled={disabled}
                loadingMessage={loadingMessageCb}
                noOptionsMessage={noOptionsMessageCb}
                options={sortedOptions}
                styles={styles}
                menuShouldScrollIntoView
                {...field}
                {...restProps}
              />
            );
          }}
        />
      ) : (
        <Select
          isDisabled={disabled}
          loadingMessage={loadingMessageCb}
          name={name}
          noOptionsMessage={noOptionsMessageCb}
          options={sortedOptions}
          styles={styles}
          menuShouldScrollIntoView
          {...restProps}
        />
      )}
      {typeof error === 'string' ? <ErrorMessage>{error}</ErrorMessage> : null}
    </div>
  );
};

export default SelectField;
