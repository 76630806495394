import { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { formatDate, useToggle } from '../../../../../common';
import { ReactComponent as AngleDownIcon } from '../../../../../assets/icons/angleDown.svg';
import { ReactComponent as ArrowRightIcon } from '../../../../../assets/icons/arrowRight.svg';
import { ReactComponent as InvoicesIcon } from '../../../../../assets/icons/navigation/invoicesLarge.svg';
import SubTableRow from './subTableRow';
import theme from '../../../../../global/style/theme';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr min-content;
  align-items: center;
  padding: 1.6rem;
  min-height: 4rem;
  cursor: pointer;

  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.stroke};
  background-color: ${({ theme }) => theme.white};

  ${({ isExpand }) =>
    isExpand &&
    css`
      border: 2px solid ${({ theme }) => theme.waterBlue};
    `}

  @media only screen and (min-width: ${({ theme }) =>
    theme.breakpoints.medium}px) {
    grid-template-columns: 1fr 1fr min-content;
    grid-template-rows: 1fr;
    grid-row-gap: 2rem;
  }
`;

const DetailsContainer = styled.div`
  display: grid;
  grid-gap: 0.8rem;
  align-items: center;

  padding-bottom: 1.6rem;
  border-bottom: 1px solid ${({ theme }) => theme.stroke};

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    border-bottom: unset;
    border-right: 1px solid ${({ theme }) => theme.stroke};
    margin-right: 2rem;
    padding: 1rem;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    grid-template-columns: max-content max-content;
    grid-template-rows: 1fr 1fr;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-rows: 1fr;
  }
`;

const LocationName = styled.p`
  color: ${({ theme }) => theme.dark500};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.143;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    grid-column: span 2;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    grid-column: unset;
  }
`;

const Code = styled.p`
  color: ${({ theme }) => theme.dark300};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.143;
`;

const OrganisationName = styled.p`
  color: ${({ theme }) => theme.dark300};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.143;
`;

const SubscriptionContainer = styled.div`
  display: grid;
  grid-gap: 0.8rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    padding: 1rem;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
`;

const ProductName = styled.p`
  color: ${({ theme }) => theme.dark500};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.143;
`;

const SubscriptionDate = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, min-content);
  align-items: center;
`;

const DateValue = styled.p`
  color: ${({ theme }) => theme.dark300};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.143;
`;

const InvoiceContainer = styled.div`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.stroke};
  opacity: 1;
  background-color: ${({ theme }) => theme.uiBackground};
  padding: 1.6rem;
  align-items: center;
  display: grid;
  grid-gap: 1rem;
  grid-auto-flow: column;
  grid-template-columns: min-content 1fr min-content;
  margin-bottom: 0.5rem;
  min-width: 14rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    min-width: 16rem;
    padding: 1.2rem 1.6rem;
  }
`;

const InvoiceCount = styled.p`
  opacity: 1;
  color: ${({ theme }) => theme.dark400};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.143;
`;

const InvoiceWrapper = styled.div`
  background-color: ${({ theme }) => theme.uiBackground};
  display: grid;
  grid-gap: 0.5rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    grid-column: span 3;
  }
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    grid-gap: 0;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.stroke};
  }
`;

const InvoiceResults = styled.div`
  display: grid;
  grid-gap: 0.5rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    grid-column: span 3;
  }
`;

const StyledCaretIcon = styled(AngleDownIcon)`
  width: 1rem;
  height: 1rem;
  ${({ $isExpand }) => $isExpand && 'transform: scale(1, -1);'}
  color: ${({ theme }) => theme.dark300};
`;

const InvoiceHeader = styled.div`
  display: none;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    grid-column: span 3;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    padding: 1.6rem;
  }
`;

const HeaderLabel = styled.p`
  color: ${({ theme }) => theme.dark400};
  font-family: 'Inter', sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.143;
`;

const TableRow = ({ subscriptionEntry }) => {
  const { t, i18n } = useTranslation();
  const [isExpand, toggleIsExpand] = useToggle();

  const productShortName = useMemo(() => {
    return (
      subscriptionEntry.subscriptionLines.find((line) => !line.product.isAddon)
        ?.product?.shortName ?? ''
    );
  }, [subscriptionEntry.subscriptionLines]);

  const formattedRecurringTime = useMemo(() => {
    return subscriptionEntry.recurringRuleType === 'monthly'
      ? t('glossary:monthWithCount', {
          count: subscriptionEntry.recurringInterval,
        })
      : t('glossary:weekWithCount', {
          count: subscriptionEntry.recurringInterval,
        });
  }, [
    subscriptionEntry.recurringInterval,
    subscriptionEntry.recurringRuleType,
    t,
  ]);

  return (
    <>
      <Container isExpand={isExpand} onClick={toggleIsExpand}>
        <DetailsContainer>
          <LocationName>
            {subscriptionEntry.locationName.split(', ')[1]} -{' '}
            {subscriptionEntry.zoneName}
          </LocationName>
          <Code>{subscriptionEntry.code}</Code>
          <OrganisationName>
            {subscriptionEntry.organizationName}
          </OrganisationName>
        </DetailsContainer>
        <SubscriptionContainer>
          <ProductName>{`${productShortName} ${formattedRecurringTime}`}</ProductName>
          <SubscriptionDate>
            <DateValue>
              {formatDate(subscriptionEntry.startDate, i18n.language)}
            </DateValue>
            <ArrowRightIcon height="1.4rem" width="1.4rem" />
            <DateValue>
              {formatDate(subscriptionEntry.endDate, i18n.language)}
            </DateValue>
          </SubscriptionDate>
        </SubscriptionContainer>
        <InvoiceContainer>
          <InvoicesIcon width="2.6rem" height="2.6rem" color={theme.dark200} />
          <InvoiceCount>{subscriptionEntry.invoiceCount}</InvoiceCount>
          <StyledCaretIcon $isExpand={isExpand} />
        </InvoiceContainer>
        {isExpand && (
          <InvoiceWrapper>
            <InvoiceHeader>
              <HeaderLabel>
                {t('customerSuccess:history.thInvoicing')}
              </HeaderLabel>
              <HeaderLabel>
                {t('customerSuccess:history.thRenewal')}
              </HeaderLabel>
              <HeaderLabel>
                {t('customerSuccess:history.thProduct')}
              </HeaderLabel>
              <HeaderLabel>
                {t('customerSuccess:history.thDescription')}
              </HeaderLabel>
              <HeaderLabel>
                {t('customerSuccess:history.thSubtotal')}
              </HeaderLabel>
            </InvoiceHeader>

            <InvoiceResults>
              {subscriptionEntry?.subscriptionLines.map((line) => (
                <SubTableRow key={line?.id} line={line} />
              ))}
            </InvoiceResults>
          </InvoiceWrapper>
        )}
      </Container>
    </>
  );
};

export default TableRow;
