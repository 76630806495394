import { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';

import { BasicButton } from '../..';

const Container = styled(BasicButton)`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.2rem;
  padding: 1.6rem;
  margin: 1px;
  border: solid 1px ${({ theme }) => theme.stroke};
  border-radius: 8px;
  transition: border-color 0.2s ease-in-out;

  ${({ isActive, theme }) =>
    isActive &&
    css`
      margin: 0;
      border: solid 2px ${theme.waterBlue};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}
`;

const MainContainer = styled.div`
  display: grid;
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: ${({ $selectBoxLeft }) => ($selectBoxLeft ? 'min-content 1fr' : '1fr min-content')};
  align-items: center;
  gap: 1.2rem;
`;

const SelectBox = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  border: solid 1px ${({ theme }) => theme.stroke};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.uiBackground};
  transition: border-color 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isActive, theme }) =>
    isActive &&
    css`
      border: solid 2px ${theme.waterBlue};
    `}
`;

const InnerBox = styled.div`
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;

  ${({ isActive }) =>
    isActive &&
    css`
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 1px;
      background-color: ${({ theme }) => theme.waterBlue};
    `}
`;

const SelectCard = ({ id, activeSelectCardId, onClick, children, title, className, isDisabled, selectBoxLeft }) => {
  const handleOnClick = useCallback(() => {
    onClick(id);
  }, [id, onClick]);

  const isActive = useMemo(
    () => (Array.isArray(activeSelectCardId) ? activeSelectCardId.includes(id) : id === activeSelectCardId),
    [activeSelectCardId, id]
  );

  return (
    <Container
      className={className}
      disabled={isDisabled}
      selectBoxLeft={selectBoxLeft}
      isActive={isActive}
      onClick={handleOnClick}
    >
      <Header $selectBoxLeft={selectBoxLeft}>
        {selectBoxLeft ? (
          <SelectBox isActive={isActive}>
            <InnerBox isActive={isActive} />
          </SelectBox>
        ) : null}
        {title}
        {!selectBoxLeft ? (
          <SelectBox isActive={isActive}>
            <InnerBox isActive={isActive} />
          </SelectBox>
        ) : null}
      </Header>
      {children ? <MainContainer>{children}</MainContainer> : null}
    </Container>
  );
};

export default SelectCard;
