import { useQuery } from '@apollo/react-hooks';
import qs from 'query-string';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import {
  ErrorMessage,
  LoadingIndicator,
  Page,
  extractErrorCodeFromApolloError,
  useLocalStorage,
  useSessionStorage,
} from '../../common';
import PageHeader from './pageHeader';
import Table from './table';

import { useAuth } from '../../global/auth/newAuthProvider';
import {
  GET_ORGANIZATION_ID_BY_ZONE_ID,
  GET_SUBSCRIPTIONS_FOR_ORGANIZATION_AND_ORGANIZATIONS,
} from './api';

const Container = styled.div`
  display: grid;
  grid-template-rows: min-content 1fr;
  height: 100%;
`;

const Grid = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: min-content 1fr;
  overflow-y: auto;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin-top: 3rem;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 3rem;
`;

export const Dashboard = () => {
  const {
    user: { id: userId },
  } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const [impersonationId] = useSessionStorage('impersonationId');
  const [queryRetryCount, setQueryRetryCount] = useState(0);

  const [searchValue, setSearchValue] = useState('');
  const [searchFilter, setSearchFilter] = useState('');

  const [
    memoryOrganizationId,
    setMemoryOrganizationId,
    removeMemoryOrganizationId,
  ] = useLocalStorage(`${userId}_lastViewedOrg`, '');

  const { data: dataId } = useQuery(GET_ORGANIZATION_ID_BY_ZONE_ID, {
    variables: { zoneId: qs.parse(location.search)?.zoneId },
    skip: !qs.parse(location.search)?.zoneId,
  });

  const organizationQueryVariables = useMemo(() => {
    if (dataId?.organizationIdByZoneId) {
      return {
        organizationId: dataId.organizationIdByZoneId,
        withOrganization: true,
      };
    }
    if (
      !!impersonationId &&
      !memoryOrganizationId &&
      location.state &&
      location.state.impersonatedOrganizationId
    ) {
      return {
        organizationId: location.state.impersonatedOrganizationId,
        withOrganization: true,
      };
    }
    if (memoryOrganizationId) {
      return { organizationId: memoryOrganizationId, withOrganization: true };
    }
    return { organizationId: 'dontcare', withOrganization: false };
  }, [
    location.state,
    dataId?.organizationIdByZoneId,
    memoryOrganizationId,
    impersonationId,
  ]);

  const { loading, data, error, refetch } = useQuery(
    GET_SUBSCRIPTIONS_FOR_ORGANIZATION_AND_ORGANIZATIONS,
    {
      variables: organizationQueryVariables,
      onError: (apolloError) => {
        const apolloErrorCode = extractErrorCodeFromApolloError(apolloError);
        // Only refetch when the organization is not found and we didn't retry before
        if (
          apolloErrorCode === 'ORGANIZATION_NOT_FOUND' &&
          queryRetryCount === 0
        ) {
          setQueryRetryCount((prev) => prev + 1);
          removeMemoryOrganizationId();
          refetch({ organizationId: 'dontcare', withOrganization: false });
        }
      },
    }
  );

  const handleOnChangeOrganization = useCallback(
    (organizationId) => {
      setMemoryOrganizationId(organizationId);
    },
    [setMemoryOrganizationId]
  );

  const resetFilters = useCallback(() => {
    setSearchValue('');
    setSearchFilter('');
  }, []);

  const pageContent = useMemo(() => {
    if (loading) return <StyledLoadingIndicator />;
    if (error) return <StyledErrorMessage error={error} />;
    if (data) {
      const { organizations, allProducts } = data;
      let organizationWithSubscriptions;
      if (memoryOrganizationId || qs.parse(location.search)?.organizationId) {
        organizationWithSubscriptions = data.organization;
      } else if (
        !!impersonationId &&
        location.state &&
        location.state.impersonatedOrganizationId
      ) {
        organizationWithSubscriptions = data.organization;
      } else {
        [organizationWithSubscriptions] = data.paginatedOrganizations;
      }

      return (
        <Container>
          <PageHeader
            onChangeOrganization={handleOnChangeOrganization}
            organization={organizationWithSubscriptions}
            organizations={organizations}
            searchValue={searchValue}
            setSearchFilter={setSearchFilter}
            setSearchValue={setSearchValue}
          />
          <Grid>
            <Table
              allProducts={allProducts}
              organizationWithSubscriptions={organizationWithSubscriptions}
              resetFilters={resetFilters}
              searchFilter={searchFilter}
              searchValue={searchValue}
            />
          </Grid>
        </Container>
      );
    }
  }, [
    data,
    error,
    handleOnChangeOrganization,
    loading,
    memoryOrganizationId,
    impersonationId,
    location.state,
    location.search,
    searchFilter,
    searchValue,
    resetFilters,
  ]);

  return <Page title={t('subscriptions:title')}>{pageContent}</Page>;
};
