import { useRef, useLayoutEffect } from 'react';

import { useGlobalState } from '../../global/state';

const useScrollMore = () => {
  const ref = useRef();
  const { shouldScrollMore, setShouldScrollMore } = useGlobalState();

  useLayoutEffect(() => {
    if (!shouldScrollMore) return;
    setTimeout(() => {
      ref.current.scroll({
        left: 25,
        behavior: 'smooth',
      });
    }, 1000);

    setTimeout(() => {
      ref.current.scroll({
        left: -25,
        behavior: 'smooth',
      });
    }, 1350);

    setShouldScrollMore(false);
  }, [ref, shouldScrollMore, setShouldScrollMore]);

  return ref;
};

export default useScrollMore;
