import { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';

import { useMediaSize } from '../../../../common';
import { Button } from '../../../../common/oldButton';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/delete.svg';
import { ReactComponent as SettingsIcon } from '../../../../assets/icons/settings.svg';

const Channel = styled.div`
  grid-column: ${({ $column }) => $column ?? 1};
  grid-row: ${({ $row }) => $row ?? 1} / span ${({ $size }) => $size};
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.stroke};
  color: ${({ theme }) => theme.dark600};
  border: none;
  border-radius: 0.2rem;
  display: grid;
  grid-template-columns: 1fr min-content min-content;
  align-items: center;
  gap: 0.5rem;
  padding: 0 1rem;
  pointer-events: auto;

  & svg path {
    fill: ${({ theme }) => theme.dark600};
  }

  & p {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${({ $canEdit, theme }) =>
    $canEdit
      ? css`
          cursor: grab;

          &:hover {
            background-color: ${theme.babyBlue};

            & p {
              color: ${theme.white};
            }

            & svg path {
              fill: ${theme.white};
            }
          }
        `
      : ''}

  ${({ $ghost, theme }) =>
    $ghost
      ? css`
          opacity: 0.2;
          background-color: ${theme.babyBlue};
        `
      : ''}
`;

const StyledButton = styled(Button)`
  padding: 0;
  margin: 0;

  & svg:hover {
    path {
      fill: ${({ theme }) => theme.white};
    }
  }

  ${({ $small }) =>
    $small &&
    css`
      min-width: 1.8rem;
      min-height: 1.8rem;
    `}
`;

const ChannelItem = ({ id, title, row, column, isCustomAgenda, size = 1 }) => {
  const { isNarrowerThanLarge } = useMediaSize();

  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useDraggable({
    id: `CHANNEL_ITEM_${id}`,
    data: {
      type: 'CHANNEL_ITEM',
      channelId: id,
    },
    disabled: isNarrowerThanLarge || !isCustomAgenda,
  });

  const deleteChannel = useCallback(() => {
    // TODO: delete channel
  }, []);

  const openChannelSettings = useCallback(() => {
    // TODO: open channel settings
  }, []);

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <Channel
      key={id}
      ref={setNodeRef}
      $canEdit={isCustomAgenda}
      $column={column}
      $ghost={isDragging}
      $row={row}
      $size={size}
      style={style}
      {...attributes}
      {...listeners}
    >
      <p>{title}</p>
      {isCustomAgenda ? (
        <>
          <StyledButton
            $small={size === 1}
            flavor="icon"
            onClick={deleteChannel}
          >
            <TrashIcon height="1rem" width="1.8rem" />
          </StyledButton>
          <StyledButton
            $small={size === 1}
            flavor="icon"
            onClick={openChannelSettings}
          >
            <SettingsIcon height="1rem" width="1.8rem" />
          </StyledButton>
        </>
      ) : null}
    </Channel>
  );
};

export default ChannelItem;
