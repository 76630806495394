import styled from 'styled-components';

const DivContainer = styled.div`
  cursor: pointer;
  user-select: none;
  font-family: 'Inter', sans-serif;
  color: ${({ theme }) => theme.dark500};
  text-align: left;
`;

const Container = styled.button`
  cursor: pointer;
  user-select: none;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font-family: 'Inter', sans-serif;
  color: ${({ theme }) => theme.dark500};
  text-align: left;

  &:disabled {
    cursor: default;
  }
`;

const Wrapper = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
`;

const BasicButton = ({ children, className, CustomContainer, isDiv, ...props }) =>
  CustomContainer ? (
    <CustomContainer {...props}>
      <Wrapper className={className}>{children}</Wrapper>
    </CustomContainer>
  ) : (
    isDiv ? (
      <DivContainer {...props}>
        <Wrapper className={className}>{children}</Wrapper>
      </DivContainer>
    ) : (
      <Container {...props}>
        <Wrapper className={className}>{children}</Wrapper>
      </Container>
    )
  );

export default BasicButton;
