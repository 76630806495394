import gql from 'graphql-tag';

export const GET_MUSIC_REGIONS = gql`
  query getMusicRegions {
    musicRegions {
      id
      name
    }
  }
`;

export const GET_MUSIC_PROFILE_SETTINGS = gql`
  query getMusicProfileSettings($id: ID!) {
    musicProfileSettings(id: $id) {
      id
      userGroupId
      musicProfileTemplateId
      explicitContentAllowed
      localChangesAllowed
      defaultMusicSelection {
        id
        activeMode
      }
    }
  }
`;

export const GET_MUSIC_PROFILE_TEMPLATES = gql`
  query getMusicProfileTemplates($id: ID!) {
    musicProfileTemplates(id: $id) {
      id
      name
      brandId
      tunifyMusicProfileUserId
      externalRegionId
      musicLicenseId
    }
  }
`;

export const UPDATE_MUSIC_PROFILE_TEMPLATE_ID = gql`
  mutation updateMusicProfileTemplateId($id: ID!, $templateId: ID) {
    updateMusicProfileTemplateId(id: $id, templateId: $templateId) {
      id
      userGroupId
      musicProfileTemplateId
      explicitContentAllowed
      localChangesAllowed
      defaultMusicSelection {
        id
        activeMode
      }
    }
  }
`;

export const UPDATE_EXPLICIT_CONTENT_ALLOWED = gql`
  mutation updateExplicitContentAllowed($id: ID!, $value: Boolean!) {
    updateExplicitContentAllowed(id: $id, value: $value) {
      id
      userGroupId
      musicProfileTemplateId
      explicitContentAllowed
      localChangesAllowed
      defaultMusicSelection {
        id
        activeMode
      }
    }
  }
`;

export const UPDATE_LOCAL_CHANGES_ALLOWED = gql`
  mutation updateLocalChangesAllowed($id: ID!, $value: Boolean!) {
    updateLocalChangesAllowed(id: $id, value: $value) {
      id
      userGroupId
      musicProfileTemplateId
      explicitContentAllowed
      localChangesAllowed
      defaultMusicSelection {
        id
        activeMode
      }
    }
  }
`;

export const GET_ALL_PRODUCTS = gql`
  query getAllProducts {
    allProducts {
      id
      isForever
      shortName
      musicLicense {
        id
        name
        description
        selectionOrder
      }
    }
  }
`;

export const EDIT_ZONE = gql`
  mutation editZone($zoneId: ID!, $editZone: EditZoneInput!) {
    editZone(zoneId: $zoneId, zone: $editZone) {
      id
      name
    }
  }
`;

export const GET_TUNIFY_CHANNEL_GROUPS = gql`
  query getTunifyChannelGroups($musicProfileId: ID!, $language: String!) {
    tunifyChannelGroups(musicProfileId: $musicProfileId, language: $language) {
      id
      name
      properties {
        visible
      }
      musicChannels {
        id
        name
        musicChannelGroupId
        properties {
          visible
        }
        musicCollections {
          id
          title
          selected
          type
        }
      }
    }
  }
`;

export const GET_TUNIFY_CALENDAR_GROUPS = gql`
  query getTunifyCalendarGroups($musicProfileId: ID!, $language: String!) {
    tunifyCalendarGroups(musicProfileId: $musicProfileId, language: $language) {
      id
      name
      properties {
        visible
      }
      calendars {
        calendarId
        name
        calendarGroupId
        properties {
          visible
        }
      }
    }
  }
`;

export const UPDATE_DEFAULT_MUSIC_SELECTION = gql`
  mutation updateDefaultMusicSelection($id: ID!, $musicSelection: MusicSelectionInput) {
    updateDefaultMusicSelection(id: $id, musicSelection: $musicSelection) {
      id
      userGroupId
      musicProfileTemplateId
      explicitContentAllowed
      localChangesAllowed
      defaultMusicSelection {
        id
        activeMode
      }
    }
  }
`;
