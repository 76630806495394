import { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { useForm } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import { BasicButton, Button, DateField, formatDate, TimeField } from '../../../common';
import { ReactComponent as RawEditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as RawCloseIcon } from '../../../assets/icons/close.svg';
import theme from '../../../global/style/theme';

const Wrapper = styled.div`
  display: grid;
  gap: 0.4rem;
  margin-bottom: 2rem;
`;

const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;

  ${({ $withoutBorder }) =>
    !$withoutBorder &&
    css`
      border-bottom: 2px solid ${({ theme }) => theme.stroke};
    `}
`;

const TopInfo = styled.div`
  display: grid;
  gap: 0.8rem;
  padding: 1rem 0;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  display: flex;
  align-items: center;

  & span {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.dark300};
  }

  ${({ $soft }) =>
    $soft &&
    css`
      color: ${({ theme }) => theme.dark300};
    `}
`;

const SubTitle = styled.h3`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.dark400};
`;

const IconButton = styled(BasicButton)`
  padding: 1.2rem;
`;

const Container = styled.div`
  display: grid;
  gap: 1.6rem;
  align-items: end;
  padding: 2.4rem 1.6rem;
  border: 1px solid ${({ theme }) => theme.stroke};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.white};

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.small}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    grid-template-columns: 2fr 2fr 1fr 1fr min-content;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 1.6rem;
  align-items: center;
  justify-content: space-between;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.small}px) {
    display: grid;
    gap: 1.6rem;
    align-items: center;
    grid-template-columns: max-content max-content;
    grid-column: span 2;
    justify-content: end;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    grid-column: unset;
  }
`;

const Label = styled.div`
  transition: color 0.2s ease-in-out;
  color: ${({ disabled, theme }) => (disabled ? theme.dark300 : theme.dark500)};
  font-size: 1.4rem;
  line-height: 1.4;
  padding-bottom: 0.4rem;
`;

const InputLookALike = styled.div`
  width: 100%;
  height: 4.8rem;
  outline: none;
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.stroke};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  line-height: 1.2;
  padding: 0 1.6rem;
  color: ${({ theme }) => theme.dark500};
  background-color: transparent;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.2s ease-in-out;
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;

  &:hover {
    border-color: ${({ theme }) => theme.dark300};
  }

  &:active,
  :focus {
    border-color: ${({ theme }) => theme.waterBlue};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      color: ${({ theme }) => theme.dark100};
      border-color: ${({ theme }) => theme.stroke};

      &:hover {
        border-color: ${({ theme }) => theme.stroke};
      }
    `}
`;

const StyledButton = styled(Button)`
  & p {
    font-size: 1.2rem;
    text-transform: lowercase;
    color: ${({ theme }) => theme.waterBlue};
  }
`;

const SmallDivider = styled.div`
  height: 1.6rem;
  width: 1px;
  background-color: ${({ theme }) => theme.dark100};
  margin: 0 1rem;
`;

const Filter = ({
  toggleZoneSelectionModal,
  selectedZone,
  musicHistoryLoading,
  selectedDate,
  changeDate,
  fromTime,
  toTime,
  changeFromTime,
  changeToTime,
  tempSelectedZone,
  setTempZone,
  setConfirmedZone,
}) => {
  const { t } = useTranslation();
  const [showFilter, setShowFilter] = useState(false);

  const { control, setValue, handleSubmit } = useForm({
    defaultValues: { date: selectedDate, from: fromTime, to: toTime },
  });

  const resetFilters = useCallback(() => {
    setValue('from', fromTime);
    setValue('to', toTime);
    setValue('date', selectedDate);
    setTempZone(selectedZone);
  }, [selectedDate, fromTime, toTime, setValue, selectedZone, setTempZone]);

  const closeFilter = useCallback(() => {
    setShowFilter(false);
    // resetFilters();
  }, []);

  const onSubmit = useCallback(
    (values) => {
      changeFromTime(values.from);
      changeToTime(values.to);
      changeDate(values.date);
      setConfirmedZone(tempSelectedZone);
      closeFilter();
    },
    [changeFromTime, changeToTime, changeDate, setConfirmedZone, tempSelectedZone, closeFilter]
  );

  return (
    <Wrapper>
      {showFilter ? (
        <>
          <InfoContainer $withoutBorder>
            <Title $soft>{t('musicManagement:musicReport.filter.setFilters')}</Title>
            <IconButton onClick={closeFilter}>
              <RawCloseIcon width="1.2rem" height="1.2rem" color={theme.dark300} />
            </IconButton>
          </InfoContainer>
          <Container>
            <div>
              <Label>{t('musicManagement:musicHistory.filter.zone')}</Label>
              <InputLookALike>
                <span>{tempSelectedZone ? `${tempSelectedZone.location.name} - ${tempSelectedZone.name}` : ''}</span>
                <StyledButton
                  emphasis="secondary"
                  size="small"
                  isDisabled={musicHistoryLoading}
                  onClick={toggleZoneSelectionModal}
                >
                  {t('common:button.select')}
                </StyledButton>
              </InputLookALike>
            </div>
            <DateField
              control={control}
              name="date"
              disabled={musicHistoryLoading}
              maxDate={new Date()}
              label={t('common:form.label.date')}
            />
            <TimeField control={control} name="from" disabled={musicHistoryLoading} label={t('glossary:from')} withColon />
            <TimeField control={control} name="to" disabled={musicHistoryLoading} label={t('glossary:to')} withColon />
            <ButtonContainer>
              <Button
                emphasis="secondary"
                highlightColor="lavenderPurple"
                isDisabled={musicHistoryLoading}
                onClick={resetFilters}
              >
                {t('musicManagement:musicReport.filter.resetFilters')}
              </Button>
              <Button isDisabled={musicHistoryLoading} onClick={handleSubmit(onSubmit)}>
                {t('musicManagement:musicReport.filter.showHistory')}
              </Button>
            </ButtonContainer>
          </Container>
        </>
      ) : (
        <InfoContainer>
          <TopInfo>
            {selectedZone ? (
              <Title>
                {selectedZone.name}
                <span>
                  <SmallDivider />
                  {selectedZone.location.name}
                  <SmallDivider />
                  {selectedZone.location.organization.name}
                </span>
              </Title>
            ) : null}
            <SubTitle>
              {formatDate(selectedDate)}, {fromTime} - {toTime}
            </SubTitle>
          </TopInfo>
          <IconButton onClick={() => setShowFilter(true)}>
            <RawEditIcon width="1.6rem" height="1.6rem" color={theme.dark300} />
          </IconButton>
        </InfoContainer>
      )}
    </Wrapper>
  );
};

export default Filter;
