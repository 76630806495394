import { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import ReactSelect from 'react-select';
import theme from '../../../../../global/style/theme';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const styles = {
  control: (style, state) => ({
    ...style,
    minHeight: '4.8rem',
    borderRadius: '4px',
    '&:hover': !state.isFocused
      ? {
          borderColor: theme.stroke,
        }
      : null,
    cursor: 'text',
    transition: 'border-color 0.2s ease-in-out',
    boxShadow: 'none',
    fontSize: '1.4rem',
    lineHeight: '1.4',
    fontWeight: '500',
    backgroundColor: 'transparent',
    background: `linear-gradient(0deg, ${theme.gradient['fade'][0]} 0%,  ${theme.gradient['fade'][1]} 100%)`,
    border: state.isDisabled
      ? `2px solid ${theme.stroke}`
      : state.isFocused
      ? `2px solid ${theme.waterBlue}`
      : `2px solid ${theme.stroke}`,
  }),
  input: (style) => ({
    ...style,
    fontWeight: '500',
    lineHeight: '1.4',
    fontSize: '1.4rem',
    '&:focus': {
      border: 'none',
      outline: 'none',
    },
    '&:hover': {
      border: 'none',
      outline: 'none',
    },
    '&:active': {
      border: 'none',
      outline: 'none',
    },
  }),
  container: (style) => ({
    ...style,
    '&:focus': {
      border: 'none',
      outline: 'none',
    },
    '&:hover': {
      border: 'none',
      outline: 'none',
    },
    '&:active': {
      border: 'none',
      outline: 'none',
    },
  }),
  valueContainer: (style) => ({
    ...style,
    paddingLeft: '1.6rem',
  }),
  indicatorSeparator: () => ({}),
  placeholder: (style, state) => ({
    ...style,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    color: state.isDisabled ? theme.stroke : theme.dark300,
    fontWeight: '400',
    lineHeight: '1.4',
    fontSize: '1.4rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  option: (style, state) => ({
    ...style,
    padding: '1.4rem 1.6rem',
    cursor: 'pointer',
    '&:hover': {
      color: theme.dark400,
      backgroundColor: theme.background,
    },
    transition: 'background-color 0.2s ease-in-out, color 0.2s ease-in-out',
    color: state.isSelected ? theme.dark500 : theme.dark300,
    backgroundColor: 'transparent',
    fontSize: '1.4rem',
    fontWeight: '500',
  }),
  indicatorsContainer: (style) => ({
    ...style,
    cursor: 'pointer',
  }),
  singleValue: (styles) => ({
    ...styles,
    fontSize: '1.4rem',
    color: theme.dark500,
  }),
  menu: (styles) => ({
    ...styles,
    border: `2px solid ${theme.waterBlue}`,
    boxShadow: 'none',
    fontSize: '1.4rem',
    zIndex: 2000,
  }),
};

const StyledReactSelect = styled(ReactSelect)`
  ${({ error }) =>
    error &&
    `
  & > div {
    border-color: ${theme.grapefruitRed};
    background: #feebe7;
  }
  
  `}
`;

/**
 * @typedef { import('react-select').Props } SelectFieldProps
 */

/**
 *
 * @type React.ForwardRefRenderFunction<unknown, SelectFieldProps>
 */
export const SelectField = forwardRef((props, ref) => {
  return <StyledReactSelect {...props} ref={ref} styles={styles} />;
});

/**
 * @typedef { import('react-hook-form').ControllerProps } ControlledSelectFieldProps
 */

/**
 * @param {{ name: ControlledSelectFieldProps.name, control: ControlledSelectFieldProps.control, defaultValues: ControlledSelectFieldProps.defaultValues, options: SelectFieldProps.options, placeholder: SelectFieldProps.placeholder, isLoading?: boolean }} props
 * @returns
 */
export const ControlledSelectField = (props) => {
  const { t } = useTranslation();

  return (
    <Controller
      {...props}
      render={({ field }) => (
        <SelectField
          {...field}
          options={props.options}
          placeholder={props.placeholder}
          isLoading={props.isLoading}
          menuShouldScrollIntoView
          error={props.error}
          noOptionsMessage={() => t('glossary:noOptionsAvailable')}
        />
      )}
    />
  );
};
