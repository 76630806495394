import { useCallback, useMemo, Children } from 'react';

import { WizardContext } from './wizardContext';

const Wizard = ({ activeStep, setActiveStep, children, handleNextStep, stepDirection, setStepDirection }) => {
  const nextStep = useCallback(
    (values) => {
      setStepDirection('forward');
      handleNextStep(values);
    },
    [handleNextStep, setStepDirection]
  );

  const previousStep = useCallback(() => {
    setStepDirection('backward');
    setActiveStep(activeStep - 1);
  }, [activeStep, setActiveStep, setStepDirection]);

  const wizardProviderValue = useMemo(
    () => ({
      stepDirection,
      nextStep,
      previousStep,
    }),
    [nextStep, previousStep, stepDirection]
  );

  const activeStepContent = useMemo(() => Children.toArray(children)[activeStep], [activeStep, children]);

  return <WizardContext.Provider value={wizardProviderValue}>{activeStepContent}</WizardContext.Provider>;
};

export default Wizard;
