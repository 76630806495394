import { useState, useEffect, useCallback, useMemo } from 'react';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { setHours, setMinutes, subHours, format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { useToggle, ZoneSelectionModal } from '../../../common';
import { MusicHistoryTable } from './musicHistoryTable';
import { GET_MUSIC_HISTORY, GET_ZONES, GET_USER_IDS_FROM_EXTERNAL_ZONE_IDS } from './api';
import Filter from './filter';

function normalizeTimeFormat(time) {
  return /^\d{4}$/.test(time) ? `${time.substring(0, 2)}:${time.substring(2,4)}` : time;
}

const MusicHistory = () => {
  const { t } = useTranslation();

  const [confirmedZone, setConfirmedZone] = useState();
  const [tempZone, setTempZone] = useState();
  const [selectedDate, setDate] = useState(new Date());
  const [fromTime, setFromTime] = useState(format(subHours(new Date(), 2), 'HH:mm'));
  const [toTime, setToTime] = useState(format(new Date(), 'HH:mm'));

  const [zoneSelectionModalIsOpen, toggleZoneSelectionModal] = useToggle(false);

  const {
    loading: zonesLoading,
    error: zonesError,
    data: zonesData,
  } = useQuery(GET_ZONES, {
    onCompleted: (data) => {
      if (data?.zones?.length > 0) {
        setConfirmedZone(data.zones[0]);
        setTempZone(data.zones[0]);
      }
    },
  });

  const { data: dataNewZoneIds } = useQuery(GET_USER_IDS_FROM_EXTERNAL_ZONE_IDS, {
    variables: {
      zoneIds: zonesData?.zones?.map((zone) => Number(zone.id)),
    },
    skip: !zonesData?.zones || !zonesData?.zones?.map((zone) => zone.id).length === 0,
  });

  const afterDateString = useMemo(() => {
    const normalizedFromTime = normalizeTimeFormat(fromTime);
    const dateWithHours = setHours(selectedDate, normalizedFromTime.split(':')[0]);
    const dateWithHoursAndMinutes = setMinutes(dateWithHours, normalizedFromTime.split(':')[1]);
    return dateWithHoursAndMinutes.toISOString();
  }, [selectedDate, fromTime]);

  const beforeDateString = useMemo(() => {
    const normalizedToTime = normalizeTimeFormat(toTime);
    const dateWithHours = setHours(selectedDate, normalizedToTime.split(':')[0]);
    const dateWithHoursAndMinutes = setMinutes(dateWithHours, normalizedToTime.split(':')[1]);
    return dateWithHoursAndMinutes.toISOString();
  }, [selectedDate, toTime]);

  const [getMusicHistory, { loading: musicHistoryLoading, data: musicHistoryData, error: musicHistoryError }] =
    useLazyQuery(GET_MUSIC_HISTORY, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    const zoneId = dataNewZoneIds?.userIdsFromExternalZoneIds?.find(
      (externalZone) => Number(externalZone.externalZoneId) === Number(confirmedZone?.id)
    )?.zoneId;
    if (confirmedZone && afterDateString && beforeDateString && zoneId) {
      getMusicHistory({
        variables: {
          zoneId: Number(confirmedZone.id),
          externalZoneId: Number(zoneId),
          afterDateString,
          beforeDateString,
        },
      });
    }
  }, [getMusicHistory, confirmedZone, afterDateString, beforeDateString, dataNewZoneIds?.userIdsFromExternalZoneIds]);

  const confirmSelectedZone = useCallback(
    (zone) => {
      setTempZone(zone);
      toggleZoneSelectionModal();
    },
    [toggleZoneSelectionModal]
  );

  const changeDate = useCallback((newDate) => {
    setDate(newDate);
  }, []);

  const changeFromTime = useCallback((newFromTime) => {
    setFromTime(newFromTime);
  }, []);

  const changeToTime = useCallback((newToTime) => {
    setToTime(newToTime);
  }, []);

  return (
    <>
      <Filter
        changeDate={changeDate}
        changeFromTime={changeFromTime}
        changeToTime={changeToTime}
        fromTime={fromTime}
        musicReportLoading={musicHistoryLoading || zonesLoading}
        selectedDate={selectedDate}
        selectedZone={confirmedZone}
        tempSelectedZone={tempZone}
        setTempZone={setTempZone}
        setConfirmedZone={setConfirmedZone}
        toggleZoneSelectionModal={toggleZoneSelectionModal}
        toTime={toTime}
      />
      <MusicHistoryTable
        musicHistory={musicHistoryData?.musicHistory.musicHistory ?? []}
        musicHistoryError={musicHistoryError}
        musicHistoryLoading={musicHistoryLoading}
        selectedZone={confirmedZone}
      />
      <ZoneSelectionModal
        defaultSelected={tempZone}
        error={zonesError}
        instructions={t('musicManagement:musicReport.zoneSelectionModal.instructions')}
        isLoading={zonesLoading}
        isOpen={zoneSelectionModalIsOpen}
        onClose={toggleZoneSelectionModal}
        onConfirm={confirmSelectedZone}
        title={t('musicManagement:musicReport.zoneSelectionModaltitle')}
        zones={
          zonesData?.zones?.map((zone) => ({
            ...zone,
            organizationName: zone.location.organization.name,
            locationName: zone.location.name,
          })) || []
        }
      />
    </>
  );
};

export default MusicHistory;
