import styled from 'styled-components';

import ErrorMessage from '../common/errorMessage';
import { ReactComponent as SmallCheckMarkIcon } from '../../../assets/icons/smallCheckmark.svg';

const Container = styled.label`
  width: fit-content;
  display: flex;
  cursor: pointer;
  font-size: 1.4rem;
  line-height: 1.4;
  user-select: none;
  position: relative;
`;

const Wrapper = styled.div`
  & input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
    background-color: transparent;
  }
  & input:checked ~ div {
    border-color: ${({ theme, color }) => theme[color]};
  }
  & input:checked ~ div > svg {
    color: ${({ theme, color }) => theme[color]};
  }
  :hover input:not(:checked) ~ div {
    border-color: ${({ theme }) => theme.dark300};
  }
  ${({ label, reverse }) => {
    if (label) {
      if (reverse) {
        return `padding-left: 1.2rem;`;
      } else {
        return `padding-right: 1.2rem;`;
      }
    }
    return '';
  }}
`;

const Checkmark = styled.div`
  height: 2rem;
  width: 2rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${({ theme }) => theme.stroke};
  transition: 0.2s border-color ease-in-out;
  background: ${({ theme }) =>
    `linear-gradient(0deg, ${theme.gradient['fade'][0]} 0%,  ${theme.gradient['fade'][1]} 100%)`};
  & > svg {
    transition: 0.2s color ease-in-out;
  }
`;

const CheckboxField = ({
  error,
  label = '',
  color = 'plantGreen',
  className,
  name,
  register = () => {},
  onChange = () => {},
  reverse,
  ...rest
}) => (
  <div className={className}>
    <Container>
      {reverse ? label : null}
      <Wrapper reverse={reverse} label={label} color={color}>
        <input
          name={name}
          onChange={onChange}
          {...register(name)}
          {...rest}
          type="checkbox"
        />
        <Checkmark>
          <SmallCheckMarkIcon
            width="1.1rem"
            height="1.1rem"
            color="transparent"
          />
        </Checkmark>
      </Wrapper>
      {!reverse ? label : null}
    </Container>
    {typeof error === 'string' ? <ErrorMessage>{error}</ErrorMessage> : null}
  </div>
);

export default CheckboxField;
