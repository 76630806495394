import { createContext, useCallback, useContext, useRef } from "react";
import config from "../../config";

const LanguageGetterContext = createContext(() => {
  throw new Error('Missing LanguageGetterContext provider.');
});

const LanguageSetterContext = createContext(() => {
  throw new Error('Missing LanguageSetterContext provider.');
});

/**
 * @returns A function to get an up-to-date value of the language of the provider in format `ln-CN`.
 */
export function useLanguageGetter() {
  return useContext(LanguageGetterContext);
}

/**
 * @returns A function to set an up-to-date value of the language of the provider in format `ln-CN`.
 */
export function useLanguageSetter() {
  return useContext(LanguageSetterContext);
}

export function LanguageGetterProvider({ children }) {
  const languageRef = useRef(config.LANGUAGE_FALLBACK);

  const setter = useCallback((newLanguage) => {
    languageRef.current = newLanguage;
  }, [])

  const getter = useCallback(() => languageRef.current, []);

  return (
    <LanguageSetterContext.Provider value={setter}>
      <LanguageGetterContext.Provider value={getter}>
        {children}
      </LanguageGetterContext.Provider>
    </LanguageSetterContext.Provider>
  );
}
