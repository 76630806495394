import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

import { LoadingIndicator } from '../../../../../common';
import { ReactComponent as ArrowRight } from '../../../../../assets/icons/arrowRight.svg';
import { CollapsableButton } from '../../../shared';
import Channels from './channels';
import { useTunifyGreen } from '../tunifyGreenProvider';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  ${({ collapsed }) => collapsed && 'opacity: 0.7;'}
`;

const Wrapper = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const StyledArrowRight = styled(ArrowRight)`
  transform: ${({ $collapsed }) =>
    $collapsed ? 'rotate(90deg)' : 'rotate(270deg)'};
  margin-right: 0.5rem;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin: 1rem 0;
`;

const MainCalendars = ({
  name,
  id,
  openedCollapsible,
  setOpenedCollapsible,
}) => {
  const { tunifyCalendars, loadingTunifyCalendars, musicProfile } =
    useTunifyGreen();

  const [openedSubCollapsible, setOpenedSubCollapsible] = useState(
    tunifyCalendars?.[0]?.id
  );

  const collapsed = openedCollapsible !== 'tunify';

  const handleOpenCollapsible = useCallback(() => {
    if (collapsed) {
      setOpenedCollapsible('tunify');
    } else {
      setOpenedCollapsible('');
    }
  }, [setOpenedCollapsible, collapsed]);

  return (
    <Container>
      <CollapsableButton
        $collapsed={collapsed}
        onClick={handleOpenCollapsible}
        type="button"
      >
        <p>{name}</p>
        <StyledArrowRight
          $collapsed={collapsed}
          height="0.7rem"
          width="0.7rem"
        />
      </CollapsableButton>
      <Wrapper>
        <AnimatePresence>
          {!collapsed ? (
            <motion.div
              animate={{ height: 'auto' }}
              exit={{ height: 0 }}
              initial={{ height: 0 }}
              transition={{ ease: 'easeInOut', duration: 0.3 }}
            >
              {!loadingTunifyCalendars ? (
                tunifyCalendars?.map((channel) => (
                  <Channels
                    key={channel.id}
                    calendars={channel.calendars}
                    channel={channel}
                    musicProfile={musicProfile}
                    openedSubCollapsible={openedSubCollapsible}
                    setOpenedSubCollapsible={setOpenedSubCollapsible}
                  />
                ))
              ) : (
                <StyledLoadingIndicator color="primary" size="small" />
              )}
            </motion.div>
          ) : null}
        </AnimatePresence>
      </Wrapper>
    </Container>
  );
};

export default MainCalendars;
