import gql from 'graphql-tag';

export const GET_MESSAGE_PROFILES = gql`
  query getMessageProfiles($defaultBrandName: String!, $defaultMessageProfileName: String!) {
    messageProfiles(defaultBrandName: $defaultBrandName, defaultMessageProfileName: $defaultMessageProfileName) {
      id
      name
    }
  }
`;

export const GET_ORGANIZATION_WITH_LOCATIONS = gql`
  query getOrganizationsWithLocations {
    organizations {
      id
      name
      locations {
        id
        name
        streetName
        streetNumber
        city
        zip
        organization {
          id
        }
      }
    }
  }
`;

export const GET_LOCATION_BY_ID = gql`
  query getLocationById($locationId: ID!) {
    location(id: $locationId) {
      id
      name
      zones {
        id
        name
        messageProfile {
          id
          name
        }
      }
    }
  }
`;

export const GET_DETAILED_ZONE_BY_ID = gql`
  query zone($zoneId: ID!) {
    zone(id: $zoneId) {
      id
      name
      messageProfile {
        id
        name
      }
      location {
        id
        organization {
          id
        }
      }
      currentSubscription {
        id
        startDate
        endDate
        hasForeverRenewalOn
        subCreatePolicy
        subscriptionLines {
          id
          product {
            id
            name
            shortName
            level
            isAddon
            type
            categoryId
            subscriptionTemplate {
              id
              recurringRuleBoundary
            }
          }
        }
      }
    }
  }
`;

export const CHANGE_MESSAGE_PROFILE_OF_ZONES = gql`
  mutation changeMessageProfileOfZones($entries: [ChangeMessageProfileOfZoneInputData!]!) {
    changeMessageProfileOfZones(entries: $entries) {
      myTunifyZoneId
      messageProfileId
    }
  }
`;

export const GET_OPENING_HOURS_SET = gql`
  query getLocationOpeningHoursSet($locationIds: [ID!]!) {
    locationOpeningHoursSet(locationIds: $locationIds) {
      locationId
      isSet
    }
  }
`;
