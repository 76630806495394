import { useCallback } from 'react';
import styled from 'styled-components';
import Select from 'react-select';

import { useTranslation } from 'react-i18next';
import Label from '../common/label';
import ErrorMessage from '../common/errorMessage';

const styles = {
  control: (style, state) => ({
    ...style,
    height: '2.5rem',
    borderRadius: '2px',
    '&:hover': {
      borderColor: 'none',
    },
    cursor: 'text',
    transition: 'border-color 0.333s ease-in-out',
    boxShadow: 'none',
    fontSize: '0.875rem',
    fontWeight: '400',
    border: state.isFocused ? '1px solid #4A75BB' : '1px solid #BFC5D2',
  }),
  input: (style) => ({
    ...style,
    fontWeight: '300',
    lineHeight: '1.2rem',
    fontSize: '0.875rem',
    '&:focus': {
      border: 'none',
      outline: 'none',
    },
    '&:hover': {
      border: 'none',
      outline: 'none',
    },
    '&:active': {
      border: 'none',
      outline: 'none',
    },
  }),
  container: (style) => ({
    ...style,
    '&:focus': {
      border: 'none',
      outline: 'none',
    },
    '&:hover': {
      border: 'none',
      outline: 'none',
    },
    '&:active': {
      border: 'none',
      outline: 'none',
    },
  }),
  indicatorSeparator: () => ({}),
  placeholder: (style) => ({
    ...style,
    fontFamily: 'Inter',
    fontStyle: 'italic',
    color: '#8798AD',
    fontWeight: '300',
    lineHeight: '1.2rem',
    fontSize: '0.875rem',
  }),
  option: (style, state) => ({
    ...style,
    cursor: 'pointer',
    '&:hover': {
      color: '#4A75BB',
      backgroundColor: '#f4f6fc',
    },
    transition: 'background-color 250ms linear, color 250ms linear',
    color: state.isSelected ? 'white' : '#2e384d',
    backgroundColor: state.isSelected ? '#04B4E0' : state.isFocused ? '#f4f6fc' : 'white',
    fontSize: '0.875rem',
    fontWeight: '400',
  }),
  indicatorsContainer: (style) => ({
    ...style,
    cursor: 'pointer',
  }),
};

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
`;

const SingleSelectField = ({
  disabled,
  error,
  label,
  onChange,
  options,
  placeholder,
  required,
  name,
  value,
  isLoading,
  className,
  isSearchable,
}) => {
  const { t } = useTranslation();

  const onChangeCb = useCallback(
    ({ value: optionValue }) => {
      onChange(name, optionValue);
    },
    [onChange, name]
  );

  const selectedOption = options.find(({ value: optionValue }) => optionValue === value) || null;

  // React-select only accepts a function
  const noOptionsMessageCb = useCallback(() => t('glossary:noOptionsAvailable'), [t]);

  // React-select only accepts a function
  const loadingMessageCb = useCallback(() => t('glossary:loading'), [t]);

  return (
    <InputWrapper className={className}>
      {label && <Label required={required}>{label}</Label>}
      <Select
        isDisabled={disabled}
        isLoading={isLoading}
        isSearchable={isSearchable}
        loadingMessage={loadingMessageCb}
        name={name}
        noOptionsMessage={noOptionsMessageCb}
        onChange={onChangeCb}
        options={options}
        placeholder={placeholder}
        styles={styles}
        value={selectedOption}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </InputWrapper>
  );
};

SingleSelectField.displayName = 'SingleSelectField';

export default SingleSelectField;

export { default as Dropdown } from './dropdownField';
