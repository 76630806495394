export const determineAvailableAddonCategoriesForZone = (
  zone,
  selectedAddons,
  skipAddonFiltering
) => {
  const currentAddonsOfZone =
    zone.currentSubscription?.subscriptionLines.filter(
      (subscriptionLine) => subscriptionLine.product.isAddon
    );

  if (!currentAddonsOfZone) {
    // Zone has no current subscription
    return selectedAddons;
  }

  if (skipAddonFiltering) return selectedAddons;

  const availableAddonsForZone = selectedAddons.filter(
    (selectedAddon) =>
      !currentAddonsOfZone.some(
        (currentAddonOfZone) =>
          currentAddonOfZone.product.shortName === selectedAddon.shortName
      )
  );

  return availableAddonsForZone;
};
