import gql from 'graphql-tag';

export const REGISTER_USER = gql`
  mutation registerUser(
    $firstName: String!
    $lastName: String!
    $emailAddress: String!
    $phone: String!
    $password: String!
    $organizationName: String!
    $countryId: Int!
    $streetName: String!
    $streetNumber: String!
    $zip: String!
    $city: String!
    $industryId: Int!
    $isPartOfChain: Boolean!
    $googlePlaceCode: String!
    $productCode: String
    $timeZone: String!
    $registrationCode: String!
    $memberCode: String!
    $memberGetMemberProductId: Int
  ) {
    register(
      user: {
        firstName: $firstName
        lastName: $lastName
        emailAddress: $emailAddress
        phone: $phone
        password: $password
        organizationName: $organizationName
        countryId: $countryId
        streetName: $streetName
        streetNumber: $streetNumber
        zip: $zip
        city: $city
        industryId: $industryId
        isChainCustomer: $isPartOfChain
        googlePlaceCode: $googlePlaceCode
        productCode: $productCode
        timeZone: $timeZone
        registrationCode: $registrationCode
        memberCode: $memberCode
        memberGetMemberProductId: $memberGetMemberProductId
      }
    ) {
      playerLink
      productMusicLicenseId
      productDefaultCode
      organizationId
      userId
    }
  }
`;

export const GET_INDUSTRIES_AND_COUNTRIES = gql`
  query getIndustriesAndCountries {
    industries {
      id
      name
    }
    countries {
      id
      name
      code
    }
  }
`;

export const GET_PRODUCT = gql`
  query getProductByBarcode($barcode: String) {
    productByBarcode(barcode: $barcode) {
      id
      name
      registrationDescription
    }
  }
`;

export const GET_PROGRAM_LINE_REGISTRATION_TEXT = gql`
  query getPartnerProgramLineRegistrationText($code: String!) {
    partnerProgramLineRegistrationText(code: $code)
  }
`;

export const GET_PARTNER_PROGRAM_REGISTRATION_IMAGE = gql`
  query getPartnerProgramRegistrationImage($code: String!) {
    partnerProgramRegistrationImage(code: $code)
  }
`;

export const GET_CUSTOMER_PROGRAM = gql`
  query getCustomerProgram($customerCode: String!) {
    customerProgram(customerCode: $customerCode) {
      referrerFullName
      commercialProductId
      royaltyFreeProductId
      commercialProductRecurringInterval
      royaltyFreeProductRecurringInterval
    }
  }
`;

export const SEND_REGISTRATION_PAGE_UPDATE = gql`
  mutation sendRegistrationPageUpdate(
    $eventUrl: String!
    $eventType: String!
    $email: String!
    $country: String
  ) {
    sendRegistrationPageUpdate(
      eventUrl: $eventUrl
      eventType: $eventType
      email: $email
      country: $country
    ) {
      events_received
    }
  }
`;

export const REGISTER_THROUGH_GOOGLE = gql`
  mutation registerThroughGoogle($input: RegisterThroughGoogleInput!) {
    registerThroughGoogle(input: $input) {
      playerLink
      authCompleted
    }
  }
`;
