import { useContext, createContext } from 'react';

const WizardContext = createContext({
  stepDirection: '',
  nextStep: () => {},
  previousStep: () => {},
});

const useWizard = () => useContext(WizardContext);

export { WizardContext, useWizard };
