import { useRef, useCallback, useLayoutEffect } from 'react';
import styled, { css } from 'styled-components';

import Label from '../common/label';
import ErrorMessage from '../common/errorMessage';

const InputWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
`;

const InputField = styled.input`
  font-family: 'Inter', sans-serif;
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.dark200};
  font-size: 0.875rem;
  height: 2.5rem;
  line-height: 1.2rem;
  padding-bottom: 0;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-top: 0;
  transition: background-color 0.333s ease-in-out,
    border-color 0.333s ease-in-out;
  width: 100%;
  color: ${({ theme }) => theme.dark600};
  background-color: ${({ theme }) => theme.white};
  &:hover {
    border-color: ${({ theme }) => theme.berryBlue};
  }
  &:focus {
    border: 1px solid ${({ theme }) => theme.berryBlue};
    outline: none;
  }
  &:invalid {
    box-shadow: none;
  }
  &::placeholder {
    color: ${({ theme }) => theme.dark300};
    transition: color 0.333s ease-in-out;
    font-style: italic;
  }

  /* Vertical center placeholder on FireFox */
  ::-moz-placeholder {
    line-height: 2.4rem;
  }

  /* Color Safari password icon */
  ::-webkit-credentials-auto-fill-button {
    background-color: ${({ theme }) => theme.berryBlue};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      background-color: ${({ theme }) => theme.white};
      color: ${({ theme }) => theme.stroke};
      border-color: ${({ theme }) => theme.stroke};
      &:hover {
        border-color: ${({ theme }) => theme.stroke};
      }
    `}
`;

const StringField = ({
  browserAutoComplete,
  className,
  disabled,
  error,
  label,
  placeholder,
  required,
  type,
  name,
  value,
  pattern,
  onBlur,
  onChange,
  onFocus,
  shouldFocus,
  onKeyPress,
  onKeyDown,
  autoFocus,
}) => {
  const inputRef = useRef({});

  const focus = useCallback(() => {
    if (inputRef.current && inputRef.current.focus) {
      inputRef.current.focus();
    }
  }, []);

  useLayoutEffect(() => {
    if (shouldFocus) {
      focus();
    }
  }, [shouldFocus, focus]);

  return (
    <InputWrapper className={className}>
      {label && (
        <Label disabled={disabled} required={required}>
          {label}
        </Label>
      )}
      <InputField
        ref={inputRef}
        autoComplete={browserAutoComplete ? 'on' : 'off'}
        autoFocus={autoFocus}
        disabled={disabled}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onKeyPress={onKeyPress}
        pattern={pattern}
        placeholder={disabled ? '' : placeholder}
        type={type}
        value={value}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </InputWrapper>
  );
};

StringField.displayName = 'StringField';

export default StringField;
