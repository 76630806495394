import { useLazyQuery } from '@apollo/react-hooks';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { Button, ErrorMessage, Page, capitalize } from '..';
import { Invite } from '../../modules/purchase/purchase/invite';
import { GET_PLAYER_LINK } from './api';
import { Checkmark, Description, Icon, Paper, Title } from './styles';

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 1rem;
`;

const CenterContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  max-width: 644px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;

  @media (min-width: 768px) {
    padding: 5rem;
  }
`;

const SuccessContent = styled.div`
  width: 100%;
  height: fit-content;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.white};
  padding: 6.4rem 1.6rem;
  border: 1px solid ${({ theme }) => theme.stroke};

  display: flex;
  align-items: center;
  flex-direction: column;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    padding: 6.4rem 4rem;
  }

  & > div {
    max-width: 440px;
  }
`;

const FlexButtonGroup = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  border-color: ${({ theme }) => theme.gradient.nightfall[0]};
`;

const Accept = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const amount = searchParams.get('amount');
  const brand = searchParams.get('BRAND');
  const zoneIdsQueryParam = searchParams.get('zoneIds');

  const [getPlayerLink, { loading, error }] = useLazyQuery(GET_PLAYER_LINK, {
    onCompleted: ({ playerLink }) => {
      window.open(playerLink, '_blank', 'noopener noreferrer');
    },
    fetchPolicy: 'network-only',
  });

  const zoneIds = useMemo(
    () => zoneIdsQueryParam ? zoneIdsQueryParam.split(',') : [],
    [zoneIdsQueryParam]
  );
  const purchaseWasMadeForOneZone = zoneIds.length === 1;

  const goToInvoices = useCallback(() => {
    navigate('/invoices');
  }, [navigate]);

  const goToSubscriptions = useCallback(() => {
    navigate('/subscriptions');
  }, [navigate]);

  const goToPlayer = useCallback(() => {
    getPlayerLink({ variables: { zoneId: Number(zoneIds[0]) } });
  }, [getPlayerLink, zoneIds]);

  return (
    <Page title={t('purchase:payment.accept.title')}>
      <CenterContent>
        <Content>
          <SuccessContent>
            <div>
              <Paper>
                <Icon isSuccess>
                  <Checkmark />
                </Icon>
                <Title>{t('purchase:payment.accept.title')}</Title>
                <Description>
                  {t('purchase:payment.accept.description', { amount, brand })}
                </Description>
              </Paper>
              <FlexButtonGroup>
                <StyledButton
                  emphasis="outline"
                  onClick={goToInvoices}
                  title={t('common:button.title.goTo', {
                    value: t('common:button.title.invoices'),
                  })}
                >
                  {t('common:button.invoices')}
                </StyledButton>
                <Button
                  isLoading={loading}
                  highlightGradient="nightfall"
                  onClick={
                    purchaseWasMadeForOneZone ? goToPlayer : goToSubscriptions
                  }
                  title={
                    purchaseWasMadeForOneZone
                      ? capitalize(
                          t('common:button.title.goTo', {
                            value: t('common:button.title.player'),
                          })
                        )
                      : t('common:button.title.goTo', {
                          value: t('common:button.title.subscriptions'),
                        })
                  }
                >
                  {purchaseWasMadeForOneZone
                    ? t('common:button.goToPlayer')
                    : t('common:button.subscriptions')}
                </Button>
              </FlexButtonGroup>
              {error ? <StyledErrorMessage error={error} /> : null}
            </div>
          </SuccessContent>
          <Invite />
        </Content>
      </CenterContent>
    </Page>
  );
};

export default Accept;
