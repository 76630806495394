import { useCallback } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

import { useSortByObjectField } from '../../../../common';
import { ReactComponent as AngleDown } from '../../../../assets/icons/angleDown.svg';
import { CollapsableButton } from '../../shared';
import MusicChannel from './musicChannel';
import theme from '../../../../global/style/theme';

const Container = styled.div`
  height: 100%;
  display: grid;
`;

const BWrapper = styled.div`
  padding: 0 0.8rem;
`;

const Wrapper = styled(motion.div)`
  height: 100%;
  overflow-y: auto;
  border: 1px solid ${({ theme }) => theme.stroke};
  background-color: ${({ theme }) => theme.uiBackground};
  border-radius: 8px;
  padding: 0 1.2rem;
`;

const StyledAngleDown = styled(AngleDown)`
  transform: ${({ $collapsed }) => (!$collapsed ? 'rotate(180deg)' : '')};
`;

const MusicChannelWrapper = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.stroke};
  }
`;

const List = ({ name, id, lists, collapsibleType, setCollapsibleType }) => {
  const collapsed = collapsibleType !== id;

  const sortedLists = useSortByObjectField(lists, 'name');

  const handleOpenCollapsible = useCallback(() => {
    if (collapsed) {
      setCollapsibleType(id);
    } else {
      setCollapsibleType('');
    }
  }, [setCollapsibleType, collapsed, id]);

  return (
    <Container>
      <BWrapper>
        <CollapsableButton $collapsed={collapsed} onClick={handleOpenCollapsible} type="button">
          <p>{name}</p>
          <StyledAngleDown $collapsed={collapsed} color={theme.dark300} height="1rem" width="1rem" />
        </CollapsableButton>
      </BWrapper>
      <AnimatePresence>
        {!collapsed ? (
          <Wrapper
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            initial={{ height: 0 }}
            transition={{ ease: 'easeInOut', duration: 0.3 }}
          >
            {sortedLists?.length > 0
              ? sortedLists.map((musicChannel) => (
                  <MusicChannelWrapper>
                    <MusicChannel
                      key={musicChannel.id}
                      musicChannel={musicChannel}
                      musicCollections={musicChannel.musicCollections}
                    />
                  </MusicChannelWrapper>
                ))
              : null}
          </Wrapper>
        ) : null}
      </AnimatePresence>
    </Container>
  );
};

export default List;
