import { useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import {
  LoadingIndicator,
  ErrorMessage,
  SelectCard,
} from '../../../../../common';
import { useSaleOrder } from '../../saleOrderContext';
import { GET_PAYMENT_OPTIONS } from '../../api';

const Options = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Image = styled.img`
  border-radius: 5%;
`;

const Label = styled.h2`
  font-size: 1.4rem;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SelectCardWrapper = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  gap: 1rem;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 1rem;
`;

const PaymentOptions = () => {
  const { t } = useTranslation();
  const {
    organization,
    alteredSaleOrder,
    setSelectedPaymentMethod,
    selectedPaymentMethod,
    cartIsChanging,
    cartChangeHasError,
  } = useSaleOrder();

  const {
    loading: paymentOptionsLoading,
    data: paymentOptionsData,
    error: paymentOptionsError,
  } = useQuery(GET_PAYMENT_OPTIONS, {
    skip: !alteredSaleOrder || alteredSaleOrder.saleOrderLines.length === 0,
    variables: {
      countryId: organization.countryId,
      productIds: alteredSaleOrder.saleOrderLines.map(
        (saleOrderLine) => saleOrderLine.product.id
      ),
    },
    onCompleted: (data) => {
      if (data) {
        const paymentOptionIds = data.paymentOptions.map(({ id }) =>
          Number(id)
        );
        if (
          selectedPaymentMethod &&
          !paymentOptionIds.includes(Number(selectedPaymentMethod.id))
        ) {
          setSelectedPaymentMethod(null);
        }
      }
    },
  });

  const handleSelect = useCallback(
    (id) => {
      if (id !== selectedPaymentMethod?.id) {
        const selectedPaymentOption = paymentOptionsData.paymentOptions.find(
          (paymentOption) => paymentOption.id === id
        );
        setSelectedPaymentMethod(selectedPaymentOption);
      }
    },
    [setSelectedPaymentMethod, paymentOptionsData, selectedPaymentMethod]
  );

  const selectOptions = useMemo(
    () =>
      paymentOptionsData &&
      paymentOptionsData.paymentOptions.map((paymentMethod) => (
        <SelectCard
          key={paymentMethod.id}
          activeSelectCardId={selectedPaymentMethod?.id}
          id={paymentMethod.id}
          onClick={handleSelect}
          title={
            <SelectCardWrapper>
              <Image src={`data:image/png;base64,${paymentMethod.image}`} />
              <Label>{paymentMethod.name}</Label>
            </SelectCardWrapper>
          }
        ></SelectCard>
      )),
    [paymentOptionsData, handleSelect, selectedPaymentMethod]
  );

  const pageContent = useMemo(
    () => (
      <>
        {(paymentOptionsError || paymentOptionsLoading || cartIsChanging) && (
          <>
            {paymentOptionsError && (
              <ErrorMessage error={paymentOptionsError} />
            )}
            {(paymentOptionsLoading || cartIsChanging) && <LoadingIndicator />}
          </>
        )}
        {!paymentOptionsLoading &&
          !cartIsChanging &&
          !paymentOptionsError &&
          !cartChangeHasError && (
            <>
              <Options>{selectOptions}</Options>
            </>
          )}
        {selectedPaymentMethod?.isWireTransfer ? (
          <StyledErrorMessage
            error={t('subscriptions:warningIsWireTransfer')}
          />
        ) : null}
      </>
    ),
    [
      paymentOptionsError,
      paymentOptionsLoading,
      selectOptions,
      cartIsChanging,
      cartChangeHasError,
      selectedPaymentMethod,
      t,
    ]
  );
  return pageContent;
};

export default PaymentOptions;
