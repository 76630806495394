import { useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ErrorMessage, Button, formatDate } from '../../../../../common';
import { Card } from '../../../shared';
import theme from '../../../../../global/style/theme';
import { usePartner } from '../../../partnerContext';
import { ReactComponent as RawEditIcon } from '../../../../../assets/icons/edit.svg';

const StyledErrorMessage = styled(ErrorMessage)`
  margin: 1rem 0;
`;

const Container = styled.div`
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
  }
`;

const Grid = styled.div`
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 3rem;
`;

const Name = styled.p`
  text-transform: uppercase;
  word-break: break-word;
  color: ${({ theme }) => theme.dark200};
  font-size: 1rem;
  line-height: 1.6;
  letter-spacing: 1px;
  font-family: 'Inter', sans-serif;
`;

const EditIcon = styled(RawEditIcon)`
  margin-right: 0.5rem;
  width: 1.6rem;
  height: 1.6rem;
`;

const PartnerInformation = styled.div`
  margin: 1.6rem 0;
`;

const PartnerFormula = styled.div`
  margin: 1.6rem 0;
`;

const OrganizationName = styled.span`
  word-break: break-all;
  font-family: 'Inter', sans-serif;
  font-size: 1.6rem;
  font-weight: 600;
  font-weight: 600;
  line-height: 1.5;
  color: ${({ theme }) => theme.dark500};
`;

const Address = styled.div`
  margin: 1.6rem 0;

  & p {
    color: ${({ theme }) => theme.dark400};
    font-family: 'Inter', sans-serif;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.429;
  }
`;

const Vat = styled.p`
  margin: 1.6rem 0;
  color: ${({ theme }) => theme.dark400};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.429;
`;

const StyledCard = styled(Card)`
  margin: 1rem 0;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.stroke};
  padding: 2rem 1.6rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    padding: 3.2rem 4rem;
  }
`;

const FormulaDescription = styled.p`
  color: ${({ theme }) => theme.dark500};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.714;
  margin: 1.6rem 0;
`;

const ClosingDateLabel = styled.p`
  color: ${({ theme }) => theme.dark300};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.714;
`;

const ClosingDateValue = styled.p`
  color: ${({ theme }) => theme.dark500};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.714;
`;

const PartnerDetailsCard = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const { partnerOrganization, partnerProgram, partnerOrganizationError } =
    usePartner();

  const handleEditOrganization = useCallback(() => {
    navigate(`/organizations/${partnerOrganization.id}/edit`);
  }, [navigate, partnerOrganization]);

  return (
    <Container>
      <StyledCard>
        <Grid>
          <Header>
            <Name>
              {t('partner:dashboard.card.partnerDetails.information')}
            </Name>
            {partnerOrganization?.id && (
              <Button
                emphasis="icon"
                size="medium"
                highlightColor="transparent"
                onClick={handleEditOrganization}
              >
                <EditIcon color={theme.dark300} />
              </Button>
            )}
          </Header>
          {partnerOrganizationError ? (
            <StyledErrorMessage error={partnerOrganizationError} />
          ) : (
            <PartnerInformation>
              <OrganizationName>{partnerOrganization?.name}</OrganizationName>
              <Address>
                <p>
                  {partnerOrganization?.streetName}{' '}
                  {partnerOrganization?.streetNumber}
                </p>
                <p>
                  {partnerOrganization?.zip} {partnerOrganization?.city}
                </p>
              </Address>
              <Vat>{partnerOrganization?.vat}</Vat>
            </PartnerInformation>
          )}
        </Grid>
      </StyledCard>
      <StyledCard>
        <Grid>
          <Header>
            <Name>{t('partner:dashboard.card.partnerDetails.formula')}</Name>
          </Header>
          {partnerOrganizationError ? (
            <StyledErrorMessage error={partnerOrganizationError} />
          ) : (
            <PartnerFormula>
              <FormulaDescription>
                {partnerProgram?.description}
              </FormulaDescription>
              <div>
                <ClosingDateLabel>
                  {t('partner:dashboard.card.partnerDetails.nextClosingDate')}
                </ClosingDateLabel>
                <ClosingDateValue>
                  {partnerOrganization &&
                    formatDate(
                      partnerOrganization.nextCommissionDate,
                      i18n.language,
                      {
                        outputFormat: {
                          day: '2-digit',
                          month: 'long',
                          year: 'numeric',
                        },
                      }
                    )}
                </ClosingDateValue>
              </div>
            </PartnerFormula>
          )}
        </Grid>
      </StyledCard>
    </Container>
  );
};

export default PartnerDetailsCard;
