import styled, { css } from 'styled-components';

const Paper = styled.div`
  border-radius: 8px;
`;

const TitleContainer = styled.div`
  width: 100%;
  height: 4.6rem;
  display: grid;
  align-items: center;
  background: ${({ theme, $highlightGradient }) =>
    $highlightGradient
      ? `linear-gradient(
            135deg,
            ${theme.gradient[$highlightGradient][0]} 0%,
            ${theme.gradient[$highlightGradient][1]} 100%
          )`
      : theme.uiBackground};
  padding: 0 1.4rem;
  border-radius: 8px 8px 0 0;

  ${({ $highlightGradient }) =>
    !$highlightGradient &&
    css`
      border: 1px solid ${({ theme }) => theme.stroke};
    `}

  & p {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.4;
    color: ${({ theme, $highlightGradient }) => ($highlightGradient ? theme.white : theme.dark500)};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Body = styled.div`
  padding: 1.4rem;
  border-radius: 0 0 8px 8px;
  border-left: 1px solid ${({ theme }) => theme.stroke};
  border-right: 1px solid ${({ theme }) => theme.stroke};
  border-bottom: 1px solid ${({ theme }) => theme.stroke};
  background-color: ${({ theme }) => theme.white};
`;

const Card = ({ title, highlightGradient, children, className }) => {
  return (
    <Paper className={className}>
      {title ? (
        <TitleContainer $highlightGradient={highlightGradient}>
          <p>{title}</p>
        </TitleContainer>
      ) : null}
      <Body>{children}</Body>
    </Paper>
  );
};

export default Card;
