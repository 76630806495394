import styled from 'styled-components';

const ErrorText = styled.p`
  color: #f83a30;
  font-size: 0.85rem;
  margin-bottom: 0;
  margin-top: 0.2rem;
`;

const ErrorMessage = ({ children, className }) => <ErrorText className={className}>{children}</ErrorText>;

export default ErrorMessage;
