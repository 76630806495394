import gql from 'graphql-tag';

export const GET_MUSIC_HISTORY = gql`
  query getMusicHistory($zoneId: ID!, $externalZoneId: ID!, $afterDateString: String!, $beforeDateString: String!) {
    musicHistory(
      zoneId: $zoneId
      externalZoneId: $externalZoneId
      afterDateString: $afterDateString
      beforeDateString: $beforeDateString
    ) {
      musicHistory {
        id
        timestamp
        songTitle
        songArtist
        collectionName
        tunifyColor
        categoryName
        calendarName
        searchText
      }
    }
  }
`;

export const GET_ZONES = gql`
  query getZones {
    zones {
      id
      name
      location {
        id
        name
        organization {
          id
          name
        }
      }
    }
  }
`;

export const GET_USER_IDS_FROM_EXTERNAL_ZONE_IDS = gql`
  query getUserIdsFromExternalZoneIds($zoneIds: [ID!]!) {
    userIdsFromExternalZoneIds(zoneIds: $zoneIds) {
      externalZoneId
      zoneId
    }
  }
`;
