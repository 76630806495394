import { createContext, useContext, useMemo, useReducer, useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';

import { GET_TUNIFY_CHANNEL_GROUPS } from './api';
import { CategoryTypes } from './types';

const tunifyBlueReducer = (state, payload) => {
  return {
    ...state,
    ...payload,
  };
};

const initialTunifyBlueState = {
  selectedCategory: null,
  selectedChannelType: CategoryTypes.TUNIFY,
  selectedChannel: null,
  currentSongPlaying: null,
  changeableParameters: [],
};

const TunifyBlueContext = createContext();

export const TunifyBlueManager = ({ children, profile }) => {
  const { i18n } = useTranslation();
  const [state, dispatch] = useReducer(tunifyBlueReducer, initialTunifyBlueState);

  const audioRef = useRef(null);

  const [isPlaying, setIsPlaying] = useState(true);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [src, setAudioSrc] = useState(null);

  const { data: dataTunifyChannelGroups, loading: loadingTunifyChannelGroups } = useQuery(GET_TUNIFY_CHANNEL_GROUPS, {
    variables: {
      musicProfileId: profile.tunifyMusicProfileUserId,
      language: i18n.language,
    },
  });

  const handleEndedAudio = useCallback(() => {
    audioRef.current.currentTime = 0;
    setProgress(audioRef.current.currentTime);
    setIsPlaying(false);
  }, []);

  const handleTimeShift = useCallback((newTime) => {
    if (audioRef?.current?.duration) {
      if (audioRef.current.paused) {
        audioRef.current.currentTime = newTime;
        setProgress(audioRef.current.currentTime);
      } else {
        audioRef.current.pause();
        audioRef.current.currentTime = newTime;
        setProgress(audioRef.current.currentTime);
        audioRef.current.play();
      }
    }
  }, []);

  const startStopAudio = useCallback(() => {
    if (audioRef.current.paused) {
      audioRef.current.play();
      setIsPlaying(true);
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  }, []);

  const changeSliderAudio = useCallback(
    (value) => {
      handleTimeShift(audioRef.current.duration * value);
    },
    [handleTimeShift]
  );

  const fastForwardAudio = useCallback(() => {
    handleTimeShift(audioRef.current.currentTime + 10);
  }, [handleTimeShift]);

  const windBackAudio = useCallback(() => {
    handleTimeShift(audioRef.current.currentTime - 10);
  }, [handleTimeShift]);

  const stopAudio = useCallback(() => {
    if (audioRef?.current) {
      if (!audioRef.current.paused) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        setProgress(audioRef.current.currentTime);
        setIsPlaying(false);
      }
      setAudioSrc(null);
      setProgress(0);
    }
    dispatch({
      currentSongPlaying: null,
    });
  }, []);

  const setProgressToCurrentTime = useCallback(() => {
    setProgress(audioRef.current.currentTime);
    setDuration(audioRef.current.duration);
  }, []);

  const value = useMemo(
    () => ({
      ...state,
      dispatch,
      tunifyChannels: dataTunifyChannelGroups?.tunifyChannelGroups ?? [],
      loadingTunifyChannels: loadingTunifyChannelGroups,
      musicProfile: profile,
      isPlaying,
      progress,
      startStopAudio,
      changeSliderAudio,
      fastForwardAudio,
      windBackAudio,
      setProgressToCurrentTime,
      stopAudio,
      setAudioSrc,
      duration,
      setIsPlaying,
    }),
    [
      state,
      dataTunifyChannelGroups?.tunifyChannelGroups,
      loadingTunifyChannelGroups,
      profile,
      isPlaying,
      progress,
      startStopAudio,
      changeSliderAudio,
      fastForwardAudio,
      windBackAudio,
      stopAudio,
      setProgressToCurrentTime,
      setAudioSrc,
      duration,
      setIsPlaying,
    ]
  );

  return (
    <TunifyBlueContext.Provider value={value}>
      {children}
      {src ? (
        <audio ref={audioRef} autoPlay onEnded={handleEndedAudio}>
          <source src={src} type="audio/mp3" />
          <source src={src} type="audio/mpeg" />
          <source src={src} type="audio/aac" />
          <source src={src} type="audio/ogg" />
          <source src={src} type="audio/wav" />
          <source src={src} type="audio/x-m4a" />
        </audio>
      ) : null}
    </TunifyBlueContext.Provider>
  );
};

export const useTunifyBlue = () => {
  return useContext(TunifyBlueContext);
};
