import { useMemo } from 'react';
import { LoadingIndicator, ErrorMessage, ReactDependentScript } from '..';

import config from '../../config';

const { GOOGLE_MAPS_API_KEY } = config;

function withMapsScript(Component) {
  return function WrappedComponent(props) {
    const googleMapsAPIURL = useMemo(
      () => [
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places&callback=Function.prototype`,
      ],
      []
    );

    const errorObject = useMemo(() => ({ name: 'LOAD_SCRIPT_ERROR' }), []);

    return (
      <ReactDependentScript
        errorComponent={<ErrorMessage error={errorObject} />}
        loadingComponent={<LoadingIndicator />}
        scripts={googleMapsAPIURL}
      >
        <Component {...props} />
      </ReactDependentScript>
    );
  };
}

export default withMapsScript;
