import { capitalize, getTunifyProductGradient } from '../../../common';

// Sort on zone name, then location name and last organization name
export const sortZones = (zones) => {
  const sortedZones = zones.sort((a, b) => {
    return (
      a.name.localeCompare(b.name) ||
      a.location.name.localeCompare(b.location.name) ||
      a.location.organization.name.localeCompare(b.location.organization.name)
    );
  });

  return sortedZones;
};

export const mapDecimalToPercentage = (decimal) =>
  Math.round((decimal * 100 + Number.EPSILON) * 100) / 100;

export const mapApiDataToChartData = (reportData, translationCategory, t) =>
  reportData.map((reportValue) => ({
    id: translationCategory
      ? capitalize(
          t(
            `musicReportCategories:${translationCategory}.${reportValue.name.toLowerCase()}`
          )
        )
      : capitalize(reportValue.name),
    originalValue: reportValue.name.toLowerCase(),
    value: mapDecimalToPercentage(reportValue.value),
  }));

export const mapTunifyColorData = (reportData) => {
  const sortedData = [];

  reportData.forEach((reportvalue) => {
    switch (reportvalue.name) {
      case 'green': {
        sortedData[0] = reportvalue;
        break;
      }
      case 'blue': {
        sortedData[1] = reportvalue;
        break;
      }
      case 'orange': {
        sortedData[2] = reportvalue;
        break;
      }
      default:
      // throw new Error('Unknown Tunify color');
    }
  });

  return sortedData.map((reportValue) => ({
    id: reportValue.id,
    name: `Tunify ${capitalize(reportValue.name)}`,
    value: mapDecimalToPercentage(reportValue.value),
    color: getTunifyProductGradient(reportValue.name),
  }));
};

const preferredTunifyApplicationOrder = ['html', 'android', 'sonos', 'ios'];

export const mapTunifyApplicationData = (reportData) =>
  reportData
    .map((reportValue) => ({
      id: reportValue.id,
      name: reportValue.name,
      value: mapDecimalToPercentage(reportValue.value),
      color: 'spring',
    }))
    .sort((a, b) => {
      return (
        preferredTunifyApplicationOrder.indexOf(a.name.toLowerCase()) -
        preferredTunifyApplicationOrder.indexOf(b.name.toLowerCase())
      );
    });
