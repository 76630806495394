import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';

import { Modal, ErrorMessage, StringField } from '../../../../common';
import { CREATE_CUSTOM_PLAYLIST, EDIT_CUSTOM_PLAYLIST } from '../api';
import {
  useUpdatePlaylistCacheAfterCreate,
  useUpdatePlaylistCacheAfterUpdate,
} from '../cache';
import { usePlaylists } from '../playlistsProvider';
import { PlaylistModalModes, PlaylistTypes } from '../types';

const PlaylistModal = ({ mode, isOpen, toggle }) => {
  const { t } = useTranslation();

  const {
    dispatch,
    selectedPlaylist,
    customPlaylistsTimestamp,
    selectedZone,
    chosenSongIds,
    addSongsToCustomPlaylist,
    songs,
    refetchCustomPlaylists,
    customPlaylists,
    favouritePlaylist,
  } = usePlaylists();

  const [name, setName] = useState('');

  const handleUpdatePlaylistCacheAfterCreate =
    useUpdatePlaylistCacheAfterCreate({
      zoneId: selectedZone.id,
    });
  const handleUpdatePlaylistCacheAfterUpdate =
    useUpdatePlaylistCacheAfterUpdate(
      {
        zoneId: selectedZone.id,
        playlistId: selectedPlaylist?.id,
        newVars: { title: name },
      },
      (playlist) => {
        dispatch({
          selectedPlaylist: playlist,
        });
      }
    );

  useEffect(() => {
    if (mode === PlaylistModalModes.EDIT && selectedPlaylist?.title) {
      setName(selectedPlaylist.title);
    }
    if (mode === PlaylistModalModes.CREATE) {
      setName('');
    }
    if (mode === PlaylistModalModes.CREATE_WITH_SONGS) {
      setName(
        songs.find((song) => song.songId === chosenSongIds?.[0])?.title ?? ''
      );
    }
  }, [selectedPlaylist, mode, songs, chosenSongIds]);

  const [createCustomPlaylist, { loading, error }] = useMutation(
    CREATE_CUSTOM_PLAYLIST,
    {
      update: handleUpdatePlaylistCacheAfterCreate,
      onCompleted: (data) => {
        if (data?.createCustomPlaylist?.updateInfos) {
          refetchCustomPlaylists();
        }
        if (data?.createCustomPlaylist?.playlist) {
          if (mode === PlaylistModalModes.CREATE_WITH_SONGS) {
            const playlists = [...customPlaylists, favouritePlaylist];
            const currentPlaylistTimestamp = playlists.find(
              (playlist) =>
                Number(playlist.id) ===
                Number(data.createCustomPlaylist.playlist.id)
            )?.timestamp;
            if (currentPlaylistTimestamp) {
              addSongsToCustomPlaylist({
                variables: {
                  zoneId: selectedZone.id,
                  playlistId: data.createCustomPlaylist.playlist.id,
                  timestamp: currentPlaylistTimestamp,
                  songIds: chosenSongIds,
                  duplicateHandling: 'ERROR',
                },
              });
            }
          }
          dispatch({
            selectedPlaylistType: PlaylistTypes.CUSTOM,
            selectedPlaylist: data.createCustomPlaylist.playlist,
          });
          toggle();
        }
      },
    }
  );

  const [editCustomPlaylist, { loading: loadingEdit, error: errorEdit }] =
    useMutation(EDIT_CUSTOM_PLAYLIST, {
      update: handleUpdatePlaylistCacheAfterUpdate,
      onCompleted: toggle,
    });

  const handleCreatePlaylist = useCallback(() => {
    if (name) {
      if (customPlaylistsTimestamp) {
        createCustomPlaylist({
          variables: {
            zoneId: selectedZone.id,
            customPlaylistsTimestamp,
            playlist: {
              title: name,
            },
          },
        });
      }
    }
  }, [name, selectedZone.id, createCustomPlaylist, customPlaylistsTimestamp]);

  const handleEditPlaylist = useCallback(() => {
    if (selectedPlaylist?.id && selectedPlaylist?.timestamp && name) {
      editCustomPlaylist({
        variables: {
          zoneId: selectedZone.id,
          playlist: {
            id: selectedPlaylist.id,
            title: name,
            timestamp: selectedPlaylist.timestamp,
          },
        },
      });
    }
  }, [name, selectedZone.id, selectedPlaylist, editCustomPlaylist]);

  const handleChange = useCallback((event) => {
    setName(event.currentTarget?.value);
  }, []);

  const isEdit = mode === PlaylistModalModes.EDIT;

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        if (isEdit) {
          handleEditPlaylist();
        } else {
          handleCreatePlaylist();
        }
      }
    },
    [handleEditPlaylist, handleCreatePlaylist, isEdit]
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={toggle}
      onConfirm={isEdit ? handleEditPlaylist : handleCreatePlaylist}
      requestLoading={loading || loadingEdit}
      zIndex={3000}
      title={
        isEdit
          ? t('musicManagement:playlists.list.editPlaylist')
          : t('musicManagement:playlists.list.newPlaylist')
      }
    >
      <StringField
        autoFocus
        label={t('common:form.label.name')}
        name="name"
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        value={name}
      />
      {error || errorEdit ? <ErrorMessage error={error || errorEdit} /> : null}
    </Modal>
  );
};

export default PlaylistModal;
