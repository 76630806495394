import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import buttonTypes from './buttonTypes';
import sizes from './sizes';
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/arrowLeft.svg';

const StyledLink = styled(Link)`
  width: fit-content;
  height: 3rem;
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  gap: 1.2rem;

  &:hover {
    & div {
      color: ${({ theme }) => theme.dark500};
      border-color: ${({ theme }) => theme.dark500};
    }

    & path {
      color: ${({ theme }) => theme.dark500};
    }
  }
`;

const Back = styled.div`
  position: relative;
  z-index: 1;
  transition: 0.2s color ease-in-out, 0.2s background-color ease-in-out, 0.2s border-color ease-in-out;

  ${({ $size, $emphasis }) => sizes[$emphasis][$size]}

  ${({ $emphasis }) => buttonTypes[$emphasis]}

  ${({ $shouldCapitalizeText }) =>
    $shouldCapitalizeText &&
    css`
      text-transform: capitalize;
    `}
`;

const StyledArrowLeftIcon = styled(ArrowLeftIcon)`
  & path {
    color: ${({ theme }) => theme.dark200};
    transition: 0.2s color ease-in-out;
  }
`;

const GoBackButton = ({ className, isDisabled = false, to = -1, title, label }) => {
  const { t } = useTranslation();

  return (
    <StyledLink to={to}>
      <StyledArrowLeftIcon width="1.1rem" height="1.1rem" />
      <Back
        className={className}
        $emphasis="secondary"
        $highlightColor="dark400"
        $size="small"
        disabled={isDisabled}
        title={title ?? t('common:button.title.back')}
      >
        <p>{label ?? t('common:button.title.back')}</p>
      </Back>
    </StyledLink>
  );
};

export default GoBackButton;
