import { useMemo, useState, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import OrderTable from './orderTable';
import PriceSummary from './priceSummary';
import { getEquivalentOfAddon } from '../../utils';
import { usePurchase } from '../../purchaseContext';
import { ReactComponent as AngleDown } from '../../../../../assets/icons/angleDown.svg';
import { ReactComponent as AngleUp } from '../../../../../assets/icons/angleUp.svg';
import theme from '../../../../../global/style/theme';

const Container = styled.div`
  display: grid;
  gap: 1rem;
  background-color: ${({ theme }) => theme.dark500};
  color: ${({ theme }) => theme.white};
  border-radius: 4px;
  padding: 1.4rem 2rem;
  margin-bottom: 1rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem 0;
  cursor: pointer;
`;

const Title = styled.h4`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.white};

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    font-size: 1.8rem;
  }
`;

const PurchaseOverview = () => {
  const {
    selectedProduct,
    currentZone,
    availableAddons,
    userInput: {
      selectedMusicLicense,
      selectedAdditionalZones,
      selectedAddons,
    },
  } = usePurchase();
  const { t } = useTranslation();

  const [isContentVisible, setContentVisible] = useState(false);

  const productName = useMemo(
    () => (selectedProduct ? selectedProduct.shortName : null),
    [selectedProduct]
  );

  const addonNames = useMemo(
    () => selectedAddons.map((addon) => addon.shortName).join(', '),
    [selectedAddons]
  );

  const addonsToBuy = useMemo(
    () =>
      selectedProduct
        ? selectedAddons.map((addon) =>
            getEquivalentOfAddon(
              addon,
              availableAddons,
              selectedProduct.subscriptionTemplate.id
            )
          )
        : [],
    [selectedAddons, availableAddons, selectedProduct]
  );

  const totalPrice = useMemo(
    () =>
      selectedProduct?.price +
      addonsToBuy.reduce((acc, curr) => acc + curr.price, 0),
    [addonsToBuy, selectedProduct]
  );

  const toggleContent = useCallback(() => {
    setContentVisible((prev) => !prev);
  }, []);

  const zones = [currentZone].concat(selectedAdditionalZones);

  return (
    <Container>
      <Header onClick={toggleContent}>
        <Title>{t('subscriptions:basket', { amount: zones.length })}</Title>

        {isContentVisible ? (
          <AngleUp width="1.4rem" height="1.4rem" color={theme.white} />
        ) : (
          <AngleDown width="1.4rem" height="1.4rem" color={theme.white} />
        )}
      </Header>
      {isContentVisible ? (
        <OrderTable
          addonNames={addonNames}
          musicLicenseName={selectedMusicLicense?.name}
          productName={productName}
          productPrice={totalPrice}
          selectedSubscriptionTemplate={selectedProduct?.subscriptionTemplate}
          zones={zones}
        />
      ) : null}
      <PriceSummary />
    </Container>
  );
};

export default PurchaseOverview;
