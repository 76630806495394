import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/react-hooks';

import {
  Modal,
  LoadingIndicator,
  ErrorMessage,
} from '../../../../../../common';
import { Button } from '../../../../../../common/oldButton';
import {
  GET_MUSIC_COLLECTION_CONTEXT,
  UPDATE_MUSIC_COLLECTION_CONTEXT,
  GET_MUSIC_COLLECTION_TWEAK_STRENGTH,
} from '../api';
import { useTunifyBlue } from '../../tunifyBlueProvider';
import ChangeableParameter from './changeableParameter';
import { ReactComponent as ResetIcon } from '../../../../../../assets/icons/reset.svg';
import theme from '../../../../../../global/style/theme';

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;

  & > :not(:first-child) {
    margin-left: 1rem;
  }

  margin-top: 2rem;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin-top: 1rem;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  width: 100%;
`;

const LineWrapper = styled.div`
  padding: 0.5rem 0;
  border-bottom: 0.1rem solid ${({ theme }) => theme.stroke};
  padding: 1rem 0 2rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2rem;
`;

const BarItem = styled.div`
  width: 0.6rem;
  height: 1.2rem;
  background-color: ${({ color, isLoading, theme }) => {
    if (isLoading) {
      return 'transparent';
    }
    return color ?? theme.stroke;
  }};
  transition: 0.3s background-color ease-in-out;
`;

const SettingButton = styled.button`
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.waterBlue};
  height: 2.8rem;
  width: fit-content;
  padding: 0 1rem;
  border: none;
  background-color: ${({ theme }) => theme.stroke};
  transition: background-color 0.5s ease;
  display: flex;
  align-items: center;
  border-radius: 5px;

  & svg {
    width: 0.9rem;
    height: 1rem;
  }

  & p {
    font-size: 0.875rem;
    padding-left: 0.5rem;
  }
`;

const TweakStatus = {
  NORMAL: 'Normal',
  WARNING: 'Warning',
  ERROR: 'Error',
};

const CollectionSettingsModal = ({
  isOpen,
  onClose,
  title,
  musicCollection,
}) => {
  const { t } = useTranslation();

  const { selectedChannel, musicProfile, changeableParameters, dispatch } =
    useTunifyBlue();

  const {
    loading,
    data: dataMusicCollectionContext,
    error: errorMusicCollectionContext,
  } = useQuery(GET_MUSIC_COLLECTION_CONTEXT, {
    variables: {
      musicProfileId: musicProfile?.tunifyMusicProfileUserId,
      musicChannelId: selectedChannel?.id,
      musicCollectionId: musicCollection?.id,
    },
    skip:
      !musicProfile?.tunifyMusicProfileUserId ||
      !selectedChannel?.id ||
      !musicCollection?.id,
    onCompleted: (d) => {
      if (d?.musicCollectionContext?.changeableParameter) {
        const changeableParametersWithoutTypename =
          d.musicCollectionContext.changeableParameter.map(
            ({ __typename, ...parameters }) => ({
              ...parameters,
              value: parameters?.value?.map(
                ({ __typename: typenameValue, ...value }) => value
              ),
            })
          );
        dispatch({
          changeableParameters: changeableParametersWithoutTypename,
        });
      }
    },
  });

  // On error just show nothing instead of message
  const { data: dataTweakStrength, loading: loadingTweakStrength } = useQuery(
    GET_MUSIC_COLLECTION_TWEAK_STRENGTH,
    {
      variables: {
        musicProfileId: musicProfile?.tunifyMusicProfileUserId,
        musicCollectionId: musicCollection?.id,
        changeableParameters: changeableParameters ?? [],
      },
      skip:
        !musicProfile?.tunifyMusicProfileUserId ||
        !musicCollection?.id ||
        !changeableParameters,
    }
  );

  const [
    updateMusicCollectionContext,
    { loading: loadingUpdate, error: errorUpdate },
  ] = useMutation(UPDATE_MUSIC_COLLECTION_CONTEXT, {
    onCompleted: onClose,
  });

  const handleUpdateSettings = useCallback(() => {
    if (
      musicProfile?.tunifyMusicProfileUserId &&
      selectedChannel?.id &&
      musicCollection?.id &&
      changeableParameters
    ) {
      updateMusicCollectionContext({
        variables: {
          musicProfileId: musicProfile.tunifyMusicProfileUserId,
          musicChannelId: selectedChannel.id,
          musicCollectionId: musicCollection.id,
          changeableParameters,
        },
      });
    }
  }, [
    musicProfile?.tunifyMusicProfileUserId,
    selectedChannel?.id,
    musicCollection?.id,
    changeableParameters,
    updateMusicCollectionContext,
  ]);

  const handleClickPutBack = useCallback(() => {
    if (
      dataMusicCollectionContext?.musicCollectionContext
        ?.defaultChangeableParameter
    ) {
      const paramsWithoutTypename =
        dataMusicCollectionContext.musicCollectionContext.defaultChangeableParameter.map(
          ({ __typename, ...parameters }) => ({
            ...parameters,
            value: parameters?.value?.map(
              ({ __typename: typenameValue, ...value }) => value
            ),
          })
        );
      dispatch({
        changeableParameters: paramsWithoutTypename,
      });
    }
  }, [dataMusicCollectionContext?.musicCollectionContext, dispatch]);

  const tweakStrengthBarLength = 25;

  const tweakStrengthBarItems = useMemo(() => {
    if (dataTweakStrength?.musicCollectionTweakStrength) {
      const highlightedBlocksAmount = Math.round(
        (tweakStrengthBarLength / 100) *
          dataTweakStrength.musicCollectionTweakStrength.percentage
      );
      const unHighlightedBlocksAmount =
        tweakStrengthBarLength - highlightedBlocksAmount;

      let highlightColor = theme.waterBlue;
      if (
        dataTweakStrength.musicCollectionTweakStrength.status ===
        TweakStatus.WARNING
      )
        highlightColor = theme.sweetOrange;
      if (
        dataTweakStrength.musicCollectionTweakStrength.status ===
        TweakStatus.ERROR
      )
        highlightColor = theme.grapefruitRed;

      const highlightedArray = Array(highlightedBlocksAmount).fill(
        highlightColor
      );
      const unHighlightedArray = Array(unHighlightedBlocksAmount).fill(
        theme.stroke
      );

      return [...highlightedArray, ...unHighlightedArray];
    }
    return Array(tweakStrengthBarLength).fill(theme.stroke);
  }, [dataTweakStrength?.musicCollectionTweakStrength]);

  return (
    <Modal footer={false} isOpen={isOpen} onClose={onClose} title={title}>
      {errorUpdate || errorMusicCollectionContext ? (
        <StyledErrorMessage
          error={errorUpdate || errorMusicCollectionContext}
        />
      ) : null}
      <p>
        {t('musicManagement:tunifyBlue.collectionSettings.info', {
          collectionName: title,
        })}
      </p>
      {loading ? (
        <StyledLoadingIndicator />
      ) : (
        <>
          <LineWrapper>
            <BarWrapper>
              {tweakStrengthBarItems.map((color, index) => (
                <BarItem
                  key={index}
                  color={color}
                  isLoading={loadingTweakStrength}
                />
              ))}
            </BarWrapper>
            <SettingButton
              onClick={handleClickPutBack}
              title={t(
                'musicManagement:tunifyBlue.collectionSettings.putBackTitle'
              )}
            >
              <ResetIcon color={theme.waterBlue} />
              <p>
                {t('musicManagement:tunifyBlue.collectionSettings.putBack')}
              </p>
            </SettingButton>
          </LineWrapper>
          {changeableParameters.map((changeableParameter, index) => (
            <ChangeableParameter
              key={index}
              changeableParameter={changeableParameter}
              disable={loadingUpdate}
              index={index}
            />
          ))}
        </>
      )}
      <Footer>
        <Button
          emphasis="secondary"
          flavor="link"
          onClick={onClose}
          type="button"
        >
          {t('common:button.cancel')}
        </Button>
        <Button
          emphasis="secondary"
          flavor="flat"
          isLoading={loadingUpdate}
          onClick={handleUpdateSettings}
          title={t('common:button.title.save')}
          type="submit"
        >
          {t('common:button.save')}
        </Button>
      </Footer>
    </Modal>
  );
};

export default CollectionSettingsModal;
