import { useAddonsOverview } from '../addonsOverviewContext';
import { AddonsBroadcastsTab } from './broadcastsTab';
import { AddonsMusicManagementTab } from './musicManagementTab';

const AddonsTable = () => {
  const { selectedAddonsCategory } = useAddonsOverview();

  switch (selectedAddonsCategory.id) {
    case 'broadcasts':
      return <AddonsBroadcastsTab />;
    case 'musicManagement':
      return <AddonsMusicManagementTab />;
    default:
      throw new Error(`Unknown addons category: ${selectedAddonsCategory.id}`);
  }
};

export default AddonsTable;
