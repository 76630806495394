import { useTranslation } from 'react-i18next';

import {
  Center,
  MaxWidth,
  Paper,
  Title,
  Description,
  Icon,
  Checkmark,
} from '../../../../../common/feedback/styles';

const SonosSuccess = ({ zoneName }) => {
  const { t } = useTranslation();

  return (
    <Center>
      <MaxWidth>
        <Paper>
          <Icon isSuccess>
            <Checkmark />
          </Icon>
          <Title isSuccess>{t('integrations:sonos.success.title')}</Title>
          <Description>
            {zoneName
              ? t('integrations:sonos.success.description', { value: zoneName })
              : t('integrations:sonos.success.descriptionWithoutZone')}
          </Description>
        </Paper>
      </MaxWidth>
    </Center>
  );
};

export default SonosSuccess;
