import styled, { keyframes, css } from 'styled-components';
import shortid from 'shortid';

const Equalizer = styled.div`
  height: 1.2rem;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 0.4rem;
  align-items: flex-end;
  justify-items: center;
`;

const MAX_HEIGHT = 12;

const equalize = keyframes`
  0% {
    height: ${1 * MAX_HEIGHT}px;
  }
  4% {
    height: ${0.86 * MAX_HEIGHT}px;
  }
  8% {
    height: ${0.8 * MAX_HEIGHT}px;
  }
  12% {
    height: ${0.6 * MAX_HEIGHT}px;
  }
  16% {
    height: ${0.4 * MAX_HEIGHT}px;
  }
  20% {
    height: ${0.6 * MAX_HEIGHT}px;
  }
  24% {
    height: ${0.8 * MAX_HEIGHT}px;
  }
  28% {
    height: ${0.2 * MAX_HEIGHT}px;
  }
  32% {
    height: ${0.8 * MAX_HEIGHT}px;
  }
  36% {
    height: ${1 * MAX_HEIGHT}px;
  }
  40% {
    height: ${0.4 * MAX_HEIGHT}px;
  }
  44% {
    height: ${0.8 * MAX_HEIGHT}px;
  }
  48% {
    height: ${1 * MAX_HEIGHT}px;
  }
  52% {
    height: ${0.6 * MAX_HEIGHT}px;
  }
  56% {
    height: ${0.2 * MAX_HEIGHT}px;
  }
  60% {
    height: ${0.6 * MAX_HEIGHT}px;
  }
  64% {
    height: ${1 * MAX_HEIGHT}px;
  }
  68% {
    height: ${0.6 * MAX_HEIGHT}px;
  }
  72% {
    height: ${1 * MAX_HEIGHT}px;
  }
  76% {
    height: ${0.4 * MAX_HEIGHT}px;
  }
  80% {
    height: ${1 * MAX_HEIGHT}px;
  }
  84% {
    height: ${0.76 * MAX_HEIGHT}px;
  }
  88% {
    height: ${1 * MAX_HEIGHT}px;
  }
  92% {
    height: ${0.4 * MAX_HEIGHT}px;
  }
  96% {
    height: ${1 * MAX_HEIGHT}px;
  }
  100% {
    height: ${1 * MAX_HEIGHT}px;
  }
`;

const Rect = styled.div`
  width: 60%;
  height: 0.1rem;
  background-color: ${({ theme }) => theme.waterBlue};
  ${({ active, index }) =>
    active &&
    css`
      animation: ${equalize} 4s 0s infinite;
      animation-timing-function: linear;
      animation-delay: -${1.9 + index}s;
    `}
`;

const equalizerArray = Array(4)
  .fill(0)
  .map(() => shortid.generate());

const EqualizerBar = ({ className, active = false }) => {
  return (
    <Equalizer className={className}>
      {equalizerArray.map((id, index) => (
        <Rect key={id} active={active} index={index} />
      ))}
    </Equalizer>
  );
};

export default EqualizerBar;
