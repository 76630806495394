import { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDroppable } from '@dnd-kit/core';
import { motion, AnimatePresence } from 'framer-motion';

import { LoadingIndicator, useMediaSize, Button } from '../../../../common';
import { ReactComponent as AngleDown } from '../../../../assets/icons/angleDown.svg';
import { ReactComponent as Star } from '../../../../assets/icons/star.svg';
import { ReactComponent as AddIcon } from '../../../../assets/icons/smallAdd.svg';
import { PlaylistModal } from '../modals';
import { CollapsableButton, SelectablePlaylistButton } from '../../shared';
import ListItem from './listItem';
import { usePlaylists } from '../playlistsProvider';
import { PlaylistModalModes, DroppableTypes, PlaylistTypes } from '../types';
import theme from '../../../../global/style/theme';

const Container = styled.div`
  height: 100%;
  display: grid;
`;

const BWrapper = styled.div`
  padding: 0 0.8rem;
`;

const Wrapper = styled(motion.div)`
  height: 100%;
  overflow-y: auto;
  border: 1px solid ${({ theme }) => theme.stroke};
  background-color: ${({ theme }) => theme.uiBackground};
  border-radius: 8px;
  padding-bottom: 0.6rem;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin: 1rem 0;
`;

const StyledAngleDown = styled(AngleDown)`
  transform: ${({ $collapsed }) => (!$collapsed ? 'rotate(180deg)' : '')};
`;

const FavouritePlaylistButton = styled(SelectablePlaylistButton)`
  ${({ isOver }) =>
    isOver &&
    css`
      background-color: ${({ theme }) => theme.dark200};
    `}
`;

const AddWrapper = styled.div`
  width: 100%;
  padding: 1rem;
`;

const StyledButton = styled(Button)`
  width: 100%;
  padding-left: 0.4rem;
  display: grid;
  grid-template-columns: min-content 1fr;
  text-align: left;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.dark400};
  border-color: ${({ theme }) => theme.dark100};

  &:hover {
    color: ${({ theme }) => theme.dark400};
    border-color: ${({ theme }) => theme.dark300};
  }

  ${({ isOver }) =>
    isOver &&
    css`
      background-color: ${({ theme }) => theme.dark100};
    `}
`;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.waterBlue};
  margin-right: 1rem;
  min-height: 3rem;
  min-width: 3rem;
`;

const StyledAddIcon = styled(AddIcon)`
  width: 1.2rem;
  height: 1.2rem;

  & path {
    fill: ${({ theme }) => theme.white};
  }
`;

const Box = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  border: 1px solid ${({ theme }) => theme.stroke};
  border-radius: 4px;
  display: grid;
  align-items: center;
  justify-content: center;
`;

const List = ({ name, collapsibleType, setCollapsibleType }) => {
  const { t } = useTranslation();

  const {
    dispatch,
    customPlaylists,
    favouritePlaylist,
    loadingCustomPlaylists,
    selectedPlaylist,
    loadingFavouritePlaylist,
    overId,
    loadingAddSongs,
    modalMode,
  } = usePlaylists();

  const { isNarrowerThanMedium } = useMediaSize();

  const collapsed = collapsibleType !== 'custom';

  const { setNodeRef: setNodeRefCreate } = useDroppable({
    id: !collapsed ? DroppableTypes.CREATE_PLAYLIST : null,
    data: {
      type: DroppableTypes.PLAYLIST,
    },
    disabled: isNarrowerThanMedium,
  });

  const { setNodeRef: setNodeRefFavourites } = useDroppable({
    id: `${DroppableTypes.PLAYLIST}_${favouritePlaylist?.id}`,
    data: {
      type: DroppableTypes.PLAYLIST,
      listId: favouritePlaylist?.id,
    },
    disabled: isNarrowerThanMedium,
  });

  const handleOpenFavourites = useCallback(() => {
    dispatch({
      selectedPlaylistType: PlaylistTypes.CUSTOM,
      selectedPlaylist: favouritePlaylist,
    });
  }, [dispatch, favouritePlaylist]);

  const handleOpenCreatePlaylist = useCallback(() => {
    dispatch({ modalMode: PlaylistModalModes.CREATE });
  }, [dispatch]);

  const handleClosePlaylist = useCallback(() => {
    dispatch({ modalMode: null });
  }, [dispatch]);

  const handleOpenCollapsible = useCallback(() => {
    if (collapsed) {
      setCollapsibleType('custom');
    } else {
      setCollapsibleType('');
    }
  }, [setCollapsibleType, collapsed]);

  return (
    <Container>
      <BWrapper>
        <CollapsableButton
          $collapsed={collapsed}
          onClick={handleOpenCollapsible}
          type="button"
        >
          <p>{name}</p>
          <StyledAngleDown
            $collapsed={collapsed}
            color={theme.dark300}
            height="1rem"
            width="1rem"
          />
        </CollapsableButton>
      </BWrapper>
      <AnimatePresence>
        {!collapsed ? (
          <Wrapper
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            initial={{ height: 0 }}
            transition={{ ease: 'easeInOut', duration: 0.3 }}
          >
            <AddWrapper ref={setNodeRefCreate}>
              <StyledButton
                emphasis="outline"
                size="medium"
                isDiv
                isOver={overId === DroppableTypes.CREATE_PLAYLIST}
                onClick={handleOpenCreatePlaylist}
              >
                <Circle>
                  <StyledAddIcon />
                </Circle>
                {t('musicManagement:playlists.list.new')}
              </StyledButton>
            </AddWrapper>
            {!loadingFavouritePlaylist &&
            !(
              loadingAddSongs && selectedPlaylist?.id === favouritePlaylist?.id
            ) ? (
              <div ref={setNodeRefFavourites}>
                <FavouritePlaylistButton
                  isOver={
                    overId ===
                    `${DroppableTypes.PLAYLIST}_${favouritePlaylist?.id}`
                  }
                  onClick={handleOpenFavourites}
                  selected={selectedPlaylist?.id === favouritePlaylist?.id}
                  type="button"
                >
                  <Box>
                    <Star
                      color={theme.dark400}
                      height="1.6rem"
                      width="1.6rem"
                    />
                  </Box>
                  <p>{t('musicManagement:playlists.list.favorites')}</p>
                </FavouritePlaylistButton>
              </div>
            ) : null}
            {loadingCustomPlaylists || loadingFavouritePlaylist ? (
              <StyledLoadingIndicator color="primary" size="small" />
            ) : null}
            {customPlaylists?.length > 0 && !loadingCustomPlaylists
              ? customPlaylists.map((list) => (
                  <ListItem key={list.id} list={list} />
                ))
              : null}
          </Wrapper>
        ) : null}
      </AnimatePresence>
      <PlaylistModal
        isOpen={!!modalMode}
        mode={modalMode}
        toggle={handleClosePlaylist}
      />
    </Container>
  );
};

export default List;
