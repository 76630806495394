export const CategoryModalModes = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
};

export const CategoryTypes = {
  CUSTOM: 'CUSTOM',
  TUNIFY: 'TUNIFY',
};

export const CollectionTypes = {
  CONTEXT: 'CONTEXT',
  PLAYLIST: 'PLAYLIST',
};
