import { useMemo, useCallback } from 'react';
import styled, { css } from 'styled-components';

import { CheckboxField } from '../../../../../../common';

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: min-content 1fr;
  grid-column-gap: 1rem;
  padding: 1rem;
  cursor: pointer;

  ${({ isChecked, theme }) =>
    isChecked
      ? css`
          background-color: ${theme.waterBlue};
          color: ${theme.white};
        `
      : css`
          &:nth-child(odd) {
            background-color: ${theme.stroke};
          }
        `}

  &:hover {
    ${({ isChecked, theme }) =>
      !isChecked &&
      css`
        background-color: ${theme.dark400};
      `}
  }
`;

const Wrapper = styled.div`
  grid-row: 1/3;
  min-height: 3rem;
  min-width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OrganizationLocationName = styled.p`
  margin: 0;
  font-style: italic;
  color: ${({ isChecked, theme }) => (isChecked ? theme.white : theme.dark300)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
`;

const ZoneName = styled.p`
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  ${({ isChecked }) =>
    isChecked &&
    css`
      font-weight: 700;
    `}
`;

const ZoneRow = ({ zone, selected, onClickZone, isMulti }) => {
  const isChecked = useMemo(
    () =>
      isMulti
        ? selected.some((selectedZone) => selectedZone.id === zone.id)
        : selected?.id === zone.id,
    [selected, zone, isMulti]
  );

  const handleRowClick = useCallback(() => {
    onClickZone(zone.id);
  }, [onClickZone, zone]);

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        handleRowClick();
      }
    },
    [handleRowClick]
  );

  return (
    <Container
      isChecked={isChecked}
      onClick={handleRowClick}
      onKeyPress={handleKeyPress}
      tabIndex="0"
    >
      <Wrapper>
        {isChecked && isMulti ? <CheckboxField checked={isChecked} /> : null}
      </Wrapper>
      <ZoneName isChecked={isChecked}>{zone.name}</ZoneName>
      <OrganizationLocationName isChecked={isChecked}>
        {`${zone.location.organization.name} - ${zone.location.name}`}
      </OrganizationLocationName>
    </Container>
  );
};

export default ZoneRow;
