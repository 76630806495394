import { Fragment } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ErrorMessage, formatNumber } from '../../../../../common';
import { usePurchase } from '../../purchaseContext';

const Container = styled.div`
  display: grid;
  gap: 1rem;
`;

const ItemContainer = styled.div`
  display: grid;
  gap: 1rem;
`;

const ItemTitle = styled.p`
  font-size: 1.6rem;
  line-height: 1.4;
  font-weight: 600;
  color: ${({ theme }) => theme.white};

  & > span {
    color: ${({ theme }) => theme.dark300};
    font-weight: 500;
  }
`;

const Text = styled.p`
  font-size: 1.4rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.dark100};
`;

const RightText = styled(Text)`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.white};

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    justify-self: flex-end;
    white-space: nowrap;
  }
`;

const HorizontalRule = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.dark400};
`;

const OrderTable = ({
  zones,
  productName,
  musicLicenseName,
  selectedSubscriptionTemplate,
  productPrice,
  addonNames,
}) => {
  const { t, i18n } = useTranslation();
  const { organizationDetails } = usePurchase();

  return (
    <Container>
      {selectedSubscriptionTemplate ? zones.map((zone) => {
        const location = organizationDetails.locations.find((loc) => loc.id === zone.location.id);
        return (
          <Fragment key={zone.id}>
            <HorizontalRule />
            <ItemContainer>
              <ItemTitle>
                {location.name} <span>| {zone.name}</span>
              </ItemTitle>
              <div>
                <Text>
                  {musicLicenseName} | {productName} |{' '}
                  {selectedSubscriptionTemplate.recurringRuleType === 'monthly'
                    ? t('glossary:monthWithCount', { count: selectedSubscriptionTemplate.recurringInterval })
                    : t('glossary:weekWithCount', { count: selectedSubscriptionTemplate.recurringInterval })}
                </Text>
                {addonNames ? <Text>{addonNames}</Text> : null}
              </div>
              <RightText>€ {formatNumber(productPrice, i18n.language)}</RightText>
            </ItemContainer>
          </Fragment>
        );
      }) : <ErrorMessage error={t('error:SUBSCRIPTION_TEMPLATE_NOT_FOUND_FOR_PRODUCT')} />}
    </Container>
  );
};

export default OrderTable;
