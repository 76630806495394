import { useCallback } from 'react';
import styled from 'styled-components';

import { DropdownOptionTypes } from '../types';

const ZoneOptionButton = styled.button`
  width: 100%;
  text-align: left;
  transition: background-color 250ms linear, color 250ms linear;
  display: block;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;

  &:hover {
    color: ${({ theme }) => theme.white};
    background-color: ${({ theme }) => theme.waterBlue};
    font-weight: bold;
    & p {
      color: ${({ theme }) => theme.white};
    }
  }
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  & p {
    margin: 0.2rem;
  }
`;

const LabelDetails = styled.p`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.dark300};
`;

const ZoneOption = ({ zone, handleOnChange }) => {
  const handleClickOption = useCallback(() => {
    handleOnChange(zone?.id, DropdownOptionTypes.ZONE);
  }, [handleOnChange, zone?.id]);

  return (
    <ZoneOptionButton onClick={handleClickOption}>
      <LabelContainer>
        <p>{zone?.name}</p>
        {zone.location && (
          <LabelDetails>
            {[zone.location.organization.name, zone?.location.name]?.join(
              ' | '
            )}
          </LabelDetails>
        )}
      </LabelContainer>
    </ZoneOptionButton>
  );
};

export default ZoneOption;
