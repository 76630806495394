import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { LoadingIndicator, ErrorMessage, CheckboxField } from '../../../../../common';
import { useAddonsBuy } from '../../addonsBuyContext';
import ZoneCard from './zoneCard';

const StyledCheckBox = styled(CheckboxField)`
  justify-self: end;
  padding: 0 1.8rem 1.6rem 0;
`;

const Options = styled.div`
  display: grid;
  gap: 1.2rem;
`;

const AdditionalZonesStep = () => {
  const { t } = useTranslation();
  const {
    availableAdditionalZones,
    handleSelectZone,
    selectedAdditionalZones,
    handleSelectAllZones,
    handleDeselectAllZones,
    availableZonesLoading,
    availableZonesError,
  } = useAddonsBuy();

  const isSelected = useMemo(
    () => availableAdditionalZones.length === selectedAdditionalZones.length,
    [availableAdditionalZones.length, selectedAdditionalZones.length]
  );

  const handleSelectAll = useCallback(() => {
    if (isSelected) {
      handleDeselectAllZones();
    } else {
      handleSelectAllZones();
    }
  }, [isSelected, handleDeselectAllZones, handleSelectAllZones]);

  const isSelectedMap = useMemo(
    () =>
      availableAdditionalZones.reduce((acc, curr) => {
        return {
          ...acc,
          ...{ [curr.id]: Boolean(selectedAdditionalZones.find((selectedZone) => selectedZone.id === curr.id)) },
        };
      }, {}),
    [availableAdditionalZones, selectedAdditionalZones]
  );

  return (
    <>
      <StyledCheckBox
        label={t('purchase:zones.selectAll')}
        onChange={handleSelectAll}
        checked={isSelected}
        color="waterBlue"
        reverse
      />
      {availableZonesLoading && <LoadingIndicator />}
      {availableZonesError && <ErrorMessage error={availableZonesError} />}
      <Options>
        {availableAdditionalZones.map((availableZone) => (
          <ZoneCard
            key={availableZone.id}
            handleSelectZone={handleSelectZone}
            isSelected={isSelectedMap[availableZone.id]}
            zone={availableZone}
          />
        ))}
      </Options>
    </>
  );
};

export default AdditionalZonesStep;
