import styled, { css } from 'styled-components';

import LoadingIndicator from './index';

const Container = styled.div`
  width: 100%;
  ${($root) =>
    $root
      ? css`
          height: 100vh;
          @supports (height: 100svh) {
            height: 100svh;
          }
        `
      : css`
          height: 100%;
        `};
  display: grid;
  align-items: center;
  justify-content: center;
`;

const SuspenseLoadingIndicator = ({ className, root }) => (
  <Container $root={root}>
    <LoadingIndicator size="large" thickness="medium" className={className} />
  </Container>
);

export default SuspenseLoadingIndicator;
