import { Route } from 'react-router';
import {
  AcceptPurchaseFeedback,
  CancelPurchaseFeedback,
  DeclinePurchaseFeedback,
  ExceptionPurchaseFeedback,
  WireTransferPurchaseFeedback,
} from '../../../common';

export const PaymentFeedbackRouter = [
  <Route path="accept" element={<AcceptPurchaseFeedback />} />,
  <Route path="cancel" element={<CancelPurchaseFeedback />} />,
  <Route path="decline" element={<DeclinePurchaseFeedback />} />,
  <Route path="exception" element={<ExceptionPurchaseFeedback />} />,
  <Route path="wire-transfer" element={<WireTransferPurchaseFeedback />} />,
];
