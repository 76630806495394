import { useApolloClient } from "@apollo/react-hooks";
import { useQuery } from "@tanstack/react-query";
import Cookies from 'js-cookie';
import { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { fetchCountryInfoFromIpService } from "../../common";
import config from "../../config";
import LoadingPage from "../../global/application/loadingPage";
import { useAuth } from "../../global/auth/newAuthProvider";
import { LANGUAGE_CODES } from './api';
import { useLanguageSetter } from "./languageGetterProvider";

const defaultLanguageCodes = [
  'nl_BE',
  'nl_NL',
  'en_US',
  'fr_BE',
  'fr_FR',
  'de_DE',
];

const ViewerLanguageContext = createContext(config.LANGUAGE_FALLBACK);

export function useViewerLanguage() {
  return useContext(ViewerLanguageContext);
}

/**
 * Convert some expected language code formats to a normalized locale format.
 * Converts `ln` to `ln`.
 * Converts `ln-cn`, `ln-CN` and `ln_CN` to `ln_CN`.
 * 
 * @param {string} code
 */
function normalizeLanguageCode(code) {
  const [language, country] = code.split(/[-_]/g);
  const result = [language.toLowerCase(), country?.toUpperCase()].filter(Boolean).join('_');
  // Cookies previously got set to en-gb, but we no longer support this. We use en_US instead now.
  return result === 'en_GB' ? 'en_US' : result;
}

export function ViewerLanguageProvider({ children }) {
  const { i18n } = useTranslation();

  const { user } = useAuth();

  const client = useApolloClient();

  const { data: languageGuess, isLoading: guessingInProgress } = useQuery({
    queryKey: ['guess-language'],
    enabled: user == null,
    queryFn: async () => {
      try {
        // Odoo Languages fetcher
        const languageCodes = await (async () => {
          try {
            const { data: languagesData } = await client.query({ query: LANGUAGE_CODES });
            return languagesData?.languages?.map(({ code }) => code.replace(/-/g, '_')) ?? defaultLanguageCodes;
          } catch (error) {
            if (process.env.NODE_ENV === 'development') {
              console.error('Failed to fetch Odoo languages.', error);
            }
            // We silently ignore errors on fetching Odoo languages, fall back to hard coded languages and log an error in the console.
            return defaultLanguageCodes;
          }
        })();
        // Convert language code format
        const convertOldToNewLanguageCodeFormat = (codeInOldFormat) => {
          if (codeInOldFormat === 'en-gb') {
            return 'en_US';
          }
          const match = codeInOldFormat.match(/^(?<language>[a-z]{2})-(?<country>[a-z][a-z])$/);
          if (!match) {
            return codeInOldFormat;
          }
          return `${match.groups.language}_${match.groups.country.toUpperCase()}`;
        };
        // IP to country fetcher
        let memoizedIpServiceCountry = null;
        const getIpServiceCountry = async () => {
          if (memoizedIpServiceCountry === null) {
            try {
              memoizedIpServiceCountry = (await fetchCountryInfoFromIpService()).country_code.toUpperCase();
            } catch (error) {
              memoizedIpServiceCountry = '';
            }
          }
          return memoizedIpServiceCountry;
        };
        // Method to open language picker
        const openLanguagePickerPage = ({ language, country }) => {
          if (process.env.NODE_ENV === 'development') {
            console.log('TODO: Open language picker page with', { language, country });
          }
          const languageCookieValue = Cookies.get(config.LANGUAGE_COOKIE_KEY_EXTERNAL);
          if (languageCodes.includes(languageCookieValue)) {
            return languageCookieValue;
          }
          return config.LANGUAGE_FALLBACK;
        };
        // Case 1: tunify_user_lang
        const internalLanguageCookieValue = Cookies.get(config.LANGUAGE_COOKIE_KEY_INTERNAL);
        if (internalLanguageCookieValue) {
          const modernizedLanguageCookieValue = convertOldToNewLanguageCodeFormat(internalLanguageCookieValue);
          if (languageCodes.includes(modernizedLanguageCookieValue)) {
            return modernizedLanguageCookieValue;
          }
        }
        // Case 2: cookie is_www_user_region_set = true
        const isUserRegionSet = Cookies.get(config.LANGUAGE_IS_EXPLICITLY_SET_COOKIE_KEY)?.toLowerCase() === 'true';
        const externalLanguageCookieValue = Cookies.get(config.LANGUAGE_COOKIE_KEY_EXTERNAL);
        if (isUserRegionSet && externalLanguageCookieValue) {
          if (languageCodes.includes(externalLanguageCookieValue)) {
            return externalLanguageCookieValue;
          } 
        }
        // Case 3: cookie is_www_user_region_set != true
        if (!isUserRegionSet && externalLanguageCookieValue) {
          const [languageFromCookie] = externalLanguageCookieValue.split('_', 1);
          const countryCodeFromIp = await getIpServiceCountry();
          if (languageFromCookie && countryCodeFromIp) {
            const composedLocale = `${languageFromCookie}_${countryCodeFromIp}`;
            if (languageCodes.includes(composedLocale)) {
              return composedLocale;
            }
          }
        }
        // Case 4: No cookie
        const preferredBrowserLocale = navigator.languages[0] ?? navigator.language;
        const [languageFromBrowser] = preferredBrowserLocale.split('-', 1);
        const countryCodeFromIp = await getIpServiceCountry();
        if (languageFromBrowser && countryCodeFromIp) {
          const composedLocale = `${languageFromBrowser}_${countryCodeFromIp}`;
          if (languageCodes.includes(composedLocale)) {
            return composedLocale;
          }
        }
        const countryMatch = languageCodes.find((code) => code.endsWith(`_${countryCodeFromIp}`));
        if (countryMatch) {
          return openLanguagePickerPage({ country: countryCodeFromIp });
        }
        const languageMatch = languageCodes.find((code) => code.startsWith(`${languageFromBrowser}_`) || code === languageFromBrowser);
        if (languageMatch) {
          return openLanguagePickerPage({ language: languageFromBrowser });
        }
        // Fallback to en_US
        return config.LANGUAGE_FALLBACK;
      } catch (error) {
        console.error('Error occurred while checking language to use.', error);
        return config.LANGUAGE_FALLBACK;
      }
    },
  });

  const viewerLanguage = normalizeLanguageCode(user ? user.impersonatorLang ?? user.lang : languageGuess ?? config.LANGUAGE_FALLBACK);

  const languageIsDetermined = user != null || !guessingInProgress;

  const targetI18nLanguage = viewerLanguage.replace(/_/g, '-');

  const setLanguage = useLanguageSetter();

  const [updatingLanguage, setUpdatingLanguage] = useState(true);

  useEffect(() => {
    if (languageIsDetermined) {
      (async () => {
        try {
          setUpdatingLanguage(true);
          Cookies.set(config.LANGUAGE_COOKIE_KEY_INTERNAL, viewerLanguage);
          setLanguage(targetI18nLanguage);
          await i18n.changeLanguage(targetI18nLanguage);
        } finally {
          setUpdatingLanguage(false);
        }
      })();
    }
  }, [i18n, languageIsDetermined, setLanguage, targetI18nLanguage, viewerLanguage]);

  if (!languageIsDetermined || updatingLanguage) {
    return <LoadingPage />;
  }

  return (
    <ViewerLanguageContext.Provider value={viewerLanguage}>
      {children}
    </ViewerLanguageContext.Provider>
  );
}
