import { useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ErrorMessage } from '../../../../../common';
import { Button } from '../../../../../common/oldButton';
import StringField from '../../../../../common/oldFields/stringField';
import { Dropdown } from '../../../../../common/oldFields/dropdownField';

const StyledDropdown = styled(Dropdown)`
  margin: 0;

  & > div > div {
    max-height: 8rem;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;

  & > :not(:first-child) {
    margin-left: 1rem;
  }
`;

const DetailsStep = ({ setRegionId, setName, error, name, region, onNext, goBack, regions, loadingRegions }) => {
  const { t } = useTranslation();

  const handleChange = useCallback(
    (event) => {
      setName(event.currentTarget?.value);
    },
    [setName]
  );

  const handleChangeRegion = useCallback(
    (id) => {
      setRegionId(id);
    },
    [setRegionId]
  );

  return (
    <>
      {error ? <ErrorMessage error={error} /> : null}
      <StringField autoFocus label={t('common:form.label.name')} name="name" onChange={handleChange} value={name} />
      <StyledDropdown
        currentOptionId={region}
        isLoading={loadingRegions}
        label={t('musicManagement:addMusicProfile.region')}
        name="region"
        onChange={handleChangeRegion}
        options={regions}
      />
      <p>{t('musicManagement:addMusicProfile.detailsInfo')}</p>
      <Footer>
        <Button emphasis="secondary" flavor="link" onClick={goBack} type="button">
          {t('common:button.back')}
        </Button>
        <Button emphasis="secondary" flavor="flat" onClick={onNext} title={t('common:button.title.save')} type="button">
          {t('common:button.next')}
        </Button>
      </Footer>
    </>
  );
};

export default DetailsStep;
