import { useTranslation } from 'react-i18next';

import { GridTable } from '../../../../common';
import MusicHistoryRow from './musicHistoryRow';

const MusicHistoryTable = ({ musicHistoryLoading, musicHistoryError, musicHistory, selectedZone }) => {
  const { t } = useTranslation();

  const shouldShowMessage = (!musicHistory || musicHistory?.length === 0) && !musicHistoryLoading && !musicHistoryError;

  return (
    <GridTable>
      <GridTable.Header templateColumns="6rem 1fr 1fr 1fr">
        <GridTable.Head>{t('musicManagement:musicHistory.timing')}</GridTable.Head>
        <GridTable.Head>{t('musicManagement:musicHistory.tableTitle')}</GridTable.Head>
        <GridTable.Head>{t('musicManagement:musicHistory.artist')}</GridTable.Head>
        <GridTable.Head>{t('musicManagement:musicHistory.collectionName')}</GridTable.Head>
      </GridTable.Header>
      {shouldShowMessage && (
        <GridTable.Message>
          {selectedZone ? t('musicManagement:musicHistory.noData') : t('musicManagement:musicHistory.noSelectedZone')}
        </GridTable.Message>
      )}
      {musicHistory?.map((musicHistoryValue) => (
        <MusicHistoryRow key={musicHistoryValue.id} musicHistoryValue={musicHistoryValue} />
      ))}
      <GridTable.LoadingIndicator isLoading={musicHistoryLoading} />
      <GridTable.ErrorMessage error={musicHistoryError} />
    </GridTable>
  );
};

export default MusicHistoryTable;
