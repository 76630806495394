import gql from 'graphql-tag';

const GET_OPEN_CONTACT_CALLS = gql`
  query getOpenContactCalls($offset: Int, $limit: Int) {
    openContactCalls(offset: $offset, limit: $limit) {
      id
      summary
      contactCategoryId
      contactChannelId
      contactPersonId
      contactPersonFirstName
      contactPersonLastName
      contactPersonPhone
      contactPersonEmailAddress
      locationId
      deadlineDate
      agentName
    }
  }
`;

const GET_CONTACT_CATEGORIES = gql`
  query getContactCategories {
    contactCategories {
      id
      name
    }
  }
`;

const GET_CONTACT_CATEGORIES_AND_CHANNELS = gql`
  query getContactCategoriesAndChannels {
    contactCategories {
      id
      name
    }
    contactChannels {
      id
      name
    }
  }
`;

const EDIT_CONTACT_CALL = gql`
  mutation editContactCall(
    $contactCallId: ID!
    $contactCall: CreateContactCallInput!
  ) {
    editContactCall(id: $contactCallId, contactCall: $contactCall)
  }
`;

const GET_ORGANIZATIONS_AND_LOCATIONS_OF_USER = gql`
  query getOrganizationsAndLocationDetailsOfUser($userId: ID!) {
    organizationsOfUser(userId: $userId) {
      id
      name
      locations {
        id
        name
      }
    }
  }
`;

const ARCHIVE_CONTACT_CALL = gql`
  mutation archiveContactCall($id: ID!) {
    archiveContactCall(id: $id)
  }
`;

export {
  GET_OPEN_CONTACT_CALLS,
  GET_CONTACT_CATEGORIES,
  EDIT_CONTACT_CALL,
  GET_CONTACT_CATEGORIES_AND_CHANNELS,
  GET_ORGANIZATIONS_AND_LOCATIONS_OF_USER,
  ARCHIVE_CONTACT_CALL,
};
