import { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  LoadingIndicator,
  predictViewerCountryCode,
  withMapsScript,
} from '../..';
import { useAuth } from '../../../global/auth/newAuthProvider';
import Label from '../common/label';
import GoogleAutocomplete from './googleAutocomplete';
import { useLanguageGetter } from '../../../modules/languagePicker/languageGetterProvider';

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;

const StyledAutocomplete = styled(GoogleAutocomplete)`
  width: 100%;
  height: 4.8rem;
  outline: none;
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.stroke};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  line-height: 1.2;
  padding: 0 1.6rem;
  color: ${({ theme }) => theme.dark500};
  background-color: transparent;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out,
    color 0.2s ease-in-out;
  background: ${({ theme }) =>
    `linear-gradient(0deg, ${theme.gradient['fade'][0]} 0%,  ${theme.gradient['fade'][1]} 100%)`};

  &:hover {
    border-color: ${({ theme }) => theme.dark300};
  }

  &:active,
  :focus {
    border-color: ${({ theme }) => theme.waterBlue};
  }

  &::placeholder {
    transition: color 0.2s ease-in-out;
    color: ${({ theme }) => theme.dark300};
  }

  /* Color Safari password icon */
  ::-webkit-credentials-auto-fill-button {
    background-color: ${({ theme }) => theme.waterBlue};
  }
`;

const LocationField = ({
  label,
  placeholder,
  types = ['establishment'],
  fields = ['address_components', 'place_id'],
  onPlaceSelected,
  required = false,
  onChange,
  className,
}) => {
  const { user: userInfo } = useAuth();

  const [componentRestrictions, setComponentRestrictions] = useState();

  const getLanguage = useLanguageGetter();

  useEffect(() => {
    const defineComponentRestrictions = async () => {
      if (userInfo.registrationCountry) {
        setComponentRestrictions({ country: userInfo.registrationCountry });
        return;
      }

      const viewerCountryCodePrediction = await predictViewerCountryCode(getLanguage());

      if (viewerCountryCodePrediction) {
        setComponentRestrictions({ country: viewerCountryCodePrediction });
      } else {
        // If we cannot get the country of the viewer, add no Google place result restrictions
        setComponentRestrictions(null);
      }
    };

    defineComponentRestrictions();
  }, [getLanguage, userInfo.registrationCountry]);

  if (typeof componentRestrictions === 'undefined') return <LoadingIndicator />;

  return (
    <Container className={className}>
      {label ? <Label required={required}>{label}</Label> : null}
      <StyledAutocomplete
        componentRestrictions={componentRestrictions}
        fields={fields}
        onChange={onChange}
        onPlaceSelected={onPlaceSelected}
        placeholder={placeholder}
        types={types}
      />
    </Container>
  );
};

export default withMapsScript(LocationField);
