import { useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useQuery } from '@apollo/react-hooks';

import {
  SelectField,
  Button,
  MUSIC_MANAGEMENT_ADD_ON_ID,
  getProductSubscriptionLineOfSubscriptionLines,
  Tooltip,
  LoadingIndicator,
  ErrorMessage,
} from '../../../../../../common';
import { ReactComponent as ZoneIcon } from '../../../../../../assets/icons/zoneLarge.svg';
import { useAddonsOverview } from '../../../addonsOverviewContext';
import { GET_DETAILED_ZONE_BY_ID } from '../api';

const StyledErrorMessage = styled(ErrorMessage)`
  width: 100%;
  margin-top: 2rem;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)``;

const Container = styled.div`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.stroke};
  padding: 1.6rem 1.2rem;
  margin-top: 1.5rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    display: grid;
    grid-gap: 1rem;
    grid-auto-columns: 2fr 2fr 1fr;
    grid-template-areas: 'name input footer';
  }
`;

const Header = styled.div`
  grid-area: name;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: min-content 1fr;
  grid-gap: 1rem;
  align-items: center;
`;

const Name = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.33;
`;

const StyledSelectField = styled(SelectField)`
  grid-area: input;
  margin: 1rem 0;
`;

const Footer = styled.div`
  grid-area: footer;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: 1fr;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    justify-content: flex-end;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    justify-content: center;
  }
`;

const ActiveLabel = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.33;
  text-align: center;
  color: ${({ theme }) => theme.dark300};
`;

const ZoneCard = ({
  zone,
  fields,
  musicProfiles,
  musicProfilesError,
  musicProfilesLoading,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { canBuyNonePaidAddons } = useAddonsOverview();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const {
    data: detailedZoneData,
    error: detailedZoneError,
    loading: detailedZoneLoading,
  } = useQuery(GET_DETAILED_ZONE_BY_ID, {
    variables: { zoneId: zone.id },
  });

  const profileOptions = useMemo(
    () => [
      ...(musicProfiles?.map((profile) => ({
        value: profile.id,
        label: profile.name,
      })) ?? []),
      {
        value: '-1',
        label: t(
          'broadcasts:card:actions.zoneOverview.modal.profile.noProfileOption'
        ),
      },
    ],
    [musicProfiles, t]
  );

  const goToBuyAddonPage = useCallback(() => {
    navigate(
      `/organizations/${
        detailedZoneData?.zone?.location.organization.id
      }/locations/${detailedZoneData.zone.location.id}/zones/${
        detailedZoneData?.zone?.id
      }/purchase/add-ons/0?addonId=${encodeURIComponent(
        MUSIC_MANAGEMENT_ADD_ON_ID
      )}`
    );
  }, [navigate, detailedZoneData?.zone]);

  const hasPaidProduct = useMemo(() => {
    const productSubscriptionLine =
      getProductSubscriptionLineOfSubscriptionLines(
        detailedZoneData?.zone?.currentSubscription?.subscriptionLines
      );
    return productSubscriptionLine?.product?.type === 'paid';
  }, [detailedZoneData?.zone?.currentSubscription?.subscriptionLines]);

  const hasAddon = useMemo(
    () =>
      detailedZoneData?.zone?.currentSubscription?.subscriptionLines?.find(
        (subscriptionLine) =>
          subscriptionLine.product.isAddon &&
          subscriptionLine.product.categoryId === MUSIC_MANAGEMENT_ADD_ON_ID
      ),
    [detailedZoneData?.zone?.currentSubscription?.subscriptionLines]
  );

  const renderBuyContent = useMemo(() => {
    if (detailedZoneError) {
      return <StyledErrorMessage error={detailedZoneError} />;
    }
    if (detailedZoneLoading) {
      return <StyledLoadingIndicator size="medium" thickness="medium" />;
    }
    if (hasAddon) {
      return <ActiveLabel>{t('glossary:active')}</ActiveLabel>;
    }
    if (hasPaidProduct || canBuyNonePaidAddons) {
      return (
        <Button onClick={goToBuyAddonPage} type="button">
          {t('glossary:buy')}
        </Button>
      );
    }
    return (
      <Button onClick={goToBuyAddonPage} isDisabled type="button">
        <Tooltip label={t('addons:overview.nonePaidProduct')}>
          {t('glossary:buy')}
        </Tooltip>
      </Button>
    );
  }, [
    canBuyNonePaidAddons,
    detailedZoneLoading,
    detailedZoneError,
    goToBuyAddonPage,
    hasAddon,
    hasPaidProduct,
    t,
  ]);

  return (
    <Container>
      <Header>
        <ZoneIcon />
        <Name>{zone.name}</Name>
      </Header>
      {fields
        .filter((field) => field.zoneId === zone.id)
        .map((zoneField) => {
          return (
            <StyledSelectField
              key={zoneField.id}
              control={control}
              name={`musicProfiles_${zone.location.id}[${fields.findIndex(
                (field) => field.zoneId === zone.id
              )}].musicProfile`}
              isLoading={musicProfilesLoading}
              error={
                musicProfilesError ||
                errors?.musicProfiles?.[
                  fields.findIndex((field) => field.zoneId === zone.id)
                ]?.musicProfile?.message
              }
              disabled={musicProfilesError}
              options={profileOptions}
            />
          );
        })}
      <Footer>{renderBuyContent}</Footer>
    </Container>
  );
};

export default ZoneCard;
