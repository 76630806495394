const extractErrorCodeFromApolloError = (apolloError) => {
  if (apolloError.graphQLErrors && apolloError.graphQLErrors.length > 0) {
    const { code } = apolloError.graphQLErrors[0].extensions;
    return code;
  }
  if (apolloError.networkError) {
    return 'NETWORK_ERROR';
  }
};

export default extractErrorCodeFromApolloError;
