import { useMemo, useCallback, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useSearchParams } from 'react-router-dom';

import { CustomerReferralContext } from './customerReferralContext';
import config from '../../config';

const {
  PARTNER_REFERRAL_COOKIE_KEY,
  PARTNER_REFERRAL_COOKIE_DOMAIN,
  PARTNER_REFERRAL_COOKIE_MAX_AGE,
  CUSTOMER_REFERRAL_COOKIE_KEY,
  CUSTOMER_REFERRAL_COOKIE_DOMAIN,
  CUSTOMER_REFERRAL_COOKIE_MAX_AGE,
} = config;

const CustomerReferralProvider = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies();

  // Add support for old registration codes
  const [searchParams, setSearchParams] = useSearchParams();
  const legacyRegistrationCode = searchParams.get('registrationCode');
  const newRegistrationCode = searchParams.get('registration-code');

  // Registration code is a code provided by a partner through the partner program
  const registrationCode = newRegistrationCode || legacyRegistrationCode;

  // Customer code is a code provided by another client through the member get member program
  const memberCode = searchParams.get('member-code');

  const removePartnerReferralRegistrationCookie = useCallback(() => {
    removeCookie(PARTNER_REFERRAL_COOKIE_KEY, {
      domain: PARTNER_REFERRAL_COOKIE_DOMAIN,
      maxAge: PARTNER_REFERRAL_COOKIE_MAX_AGE,
      path: '/',
      httpOnly: false,
    });
  }, [removeCookie]);

  const removeCustomerReferralRegistrationCookie = useCallback(() => {
    removeCookie(CUSTOMER_REFERRAL_COOKIE_KEY, {
      domain: CUSTOMER_REFERRAL_COOKIE_DOMAIN,
      maxAge: CUSTOMER_REFERRAL_COOKIE_MAX_AGE,
      path: '/',
      httpOnly: false,
    });
  }, [removeCookie]);

  useEffect(() => {
    // Whenever there are 2 referral codes present in the query params,
    // give prio to the member code of the member get member program.
    if (memberCode) {
      setCookie(CUSTOMER_REFERRAL_COOKIE_KEY, memberCode, {
        domain: CUSTOMER_REFERRAL_COOKIE_DOMAIN,
        maxAge: CUSTOMER_REFERRAL_COOKIE_MAX_AGE,
        path: '/',
        httpOnly: false,
      });
      // Clear referral codes from query params whenever a new one is set
      setSearchParams();
      if (cookies[PARTNER_REFERRAL_COOKIE_KEY]) {
        // Only one referral code can be used during registration,
        // we give prio to the last one. If there is already a partner
        // code cookie present, delete it.
        removePartnerReferralRegistrationCookie();
      }
    } else if (registrationCode) {
      setCookie(PARTNER_REFERRAL_COOKIE_KEY, registrationCode, {
        domain: PARTNER_REFERRAL_COOKIE_DOMAIN,
        maxAge: PARTNER_REFERRAL_COOKIE_MAX_AGE,
        path: '/',
        httpOnly: false,
      });
      // Clear referral codes from query params whenever a new one is set
      setSearchParams();
      if (cookies[CUSTOMER_REFERRAL_COOKIE_KEY]) {
        // Only one referral code can be used during registration,
        // we give prio to the last one. If there is already a customer
        // code cookie present, delete it.
        removeCustomerReferralRegistrationCookie();
      }
    }
  }, [
    setCookie,
    removeCustomerReferralRegistrationCookie,
    removePartnerReferralRegistrationCookie,
    memberCode,
    registrationCode,
    setSearchParams,
    cookies,
  ]);

  const contextValue = useMemo(
    () => ({
      registrationCode: cookies[PARTNER_REFERRAL_COOKIE_KEY] || '',
      memberCode: cookies[CUSTOMER_REFERRAL_COOKIE_KEY] || '',
      removePartnerReferralRegistrationCookie,
      removeCustomerReferralRegistrationCookie,
    }),
    [cookies, removePartnerReferralRegistrationCookie, removeCustomerReferralRegistrationCookie]
  );

  return <CustomerReferralContext.Provider value={contextValue}>{children}</CustomerReferralContext.Provider>;
};

export default CustomerReferralProvider;
