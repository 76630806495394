import gql from 'graphql-tag';

const GET_ZONES_APPLICABLE_FOR_SPOTIFY_PLAYLIST_IMPORT = gql`
  query getZonesApplicableForSpotifyPlaylistImport {
    zonesApplicableForSpotifyPlaylistImport {
      id
      name
      location {
        id
        name
        organization {
          id
          name
        }
      }
    }
  }
`;

const IMPORT_SPOTIFY_PLAYLISTS = gql`
  mutation importSpotifyPlaylists($playlists: [PlaylistArgs!]!, $zoneIds: [Int!]!) {
    importSpotifyPlaylists(playlists: $playlists, zoneIds: $zoneIds) {
      playlistId
      status
    }
  }
`;

const GET_PLAYLIST_STATUSES = gql`
  query getPlaylistStatuses($playlistIds: [ID!]!) {
    spotifyPlaylistStatuses(playlistIds: $playlistIds) {
      playlistId
      status
      timestamp
    }
  }
`;

export { GET_ZONES_APPLICABLE_FOR_SPOTIFY_PLAYLIST_IMPORT, IMPORT_SPOTIFY_PLAYLISTS, GET_PLAYLIST_STATUSES };
