import { useCallback, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as RawEditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as RawAddIcon } from '../../../assets/icons/add.svg';
import { ReactComponent as DashboardLarge } from '../../../assets/icons/navigation/dashboardLarge.svg';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';

import { BasicButton, StringField, SelectField } from '../../../common';
import theme from '../../../global/style/theme';

const Container = styled.div`
  display: grid;
  row-gap: 1rem;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr min-content;
  background-color: ${({ theme }) => theme.white};
  padding: 1.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.stroke};

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    grid-template-columns: min-content 3fr 2fr;
    grid-template-rows: unset;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    padding: 2.2rem 3rem;
    grid-auto-flow: column;
    gap: 3rem;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    padding: 3.2rem 4rem;
    grid-template-columns: min-content 5fr 4fr;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    padding: 4rem 4.8rem;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.superLarge}px) {
    grid-template-columns: min-content 2fr 3fr;
  }
`;

const TitleContainer = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  gap: 1.6rem;
  grid-column: span 2;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    grid-column: unset;
  }
`;

const Logo = styled.div`
  width: 4rem;
  height: 4rem;
  display: grid;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    135deg,
    ${({ theme }) => theme.gradient['clearsky'][0]} 0%,
    ${({ theme }) => theme.gradient['clearsky'][1]} 100%
  );
  border-radius: 4px;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    width: 4.8rem;
    height: 4.8rem;
  }
`;

const StyledDashboardLarge = styled(DashboardLarge)`
  width: 2.6rem;
  height: 2.6rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    width: 3.2rem;
    height: 3.2rem;
  }
`;

const Title = styled.h1`
  font-size: 2.4rem;
  font-weight: 600;
  white-space: nowrap;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    font-size: 2.8rem;
  }
`;

const Wrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr min-content;
  grid-column: span 2;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    grid-column: unset;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    grid-template-columns: 1fr min-content;
  }
`;

const GridRight = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, min-content);
`;

const EditButton = styled(BasicButton)`
  padding: 1.6rem;
`;

const AddButton = styled(BasicButton)`
  padding: 1.6rem;
`;

const Divider = styled.div`
  height: 2.4rem;
  width: 0.1rem;
  background-color: ${({ theme }) => theme.dark100};
`;

const StyledStringField = styled(StringField)`
  margin: 0;
  min-width: 10rem;
  width: 100%;

  & input {
    border-radius: 2.6rem;
    padding-left: 4rem;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    min-width: 14rem;
  }
`;

const SearchWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  position: relative;
  outline: none;
  z-index: 10;
  border-top: 1px solid ${({ theme }) => theme.stroke};
  width: calc(100% + 3rem);
  margin-left: -1.5rem;
  padding: 1.4rem 1.5rem 0 1.5rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    border-top: none;
    width: 100%;
    margin-left: 0;
    padding: 0;
  }
`;

const SearchFieldWrapper = styled.div`
  display: grid;
  position: relative;
`;

const StyledSearchIcon = styled(SearchIcon)`
  width: 1.6rem;
  height: 1.6rem;
  position: absolute;
  top: 1.6rem;
  left: 1.6rem;
`;

const SearchOptions = styled.div`
  width: calc(100% - 3rem);
  position: absolute;
  top: 7rem;
  left: 1.5rem;
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.stroke};
  display: grid;
  border-radius: 4px;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    width: 100%;
    top: 5.5rem;
    left: 0;
  }
`;

const OptionTitle = styled.span`
  font-size: 1.4rem;
  padding: 1.2rem;
  color: ${({ theme }) => theme.dark300};
`;

const OptionButton = styled.button`
  font-size: 1.4rem;
  cursor: pointer;
  padding: 1.2rem;
  user-select: none;
  background-color: ${({ theme }) => theme.white};
  border: none;
  font-family: 'Inter', sans-serif;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 4px;
  transition: background-color 250ms linear, color 250ms linear;

  &:hover {
    background-color: ${({ theme }) => theme.waterBlue};
    color: ${({ theme }) => theme.white};
  }
`;

const PageHeader = ({
  organization,
  organizations,
  onChangeOrganization,
  setSearchValue,
  searchValue,
  setSearchFilter,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [tempSearchValue, setTempSearchValue] = useState('');
  const [showSearchOptions, setShowSearchOptions] = useState(false);

  const handleEditOrganization = useCallback(() => {
    navigate(`/organizations/${organization.id}/edit`);
  }, [navigate, organization]);

  const handleCreateOrganization = useCallback(() => {
    navigate('/organizations/create');
  }, [navigate]);

  const handleOnChange = useCallback(
    (organization) => {
      onChangeOrganization(organization.value);
    },
    [onChangeOrganization]
  );

  const onChangeCb = useCallback((event) => {
    setTempSearchValue(event.target.value);
  }, []);

  useEffect(() => {
    if (!searchValue) {
      setTempSearchValue('');
    }
  }, [searchValue]);

  const handleApplyFilter = useCallback(
    (value) => {
      setSearchValue(tempSearchValue);
      setSearchFilter(value);
      setShowSearchOptions(false);
    },
    [tempSearchValue, setSearchFilter, setSearchValue]
  );

  const handleShowOptions = useCallback(() => {
    setShowSearchOptions(true);
  }, []);

  const handleHideOptions = useCallback((event) => {
    const clickedOnOptions = event.currentTarget.contains(event.relatedTarget);

    if (!clickedOnOptions) {
      setShowSearchOptions(false);
    }
  }, []);

  const handleClickOptions = useCallback((event) => {
    event.stopPropagation();
  }, []);

  const handleKeyPressSearch = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        event.target.blur();
        handleApplyFilter('all');
      }
    },
    [handleApplyFilter]
  );

  const organizationOptions = useMemo(
    () =>
      organizations.map((organization) => ({
        label: organization.name,
        value: organization.id,
      })),
    [organizations]
  );

  const selectedOrganization = useMemo(
    () =>
      organizationOptions.find((profile) => profile.value === organization.id),
    [organizationOptions, organization.id]
  );

  return (
    <Container>
      <TitleContainer>
        <Logo>
          <StyledDashboardLarge color={theme.white} />
        </Logo>
        <Title>{t('dashboard:title')}</Title>
      </TitleContainer>
      <Wrapper>
        <SelectField
          name="profile"
          options={organizationOptions}
          value={selectedOrganization}
          onChange={handleOnChange}
        />
        <GridRight>
          <EditButton
            onClick={handleEditOrganization}
            title={t('common:button.title.edit', {
              value: t('common:button.title.organization'),
            })}
          >
            <RawEditIcon width="1.6rem" height="1.6rem" color={theme.dark300} />
          </EditButton>
          <Divider />
          <AddButton
            onClick={handleCreateOrganization}
            title={t('common:button.title.add', {
              value: t('common:button.title.organization'),
            })}
          >
            <RawAddIcon width="1.6rem" height="1.6rem" color={theme.dark300} />
          </AddButton>
        </GridRight>
      </Wrapper>
      <SearchWrapper
        onBlur={handleHideOptions}
        onFocus={handleShowOptions}
        tabIndex="0"
      >
        <SearchFieldWrapper>
          <StyledSearchIcon color={theme.waterBlue} />
          <StyledStringField
            name="search"
            onChange={onChangeCb}
            onKeyPress={handleKeyPressSearch}
            placeholder={t('subscriptions:searchPlaceholder')}
            value={tempSearchValue}
          />
        </SearchFieldWrapper>
        {showSearchOptions ? (
          <SearchOptions onClick={handleClickOptions}>
            <OptionTitle>{t('subscriptions:search.title')}</OptionTitle>
            <OptionButton
              onClick={() => handleApplyFilter('location')}
              type="button"
            >
              {t('subscriptions:search.location')}: {tempSearchValue}
            </OptionButton>
            <OptionButton
              onClick={() => handleApplyFilter('place')}
              type="button"
            >
              {t('subscriptions:search.place')}: {tempSearchValue}
            </OptionButton>
            <OptionButton
              onClick={() => handleApplyFilter('zone')}
              type="button"
            >
              {t('subscriptions:search.zone')}: {tempSearchValue}
            </OptionButton>
          </SearchOptions>
        ) : null}
      </SearchWrapper>
    </Container>
  );
};

export default PageHeader;
