import { useCallback } from 'react';
import styled, { css, useTheme } from 'styled-components';

import { GridTable, Tooltip } from '../../../../../common';
import { useAuth } from '../../../../../global/auth/newAuthProvider';

import { ReactComponent as LeadFillIcon } from '../../../../../assets/icons/leadFill.svg';
import { useTranslation } from 'react-i18next';

const DesktopGridRow = styled(GridTable.Row)`
  display: none;

  ${({ $highlighted }) => $highlighted && css`
    background-color: #F5FBFF;
  `}

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    display: grid;
    color: ${({ theme }) => theme.dark400};
  }
`;

const MobileGridRow = styled(GridTable.Row)`
  display: grid;
  grid-template-rows: 1fr min-content 1fr min-content 1fr;
  padding: 1rem 1.6rem;
  gap: 1.5rem;

  ${({ $highlighted }) => $highlighted && css`
    background-color: #F5FBFF;
  `}

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    grid-template-columns: 1.6rem 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    display: none;
  }
`;

const ValuePair = styled.div`
  display: grid;
  gap: 0.4rem;
`;

const MobileTitle = styled(GridTable.Value)``;

const MobileValue = styled(GridTable.Value)`
  color: ${({ theme }) => theme.dark300};

  ${({ $spansMultiple }) => $spansMultiple && css`
    grid-column: 1/3;

    @media only screen and (min-width: ${({ theme }) =>
        theme.breakpoints.small}px) {
      grid-column: unset;
    }
  `}
`;

const MobileDivider = styled.div`
  width: 100%;
  height: 0.1rem;
  background-color: ${({ theme }) => theme.stroke};

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    display: none;
  }
`;

const DesktopLeadContainer = styled.div`
  align-items: center;
  display: flex;
  height: 1.6rem;
  justify-content: center;
  width: 1.6rem;
`;

const TabletLeadContainer = styled.div`
  display: none;
  grid-row: 1/2;
  height: 1.6rem;
  width: 1.6rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    display: flex;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    display: none;
  }
`;

const MobileLeadContainer = styled.div`
  display: flex;
  grid-column: 2;
  grid-row: 1;
  height: 1.6rem;
  justify-self: right;
  width: 1.6rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    display: none;
  }
`;

const FlexTooltip = styled(Tooltip)`
  & {
    display: flex;
  }
`;

const LeadIndication = ({ direction }) => {
  const { t } = useTranslation();

  const theme = useTheme();

  return (
    <FlexTooltip
      label={t('customerSuccess:search.lead.tooltip')}
      preventClickThrough
      direction={direction}
    >
      <LeadFillIcon width="1.6rem" height="1.6rem" color={theme.waterBlue} />
    </FlexTooltip>
  );
};

const templateColumns = '1.6rem repeat(2, 2fr) 1fr repeat(4, 2fr)';

const TableRow = ({ customer }) => {
  const { impersonate } = useAuth();

  const impersonateUser = useCallback(async () => {
    await impersonate(customer.contact.id);
  }, [impersonate, customer.contact.id]);

  const isLead = customer.contact.type === 'lead';

  return (
    <>
      <DesktopGridRow
        onClick={impersonateUser}
        templateColumns={templateColumns}
        $highlighted={isLead}
      >
        <DesktopLeadContainer>{isLead && <LeadIndication direction="right" />}</DesktopLeadContainer>
        <GridTable.Value>{customer.organization.name || '-'}</GridTable.Value>
        <GridTable.Value>{customer.location.name || '-'}</GridTable.Value>
        <GridTable.Value>{customer.location.city || '-'}</GridTable.Value>
        <GridTable.Value>{customer.contact.name || '-'}</GridTable.Value>
        <GridTable.Value>{customer.contact.phone || '-'}</GridTable.Value>
        <GridTable.Value>{customer.action.name || '-'}</GridTable.Value>
        <GridTable.Value>{customer.action.date || '-'}</GridTable.Value>
      </DesktopGridRow>
      <MobileGridRow
        onClick={impersonateUser}
        templateColumns={templateColumns}
        $highlighted={isLead}
      >
        <TabletLeadContainer>{isLead && <LeadIndication direction="right" />}</TabletLeadContainer>
        <ValuePair>
          <MobileTitle>{customer.organization.name || '-'}</MobileTitle>
          <MobileValue $spansMultiple={isLead}>
            {customer.location.name || '-'}&nbsp;&nbsp;&nbsp;
            {customer.location.city || '-'}
          </MobileValue>
          {isLead && <MobileLeadContainer>{<LeadIndication />}</MobileLeadContainer>}
        </ValuePair>
        <MobileDivider />
        <ValuePair>
          <MobileTitle>{customer.contact.name || '-'}</MobileTitle>
          <MobileValue>{customer.contact.phone || '-'}</MobileValue>
        </ValuePair>
        <MobileDivider />
        <ValuePair>
          <MobileTitle>{customer.action.name || '-'}</MobileTitle>
          <MobileValue>{customer.action.date || '-'}</MobileValue>
        </ValuePair>
      </MobileGridRow>
    </>
  );
};

export default TableRow;
