import { useCallback, useMemo, useEffect } from 'react';
import styled from 'styled-components';

import { SelectCard } from '../../../../common';
import { usePurchase } from '../purchaseContext';
import { productCatalogueOptions } from '../purchaseReducer';

const Label = styled.h2`
  font-size: 1.8rem;
  line-height: 1.5;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SubLabel = styled.div`
  font-size: 1.4rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.dark300};
`;

const Options = styled.div`
  display: grid;
  gap: 1.2rem;
`;

const ProductCatalogueStep = ({ setGoToNextStepLoading, setGoToNextStepError }) => {
  const { handleSelectProductCatalogue, selectedProductCatalogue, productCatalogueLoading, productCatalogueError } =
    usePurchase();

  useEffect(() => {
    setGoToNextStepLoading(productCatalogueLoading);
    setGoToNextStepError(productCatalogueError);
    return () => {
      setGoToNextStepError(null);
    };
  }, [setGoToNextStepError, setGoToNextStepLoading, productCatalogueError, productCatalogueLoading]);

  const handleSelect = useCallback(
    (id) => {
      if (id !== selectedProductCatalogue?.id) {
        const productCatalogue = productCatalogueOptions.find((pc) => pc.id === id);
        handleSelectProductCatalogue(productCatalogue);
      }
    },
    [selectedProductCatalogue, handleSelectProductCatalogue]
  );

  const selectOptions = useMemo(
    () =>
      productCatalogueOptions.map((productCatalogue) => (
        <SelectCard
          key={productCatalogue.id}
          activeSelectCardId={selectedProductCatalogue?.id}
          id={productCatalogue.id}
          onClick={handleSelect}
          title={<Label>{productCatalogue.name}</Label>}
        >
          <SubLabel>{productCatalogue.description}</SubLabel>
        </SelectCard>
      )),
    [handleSelect, selectedProductCatalogue]
  );

  return <Options>{selectOptions}</Options>;
};

export default ProductCatalogueStep;
