import { ScheduledCallsResultsCard } from './cards';
import CustomerSuccessScheduledCallsProvider from './scheduledCallsProvider';

const CustomerSuccessHistoryPage = () => {
  return (
    <CustomerSuccessScheduledCallsProvider>
      <ScheduledCallsResultsCard />
    </CustomerSuccessScheduledCallsProvider>
  );
};

export default CustomerSuccessHistoryPage;
