import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import Card from './card';
import { LoadingIndicator, ErrorMessage } from '../../../../../common';
import { useCustomerSuccessScheduledCalls } from '../../scheduledCallsContext';
import { LIMIT } from '../../scheduledCallsProvider';

const Container = styled.div`
  display: grid;
  grid-gap: 1rem;
  padding: 0.8rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    padding: 1.6rem 2.4rem;
  }
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    padding: 1.6rem 4.6rem;
  }
`;

const Message = styled.div`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.dark300};
  line-height: 1.4;
  padding-top: 1rem;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin: 1rem;
`;

const Results = () => {
  const { t } = useTranslation();
  const {
    loading,
    hasMore,
    error,
    loadMore,
    scheduledCalls,
    contactCategories,
    openEditContactModal,
  } = useCustomerSuccessScheduledCalls();

  const [infiniteRef] = useInfiniteScroll({
    loading,
    // Due to refetch we cannot know if there is more after the initial query,
    // we have to additionally check for this with scheduledCalls.length >= LIMIT
    hasNextPage: scheduledCalls.length >= LIMIT && hasMore,
    onLoadMore: loadMore,
  });

  if (loading && scheduledCalls.length === 0) {
    return <StyledLoadingIndicator />;
  }

  if (scheduledCalls.length === 0 && error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <Container ref={infiniteRef}>
      {scheduledCalls.length === 0 && (
        <Message>{t('customerSuccess:scheduledCalls.tbNoResults')}</Message>
      )}
      {scheduledCalls.map((scheduledCall) => (
        <Card
          key={scheduledCall.id}
          contactCategories={contactCategories}
          openEditContactModal={openEditContactModal}
          scheduledCall={scheduledCall}
        />
      ))}
      {error && <ErrorMessage error={error} />}
      {loading && <StyledLoadingIndicator />}
    </Container>
  );
};

export default Results;
