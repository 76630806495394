export const checkIfOrganizationDetailsAreTheSame = (previousOrganizationDetails, currentOrganizationDetails) =>
  previousOrganizationDetails.name.trim() === currentOrganizationDetails.name.trim() &&
  previousOrganizationDetails.streetName.trim() === currentOrganizationDetails.streetName.trim() &&
  previousOrganizationDetails.streetNumber.trim() === currentOrganizationDetails.streetNumber.trim() &&
  previousOrganizationDetails.zip.trim() === currentOrganizationDetails.zip.trim() &&
  previousOrganizationDetails.city.trim() === currentOrganizationDetails.city.trim() &&
  previousOrganizationDetails.countryId === currentOrganizationDetails.countryId &&
  previousOrganizationDetails.vat.trim() === currentOrganizationDetails.vat.trim() &&
  previousOrganizationDetails.extraAddressInfo.trim() === currentOrganizationDetails.extraAddressInfo.trim() &&
  previousOrganizationDetails.invoiceEmail.trim() === currentOrganizationDetails.invoiceEmail.trim() &&
  previousOrganizationDetails.ref.trim() === currentOrganizationDetails.ref.trim();
