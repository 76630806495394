import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Page, useSessionStorage } from '../../common';
import {
  hasAllRequiredPermissions,
  OWNER_ROLE_PERMISSIONS,
  SUPPORT_ROLE_PERMISSIONS,
} from '../../global/auth';
import { useAuth } from '../../global/auth/newAuthProvider';

const Centered = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: grid;
  align-items: center;
  justify-content: center;
`;

const StaticWidthContainer = styled.div`
  display: grid;
  max-width: 40rem;
  padding: 0 1.6rem;
`;

const RequestStatusMock = styled.span`
  font-size: 8rem;
  font-weight: 900;
  line-height: 1;
  text-align: center;
  margin-bottom: 3rem;
`;

const Title = styled.h1`
  font-size: 3.2rem;
  line-height: 1;
  text-align: center;
  margin-bottom: 3rem;
`;

const Text = styled.p`
  font-size: 1.6rem;
  line-height: 1.4;
  text-align: center;
  padding-bottom: 3rem;
`;

const ButtonGroup = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-rows: 1fr 1fr;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    grid-template-columns: 3fr 2fr;
  }
`;

const NotFoundPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();

  const [impersonationId] = useSessionStorage('impersonationId');
  const isOwner = hasAllRequiredPermissions(user, OWNER_ROLE_PERMISSIONS);
  const isSupport = hasAllRequiredPermissions(user, SUPPORT_ROLE_PERMISSIONS);

  const handleRedirect = useCallback(() => {
    if ((isOwner && !isSupport) || (isSupport && !!impersonationId)) {
      navigate('/', { replace: true });
    } else if (isSupport && !impersonationId) {
      navigate('/customer-success/search', { replace: true });
    } else {
      navigate('/invoices', { replace: true });
    }
  }, [navigate, isOwner, impersonationId, isSupport]);

  const redirectButtonText = useMemo(() => {
    if ((isOwner && !isSupport) || (isSupport && !!impersonationId)) {
      return t('common:button.goToHome');
    }
    if (isSupport && !impersonationId) {
      return t('common:button.goToCustomerSuccess');
    }
    return t('common:button.goToInvoices');
  }, [t, isOwner, isSupport, impersonationId]);

  const redirectButtonTitle = useMemo(() => {
    if ((isOwner && !isSupport) || (isSupport && !!impersonationId)) {
      return t('common:button.title.goTo', {
        value: t('common:button.home'),
      });
    }
    if (isSupport && !impersonationId) {
      return t('common:button.title.goTo', {
        value: t('common:button.title.customerSuccess'),
      });
    }
    return t('common:button.title.goTo', {
      value: t('common:button.invoices'),
    });
  }, [t, isOwner, isSupport, impersonationId]);


  const openContact = useCallback(() => {
    window.open(`mailto:${t('config:customerSupportEmail')}?subject=${t(
      'error:NOT_FOUND_SUPPORT_EMAIL_SUBJECT',
      { url: window.location.href }
    )}&body=${t(
      'error:NOT_FOUND_SUPPORT_EMAIL_BODY',
      { url: window.location.href }
    )}`);
  }, [t]);

  return (
    <Page title={t('global:notFound.title')}>
      <Centered>
        <StaticWidthContainer>
          <RequestStatusMock>404</RequestStatusMock>
          <Title>{t('global:notFound.header')}</Title>
          <Text>{t('global:notFound.label')}</Text>
          <ButtonGroup>
            <Button onClick={handleRedirect} title={redirectButtonTitle}>
              {redirectButtonText}
            </Button>
            <Button highlightGradient="sunset" onClick={openContact} title={t('common:button.title.contact')}>
              {t('common:button.contact')}
            </Button>
          </ButtonGroup>
        </StaticWidthContainer>
      </Centered>
    </Page>
  );
};

export default NotFoundPage;
