import gql from 'graphql-tag';

export const GET_ZONES = gql`
  query getOrganizationsWithZones {
    organizations {
      id
      name
      zones {
        id
        name
        location {
          id
          name
        }
        currentSubscription {
          id
          subscriptionLines {
            id
            product {
              id
              isAddon
              shortName
              name
            }
          }
        }
      }
    }
  }
`;

export const CREATE_CUSTOM_PLAYLIST = gql`
  mutation createCustomPlaylist($zoneId: ID!, $playlist: CustomPlaylistInput!) {
    createCustomPlaylist(zoneId: $zoneId, playlist: $playlist) {
      id
      title
      description
    }
  }
`;

export const EDIT_CUSTOM_PLAYLIST = gql`
  mutation editCustomPlaylist($zoneId: ID!, $playlist: CustomPlaylistInput!) {
    editCustomPlaylist(zoneId: $zoneId, playlist: $playlist)
  }
`;

export const GET_TUNIFY_CALENDAR_GROUPS = gql`
  query getTunifyCalendarGroups($musicProfileId: ID!, $language: String!) {
    tunifyCalendarGroups(musicProfileId: $musicProfileId, language: $language) {
      id
      name
      properties {
        visible
      }
      calendars {
        calendarId
        name
        calendarGroupId
        properties {
          visible
        }
      }
    }
  }
`;

export const UPDATE_CALENDAR_GROUP_VISIBILITY = gql`
  mutation updateCalendarGroupVisibility(
    $musicProfileId: ID!
    $calendarGroupId: ID!
    $visible: Boolean!
    $childrenIds: [String!]
  ) {
    updateCalendarGroupVisibility(
      musicProfileId: $musicProfileId
      calendarGroupId: $calendarGroupId
      visible: $visible
      childrenIds: $childrenIds
    ) {
      visible
    }
  }
`;

export const UPDATE_CALENDAR_VISIBILITY = gql`
  mutation updateCalendarVisibility($musicProfileId: ID!, $calendarId: ID!, $visible: Boolean!) {
    updateCalendarVisibility(musicProfileId: $musicProfileId, calendarId: $calendarId, visible: $visible) {
      visible
    }
  }
`;

export const GET_TUNIFY_CALENDAR = gql`
  query getTunifyCalendar($musicProfileId: ID!, $calendarId: ID!, $language: String!) {
    tunifyCalendar(musicProfileId: $musicProfileId, calendarId: $calendarId, language: $language) {
      id
      musicChannelId
      musicCollectionId
      title
      cronString
      duration
      position
      shufflePlaylist
      changeableParameter {
        type
        name
        metadataDisplayType
        metadataDisplayName
        value {
          value
          selected
        }
        minimum
        maximum
        step
        lowerSelection
        upperSelection
        metadataDisplayMinimum
        metadataDisplayMaximum
      }
      defaultChangeableParameter {
        type
        name
        metadataDisplayType
        metadataDisplayName
        value {
          value
          selected
        }
        minimum
        maximum
        step
        lowerSelection
        upperSelection
        metadataDisplayMinimum
        metadataDisplayMaximum
      }
    }
  }
`;
