import { useTranslation } from 'react-i18next';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import styled from 'styled-components';

import { GridTable } from '../../../common';
import CommissionRequestTableRow from './commissionRequestTableRow';
import { usePartnerManager } from '../partnerManagerContext';
import { LIMIT } from '../partnerManagerProvider';

const DesktopHeader = styled(GridTable.Header)`
  display: none;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    display: grid;
  }
`;

const TabletHeader = styled(GridTable.Header)`
  display: none;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.small}px) {
    display: grid;
    ${({ templateColumns }) => templateColumns && `grid-template-columns: ${templateColumns};`}
    column-gap: 1.5rem;
    align-items: center;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    display: none;
  }
`;

const CommissionRequestsTable = () => {
  const { t } = useTranslation();

  const {
    commissionRequests,
    commissionRequestsError,
    commissionRequestsLoading,
    hasMoreCommissionRequests,
    fetchMoreCommissionRequests,
  } = usePartnerManager();

  const [infiniteRef] = useInfiniteScroll({
    loading: commissionRequestsLoading,
    // Due to refetch we cannot know if there is more after the initial query,
    // we have to additionally check for this
    hasNextPage: commissionRequests?.length >= LIMIT && hasMoreCommissionRequests,
    onLoadMore: fetchMoreCommissionRequests,
  });

  return (
    <GridTable ref={infiniteRef}>
      <DesktopHeader templateColumns="repeat(5, 1fr) 2.5rem">
        <GridTable.Head>{t('partnerManager:commissionRequests.tableHeader.settlementDate')}</GridTable.Head>
        <GridTable.Head>{t('partnerManager:commissionRequests.tableHeader.partnerName')}</GridTable.Head>
        <GridTable.Head>{t('partnerManager:commissionRequests.tableHeader.reference')}</GridTable.Head>
        <GridTable.Head>{t('partnerManager:commissionRequests.tableHeader.commission')}</GridTable.Head>
        <GridTable.Head>{t('partnerManager:commissionRequests.tableHeader.approved')}</GridTable.Head>
        <GridTable.Head />
      </DesktopHeader>
      <TabletHeader templateColumns="repeat(4, 1fr) 2.5rem">
        <GridTable.Head>{t('partnerManager:commissionRequests.tableHeader.settlementDate')}</GridTable.Head>
        <GridTable.Head>{t('partnerManager:commissionRequests.tableHeader.partnerName')}</GridTable.Head>
        <GridTable.Head>{t('partnerManager:commissionRequests.tableHeader.commission')}</GridTable.Head>
        <GridTable.Head>{t('partnerManager:commissionRequests.tableHeader.approved')}</GridTable.Head>
        <GridTable.Head />
      </TabletHeader>
      {(!commissionRequests || commissionRequests?.length === 0) && !commissionRequestsLoading && (
        <GridTable.Message>{t('partnerManager:commissionRequests.noCommissionRequests')}</GridTable.Message>
      )}
      {commissionRequests?.map((commissionRequest) => (
        <CommissionRequestTableRow key={commissionRequest.id} commissionRequest={commissionRequest} />
      ))}
      <GridTable.LoadingIndicator isLoading={commissionRequestsLoading} />
      <GridTable.ErrorMessage error={commissionRequestsError} />
    </GridTable>
  );
};

export default CommissionRequestsTable;
