import { GET_ZONES_WITH_ACTIVE_SUBSCRIPTION } from '../../api';

export const updateZonesCacheAfterMutation = (cache, mutationResult) => {
  const { zonesWithAnActiveSubscription } = cache.readQuery({
    query: GET_ZONES_WITH_ACTIVE_SUBSCRIPTION,
  });

  const {
    data: { changeMusicProfileOfZones },
  } = mutationResult;

  const updatedZones = zonesWithAnActiveSubscription.map((originalZone) => {
    const mutatedOriginalZone = changeMusicProfileOfZones.find(
      (mutatedZone) => mutatedZone.myTunifyZoneId === originalZone.id
    );

    return mutatedOriginalZone
      ? {
          ...originalZone,
          ...{ musicProfile: { id: mutatedOriginalZone.musicProfileId, __typename: 'MusicProfile' } },
        }
      : originalZone;
  });

  const data = {
    zonesWithAnActiveSubscription: updatedZones,
  };

  cache.writeQuery({
    query: GET_ZONES_WITH_ACTIVE_SUBSCRIPTION,
    data,
  });
};
