import { useCallback, useEffect, useState, useMemo } from 'react';

const useLocalStorage = (key, initialValue) => {
  const [value, setValue] = useState(() => window.localStorage.getItem(key) || initialValue);

  const setItem = useCallback(
    (newValue) => {
      setValue(newValue);
      window.localStorage.setItem(key, newValue);
    },
    [key]
  );

  useEffect(() => {
    const newValue = window.localStorage.getItem(key);
    if (value !== newValue) {
      setValue(newValue || initialValue);
    }
  }, [key, value, initialValue]);

  const handleStorage = useCallback(
    (event) => {
      if (event.key === key && event.newValue !== value) {
        setValue(event.newValue || initialValue);
      }
    },
    [initialValue, key, value]
  );

  const removeItem = useCallback(() => {
    setValue(initialValue);
    window.localStorage.removeItem(key);
  }, [initialValue, key]);

  useEffect(() => {
    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, [handleStorage]);

  return useMemo(() => [value, setItem, removeItem], [value, setItem, removeItem]);
};

export default useLocalStorage;
