import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Description } from './description';
import { useSpotifyImport } from '../../spotifyImportContext';

const Info = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.dark300};
  line-height: 1.4;
`;

const Header = () => {
  const { t } = useTranslation();
  const { isAuthenticated, userName } = useSpotifyImport();

  return isAuthenticated ? (
    <Description userName={userName} />
  ) : (
    <Info>{t('integrations:spotify.import.loginDescription')}</Info>
  );
};

export default Header;
