import { useEffect } from 'react';

const CoviewScript = (url) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.innerHTML = `(function(c,o,v,i,e,w){c[v]=c[v]||function(){(c[v].a=c[v].a||[]).push(arguments)};var s=o.createElement(i);s.src=e;s.async=1;var h=o.getElementsByTagName(i)[0];h.parentNode.insertBefore(s,h);c.addEventListener('error',function(err){c[v]('error',err)});c.addEventListener('message',function(msg){c[v]('message',msg)})})(window,document,'coview','script','https://cdn.coview.com/coview.js');
    coview('start', {
      projectKey:'u2T5GK_XRBo'
    });`;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);

  return null;
};

export default CoviewScript;
