import { useTranslation } from 'react-i18next';

import { Page } from '../../common';
import PartnerProvider from './partnerProvider';
import Overview from './overview';

const PartnerPage = () => {
  const { t } = useTranslation();

  return (
    <PartnerProvider>
      <Page title={t('partner:dashboard.title')}>
        <Overview />
      </Page>
    </PartnerProvider>
  );
};

export default PartnerPage;
