/* global google */
import { useRef, useCallback, useEffect } from 'react';

const GoogleAutoComplete = ({
  className,
  types,
  fields,
  componentRestrictions,
  onChange,
  onPlaceSelected,
  ...rest
}) => {
  const inputRef = useRef();
  const autocomplete = useRef();
  const config = { types, fields, componentRestrictions };

  const disableAutofill = useCallback(() => {
    if (window.MutationObserver) {
      const observerHack = new MutationObserver(() => {
        observerHack.disconnect();
        if (inputRef && inputRef.current)
          inputRef.current.autocomplete = 'disable-autofill';
      });
      observerHack.observe(inputRef.current, {
        attributes: true,
        attributeFilter: ['autocomplete'],
      });
    }
  }, []);

  const handleOnFocus = useCallback(() => {
    const autoCompleteContainer = document.querySelector('.pac-container');
    autoCompleteContainer.style.display = 'none';

    if (inputRef.current.value !== '') {
      setTimeout(() => {
        const autoCompleteContainer = document.querySelector('.pac-container');
        const inputBoundings = inputRef.current.getBoundingClientRect();
        const calculatedTop = inputBoundings.top + inputBoundings.height;

        autoCompleteContainer.style.top = `${calculatedTop}px`;
      }, 450);
    }
  }, []);

  const onSelected = useCallback(() => {
    // Default value of `getPlace()` is `{ name: '' }`
    if (
      onPlaceSelected &&
      autocomplete &&
      Object.keys(autocomplete.current.getPlace()).length > 1
    ) {
      onPlaceSelected(autocomplete.current.getPlace(), inputRef.current);
    }
  }, [onPlaceSelected]);

  const handleOnChange = useCallback(() => {
    if (onChange) onChange(inputRef.current ? inputRef.current.value : '');
  }, [onChange]);

  const handleOnBlur = useCallback(() => {
    const autoCompleteContainer = document.querySelector('.pac-container');
    autoCompleteContainer.style.display = 'none';
  }, []);

  useEffect(() => {
    disableAutofill();
    if (!autocomplete.current)
      autocomplete.current = new google.maps.places.Autocomplete(
        inputRef.current,
        config
      );
    const event = autocomplete.current.addListener('place_changed', onSelected);

    return () => event.remove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <input
      ref={inputRef}
      className={className}
      onBlur={handleOnBlur}
      onChange={handleOnChange}
      onFocus={handleOnFocus}
      {...rest}
    />
  );
};

GoogleAutoComplete.displayName = 'GoogleAutocomplete';

export default GoogleAutoComplete;
