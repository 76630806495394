import DatePicker, { registerLocale } from 'react-datepicker';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { de, enUS, nlBE, fr } from 'date-fns/locale';
import styled, { css } from 'styled-components';

import ErrorMessage from '../common/errorMessage';
import Label from '../common/label';
import '../common/datePicker.css';

const languagesObject = {
  'nl-be': nlBE,
  'nl-nl': nlBE,
  'en-us': enUS,
  'fr-be': fr,
  'fr-fr': fr,
  'de-de': de,
};

const Container = styled.div`
  /* ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.5;
        `
      : ''} */
`;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  height: 4.8rem;
  outline: none;
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.stroke};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  line-height: 1.2;
  padding: 0 1.6rem;
  color: ${({ theme }) => theme.dark500};
  background-color: transparent;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out,
    color 0.2s ease-in-out;

  background: ${({ theme }) =>
    `linear-gradient(0deg, ${theme.gradient['fade'][0]} 0%,  ${theme.gradient['fade'][1]} 100%)`};

  &:hover {
    border-color: ${({ theme }) => theme.dark300};
  }

  &:active,
  :focus {
    border-color: ${({ theme }) => theme.waterBlue};
  }

  &::placeholder {
    transition: color 0.2s ease-in-out;
    color: ${({ theme }) => theme.dark300};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      color: ${({ theme }) => theme.dark100};
      border-color: ${({ theme }) => theme.stroke};

      &:hover {
        border-color: ${({ theme }) => theme.stroke};
      }

      &::placeholder {
        color: ${({ theme }) => theme.dark100};
      }
    `}
`;

const DateField = ({
  name,
  error,
  control,
  label,
  placeholder,
  className,
  disabled,
  ...props
}) => {
  const { i18n } = useTranslation();
  registerLocale(i18n.language, languagesObject[i18n.language.toLowerCase()]);

  return (
    <Container className={className} disabled={disabled}>
      {label ? (
        <Label htmlFor={name} disabled={disabled}>
          {label}
        </Label>
      ) : null}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value, ...rest } }) => (
          <StyledDatePicker
            name={name}
            {...(!disabled ? { onChange: onChange } : {})}
            selected={props?.selectsRange ? value?.[0] : value}
            timeCaption="time"
            timeFormat="p"
            timeIntervals={15}
            dateFormat={props.showTimeSelect ? 'PPp' : 'PP'}
            locale={i18n.language}
            placeholderText={placeholder}
            disabled={disabled}
            {...rest}
            {...props}
            {...(props?.selectsRange
              ? {
                  startDate: value?.[0],
                  endDate: value?.[1],
                }
              : {})}
            {...(disabled
              ? props?.selectsRange
                ? {
                    minDate: value?.[0],
                    maxDate: value?.[0],
                  }
                : {
                    minDate: value,
                    maxDate: value,
                  }
              : {})}
          />
        )}
      />
      {typeof error === 'string' ? <ErrorMessage>{error}</ErrorMessage> : null}
    </Container>
  );
};

export default DateField;
