import { useCallback, useState, forwardRef, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { format } from 'date-fns';

import {
  GridTable,
  useMediaSize,
  useToggle,
  LoadingIndicator,
  useOnClickOutside,
  CheckboxField,
  BasicButton,
} from '../../../../common';
import { ReactComponent as RawAddToListIcon } from '../../../../assets/icons/addToList.svg';
import { ReactComponent as RawInfoIcon } from '../../../../assets/icons/infoBasic.svg';
import { ReactComponent as RawTrashIcon } from '../../../../assets/icons/delete.svg';
import { ReactComponent as RawPlayIcon } from '../../../../assets/icons/playLarge.svg';
import { ReactComponent as MoreOptionsIcon } from '../../../../assets/icons/options.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { SAVE_CUSTOM_PLAYLIST, GET_SONG_MEDIA_URL } from '../api';
import { usePlaylists } from '../playlistsProvider';
import { DroppableTypes, PlaylistTypes } from '../types';
import { PlaylistSelectionModal, SongInfoModal } from '../modals';
import Equalizer from '../../shared/equalizer';
import AudioBar from '../audioBar';
import theme from '../../../../global/style/theme';

const Container = styled.div`
  display: grid;
  opacity: ${(props) => (props.clone ? 0.8 : 1)};
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.stroke};
  border-radius: 8px;
  margin-bottom: 0.4rem;
  color: ${({ theme }) => theme.dark400};

  &:hover {
    ${({ clone }) =>
      clone &&
      css`
        cursor: grabbing;
      `}
    ${({ isOverItem, clone }) =>
      isOverItem &&
      clone &&
      css`
        cursor: copy;
      `}
  }

  ${({ clone, ghost, isOverItem }) =>
    ghost && !clone && !isOverItem
      ? css`
          opacity: 0.4;
          border-color: ${({ theme }) => theme.waterBlue};
          & :hover {
            border-color: ${({ theme }) => theme.waterBlue};
          }
        `
      : ''}

  ${({ clone }) =>
    clone
      ? css`
          border-color: ${({ theme }) => theme.waterBlue};
          & :hover {
            border-color: ${({ theme }) => theme.waterBlue};
          }
        `
      : ''}

  ${({ isSelected }) =>
    isSelected
      ? css`
          border-color: ${({ theme }) => theme.waterBlue};
          & :hover {
            border-color: ${({ theme }) => theme.waterBlue};
          }
        `
      : ''}
`;

const StyledGridRow = styled(GridTable.Row)`
  min-height: 4.4rem;
  grid-template-columns: min-content 1fr min-content;
  align-items: center;
  padding: 0.6rem 0.6rem 0.6rem 0.4rem;
  border-radius: 5px;
  border: none;
  gap: 0.5rem;
  background-color: transparent;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    grid-template-columns: min-content 1fr 1fr 1fr min-content;
    padding: 0 0.6rem 0 0;
    gap: 0;
  }
`;

const PlayerWrapper = styled.div`
  grid-template-columns: 1fr;
  padding: 0 0.6rem 0.6rem 0.6rem;
`;

const IconsWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  grid-template-columns: 1fr;
  align-items: center;
  gap: 0.6rem;
  padding: 1.5rem 0.6rem;

  ${({ $moreOptionsPopupIsOpen }) =>
    $moreOptionsPopupIsOpen &&
    css`
      background-color: ${({ theme }) => theme.uiBackground};
      border: 1px solid ${({ theme }) => theme.stroke};
      border-radius: 4px;
      padding: 1.4rem 0.6rem;
    `}

  @media only screen and (min-width: ${({ theme }) =>
    theme.breakpoints.large}px) {
    background-color: transparent;
    border: none;
    border-radius: 0;
    padding: 0;
  }
`;

const IconWrapper = styled(BasicButton)`
  display: grid;
  position: relative;
  padding: 0 0.8rem;
`;

const MainWrapper = styled.div`
  display: grid;
  width: fit-content;
`;

const Bottom = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto 1fr;
  gap: 0.8rem;
`;

const Year = styled(GridTable.Value)`
  user-select: none;
`;

const MarkValue = styled.div`
  height: 100%;
  display: grid;
  align-content: center;
  ${({ theme, mark, small }) =>
    mark
      ? css`
          color: ${theme.waterBlue};
        `
      : css`
          color: ${small ? theme.dark300 : theme.dark400};
        `}

  ${({ small }) =>
    small
      ? css`
          font-size: 1.3rem;
        `
      : ''}

  @media only screen and (min-width: ${({ theme }) =>
    theme.breakpoints.large}px) {
    padding: 0 1rem;
  }
`;

const StyledValue = styled(GridTable.Value)`
  user-select: none;
`;

const Title = styled(StyledValue)`
  font-weight: 600;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    font-weight: 400;
  }
`;

const LoadingWrapper = styled.div`
  width: 2.5rem;
  padding: 0 0 0 0.5rem;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  align-self: center;
  justify-self: center;
`;

const StyledButton = styled(BasicButton)`
  margin: 0 0.8rem;
  display: grid;
  gap: 0.8rem;

  ${({ $mobile }) =>
    $mobile &&
    css`
      grid-template-columns: min-content 1fr;
    `}
`;

const StyledCheckBox = styled(CheckboxField)`
  margin: 0 1.2rem 0 1.4rem;
`;

const PlayIcon = styled(RawPlayIcon)`
  width: 2rem;
  height: 2rem;
  justify-self: center;
`;

const TrashIcon = styled(RawTrashIcon)`
  &:hover {
    color: ${({ theme }) => theme.grapefruitRed};
  }
`;

const AddToListIcon = styled(RawAddToListIcon)`
  &:hover {
    color: ${({ theme }) => theme.waterBlue};
  }
`;

const InfoIcon = styled(RawInfoIcon)`
  &:hover {
    color: ${({ theme }) => theme.waterBlue};
  }
`;

const EqualizerWrapper = styled.div`
  width: 4.6rem;
  display: grid;
  align-items: center;
  justify-content: center;
  padding-left: 0.8rem;
`;

const MiddleWrapper = styled.div`
  display: contents;
`;

const Divider = styled.div`
  width: 0.1rem;
  height: 90%;
  background-color: ${({ theme }) => theme.stroke};
`;

const PreviewText = styled.p`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.dark300};
`;

const StyledCloseIcon = styled(CloseIcon)`
  padding: 0.4rem 0;
`;

export const Song = forwardRef(
  (
    { handleProps, clone, ghost, song, sortBy, style, setTab, onClickSong },
    ref
  ) => {
    const { t } = useTranslation();
    const {
      dispatch,
      selectedZone,
      selectedPlaylist,
      songs,
      overId,
      selectedPlaylistType,
      lastSongValue,
      isSongSelectionActivated,
      selectedSongs,
      currentSongPlaying,
      stopAudio,
      setAudioSrc,
      setIsPlaying,
      isPlaying,
      startStopAudio,
    } = usePlaylists();

    const { isNarrowerThanLarge, isWiderThanLarge } = useMediaSize();

    const [isAddToListPopUpOpen, setAddToListPopup] = useState(false);
    const [moreOptionsPopupIsOpen, setMoreOptionsPopup] = useState(false);
    const [isSongInfoModalOpen, toggleSongInfoModal] = useToggle(false);

    const outsideRef = useRef(false);

    const playerIsOpen = currentSongPlaying === song.id;

    useOnClickOutside(
      outsideRef,
      () => {
        setMoreOptionsPopup(false);
        stopAudio();
      },
      'click'
    );

    const handleToggleMoreOptions = useCallback((event) => {
      event.stopPropagation();
      setMoreOptionsPopup((prev) => !prev);
    }, []);

    const handleClosePlaylistSelection = useCallback(() => {
      setAddToListPopup(false);
    }, []);

    const handleTogglePlaylistSelection = useCallback((event) => {
      event.stopPropagation();
      setAddToListPopup((prev) => !prev);
    }, []);

    const [saveCustomPlaylist, { loading }] = useMutation(
      SAVE_CUSTOM_PLAYLIST,
      {
        onCompleted: () => {
          dispatch({
            songs: songs.filter((i) => i.id !== song.id),
          });
        },
      }
    );

    const { data: dataSongUrl, loading: loadingSongUrl } = useQuery(
      GET_SONG_MEDIA_URL,
      {
        variables: {
          songId: song.songId,
        },
      }
    );

    const handleClickStart = useCallback(
      (event) => {
        event.stopPropagation();
        setMoreOptionsPopup(false);
        setAudioSrc(dataSongUrl?.songMediaUrl);
        setIsPlaying(true);
        startStopAudio();
        dispatch({
          currentSongPlaying: song.id,
        });
      },
      [
        dataSongUrl?.songMediaUrl,
        dispatch,
        setAudioSrc,
        setIsPlaying,
        song.id,
        startStopAudio,
      ]
    );

    const handleSavePlaylist = useCallback(
      (event) => {
        event.stopPropagation();
        saveCustomPlaylist({
          variables: {
            zoneId: selectedZone.id,
            playlistId: selectedPlaylist.id,
            playlist: {
              id: selectedPlaylist.id,
              title: selectedPlaylist.title,
              audioFiles: songs
                .filter((i) => i.id !== song.id)
                .map((i) => ({ id: i.songId, title: i.title })),
            },
          },
        });
      },
      [selectedZone.id, songs, selectedPlaylist, song, saveCustomPlaylist]
    );

    // Need to stop propagation in order to open the popup instead of draging the song
    const handleStopPropagation = useCallback(
      (event) => event.stopPropagation(),
      []
    );

    const selectedSongsModal = useMemo(() => [song], [song]);

    const handleCloseSongInfo = useCallback(() => {
      if (isSongInfoModalOpen) {
        toggleSongInfoModal();
      }
    }, [isSongInfoModalOpen, toggleSongInfoModal]);

    const handleOpenSongInfo = useCallback(
      (event) => {
        event.stopPropagation();
        if (!isSongInfoModalOpen) {
          toggleSongInfoModal();
        }
      },
      [isSongInfoModalOpen, toggleSongInfoModal]
    );

    const lastValuelabel = useMemo(() => {
      if (song?.[lastSongValue]) {
        if (
          lastSongValue === 'duration' &&
          typeof song[lastSongValue] === 'number'
        ) {
          return format(new Date(song[lastSongValue]), 'm:ss');
        }
        return song[lastSongValue];
      }
      return '-';
    }, [song, lastSongValue]);

    const canDelete =
      selectedPlaylist?.id && selectedPlaylistType === PlaylistTypes.CUSTOM;

    const isSelected =
      !!selectedSongs.find((s) => s.id === song.id) && isSongSelectionActivated;

    return (
      <>
        <Container
          ref={ref}
          clone={clone}
          style={style}
          ghost={ghost}
          isOverItem={overId?.includes(DroppableTypes.PLAYLIST)}
          isSelected={isSelected}
        >
          <StyledGridRow onClick={onClickSong}>
            <StyledCheckBox
              onClick={handleStopPropagation}
              checked={isSelected}
              color="waterBlue"
            />
            <MiddleWrapper {...(isSongSelectionActivated ? {} : handleProps)}>
              {isNarrowerThanLarge ? (
                <MainWrapper>
                  <MarkValue mark={sortBy === 'title'}>
                    <Title>{song?.title ?? '-'}</Title>
                  </MarkValue>
                  <Bottom>
                    <MarkValue small mark={sortBy === 'group'}>
                      <StyledValue>{song?.group ?? '-'}</StyledValue>
                    </MarkValue>
                    <MarkValue small mark={sortBy === 'lastValue'}>
                      <Year>{lastValuelabel}</Year>
                    </MarkValue>
                  </Bottom>
                </MainWrapper>
              ) : (
                <>
                  <MarkValue mark={sortBy === 'title'}>
                    <StyledValue>{song?.title ?? '-'}</StyledValue>
                  </MarkValue>
                  <MarkValue mark={sortBy === 'group'}>
                    <StyledValue>{song?.group ?? '-'}</StyledValue>
                  </MarkValue>
                  <MarkValue mark={sortBy === 'lastValue'}>
                    <StyledValue>{lastValuelabel}</StyledValue>
                  </MarkValue>
                </>
              )}
            </MiddleWrapper>
            <IconsWrapper>
              {isWiderThanLarge ? (
                <>
                  {playerIsOpen ? (
                    <EqualizerWrapper>
                      <Equalizer active={!loadingSongUrl && isPlaying} />
                    </EqualizerWrapper>
                  ) : (
                    <StyledButton
                      onClick={handleClickStart}
                      onPointerDown={handleStopPropagation}
                      onPointerUp={handleStopPropagation}
                      title={t('broadcasts:card.table.play')}
                    >
                      <PlayIcon color={theme.waterBlue} />
                    </StyledButton>
                  )}
                  <Divider />
                  {canDelete ? (
                    <>
                      {loading ? (
                        <LoadingWrapper>
                          <StyledLoadingIndicator
                            color="primary"
                            size="small"
                          />
                        </LoadingWrapper>
                      ) : (
                        <IconWrapper isDiv onClick={handleSavePlaylist}>
                          <TrashIcon
                            color={theme.dark300}
                            height="1.8rem"
                            width="1.8rem"
                          />
                        </IconWrapper>
                      )}
                      <Divider />
                    </>
                  ) : null}
                  <IconWrapper isDiv onClick={handleOpenSongInfo}>
                    <InfoIcon
                      color={theme.dark300}
                      height="1.7rem"
                      width="1.7rem"
                    />
                  </IconWrapper>
                  <Divider />
                  <IconWrapper isDiv onClick={handleTogglePlaylistSelection}>
                    <AddToListIcon
                      color={theme.dark300}
                      height="1.7rem"
                      width="1.7rem"
                    />
                  </IconWrapper>
                </>
              ) : (
                <IconWrapper isDiv onClick={handleToggleMoreOptions}>
                  {moreOptionsPopupIsOpen ? (
                    <StyledCloseIcon
                      color={theme.dark300}
                      height="1.8rem"
                      width="1.8rem"
                    />
                  ) : (
                    <MoreOptionsIcon
                      color={theme.dark300}
                      height="1.8rem"
                      width="1.8rem"
                    />
                  )}
                </IconWrapper>
              )}
            </IconsWrapper>
          </StyledGridRow>
          {!isWiderThanLarge && moreOptionsPopupIsOpen ? (
            <PlayerWrapper ref={outsideRef}>
              <IconsWrapper $moreOptionsPopupIsOpen={moreOptionsPopupIsOpen}>
                <StyledButton
                  onClick={handleClickStart}
                  onPointerDown={handleStopPropagation}
                  onPointerUp={handleStopPropagation}
                  title={t('broadcasts:card.table.play')}
                  $mobile
                >
                  <PlayIcon color={theme.waterBlue} />
                  <PreviewText>
                    {t('musicManagement:playlists.playlistSelection.preview')}
                  </PreviewText>
                </StyledButton>
                <Divider />
                {canDelete ? (
                  <>
                    {loading ? (
                      <LoadingWrapper>
                        <StyledLoadingIndicator color="primary" size="small" />
                      </LoadingWrapper>
                    ) : (
                      <IconWrapper isDiv onClick={handleSavePlaylist}>
                        <TrashIcon
                          color={theme.dark300}
                          height="1.8rem"
                          width="1.8rem"
                        />
                      </IconWrapper>
                    )}
                    <Divider />
                  </>
                ) : null}
                <IconWrapper isDiv onClick={handleOpenSongInfo}>
                  <InfoIcon
                    color={theme.dark300}
                    height="1.7rem"
                    width="1.7rem"
                  />
                </IconWrapper>
                <Divider />
                <IconWrapper isDiv onClick={handleTogglePlaylistSelection}>
                  <AddToListIcon
                    color={theme.dark300}
                    height="1.7rem"
                    width="1.7rem"
                  />
                </IconWrapper>
              </IconsWrapper>
            </PlayerWrapper>
          ) : null}
          {playerIsOpen ? (
            <PlayerWrapper
              onPointerDown={handleStopPropagation}
              onPointerUp={handleStopPropagation}
            >
              <AudioBar isLoading={loadingSongUrl} onClose={stopAudio} />
            </PlayerWrapper>
          ) : null}
        </Container>

        {isSongInfoModalOpen ? (
          <SongInfoModal
            isOpen={isSongInfoModalOpen}
            onClose={handleCloseSongInfo}
            setTab={setTab}
            song={song}
            title={t('common:button.songInfo')}
          />
        ) : null}
        {isAddToListPopUpOpen ? (
          <PlaylistSelectionModal
            instructions={t(
              'musicManagement:playlists.playlistSelection.instructions'
            )}
            isOpen={isAddToListPopUpOpen}
            onClose={handleClosePlaylistSelection}
            songs={selectedSongsModal}
            title={t('musicManagement:playlists.playlistSelection.title')}
          />
        ) : null}
      </>
    );
  }
);
