import { useMemo, useCallback, useReducer, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';

import { renewPurchaseReducer, initialRenewPurchaseState, RenewPurchaseAction } from './renewPurchaseReducer';
import { LoadingIndicator, ErrorMessage } from '../../../common';
import { useRenewPurchase, RenewPurchaseContext } from './renewPurchaseContext';
import {
  GET_AVAILABLE_PRODUCTS_FOR_ORGANIZATION_AND_SUBSCRIPTION_LINES,
  ADD_ITEMS_TO_CART,
  REMOVE_ITEMS_FROM_CART,
  SWITCH_CART_ITEMS,
  GET_PAYMENT_OPTIONS,
} from './api';

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin-top: 3rem;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 3rem;
`;

const RenewPurchaseProvider = ({ children }) => {
  const { organizationId } = useParams();

  const [state, dispatch] = useReducer(renewPurchaseReducer, initialRenewPurchaseState);

  useEffect(() => {
    // Whenever we mount, we reset the state values to the initial state
    // This is necessary because when we switch organizations (with the notifications drawer)
    // the state of the previous renew-subscriptions page will still be intact because this
    // provider does not unmount.
    dispatch({
      type: RenewPurchaseAction.CLEAR_STATE,
    });
  }, []);

  const { loading: initialQueryLoading, error: initialQueryError } = useQuery(
    GET_AVAILABLE_PRODUCTS_FOR_ORGANIZATION_AND_SUBSCRIPTION_LINES,
    {
      variables: { organizationId },
      // This is necessary because otherwise Apollo will "remember" the previous results
      // And will execute the switchCurrentProductsToForeverEquivalents twice, resulting in
      // duplicate lines in the sale order.
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        dispatch({
          type: RenewPurchaseAction.FETCHED_INITIAL_QUERY,
          payload: { initialQuery: data },
        });
      },
    }
  );

  const [cartItemsErrorFixed, setCartItemsErrorFixed] = useState(null);
  const [cartItemsErrorForever, setCartItemsErrorForever] = useState(null);

  // There is a bug with useMutation where loading is always false.
  const [addItemsToCartLoadingCount, setAddItemsToCartLoadingCount] = useState(0);
  const [addItemsToCart] = useMutation(
    ADD_ITEMS_TO_CART,
  );

  // There is a bug with useMutation where loading is always false.
  const [removeItemsFromCartLoadingCount, setRemoveItemsFromCartLoadingCount] = useState(0);
  const [removeItemsFromCart] = useMutation(
    REMOVE_ITEMS_FROM_CART,
  );

  // There is a bug with useMutation where loading is always false.
  const [switchCartItemsLoadingCount, setSwitchCartItemsLoadingCount] = useState(0);
  const [switchCartItems] = useMutation(
    SWITCH_CART_ITEMS,
  );

  const { loading: paymentOptionsLoading, error: paymentOptionsError } = useQuery(GET_PAYMENT_OPTIONS, {
    skip: !state.saleOrderProductIds.length && !state.alternativeRenewalSaleOrderProductIds.length,
    variables: {
      countryId: state.organization?.countryId,
      productIds: [...state.saleOrderProductIds, ...state.alternativeRenewalSaleOrderProductIds].sort(),
    },
    onCompleted: (data) => {
      if (data) {
        dispatch({
          type: RenewPurchaseAction.FETCHED_PAYMENT_OPTIONS,
          payload: { paymentOptions: data.paymentOptions },
        });
      }
    },
  });

  const {
    cartItemsToAddFixed,
    cartItemIdsToRemoveFixed,
    saleOrderFixed,
    cartItemsToAddForever,
    cartItemIdsToRemoveForever,
    saleOrderForever,
  } = state.userInput.wantsForever ? {
    cartItemsToAddFixed: state.alternativeRenewalCartItemsToAdd,
    cartItemIdsToRemoveFixed: state.alternativeRenewalCartItemIdsToRemove,
    saleOrderFixed: state.alternativeRenewalSaleOrder,
    cartItemsToAddForever: state.cartItemsToAdd,
    cartItemIdsToRemoveForever: state.cartItemIdsToRemove,
    saleOrderForever: state.saleOrder,
  } : {
    cartItemsToAddFixed: state.cartItemsToAdd,
    cartItemIdsToRemoveFixed: state.cartItemIdsToRemove,
    saleOrderFixed: state.saleOrder,
    cartItemsToAddForever: state.alternativeRenewalCartItemsToAdd,
    cartItemIdsToRemoveForever: state.alternativeRenewalCartItemIdsToRemove,
    saleOrderForever: state.alternativeRenewalSaleOrder,
  };

  const updateCartItems = useCallback(({
    wantsForever,
    cartItemsToAdd,
    cartItemIdsToRemove,
    saleOrder,
  }) => {
    const setError = wantsForever ? setCartItemsErrorForever : setCartItemsErrorFixed;
    if (cartItemsToAdd.length > 0 && cartItemIdsToRemove.length === 0) {
      setError(null);
      setAddItemsToCartLoadingCount(count => count + 1);
      addItemsToCart({
        variables: {
          organizationId: state.organization.id,
          cartItems: cartItemsToAdd,
          cartId: saleOrder ? saleOrder.id : null,
        },
      }).then(({ data }) => {
        dispatch({
          type: RenewPurchaseAction.UPDATED_CART_ITEMS,
          payload: { saleOrder: data.addItemsToCart, wantsForever },
        });
      }).catch((error) => {
        setError(error);
      }).finally(() => {
        setAddItemsToCartLoadingCount(count => count - 1);
      });
    }
    if (state.cartItemIdsToRemove.length > 0 && state.cartItemsToAdd.length === 0) {
      setError(null);
      setRemoveItemsFromCartLoadingCount(count => count + 1)
      removeItemsFromCart({
        variables: {
          organizationId: state.organization.id,
          cartItemIds: state.cartItemIdsToRemove,
          cartId: state.saleOrder.id,
        },
      }).then(({ data }) => {
        dispatch({
          type: RenewPurchaseAction.UPDATED_CART_ITEMS,
          payload: { saleOrder: data.removeItemsFromCart, wantsForever },
        });
      }).catch((error) => {
        setError(error);
      }).finally(() => {
        setRemoveItemsFromCartLoadingCount(count => count - 1);
      });
    }
    if (state.cartItemsToAdd.length > 0 && state.cartItemIdsToRemove.length > 0) {
      setError(null);
      setSwitchCartItemsLoadingCount(count => count + 1);
      switchCartItems({
        variables: {
          organizationId: state.organization.id,
          cartItemIds: state.cartItemIdsToRemove,
          cartItems: state.cartItemsToAdd,
          cartId: state.saleOrder.id,
        },
      }).then(({ data }) => {
        dispatch({
          type: RenewPurchaseAction.UPDATED_CART_ITEMS,
          payload: { saleOrder: data.addItemsToCart, wantsForever },
        });
      }).catch((error) => {
        setError(error);
      }).finally(() => {
        setSwitchCartItemsLoadingCount(count => count - 1);
      });
    }
  }, [
    addItemsToCart,
    removeItemsFromCart,
    state.cartItemIdsToRemove,
    state.cartItemsToAdd,
    state.organization?.id,
    state.saleOrder?.id,
    switchCartItems
  ]);

  useEffect(() => {
    updateCartItems({
      wantsForever: true,
      cartItemsToAdd: cartItemsToAddForever,
      cartItemIdsToRemove: cartItemIdsToRemoveForever,
      saleOrder: saleOrderForever,
    })
  }, [
    updateCartItems,
    cartItemsToAddForever,
    cartItemIdsToRemoveForever,
    saleOrderForever
  ]);

  useEffect(() => {
    updateCartItems({
      wantsForever: false,
      cartItemsToAdd: cartItemsToAddFixed,
      cartItemIdsToRemove: cartItemIdsToRemoveFixed,
      saleOrder: saleOrderFixed,
    })
  }, [updateCartItems, cartItemsToAddFixed, cartItemIdsToRemoveFixed, saleOrderFixed]);

  const switchIsForever = useCallback((wantsForever) => {
    dispatch({
      type: RenewPurchaseAction.TOGGLE_FOREVER_PRODUCT_SWITCH,
      payload: { wantsForever },
    });
  }, []);

  const toggleSelectedZone = useCallback(
    (zone) => {
      if (state.userInput.selectedZones.some((selectedZone) => selectedZone.id === zone.id)) {
        dispatch({
          type: RenewPurchaseAction.DESELECT_ZONE,
          payload: { zone },
        });
      } else {
        dispatch({
          type: RenewPurchaseAction.SELECT_ZONE,
          payload: { zone },
        });
      }
    },
    [state]
  );

  const selectPaymentOption = useCallback((paymentOption) => {
    dispatch({ type: RenewPurchaseAction.SELECT_PAYMENT_OPTION, payload: { paymentOption } });
  }, []);

  const content = useMemo(() => {
    if (initialQueryLoading) return <StyledLoadingIndicator />;
    if (initialQueryError) return <StyledErrorMessage withPadding error={initialQueryError} />;
    return children;
  }, [children, initialQueryLoading, initialQueryError]);

  const renewPurchaseValue = useMemo(
    () => ({
      organization: state.organization,
      isForever: state.userInput.wantsForever,
      switchIsForever,
      selectedZones: state.userInput.selectedZones,
      toggleSelectedZone,
      cartLoading: addItemsToCartLoadingCount > 0 || removeItemsFromCartLoadingCount > 0 || switchCartItemsLoadingCount > 0,
      cartError: state.userInput.wantsForever ? cartItemsErrorForever : cartItemsErrorFixed,
      order: state.saleOrder,
      selectPaymentOption,
      selectedPaymentOption: state.userInput.selectedPaymentOption,
      availableProducts: state.availableProducts,
      availablePaymentOptions: state.availablePaymentOptions,
      availableAddons: state.availablePaidAddons,
      zones: state.zones,
      paymentOptionsError,
      paymentOptionsLoading,
    }),
    [
      state.organization,
      state.userInput.wantsForever,
      state.userInput.selectedZones,
      state.userInput.selectedPaymentOption,
      state.saleOrder,
      state.availableProducts,
      state.availablePaymentOptions,
      state.availablePaidAddons,
      state.zones,
      switchIsForever,
      toggleSelectedZone,
      addItemsToCartLoadingCount,
      removeItemsFromCartLoadingCount,
      switchCartItemsLoadingCount,
      cartItemsErrorForever,
      cartItemsErrorFixed,
      selectPaymentOption,
      paymentOptionsError,
      paymentOptionsLoading
    ]
  );

  return <RenewPurchaseContext.Provider value={renewPurchaseValue}>{content}</RenewPurchaseContext.Provider>;
};

export { RenewPurchaseProvider, useRenewPurchase };
