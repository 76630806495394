import { useMemo, useCallback, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import { CustomerSuccessScheduledCallsContext } from './scheduledCallsContext';
import EditContactModal from './editContactModal';
import { GET_OPEN_CONTACT_CALLS, GET_CONTACT_CATEGORIES } from './api';

export const LIMIT = 10;

const CustomerSuccessScheduledCallsProvider = ({ children }) => {
  const { t } = useTranslation();
  const [hasMore, setHasMore] = useState(true);
  const [refetchError, setRefetchError] = useState(null);

  const [scheduledCall, setScheduledCall] = useState(null);
  const [editContactModalIsOpen, setEditContactModalIsOpen] = useState(false);
  const toggleEditContactModal = useCallback(
    () => setEditContactModalIsOpen((prev) => !prev),
    []
  );

  const {
    data: contactCategoriesData,
    loading: contactCategoriesLoading,
    error: contactCategoriesError,
  } = useQuery(GET_CONTACT_CATEGORIES, { fetchPolicy: 'cache-first' });

  const {
    data: contactCallsData,
    loading: contactCallsLoading,
    error: contactCallsError,
    fetchMore,
  } = useQuery(GET_OPEN_CONTACT_CALLS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      offset: 0,
      limit: LIMIT,
    },
  });

  const handleLoadMore = useCallback(async () => {
    if (contactCallsError || refetchError) return; // Don't refetch when an error occured
    try {
      await fetchMore({
        variables: {
          offset: contactCallsData.openContactCalls.length,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          setHasMore(fetchMoreResult.openContactCalls.length === LIMIT);

          return {
            ...prev,
            openContactCalls: [
              ...prev.openContactCalls,
              ...fetchMoreResult.openContactCalls,
            ],
          };
        },
      });
    } catch (e) {
      // Although fetchMore is the way to go for pagination, it provides no easy to use mechanism for error handling
      // you have to catch the error yourself and set an error state
      setRefetchError(e);
    }
  }, [fetchMore, contactCallsError, contactCallsData, refetchError]);

  const handleOpenEditContactModal = useCallback(
    (scheduledCallValues) => {
      setScheduledCall(scheduledCallValues);
      toggleEditContactModal();
    },
    [toggleEditContactModal]
  );

  const contextValue = useMemo(
    () => ({
      scheduledCalls: contactCallsData?.openContactCalls || [],
      contactCategories: contactCategoriesData?.contactCategories || [],
      loadMore: handleLoadMore,
      hasMore,
      loading: contactCallsLoading || contactCategoriesLoading,
      error: contactCallsError || contactCategoriesError || refetchError,
      openEditContactModal: handleOpenEditContactModal,
      setHasMore,
    }),
    [
      contactCallsLoading,
      contactCallsError,
      handleLoadMore,
      contactCallsData,
      hasMore,
      refetchError,
      contactCategoriesData,
      contactCategoriesLoading,
      contactCategoriesError,
      handleOpenEditContactModal,
    ]
  );

  return (
    <CustomerSuccessScheduledCallsContext.Provider value={contextValue}>
      {children}
      {scheduledCall && editContactModalIsOpen ? (
        <EditContactModal
          isOpen={editContactModalIsOpen}
          scheduledCall={scheduledCall}
          title={t('customerSuccess:contact.edit')}
          toggle={toggleEditContactModal}
        />
      ) : null}
    </CustomerSuccessScheduledCallsContext.Provider>
  );
};

export default CustomerSuccessScheduledCallsProvider;
