import styled from 'styled-components';

const LabelContainer = styled.label`
  transition: color 0.333s ease-in-out;
  color: ${({ disabled, theme }) => (disabled ? theme.dark200 : theme.dark600)};
  margin-bottom: 0.5rem;
`;

const Label = ({ children, required, disabled, className }) => (
  <LabelContainer className={className} disabled={disabled}>
    {children}
    &nbsp;
    {required && '*'}
  </LabelContainer>
);

export default Label;
