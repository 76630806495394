import { useMemo, useCallback } from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import {
  LoadingIndicator,
  formatDate,
  CheckboxCard,
} from '../../../../../common';
import { useSpotifyImport } from '../../spotifyImportContext';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close.svg';

const Container = styled.div`
  display: grid;
  gap: 1rem;
`;

const Name = styled.p`
  font-size: 1.6rem;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Songs = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.dark400};
  white-space: nowrap;
`;

const Bottom = styled.div`
  display: grid;
  grid-template-columns: min-content min-content 1fr;
  gap: 0.8rem;
  align-items: center;
`;

const Divider = styled.div`
  height: 1.4rem;
  width: 0.1rem;
  background-color: ${({ theme }) => theme.dark100};
`;

const Imported = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  gap: 0.6rem;
  color: ${({ theme }) => theme.dark400};

  & span {
    color: ${({ theme }) => theme.dark300};
  }
`;

const FailIcon = styled(CloseIcon)`
  width: 1rem;
  height: 1rem;
  color: ${({ theme }) => theme.grapefruitRed};
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin: 0 1rem;
`;

const PlaylistRow = ({ playlist, playlistStatusesByIds }) => {
  const { t, i18n } = useTranslation();
  const { selectedPlaylists, togglePlaylistCheckBox } = useSpotifyImport();

  const isChecked = useMemo(
    () =>
      selectedPlaylists.some(
        (selectedPlaylist) => selectedPlaylist.id === playlist.id
      ),
    [selectedPlaylists, playlist]
  );

  const isDisabled = useMemo(() => {
    const playlistStatus = playlistStatusesByIds[playlist.id];
    if (!playlistStatus) return true; // Still loading or error occurred during request
    return (
      playlistStatusesByIds[playlist.id].status === 'WAITING' ||
      playlistStatusesByIds[playlist.id].status === 'BUSY'
    );
  }, [playlistStatusesByIds, playlist.id]);

  const playlistStatusView = useMemo(() => {
    const playlistStatus = playlistStatusesByIds[playlist.id];
    if (!playlistStatus) return ''; // Still loading or error occurred during request
    const { status } = playlistStatus;
    if (status === 'NOT') return ''; // If the playlist has never been imported, show empty column
    if (status === 'WAITING' || status === 'BUSY')
      return <StyledLoadingIndicator size="smallMedium" />;
    if (status === 'FAILED') return <FailIcon />;
    return playlistStatus.timestamp
      ? formatDate(playlistStatus.timestamp, i18n.language)
      : ''; // Status is definitely SUCCESS
  }, [playlistStatusesByIds, playlist.id, i18n.language]);

  const handleRowClick = useCallback(() => {
    if (!isDisabled) {
      togglePlaylistCheckBox(playlist);
    }
  }, [isDisabled, togglePlaylistCheckBox, playlist]);

  return (
    <CheckboxCard
      isSelected={isChecked}
      onClick={handleRowClick}
      isDisabled={isDisabled}
      title={
        <Container>
          <Name>{playlist.name}</Name>
          <Bottom>
            <Songs>
              {t('integrations:spotify.import.songs', {
                songsAmount: playlist.tracks.total,
              })}
            </Songs>
            <Divider />
            <Imported>
              <span>{t('integrations:spotify.import.lastImported')}:</span>{' '}
              {playlistStatusView}
            </Imported>
          </Bottom>
        </Container>
      }
    ></CheckboxCard>
  );
};

export default PlaylistRow;
