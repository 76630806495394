import gql from 'graphql-tag';

export const GET_MUSIC_COLLECTION_AS_PLAYLIST = gql`
  query getMusicCollectionAsPlaylist($musicCollectionId: ID!, $musicChannelId: ID!, $musicProfileId: ID!) {
    musicCollectionAsPlaylist(
      musicCollectionId: $musicCollectionId
      musicChannelId: $musicChannelId
      musicProfileId: $musicProfileId
    ) {
      id
      audioFiles {
        id
        duration
        group
        location
        title
        composer
        bpm
        musicFeel
        version
        year
        mood
        dancingStyle
        startAudioSignal
        endAudioSignal
        fadeBeginPosition
        musicStyle
        subStyle
        genreDescription
        rhythmDescription
      }
    }
  }
`;

export const GET_MUSIC_COLLECTION_CONTEXT = gql`
  query getMusicCollectionContext($musicCollectionId: ID!, $musicChannelId: ID!, $musicProfileId: ID!) {
    musicCollectionContext(
      musicCollectionId: $musicCollectionId
      musicChannelId: $musicChannelId
      musicProfileId: $musicProfileId
    ) {
      type
      id
      title
      description
      timestamp
      changeableParameter {
        type
        name
        metadataDisplayType
        metadataDisplayName
        value {
          value
          selected
        }
        minimum
        maximum
        step
        lowerSelection
        upperSelection
        metadataDisplayMinimum
        metadataDisplayMaximum
      }
      defaultChangeableParameter {
        type
        name
        metadataDisplayType
        metadataDisplayName
        value {
          value
          selected
        }
        minimum
        maximum
        step
        lowerSelection
        upperSelection
        metadataDisplayMinimum
        metadataDisplayMaximum
      }
    }
  }
`;

export const UPDATE_MUSIC_COLLECTION_CONTEXT = gql`
  mutation updateMusicCollectionContext(
    $musicCollectionId: ID!
    $musicChannelId: ID!
    $musicProfileId: ID!
    $changeableParameters: [ChangeableParameterInput!]!
  ) {
    updateMusicCollectionContext(
      musicCollectionId: $musicCollectionId
      musicChannelId: $musicChannelId
      musicProfileId: $musicProfileId
      changeableParameters: $changeableParameters
    )
  }
`;

export const GET_MUSIC_COLLECTION_TWEAK_STRENGTH = gql`
  query getMusicCollectionTweakStrength(
    $musicCollectionId: ID!
    $musicProfileId: ID!
    $changeableParameters: [ChangeableParameterInput!]!
  ) {
    musicCollectionTweakStrength(
      musicCollectionId: $musicCollectionId
      musicProfileId: $musicProfileId
      changeableParameters: $changeableParameters
    ) {
      percentage
      status
    }
  }
`;

export const GET_SONG_MEDIA_URL = gql`
  query getSongMediaUrl($songId: ID!) {
    songMediaUrl(songId: $songId)
  }
`;
