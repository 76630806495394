import { Outlet } from 'react-router';
import styled from 'styled-components';

const StyledContainer = styled.div`
  @media (min-width: 500px) {
    position: relative;
    min-height: 100vh;
    width: 100%;
    background-image: url('https://www-public-cdn.tunify.com/images/mytunify_registration_background.webp');
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: 600px) {
    background-position: 50%;

    display: grid;
    grid-template-rows: [left-start center-start right-start] 1fr [left-end center-end right-end];
    grid-template-columns: [left-start center-start] 1fr [left-end right-start] 1fr [center-end right-end];
    grid-column-gap: 180px;

    justify-content: center;
    justify-items: center;
    align-items: center;

    padding: 80px;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 40px;

  @media (min-width: 500px) {
    width: 400px;
    background-color: white;
    flex: 1;
  }

  @media (min-width: 600px) {
    height: fit-content;
    grid-area: right;
    border-radius: 6px;
  }
`;

const StyledImage = styled.img`
  height: 48px;
  width: 100%;
  margin: 0 auto;
  object-fit: contain;
  margin-bottom: 24px;
`;

const StyledContentContainer = styled.div`
  position: relative;
  height: 100%;
`;

export const LoginLikeLayout = () => {
  return (
    <StyledContainer>
      <Container>
        <StyledContentContainer>
          <StyledImage src="https://my.tunify.com/static-templates/logoBlackSharp.png" />
          <Outlet />
        </StyledContentContainer>
      </Container>
    </StyledContainer>
  );
};
