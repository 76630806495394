import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/react-hooks';

import { GET_PLAYER_LINK } from './api';
import { usePlaylists } from './playlistsProvider';
import WidePlayerButton from '../../../common/button/widePlayerButton';

const Container = styled.div`
  padding: 2rem;
  text-align: center;
`;

const Description = styled.p`
  color: ${({ theme }) => theme.dark400};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.429;
  margin-bottom: 2rem;
`;

const StartPlayer = () => {
  const { t } = useTranslation();
  const { selectedZone } = usePlaylists();

  const [getPlayerLink, { loading }] = useLazyQuery(GET_PLAYER_LINK, {
    variables: { zoneId: Number(selectedZone.id) },
    onCompleted: ({ playerLink }) => {
      window.open(playerLink, '_blank', 'noopener noreferrer');
    },
    fetchPolicy: 'network-only',
  });

  return (
    <Container>
      <Description>
        {t('musicManagement:playlist.startPlayer.description')}
      </Description>
      <WidePlayerButton
        onClick={() => getPlayerLink()}
        isLoading={loading}
      />
    </Container>
  );
};

export default StartPlayer;
