import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import FilterResultRow from './filterTableRow';
import { LoadingIndicator, ErrorMessage } from '../../../../../common';
import { useCustomerSuccessHistory } from '../../historyContext';
import HistoryFilterCard from '../filter';

const Container = styled.div`
  display: grid;
  grid-gap: 0.8rem;
  padding: 0.8rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    padding: 1.6rem 2.4rem;
  }
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    padding: 1.6rem 4.6rem;
  }
`;

const TableMessage = styled.div`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.dark300};
  line-height: 1.4;
  padding-top: 1rem;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin: 1rem;
`;

const TableBody = () => {
  const { t } = useTranslation();
  const { subscriptionsLoading, subscriptionsError, subscriptions } =
    useCustomerSuccessHistory();

  if (subscriptionsLoading && subscriptions.length === 0) {
    return <StyledLoadingIndicator />;
  }

  if (subscriptions.length === 0 && subscriptionsError) {
    return <ErrorMessage error={subscriptionsError} />;
  }

  return (
    <Container>
      <HistoryFilterCard />
      {subscriptions.length === 0 && (
        <TableMessage>{t('customerSuccess:history.tbNoResults')}</TableMessage>
      )}
      {subscriptions?.map((subscription) => (
        <FilterResultRow
          key={subscription.id}
          subscriptionEntry={subscription}
        />
      ))}
      {subscriptionsError && <ErrorMessage error={subscriptionsError} />}
      {subscriptionsLoading && <StyledLoadingIndicator />}
    </Container>
  );
};

export default TableBody;
