import { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ReactComponent as AnalyticsLarge } from '../../../assets/icons/navigation/analyticsLarge.svg';
import { Button } from '../../../common';
import theme from '../../../global/style/theme';

const Container = styled.div`
  display: grid;
  row-gap: 2.4rem;
  grid-template-columns: 1fr;
  background-color: ${({ theme }) => theme.white};
  padding: 1.5rem 1.5rem 0 1.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.stroke};

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    padding: 2.2rem 3rem 0 3rem;
    row-gap: 3rem;
  }
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    padding: 3.2rem 4rem 0 4rem;
  }
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    padding: 4rem 4.8rem 0 4.8rem;
  }
`;

const TitleContainer = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  gap: 1.6rem;
  grid-column: span 2;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    grid-column: unset;
  }
`;

const Logo = styled.div`
  width: 4rem;
  height: 4rem;
  display: grid;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.dark500};
  border-radius: 4px;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    width: 4.8rem;
    height: 4.8rem;
  }
`;

const StyledAnalyticsLarge = styled(AnalyticsLarge)`
  width: 2.6rem;
  height: 2.6rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    width: 3.2rem;
    height: 3.2rem;
  }
`;

const Title = styled.h1`
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    font-size: 2.8rem;
  }
`;

const Bottom = styled.div`
  display: grid;
  gap: 3rem;
  grid-template-columns: repeat(3, min-content);
  overflow-x: auto;
`;

const TabButton = styled(Button)`
  white-space: nowrap;
  padding-bottom: 1rem;
  border-width: 4px;
  color: ${({ theme }) => theme.dark500};
  font-weight: 600;

  ${({ selected }) =>
    selected
      ? css``
      : css`
          border: none;
          padding-bottom: 1.4rem;
        `}
`;

const PageHeader = ({ selectedCategory, setSelectedCategory }) => {
  const { t } = useTranslation();

  const handleSelect = useCallback(
    (category) => {
      setSelectedCategory(category);
    },
    [setSelectedCategory]
  );

  return (
    <Container>
      <TitleContainer>
        <Logo>
          <StyledAnalyticsLarge color={theme.white} />
        </Logo>
        <Title>{t('musicManagement:musicReport.title')}</Title>
      </TitleContainer>
      <Bottom>
        <TabButton
          emphasis="secondary"
          selected={selectedCategory === 'report'}
          onClick={() => handleSelect('report')}
        >
          {t('musicManagement:musicReport.subtitle')}
        </TabButton>
        <TabButton
          emphasis="secondary"
          selected={selectedCategory === 'musicHistory'}
          onClick={() => handleSelect('musicHistory')}
        >
          {t('musicManagement:musicReport.musicHistory.title')}
        </TabButton>
        {/* <TabButton
          emphasis="secondary"
          selected={selectedCategory === 'suggestions'}
          onClick={() => handleSelect('suggestions')}
        >
          {t('musicManagement:musicReport.suggestions.title')}
        </TabButton> */}
      </Bottom>
    </Container>
  );
};

export default PageHeader;
