import { Route } from 'react-router';
import {
  EXPERIMENTAL_ROLE_PERMISSIONS,
  OWNER_ROLE_PERMISSIONS,
} from '../../../global/auth';
import { AuthGuardPermissions } from '../../../global/auth/authGuardPermissions';
import { MusicManagementPage } from '../../../modules/musicManagement';

const requiredPermissionsMusicManagement = [
  ...OWNER_ROLE_PERMISSIONS,
  ...EXPERIMENTAL_ROLE_PERMISSIONS,
];

export const MusicManagementRouter = [
  <Route
    index
    element={
      <AuthGuardPermissions permissions={requiredPermissionsMusicManagement}>
        <MusicManagementPage />
      </AuthGuardPermissions>
    }
  />,
];
