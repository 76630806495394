import { useCallback } from 'react';
import { GET_CUSTOM_PLAYLISTS_OF_ZONE, GET_FAVOURITE_CUSTOM_PLAYLIST_OF_ZONE } from './api';

export const useUpdatePlaylistCacheAfterRemove = (variables, playlistId) => {
  return useCallback(
    (cache, mutationResult) => {
      const { customPlaylistsOfZone } = cache.readQuery({
        query: GET_CUSTOM_PLAYLISTS_OF_ZONE,
        variables,
      });

      const {
        data: { deleteCustomPlaylist },
      } = mutationResult;

      const updatedPlaylists = customPlaylistsOfZone.customPlaylists.filter((p) => Number(p.id) !== Number(playlistId));

      const data = {
        customPlaylistsOfZone: {
          ...customPlaylistsOfZone,
          customPlaylists: updatedPlaylists,
          timestamp: deleteCustomPlaylist?.customPlaylistsTimestamp ?? customPlaylistsOfZone?.timestamp,
        },
      };

      cache.writeQuery({
        query: GET_CUSTOM_PLAYLISTS_OF_ZONE,
        variables,
        data,
      });
    },
    [variables, playlistId]
  );
};

export const useUpdatePlaylistCacheAfterCreate = (variables) => {
  return useCallback(
    (cache, mutationResult) => {
      const { customPlaylistsOfZone } = cache.readQuery({
        query: GET_CUSTOM_PLAYLISTS_OF_ZONE,
        variables,
      });

      const {
        data: { createCustomPlaylist },
      } = mutationResult;

      const updatedPlaylists = [...customPlaylistsOfZone.customPlaylists, createCustomPlaylist?.playlist].filter(
        Boolean
      );

      const data = {
        customPlaylistsOfZone: {
          ...customPlaylistsOfZone,
          customPlaylists: updatedPlaylists,
          timestamp: createCustomPlaylist?.customPlaylistsTimestamp ?? customPlaylistsOfZone?.timestamp,
        },
      };

      cache.writeQuery({
        query: GET_CUSTOM_PLAYLISTS_OF_ZONE,
        variables,
        data,
      });
    },
    [variables]
  );
};

export const useUpdatePlaylistCacheAfterUpdate = ({ zoneId, playlistId, newVars }, setSelectedPlaylist) => {
  return useCallback(
    (cache, mutationResult) => {
      const { customPlaylistsOfZone } = cache.readQuery({
        query: GET_CUSTOM_PLAYLISTS_OF_ZONE,
        variables: {
          zoneId,
        },
      });

      const {
        data: { editCustomPlaylist },
      } = mutationResult;

      const currentPlaylist = customPlaylistsOfZone.customPlaylists.find((p) => Number(p.id) === Number(playlistId));
      const updatedPlaylist = {
        ...currentPlaylist,
        ...newVars,
        timestamp: editCustomPlaylist?.timestamp ?? currentPlaylist?.timestamp,
      };
      const updatedPlaylists = [
        ...customPlaylistsOfZone.customPlaylists.filter((p) => Number(p.id) !== Number(playlistId)),
        updatedPlaylist,
      ];

      setSelectedPlaylist(updatedPlaylist);

      const data = {
        customPlaylistsOfZone: { ...customPlaylistsOfZone, customPlaylists: updatedPlaylists },
      };

      cache.writeQuery({
        query: GET_CUSTOM_PLAYLISTS_OF_ZONE,
        variables: {
          zoneId,
        },
        data,
      });
    },
    [zoneId, playlistId, newVars, setSelectedPlaylist]
  );
};

export const useUpdatePlaylistCacheAfterAddSongs = (
  { zoneId, playlistId, selectedPlaylistId },
  setSelectedPlaylist
) => {
  return useCallback(
    (cache, mutationResult) => {
      const { customPlaylistsOfZone } = cache.readQuery({
        query: GET_CUSTOM_PLAYLISTS_OF_ZONE,
        variables: {
          zoneId,
        },
      });
      const { favouriteCustomPlaylistOfZone } = cache.readQuery({
        query: GET_FAVOURITE_CUSTOM_PLAYLIST_OF_ZONE,
        variables: {
          zoneId,
        },
      });

      const {
        data: { addSongsToCustomPlaylist },
      } = mutationResult;

      if (Number(playlistId) === Number(favouriteCustomPlaylistOfZone.id)) {
        const updatedPlaylist = {
          ...favouriteCustomPlaylistOfZone,
          timestamp: addSongsToCustomPlaylist?.timestamp ?? favouriteCustomPlaylistOfZone?.timestamp,
        };

        if (Number(selectedPlaylistId) === Number(playlistId)) {
          setSelectedPlaylist(updatedPlaylist);
        }

        const data = {
          favouriteCustomPlaylistOfZone: updatedPlaylist,
        };

        cache.writeQuery({
          query: GET_FAVOURITE_CUSTOM_PLAYLIST_OF_ZONE,
          variables: {
            zoneId,
          },
          data,
        });
      } else {
        const currentPlaylist = customPlaylistsOfZone.customPlaylists.find((p) => Number(p.id) === Number(playlistId));
        const updatedPlaylist = {
          ...currentPlaylist,
          timestamp: addSongsToCustomPlaylist?.timestamp ?? currentPlaylist?.timestamp,
        };
        const updatedPlaylists = [
          ...customPlaylistsOfZone.customPlaylists.filter((p) => Number(p.id) !== Number(playlistId)),
          updatedPlaylist,
        ];

        if (Number(selectedPlaylistId) === Number(playlistId)) {
          setSelectedPlaylist(updatedPlaylist);
        }

        const data = {
          customPlaylistsOfZone: { ...customPlaylistsOfZone, customPlaylists: updatedPlaylists },
        };

        cache.writeQuery({
          query: GET_CUSTOM_PLAYLISTS_OF_ZONE,
          variables: {
            zoneId,
          },
          data,
        });
      }
    },
    [zoneId, playlistId, setSelectedPlaylist, selectedPlaylistId]
  );
};
