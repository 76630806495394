import { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useDroppable } from '@dnd-kit/core';

import { useMediaSize } from '../../../../common';
import { ReactComponent as Playlists } from '../../../../assets/icons/navigation/playlists.svg';
import { SelectablePlaylistButton } from '../../shared';
import { usePlaylists } from '../playlistsProvider';
import { DroppableTypes, PlaylistTypes } from '../types';
import theme from '../../../../global/style/theme';

const Box = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  border: 1px solid ${({ theme }) => theme.stroke};
  border-radius: 4px;
  display: grid;
  align-items: center;
  justify-content: center;
`;

const StyledSelectablePlaylistButton = styled(SelectablePlaylistButton)`
  ${({ isOver }) =>
    isOver &&
    css`
      background-color: ${theme.dark200};
    `}
`;

const ListItem = ({ list }) => {
  const { dispatch, selectedPlaylist, overId } = usePlaylists();

  const { isNarrowerThanMedium } = useMediaSize();

  const { setNodeRef } = useDroppable({
    id: `${DroppableTypes.PLAYLIST}_${list.id}`,
    data: {
      type: DroppableTypes.PLAYLIST,
      listId: list.id,
    },
    disabled: isNarrowerThanMedium,
  });

  const handleClickListItem = useCallback(() => {
    dispatch({
      selectedPlaylistType: PlaylistTypes.CUSTOM,
      selectedPlaylist: list,
    });
  }, [dispatch, list]);

  return (
    <div ref={setNodeRef}>
      <StyledSelectablePlaylistButton
        key={list.id}
        isOver={overId === `${DroppableTypes.PLAYLIST}_${list.id}`}
        onClick={handleClickListItem}
        selected={selectedPlaylist?.id === list.id}
        type="button"
        value={list.id}
      >
        <Box>
          <Playlists color={theme.dark400} height="1.6rem" width="1.6rem" />
        </Box>
        <p>{list.title}</p>
      </StyledSelectablePlaylistButton>
    </div>
  );
};

export default ListItem;
