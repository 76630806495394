import { useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ErrorMessage, LoadingIndicator } from '../../../../../common';

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Label = styled.span`
  margin-top: 1rem;
  font-weight: 800;
  font-size: 1.1rem;
`;

const SubLabel = styled.span`
  margin-top: 2rem;
`;

const Card = styled.button`
  border: solid 1px ${({ theme }) => theme.dark200};
  border-radius: 5px;
  background: inherit;
  color: inherit;
  margin: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  transition: border 0.3s ease-in-out;
  &:hover {
    border: solid 1px ${({ theme }) => theme.dark300};
  }
  &:disabled {
    cursor: default;
  }
`;

const MusicLicenseCard = ({ musicLicense, onClick }) => {
  const { t } = useTranslation();

  const handleClickLicense = useCallback(() => {
    onClick(musicLicense.id);
  }, [onClick, musicLicense]);

  return (
    <Card onClick={handleClickLicense}>
      <Label>{musicLicense.name}</Label>
      <SubLabel>
        {musicLicense.id.toString() === '1'
          ? t('musicManagement:addMusicProfile.commercialSub')
          : t('musicManagement:addMusicProfile.royaltyFreeSub')}
      </SubLabel>
    </Card>
  );
};

const CategoryStep = ({
  next,
  setCategoryId,
  error,
  musicLicensesLoading,
  musicLicenses,
}) => {
  const { t } = useTranslation();

  const handleClickLicense = useCallback(
    (id) => {
      setCategoryId(id);
      next();
    },
    [setCategoryId, next]
  );

  return (
    <>
      <p>{t('musicManagement:addMusicProfile.info')}</p>
      {error ? <ErrorMessage error={error} /> : null}
      {musicLicensesLoading ? (
        <LoadingIndicator />
      ) : (
        <CardWrapper>
          {musicLicenses.map((musicLicense) => (
            <MusicLicenseCard
              key={musicLicense.id}
              musicLicense={musicLicense}
              onClick={handleClickLicense}
            />
          ))}
        </CardWrapper>
      )}
    </>
  );
};

export default CategoryStep;
