import { useRef, useCallback, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useOnClickOutside } from '..';
import { Button } from '../button';
import { ReactComponent as RawPlayIcon } from '../../assets/icons/simplePlayControls/play.svg';
import { ReactComponent as RawStopIcon } from '../../assets/icons/simplePlayControls/stop.svg';
import theme from '../../global/style/theme';

const PlayIcon = styled(RawPlayIcon)`
  width: 0.8rem;
  height: 1rem;
  justify-self: center;
  margin-left: 0.15rem;
`;

const StopIcon = styled(RawStopIcon)`
  width: 1rem;
  height: 1rem;
  justify-self: center;
`;

const StyledButton = styled(Button)`
  height: 3rem;
  width: 3rem;
  padding: 0;
  border-color: ${({ theme }) => theme.waterBlue};
`;

const AudioButton = ({ src }) => {
  const { t } = useTranslation();
  const audioRef = useRef(null);
  const buttonRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleClick = useCallback((event) => {
    event.stopPropagation();
    if (audioRef.current.paused) {
      audioRef.current.play();
      setIsPlaying(true);
    } else {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsPlaying(false);
    }
  }, []);

  const stopAudio = useCallback(() => {
    if (!audioRef.current.paused) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsPlaying(false);
    }
  }, []);

  const handleEndedAudio = useCallback(() => {
    audioRef.current.currentTime = 0;
    setIsPlaying(false);
  }, []);

  useOnClickOutside(buttonRef, stopAudio);

  return (
    <>
      <div ref={buttonRef}>
        <StyledButton
          emphasis="outline"
          isDiv
          onClick={handleClick}
          title={t('broadcasts:card.table.play')}
        >
          {isPlaying ? <StopIcon /> : <PlayIcon color={theme.waterBlue} />}
        </StyledButton>
      </div>
      <audio ref={audioRef} onEnded={handleEndedAudio} preload="none">
        <source src={src} type="audio/mp3" />
        <source src={src} type="audio/mpeg" />
        <source src={src} type="audio/aac" />
        <source src={src} type="audio/ogg" />
        <source src={src} type="audio/wav" />
        <source src={src} type="audio/x-m4a" />
      </audio>
    </>
  );
};

export default AudioButton;
