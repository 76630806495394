import styled, { css } from 'styled-components';
import Switch from 'react-switch';

import theme from '../../../global/style/theme';

const Label = styled.label`
  width: fit-content;
  font-size: 1.4rem;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  line-height: 1.4;
  color: ${({ theme }) => theme.dark500};
  display: flex;
  align-items: center;

  ${({ disabled }) =>
    !disabled &&
    css`
      cursor: pointer;
    `}

  & > p {
    padding: 0 1.2rem;
  }
`;

const SwitchField = ({
  height = 16,
  width = 30,
  handleDiameter = 10,
  onChange,
  checked,
  checkedLabel,
  unCheckedLabel,
  isDisabled = false,
  className,
  name,
  label,
}) => (
  <Label htmlFor={name} disabled={isDisabled} className={className}>
    {unCheckedLabel ? <p>{unCheckedLabel}</p> : null}
    <Switch
      id={name}
      checked={checked}
      disabled={isDisabled}
      handleDiameter={handleDiameter}
      offHandleColor={theme.white}
      height={height}
      name={name}
      offColor={theme.stroke}
      onColor={theme.plantGreen}
      onChange={onChange}
      uncheckedIcon={false}
      checkedIcon={false}
      width={width}
    />
    {label || checkedLabel ? <p>{label || checkedLabel}</p> : null}
  </Label>
);

export default SwitchField;
