import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useLocation } from 'react-router-dom';
import { LinkButton, Page, formatNumber } from '..';
import { Invite } from '../../modules/purchase/purchase/invite';
import { Checkmark, Description, Icon, Paper, Title } from './styles';

const PaymentInfo = styled.div`
  background-color: #fbfbfb;
  border: 1px solid #dfe0e0;
  border-radius: 8px;
  padding: 16px;

  margin: 2rem 0;
  font-size: 1.6rem;

  display: flex;
  align-items: flex-start;
  flex-direction: column;

  gap: 1rem;

  div {
    text-align: start;
  }
`;

const PaymentInfoSectionTitle = styled.p`
  color: #5e656e;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
`;
const PaymentInfoSectionValue = styled.p`
  color: #868b92;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`;

const StyledPaper = styled(Paper)`
  max-width: 440px;
`;

const CenterContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  max-width: 644px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;

  @media (min-width: 768px) {
    padding: 5rem;
  }
`;

const SuccessContent = styled.div`
  width: 100%;
  height: fit-content;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.white};
  padding: 6.4rem 1.6rem;
  border: 1px solid ${({ theme }) => theme.stroke};

  display: flex;
  align-items: center;
  flex-direction: column;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    padding: 6.4rem 4rem;
  }

  & > div {
    max-width: 440px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const StyledLinkButton = styled(LinkButton)`
  border-bottom: 2px solid #75acff;
  display: block;
`;

const WireTransferPage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  if (!location.state)
    throw new Error(
      'No location state, the user must have navigated directly to this URL'
    );

  const goToSubscriptions = useCallback(() => {
    navigate('/subscriptions');
  }, [navigate]);

  return (
    <Page title={t('purchase:payment.wireTransfer.title')}>
      <CenterContent>
        <Content>
          <SuccessContent>
            <div>
              <StyledPaper>
                <Icon isSuccess>
                  <Checkmark />
                </Icon>
                <Title>{t('purchase:payment.wireTransfer.title')}</Title>
                <div>
                  <Description>
                    {t('purchase:payment.wireTransfer.description')}
                  </Description>
                  <Description>
                    {t('purchase:payment.wireTransfer.subParagraph')}
                  </Description>
                </div>
                <PaymentInfo>
                  <div>
                    <PaymentInfoSectionTitle>
                      {t('purchase:payment.wireTransfer.amount')}{' '}
                    </PaymentInfoSectionTitle>
                    <PaymentInfoSectionValue>
                      €{' '}
                      {formatNumber(location.state.amount / 100, i18n.language)}
                    </PaymentInfoSectionValue>
                  </div>
                  <div>
                    <PaymentInfoSectionTitle>
                      {t('purchase:payment.wireTransfer.receiver')}:{' '}
                    </PaymentInfoSectionTitle>
                    <PaymentInfoSectionValue>
                      Tunify NV - {location.state.iban}
                    </PaymentInfoSectionValue>
                  </div>
                  <div>
                    <PaymentInfoSectionTitle>
                      {t('purchase:payment.wireTransfer.reference')}:{' '}
                    </PaymentInfoSectionTitle>
                    <PaymentInfoSectionValue>
                      {location?.state?.wireTransferReference}
                    </PaymentInfoSectionValue>
                  </div>
                </PaymentInfo>
                <ButtonWrapper>
                  <StyledLinkButton
                    onClick={goToSubscriptions}
                    highlightGradient="blooming"
                    title={t('common:button.title.goTo', {
                      value: t('common:button.title.subscriptions'),
                    })}
                  >
                    {t('common:button.subscriptions')}
                  </StyledLinkButton>
                </ButtonWrapper>
              </StyledPaper>
            </div>
          </SuccessContent>
          <Invite />
        </Content>
      </CenterContent>
    </Page>
  );
};

export default WireTransferPage;
