import { useLayoutEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import { usePurchase } from '../purchaseContext';
import { SelectCard, formatNumber } from '../../../../common';
import {
  determineSelectedProduct,
  calculateSavingsPerYearForSubscriptionTemplate,
} from '../utils';
import { ReactComponent as ActionIcon } from '../../../../assets/icons/actionWithGradient.svg';

const SubLabel = styled.div`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.dark300};
  line-height: 1.4;

  & > span {
    font-weight: 600;
    color: ${({ theme }) => theme.dark500};
  }
`;

const Label = styled.h2`
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Options = styled.div`
  display: grid;
  gap: 1.2rem;
`;

const ActionInfo = styled.span`
  font-size: 1.2rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.dark300};
`;

const SubscriptionTemplateSavings = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  gap: 1.2rem;
  padding-top: 1.6rem;
`;

const SubscriptionTemplateStep = ({ goToNextStep, goToPreviousStep }) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { currentStep } = useParams();

  const {
    availableSubscriptionTemplateGroups,
    handleSelectSubscriptionTemplateGroup,
    userInput: {
      selectedMusicLicense,
      selectedColor,
      selectedSubscriptionTemplateGroup,
    },
    productCatalogue,
  } = usePurchase();

  useLayoutEffect(() => {
    if (!selectedSubscriptionTemplateGroup && availableSubscriptionTemplateGroups.length > 0) {
      const initialSelectedSubscriptionTemplateGroup =
        availableSubscriptionTemplateGroups.reduce((acc, curr) =>
          acc[0].selectionOrder < curr[0].selectionOrder ? acc : curr
        );
      handleSelectSubscriptionTemplateGroup(initialSelectedSubscriptionTemplateGroup);
    }
    if (availableSubscriptionTemplateGroups.length === 1) {
      const previousStep = location.state.from;
      if (currentStep > previousStep) {
        goToNextStep();
      } else {
        goToPreviousStep();
      }
    }
  }, [
    availableSubscriptionTemplateGroups,
    handleSelectSubscriptionTemplateGroup,
    selectedSubscriptionTemplateGroup,
    goToPreviousStep,
    goToNextStep,
    currentStep,
    location,
  ]);

  const handleSelect = useCallback(
    (id) => {
      if (!selectedSubscriptionTemplateGroup?.some((template) => template.id === id)) {
        const subscriptionTemplateGroup = availableSubscriptionTemplateGroups.find(
          (group) => group.some(template => template.id === id)
        );
        handleSelectSubscriptionTemplateGroup(subscriptionTemplateGroup);
      }
    },
    [
      selectedSubscriptionTemplateGroup,
      availableSubscriptionTemplateGroups,
      handleSelectSubscriptionTemplateGroup,
    ]
  );

  const selectOptions = useMemo(
    () =>
      availableSubscriptionTemplateGroups
        .map(group => group[0])
        .sort((a, b) => a.showOrder - b.showOrder)
        .map((subscriptionTemplate) => {
          const productPrice = determineSelectedProduct(
            productCatalogue,
            [selectedMusicLicense.id],
            [selectedColor.id],
            [subscriptionTemplate.id],
            'either'
          ).price;

          const savingsPerYear = calculateSavingsPerYearForSubscriptionTemplate(
            subscriptionTemplate.id,
            productCatalogue,
            selectedMusicLicense.id,
            selectedColor.id
          );

          return (
            <SelectCard
              key={subscriptionTemplate.id}
              activeSelectCardId={selectedSubscriptionTemplateGroup?.map(template => template.id)}
              id={subscriptionTemplate.id}
              onClick={handleSelect}
              title={
                subscriptionTemplate.recurringRuleType === 'monthly' ? (
                  <Label>
                    {t('glossary:monthWithCount', {
                      count: subscriptionTemplate.recurringInterval,
                    })}
                  </Label>
                ) : (
                  <Label>
                    {t('glossary:weekWithCount', {
                      count: subscriptionTemplate.recurringInterval,
                    })}
                  </Label>
                )
              }
            >
              <SubLabel>
                <span>€ {formatNumber(productPrice, i18n.language)}</span>{' '}
                {t('glossary:exclusiveVat')}
              </SubLabel>
              {Math.round(savingsPerYear * 100) / 100 !== 0 ? (
                <SubscriptionTemplateSavings>
                  <ActionIcon width="2rem" height="2rem" />
                  <ActionInfo>
                    {t('subscriptions:subscriptionTemplateSavings', {
                      savings: formatNumber(savingsPerYear, i18n.language),
                    })}
                  </ActionInfo>
                </SubscriptionTemplateSavings>
              ) : null}
            </SelectCard>
          );
        }),
    [
      availableSubscriptionTemplateGroups,
      handleSelect,
      i18n.language,
      selectedSubscriptionTemplateGroup,
      t,
      productCatalogue,
      selectedMusicLicense,
      selectedColor,
    ]
  );

  return <Options>{selectOptions}</Options>;
};

export default SubscriptionTemplateStep;
