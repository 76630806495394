import config from '../../config';

const { IPSTACK_API_KEY, IPSTACK_BASE_URL } = config;

export const fetchCountryInfoFromIpService = async () => {
  const response = await window.fetch(
    `${IPSTACK_BASE_URL}/check?access_key=${IPSTACK_API_KEY}&fields=country_code,ip`
  );

  if (!response.ok) {
    throw new Error('Request country code from ip service error');
  }

  const data = await response.json();

  if (!data.country_code) {
    throw new Error('Request country code from ip service error');
  }

  return data;
};

/**
 * Predict the country of the user viewing MyTunify.
 * 
 * @param {string} appliedLanguageCode The language applied in the app.
 *
 * @returns A promise resolving into the country code part of the language applied in the app if it is a non-US (European) country.
 *          Otherwise, consults the IP Stack IP-to-country service to predict the country and resolves into that country code.
 *          If the IP-to-country service fails, resolves to `null`.
 */
export const predictViewerCountryCode = async (appliedLanguageCode) => {
  const [, langCountryCode] = appliedLanguageCode.split('-');

  if (langCountryCode.toLowerCase() !== 'us') {
    return langCountryCode; // We predict the viewer country code based on the selected language
  }

  try {
    const { country_code } = await fetchCountryInfoFromIpService(); // We try to predict the view country code based on IP

    return country_code;
  } catch {
    return null; // We cannot predict the viewer country code
  }
};
