const flavors = {
  flat: `
    text-decoration: none;
  `,
  outline: `
    text-decoration: none;
  `,
  link: `
    text-decoration: none;
    position: relative;

    &::before {
      content: '';
      height: 2px;
      position: absolute;
      bottom: .8rem;
      left: 1rem;
      right: 1rem;
    }
  `,
  icon: `
    text-decoration: none;
    border-radius: 50%;
    padding: 0.5rem;
    min-width: 2.5rem;
    min-height: 2.5rem;

    &:focus {
      outline: none;
    }
  `,
};

export default flavors;
