import { useCallback, useState, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { format } from 'date-fns';

import { usePlaylists } from '../../playlistsProvider';
import { Tabs, PlaylistTypes } from '../../types';
import theme from '../../../../../global/style/theme';

export const Container = styled.div`
  width: 100%;
  height: 4.8rem;
  font-size: 1.4rem;
  line-height: 1.4;
  padding: 0 2rem 0 1rem;
  user-select: none;
  display: grid;
  grid-template-columns: 1fr min-content;
  gap: 1rem;
  align-items: center;
  text-align: left;
  background-color: ${({ theme }) => theme.uiBackground};
  border-radius: 0 2.4rem 2.4rem 0;

  & p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${({ canSearch }) =>
    canSearch &&
    css`
      cursor: pointer;
      transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

      & > div {
        font-weight: 700;
        color: ${({ theme }) => theme.white};
      }

      & svg path {
        fill: ${({ theme }) => theme.waterBlue};
        transition: fill 0.2s ease-in-out;
      }
    `}

  ${({ isSearchVisible }) =>
    isSearchVisible &&
    css`
      color: ${({ theme }) => theme.white};
      background-color: ${({ theme }) => theme.waterBlue};
      & svg path {
        fill: ${({ theme }) => theme.white};
      }
    `}
`;

const StyledSvg = styled(motion.svg)`
  width: 1.8rem;
  height: 1.8rem;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  & > div {
    font-weight: 700;
    color: ${({ theme }) => theme.white};
    padding-left: 1rem;
  }
`;

const Bold = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.dark500};
  padding-left: 0.6rem;
`;

const initial = { x: '140%' };
const animateX = { x: '0%' };
const exit = { x: '140%' };
const transition = { ease: 'easeInOut', duration: 0.2 };

const SearchButton = ({ index, title, type, songId, setTab, onHover }) => {
  const { t } = useTranslation();

  const { dispatch } = usePlaylists();

  const [isSearchVisible, setSearch] = useState(false);

  const handleSearch = useCallback(() => {
    dispatch({
      selectedPlaylistType: PlaylistTypes.SEARCH,
      selectedPlaylist: {
        title: (
          <Trans
            components={{ bold: <Bold /> }}
            i18nKey="musicManagement:playlists.searchResultsFor"
            defaults="Resultaten voor: <bold>{{result}}</bold>"
            values={{ result: title }}
          />
        ),
      },
      searchSongsIsSuggestion: !(
        type === 'searchOnTitle' || type === 'searchOnGroup'
      ),
      searchSongsType: type,
      searchSongsSongId: songId,
    });
    if (setTab) {
      setTab(Tabs.SEARCH);
    }
  }, [dispatch, songId, type, title, setTab]);

  const animate = useMemo(
    () => (isSearchVisible ? animateX : exit),
    [isSearchVisible]
  );

  const label = useMemo(
    () =>
      type === 'duration' && typeof title === 'number'
        ? format(new Date(title), 'm:ss')
        : title,
    [type, title]
  );

  const canSearch = type !== 'duration' && label;

  const handleMouseEnter = useCallback(() => {
    if (canSearch) {
      setSearch(true);
      onHover(index, true);
    }
  }, [onHover, index, canSearch]);

  const handleMouseLeave = useCallback(() => {
    if (canSearch) {
      setSearch(false);
      onHover(index, false);
    }
  }, [onHover, index, canSearch]);

  return (
    <Container
      canSearch={canSearch}
      onClick={canSearch ? handleSearch : undefined}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      isSearchVisible={isSearchVisible}
    >
      <p>{label ?? '-'}</p>
      {canSearch ? (
        <Wrapper>
          <StyledSvg
            layout
            transition={transition}
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.2,6.5996 C1.2,3.6216 3.622,1.1996 6.6,1.1996 C9.577,1.1996 12,3.6216 12,6.5996 C12,9.5776 9.577,11.9996 6.6,11.9996 C3.622,11.9996 1.2,9.5776 1.2,6.5996 M15.824,14.9766 L11.668,10.8206 C12.624,9.6756 13.2,8.2036 13.2,6.5996 C13.2,2.9596 10.239,-0.0004 6.6,-0.0004 C2.961,-0.0004 5.32907052e-15,2.9596 5.32907052e-15,6.5996 C5.32907052e-15,10.2396 2.961,13.1996 6.6,13.1996 C8.204,13.1996 9.675,12.6226 10.82,11.6686 L14.975,15.8246 C15.093,15.9416 15.246,15.9996 15.4,15.9996 C15.553,15.9996 15.707,15.9416 15.824,15.8246 C16.058,15.5906 16.058,15.2106 15.824,14.9766"
              fill={theme.white}
              fillRule="evenodd"
            />
          </StyledSvg>
          {isSearchVisible ? (
            <motion.div
              animate={animate}
              initial={initial}
              transition={transition}
            >
              {t('common:button.search')}
            </motion.div>
          ) : null}
        </Wrapper>
      ) : null}
    </Container>
  );
};

export default SearchButton;
