import styled from 'styled-components';

const Container = styled.div`
  display: none;
  min-height: 4rem;
  padding: 0 1.1rem;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    display: grid;
    ${({ templateColumns }) => templateColumns && `grid-template-columns: ${templateColumns};`}
    column-gap: 1.5rem;
    align-items: center;
  }
`;

const TableHeader = ({ className, children, templateColumns }) => {
  return (
    <Container className={className} templateColumns={templateColumns}>
      {children}
    </Container>
  );
};

export default TableHeader;
