import styled from 'styled-components';

import { Collection } from '../collection';

const GroupContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, 1fr);
`;

const GroupCollection = ({ musicCollectionGroup, selectedChannelType }) => {
  return (
    <GroupContainer>
      {musicCollectionGroup?.map((musicCollection) => (
        <Collection
          key={musicCollection.id}
          musicCollection={musicCollection}
          selectedChannelType={selectedChannelType}
        />
      ))}
    </GroupContainer>
  );
};

export default GroupCollection;
