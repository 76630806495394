import { Fragment, useState, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useAddonsBuy } from '../addonsBuyContext';
import PurchaseRow from './purchaseRow';
import PriceSummary from './priceSummary';
import { ReactComponent as AngleDown } from '../../../../assets/icons/angleDown.svg';
import { ReactComponent as AngleUp } from '../../../../assets/icons/angleUp.svg';
import theme from '../../../../global/style/theme';

const Container = styled.div`
  display: grid;
  gap: 1rem;
  background-color: ${({ theme }) => theme.dark500};
  color: ${({ theme }) => theme.white};
  border-radius: 4px;
  padding: 1.4rem 2rem;
  margin-bottom: 1rem;
`;

const ZonesContainer = styled.div`
  display: grid;
  gap: 1rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem 0;
  cursor: pointer;
`;

const Title = styled.h4`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.white};

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    font-size: 1.8rem;
  }
`;

const HorizontalRule = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.dark400};
`;

const PurchaseOverview = () => {
  const { t } = useTranslation();
  const {
    currentZone,
    selectedAddons,
    selectedAdditionalZones,
    availableAddons,
  } = useAddonsBuy();

  const [isContentVisible, setContentVisible] = useState(false);

  const toggleContent = useCallback(() => {
    setContentVisible((prev) => !prev);
  }, []);

  const zones = [currentZone].concat(selectedAdditionalZones);

  return (
    <Container>
      <Header onClick={toggleContent}>
        <Title>{t('subscriptions:basket', { amount: zones.length })}</Title>
        {isContentVisible ? (
          <AngleUp width="1.4rem" height="1.4rem" color={theme.white} />
        ) : (
          <AngleDown width="1.4rem" height="1.4rem" color={theme.white} />
        )}
      </Header>
      {isContentVisible ? (
        <ZonesContainer>
          {zones.map((zone, index) => (
            <Fragment key={zone.id}>
              <HorizontalRule />
              <PurchaseRow
                zone={zone}
                selectedAddons={selectedAddons}
                availableAddons={availableAddons}
              />
            </Fragment>
          ))}
        </ZonesContainer>
      ) : null}
      <PriceSummary />
    </Container>
  );
};

export default PurchaseOverview;
