import { useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Modal } from '../../..';
import { ReactComponent as MoodImg } from '../../../../assets/images/sfeerkanaal.svg';
import { ReactComponent as AgendaImg } from '../../../../assets/images/musicAgenda.svg';
import { ReactComponent as PlaylistImg } from '../../../../assets/images/afspeellijst.svg';
import { Mood } from './mood';
import { Agenda } from './agenda';

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  padding-bottom: 1rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Label = styled.span`
  margin-top: 1rem;
  font-weight: bold;
`;

const Card = styled.button`
  border: solid 1px ${({ theme }) => theme.stroke};
  border-radius: 5px;
  background: inherit;
  color: inherit;
  margin: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  transition: border 0.3s ease-in-out;
  &:hover {
    border: solid 1px ${({ theme }) => theme.dark300};
  }
  &:disabled {
    cursor: default;
  }
`;

const ModalDescription = styled.p`
  padding: 1rem 0;
`;

const flowTypes = {
  MOOD: 'MOOD',
  AGENDA: 'AGENDA',
  PLAYLIST: 'PLAYLIST',
};

const flowComponents = {
  MOOD: Mood,
  AGENDA: Agenda,
  PLAYLIST: null,
};

const MusicListSelectionModal = ({
  isOpen,
  onClose,
  onSubmit,
  isSubmitting,
  title,
  tunifyChannels,
  tunifyCalendars,
  flows = [],
}) => {
  const { t } = useTranslation();

  const [flow, setFlow] = useState('');

  const chooseMood = useCallback(() => {
    setFlow(flowTypes.MOOD);
  }, [setFlow]);

  const chooseAgenda = useCallback(() => {
    setFlow(flowTypes.AGENDA);
  }, [setFlow]);

  const choosePlaylist = useCallback(() => {
    setFlow(flowTypes.PLAYLIST);
  }, [setFlow]);

  const CurrentFlowComponent = useMemo(() => flowComponents[flow], [flow]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} footer={false}>
      {!flow ? (
        <>
          <ModalDescription>
            {t('musicManagement:tunifyBlue.addCollection.info')}
          </ModalDescription>
          <CardWrapper>
            {flows.includes(flowTypes.AGENDA) ? (
              <Card onClick={chooseAgenda}>
                <AgendaImg height="122" width="106" />
                <Label>
                  {t('musicManagement:tunifyGreen.addCollection.agenda')}
                </Label>
              </Card>
            ) : null}
            {flows.includes(flowTypes.MOOD) ? (
              <Card onClick={chooseMood}>
                <MoodImg height="122" width="106" />
                <Label>
                  {t('musicManagement:tunifyBlue.addCollection.mood')}
                </Label>
              </Card>
            ) : null}
            {flows.includes(flowTypes.PLAYLIST) ? (
              <Card onClick={choosePlaylist}>
                <PlaylistImg height="122" width="106" />
                <Label>
                  {t('musicManagement:tunifyBlue.addCollection.playlist')}
                </Label>
              </Card>
            ) : null}
          </CardWrapper>
        </>
      ) : null}
      {CurrentFlowComponent ? (
        <CurrentFlowComponent
          flow={flow}
          isSubmitting={isSubmitting}
          onClose={onClose}
          onSubmit={onSubmit}
          setFlow={setFlow}
          tunifyCalendars={tunifyCalendars}
          tunifyChannels={tunifyChannels}
        />
      ) : null}
    </Modal>
  );
};

export default MusicListSelectionModal;
