import ProjectStyle from './projectStyle';
import GoogleAutoCompleteStyle from './googleAutoCompleteStyle';

const GlobalStyles = () => (
  <>
    <ProjectStyle />
    <GoogleAutoCompleteStyle />
  </>
);

export { default as theme } from './theme';

export default GlobalStyles;
