import { css } from 'styled-components';

const buttonTypes = {
  primary: css`
    transition: opacity 0.2s ease-in-out;

    ${({ theme, $highlightColor, $highlightGradient }) => {
      if ($highlightColor) {
        return css`
          background-color: ${theme[$highlightColor]};
        `;
      }
      if ($highlightGradient) {
        return css`
          background: ${theme.gradient[$highlightGradient][0]};
          background: linear-gradient(
            135deg,
            ${theme.gradient[$highlightGradient][0]} 0%,
            ${theme.gradient[$highlightGradient][1]} 100%
          );

          &::before {
            position: absolute;
            content: '';
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 5rem;
            background-color: ${theme.gradient[$highlightGradient][0]};
            z-index: -2;
            transition: opacity 0.2s ease-in-out;
            opacity: 0;
          }

          &::after {
            position: absolute;
            content: '';
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 5rem;
            background-color: ${theme.gradient[$highlightGradient][1]};
            z-index: -1;
            transition: opacity 0.2s ease-in-out;
            opacity: 0;
          }

          &:hover::before {
            opacity: 1;
          }

          &:active::after {
            opacity: 1;
          }
        `;
      }
    }}
    border-radius: 5rem;
    color: ${({ theme }) => theme.white};
    text-align: center;

    ${({ disabled }) =>
      disabled &&
      css`
        background: ${({ theme }) => theme.dark100};
        cursor: default;

        &:hover::before {
          opacity: 0;
        }

        &:active::after {
          opacity: 0;
        }
      `}
  `,
  secondary: css`
    ${({ theme, $highlightColor }) => css`
      border-color: ${theme[$highlightColor] ?? theme.waterBlue};
    `}
    color: ${({ theme }) => theme.dark400};

    &:hover {
      color: ${({ theme }) => theme.dark600};
    }

    ${({ disabled }) =>
      disabled &&
      css`
        border-color: ${({ theme }) => theme.stroke};
        color: ${({ theme }) => theme.dark300};
        cursor: default;

        &:hover {
          color: ${({ theme }) => theme.dark300};
        }
      `}
  `,
  outline: css`
    ${({ theme, $highlightColor }) => css`
      border: 2px solid ${theme[$highlightColor] ?? theme.dark300};
    `}
    border-radius: 5rem;
    color: ${({ theme }) => theme.dark400};
    text-align: center;

    &:hover {
      border: 2px solid ${({ theme }) => theme.dark500};
      color: ${({ theme }) => theme.dark500};
    }

    ${({ disabled }) =>
      disabled &&
      css`
        color: ${({ theme }) => theme.dark200};
        border: 2px solid ${({ theme }) => theme.dark400};
        cursor: default;
      `}
  `,
  icon: css`
    ${({ theme, $highlightColor, $highlightGradient }) => {
      if ($highlightColor) {
        return css`
          background-color: ${theme[$highlightColor]};
        `;
      }
      if ($highlightGradient) {
        return css`
          background: ${theme.gradient[$highlightGradient][0]};
          background: linear-gradient(
            135deg,
            ${theme.gradient[$highlightGradient][0]} 0%,
            ${theme.gradient[$highlightGradient][1]} 100%
          );

          &::before {
            position: absolute;
            content: '';
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 5rem;
            background-color: ${theme.gradient[$highlightGradient][0]};
            z-index: -2;
            transition: opacity 0.2s ease-in-out;
            opacity: 0;
          }

          &::after {
            position: absolute;
            content: '';
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 5rem;
            background-color: ${theme.gradient[$highlightGradient][1]};
            z-index: -1;
            transition: opacity 0.2s ease-in-out;
            opacity: 0;
          }

          &:hover::before {
            opacity: 1;
          }

          &:active::after {
            opacity: 1;
          }
        `;
      }
    }}
    border-radius: 100%;
    color: ${({ theme }) => theme.white};
    text-align: center;

    ${({ disabled }) =>
      disabled &&
      css`
        cursor: not-allowed;

        &:hover::before {
          opacity: 0;
        }

        &:active::after {
          opacity: 0;
        }
      `}
  `,
};

export default buttonTypes;
