import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Page } from '../../../common';
import HistoryPage from './historyPage';
import CustomerSuccessHistoryProvider from './historyProvider';
import PageHeader from './pageHeader';

const StyledPage = styled(Page)`
  display: grid;
  grid-template-rows: min-content 1fr;
  height: 100%;
`;

const History = () => {
  const { t } = useTranslation();

  return (
    <CustomerSuccessHistoryProvider>
      <StyledPage title={t('customerSuccess:history.title')}>
        <PageHeader />
        <HistoryPage />
      </StyledPage>
    </CustomerSuccessHistoryProvider>
  );
};

export default History;
