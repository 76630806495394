import styled from 'styled-components';

import { Card } from '../../../shared';

const StatsCard = styled(Card)`
  grid-gap: 1rem;
  align-items: center;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
`;

const Label = styled.p`
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.33;
  color: ${({ theme }) => theme.white};
  text-align: center;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    font-size: 1.6rem;
    line-height: 1.5;
  }
`;

const Value = styled.p`
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  line-height: 1;
  font-size: 4rem;
  text-align: center;
  color: ${({ theme }) => theme.white};
  margin: 0 0 1rem 0;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    margin: 0;
    text-align: right;
  }
`;

const StatsItem = ({ value, label, color }) => (
  <StatsCard color={color}>
    <Label>{label}</Label>
    <Value color={color}>{value ?? '-'}</Value>
  </StatsCard>
);

export default StatsItem;
