import { useQuery } from '@apollo/react-hooks';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSessionStorage } from '../../../common';
import { useAuth } from '../../../global/auth/newAuthProvider';
import {
  GET_ALL_SUBSCRIPTIONS_BY_ID,
  GET_CONTACT_CALLS,
  GET_CONTACT_CATEGORIES,
} from './api';
import CloseContactModal from './closeContactModal';
import EditContactModal from './editContactModal';
import { CustomerSuccessHistoryContext } from './historyContext';

export const LIMIT = 10;

const historyCategories = [
  {
    id: 'contact',
    i18nKey: 'customerSuccess:history.contact',
    isDisabled: false,
  },
  {
    id: 'subscription',
    i18nKey: 'customerSuccess:history.subscriptions',
    isDisabled: true,
  },
];

const CustomerSuccessHistoryProvider = ({ children }) => {
  const { t } = useTranslation();
  const [hasMore, setHasMore] = useState(true);
  const [refetchError, setRefetchError] = useState(null);
  const [impersonationId] = useSessionStorage('impersonationId');
  const [useFilter, setUseFilter] = useState(!!impersonationId);
  const [editContactModalIsOpen, setEditContactModalIsOpen] = useState(false);
  const [closeContactModalIsOpen, setCloseContactModalIsOpen] = useState(false);
  const [contactCall, setContactCall] = useState(null);
  const [selectedHistoryCategory, setSelectedHistoryCategory] = useState(
    historyCategories[0].id
  );
  const { user } = useAuth();

  const {
    data: contactCategoriesData,
    loading: contactCategoriesLoading,
    error: contactCategoriesError,
  } = useQuery(GET_CONTACT_CATEGORIES, { fetchPolicy: 'cache-first' });

  const {
    data: contactCallsData,
    loading: contactCallsLoading,
    error: contactCallsError,
    fetchMore,
  } = useQuery(GET_CONTACT_CALLS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      offset: 0,
      limit: LIMIT,
      filterUser: useFilter,
      userId: user.id,
    },
  });

  const {
    data: subscriptions,
    error: subscriptionsError,
    loading: subscriptionsLoading,
  } = useQuery(GET_ALL_SUBSCRIPTIONS_BY_ID, {
    skip: selectedHistoryCategory !== historyCategories[1]?.id,
  });

  const handleLoadMore = useCallback(async () => {
    if (contactCallsError || refetchError) return; // Don't refetch when an error occured
    try {
      await fetchMore({
        variables: {
          offset: useFilter
            ? contactCallsData.contactCallsOfUser.length
            : contactCallsData.closedContactCalls.length,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          setHasMore(
            useFilter
              ? fetchMoreResult.contactCallsOfUser.length === LIMIT
              : fetchMoreResult.closedContactCalls.length === LIMIT
          );
          if (useFilter) {
            return {
              ...prev,
              contactCallsOfUser: [
                ...prev.contactCallsOfUser,
                ...fetchMoreResult.contactCallsOfUser,
              ],
            };
          }
          return {
            ...prev,
            closedContactCalls: [
              ...prev.closedContactCalls,
              ...fetchMoreResult.closedContactCalls,
            ],
          };
        },
      });
    } catch (e) {
      // Although fetchMore is the way to go for pagination, it provides no easy to use mechanism for error handling
      // you have to catch the error yourself and set an error state
      setRefetchError(e);
    }
  }, [fetchMore, contactCallsError, contactCallsData, refetchError, useFilter]);

  const deleteFilter = useCallback(() => {
    setUseFilter(false);
    setHasMore(true);
  }, []);

  const toggleEditContactModal = useCallback(
    () => setEditContactModalIsOpen((prev) => !prev),
    []
  );

  const toggleFutureEditContactModal = useCallback(
    () => setCloseContactModalIsOpen((prev) => !prev),
    []
  );

  const handleOpenEditContactModal = useCallback(
    (scheduledCallValues) => {
      setContactCall(scheduledCallValues);
      toggleEditContactModal();
    },
    [toggleEditContactModal]
  );

  const handleOpenCloseContactModal = useCallback(
    (scheduledCallValues) => {
      setContactCall(scheduledCallValues);
      toggleFutureEditContactModal();
    },
    [toggleFutureEditContactModal]
  );

  const contextValue = useMemo(
    () => ({
      history:
        (useFilter
          ? contactCallsData?.contactCallsOfUser
          : contactCallsData?.closedContactCalls) || [],
      contactCategories: contactCategoriesData?.contactCategories || [],
      loadMore: handleLoadMore,
      hasMore,
      setHasMore,
      loading: contactCallsLoading || contactCategoriesLoading,
      error: contactCallsError || contactCategoriesError || refetchError,
      deleteFilter,
      useFilter,
      editContactModalIsOpen,
      openEditContactModal: handleOpenEditContactModal,
      closeContactModalIsOpen,
      openCloseContactModal: handleOpenCloseContactModal,
      historyCategories,
      selectedHistoryCategory,
      setSelectedHistoryCategory,
      subscriptions: subscriptions?.getAllSubscriptionsByUserId ?? [],
      subscriptionsError,
      subscriptionsLoading,
    }),
    [
      useFilter,
      contactCallsData?.contactCallsOfUser,
      contactCallsData?.closedContactCalls,
      contactCategoriesData?.contactCategories,
      handleLoadMore,
      hasMore,
      contactCallsLoading,
      contactCategoriesLoading,
      contactCallsError,
      contactCategoriesError,
      refetchError,
      deleteFilter,
      editContactModalIsOpen,
      handleOpenEditContactModal,
      closeContactModalIsOpen,
      handleOpenCloseContactModal,
      selectedHistoryCategory,
      subscriptions,
      subscriptionsError,
      subscriptionsLoading,
    ]
  );

  return (
    <CustomerSuccessHistoryContext.Provider value={contextValue}>
      {children}
      {contactCall ? (
        <>
          {editContactModalIsOpen ? (
            <EditContactModal
              historyEntry={contactCall}
              isOpen={editContactModalIsOpen}
              title={t('customerSuccess:contact.edit')}
              toggle={toggleEditContactModal}
            />
          ) : null}
          {closeContactModalIsOpen ? (
            <CloseContactModal
              historyEntry={contactCall}
              isOpen={closeContactModalIsOpen}
              title={t('customerSuccess:contact.close')}
              toggle={toggleFutureEditContactModal}
            />
          ) : null}
        </>
      ) : null}
    </CustomerSuccessHistoryContext.Provider>
  );
};

export default CustomerSuccessHistoryProvider;
