import { createContext, useContext, useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/react-hooks';

import { determineAvailableMusicLicenses } from '../../../../common';
import {
  GET_MUSIC_REGIONS,
  GET_MUSIC_PROFILE_SETTINGS,
  GET_MUSIC_PROFILE_TEMPLATES,
  GET_ALL_PRODUCTS,
  GET_TUNIFY_CHANNEL_GROUPS,
  GET_TUNIFY_CALENDAR_GROUPS,
  UPDATE_DEFAULT_MUSIC_SELECTION,
} from './api';

const generalReducer = (state, payload) => {
  return {
    ...state,
    ...payload,
  };
};

const initialGeneralState = {};

const GeneralContext = createContext();

export const GeneralManager = ({ children, profile }) => {
  const { i18n } = useTranslation();

  const [state, dispatch] = useReducer(generalReducer, initialGeneralState);

  const { loading: loadingRegions, data: regionsData, error: errorRegions } = useQuery(GET_MUSIC_REGIONS);

  const { loading: loadingProducts, data: dataProducts, error: errorProducts } = useQuery(GET_ALL_PRODUCTS);

  const {
    data: dataTunifyChannelGroups,
    loading: loadingTunifyChannelGroups,
    error: errorTunifyChannelGroups,
  } = useQuery(GET_TUNIFY_CHANNEL_GROUPS, {
    variables: {
      musicProfileId: profile?.tunifyMusicProfileUserId,
      language: i18n.language,
    },
    skip: !profile?.tunifyMusicProfileUserId,
  });

  const {
    data: dataTunifyCalendarGroups,
    loading: loadingTunifyCalendarGroups,
    error: errorTunifyCalendarGroups,
  } = useQuery(GET_TUNIFY_CALENDAR_GROUPS, {
    variables: {
      musicProfileId: profile?.tunifyMusicProfileUserId,
      language: i18n.language,
    },
    skip: !profile?.tunifyMusicProfileUserId,
  });

  const [
    updateDefaultMusicSelection,
    { loading: loadingUpdateDefaultMusicSelection, error: errorUpdateDefaultMusicSelection },
  ] = useMutation(UPDATE_DEFAULT_MUSIC_SELECTION);

  const musicLicenses = useMemo(
    () =>
      dataProducts?.allProducts?.length > 0 ? determineAvailableMusicLicenses(dataProducts.allProducts, 'either') : [],
    [dataProducts?.allProducts]
  );

  const {
    loading: loadingMusicProfileSettings,
    data: dataMusicProfileSettings,
    error: errorMusicProfileSettings,
  } = useQuery(GET_MUSIC_PROFILE_SETTINGS, {
    variables: { id: profile?.tunifyMusicProfileUserId },
    skip: !profile?.tunifyMusicProfileUserId,
  });

  const {
    loading: loadingMusicProfileTemplates,
    data: dataMusicProfileTemplates,
    error: errorMusicProfileTemplates,
  } = useQuery(GET_MUSIC_PROFILE_TEMPLATES, {
    variables: { id: profile?.id },
    skip: !profile?.id || !profile?.tunifyMusicProfileUserId,
  });

  const detailedSelectedStartList = useMemo(() => {
    const startList = dataMusicProfileSettings?.musicProfileSettings?.defaultMusicSelection;
    if (startList?.activeMode === 'Calendar') {
      const calendars = dataTunifyCalendarGroups?.tunifyCalendarGroups?.map((group) => group?.calendars)?.flat() ?? [];
      return calendars.find((calendar) => calendar?.calendarId === startList.id);
    }
    if (startList?.activeMode === 'MusicChannel') {
      const collections =
        dataTunifyChannelGroups?.tunifyChannelGroups?.flatMap((group) =>
          group?.musicChannels?.flatMap((channel) => channel?.musicCollections)
        ) ?? [];
      return collections.find((collection) => collection?.id === startList.id);
    }
    return undefined;
  }, [
    dataMusicProfileSettings?.musicProfileSettings,
    dataTunifyCalendarGroups?.tunifyCalendarGroups,
    dataTunifyChannelGroups?.tunifyChannelGroups,
  ]);

  const value = useMemo(
    () => ({
      ...state,
      dispatch,
      selectedProfile: profile,
      regions: regionsData?.musicRegions ?? [],
      musicProfileSettings: dataMusicProfileSettings?.musicProfileSettings,
      musicProfileTemplates: dataMusicProfileTemplates?.musicProfileTemplates,
      tunifyChannels: dataTunifyChannelGroups?.tunifyChannelGroups ?? [],
      tunifyCalendars: dataTunifyCalendarGroups?.tunifyCalendarGroups ?? [],
      loadingRegions,
      loadingMusicProfileSettings,
      loadingMusicProfileTemplates,
      errorMusicProfileSettings,
      errorMusicProfileTemplates,
      loadingProducts,
      musicLicenses,
      errorProducts,
      errorRegions,
      loadingTunifyChannelGroups,
      errorTunifyChannelGroups,
      loadingTunifyCalendarGroups,
      errorTunifyCalendarGroups,
      updateDefaultMusicSelection,
      loadingUpdateDefaultMusicSelection,
      errorUpdateDefaultMusicSelection,
      detailedSelectedStartList,
    }),
    [
      state,
      profile,
      loadingRegions,
      regionsData?.musicRegions,
      loadingMusicProfileSettings,
      dataMusicProfileSettings?.musicProfileSettings,
      loadingMusicProfileTemplates,
      dataMusicProfileTemplates?.musicProfileTemplates,
      errorMusicProfileSettings,
      errorMusicProfileTemplates,
      loadingProducts,
      musicLicenses,
      errorProducts,
      errorRegions,
      dataTunifyChannelGroups?.tunifyChannelGroups,
      loadingTunifyChannelGroups,
      errorTunifyChannelGroups,
      dataTunifyCalendarGroups?.tunifyCalendarGroups,
      loadingTunifyCalendarGroups,
      errorTunifyCalendarGroups,
      updateDefaultMusicSelection,
      loadingUpdateDefaultMusicSelection,
      errorUpdateDefaultMusicSelection,
      detailedSelectedStartList,
    ]
  );

  return <GeneralContext.Provider value={value}>{children}</GeneralContext.Provider>;
};

export const useGeneral = () => {
  return useContext(GeneralContext);
};
