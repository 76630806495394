import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import FilterResultRow from './filterTableRow';
import ResultRow from './tableRow';
import { LoadingIndicator, ErrorMessage } from '../../../../../common';
import { useCustomerSuccessHistory } from '../../historyContext';
import { LIMIT } from '../../historyProvider';

const Container = styled.div`
  display: grid;
  grid-gap: 0.8rem;
  padding: 0.8rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    padding: 1.6rem 2.4rem;
  }
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    padding: 1.6rem 4.6rem;
  }
`;

const TableMessage = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.dark300};
  line-height: 1.4;
  padding-top: 1rem;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin: 1rem;
`;

const ContactResults = () => {
  const { t } = useTranslation();
  const {
    loading,
    hasMore,
    error,
    loadMore,
    history,
    contactCategories,
    useFilter,
  } = useCustomerSuccessHistory();

  const [infiniteRef] = useInfiniteScroll({
    loading,
    // Due to refetch we cannot know if there is more after the initial query,
    // we have to additionally check for this with history.length >= LIMIT
    hasNextPage: history.length >= LIMIT && hasMore,
    onLoadMore: loadMore,
  });

  if (loading && history.length === 0) {
    return <StyledLoadingIndicator />;
  }

  if (history.length === 0 && error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <Container ref={infiniteRef}>
      {history.length === 0 && (
        <TableMessage>{t('customerSuccess:history.tbNoResults')}</TableMessage>
      )}
      {history.map((historyEntry) =>
        useFilter ? (
          <FilterResultRow
            key={historyEntry.id}
            contactCategories={contactCategories}
            historyEntry={historyEntry}
          />
        ) : (
          <ResultRow
            key={`${historyEntry.id}_${historyEntry.writeDate}`}
            contactCategories={contactCategories}
            historyEntry={historyEntry}
          />
        )
      )}
      {error && <ErrorMessage error={error} />}
      {loading && <StyledLoadingIndicator />}
    </Container>
  );
};

export default ContactResults;
