import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

const Card = styled.div`
  max-width: 72rem;
  justify-self: center;
  display: grid;
  grid-template-rows: min-content 1fr;
  border: 1px solid ${({ theme }) => theme.stroke};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.white};
  margin: 1rem;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.small}px) {
    width: 100%;
    margin: 0;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    grid-template-rows: min-content 1fr;
  }
`;

const CardHeader = styled.div`
  display: none;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    display: grid;
    padding: 3.2rem 4rem 2.4rem 4rem;
    border-radius: 8px 8px 0 0;
    background-color: ${({ theme }) => theme.uiBackground};
    border-bottom: 1px solid ${({ theme }) => theme.stroke};
  }
`;

const CardBody = styled.div`
  display: grid;
  padding: 2rem 1.6rem;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    padding: 3.2rem 4rem;
  }
`;

const SectionLabel = styled.h2`
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.5;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.small}px) {
    font-size: 1.8rem;
    ${({ description }) =>
      description &&
      css`
        padding-bottom: 0.8rem;
      `}
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    font-size: 2.4rem;
  }
`;

const SectionDescription = styled.p`
  font-size: 1.4rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.dark300};
`;

const StepLabel = styled.p`
  width: min-content;
  background-color: ${({ theme }) => theme.waterBlue};
  color: ${({ theme }) => theme.white};
  font-size: 1.2rem;
  line-height: 1.4;
  padding: 0.4rem 0.8rem;
  border-radius: 4px;
  white-space: nowrap;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.small}px) {
    margin-bottom: 1.2rem;
  }
`;

const StepCard = ({ currentStep, totalSteps, title, description, className, children }) => {
  const { t } = useTranslation();

  return (
    <Card className={className}>
      <CardHeader>
        <StepLabel>
          {t('subscriptions:stepLabel', {
            currentStep,
            totalSteps,
          })}
        </StepLabel>
        <SectionLabel description={description}>{title}</SectionLabel>
        <SectionDescription>{description}</SectionDescription>
      </CardHeader>
      <CardBody>{children}</CardBody>
    </Card>
  );
};

export default StepCard;
