/**
 * Returns a formatted object that is ready to be sent to the GraphQL backend
 *
 * Empty values are replaced with null, % wildcard is appended to the beginning
 * and end of non empty values
 *
 * @param {object} searchFormValues - Customer success search form values
 *
 * @returns {object} normalizedFormValues - Custom success normalized search values
 */
const normalizeSearchValues = (searchFormValues) => ({
  organization: {
    name: searchFormValues.organization ? `%${searchFormValues.organization.trim()}%` : null,
    vat: searchFormValues.vat ? `%${searchFormValues.vat.trim()}%` : null,
    countryId: searchFormValues.countryId || null,
  },
  location: {
    name: searchFormValues.location ? `%${searchFormValues.location.trim()}%` : null,
    city: searchFormValues.city ? `%${searchFormValues.city.trim()}%` : null,
  },
  zone: {
    name: searchFormValues.zone ? `%${searchFormValues.zone.trim()}%` : null,
  },
  industryId: searchFormValues.industryId || null,
  withActiveSubscriptionsOnly: searchFormValues.onlyActiveLocations,
  contact: {
    name: searchFormValues.contactPerson ? `%${searchFormValues.contactPerson.trim()}%` : null,
    phone: searchFormValues.phone ? `%${searchFormValues.phone.trim()}%` : null,
    email: searchFormValues.emailAddress ? `%${searchFormValues.emailAddress.trim()}%` : null,
  },
});

export { normalizeSearchValues };
