import { useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {
  StringField,
  Button,
  SwitchField,
  SelectField,
} from '../../../../../common';
import { useCustomerSuccessSearch } from '../../searchContext';

const Container = styled.article`
  display: grid;
  background-color: ${({ theme }) => theme.white};
  padding: 1.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.stroke};

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    padding: 2rem 3rem 2rem 3rem;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    padding: 2rem 4rem 2rem 4rem;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    padding: 2rem 4.8rem 2rem 4.8rem;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-rows: repeat(5, min-content);
`;

const BaseChildGrid = styled.div`
  display: grid;
  gap: 3rem 2rem;
`;

const Top = styled(BaseChildGrid)`
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
  }
`;

const Middle = styled(BaseChildGrid)`
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
  }
`;

const Bottom = styled(BaseChildGrid)`
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, min-content);
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, min-content);
  }
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.stroke};
  margin: ${({ $margin }) => ($margin ? $margin : '3rem 0')};
`;

const Actions = styled.div`
  display: flex;
  flex-flow: column-reverse;
  justify-content: flex-end;
  gap: 1rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    flex-direction: normal;
    flex-flow: row;
  }
`;

const SwitchFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SwitchFieldDescription = styled.p`
  color: ${({ theme }) => theme.dark300};
  padding: 1rem 0 0 4.2rem;
  font-size: 1.2rem;
  line-height: 1.2;
`;

const SearchForm = () => {
  const { t } = useTranslation();
  const {
    state: { countries, industries },
    searchCustomers,
    searchCustomersLoading,
  } = useCustomerSuccessSearch();

  const schema = Yup.object().shape({
    emailAddress: Yup.string(),
    contactPerson: Yup.string().typeError(
      t('common:form.validation.contactPerson.type')
    ),
    phone: Yup.string(),
    organization: Yup.string().typeError(
      t('common:form.validation.organizationName.type')
    ),
    location: Yup.string().typeError(
      t('common:form.validation.locationName.type')
    ),
    zone: Yup.string().typeError(t('common:form.validation.zoneName.type')),
    city: Yup.string().typeError(t('common:form.validation.city.type')),
    country: Yup.object().nullable(true),
    industry: Yup.object().nullable(true),
    vat: Yup.string(),
  });

  const {
    register,
    control,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      emailAddress: '',
      contactPerson: '',
      phone: '',
      organization: '',
      location: '',
      zone: '',
      vat: '',
      city: '',
      country: '',
      industry: '',
      onlyActiveLocations: false,
    },
  });

  const watchCountry = watch('country');
  const watchOnlyActiveLocations = watch('onlyActiveLocations');

  const selectedCountry = useMemo(
    () => countries.find(({ id }) => Number(id) === watchCountry?.value),
    [watchCountry, countries]
  );

  const countryOptions = useMemo(
    () =>
      countries.map((country) => ({
        value: Number(country.id),
        label: country.name,
      })),
    [countries]
  );

  const industryOptions = useMemo(
    () =>
      industries.map((industry) => ({
        value: Number(industry.id),
        label: industry.name,
      })),
    [industries]
  );

  const handleOnActiveOnlyToggle = useCallback(
    (isChecked) => {
      setValue('onlyActiveLocations', isChecked);
    },
    [setValue]
  );

  const onSubmit = useCallback(
    (values) => {
      const normalizedValues = {
        ...values,
        countryId: values?.country?.value,
        industryId: values?.industry?.value,
      };
      delete normalizedValues.country;
      delete normalizedValues.industry;

      searchCustomers(normalizedValues);
    },
    [searchCustomers]
  );

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid>
          <Top>
            <StringField
              register={register}
              error={errors.contactPerson?.message}
              label={t('common:form.label.contactPerson')}
              name="contactPerson"
              placeholder={t('common:form.label.contactPerson')}
            />
            <StringField
              register={register}
              error={errors.emailAddress?.message}
              label={t('common:form.label.emailAddress')}
              name="emailAddress"
              placeholder={t('common:form.label.emailAddress')}
            />
            <StringField
              register={register}
              error={errors.phone?.message}
              label={t('common:form.label.phone')}
              name="phone"
              placeholder={t('common:form.placeholder.phone')}
            />
          </Top>
          <Divider />
          <Middle>
            <StringField
              register={register}
              error={errors.organization?.message}
              label={t('common:form.label.organizationName')}
              name="organization"
              placeholder={t('common:form.placeholder.organizationName')}
            />
            <StringField
              register={register}
              error={errors.vat?.message}
              label={t('common:form.label.vatNumber')}
              name="vat"
              placeholder={t('common:form.placeholder.vatNumber', {
                value: selectedCountry ? selectedCountry.code : 'XX',
              })}
            />
          </Middle>
          <Divider />
          <Bottom>
            <StringField
              register={register}
              error={errors.location?.message}
              label={t('common:form.label.locationName')}
              name="location"
              placeholder={t('common:form.placeholder.organizationName')}
            />
            <StringField
              register={register}
              error={errors.zone?.message}
              label={t('common:form.label.zoneName')}
              name="zone"
              placeholder={t('common:form.placeholder.zoneName')}
            />
            <SwitchFieldContainer>
              <SwitchField
                checked={watchOnlyActiveLocations}
                label={t('common:form.label.activeSubscriptions')}
                onChange={handleOnActiveOnlyToggle}
              />
              <SwitchFieldDescription>
                {t('common:form.label.onlyActiveLocations')}
              </SwitchFieldDescription>
            </SwitchFieldContainer>
            <SelectField
              control={control}
              error={errors.country?.message}
              label={t('common:form.label.country')}
              name="country"
              options={countryOptions}
              placeholder={t('common:form.placeholder.country')}
            />
            <StringField
              register={register}
              error={errors.city?.message}
              label={t('common:form.label.city')}
              name="city"
              placeholder={t('common:form.placeholder.city')}
            />
            <SelectField
              control={control}
              error={errors.industry?.message}
              label={t('common:form.label.industry')}
              name="industry"
              options={industryOptions}
              placeholder={t('common:form.placeholder.industry')}
            />
          </Bottom>
          <Divider $margin="2rem 0" />
          <Actions>
            <Button
              emphasis="outline"
              highlightColor="grapefruitRed"
              onClick={() => reset()}
              title={t('common:button.title.clearForm')}
            >
              {t('common:button.clearForm')}
            </Button>
            <Button
              isLoading={searchCustomersLoading}
              title={t('common:button.title.search')}
              type="submit"
            >
              {t('common:button.search')}
            </Button>
          </Actions>
        </Grid>
      </form>
    </Container>
  );
};

export default SearchForm;
