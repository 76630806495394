import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Page, Button } from '../../common';
import {
  Center,
  Title,
  Description,
  ButtonGroup,
  MaxWidth,
  Paper,
  Icon,
  Cross,
} from './styles';

const Cancel = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { organizationId, saleOrderId } = useParams();
  const [searchParams] = useSearchParams();
  const saleOrderIdSearch = searchParams.get('saleOrderId');

  const handleCancel = useCallback(() => {
    navigate('/subscriptions');
  }, [navigate]);

  const handleAgain = useCallback(() => {
    navigate(
      `/organizations/${organizationId}/sale-orders/${
        saleOrderId ?? saleOrderIdSearch
      }`
    );
  }, [navigate, saleOrderId, organizationId, saleOrderIdSearch]);

  return (
    <Page title={t('purchase:payment.cancel.title')}>
      <Center>
        <MaxWidth>
          <Paper>
            <Icon>
              <Cross />
            </Icon>
            <Title>{t('purchase:payment.cancel.title')}</Title>
            <Description>
              {t('purchase:payment.cancel.description')}
            </Description>
          </Paper>
          <ButtonGroup>
            <Button onClick={handleAgain} title={t('common:button.tryAgain')}>
              {t('common:button.tryAgain')}
            </Button>
            <Button
              emphasis="outline"
              onClick={handleCancel}
              title={t('common:button.title.cancel')}
            >
              {t('common:button.cancel')}
            </Button>
          </ButtonGroup>
        </MaxWidth>
      </Center>
    </Page>
  );
};

export default Cancel;
