import { Route } from 'react-router';
import { AuthGuardPermissions } from '../../../global/auth/authGuardPermissions';
import { OWNER_ROLE_PERMISSIONS } from '../../../global/auth';
import { lazy } from 'react';

const PlaylistsPage = lazy(() =>
  import('../../../modules/musicManagement/playlists')
);

export const MusicPlaylistRouter = [
  <Route
    index
    element={
      <AuthGuardPermissions permissions={OWNER_ROLE_PERMISSIONS}>
        <PlaylistsPage />
      </AuthGuardPermissions>
    }
  />,
];
