import styled from 'styled-components';

import LoadingIndicator from '../loadingIndicator';

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin: 1rem 0;
`;

const TableLoadingIndicator = ({ isLoading }) => (isLoading ? <StyledLoadingIndicator /> : null);

export default TableLoadingIndicator;
