import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Modal } from '../../../../../common';
import { Button } from '../../../../../common/oldButton';
import StringField from '../../../../../common/oldFields/stringField';
import { CategoryModalModes } from '../types';

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;

  & > :not(:first-child) {
    margin-left: 1rem;
  }
`;

const CategoryModal = ({ mode, isOpen, toggle }) => {
  const { t } = useTranslation();

  const [name, setName] = useState('');

  const handleCreateList = useCallback(() => {
    if (name) {
      // TODO: Create list
    }
  }, [name]);

  const handleEditList = useCallback(() => {
    // TODO: Edit list
  }, []);

  const handleChange = useCallback((event) => {
    setName(event.currentTarget?.value);
  }, []);

  const isEdit = mode === CategoryModalModes.EDIT;

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        if (isEdit) {
          handleEditList();
        } else {
          handleCreateList();
        }
      }
    },
    [handleEditList, handleCreateList, isEdit]
  );

  return (
    <Modal
      footer={false}
      isOpen={isOpen}
      onClose={toggle}
      title={
        isEdit
          ? t('musicManagement:playlists.list.editPlaylist')
          : t('musicManagement:playlists.list.newPlaylist')
      }
    >
      <StringField
        autoFocus
        label={t('common:form.label.name')}
        name="name"
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        value={name}
      />
      {/* TODO: add error message */}
      {/* {error || errorEdit ? <ErrorMessage error={error || errorEdit} /> : null} */}
      <ButtonGroup>
        <Button
          emphasis="tertiary"
          flavor="link"
          onClick={toggle}
          title={t('common:button.title.cancel')}
        >
          {t('common:button.cancel')}
        </Button>
        <Button title={t('common:button.title.save')}>
          {t('common:button.save')}
        </Button>
      </ButtonGroup>
    </Modal>
  );
};

export default CategoryModal;
