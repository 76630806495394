import gql from 'graphql-tag';

export const COMPLETE_REGISTRATION = gql`
  mutation CompleteRegistration($input: CompleteRegistrationInput!) {
    completeRegistration(input: $input) {
      success
    }
  }
`;

export const GET_PRESENT_ORGANISATION_DATA = gql`
  query organizations {
    organizations {
      id
      name
      locations {
        id
        industryId
        streetNumber
        streetName
        zip
        city
        phone
      }
    }
  }
`;
