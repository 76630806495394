import { useTranslation } from 'react-i18next';

import { Page } from '../../../common';
import CustomerSuccessSearchProvider from './searchProvider';
import { SearchFilterCard, SearchResultsCard } from './cards';
import PageHeader from './pageHeader';

const CustomerSuccessSearchPage = () => {
  const { t } = useTranslation();

  return (
    <Page title={t('customerSuccess:title')}>
      <PageHeader />
      <CustomerSuccessSearchProvider>
        <SearchFilterCard />
        <SearchResultsCard />
      </CustomerSuccessSearchProvider>
    </Page>
  );
};

export default CustomerSuccessSearchPage;
