import { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useDroppable } from '@dnd-kit/core';
import { useTranslation } from 'react-i18next';

import { useMediaSize } from '../../../../common';
import { useTunifyGreen } from './tunifyGreenProvider';
import { ReactComponent as AddIcon } from '../../../../assets/icons/add.svg';

const TimeSlotButton = styled.button`
  width: 100%;
  height: 100%;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  color: ${({ theme }) => theme.dark200};
  border: 0.1rem solid ${({ theme }) => theme.stroke};
  border-radius: 0.2rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-align: left;

  & svg path {
    fill: ${({ theme }) => theme.white};
  }

  & p {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    background-color: ${({ theme }) => theme.babyBlue};
    border-color: ${({ theme }) => theme.babyBlue};

    & p {
      color: ${({ theme }) => theme.white};
      font-weight: 600;
    }
  }

  ${({ isOver, theme }) =>
    isOver &&
    css`
      border-color: ${theme.babyBlue};
    `}
`;

const StyledAddIcon = styled(AddIcon)`
  & path {
    fill: ${({ theme }) => theme.white};
  }
`;

const TimeValue = styled.p`
  color: ${({ showValueOnHover, theme }) =>
    showValueOnHover ? theme.white : theme.dark200};
`;

const AddInfo = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.white};
  font-weight: 600;
`;

const TimeSlot = ({ id, value }) => {
  const { isNarrowerThanLarge } = useMediaSize();
  const { t } = useTranslation();

  const { overId } = useTunifyGreen();

  const { setNodeRef } = useDroppable({
    id: `AGENDA_ADD_${id}`,
    data: {
      type: 'AGENDA_ADD',
      timeSlotId: id,
      timeSlotValue: value,
    },
    disabled: isNarrowerThanLarge,
  });

  const handleClickGridItem = useCallback(() => {
    // TODO: open popup
  }, []);

  return (
    <div ref={setNodeRef}>
      <TimeSlotButton
        key={id}
        isOver={overId === `AGENDA_ADD_${id}`}
        onClick={handleClickGridItem}
        type="button"
        value={id}
      >
        <TimeValue showValueOnHover={value.includes(':30')}>{value}</TimeValue>
        <StyledAddIcon />
        <AddInfo>{t('musicManagement:tunifyGreen.channels.addMusic')}</AddInfo>
      </TimeSlotButton>
    </div>
  );
};

export default TimeSlot;
