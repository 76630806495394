import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

const StyledNavLink = styled(NavLink)`
  height: 4rem;
  border-radius: 4px;
  padding: 0.2rem;

  &.active {
    ${({ theme, $navGradientTheme }) =>
      $navGradientTheme
        ? css`
            background: ${theme.gradient[$navGradientTheme][0]};
            background: linear-gradient(
              135deg,
              ${theme.gradient[$navGradientTheme][0]} 0%,
              ${theme.gradient[$navGradientTheme][1]} 100%
            );
          `
        : css`
            background: transparent;
          `}
    & > div > p {
      color: ${({ theme }) => theme.white};
    }
  }

  &:hover {
    & > div > p {
      color: ${({ theme }) => theme.white};
    }
    & > div {
      background-color: ${({ theme }) => theme.dark600};
    }
  }
`;

const InnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.dark100};
  background-color: ${({ theme }) => theme.dark500};
  border-radius: 4px;
  padding: 0 1rem;
  transition: background-color 0.2s ease-in-out;

  & > p {
    text-decoration: none;
    font-size: 1.6rem;
    font-weight: 500;
    padding-left: 1.2rem;
    color: ${({ theme }) => theme.dark100};
    transition: color 0.2s ease-in-out;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    justify-content: center;
    padding: 0;
    & > p {
      display: none;
    }
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.extraLarge}px) {
    justify-content: flex-start;
    padding: 0 1rem;
    & > p {
      display: block;
    }
  }
`;

const NavItem = ({ text, Icon, show = true, ...props }) =>
  show ? (
    <StyledNavLink {...props}>
      <InnerWrapper>
        <Icon width="2.4rem" height="2.4rem" />
        <p>{text}</p>
      </InnerWrapper>
    </StyledNavLink>
  ) : null;

export default NavItem;
