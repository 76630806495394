import gql from 'graphql-tag';

const GET_CONTACT_CATEGORIES_AND_CHANNELS = gql`
  query getContactCategoriesAndChannels {
    contactCategories {
      id
      name
    }
    contactChannels {
      id
      name
    }
  }
`;

const GET_LANGUAGES = gql`
  query getLanguages {
    languages {
      id
      code
      name
    }
  }
`;

const CREATE_LEAD = gql`
  mutation createLead($lead: CreateLeadInput!) {
    createLead(lead: $lead)
  }
`;

const GET_COUNTRIES = gql`
  query getCountries {
    countries {
      id
      name
      code
    }
  }
`;

export { GET_LANGUAGES, GET_CONTACT_CATEGORIES_AND_CHANNELS, CREATE_LEAD, GET_COUNTRIES };
