import { useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { addMonths } from 'date-fns';

import {
  formatDate,
  formatNumber,
  parseDateString,
  getProductSubscriptionLineOfSubscriptionLines,
  getAddonSubscriptionLinesOfSubscriptionLines,
  CheckboxField,
} from '../../../common';
import { useRenewPurchase } from './renewPurchaseContext';
import { getEquivalentOfProduct, getEquivalentOfAddon } from './utils';

const ItemContainer = styled.div`
  display: grid;
  gap: 1rem;
  padding-top: 1rem;
`;

const ItemTitle = styled.p`
  font-size: 1.6rem;
  line-height: 1.4;
  font-weight: 600;
  color: ${({ theme }) => theme.white};

  & > span {
    color: ${({ theme }) => theme.dark300};
    font-weight: 500;
  }
`;

const Text = styled.p`
  font-size: 1.4rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.dark100};

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    padding-left: 3.2rem;
  }
`;

const RightText = styled(Text)`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.white};

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    justify-self: flex-end;
    white-space: nowrap;
  }
`;

const OrderRow = ({ zone }) => {
  const { t, i18n } = useTranslation();

  const { toggleSelectedZone, selectedZones, cartLoading, order, availableProducts, isForever, availableAddons } =
    useRenewPurchase();

  const productSubscriptionLine = useMemo(
    () => getProductSubscriptionLineOfSubscriptionLines(zone.lastSubscription.subscriptionLines),
    [zone.lastSubscription.subscriptionLines]
  );
  const addonSubscriptionLines = useMemo(
    () => getAddonSubscriptionLinesOfSubscriptionLines(zone.lastSubscription.subscriptionLines),
    [zone.lastSubscription.subscriptionLines]
  );

  const isChecked = useMemo(
    () => selectedZones.map((selectedZone) => selectedZone.id).includes(zone.id),
    [zone, selectedZones]
  );

  // The subscription end date if the user actually buys this new subscription
  const subscriptionEndDate = useMemo(() => {
    let amountOfMonths;

    if (order) {
      const possibleSaleOrderLine = order.saleOrderLines.find((saleOrderLine) => saleOrderLine.zone.id === zone.id);
      // If there is a sale order line for the order row (the user has selected the row),
      // set the "official" recurring interval of the product
      if (possibleSaleOrderLine) {
        amountOfMonths = possibleSaleOrderLine.product.subscriptionTemplate.recurringInterval;
      }
    }

    if (!amountOfMonths) {
      // If the sale order is not fetched or the row is not selected, set the calculated recurring interval
      const equivalentProduct = getEquivalentOfProduct(productSubscriptionLine.product, availableProducts, isForever);
      amountOfMonths = equivalentProduct.subscriptionTemplate.recurringInterval;
    }

    const date = addMonths(parseDateString(zone.lastSubscription.endDate), amountOfMonths);

    return date;
  }, [zone.lastSubscription.endDate, productSubscriptionLine, availableProducts, isForever, order, zone.id]);

  const orderRowPrice = useMemo(() => {
    if (order) {
      const saleOrderLines = order.saleOrderLines.filter((saleOrderLine) => saleOrderLine.zone.id === zone.id);
      // If there are sale order lines for the order row (the user has selected the row),
      // show the "official" price of the sale order
      if (saleOrderLines.length > 0) {
        return saleOrderLines.reduce((acc, curr) => acc + curr.priceUnit, 0);
      }
    }

    // If the row is not selected, show the calculated price
    const equivalentProduct = getEquivalentOfProduct(productSubscriptionLine.product, availableProducts, isForever);
    const equivalentAddons = addonSubscriptionLines.map((addonSubscriptionLine) =>
      getEquivalentOfAddon(addonSubscriptionLine.product, availableAddons, isForever)
    );
    return equivalentAddons.reduce((acc, curr) => acc + curr.price, 0) + equivalentProduct.price;
  }, [order, zone, availableProducts, productSubscriptionLine, isForever, addonSubscriptionLines, availableAddons]);

  const addonNames = useMemo(() => {
    return addonSubscriptionLines.map((subscriptionLine) => subscriptionLine.product.shortName).join(', ');
  }, [addonSubscriptionLines]);

  const handleCheckBoxChange = useCallback(() => {
    toggleSelectedZone(zone);
  }, [zone, toggleSelectedZone]);

  return (
    <ItemContainer>
      <CheckboxField
        disabled={cartLoading}
        onChange={handleCheckBoxChange}
        checked={isChecked}
        label={
          <ItemTitle>
            {zone.location.name} <span>| {zone.name}</span>
          </ItemTitle>
        }
      />
      <div>
        <Text>{productSubscriptionLine.product.musicLicense.name}</Text>
        <Text>
          {productSubscriptionLine.product.shortName}{' '}
          {t('glossary:monthWithCount', {
            count: productSubscriptionLine.product.subscriptionTemplate.recurringInterval,
          })}
        </Text>
        <Text>{addonNames}</Text>
        <Text>{formatDate(subscriptionEndDate, i18n.language)}</Text>
      </div>
      <RightText>{orderRowPrice ? `€ ${formatNumber(orderRowPrice, i18n.language)}` : ''}</RightText>
    </ItemContainer>
  );
};

export default OrderRow;
