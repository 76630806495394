import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { GridTable, formatDate, formatNumber, useMediaSize } from '../../../common';

const RowContainer = styled(GridTable.Row)`
  border-radius: unset;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.stroke};

  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: 'name amount' 'product amount';

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    grid-auto-flow: row;
    grid-template-columns: 1fr 1fr min-content;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: 'name product amount' 'name endDate amount';
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    grid-auto-flow: row;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
    grid-template-areas: 'creationDate name product endDate amount';
  }
`;

const CreationDate = styled(GridTable.Value)`
  grid-area: creationDate;
  color: ${({ theme }) => theme.dark300};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.143;
`;
const EndDate = styled(GridTable.Value)`
  grid-area: endDate;
  color: ${({ theme }) => theme.dark300};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.143;
`;

const Name = styled(GridTable.Value)`
  grid-area: name;
  color: ${({ theme }) => theme.dark500};
  font-family: 'Inter', sans-serif;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.5;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    color: ${({ theme }) => theme.dark300};
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.143;
  }
`;

const CommissionAmount = styled(GridTable.Value)`
  grid-area: amount;
  color: ${({ theme }) => theme.dark300};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.143;
`;

const ProductName = styled(GridTable.Value)`
  grid-area: product;
  color: ${({ theme }) => theme.dark300};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.143;
`;

const WinnerTableRow = ({ winnerCustomer }) => {
  const { i18n } = useTranslation();

  const { isWiderThanMedium, isWiderThanLarge } = useMediaSize();

  const name = useMemo(() => `${winnerCustomer.location.name} - ${winnerCustomer.name}`, [winnerCustomer]);

  return (
    <RowContainer>
      {isWiderThanLarge && (
        <CreationDate>
          {formatDate(winnerCustomer.createDate, i18n.language, {
            outputFormat: { day: '2-digit', month: 'long', year: 'numeric' },
          })}
        </CreationDate>
      )}
      <Name>{name}</Name>
      <ProductName>{winnerCustomer.commissionDetails.commissionProduct.name}</ProductName>
      {isWiderThanMedium && (
        <EndDate>
          {formatDate(winnerCustomer.lastSubscription.endDate, i18n.language, {
            outputFormat: { day: '2-digit', month: 'long', year: 'numeric' },
          })}
        </EndDate>
      )}
      <CommissionAmount>
        € {formatNumber(winnerCustomer.commissionDetails.finderCommissionAmount, i18n.language)}
      </CommissionAmount>
    </RowContainer>
  );
};

export default WinnerTableRow;
