import { useReducer } from 'react';

const toggleReducer = (flag, nextFlag) => {
  // Use the next flag if supplied
  if (typeof nextFlag === 'boolean') {
    return nextFlag;
  }

  return !flag;
};

const useToggle = (initialFlag) => {
  return useReducer(toggleReducer, initialFlag);
};

export default useToggle;
