import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { LoadingIndicator, ErrorMessage, formatNumber } from '../../../../../common';
import { usePurchase } from '../../purchaseContext';
import { ReactComponent as InvoicesIcon } from '../../../../../assets/icons/navigation/invoicesPurchase.svg';

const OrderPriceOverview = styled.div`
  display: grid;
  gap: 1rem;
`;

const OrderPrice = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    grid-template-columns: 1fr min-content;
    gap: 1.6rem;
  }
`;

const OrderTotal = styled(OrderPrice)`
  grid-template-columns: min-content 1fr;
  column-gap: 1.2rem;
  row-gap: 0.4rem;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    grid-template-columns: min-content 1fr min-content;
  }
`;

const SmallText = styled.p`
  font-size: 1.6rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.white};
  white-space: nowrap;
`;

const GreyText = styled.div`
  font-size: 1.4rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.dark200};
`;

const TotalPrice = styled(SmallText)`
  grid-column: span 2;
  font-size: 2.4rem;
  font-weight: 600;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    grid-column: span 1;
  }
`;

const HorizontalRule = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.dark400};
`;

const LoadingIndicatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({ hasMultipleZones }) => (hasMultipleZones ? '9.9rem' : '12rem')};
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin-top: ${({ hasMultipleZones }) => (hasMultipleZones ? '4rem' : '5rem')};
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: ${({ hasMultipleZones }) => (hasMultipleZones ? '3rem' : '4rem')};
`;

const PriceSummary = () => {
  const { t, i18n } = useTranslation();
  const { saleOrder, selectedAdditionalZones, saleOrderLoading, saleOrderError } = usePurchase();

  if (saleOrderLoading || saleOrderError) {
    return (
      <LoadingIndicatorContainer hasMultipleZones={selectedAdditionalZones.length > 0}>
        {selectedAdditionalZones.length === 0 && <HorizontalRule />}
        {saleOrderLoading && <StyledLoadingIndicator hasMultipleZones={selectedAdditionalZones.length > 0} />}
        {saleOrderError && (
          <StyledErrorMessage error={saleOrderError} hasMultipleZones={selectedAdditionalZones.length > 0} />
        )}
      </LoadingIndicatorContainer>
    );
  }

  if (saleOrder) {
    const { amountUntaxed, amountTax, amountTotal } = saleOrder;

    return (
      <OrderPriceOverview>
        <HorizontalRule />
        <OrderPrice>
          <GreyText>{t('glossary:exclusiveVatLong')}</GreyText>
          <SmallText>€ {formatNumber(amountUntaxed, i18n.language)}</SmallText>
        </OrderPrice>
        <OrderPrice>
          <GreyText>{t('glossary:vatLong')}</GreyText>
          <SmallText>€ {formatNumber(amountTax, i18n.language)}</SmallText>
        </OrderPrice>
        <HorizontalRule />
        <OrderTotal>
          <InvoicesIcon width="2.4rem" height="2.4rem" />
          <SmallText>{t('glossary:inclusiveVatLong')}</SmallText>
          <TotalPrice>€ {formatNumber(amountTotal, i18n.language)}</TotalPrice>
        </OrderTotal>
      </OrderPriceOverview>
    );
  }

  return null;
};

export default PriceSummary;
