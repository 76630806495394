import { Navigate, Outlet, Route, useLocation } from 'react-router';
import { SonosOverviewPage, SonosPlayerCodePage } from '../../../modules/sonos';
import { SpotifyImportPage } from '../../../modules/spotify';
import { useAuth0 } from '@auth0/auth0-react';
import { useMemo } from 'react';

export const integrationRoutesBehindAuthentication = [
  <Route path="spotify/import" element={<SpotifyImportPage />} />,
];

const AuthorizationGuard = () => {
  const { isAuthenticated } = useAuth0();
  const location = useLocation();

  const to = useMemo(() => ({
    pathname: '../sonos/player-code',
    search: location.search,
    hash: location.hash,
  }), [location]);

  if (isAuthenticated) {
    return <Outlet />;
  }

  return <Navigate to={to} replace />
};

export const integrationRoutesWithoutAuthentication = [
  <Route path="sonos/player-code" element={<SonosPlayerCodePage />} />,
  <Route path="sonos/overview" element={<AuthorizationGuard />}>
    <Route path="" element={<SonosOverviewPage />} />
  </Route>,
];
