import { useQuery } from '@apollo/react-hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  ErrorMessage,
  LoadingIndicator,
  LocationCard,
  useLocalStorage,
} from '../../../../../../common';
import { useAuth } from '../../../../../../global/auth/newAuthProvider';
import {
  GET_LOCATION_BY_ID,
  GET_MUSIC_PROFILES,
} from '../../musicManagementTab/api';
import ZoneCard from './zoneCard';

const StyledErrorMessage = styled(ErrorMessage)`
  width: 100%;
  margin-top: 2rem;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin: 2rem;
`;

const AddonLocationCard = ({
  location,
  forceRender,
  setForceRender,
  expandAllLocations,
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const { control, setValue } = useFormContext();
  const { fields } = useFieldArray({
    name: `musicProfiles_${location.id}`,
    control,
  });

  const [isOpen, setOpen] = useState(false);

  const [memoryProfileId, setMemoryProfileId] = useLocalStorage(
    `${user.id}_lastViewedProfile`,
    ''
  );

  const {
    data: locationData,
    loading: locationLoading,
    error: locationError,
  } = useQuery(GET_LOCATION_BY_ID, {
    skip: !isOpen,
    variables: { locationId: location.id },
    onCompleted: (data) => {
      setValue(
        `musicProfiles_${location.id}`,
        data?.location?.zones?.map((zone) => ({
          zoneId: zone.id,
          musicProfile: zone?.musicProfile
            ? { value: zone.musicProfile.id, label: zone.musicProfile.name }
            : undefined,
        }))
      );
    },
  });

  useEffect(() => {
    // Forcing to rerender if we clicked the "cancel" button
    if (forceRender && isOpen) {
      setForceRender(false);
      // We reset the values for this opened location card in the form
      setValue(
        `musicProfiles_${location.id}`,
        locationData?.location?.zones?.map((zone) => ({
          zoneId: zone.id,
          musicProfile: zone?.musicProfile
            ? { value: zone.musicProfile.id, label: zone.musicProfile.name }
            : undefined,
        }))
      );
    }
  }, [
    forceRender,
    setForceRender,
    isOpen,
    setValue,
    location.id,
    locationData?.location?.zones,
  ]);

  const {
    loading: musicProfilesLoading,
    error: musicProfilesError,
    data: musicProfilesData,
  } = useQuery(GET_MUSIC_PROFILES, {
    variables: {
      // Will be used to create if the user has no brand
      defaultBrandName: t('config:broadcasts.defaultBrandName'),
    },
    onCompleted: ({ musicProfiles }) => {
      if (!memoryProfileId) {
        setMemoryProfileId(musicProfiles[0].id);
      }
    },
  });

  const toggleLocationCard = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (expandAllLocations && !isOpen) {
      setOpen(true);
    } else if (!expandAllLocations && isOpen) {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandAllLocations]);

  const renderZones = useMemo(() => {
    if (locationError) {
      return <StyledErrorMessage error={locationError} />;
    }
    if (
      musicProfilesLoading ||
      locationLoading ||
      !locationData?.location?.zones
    ) {
      return <StyledLoadingIndicator size="medium" thickness="medium" />;
    }
    return locationData?.location?.zones
      ?.sort((a, b) => a.name.localeCompare(b.name))
      .map((zone) => (
        <ZoneCard
          key={zone.id}
          zone={zone}
          fields={fields}
          musicProfiles={musicProfilesData?.musicProfiles}
          musicProfilesError={musicProfilesError}
          musicProfilesLoading={musicProfilesLoading}
        />
      ));
  }, [
    fields,
    locationData?.location?.zones,
    locationError,
    locationLoading,
    musicProfilesData?.musicProfiles,
    musicProfilesError,
    musicProfilesLoading,
  ]);

  return (
    <LocationCard
      location={location}
      isOpen={isOpen}
      toggleLocationCard={toggleLocationCard}
      hasEditButton={false}
    >
      {renderZones}
    </LocationCard>
  );
};

export default AddonLocationCard;
