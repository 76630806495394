import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { ReactComponent as RawAddIcon } from '../../../../assets/icons/add.svg';
import { ReactComponent as RawEditIcon } from '../../../../assets/icons/edit.svg';
import {
  hasAllRequiredPermissions,
  OWNER_ROLE_PERMISSIONS,
  SUPPORT_ROLE_PERMISSIONS,
} from '../../../../global/auth';

import { Button } from '../../../../common/oldButton';
import { useAuth } from '../../../../global/auth/newAuthProvider';
import { AddMusicProfileModal, EditMusicProfileModal } from '../modals';
import { AddonTabType, DropdownOptionTypes } from '../types';
import Dropdown from './dropdown';

const iconCss = css`
  width: 1.2rem;
  height: 1.2rem;
  & path {
    fill: ${({ theme }) => theme.dark300};
  }
`;

const AddIcon = styled(RawAddIcon)`
  ${iconCss}
`;

const EditIcon = styled(RawEditIcon)`
  ${iconCss}
`;

const ConfigureIcon = styled(RawAddIcon)`
  ${iconCss}

  margin-right: 1rem;
  & path {
    fill: ${({ theme }) => theme.white};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 1rem;
`;

const EditButton = styled(Button)`
  margin: 0 0 0.5rem 1rem;
`;

const AddButton = styled(Button)`
  margin: 0 0 0.5rem 1rem;
`;

const Container = styled.div`
  display: grid;
  gap: 1rem;
  align-items: center;
  margin-bottom: 2rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    grid-template-columns: 1fr min-content;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ZonesWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LinkButton = styled(Button)`
  color: ${({ theme }) => theme.berryBlue};
  text-transform: unset;
  letter-spacing: unset;
  width: fit-content;
  white-space: nowrap;
  padding: 0;

  &::before {
    left: 0;
    right: 0;
    bottom: -3px;
  }
`;

const ZoneAvatars = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  column-gap: 0.5rem;
  padding-right: 1rem;
  & p {
    color: ${({ theme }) => theme.dark300};
    white-space: nowrap;
    font-size: 0.9rem;
  }
`;

const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse; // Reverse back from reversed array to have correct order with changed overlapping
`;

const CreateProfileButton = styled(Button)`
  height: 3rem;
  align-items: center;
  margin-left: 2rem;
`;

const PageHeader = ({
  profile,
  profiles,
  profileType,
  onChangeProfile,
  zones,
  zonesWithAnActiveSubscription,
  removeProfileId,
  setProfileId,
  setProfileType,
  onConfigureMusicProfile,
  zonesWithoutProfile,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useAuth();

  const [isAddMusicProfileModalOpen, setIsAddMusicProfileModalOpen] =
    useState(false);
  const [isEditMusicProfileModalOpen, setIsEditMusicProfileModalOpen] =
    useState(false);

  const handleAddMusicProfile = useCallback(() => {
    setIsAddMusicProfileModalOpen(true);
  }, []);

  const handleEditMusicProfile = useCallback(() => {
    setIsEditMusicProfileModalOpen(true);
  }, []);

  const handleCloseAddModal = useCallback(() => {
    setIsAddMusicProfileModalOpen(false);
  }, []);

  const handleCloseEditModal = useCallback(() => {
    setIsEditMusicProfileModalOpen(false);
    // Set to different profile/zone after delete
    if (profiles.length > 0) {
      setProfileId(profiles[0].id);
      setProfileType(DropdownOptionTypes.PROFILE);
    } else {
      setProfileId(zones[0].id);
      setProfileType(DropdownOptionTypes.ZONE);
    }
  }, [profiles, setProfileId, setProfileType, zones]);

  const isOwner = hasAllRequiredPermissions(user, OWNER_ROLE_PERMISSIONS);
  const isSupport = hasAllRequiredPermissions(user, SUPPORT_ROLE_PERMISSIONS);

  const musicProfileZones = useMemo(
    () =>
      zonesWithAnActiveSubscription?.filter(
        (zone) => zone.musicProfile?.id === profile?.id
      ),
    [profile?.id, zonesWithAnActiveSubscription]
  );

  const goToAddonsOverview = useCallback(() => {
    navigate(`/add-ons?type=${AddonTabType.MUSIC_MANAGEMENT}`);
  }, [navigate]);

  return (
    <Container>
      <Wrapper>
        {profile && (
          <Dropdown
            currentProfile={profile}
            onChange={onChangeProfile}
            options={profiles}
            profileType={profileType}
            zonesWithoutProfile={zonesWithoutProfile}
          />
        )}
        {(isOwner || isSupport) && profileType === DropdownOptionTypes.PROFILE && (
          <ButtonWrapper>
            {profile && (
              <EditButton
                emphasis="secondary"
                flavor="icon"
                onClick={handleEditMusicProfile}
                title={t('common:button.title.edit', {
                  value: t('common:button.title.musicProfile'),
                })}
                type="button"
              >
                <EditIcon />
              </EditButton>
            )}
            <AddButton
              emphasis="secondary"
              flavor="icon"
              onClick={handleAddMusicProfile}
              title={t('common:button.title.add', {
                value: t('common:button.title.musicProfile'),
              })}
              type="button"
            >
              <AddIcon />
            </AddButton>
          </ButtonWrapper>
        )}
        {(isOwner || isSupport) && profileType === DropdownOptionTypes.ZONE && (
          <CreateProfileButton
            emphasis="primary"
            onClick={onConfigureMusicProfile}
            shouldCapitalizeText={false}
            title={t('common:button.title.add', {
              value: t('common:button.title.musicProfile'),
            })}
            type="button"
          >
            <ConfigureIcon />
            <p>{t('musicManagement:addMusicProfile.title')}</p>
          </CreateProfileButton>
        )}
      </Wrapper>
      {profileType === DropdownOptionTypes.PROFILE && (
        <ZonesWrapper>
          <ZoneAvatars>
            <AvatarWrapper></AvatarWrapper>
            {musicProfileZones?.length > 3 ? (
              <p>+ {musicProfileZones.length - 3}</p>
            ) : null}
          </ZoneAvatars>
          <LinkButton
            emphasis="tertiary"
            flavor="link"
            onClick={goToAddonsOverview}
            title={t('broadcasts:card.actions.zoneOverview.title')}
            type="button"
          >
            {t('broadcasts:card.actions.zoneOverview.label')}
          </LinkButton>
        </ZonesWrapper>
      )}
      {isAddMusicProfileModalOpen && (
        <AddMusicProfileModal
          isOpen={isAddMusicProfileModalOpen}
          onClose={handleCloseAddModal}
          setProfileId={setProfileId}
        />
      )}
      {isEditMusicProfileModalOpen && (
        <EditMusicProfileModal
          canDelete={profiles?.length > 1}
          isOpen={isEditMusicProfileModalOpen}
          onClose={handleCloseEditModal}
          profile={profile}
          removeProfileId={removeProfileId}
        />
      )}
    </Container>
  );
};

export default PageHeader;
