import { useTranslation } from 'react-i18next';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import styled from 'styled-components';

import { usePartnerManager } from '../partnerManagerContext';
import PartnerTableRow from './partnerTableRow';
import { LIMIT } from '../partnerManagerProvider';
import { GridTable } from '../../../common';

const DesktopHeader = styled(GridTable.Header)`
  display: none;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    display: grid;
  }
`;

const TabletHeader = styled(GridTable.Header)`
  display: none;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.small}px) {
    display: grid;
    ${({ templateColumns }) => templateColumns && `grid-template-columns: ${templateColumns};`}
    column-gap: 1.5rem;
    align-items: center;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    display: none;
  }
`;

const PartnersTable = () => {
  const { t } = useTranslation();

  const { partners, partnersError, partnersLoading, fetchMorePartners, hasMorePartners } = usePartnerManager();

  const [infiniteRef] = useInfiniteScroll({
    loading: partnersLoading,
    // Due to refetch we cannot know if there is more after the initial query,
    // we have to additionally check for this
    hasNextPage: partners?.length >= LIMIT && hasMorePartners,
    onLoadMore: fetchMorePartners,
  });

  return (
    <GridTable ref={infiniteRef}>
      <DesktopHeader templateColumns="2fr 2fr 1fr 1fr 1fr">
        <GridTable.Head>{t('partnerManager:partners.tableHeader.partnerName')}</GridTable.Head>
        <GridTable.Head>{t('partnerManager:partners.tableHeader.closingDate')}</GridTable.Head>
        <GridTable.Head>{t('partnerManager:partners.tableHeader.qualifiers')}</GridTable.Head>
        <GridTable.Head>{t('partnerManager:partners.tableHeader.winners')}</GridTable.Head>
        <GridTable.Head>{t('partnerManager:partners.tableHeader.referredCustomers')}</GridTable.Head>
      </DesktopHeader>
      <TabletHeader templateColumns="2fr 1fr 1fr 1fr">
        <GridTable.Head>{t('partnerManager:partners.tableHeader.partnerName')}</GridTable.Head>
        <GridTable.Head>{t('partnerManager:partners.tableHeader.qualifiers')}</GridTable.Head>
        <GridTable.Head>{t('partnerManager:partners.tableHeader.winners')}</GridTable.Head>
        <GridTable.Head>{t('partnerManager:partners.tableHeader.referredCustomers')}</GridTable.Head>
      </TabletHeader>
      {(!partners || partners?.length === 0) && !partnersLoading && (
        <GridTable.Message>{t('partnerManager:partners.table.noPartners')}</GridTable.Message>
      )}
      {partners?.map((partner) => (
        <PartnerTableRow key={partner.id} partner={partner} />
      ))}
      <GridTable.LoadingIndicator isLoading={partnersLoading} />
      <GridTable.ErrorMessage error={partnersError} />
    </GridTable>
  );
};

export default PartnersTable;
