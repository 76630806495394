import shortid from 'shortid';

const createOpeningPeriods = () => {
  return [{ open: null, close: null, id: shortid.generate() }];
};

const createOpeningDay = (day) => {
  return {
    day,
    openingType: 'withOpeningPeriods',
    openingPeriods: createOpeningPeriods(),
  };
};

export const createEmptyForm = () => {
  const days = new Array(7).fill().map((_, index) => index + 1);
  return {
    googleSynced: false,
    openingDays: days.map(createOpeningDay),
  };
};
