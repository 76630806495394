import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Failure } from '../../../../../common';

const SonosFailure = ({ setCouplingHasFailed, zoneName, errorMessage }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleAgain = useCallback(() => {
    setCouplingHasFailed(false);
  }, [setCouplingHasFailed]);

  const handleCancel = useCallback(() => {
    navigate(`/`);
  }, [navigate]);

  return (
    <Failure
      description={
        errorMessage ??
        t('integrations:sonos.failure.description', { value: zoneName })
      }
      onAgain={handleAgain}
      onCancel={handleCancel}
      title={t('integrations:sonos.failure.title')}
    />
  );
};

export default SonosFailure;
