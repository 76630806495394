import { setHours, setMinutes, getHours, getMinutes, parseISO } from 'date-fns';

/**
 * timeString must be in military time format, like '1700' or '0230'
 */
export const parseTimeString = (timeString) => {
  const hours = timeString.slice(0, 2);
  const minutes = timeString.slice(2, 4);
  const date = new Date();
  const dateWithHours = setHours(date, hours);
  const dateWithHoursMinutes = setMinutes(dateWithHours, minutes);
  return dateWithHoursMinutes;
};

/**
 * timeString must be in military time format, like '1700' or '0230'
 */
export const parseTimeStringWithDate = (timeString, date) => {
  const hours = timeString.slice(0, 2);
  const minutes = timeString.slice(2, 4);
  const dateWithHours = setHours(date, hours);
  const dateWithHoursMinutes = setMinutes(dateWithHours, minutes);
  return dateWithHoursMinutes;
};

/**
 * Result is in military time format, like '1700' or '0230'
 */
export const createTimeString = (date, withDivider = false) => {
  let dateObject;
  if (date instanceof Date) {
    dateObject = date;
  } else {
    dateObject = parseISO(`2022-04-15T${date ?? '00:00'}:00`);
  }
  const hours = String(getHours(dateObject)).padStart(2, '0').padEnd(2, '0');
  const minutes = String(getMinutes(dateObject))
    .padStart(2, '0')
    .padEnd(2, '0');
  return `${hours}${withDivider ? ':' : ''}${minutes}`;
};
