import { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { addDays, subDays } from 'date-fns';
import { useQuery } from '@apollo/react-hooks';
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
  closestCenter,
} from '@dnd-kit/core';

import {
  capitalize,
  formatDayOfWeek,
  LoadingIndicator,
  ErrorMessage,
} from '../../../../common';
import { Button } from '../../../../common/oldButton';
import { useTunifyGreen } from './tunifyGreenProvider';
import { ReactComponent as Trash } from '../../../../assets/icons/delete.svg';
import { ReactComponent as Edit } from '../../../../assets/icons/edit.svg';
import { ReactComponent as ArrowRight } from '../../../../assets/icons/arrowRight.svg';
import TimeSlot from './timeSlot';
import ChannelItem from './channelItem';
import { GET_TUNIFY_CALENDAR } from './api';

const Container = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: min-content min-content 1fr;
`;

const MainHeader = styled.header`
  margin: 0;
  display: grid;
  grid-template-columns: 1fr min-content min-content;
  gap: 0.5rem;
  align-items: center;
  padding: 1rem 1.2rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    margin: 1rem 1.2rem 0.5rem 1rem;
    padding: 0;
  }
`;

const StyledButton = styled(Button)`
  padding: 0;
  margin: 0;

  & svg:hover {
    path {
      fill: ${({ theme }) => theme.babyBlue};
    }
  }
`;

const MainTitle = styled.h3`
  font-size: 1.1rem;
  font-weight: 800;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DayBrowser = styled.div`
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 0.1rem solid ${({ theme }) => theme.dark200};
  border-bottom: 0.1rem solid ${({ theme }) => theme.dark200};
  margin: 0 1rem;
`;

const ArrowLeft = styled(ArrowRight)`
  transform: rotate(180deg);
`;

const DayOfTheWeek = styled.p`
  min-width: 10rem;
  text-align: center;
  margin: 0;
`;

const Details = styled.div`
  height: calc(100vh - 26.5rem);
  display: grid;
  grid-template-columns: min-content 1fr;
  overflow-y: auto;
  padding: 2rem 1rem 1rem 1rem;
`;

const TimeWrapper = styled.div`
  display: grid;
  justify-items: center;
  min-width: 3rem;
  position: relative;
`;

const Hour = styled.p`
  margin: -0.75rem 0 0 0;
  padding-bottom: 3rem;
  color: ${({ theme }) => theme.dark300};
  font-size: 0.85rem;
`;

const TimeIndicator = styled.div`
  width: 0.8rem;
  height: 0.2rem;
  background-color: ${({ theme }) => theme.dark200};
  position: absolute;
  top: ${({ position }) => position}%;
  ${({ right }) =>
    right
      ? css`
          right: 0;
        `
      : css`
          left: 0;
        `}
`;

const AgendaGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(48, 1.9rem);
  row-gap: 0.1rem;
  column-gap: 0.5rem;
  position: relative;
`;

const ChannelItems = styled(AgendaGrid)`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  width: 100%;
  height: min-content;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin-top: 2rem;
`;

const sideTimeArray = Array.from(Array(24), (_, index) =>
  `${index}`.padStart(2, '0')
);

const timeSlotArray = sideTimeArray.flatMap((hourString) => [
  `${hourString}:00`,
  `${hourString}:30`,
]);

const doubleTimeSlotArray = timeSlotArray.flatMap((value) => {
  return [value, value];
});

const AgendaDetails = () => {
  const { i18n } = useTranslation();

  const { selectedCalendar, musicProfile, dispatch } = useTunifyGreen();

  const [currentDay, setCurrentDay] = useState(new Date());

  const {
    data: dataCalendar,
    loading: loadingCalendar,
    error: errorCalendar,
  } = useQuery(GET_TUNIFY_CALENDAR, {
    variables: {
      musicProfileId: musicProfile?.tunifyMusicProfileUserId,
      language: i18n.language,
      calendarId: selectedCalendar?.calendarId,
    },
    skip:
      !musicProfile?.tunifyMusicProfileUserId || !selectedCalendar?.calendarId,
  });

  const currentDayOfTheWeek = useMemo(
    () => capitalize(formatDayOfWeek(currentDay, i18n.language)),
    [currentDay, i18n.language]
  );

  const currentCalendarsOfDay = useMemo(
    () =>
      dataCalendar?.tunifyCalendar?.filter(
        (calendarItem) =>
          calendarItem?.cronString?.split(' ')?.[5] ===
          (currentDay.getDay() + 1).toString()
      ),
    [currentDay, dataCalendar]
  );

  const isCustomAgenda = !dataCalendar?.tunifyCalendar;

  const goToNextDay = useCallback(() => {
    setCurrentDay((prev) => addDays(prev, 1));
  }, []);

  const goToPreviousDay = useCallback(() => {
    setCurrentDay((prev) => subDays(prev, 1));
  }, []);

  const currentTimePercentage = useMemo(() => {
    const timeAsNumber = currentDay.getHours() + currentDay.getMinutes() / 60;
    return (timeAsNumber / 24) * 100;
  }, [currentDay]);

  const sensors = useSensors(useSensor(PointerSensor));

  const handleDragOver = useCallback(
    (event) => {
      dispatch({
        overId: event?.over?.id,
      });
    },
    [dispatch]
  );

  const handleDragEnd = useCallback(
    (event) => {
      const { over } = event;

      if (over?.data?.current?.type === 'AGENDA_ADD') {
        if (over?.data?.current?.timeSlotId) {
          // TODO
        }
      }

      dispatch({
        overId: null,
      });
    },
    [dispatch]
  );

  const handleDragCancel = useCallback(() => {
    dispatch({
      overId: null,
    });
  }, [dispatch]);

  return loadingCalendar ? (
    <StyledLoadingIndicator />
  ) : (
    <>
      {errorCalendar ? <StyledErrorMessage error={errorCalendar} /> : null}
      <DndContext
        collisionDetection={closestCenter}
        onDragCancel={handleDragCancel}
        onDragEnd={handleDragEnd}
        onDragOver={handleDragOver}
        sensors={sensors}
      >
        <Container>
          <MainHeader>
            <MainTitle>{capitalize(selectedCalendar.name)}</MainTitle>
            {isCustomAgenda ? (
              <>
                <StyledButton flavor="icon">
                  <Edit height="1rem" width="2rem" />
                </StyledButton>
                <StyledButton flavor="icon">
                  <Trash height="1rem" width="2rem" />
                </StyledButton>
              </>
            ) : null}
          </MainHeader>
          <DayBrowser>
            <StyledButton flavor="icon" onClick={goToPreviousDay}>
              <ArrowLeft height="0.8rem" width="2rem" />
            </StyledButton>
            <DayOfTheWeek>{currentDayOfTheWeek}</DayOfTheWeek>
            <StyledButton flavor="icon" onClick={goToNextDay}>
              <ArrowRight height="0.8rem" width="2rem" />
            </StyledButton>
          </DayBrowser>
          <Details>
            <TimeWrapper>
              {sideTimeArray.map((value) => (
                <Hour key={value}>{value}</Hour>
              ))}
              <TimeIndicator position={currentTimePercentage} />
              <TimeIndicator position={currentTimePercentage} right />
            </TimeWrapper>
            <AgendaGrid>
              {isCustomAgenda
                ? doubleTimeSlotArray.map((value, index) => (
                    <TimeSlot key={index} id={index} value={value} />
                  ))
                : null}
              <ChannelItems>
                {currentCalendarsOfDay?.map((calendarItem) => {
                  const minutes = Number(calendarItem.cronString.split(' ')[1]);
                  const hour = Number(calendarItem.cronString.split(' ')[2]);
                  const row =
                    timeSlotArray.findIndex(
                      (value) =>
                        value ===
                        `${`${hour}`.padStart(2, '0')}:${`${minutes}`.padStart(
                          2,
                          '0'
                        )}`
                    ) + 1;
                  return (
                    <ChannelItem
                      key={calendarItem.id}
                      column={calendarItem.position}
                      id={calendarItem.id}
                      isCustomAgenda={isCustomAgenda}
                      row={row}
                      size={calendarItem.duration / 30}
                      title={calendarItem.title}
                    />
                  );
                })}
              </ChannelItems>
            </AgendaGrid>
          </Details>
        </Container>
      </DndContext>
    </>
  );
};

export default AgendaDetails;
