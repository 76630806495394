import { useQuery } from '@apollo/react-hooks';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  ErrorMessage,
  LoadingIndicator,
  MUSIC_MANAGEMENT_ADD_ON_ID,
  Page,
  SelectCard,
  useLocalStorage,
  useToggle,
} from '../../../common';
import { useAuth } from '../../../global/auth/newAuthProvider';
import {
  GET_MUSIC_PROFILES,
  GET_ZONES,
  GET_ZONES_WITH_ACTIVE_SUBSCRIPTION,
} from './api';
import { General } from './general';
import { GeneralManager } from './general/generalProvider';
import { ConfigureMusicProfileModal } from './modals';
import { ZoneProfileReminderModal } from './modals/zoneProfileReminderModal';
import PageHeader from './pageHeader';
import { TunifyBlue } from './tunifyBlue';
import { TunifyBlueManager } from './tunifyBlue/tunifyBlueProvider';
import { TunifyGreen } from './tunifyGreen';
import { TunifyGreenManager } from './tunifyGreen/tunifyGreenProvider';
import { DropdownOptionTypes, Tabs } from './types';

const StyledPage = styled(Page)`
  position: relative;
  padding-bottom: 1rem;
  display: grid;
  grid-template-rows: min-content min-content 1fr;
  min-height: calc(100vh - 5rem);
`;

const StyledErrorMessage = styled(ErrorMessage)`
  width: 100%;
  margin-bottom: 1rem;
`;

const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  padding-bottom: 2rem;
  height: fit-content;
`;

const StyledSelectCard = styled(SelectCard)`
  & > div {
    display: grid;
  }
`;

const CardLabel = styled.div`
  text-transform: uppercase;
  text-align: center;
  font-size: 0.875rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ isSelected }) => isSelected && `font-weight: 700;`}

  @media only screen and (min-width: ${({ theme }) =>
    theme.breakpoints.small}px) {
    font-size: 1rem;
  }
`;

const mapZonesWithActiveSubscriptionAndAddon = (data) =>
  data?.filter(
    (zone) =>
      !zone.musicProfile &&
      zone.currentSubscription.subscriptionLines.some(
        (line) =>
          line.product.isAddon &&
          line.product.categoryId === MUSIC_MANAGEMENT_ADD_ON_ID
      )
  );

export const MusicManagementPage = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const [isZoneProfileReminderModalOpen, toggleIsZoneProfileReminderModalOpen] =
    useToggle(false);
  const [isConfigureMusicProfileModalOpen, toggleConfigureMusicProfileModal] =
    useToggle(false);
  const [tab, setTab] = useState(Tabs.GENERAL);

  const [profileId, setProfileId, removeProfileId] = useLocalStorage(
    `${user.id}_musicManagementProfileId`,
    ''
  );
  const [profileType, setProfileType] = useLocalStorage(
    `${user.id}_musicManagementProfileType`,
    ''
  );

  const handleCloseZoneProfileReminderModal = useCallback(() => {
    toggleIsZoneProfileReminderModalOpen(false);
  }, [toggleIsZoneProfileReminderModalOpen]);

  const {
    loading: musicProfilesLoading,
    error: musicProfilesError,
    data: musicProfilesData,
  } = useQuery(GET_MUSIC_PROFILES, {
    variables: {
      defaultBrandName: t('config:broadcasts.defaultBrandName'),
    },
    onCompleted: (data) => {
      if (data?.musicProfiles?.length > 0) {
        setProfileId(data.musicProfiles[0].id);
        setProfileType(DropdownOptionTypes.PROFILE);
      }
    },
  });

  const {
    data: zonesData,
    loading: zonesLoading,
    error: zonesError,
  } = useQuery(GET_ZONES);

  const zonesWithoutProfile = useMemo(() => {
    const zones = zonesData?.zones.filter((zone) => !zone.musicProfile) ?? [];
    // if no profile is set set one of the zones
    if (zones.length > 0 && musicProfilesData?.musicProfiles?.length === 0) {
      setProfileId(zones[0].id);
      setProfileType(DropdownOptionTypes.ZONE);
    }
    return zones;
  }, [
    musicProfilesData?.musicProfiles?.length,
    setProfileId,
    setProfileType,
    zonesData?.zones,
  ]);

  const {
    data: zonesWithActiveSubscription,
    loading: loadingZonesWithActiveSubscription,
    error: errorZonesWithActiveSubscription,
    refetch: refetchZonesWithActiveSubscription,
  } = useQuery(GET_ZONES_WITH_ACTIVE_SUBSCRIPTION, {
    onCompleted: (data) => {
      const zonesWithActiveSubscriptionAndAddon =
        mapZonesWithActiveSubscriptionAndAddon(
          data?.zonesWithAnActiveSubscription
        );
      if (zonesWithActiveSubscriptionAndAddon?.length > 0) {
        toggleIsZoneProfileReminderModalOpen(true);
      }
    },
  });

  const zonesWithActiveSubscriptionAndAddon = useMemo(
    () =>
      mapZonesWithActiveSubscriptionAndAddon(
        zonesWithActiveSubscription?.zonesWithAnActiveSubscription
      ),
    [zonesWithActiveSubscription]
  );

  const isZoneSelected = useMemo(
    () => profileType === DropdownOptionTypes.ZONE,
    [profileType]
  );

  const selectedProfile = useMemo(() => {
    if (isZoneSelected) {
      return zonesWithoutProfile?.find((zone) => zone?.id === profileId);
    }
    if (!profileId) {
      return musicProfilesData?.musicProfiles?.[0];
    }
    return musicProfilesData?.musicProfiles?.find(
      (profile) => profile?.id === profileId
    );
  }, [
    isZoneSelected,
    musicProfilesData?.musicProfiles,
    zonesWithoutProfile,
    profileId,
  ]);

  const handleOnChangeOrganization = useCallback(
    (currentId, currentType) => {
      if (currentType === DropdownOptionTypes.ZONE) {
        setTab(Tabs.GENERAL);
      }
      setProfileType(currentType);
      setProfileId(currentId);
    },
    [setProfileId, setProfileType]
  );

  const handleConfigureMusicProfile = useCallback(() => {
    toggleConfigureMusicProfileModal(true);
  }, [toggleConfigureMusicProfileModal]);

  const handleCloseConfigureModal = useCallback(() => {
    toggleConfigureMusicProfileModal(false);
  }, [toggleConfigureMusicProfileModal]);

  const handleSetProfile = useCallback(
    (id) => {
      setProfileId(id);
      setProfileType(DropdownOptionTypes.PROFILE);
    },
    [setProfileId, setProfileType]
  );

  const handleSubmitProfileReminder = useCallback(() => {
    toggleIsZoneProfileReminderModalOpen(false);
    toggleConfigureMusicProfileModal(true);
  }, [toggleConfigureMusicProfileModal, toggleIsZoneProfileReminderModalOpen]);

  const profiles = useMemo(
    () => musicProfilesData?.musicProfiles ?? [],
    [musicProfilesData?.musicProfiles]
  );

  const renderTabSwitch = useCallback(
    (tabValue) => {
      switch (tabValue) {
        case Tabs.GENERAL:
          return (
            <GeneralManager profile={selectedProfile}>
              <General
                refetchZonesWithActiveSubscription={
                  refetchZonesWithActiveSubscription
                }
                selectedType={profileType}
                zonesWithActiveSubscription={
                  zonesWithActiveSubscription?.zonesWithAnActiveSubscription
                }
              />
            </GeneralManager>
          );
        case Tabs.TUNIFY_GREEN:
          return (
            <TunifyGreenManager profile={selectedProfile}>
              <TunifyGreen />
            </TunifyGreenManager>
          );
        case Tabs.TUNIFY_BLUE:
          return (
            <TunifyBlueManager profile={selectedProfile}>
              <TunifyBlue />
            </TunifyBlueManager>
          );
        default:
          return null;
      }
    },
    [
      profileType,
      selectedProfile,
      zonesWithActiveSubscription,
      refetchZonesWithActiveSubscription,
    ]
  );

  return (
    <StyledPage title={t('musicManagement:title')}>
      {zonesError && <StyledErrorMessage error={zonesError} />}
      {musicProfilesError && <StyledErrorMessage error={musicProfilesError} />}
      {musicProfilesLoading || zonesLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          {(musicProfilesData?.musicProfiles?.length > 0 ||
            zonesWithoutProfile.length > 0) && (
            <PageHeader
              onChangeProfile={handleOnChangeOrganization}
              onConfigureMusicProfile={handleConfigureMusicProfile}
              profile={selectedProfile}
              profiles={profiles}
              profileType={profileType}
              removeProfileId={removeProfileId}
              setProfileId={setProfileId}
              setProfileType={setProfileType}
              zones={zonesData?.zones}
              zonesWithAnActiveSubscription={
                zonesWithActiveSubscription?.zonesWithAnActiveSubscription
              }
              zonesWithoutProfile={zonesWithoutProfile}
            />
          )}
          {musicProfilesData?.musicProfiles?.length > 0 ||
          zonesWithoutProfile.length > 0 ? (
            <CardsWrapper>
              <StyledSelectCard
                activeSelectCardId={tab}
                id={Tabs.GENERAL}
                onClick={setTab}
              >
                <CardLabel isSelected={tab === Tabs.GENERAL}>
                  {t('musicManagement:general.subTitle')}
                </CardLabel>
              </StyledSelectCard>
              <StyledSelectCard
                activeSelectCardId={tab}
                id={Tabs.TUNIFY_GREEN}
                isDisabled={isZoneSelected}
                onClick={setTab}
              >
                <CardLabel isSelected={tab === Tabs.TUNIFY_GREEN}>
                  {t('musicManagement:tunifyGreen.subTitle')}
                </CardLabel>
              </StyledSelectCard>
              <StyledSelectCard
                activeSelectCardId={tab}
                id={Tabs.TUNIFY_BLUE}
                isDisabled={isZoneSelected}
                onClick={setTab}
              >
                <CardLabel isSelected={tab === Tabs.TUNIFY_BLUE}>
                  {t('musicManagement:tunifyBlue.subTitle')}
                </CardLabel>
              </StyledSelectCard>
              {/* Uncomment when component with api is ready */}
              {/* <StyledSelectCard
                activeSelectCardId={tab}
                id={Tabs.THEME_MUSIC}
                isDisabled={isZoneSelected}
                onClick={setTab}
              >
                <CardLabel isSelected={tab === Tabs.THEME_MUSIC}>{t('musicManagement:themeMusic.subTitle')}</CardLabel>
              </StyledSelectCard> */}
            </CardsWrapper>
          ) : null}
          {(!musicProfilesError || !zonesError) &&
          (!musicProfilesLoading || !zonesLoading) &&
          (musicProfilesData?.musicProfiles?.length > 0 ||
            zonesWithoutProfile.length > 0) ? (
            <>{renderTabSwitch(tab)}</>
          ) : null}
          {isZoneProfileReminderModalOpen && (
            <ZoneProfileReminderModal
              errorMusicProfiles={musicProfilesError}
              errorZones={errorZonesWithActiveSubscription}
              isOpen={isZoneProfileReminderModalOpen}
              loadingMusicProfiles={musicProfilesLoading}
              loadingZones={loadingZonesWithActiveSubscription}
              musicProfiles={musicProfilesData?.musicProfiles}
              onClose={handleCloseZoneProfileReminderModal}
              onSubmit={handleSubmitProfileReminder}
              setProfileId={setProfileId}
              zones={zonesWithActiveSubscriptionAndAddon}
            />
          )}
          {isConfigureMusicProfileModalOpen && (
            <ConfigureMusicProfileModal
              isOpen={isConfigureMusicProfileModalOpen}
              onClose={handleCloseConfigureModal}
              profile={selectedProfile}
              setProfile={handleSetProfile}
            />
          )}
        </>
      )}
    </StyledPage>
  );
};
