import { Fragment, useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import theme from '../../../global/style/theme';
import { ReactComponent as SmallCheckmark } from '../../../assets/icons/smallCheckmark.svg';
import { ReactComponent as AngleDown } from '../../../assets/icons/angleDown.svg';
import { ReactComponent as AngleUp } from '../../../assets/icons/angleUp.svg';

const Container = styled.div`
  display: none;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    margin-top: 2rem;
    width: 100%;
    border: 1px solid ${({ theme }) => theme.stroke};
    border-radius: 4px;
    padding: 2.4rem;
    display: grid;
    gap: 2rem;
  }
`;

const Title = styled.h4`
  font-size: 1rem;
  color: ${({ theme }) => theme.dark300};
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.18rem;
`;

const StepContainer = styled.div`
  display: grid;
`;

const Step = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  gap: 1rem;
`;

const StepIcon = styled.div`
  width: 3.6rem;
  height: 3.6rem;
  border: 2px solid
    ${({ theme, $done, $current }) => {
      if ($done) return theme.plantGreen;
      if ($current) return theme.waterBlue;
      return theme.stroke;
    }};
  background-color: ${({ theme, $current }) =>
    $current ? theme.waterBlue : 'transparent'};
  border-radius: 50%;
  display: grid;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  & > span {
    font-size: 1.4rem;
    font-weight: 600;
    color: ${({ theme, $current }) => ($current ? theme.white : theme.dark400)};
  }
`;

const Connection = styled.div`
  height: 1.6rem;
  width: 2px;
  background-color: ${({ theme }) => theme.stroke};
  margin-left: calc(1.8rem - 1px);
`;

const StepInfo = styled.div`
  display: grid;
`;

const Subtitle = styled.div`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.dark300};
  line-height: 1.2;
`;

const Text = styled.div`
  font-size: 1.4rem;
  line-height: 1.2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const MobileContainer = styled.div`
  width: calc(100% + 3.2rem);
  padding: 0 1.6rem;
  display: grid;
  gap: 2rem;
  border-top: 1px solid ${({ theme }) => theme.stroke};
  margin: 0 -1.6rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    width: calc(100% + 6.4rem);
    padding: 0 3.2rem;
    margin: 0 -3.2rem;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    display: none;
  }
`;

const MobileCardHeader = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  align-items: center;
  gap: 1rem;
  padding-top: 1.6rem;
  background-color: ${({ theme }) => theme.white};
  cursor: pointer;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    padding-top: 3.2rem;
  }
`;

const SectionLabel = styled.h2`
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.5;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    font-size: 1.8rem;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    font-size: 2.4rem;
  }
`;

const StepLabel = styled.p`
  width: min-content;
  background-color: ${({ theme }) => theme.waterBlue};
  color: ${({ theme }) => theme.white};
  font-size: 1.2rem;
  line-height: 1.4;
  padding: 0.4rem 0.8rem;
  border-radius: 4px;
  white-space: nowrap;
`;

const StepOverview = ({ stepsInfo, currentStep }) => {
  const { t } = useTranslation();
  const [isContentVisible, setContentVisible] = useState(false);

  const stepContent = useMemo(
    () => (
      <StepContainer>
        {stepsInfo.map((step, index) => (
          <Fragment key={index}>
            {index !== 0 ? <Connection /> : null}
            <Step>
              <StepIcon
                $done={index < currentStep}
                $current={index === currentStep}
              >
                {index < currentStep ? (
                  <SmallCheckmark
                    color={theme.plantGreen}
                    width="1.1rem"
                    height="1.1rem"
                  />
                ) : (
                  <span>{index + 1}</span>
                )}
              </StepIcon>
              <StepInfo>
                {step.selection ? (
                  <>
                    <Subtitle>{step.shortTitle}</Subtitle>
                    <Text>{step.selection}</Text>
                  </>
                ) : (
                  <Text>{step.shortTitle}</Text>
                )}
              </StepInfo>
            </Step>
          </Fragment>
        ))}
      </StepContainer>
    ),
    [currentStep, stepsInfo]
  );

  const toggleContent = useCallback(() => {
    setContentVisible((prev) => !prev);
  }, []);

  return (
    <>
      <MobileContainer>
        <MobileCardHeader onClick={toggleContent}>
          {isContentVisible ? (
            <>
              <Title>{t('subscriptions:purchaseSteps')}</Title>
              <div />
              <AngleUp width="1.2rem" height="1.2rem" color={theme.dark300} />
            </>
          ) : (
            <>
              <StepLabel>
                {t('subscriptions:stepLabel', {
                  currentStep: currentStep + 1,
                  totalSteps: stepsInfo.length,
                })}
              </StepLabel>
              <SectionLabel>{stepsInfo[currentStep].title}</SectionLabel>
              <AngleDown width="1.2rem" height="1.2rem" color={theme.dark300} />
            </>
          )}
        </MobileCardHeader>
        {isContentVisible ? stepContent : null}
      </MobileContainer>
      <Container>
        <Title>{t('subscriptions:purchaseSteps')}</Title>
        {stepContent}
      </Container>
    </>
  );
};

export default StepOverview;
