import { useCallback, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Pie } from '@nivo/pie';

const PieChart = ({ data, colors }) => {
  const [margin] = useState({ top: 0, right: 0, bottom: 0, left: 0 });

  const handleResizeChart = useCallback(({ width }) => {}, []);

  return (
    <AutoSizer onResize={handleResizeChart}>
      {({ height, width }) => (
        <Pie
          colors={colors}
          data={data}
          width={width}
          height={height}
          innerRadius={0.6}
          layers={['arcs', 'radialLabels']}
          margin={margin}
          symbolSize={12}
          theme={{
            fontFamily: 'Inter',
            fontSize: 12,
          }}
        />
      )}
    </AutoSizer>
  );
};

export default PieChart;
