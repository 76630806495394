import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';

import { useToggle, useMediaSize } from '../../../common';
import { usePlaylists } from './playlistsProvider';
import { ReactComponent as AngleRight } from '../../../assets/icons/angleRight.svg';
import { GET_CUSTOM_PLAYLISTS_OF_ZONE } from './api';
import { ReactComponent as ZoneLargeIcon } from '../../../assets/icons/zoneLarge.svg';

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-template-rows: 1fr min-content min-content;
  align-items: center;
  column-gap: 0.6rem;
  cursor: pointer;
  padding: 1.4rem;
  background-color: ${({ theme }) => theme.white};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.stroke};

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    grid-template-columns: 2fr 1fr min-content;
    grid-template-rows: unset;
  }
`;

const Divider = styled.div`
  grid-column: span 2;
  margin: 0.6rem 0;
  width: 100%;
  height: 0.1rem;
  background-color: ${({ theme }) => theme.stroke};
`;

const Name = styled.div`
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: min-content 1fr;
  align-items: center;
`;

const NameWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & p {
    word-break: break-all;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.4;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    display: flex;
    align-items: flex-end;
  }
`;

const OrganizationLocationName = styled.span`
  font-size: 1.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.dark300};
  line-height: 1.6;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    grid-column: 2;
    padding-left: 1rem;
  }
`;

const CustomListCount = styled(OrganizationLocationName)``;

const ZoneRow = ({ zone, onClick }) => {
  const { t } = useTranslation();
  const { isNarrowerThanMedium } = useMediaSize();
  const handleClickZone = useCallback(() => onClick(zone), [onClick, zone]);

  const { data: dataZoneCustomPlaylists } = useQuery(
    GET_CUSTOM_PLAYLISTS_OF_ZONE,
    {
      variables: {
        zoneId: zone.id,
      },
    }
  );

  return (
    <Container onClick={handleClickZone}>
      <Name>
        <ZoneLargeIcon />{' '}
        <NameWrapper>
          <p>{zone.name}</p>{' '}
          <OrganizationLocationName>{`${zone.organizationName} - ${zone.location.name}`}</OrganizationLocationName>
        </NameWrapper>
      </Name>
      {!isNarrowerThanMedium ? (
        <CustomListCount>
          {t('musicManagement:playlists.countCustomPlaylists', {
            playlistCount:
              dataZoneCustomPlaylists?.customPlaylistsOfZone?.customPlaylists
                .length ?? 0,
          })}
        </CustomListCount>
      ) : null}
      <div>
        <AngleRight width="1.6rem" height="1.6rem" />
      </div>
      {isNarrowerThanMedium ? (
        <>
          <Divider />
          <CustomListCount>
            {t('musicManagement:playlists.countCustomPlaylists', {
              playlistCount:
                dataZoneCustomPlaylists?.customPlaylistsOfZone?.customPlaylists
                  .length ?? 0,
            })}
          </CustomListCount>
        </>
      ) : null}
    </Container>
  );
};

const ZoneSelection = ({ onConfirm }) => {
  const { dispatch, zones } = usePlaylists();

  const [zoneSelectionModalIsOpen, toggleZoneSelectionModal] = useToggle();

  const handleChangeSelectedZone = useCallback(
    (zone) => {
      dispatch({
        selectedZone: zone,
        selectedPlaylist: null,
      });
      onConfirm();
      if (zoneSelectionModalIsOpen) {
        toggleZoneSelectionModal();
      }
    },
    [dispatch, toggleZoneSelectionModal, zoneSelectionModalIsOpen, onConfirm]
  );

  const partialZones = useMemo(
    () =>
      zones?.map((zone) => ({
        ...zone,
        organizationName: zone.organizationName,
        locationName: zone.location.name,
      })),
    [zones]
  );

  return (
    <>
      {partialZones?.map((zone) => (
        <ZoneRow key={zone.id} onClick={handleChangeSelectedZone} zone={zone} />
      ))}
    </>
  );
};

export default ZoneSelection;
