import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import { GridTable, useMediaSize } from '../../../common';
import { usePartner } from '../partnerContext';
import QualifierTableRow from './qualifierTableRow';
import { LIMIT } from '../partnerProvider';

const Header = styled(GridTable.Header)`
  border-bottom: 1px solid ${({ theme }) => theme.stroke};
`;

const Head = styled(GridTable.Head)`
  color: ${({ theme }) => theme.dark500};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.143;
`;

const QualifiersTable = () => {
  const { t } = useTranslation();
  const {
    partnerQualifierCustomers,
    partnerQualifierCustomersLoading,
    partnerQualifierCustomersError,
    hasMoreQualifierCustomers,
    fetchMoreQualifierCustomers,
  } = usePartner();

  const { isWiderThanLarge } = useMediaSize();

  const [infiniteRef] = useInfiniteScroll({
    loading: partnerQualifierCustomersLoading,
    // Due to refetch we cannot know if there is more after the initial query,
    // we have to additionally check for this
    hasNextPage: partnerQualifierCustomers?.length >= LIMIT && hasMoreQualifierCustomers,
    onLoadMore: fetchMoreQualifierCustomers,
  });

  return (
    <GridTable ref={infiniteRef}>
      <div>
        {isWiderThanLarge && (
          <Header templateColumns="repeat(5, 1fr)">
            <Head>{t('partner:registrations.tableHeader.registerDate')}</Head>
            <Head>{t('partner:registrations.tableHeader.name')}</Head>
            <Head>{t('partner:registrations.tableHeader.subscription')}</Head>
            <Head>{t('partner:registrations.tableHeader.endDate')}</Head>
            <Head>{t('partner:registrations.tableHeader.numberOfMonths')}</Head>
          </Header>
        )}
        {(!partnerQualifierCustomers || partnerQualifierCustomers?.length === 0) &&
          !partnerQualifierCustomersLoading && (
            <GridTable.Message>{t('partner:registrations.table.noQualifierCustomers')}</GridTable.Message>
          )}
        {partnerQualifierCustomers?.map((qualifierCustomer) => (
          <QualifierTableRow key={qualifierCustomer.id} qualifierCustomer={qualifierCustomer} />
        ))}
        <GridTable.LoadingIndicator isLoading={partnerQualifierCustomersLoading} />
        <GridTable.ErrorMessage error={partnerQualifierCustomersError} />
      </div>
    </GridTable>
  );
};

export default QualifiersTable;
