/**
 * Returns `true` if all of the `requiredPermissions` is included in the `userInfo.permissions`.
 *
 * @param {object} userInfo - The given current user info
 * @param {string[]} requiredPermissions - All the permissions that are required
 */
export const hasAllRequiredPermissions = (userInfo, requiredPermissions) => {
  if (!userInfo) return false;
  const { permissions } = userInfo;
  if (!permissions) return false;
  return requiredPermissions.every((permission) =>
    permissions.includes(permission)
  );
};
