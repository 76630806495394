import { useMutation } from '@apollo/react-hooks';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router';
import styled from 'styled-components';
import packageJson from '../../../package.json';
import { SEND_PAGE_UPDATE } from '../../api';
import config from '../../config';
import { useAuth } from '../../global/auth/newAuthProvider';
import MobileTopBar from '../../modules/mobileTopBar';
import { SideBar } from '../../modules/sideBar';
import { ProfileModal } from '../_common/profileModal';
import { useQuery } from '@tanstack/react-query';
import { fetchCountryInfoFromIpService } from '../../common';

const Container = styled.div`
  height: 100vh;
  @supports (height: 100svh) {
    height: 100svh;
  }
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 7rem 1fr;
  overflow: hidden;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    grid-template-columns: 8rem 1fr;
    grid-template-rows: unset;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    grid-template-columns: 25rem 1fr;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.superLarge}px) {
    grid-template-columns: 32rem 1fr;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.ultraLarge}px) {
    grid-template-columns: 40rem 1fr;
  }
`;

const MainContainer = styled.div`
  overflow-y: auto;
  display: grid;
`;

export const AppEntryPoint = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { user } = useAuth();

  // TODO; refactor this mess
  const [isNotificationsOpen, setNotificationsOpen] = React.useState(false);
  const [hasNotifications, setHasNotifications] = React.useState(false);
  const [isSideBarDrawerOpen, setSideBarDrawerOpen] = React.useState(false);

  const [isRegistrationModalOpen, setIsRegistrationModalOpen] = React.useState(
    !user.isRegistrationComplete
  );

  const closeSideBarDrawer = React.useCallback(() => {
    setSideBarDrawerOpen(false);
  }, []);

  const [sendPageUpdate] = useMutation(SEND_PAGE_UPDATE);

  const { data: countryInfo } = useQuery(['viewer_country_code'], async () => {
    return await fetchCountryInfoFromIpService();
  });

  React.useEffect(() => {
    // Important only use mutation when auth session is set otherwise logout loop will be created because of 'makeRefreshLink'
    if (location?.pathname && user?.emailAddress && countryInfo) {
      sendPageUpdate({
        variables: {
          eventUrl: window.location.href,
          email: user.emailAddress.toLowerCase(),
          country: countryInfo.country_code.toLowerCase(),
          ip: countryInfo.ip,
          agent: window.navigator.userAgent,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryInfo, location]);

  return (
    <>
      <Helmet>
        <meta content={t('global:site.description')} name="description" />
        <meta
          content={t('global:site.description')}
          property="og:description"
        />
        <meta content={config.BASE_URL} property="og:url" />
        <meta content="website" property="og:type" />
        <meta content="MyTunify | Tunify" property="og:title" />
        <meta content={packageJson.version} name="version" />
      </Helmet>
      <Container $appVersion={config.SHOW_APP_VERSION}>
        <SideBar
          isNotificationsOpen={isNotificationsOpen}
          setNotificationsOpen={setNotificationsOpen}
          hasNotifications={hasNotifications}
          setHasNotifications={setHasNotifications}
          isSideBarDrawerOpen={isSideBarDrawerOpen}
          onNavigationClick={closeSideBarDrawer}
        />
        <MobileTopBar
          setNotificationsOpen={setNotificationsOpen}
          hasNotifications={hasNotifications}
          isSideBarDrawerOpen={isSideBarDrawerOpen}
          setSideBarDrawerOpen={setSideBarDrawerOpen}
          isNotificationsOpen={isNotificationsOpen}
        />
        <MainContainer>
          <Outlet context={{ hasNotifications, setNotificationsOpen }} />
        </MainContainer>
      </Container>
      {isRegistrationModalOpen && (
        <ProfileModal
          isOpen={isRegistrationModalOpen}
          onClose={() => setIsRegistrationModalOpen(false)}
        />
      )}
    </>
  );
};
