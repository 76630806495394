import { getHours, getMinutes, set } from 'date-fns';
import { de, enUS, fr, nlBE } from 'date-fns/locale';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import '../common/datePicker.css';
import ErrorMessage from '../common/errorMessage';
import Label from '../common/label';

const languagesObject = {
  'nl-be': nlBE,
  'nl-nl': nlBE,
  'en-us': enUS,
  'fr-be': fr,
  'fr-fr': fr,
  'de-de': de,
};

const Container = styled.div`
  /* ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.5;
        `
      : ''} */
`;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  height: 4.8rem;
  outline: none;
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.stroke};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  line-height: 1.2;
  padding: 0 1.6rem;
  color: ${({ theme }) => theme.dark500};
  background-color: transparent;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out,
    color 0.2s ease-in-out;

  background: ${({ theme }) =>
    `linear-gradient(0deg, ${theme.gradient['fade'][0]} 0%,  ${theme.gradient['fade'][1]} 100%)`};

  &:hover {
    border-color: ${({ theme }) => theme.dark300};
  }

  &:active,
  :focus {
    border-color: ${({ theme }) => theme.waterBlue};
  }

  &::placeholder {
    transition: color 0.2s ease-in-out;
    color: ${({ theme }) => theme.dark300};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      color: ${({ theme }) => theme.dark100};
      border-color: ${({ theme }) => theme.stroke};

      &:hover {
        border-color: ${({ theme }) => theme.stroke};
      }

      &::placeholder {
        color: ${({ theme }) => theme.dark100};
      }
    `}
`;

const TimeField = ({
  name,
  error,
  control,
  label,
  placeholder,
  className,
  disabled,
  withColon,
  ...props
}) => {
  const { i18n } = useTranslation();
  registerLocale(i18n.language, languagesObject[i18n.language.toLowerCase()]);

  return (
    <Container className={className} disabled={disabled}>
      {label ? (
        <Label htmlFor={name} disabled={disabled}>
          {label}
        </Label>
      ) : null}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value, ...rest } }) => {
          let updatedValue;
          // Value may be a `Date` object, a string of format `HHmm`, a string of format `HH:mm`, `null` or `undefined`.
          if (value instanceof Date) {
            updatedValue = value;
          } else {
            // At this point, we expect the hours to be given in a "hh:mm" format.
            updatedValue = set(new Date(), {
              hours: Number(value?.slice(0, 2) || 0),
              minutes: Number(value?.replace(':', '')?.slice(2, 4) || 0),
              seconds: 0,
            });
          }

          const onChangeCb = (value) => {
            onChange(
              `${getHours(value).toString().padStart(2, '0')}${withColon ? ':' : ''}${getMinutes(value)
                .toString()
                .padStart(2, '0')}`
            );
          };

          return (
            <StyledDatePicker
              {...(!disabled ? { onChange: onChangeCb } : {})}
              selected={updatedValue}
              timeFormat="HH:mm"
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={30}
              timeCaption="time"
              dateFormat="HH:mm"
              locale={i18n.language}
              placeholderText={placeholder}
              disabled={disabled}
              {...rest}
              {...props}
            />
          );
        }}
      />
      {typeof error === 'string' ? <ErrorMessage>{error}</ErrorMessage> : null}
    </Container>
  );
};

export default TimeField;
