import { useMemo } from 'react';
import styled, { css } from 'styled-components';

import { capitalize } from '..';
import LoadingIndicator from '../loadingIndicator';

import buttonTypes from './buttonTypes';
import sizes from './sizes';

const Container = styled.a`
  width: fit-content;
  font-family: 'Inter', sans-serif;
  color: ${({ theme }) => theme.dark500};
  text-decoration: none;
  ${({ $size, $emphasis }) => sizes[$emphasis][$size]}
  position: relative;
  z-index: 1;
  transition: 0.2s color ease-in-out, 0.2s background-color ease-in-out;

  ${({ $emphasis }) => buttonTypes[$emphasis]}

  ${({ $shouldCapitalizeText }) =>
    $shouldCapitalizeText &&
    css`
      text-transform: capitalize;
    `}
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  transform: scale(0.8);
`;

const LinkButton = ({
  emphasis = 'secondary',
  highlightColor = 'waterBlue',
  highlightGradient,
  size = 'large',
  onClick,
  isLoading = false,
  isDisabled = false,
  className,
  children,
  shouldCapitalizeText = false,
  title = '',
  href,
}) => {
  const memoizedIsDisabled = useMemo(
    () => isDisabled || isLoading,
    [isDisabled, isLoading]
  );

  return (
    <Container
      $emphasis={emphasis}
      $highlightColor={highlightColor}
      $highlightGradient={highlightGradient}
      $shouldCapitalizeText={shouldCapitalizeText}
      $size={size}
      className={className}
      disabled={memoizedIsDisabled}
      onClick={!isLoading ? onClick : undefined}
      title={capitalize(title)}
      href={href}
      rel="noopener noreferrer"
      target="_blank"
    >
      {!isLoading ? (
        <>{typeof children === 'string' ? <p>{children}</p> : children}</>
      ) : (
        <StyledLoadingIndicator size={size} thickness="thin" color="white" />
      )}
    </Container>
  );
};

export default LinkButton;
