import gql from 'graphql-tag';

const GET_CUSTOMER_CODE = gql`
  query getCustomerCode {
    memberGetMemberCustomerCode
  }
`;

const GET_CUSTOMER_PROGRAM = gql`
  query getCustomerProgram($customerCode: String!) {
    customerProgram(customerCode: $customerCode) {
      commercialProductId
      commercialProductRecurringInterval
    }
  }
`;

const GET_QUALIFIER_CUSTOMERS = gql`
  query getQualifierCustomers($limit: Int!, $offset: Int!) {
    qualifierCustomers(limit: $limit, offset: $offset) {
      id
      registrationDate
      fullName
      locationName
      locationCity
    }
  }
`;

const GET_WINNER_CUSTOMERS = gql`
  query getWinnerCustomers($limit: Int!, $offset: Int!) {
    winnerCustomers(limit: $limit, offset: $offset) @connection(key: "id") {
      id
      date
      reward
      fullName
      locationName
      locationCity
    }
  }
`;

export const SEND_INVITATION_EMAIL = gql`
  mutation sendInvitationEmail($senderId: ID!, $senderLang: String!, $emailAdresses: [String!]!) {
    sendInvitationEmail(senderId: $senderId, senderLang: $senderLang, emailAdresses: $emailAdresses)
  }
`;

export { GET_CUSTOMER_CODE, GET_WINNER_CUSTOMERS, GET_QUALIFIER_CUSTOMERS, GET_CUSTOMER_PROGRAM };
