import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import shortid from 'shortid';
import { useFieldArray } from 'react-hook-form';

import { capitalize } from '../stringUtil';
import TimeField from '../fields/timeField';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { BasicButton } from '../../common';
import theme from '../../global/style/theme';
import ErrorMessage from '../fields/common/errorMessage';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:first-child) {
    margin-top: 1rem;
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 3rem 7.6rem 7.6rem min-content;
  column-gap: 1rem;
  align-items: center;
`;

const Label = styled.p`
  font-size: 1.4rem;
  line-height: 1.4;
`;

const StyledBasicButton = styled(BasicButton)`
  padding: 1rem;
`;

const StyledAddIcon = styled(CloseIcon)`
  transform: rotate(45deg);
`;

const StyledErrorMessage = styled(ErrorMessage)`
  grid-column: 2 / span 4;
`;

const DayOpeningHours = ({ control, errors, day }) => {
  const { t } = useTranslation();

  const currentDayIndex = day - 1;

  const { fields, append, remove } = useFieldArray({
    control,
    name: `openingHours.openingDays[${currentDayIndex}].openingPeriods`,
  });

  return (
    <Container>
      {fields.map((dayOpeningHours, index) => {
        const key = `openingHours.openingDays[${currentDayIndex}].openingPeriods[${index}]`;

        const error = Array.isArray(
          errors?.openingHours?.openingDays?.[currentDayIndex]?.openingPeriods
        )
          ? errors?.openingHours?.openingDays?.[currentDayIndex]
              ?.openingPeriods?.[index]?.message
          : errors?.openingHours?.openingDays?.[currentDayIndex]?.openingPeriods
              ?.message;

        return (
          <Row key={dayOpeningHours.id}>
            <Label>
              {index === 0 ? t(`glossary:day${day}`).slice(0, 2) : ''}
            </Label>
            <TimeField
              control={control}
              name={`${key}.open`}
              placeholder="00:00"
            />
            <TimeField
              control={control}
              name={`${key}.close`}
              placeholder="00:00"
            />
            {index === 0 ? (
              <StyledBasicButton
                type="button"
                onClick={() =>
                  append({
                    open: null,
                    close: null,
                    id: shortid.generate(),
                  })
                }
                title={capitalize(
                  t('common:button.title.add', {
                    value: t('common:button.title.zone'),
                  })
                )}
              >
                <StyledAddIcon
                  width="1.2rem"
                  height="1.2rem"
                  color={theme.dark200}
                />
              </StyledBasicButton>
            ) : (
              <StyledBasicButton type="button" onClick={() => remove(index)}>
                <CloseIcon
                  width="1.3rem"
                  height="1.3rem"
                  color={theme.dark200}
                />
              </StyledBasicButton>
            )}
            {typeof error === 'string' ? (
              <StyledErrorMessage>{error}</StyledErrorMessage>
            ) : null}
          </Row>
        );
      })}
    </Container>
  );
};

export default DayOpeningHours;
