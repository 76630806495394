export function partitionArray(array, getElementPartitionLabel) {
  const result = {};
  for (const element of array) {
    const label = getElementPartitionLabel(element);
    if (!(label in result)) {
      result[label] = [];
    }
    result[label].push(element);
  }
  return result;
}