import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  ErrorMessage,
  LoadingIndicator,
  CheckboxField,
} from '../../../../../../common';
import { Button } from '../../../../../../common/oldButton';
import ZoneRow from './zoneRow';

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;

  & > :not(:first-child) {
    margin-left: 1rem;
  }
`;

const Instructions = styled.p`
  margin: 0 1rem 1rem 1rem;
`;

const ScrollableBody = styled.div`
  margin: 0 -1rem;
  overflow-y: scroll;
  max-height: calc(100vh - 15rem);

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    max-height: calc(100vh - 11rem);
  }
`;

const Wrapper = styled.div`
  min-width: 3rem;
  display: flex;
  justify-content: center;
`;

const TableHeader = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.dark300};
  user-select: none;
  cursor: pointer;
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  padding: 1rem;
  align-items: center;
  column-gap: 1rem;
`;

const NoZonesMessage = styled.p`
  margin: 1rem 1rem 2rem 1rem;
`;

const Container = styled.div`
  margin: 0;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin: 0 -1rem;
  width: 100%;
`;

const ZoneSelectionModal = ({
  zones = [],
  selectedZones,
  setSelectedZones,
  goBack,
  next,
  error,
  loading,
  loadingCreateMusicProfile,
}) => {
  const { t } = useTranslation();

  const handleConfirm = useCallback(() => {
    next();
  }, [next]);

  const toggleAllZonesCheckBoxes = useCallback(() => {
    setSelectedZones((prev) =>
      prev.length !== zones.length || prev.length === 0 ? zones : []
    );
  }, [zones, setSelectedZones]);

  const handleClickZone = useCallback(
    (id) => {
      setSelectedZones((prev) =>
        prev.find((zone) => zone.id === id)
          ? prev.filter((zone) => zone.id !== id)
          : [...prev, zones.find((zone) => zone.id === id)]
      );
    },
    [zones, setSelectedZones]
  );

  const isChecked = useMemo(
    () => zones.length > 0 && selectedZones.length === zones.length,
    [selectedZones, zones]
  );

  return (
    <>
      <ScrollableBody>
        {zones && zones.length > 0 && (
          <Instructions>
            {t('musicManagement:addMusicProfile.zonesInfo')}
          </Instructions>
        )}
        {error ? <StyledErrorMessage error={error} /> : null}
        {loading ? (
          <LoadingIndicator />
        ) : (
          <>
            {zones.length > 0 ? (
              <>
                <Header>
                  <Wrapper>
                    <CheckboxField
                      onChange={toggleAllZonesCheckBoxes}
                      checked={isChecked}
                    />
                  </Wrapper>
                  <TableHeader onClick={toggleAllZonesCheckBoxes}>
                    {t('integrations:spotify.import.thAllZones')}
                  </TableHeader>
                </Header>
                <Container>
                  {zones.map((zone) => (
                    <ZoneRow
                      key={zone.id}
                      isMulti
                      onClickZone={handleClickZone}
                      selected={selectedZones}
                      zone={zone}
                    />
                  ))}
                </Container>
              </>
            ) : (
              <NoZonesMessage>
                {t('musicManagement:addMusicProfile.noZonesInfo')}
              </NoZonesMessage>
            )}
          </>
        )}
      </ScrollableBody>
      <Footer>
        <Button
          emphasis="tertiary"
          flavor="link"
          onClick={goBack}
          title={t('common:button.title.cancel')}
        >
          {t('common:button.back')}
        </Button>
        <Button
          isDisabled={selectedZones.length === 0}
          isLoading={loadingCreateMusicProfile}
          onClick={handleConfirm}
        >
          {t('common:button.confirm')}
        </Button>
      </Footer>
    </>
  );
};

export default ZoneSelectionModal;
