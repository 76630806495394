import { useMemo, useState } from 'react';

import { GlobalStateContext } from './stateContext';

const GlobalStateProvider = ({ children }) => {
  const [newLead, setNewLead] = useState({});

  const [logContact, setLogContact] = useState({});

  const [shouldScrollMore, setShouldScrollMore] = useState(true);

  const contextValue = useMemo(
    () => ({
      newLead,
      setNewLead,
      logContact,
      setLogContact,
      shouldScrollMore,
      setShouldScrollMore,
    }),
    [newLead, setNewLead, logContact, setLogContact, shouldScrollMore, setShouldScrollMore]
  );

  return <GlobalStateContext.Provider value={contextValue}>{children}</GlobalStateContext.Provider>;
};

export default GlobalStateProvider;
