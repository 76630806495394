import { Route } from 'react-router';
import { AuthGuardPermissions } from '../../../global/auth/authGuardPermissions';
import { OWNER_ROLE_PERMISSIONS } from '../../../global/auth';
import { lazy } from 'react';

const MusicReportPage = lazy(() =>
  import('../../../modules/musicManagement/musicReport')
);

export const MusicReportRouter = [
  <Route
    index
    element={
      <AuthGuardPermissions permissions={OWNER_ROLE_PERMISSIONS}>
        <MusicReportPage />
      </AuthGuardPermissions>
    }
  />,
];
