import gql from 'graphql-tag';

const CREATE_CONTACT_CALL = gql`
  mutation createContactCall(
    $contactCall: CreateContactCallInput!
    $hasFutureContactCall: Boolean!
    $futureContactCall: CreateContactCallInput!
  ) {
    closedContactCall: createContactCall(contactCall: $contactCall)
    openContactCall: createContactCall(contactCall: $futureContactCall)
      @include(if: $hasFutureContactCall)
  }
`;

const GET_CONTACT_CATEGORIES_AND_CHANNELS = gql`
  query getContactCategoriesAndChannels {
    contactCategories {
      id
      name
    }
    contactChannels {
      id
      name
    }
  }
`;

const GET_ORGANIZATIONS_AND_LOCATIONS_OF_USER = gql`
  query getOrganizationsAndLocationDetailsOfUser($userId: ID!) {
    organizationsOfUser(userId: $userId) {
      id
      name
      locations {
        id
        name
      }
    }
  }
`;

export {
  CREATE_CONTACT_CALL,
  GET_CONTACT_CATEGORIES_AND_CHANNELS,
  GET_ORGANIZATIONS_AND_LOCATIONS_OF_USER,
};
