import theme from '../../global/style/theme';

export const getTunifyProductColor = (color) => {
  switch (color.toLowerCase()) {
    case 'green':
      return theme.plantGreen;
    case 'orange':
      return theme.grapefruitRed;
    case 'blue':
      return theme.berryBlue;
    default:
      return theme.dark500;
  }
};

export const getTunifyProductGradient = (color) => {
  switch (color.toLowerCase()) {
    case 'green':
      return 'blooming';
    case 'orange':
      return 'sunset';
    case 'blue':
      return 'clearsky';
    default:
      return 'nightfall';
  }
};
