import { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: grid;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.stroke};
  grid-gap: 1rem;
  align-items: center;
  padding: 1.6rem;
  min-height: 4rem;

  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 1rem;
  grid-template-areas:
    'invoiceType invoiceType price'
    'paymentType paymentType paymentType'
    'name description description';

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 1rem;
    grid-template-areas: 'invoiceType paymentType name description price';
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    border-radius: 0;
    border: unset;
    border-top: 1px solid ${({ theme }) => theme.stroke};
  }
`;

const InvoiceType = styled.p`
  grid-area: invoiceType;

  color: ${({ theme }) => theme.dark400};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.143;
`;

const PaymentType = styled.p`
  grid-area: paymentType;

  color: ${({ theme }) => theme.dark300};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.143;
`;

const ProductName = styled.p`
  grid-area: name;

  color: ${({ theme }) => theme.dark400};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.143;
`;

const Description = styled.p`
  grid-area: description;

  color: ${({ theme }) => theme.dark300};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.143;
`;
const Price = styled.p`
  grid-area: price;

  text-align: right;
  color: ${({ theme }) => theme.dark500};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.143;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    text-align: left;
  }
`;

const SubTableRow = ({ line }) => {
  const { t } = useTranslation('customerSuccess');

  const productName = useMemo(() => {
    if (line.product.isAddon) return t('history.addon');
    if (line.product.isRoyaltyFree) return t('history.royaltyFree');
    return t('history.commercial');
  }, [line.product.isAddon, line.product.isRoyaltyFree, t]);

  return (
    <Container>
      <InvoiceType>{line.product.isPrepaid ? t('history.prepaid') : t('history.postpaid')}</InvoiceType>
      <PaymentType>{line.product.isForever ? t('history.auto') : t('history.manual')}</PaymentType>
      <ProductName>{productName}</ProductName>
      <Description>{line.product.shortName}</Description>
      <Price>€ {line.priceSubtotal}</Price>
    </Container>
  );
};

export default SubTableRow;
