import { useCallback } from 'react';
import styled from 'styled-components';

import { SelectCard } from '../../../../../common';

const Label = styled.h3`
  font-size: 1.6rem;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PlaylistRow = ({ playlist, selected, togglePlaylistCheckBox }) => {
  const onSelect = useCallback(() => {
    togglePlaylistCheckBox(playlist.id);
  }, [togglePlaylistCheckBox, playlist]);

  return (
    <SelectCard
      activeSelectCardId={selected?.id}
      id={playlist.id}
      onClick={onSelect}
      title={<Label>{playlist.title}</Label>}
    />
  );
};

export default PlaylistRow;
