import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { useLazyQuery } from '@apollo/react-hooks';

import { useMediaSize, BasicButton, Button, capitalize } from '../../../common';
import Playlists from './playlists';
import StartPlayer from './startPlayer';
import { usePlaylists } from './playlistsProvider';
import { Tabs } from './types';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as ZoneLargeIcon } from '../../../assets/icons/zoneLarge.svg';
import { ReactComponent as RawPlayIcon } from '../../../assets/icons/simplePlayControls/play.svg';
import theme from '../../../global/style/theme';
import { GET_PLAYER_LINK } from './api';

const Container = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: ${({ theme }) => theme.white};
  display: grid;
  grid-template-rows: min-content 1fr;
`;

const CardsWrapper = styled.div`
  height: 4.6rem;
  padding: 0 1.6rem;
  display: grid;
  gap: 3rem;
  grid-template-columns: repeat(3, min-content);
  align-items: end;
  overflow-x: auto;
  border-bottom: 1px solid ${({ theme }) => theme.stroke};
  background-color: ${({ theme }) => theme.white};
`;

const TabButton = styled(Button)`
  white-space: nowrap;
  padding-bottom: 0.6rem;
  border-width: 4px;
  color: ${({ theme }) => theme.dark500};
  font-weight: 600;

  ${({ isSelected }) =>
    isSelected
      ? css``
      : css`
          border: none;
          padding-bottom: 1rem;
        `}
`;

const Header = styled.div`
  width: 100%;
  height: 6.6rem;
  display: grid;
  grid-template-columns: 1fr min-content min-content;
  align-items: center;
  column-gap: 1.6rem;
  row-gap: 0.4rem;
  padding: 0 1.6rem;
  background-color: ${({ theme }) => theme.dark600};
`;

const CloseButton = styled(BasicButton)`
  width: fit-content;
  height: 5rem;

  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 0 1rem;

  & p {
    display: none;

    @media only screen and (min-width: ${({ theme }) =>
        theme.breakpoints.medium}px) {
      display: block;
      color: ${({ theme }) => theme.dark200};
      font-size: 1.4rem;
    }
  }
`;

const HeaderWrapper = styled.div`
  display: grid;
  gap: 0.2rem;
`;

const ZoneName = styled.p`
  color: ${({ theme }) => theme.white};
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const OrganizationLocationName = styled.div`
  color: ${({ theme }) => theme.dark200};
  font-size: 1.4rem;
  line-height: 1.4;
  display: grid;
  gap: 0.4rem;
  grid-template-columns: min-content 1fr;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & p {
    display: flex;
    align-items: center;
  }

  & span {
    padding: 0 0.8rem;
    font-size: 1rem;
    color: ${({ theme }) => theme.dark300};
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  width: 1rem;
  height: 1rem;
  color: ${({ theme }) => theme.dark200};
`;

const StyledZoneLarge = styled(ZoneLargeIcon)`
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 0.2rem;
`;

const PlayerButton = styled(Button)`
  display: none;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    height: 3.8rem;
    padding-left: 0.4rem;
    display: grid;
    grid-template-columns: min-content 1fr;
    border-width: 1px;
    white-space: nowrap;
    color: ${({ theme }) => theme.white};
    border-color: ${({ theme }) => theme.dark300};

    ${({ isLoading }) =>
      isLoading &&
      css`
        padding: 0 1.2rem;
      `}

    &:hover {
      border-width: 1px;
      color: ${({ theme }) => theme.white};
      border-color: ${({ theme }) => theme.dark100};
    }
  }
`;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.waterBlue};
  margin-right: 1rem;
  min-height: 2.8rem;
  min-width: 2.8rem;
`;

const PlayIcon = styled(RawPlayIcon)`
  width: 1rem;
  height: 1rem;
  margin-left: 0.15rem;
`;

const PlaylistsWrapper = ({ onClose, ...props }) => {
  const { t } = useTranslation();
  const { isNarrowerThanMedium } = useMediaSize();
  const { selectedZone } = usePlaylists();

  const [tab, setTab] = useState(Tabs.PLAYLIST);

  const handleSelectPlaylistTab = useCallback(() => {
    setTab(Tabs.PLAYLIST);
  }, []);
  const handleSelectSearchTab = useCallback(() => {
    setTab(Tabs.SEARCH);
  }, []);
  const handleSelectPlayerTab = useCallback(() => {
    setTab(Tabs.PLAYER);
  }, []);

  const [getPlayerLink, { loading }] = useLazyQuery(GET_PLAYER_LINK, {
    variables: { zoneId: Number(selectedZone.id) },
    onCompleted: ({ playerLink }) => {
      window.open(playerLink, '_blank', 'noopener noreferrer');
    },
    fetchPolicy: 'network-only',
  });

  return (
    <Container {...props}>
      <Header>
        <HeaderWrapper>
          <ZoneName>
            {t('musicManagement:playlists.playlist.titleEdit')}
          </ZoneName>
          <OrganizationLocationName>
            <StyledZoneLarge />
            <p>
              {selectedZone.name}
              <span>|</span>
              {selectedZone.location.name}
              <span>|</span>
              {selectedZone.organizationName}
            </p>
          </OrganizationLocationName>
        </HeaderWrapper>
        <PlayerButton
          emphasis="outline"
          size="medium"
          title={capitalize(
            t('common:button.title.goTo', {
              value: t('common:button.title.player'),
            })
          )}
          onClick={() => getPlayerLink()}
          isLoading={loading}
        >
          <Circle>
            <PlayIcon color={theme.waterBlue} />
          </Circle>
          {t('common:button.goToPlayer')}
        </PlayerButton>
        <CloseButton isDiv onClick={onClose}>
          <p>{t('musicManagement:playlists.playlist.close')}</p>
          <StyledCloseIcon />
        </CloseButton>
      </Header>
      <div>
        {isNarrowerThanMedium ? (
          <CardsWrapper>
            <TabButton
              emphasis="secondary"
              isSelected={tab === Tabs.PLAYLIST}
              onClick={handleSelectPlaylistTab}
            >
              {t('musicManagement:playlists.playlist.subTitle')}
            </TabButton>
            <TabButton
              emphasis="secondary"
              isSelected={tab === Tabs.SEARCH}
              onClick={handleSelectSearchTab}
            >
              {t('musicManagement:playlists.search.subTitle')}
            </TabButton>
            <TabButton
              emphasis="secondary"
              isSelected={tab === Tabs.PLAYER}
              onClick={handleSelectPlayerTab}
            >
              {t('common:button.goToPlayer')}
            </TabButton>
          </CardsWrapper>
        ) : null}
        {isNarrowerThanMedium ? (
          <>
            {tab === Tabs.PLAYLIST ? (
              <Playlists setTab={setTab} tab={tab} />
            ) : null}
            {tab === Tabs.SEARCH ? (
              <Playlists setTab={setTab} tab={tab} />
            ) : null}
            {tab === Tabs.PLAYER ? (
              <StartPlayer setTab={setTab} tab={tab} />
            ) : null}
          </>
        ) : (
          <Playlists />
        )}
      </div>
    </Container>
  );
};

export default PlaylistsWrapper;
