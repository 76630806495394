/* eslint-disable no-nested-ternary */
import { useState, useLayoutEffect, useRef, useMemo, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as RawSuccessIcon } from '../../assets/icons/smallCheckmark.svg';
import { ReactComponent as RawFailIcon } from '../../assets/icons/close.svg';

import { capitalize } from '..';
import LoadingIndicator from '../loadingIndicator';

import colors from './colors';
import flavors from './flavors';

const BUTTON_SUCCESS_TIMEOUT = 3000;

const SuccessIcon = styled(RawSuccessIcon)`
  width: 1rem;
  height: 1rem;

  & path {
    fill: ${({ color }) => color};
  }
`;

const FailIcon = styled(RawFailIcon)`
  width: 1rem;
  height: 1rem;

  & path {
    fill: ${({ color }) => color};
  }
`;

const Container = styled.button`
  border-radius: 5px;
  cursor: pointer;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 250ms linear, background 250ms linear,
    border-color 250ms linear, color 250ms linear;
  user-select: none;

  &::before {
    transition: background-color 250ms linear, background 250ms linear,
      border-color 250ms linear, color 250ms linear;
  }

  ${({ shouldCapitalizeText }) =>
    shouldCapitalizeText && 'text-transform: uppercase;'}

  ${({ emphasis, flavor }) => colors[emphasis][flavor]}
  ${({ flavor }) => flavors[flavor]}

  ${({ isLoading, width, height }) => {
    if (isLoading) {
      return css`
        width: ${width}px;
        height: ${height}px;
        padding: 0;
        &::before {
          content: none;
        }
      `;
    }
  }}

  ${({ isSuccess, width, height }) => {
    if (isSuccess) {
      return css`
        width: ${width}px;
        height: ${height}px;
        padding: 0;
      `;
    }
  }}

  &:disabled {
    cursor: default;
  }
`;

const Button = ({
  emphasis = 'primary',
  flavor = 'flat',
  onClick,
  onPointerDown,
  isLoading = false,
  isDisabled = false,
  isSuccess = false,
  isFail = false,
  loadingIndicatorColor = 'white',
  successIndicatorColor = 'white',
  failIndicatorColor = '#f55325',
  type,
  className,
  children,
  shouldCapitalizeText = true,
  title = '',
  value,
}) => {
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [isLocalSuccess, setIsLocalSuccess] = useState(isSuccess);
  const [isLocalFail, setIsLocalFail] = useState(isFail);

  useEffect(() => {
    setIsLocalSuccess(isSuccess);
    setIsLocalFail(isFail);
    if (isSuccess)
      setTimeout(() => setIsLocalSuccess(false), BUTTON_SUCCESS_TIMEOUT);
    if (isFail) setTimeout(() => setIsLocalFail(false), BUTTON_SUCCESS_TIMEOUT);
  }, [isSuccess, isFail]);

  // Save dimensions of the button with it's children
  useLayoutEffect(() => {
    if (ref.current && ref.current.getBoundingClientRect().width) {
      setWidth(ref.current.getBoundingClientRect().width);
    }
    if (ref.current && ref.current.getBoundingClientRect().height) {
      setHeight(ref.current.getBoundingClientRect().height);
    }
  }, [children]);

  const memoizedIsDisabled = useMemo(
    () => isDisabled || isLoading,
    [isDisabled, isLoading]
  );

  const content = useMemo(() => {
    if (isLoading)
      return <LoadingIndicator color={loadingIndicatorColor} size="small" />;
    if (isLocalSuccess) return <SuccessIcon color={successIndicatorColor} />;
    if (isLocalFail) return <FailIcon color={failIndicatorColor} />;
    return children;
  }, [
    isLoading,
    isLocalSuccess,
    isLocalFail,
    children,
    failIndicatorColor,
    loadingIndicatorColor,
    successIndicatorColor,
  ]);

  return (
    <Container
      ref={ref}
      className={className}
      disabled={memoizedIsDisabled}
      emphasis={emphasis}
      flavor={flavor}
      height={height}
      isLoading={isLoading}
      isSuccess={isLocalSuccess}
      onClick={!isLoading ? onClick : undefined}
      onPointerDown={onPointerDown}
      shouldCapitalizeText={shouldCapitalizeText}
      title={capitalize(title)}
      type={type}
      value={value}
      width={width}
    >
      {content}
    </Container>
  );
};

export default Button;
