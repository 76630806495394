import { useMutation } from '@apollo/react-hooks';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  ErrorMessage,
  HiddenIngenicoForm,
  LoadingIndicator,
  SelectCard,
} from '../../../../common';
import config from '../../../../config';
import { useAuth } from '../../../../global/auth/newAuthProvider';
import { useAddonsBuy } from '../addonsBuyContext';
import { CONFIRM_SALE_ORDER_WITHOUT_PAYMENT, PREPARE_PAYMENT } from '../api';
import { PurchaseOverview } from '../purchaseOverview';

const { BASE_URL } = config;

const Container = styled.div`
  display: grid;
  gap: 2rem;
`;

const Image = styled.img`
  border-radius: 4px;
`;

const Text = styled.div`
  font-size: 1.2rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.dark200};
`;

const SelectCardWrapper = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  gap: 1rem;
`;

const Label = styled.h3`
  font-size: 1.4rem;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Options = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin-top: 2rem;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 2rem;
`;

const ConfirmWithoutPaymentStep = ({
  setGoToNextStepAction,
  setGoToNextStepLoading,
  setGoToNextStepError,
  goToNextStep,
  setCanProceedToNextStep,
}) => {
  const navigate = useNavigate();
  const { organizationId, locationId, zoneId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const { user: userInfo } = useAuth();

  const {
    saleOrderLoading,
    saleOrderError,
    selectedPaymentOption,
    buildSaleOrder,
    selectedAddonCatalogue,
    saleOrder,
    paymentOptionsLoading,
    paymentOptionsError,
    paymentOptions,
    handleSelectPaymentOption,
    organizationDetails,
    selectedAdditionalZones,
  } = useAddonsBuy();

  const formRef = useRef(null);

  useEffect(() => {
    buildSaleOrder();
  }, [buildSaleOrder]);

  const [confirmSaleOrderWithoutPayment] = useMutation(
    CONFIRM_SALE_ORDER_WITHOUT_PAYMENT,
    {
      variables: { saleOrderId: saleOrder?.id },
      onCompleted: () => {
        setGoToNextStepLoading(false);
        setGoToNextStepError(null);
        navigate('/subscriptions');
      },
      onError: (err) => {
        setGoToNextStepLoading(false);
        setGoToNextStepError(err);
      },
    }
  );

  const [preparePayment] = useMutation(PREPARE_PAYMENT, {
    onCompleted: ({ preparePayment: preparePaymentResult }) => {
      setGoToNextStepError(null);
      if (preparePaymentResult.wireTransferReference) {
        // User selected wire transfer and will not be redirected to Ingenico
        const { amount, wireTransferReference, iban } = preparePaymentResult;
        navigate(
          `/organizations/${organizationId}/locations/${locationId}/zones/${zoneId}/purchase/wire-transfer`,
          {
            state: {
              amount,
              iban,
              wireTransferReference,
            },
          }
        );
      } else {
        formRef.current.PSPID.value = preparePaymentResult.ogonePspid;
        formRef.current.ORDERID.value = preparePaymentResult.reference;
        formRef.current.AMOUNT.value = Number(preparePaymentResult.amount);
        formRef.current.CURRENCY.value = preparePaymentResult.currency;
        formRef.current.SHASIGN.value = preparePaymentResult.ingenicoShaIn;
        formRef.current.ALIAS.value = preparePaymentResult.ogoneAlias;
        formRef.current.ALIASUSAGE.value = preparePaymentResult.ogoneAliasUsage;
        formRef.current.OWNERCTY.value = preparePaymentResult.ownerCountryCode;
        formRef.current.submit();
      }
    },
    onError: (err) => {
      setGoToNextStepLoading(false);
      setGoToNextStepError(err);
    },
  });

  const handlePay = useCallback(() => {
    const inputs = formRef.current.querySelectorAll('input');
    const newPaymentValues = {};
    inputs.forEach((input) => {
      if (
        input.name !== 'PSPID' &&
        input.name !== 'ORDERID' &&
        input.name !== 'AMOUNT' &&
        input.name !== 'CURRENCY' &&
        input.name !== 'SHASIGN' &&
        input.name !== 'ALIAS' &&
        input.name !== 'ALIASUSAGE'
      ) {
        newPaymentValues[input.name] = input.value;
      }
    });
    preparePayment({
      variables: {
        ingenicoParams: newPaymentValues,
        saleOrderId: Number(saleOrder.id),
        paymentAcquirerId: Number(selectedPaymentOption.paymentAcquirerId),
        paymentIconId: Number(selectedPaymentOption.id),
      },
    });
  }, [preparePayment, selectedPaymentOption, saleOrder]);

  useEffect(() => {
    setGoToNextStepAction(() => () => {
      setGoToNextStepLoading(true);
      if (selectedAddonCatalogue.id === 'paid') {
        handlePay();
      } else {
        confirmSaleOrderWithoutPayment();
      }
    });
    return () => {
      setGoToNextStepError(null);
      setGoToNextStepAction(() => goToNextStep);
    };
  }, [
    setGoToNextStepAction,
    setGoToNextStepError,
    goToNextStep,
    confirmSaleOrderWithoutPayment,
    setGoToNextStepLoading,
    selectedAddonCatalogue,
    handlePay,
  ]);

  useEffect(() => {
    if (
      saleOrderLoading ||
      saleOrderError ||
      paymentOptionsLoading ||
      paymentOptionsError ||
      (selectedAddonCatalogue.id === 'paid' && !selectedPaymentOption)
    ) {
      setCanProceedToNextStep(false);
    } else {
      setCanProceedToNextStep(true);
    }

    return () => {
      setCanProceedToNextStep(true);
    };
  }, [
    setCanProceedToNextStep,
    saleOrderLoading,
    saleOrderError,
    selectedAddonCatalogue,
    selectedPaymentOption,
    paymentOptionsLoading,
    paymentOptionsError,
  ]);

  const handleSelect = useCallback(
    (id) => {
      if (id !== selectedPaymentOption?.id) {
        const paymentOption = paymentOptions.find((po) => po.id === id);
        handleSelectPaymentOption(paymentOption);
      }
    },
    [selectedPaymentOption, handleSelectPaymentOption, paymentOptions]
  );

  useEffect(() => {
    let paramContent = {
      zoneIds: selectedAdditionalZones.map((az) => az.id).concat(zoneId).join(','),
    };
    if (saleOrder?.id) {
      paramContent = { ...paramContent, saleOrderId: saleOrder.id };
    }
    setSearchParams(paramContent);
  }, [saleOrder?.id, selectedAdditionalZones, setSearchParams, zoneId]);

  const paramPlus = useMemo(() => searchParams.toString(), [searchParams]);

  return selectedAddonCatalogue.id === 'paid' ? (
    <>
      <Container>
        <PurchaseOverview />
        {paymentOptionsLoading && <StyledLoadingIndicator />}
        {paymentOptionsError && (
          <StyledErrorMessage error={paymentOptionsError} />
        )}
        <Options>
          {!paymentOptionsLoading && !paymentOptionsError
            ? paymentOptions?.map((paymentOption) => (
                <SelectCard
                  key={paymentOption.id}
                  activeSelectCardId={selectedPaymentOption?.id}
                  id={paymentOption.id}
                  onClick={handleSelect}
                  title={
                    <SelectCardWrapper>
                      <Image
                        src={`data:image/png;base64,${paymentOption.image}`}
                      />
                      <Label>{paymentOption.name}</Label>
                    </SelectCardWrapper>
                  }
                ></SelectCard>
              ))
            : null}
        </Options>
      </Container>
      {selectedPaymentOption?.isWireTransfer ? (
        <StyledErrorMessage error={t('subscriptions:warningIsWireTransfer')} />
      ) : null}
      <HiddenIngenicoForm
        ref={formRef}
        brand={(selectedPaymentOption && selectedPaymentOption.brand) || ''}
        cn={`${userInfo.firstName} ${userInfo.lastName}`}
        email={userInfo.emailAddress}
        language={userInfo.lang}
        orderId={(saleOrder && saleOrder.id) || ''}
        organizationId={organizationId}
        ownerAddress={`${organizationDetails.streetName} ${organizationDetails.streetNumber}`}
        ownerCty={organizationDetails.countryId}
        ownerTown={organizationDetails.city}
        ownerZip={organizationDetails.zip}
        paramPlus={paramPlus}
        pm={(selectedPaymentOption && selectedPaymentOption.pm) || ''}
        redirectUrlOnAccept={`${BASE_URL}/payment-feedback/accept`}
        redirectUrlOnCancel={`${BASE_URL}/payment-feedback/cancel`}
        redirectUrlOnDecline={`${BASE_URL}/payment-feedback/decline`}
        redirectUrlOnException={`${BASE_URL}/payment-feedback/exception`}
      />
    </>
  ) : (
    <Text>{t('subscriptions:confirmWithoutPaymentStep:warning')}</Text>
  );
};

export default ConfirmWithoutPaymentStep;
