import styled, { css } from 'styled-components';

const Container = styled.div`
  display: grid;
  column-gap: 1.5rem;
  align-items: center;
  padding: 1rem;
  min-height: 4.4rem;
  font-size: 1.4rem;
  line-height: 1.4;
  background-color: ${({ theme }) => theme.white};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.stroke};

  &:not(:last-child) {
    margin-bottom: 0.2rem;
  }

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    padding: 0.5rem 1rem;
    ${({ templateColumns }) => templateColumns && `grid-template-columns: ${templateColumns};`}
  }
`;

const TableRow = ({ children, className, ...props }) => {
  return (
    <Container className={className} {...props}>
      {children}
    </Container>
  );
};

export default TableRow;
