import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { BasicButton } from '../../../common';
import { ReactComponent as CrossIcon } from '../../../assets/icons/close.svg';
import theme from '../../../global/style/theme';

const Container = styled.div`
  background-color: ${({ theme }) => theme.waterBlue};
  border-radius: 4px;
  align-items: center;
  padding: 1.2rem 1.6rem;
  overflow: hidden;
  min-height: 4rem;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: fit-content min-content;
  justify-content: space-between;
  column-gap: 1rem;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.p`
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 600;
  color: ${({ theme }) => theme.white};
  padding-right: 2rem;
`;

const Text = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.white};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CrossButton = styled(BasicButton)`
  padding: 1rem;
`;

const FilterMessage = ({ searchFilter, searchValue, resetFilters }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <TitleWrapper>
        <Title>{t(`subscriptions:search.messageTitle`)}</Title>
        <Text>
          {t(`subscriptions:search.${searchFilter}`)}: {searchValue}
        </Text>
      </TitleWrapper>
      <CrossButton
        onClick={resetFilters}
        title={t('common:button.title.resetFilter')}
      >
        <CrossIcon color={theme.white} />
      </CrossButton>
    </Container>
  );
};

export default FilterMessage;
