import { forwardRef } from 'react';
import { isMobile } from 'react-device-detect';

import config from '../config';

const { INGENICO_ACTION } = config;

const HiddenIngenicoForm = forwardRef(
  (
    {
      language,
      cn,
      email,
      pm,
      brand,
      ownerCty,
      ownerAddress,
      ownerZip,
      ownerTown,
      redirectUrlOnAccept,
      redirectUrlOnDecline,
      redirectUrlOnException,
      redirectUrlOnCancel,
      paramPlus,
    },
    ref
  ) => (
    <form ref={ref} action={INGENICO_ACTION} method="post">
      {/* These values are fetched from the back-end */}
      <input name="PSPID" type="hidden" value="" />
      <input name="ORDERID" type="hidden" value="" />
      <input name="AMOUNT" type="hidden" value="" />
      <input name="CURRENCY" type="hidden" value="" />
      <input name="SHASIGN" type="hidden" value="" />
      <input name="ALIAS" type="hidden" value="" />
      <input name="ALIASUSAGE" type="hidden" value="" />

      {/* Recurring payment configuration */}
      <input name="ALIASOPERATION" type="hidden" value="BYMERCHANT" />

      {/* Chosen payment option */}
      <input name="PM" type="hidden" value={pm} />
      <input name="BRAND" type="hidden" value={brand} />
      <input name="PMLISTTYPE" type="hidden" value="2" />

      {/* Ingenico payment page configuration */}
      <input name="LANGUAGE" type="hidden" value={language} />
      {/* Only include when on mobile */}
      {isMobile && <input name="DEVICE" type="hidden" value="mobile" />}

      {/* Additional parameters which are returned by Ingenico */}
      <input name="PARAMPLUS" type="hidden" value={paramPlus} />

      {/* User credentials (fraud prevention by Ingenico) */}
      <input name="CN" type="hidden" value={cn} />
      <input name="EMAIL" type="hidden" value={email} />
      <input name="OWNERADDRESS" type="hidden" value={ownerAddress} />
      <input name="OWNERZIP" type="hidden" value={ownerZip} />
      <input name="OWNERTOWN" type="hidden" value={ownerTown} />
      <input name="OWNERCTY" type="hidden" value={ownerCty} />

      {/* Redirect URLs */}
      <input name="ACCEPTURL" type="hidden" value={redirectUrlOnAccept} />
      <input name="DECLINEURL" type="hidden" value={redirectUrlOnDecline} />
      <input name="EXCEPTIONURL" type="hidden" value={redirectUrlOnException} />
      <input name="CANCELURL" type="hidden" value={redirectUrlOnCancel} />
    </form>
  )
);

export default HiddenIngenicoForm;
