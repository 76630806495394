export const getPercentage = (current, max, steps) => {
  const stepValue = max / (steps - 1);
  const snappedValue = Math.round(current / stepValue) * stepValue;

  if (current >= snappedValue) {
    return (100 * snappedValue) / max;
  }

  return undefined;
};

export const getInitialPercentage = (current, max, steps) => {
  const stepValue = max / (steps - 1);
  const snappedValue = Math.round(current / stepValue) * stepValue;

  return (100 * snappedValue) / max;
};

export const getValue = (percentage, max) => (max / 100) * percentage;
