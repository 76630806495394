import { useCallback } from 'react';
import styled from 'styled-components';

import { DropdownOptionTypes } from '../types';

const ProfileOptionButton = styled.button`
  width: 100%;
  text-align: left;
  transition: background-color 250ms linear, color 250ms linear;
  display: block;
  border: none;
  background: none;
  cursor: pointer;
  padding: 1rem;

  &:hover {
    color: ${({ theme }) => theme.white};
    background-color: ${({ theme }) => theme.waterBlue};
    font-weight: bold;
  }
`;

const ProfileOption = ({ profile, handleOnChange }) => {
  const handleClickOption = useCallback(() => {
    handleOnChange(profile?.id, DropdownOptionTypes.PROFILE);
  }, [handleOnChange, profile?.id]);

  return (
    <ProfileOptionButton onClick={handleClickOption}>
      {profile?.name}
    </ProfileOptionButton>
  );
};

export default ProfileOption;
