import { useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { CheckboxField, useToggle, Button } from '../../../../../common';
import { usePurchase } from '../../purchaseContext';
import ZonesWithSubscription from './zonesWithSubscription';
import ZonesWithoutSubscription from './zonesWithoutSubscription';

const StyledCheckBox = styled(CheckboxField)`
  justify-self: end;
  padding: 0 1.8rem 1.6rem 0;
`;

const StyledButton = styled(Button)`
  margin: 0 auto;
  margin-top: 2rem;
  max-width: 25rem;
`;

const AdditionalSubscriptionStep = () => {
  const { t } = useTranslation();
  const {
    availableAdditionalZones,

    handleSelectAdditionalZone,

    userInput: { selectedAdditionalZones },
  } = usePurchase();

  const [showAll, setShowAll] = useToggle(false);

  const [zonesWithoutSubscription, zonesWithSubscription] = useMemo(() => {
    const truthyZones = availableAdditionalZones.filter(Boolean);

    return truthyZones.reduce(
      (acc, current) => {
        const zonesWithoutSubscription = acc[0];
        const zonesWithSubscription = acc[1];

        if (current.currentSubscription == null) {
          zonesWithoutSubscription.push(current);
          return acc;
        }

        zonesWithSubscription.push(current);
        return acc;
      },
      [[], []]
    );
  }, [availableAdditionalZones]);

  const isZonesWithoutSubscriptionSelected = useMemo(() => {
    return zonesWithoutSubscription.every((selectedZone) =>
      selectedAdditionalZones.includes(selectedZone)
    );
  }, [selectedAdditionalZones, zonesWithoutSubscription]);

  const isZonesWithSubscriptionSelected = useMemo(() => {
    return zonesWithSubscription.every((selectedZone) =>
      selectedAdditionalZones.includes(selectedZone)
    );
  }, [selectedAdditionalZones, zonesWithSubscription]);

  const isEveryShownZoneSelected = useMemo(() => {
    return (
      (!showAll && isZonesWithoutSubscriptionSelected) ||
      (showAll &&
        isZonesWithSubscriptionSelected &&
        isZonesWithoutSubscriptionSelected)
    );
  }, [
    isZonesWithSubscriptionSelected,
    isZonesWithoutSubscriptionSelected,
    showAll,
  ]);

  const handleOpenAll = useCallback(() => {
    // Take the reverse of the current state since we reverse it at the end of the function.
    const reverseOfCurrentState = !showAll;

    if (!reverseOfCurrentState) {
      zonesWithSubscription
        .filter((zoneWithSubscription) =>
          selectedAdditionalZones.includes(zoneWithSubscription)
        )
        .forEach((selectedZoneWithSubscription) =>
          handleSelectAdditionalZone(selectedZoneWithSubscription)
        );
    }

    setShowAll();
  }, [
    handleSelectAdditionalZone,
    selectedAdditionalZones,
    setShowAll,
    showAll,
    zonesWithSubscription,
  ]);

  const handleSelectAll = useCallback(() => {
    // If not every zone is selected then we can assume that some zones can be selected. In that case we only revert the
    // zones that where not selected. If they where all selected we can just reverse every zone.

    const [zonesWithoutSubscriptionToToggle, zonesWithSubscriptionToToggle] =
      selectedAdditionalZones.length === 0 ||
      selectedAdditionalZones.length ===
        (showAll ? zonesWithSubscription.length : 0) +
          zonesWithoutSubscription.length
        ? [zonesWithoutSubscription, zonesWithSubscription]
        : [
            zonesWithoutSubscription.filter(
              (zoneWithoutSubscription) =>
                !selectedAdditionalZones.includes(zoneWithoutSubscription)
            ),
            zonesWithSubscription.filter(
              (zoneWithSubscription) =>
                !selectedAdditionalZones.includes(zoneWithSubscription)
            ),
          ];

    if (showAll) {
      zonesWithSubscriptionToToggle.forEach((zoneWithSubscription) =>
        handleSelectAdditionalZone(zoneWithSubscription)
      );
    }

    zonesWithoutSubscriptionToToggle.forEach((zoneWithoutSubscription) =>
      handleSelectAdditionalZone(zoneWithoutSubscription)
    );
  }, [
    handleSelectAdditionalZone,
    selectedAdditionalZones,
    showAll,
    zonesWithSubscription,
    zonesWithoutSubscription,
  ]);

  return (
    <>
      {(showAll || zonesWithoutSubscription.length > 1) && (
        <StyledCheckBox
          label={t('purchase:zones.selectAll')}
          onChange={handleSelectAll}
          checked={isEveryShownZoneSelected}
          color="waterBlue"
          reverse
        />
      )}
      {zonesWithoutSubscription.length > 0 ? (
        <ZonesWithoutSubscription
          zonesWithoutSubscriptions={zonesWithoutSubscription}
        />
      ) : null}
      {showAll && <ZonesWithSubscription />}
      {zonesWithSubscription.length > 0 ? (
        <StyledButton
          emphasis="outline"
          highlightColor="waterBlue"
          title={
            showAll
              ? t('subscriptions:thHideAllZones')
              : t('subscriptions:thShowAllZones')
          }
          onClick={handleOpenAll}
        >
          {showAll
            ? t('subscriptions:thHideAllZones')
            : t('subscriptions:thShowAllZones')}
        </StyledButton>
      ) : null}
    </>
  );
};

export default AdditionalSubscriptionStep;
