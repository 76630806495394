import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useSessionStorage } from '../../../../common';
import {
  PARTNER_MANAGER_ROLE_PERMISSIONS,
  SUPPORT_ADMIN_ROLE_PERMISSIONS,
  hasAllRequiredPermissions,
} from '../../../../global/auth';
import { useAuth } from '../../../../global/auth/newAuthProvider';
import { usePurchase } from '../purchaseContext';
import {
  AdditionalSubscriptionStep,
  AddonsStep,
  ColorStep,
  ConfirmWithoutPaymentStep,
  MusicLicenseStep,
  OrganizationDetailsStep,
  PaymentStep,
  ProductCatalogueStep,
  SubscriptionTemplateStep,
} from '../steps';

export const usePurchaseSteps = () => {
  const { t } = useTranslation();
  const { organizationId, locationId, zoneId, currentStep } = useParams();
  const {
    availableAdditionalZones,
    selectedProductCatalogue,
    userInput,
    selectedAdditionalZones,
  } = usePurchase();
  const navigate = useNavigate();
  const { user } = useAuth();

  const [impersonationId] = useSessionStorage('impersonationId');
  const isSupportAdmin = hasAllRequiredPermissions(
    user,
    SUPPORT_ADMIN_ROLE_PERMISSIONS
  );
  const isPartnerManager = hasAllRequiredPermissions(
    user,
    PARTNER_MANAGER_ROLE_PERMISSIONS
  );

  const goToNextStep = useCallback(() => {
    // The currentStep variable of useParams is still outdated in this callback.
    // Need to make use of the window location pathname to get the real, current step.
    const [realCurrentStep] = window.location.pathname.split('/').slice(-1);
    navigate(
      `/organizations/${organizationId}/locations/${locationId}/zones/${zoneId}/purchase/${
        Number(realCurrentStep) + 1
      }`,
      { state: { from: Number(realCurrentStep) } }
    );
  }, [navigate, organizationId, locationId, zoneId]);

  // These states can be updated via callbacks in the current step component
  const [canProceedToNextStep, setCanProceedToNextStep] = useState(true);
  const [goToNextStepAction, setGoToNextStepAction] = useState(
    () => goToNextStep
  );
  const [goToNextStepLoading, setGoToNextStepLoading] = useState(false);
  const [goToNextStepError, setGoToNextStepError] = useState(null);

  const goToPreviousStep = useCallback(() => {
    if (Number(currentStep) === 0) {
      navigate('/subscriptions');
    } else {
      navigate(
        `/organizations/${organizationId}/locations/${locationId}/zones/${zoneId}/purchase/${
          Number(currentStep) - 1
        }`,
        { state: { from: Number(currentStep) } }
      );
    }
  }, [navigate, currentStep, organizationId, locationId, zoneId]);

  const stepsInfo = useMemo(() => {
    const purchaseSteps = [];

    if ((isSupportAdmin || isPartnerManager) && impersonationId) {
      purchaseSteps.push({
        title: t('subscriptions:buyStep0Title'),
        shortTitle: t('subscriptions:buyStep0TitleShort'),
        description: t('subscriptions:buyStep0Description'),
        selection: selectedProductCatalogue?.name,
        Component: (
          <ProductCatalogueStep
            setGoToNextStepError={setGoToNextStepError}
            setGoToNextStepLoading={setGoToNextStepLoading}
          />
        ),
      });
    }

    purchaseSteps.push(
      {
        title: t('subscriptions:buyStep1Title'),
        shortTitle: t('subscriptions:buyStep1TitleShort'),
        description: t('subscriptions:buyStep1Description'),
        selection: userInput?.selectedMusicLicense?.name,
        Component: (
          <MusicLicenseStep
            key="musicLicenseStep"
            goToNextStep={goToNextStep}
            goToPreviousStep={goToPreviousStep}
          />
        ),
      },
      {
        title: t('subscriptions:buyStep2Title'),
        shortTitle: t('subscriptions:buyStep2TitleShort'),
        description: t('subscriptions:buyStep2Description'),
        selection: userInput?.selectedColor?.name
          ? `Tunify ${userInput.selectedColor.name}`
          : undefined,
        Component: (
          <ColorStep
            key="colorStep"
            goToNextStep={goToNextStep}
            goToPreviousStep={goToPreviousStep}
          />
        ),
      },
      {
        title: t('subscriptions:buyStep3Title'),
        shortTitle: t('subscriptions:buyStep3TitleShort'),
        description: t('subscriptions:buyStep3Description'),

        selection: userInput?.selectedSubscriptionTemplate?.recurringInterval
          ? userInput?.selectedSubscriptionTemplate?.recurringRuleType ===
            'weekly'
            ? t('glossary:weekWithCount', {
                count:
                  userInput.selectedSubscriptionTemplate?.recurringInterval,
              })
            : t('glossary:monthWithCount', {
                count:
                  userInput.selectedSubscriptionTemplate?.recurringInterval,
              })
          : undefined,
        Component: (
          <SubscriptionTemplateStep
            key="subscriptionTemplateStep"
            goToNextStep={goToNextStep}
            goToPreviousStep={goToPreviousStep}
          />
        ),
      },
      {
        title: t('subscriptions:buyAddonsStepTitle'),
        shortTitle: t('subscriptions:buyAddonsStepTitleShort'),
        description: t('subscriptions:buyAddonsStepDescription'),
        selection: userInput?.selectedAddons
          ?.map((addon) => addon?.shortName)
          ?.join(', '),
        Component: <AddonsStep key="addonsStep" />,
      }
    );

    // Only add this step if there are additional zones to buy a subscription for
    if (availableAdditionalZones.length > 0) {
      purchaseSteps.push({
        title: t('subscriptions:buyStep4Title'),
        shortTitle: t('subscriptions:buyStep4Title'),
        description: t('subscriptions:buyStep4Description'),
        selection:
          selectedAdditionalZones.length > 0
            ? t('addons:buyStep4TitleSelection', {
                count: selectedAdditionalZones.length,
              })
            : undefined,
        Component: (
          <AdditionalSubscriptionStep key="additionalSubscriptionStep" />
        ),
      });
    }

    purchaseSteps.push({
      title: t('subscriptions:buyStep5Title'),
      shortTitle: t('subscriptions:buyStep5TitleShort'),
      description: t('subscriptions:buyStep5Description'),
      Component: (
        <OrganizationDetailsStep
          key="organizationDetailsStep"
          goToNextStep={goToNextStep}
          setGoToNextStepAction={setGoToNextStepAction}
          setGoToNextStepError={setGoToNextStepError}
          setGoToNextStepLoading={setGoToNextStepLoading}
        />
      ),
    });

    if ((isSupportAdmin || isPartnerManager) && impersonationId) {
      purchaseSteps.push({
        title: t('subscriptions:buyLastStepTitle'),
        shortTitle: t('subscriptions:buyLastStepTitle'),
        Component: (
          <ConfirmWithoutPaymentStep
            setCanProceedToNextStep={setCanProceedToNextStep}
            setGoToNextStepAction={setGoToNextStepAction}
            setGoToNextStepError={setGoToNextStepError}
            setGoToNextStepLoading={setGoToNextStepLoading}
          />
        ),
      });
    } else {
      purchaseSteps.push({
        title: t('subscriptions:buyLastStepTitle'),
        shortTitle: t('subscriptions:buyLastStepTitle'),
        Component: (
          <PaymentStep
            setCanProceedToNextStep={setCanProceedToNextStep}
            setGoToNextStepAction={setGoToNextStepAction}
            setGoToNextStepError={setGoToNextStepError}
            setGoToNextStepLoading={setGoToNextStepLoading}
          />
        ),
      });
    }

    return purchaseSteps;
  }, [
    isSupportAdmin,
    isPartnerManager,
    impersonationId,
    t,
    userInput?.selectedMusicLicense?.name,
    userInput?.selectedColor?.name,
    userInput?.selectedSubscriptionTemplate?.recurringRuleType,
    userInput?.selectedSubscriptionTemplate?.recurringInterval,
    userInput?.selectedAddons,
    goToNextStep,
    goToPreviousStep,
    availableAdditionalZones.length,
    selectedProductCatalogue?.name,
    selectedAdditionalZones.length,
  ]);

  const values = useMemo(
    () => ({
      goToNextStepError,
      canProceedToNextStep,
      goToNextStepAction,
      goToPreviousStep,
      currentStep: Number(currentStep),
      goToNextStepLoading,
      hasPreviousStep: Number(currentStep) > 0,
      hasNextStep: Number(currentStep) < stepsInfo.length - 1,
      stepsInfo,
    }),
    [
      canProceedToNextStep,
      currentStep,
      goToNextStepAction,
      goToNextStepError,
      goToNextStepLoading,
      goToPreviousStep,
      stepsInfo,
    ]
  );

  return values;
};
