import {
  getProductSubscriptionLineOfSubscriptionLines,
  determineAvailableAddonCategoriesForZone,
} from '../../../../common';
import { getAddonCategories } from './getAddonCategories';

export const calculatePriceForZone = (zone, selectedAddons, availableAddons) => {
  const addonCategories = getAddonCategories(availableAddons);

  const { discount } = zone.currentSubscription.upsellDiscount;
  const productOfZone = getProductSubscriptionLineOfSubscriptionLines(
    zone.currentSubscription.subscriptionLines
  ).product;

  const availableAddonCategories = determineAvailableAddonCategoriesForZone(zone, selectedAddons);

  const addonPrices = availableAddonCategories.map((addonCategory) => {
    const addonCategoryOptions = addonCategories[addonCategory.shortName];
    const addonCategoryOption = addonCategoryOptions.find(
      (addon) => addon.subscriptionTemplate.id === productOfZone.subscriptionTemplate.id
    );
    return addonCategoryOption?.price;
  });

  if (addonPrices.some((price) => price === undefined)) {
    return null;
  }

  const price = addonPrices.reduce((acc, curr) => {
    return acc + curr * (1 - discount);
  }, 0);

  return price.toFixed(2);
};
