import { useCallback } from 'react';
import styled, { css } from 'styled-components';

const Label = styled.label`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  cursor: pointer;
  font-size: 1.4rem;
  line-height: 1.4;
  user-select: none;
  position: relative;
  user-select: none;
`;

const RadioInput = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  cursor: pointer;

  :disabled {
    cursor: default;
  }
`;

const OuterCircle = styled.div`
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  min-height: 2rem;
  border: 0.2rem solid ${({ theme }) => theme.waterBlue};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.1s linear;
  background: ${({ theme }) =>
    `linear-gradient(0deg, ${theme.gradient['fade'][0]} 0%,  ${theme.gradient['fade'][1]} 100%)`};

  ${({ disabled }) =>
    disabled &&
    css`
      border-color: ${({ theme }) => theme.dark200};
    `}
`;

const InnerCircle = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.waterBlue};
  transition: all 0.1s ease-in-out;

  ${({ isChecked }) =>
    !isChecked &&
    css`
      opacity: 0;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${({ theme }) => theme.dark200};
    `}
`;

const RadioField = ({
  onChange,
  selected,
  value,
  name,
  disabled,
  label,
  className,
}) => {
  const checked = value === selected;

  const handleChange = useCallback(
    (e) => {
      if (disabled) return;
      e.preventDefault();
      onChange(value);
    },
    [onChange, value, disabled]
  );

  return (
    <Label className={className} onClick={handleChange}>
      <RadioInput
        checked={checked}
        disabled={disabled}
        name={name}
        onChange={handleChange}
        type="radio"
        value={value}
      />
      <OuterCircle disabled={disabled}>
        <InnerCircle disabled={disabled} isChecked={checked} />
      </OuterCircle>
      {label}
    </Label>
  );
};

export default RadioField;
