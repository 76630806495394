const determineAvailableMusicLicenses = (productCatalogue, userWantsForeverProduct) => {
  const availableProducts = productCatalogue.filter((product) => {
    return userWantsForeverProduct === 'either' || product.isForever === userWantsForeverProduct;
  });

  const availableMusicLicensesById = availableProducts.reduce((acc, curr) => {
    if (!acc[curr.musicLicense.id]) {
      return { ...acc, [curr.musicLicense.id]: curr.musicLicense };
    }
    return acc;
  }, {});

  const availableMusicLicenses = Object.keys(availableMusicLicensesById).map(
    (musicLicenseId) => availableMusicLicensesById[musicLicenseId]
  );

  return availableMusicLicenses;
};

export default determineAvailableMusicLicenses;
