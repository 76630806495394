import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { formatDistanceToNow } from '../../../../common';

import ZoneRow from './zoneRow';

const Zone = ({
  organization,
  location,
  zone,
  lastSeen,
  mappingExternalZoneIds,
}) => {
  const { t, i18n } = useTranslation();

  const { id: zoneId } = zone;
  const { id: organizationId } = organization;
  const { id: locationId } = location;

  const lastSeenDate = useMemo(() => {
    if (lastSeen) {
      return formatDistanceToNow(lastSeen, t, i18n.language);
    }
    return null;
  }, [lastSeen, t, i18n?.language]);

  return (
    <ZoneRow
      lastSeenDate={lastSeenDate}
      hasActiveSubscription={zone.currentSubscription}
      locationName={location?.name}
      locationId={locationId}
      organizationId={organizationId}
      zoneId={zoneId}
      zone={zone}
      mappingExternalZoneIds={mappingExternalZoneIds}
    />
  );
};

export default Zone;
