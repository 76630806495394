import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LocizeBackend from 'i18next-locize-backend';

import config from '../../config';

import localeWhiteList from '../../localeWhitelist';

const {
  SHOW_MISSING_I18N_KEYS,
  SAVE_MISSING_I18N_KEYS,
  UPDATE_MISSING_I18N_KEYS,
  LOCIZE_PROJECT_ID,
  LOCIZE_API_KEY,
} = config;

i18next
  .use(initReactI18next)
  .use(LocizeBackend)
  .init({
    initImmediate: false,
    whitelist: localeWhiteList,
    fallbackLng: {
      'nl-NL': ['nl-BE'], // Extend from nl-BE but overwrite specific resources
      'fr-FR': ['fr-BE'], // Extend from fr-BE but overwrite specific resources
      default: SHOW_MISSING_I18N_KEYS ? ['nl-BE'] : ['en-US'], // Default fallback language
    },
    ns: [
      'auth',
      'broadcasts',
      'common',
      'config',
      'customerSuccess',
      'dashboard',
      'error',
      'global',
      'glossary',
      'integrations',
      'invoices',
      'locations',
      'organizations',
      'partner',
      'partnerManager',
      'purchase',
      'subscriptions',
      'zones',
      'partner',
      'gifts',
      'addons',
      'musicManagement',
      'musicReportCategories',
      'registration',
      'sonos',
    ],
    backend: {
      projectId: LOCIZE_PROJECT_ID,
      apiKey: LOCIZE_API_KEY,
      referenceLng: 'nl-BE',
    },
    saveMissing: SAVE_MISSING_I18N_KEYS,
    saveMissingTo: 'all',
    updateMissing: UPDATE_MISSING_I18N_KEYS,
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false, // React escapes by default
    },
    parseMissingKeyHandler: (key) => {
      if (SHOW_MISSING_I18N_KEYS) {
        return `⚠️ [${key}] key not found in translation resources ⚠️`;
      }
      return key;
    },
  });

export default i18next;
