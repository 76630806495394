import gql from 'graphql-tag';

const GET_ORGANIZATION_AND_SALE_ORDER = gql`
  query getOrganizationAndSaleOrder($organizationId: ID!, $saleOrderId: ID!) {
    organization(id: $organizationId) {
      id
      name
      city
      zip
      streetName
      streetNumber
      vat
      countryId
    }
    saleOrder(saleOrderId: $saleOrderId) {
      id
      saleOrderLines {
        id
        priceUnit
        product {
          id
          name
          isAddon
          shortName
          musicLicense {
            id
            name
          }
          color {
            id
            name
          }
          subscriptionTemplate {
            id
            recurringInterval
          }
        }
        location {
          id
          name
        }
        zoneWithDetailedSubscriptions {
          id
          name
          lastSubscription {
            id
            endDate
          }
        }
      }
    }
  }
`;

const ADD_ITEMS_TO_CART = gql`
  mutation addItemsToCart($organizationId: ID!, $cartId: ID, $cartItems: [CartItemInput!]!) {
    addItemsToCart(organizationId: $organizationId, cartId: $cartId, cartItems: $cartItems) {
      id
      amountUntaxed
      amountTax
      amountTotal
      saleOrderLines {
        id
        cartLineId
        product {
          id
        }
        zone {
          id
        }
      }
    }
  }
`;

const REMOVE_ITEMS_FROM_CART = gql`
  mutation removeItemsFromCart($organizationId: ID!, $cartId: ID!, $cartItemIds: [String!]!) {
    removeItemsFromCart(organizationId: $organizationId, cartId: $cartId, cartItemIds: $cartItemIds) {
      id
      amountUntaxed
      amountTax
      amountTotal
      saleOrderLines {
        id
        cartLineId
        product {
          id
        }
        zone {
          id
        }
      }
    }
  }
`;

const GET_PAYMENT_OPTIONS = gql`
  query getPaymentOptions($productIds: [ID!]!, $countryId: ID!) {
    paymentOptions(productIds: $productIds, countryId: $countryId) {
      id
      name
      image
      pm
      brand
      paymentAcquirerId
      isWireTransfer
      supportsRecurringBilling
    }
  }
`;

const PREPARE_PAYMENT = gql`
  mutation preparePayment(
    $ingenicoParams: NewPaymentInput!
    $saleOrderId: Int!
    $paymentAcquirerId: Int!
    $paymentIconId: Int!
  ) {
    preparePayment(
      ingenicoParams: $ingenicoParams
      saleOrderId: $saleOrderId
      paymentAcquirerId: $paymentAcquirerId
      paymentIconId: $paymentIconId
    ) {
      id
      reference
      amount
      currency
      wireTransferReference
      iban
      bic
      ingenicoShaIn
      ogonePspid
      ogoneAlias
      ogoneAliasUsage
      ownerCountryCode
    }
  }
`;

export {
  GET_ORGANIZATION_AND_SALE_ORDER,
  ADD_ITEMS_TO_CART,
  REMOVE_ITEMS_FROM_CART,
  GET_PAYMENT_OPTIONS,
  PREPARE_PAYMENT,
};
