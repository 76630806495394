import { useRef, useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';
import ReactSlider from 'react-slider';
import { format, secondsToMilliseconds } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { useOnClickOutside, BasicButton } from '../../../common';
import { ReactComponent as RawPlayIcon } from '../../../assets/icons/playControls/play.svg';
import { ReactComponent as RawPauseIcon } from '../../../assets/icons/playControls/pause.svg';
import { ReactComponent as RawCrossIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as RawFastForwardIcon } from '../../../assets/icons/playControls/fastForward.svg';
import { usePlaylists } from './playlistsProvider';

const Container = styled.div`
  height: 8rem;
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  grid-template-rows: min-content min-content;
  align-items: center;
  align-content: center;
  justify-items: start;
  gap: 1.6rem;
  background-color: ${({ theme }) => theme.uiBackground};
  border: 1px solid ${({ theme }) => theme.stroke};
  border-radius: 8px;
  padding: 0 0.8rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    height: 4.8rem;
    grid-template-columns: repeat(2, min-content) 1fr repeat(2, min-content);
    grid-template-rows: unset;
    align-content: unset;
    justify-items: unset;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    grid-template-columns: repeat(3, min-content) 1fr repeat(2, min-content);
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

const Preview = styled.p`
  display: none;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    display: block;
    font-size: 1.2rem;
    padding-left: 0.4rem;
    color: ${({ theme }) => theme.dark200};
  }
`;

const MainButtons = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  grid-column: 1 / span 2;
  grid-row: 1;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    grid-column: unset;
    grid-row: unset;
  }
`;

const PlayControlButton = styled(BasicButton)`
  padding: 0.6rem 1.2rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    padding: 0.6rem;
  }
`;

const CloseButton = styled(BasicButton)`
  padding: 0.6rem;
  grid-column: 3;
  grid-row: 1;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    grid-column: unset;
    grid-row: unset;
  }
`;

const iconCss = css`
  width: 1.6rem;
  height: 1.6rem;
  justify-self: center;
  color: ${({ theme }) => theme.waterBlue};
`;

const PlayIcon = styled(RawPlayIcon)`
  ${iconCss}
  padding-left: 0.2rem;
  transform: scale(0.9);
`;

const PauseIcon = styled(RawPauseIcon)`
  ${iconCss}
  transform: scale(1.2);
`;

const FastForwardIcon = styled(RawFastForwardIcon)`
  ${iconCss}
`;

const WindBackIcon = styled(RawFastForwardIcon)`
  ${iconCss}
  transform: rotate(180deg);
`;

const CrossIcon = styled(RawCrossIcon)`
  ${iconCss}
  width: 1rem;
  height: 1rem;
  color: ${({ theme }) => theme.dark300};
`;

const Time = styled.p`
  min-width: 3rem;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.dark200};
`;

const TimeStart = styled(Time)`
  grid-column: 1;
  grid-row: 2;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    grid-column: unset;
    grid-row: unset;
  }
`;

const TimeEnd = styled(Time)`
  grid-column: 3;
  grid-row: 2;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    grid-column: unset;
    grid-row: unset;
  }
`;

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 0.8rem;
  grid-column: 2;
  grid-row: 2;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    grid-column: unset;
    grid-row: unset;
  }
`;

const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background: ${({ index, theme }) => {
    if (index === 2) {
      return theme.dark600;
    }
    if (index === 1) {
      return theme.uiBackground;
    }
    return `linear-gradient(
            135deg,
            ${theme.gradient.nightfall[0]} 0%,
            ${theme.gradient.nightfall[1]} 100%
          )`;
  }};

  ${({ index, theme, startPosition }) => {
    if (index === 1) {
      if (startPosition) {
        return css`
          border: 1px solid ${theme.stroke};
          border-radius: 0.4rem;
        `;
      }
      return css`
        border-top: 1px solid ${theme.stroke};
        border-bottom: 1px solid ${theme.stroke};
        border-right: 1px solid ${theme.stroke};
        border-radius: 0 0.4rem 0.4rem 0;
      `;
    }
    return css`
      z-index: 10;
      border-radius: 0.4rem 0 0 0.4rem;
    `;
  }};
`;

const AudioBar = ({ onClose, isLoading }) => {
  const { t } = useTranslation();
  const buttonRef = useRef(null);

  const {
    isPlaying,
    progress,
    startStopAudio,
    changeSliderAudio,
    fastForwardAudio,
    windBackAudio,
    stopAudio,
    setProgressToCurrentTime,
    duration,
  } = usePlaylists();

  useOnClickOutside(buttonRef, stopAudio, 'mouseup');

  const Track = useCallback(
    (props, state) => (
      <StyledTrack
        {...props}
        index={state.index}
        startPosition={isLoading || progress <= 0}
      />
    ),
    [isLoading, progress]
  );

  useEffect(() => {
    let interval = null;
    if (!isLoading) {
      if (isPlaying) {
        interval = setInterval(() => {
          setProgressToCurrentTime();
        }, 1000);
      } else if (!isPlaying && progress !== 0) {
        clearInterval(interval);
      }
    }
    return () => interval && clearInterval(interval);
  }, [isPlaying, progress, isLoading, setProgressToCurrentTime]);

  return (
    <>
      {isLoading ? (
        <Container ref={buttonRef} disabled={isLoading}>
          <Preview>
            {t('musicManagement:playlists.playlistSelection.preview')}
          </Preview>
          <MainButtons>
            <PlayControlButton>
              <WindBackIcon />
            </PlayControlButton>
            <PlayControlButton>
              <PlayIcon />
            </PlayControlButton>
            <PlayControlButton>
              <FastForwardIcon />
            </PlayControlButton>
          </MainButtons>
          <TimeStart>0:00</TimeStart>
          <StyledSlider
            defaultValue={0}
            max={1}
            onSliderClick={changeSliderAudio}
            renderTrack={Track}
            value={0}
          />
          <TimeEnd>0:00</TimeEnd>
          <CloseButton>
            <CrossIcon />
          </CloseButton>
        </Container>
      ) : (
        <>
          <Container ref={buttonRef} disabled={isLoading}>
            <Preview>
              {t('musicManagement:playlists.playlistSelection.preview')}
            </Preview>
            <MainButtons>
              <PlayControlButton onClick={windBackAudio}>
                <WindBackIcon />
              </PlayControlButton>
              <PlayControlButton onClick={startStopAudio}>
                {isPlaying ? <PauseIcon /> : <PlayIcon />}
              </PlayControlButton>
              <PlayControlButton onClick={fastForwardAudio}>
                <FastForwardIcon />
              </PlayControlButton>
            </MainButtons>
            <TimeStart>
              {progress
                ? format(
                    new Date(secondsToMilliseconds(Math.round(progress))),
                    'm:ss'
                  )
                : '0:00'}
            </TimeStart>
            <StyledSlider
              defaultValue={0}
              max={1}
              onSliderClick={changeSliderAudio}
              renderTrack={Track}
              step={0.0001}
              value={duration ? progress / duration : 0}
            />
            <TimeEnd>
              {duration
                ? format(
                    new Date(secondsToMilliseconds(Math.round(duration))),
                    'm:ss'
                  )
                : '0:00'}
            </TimeEnd>
            <CloseButton onClick={onClose}>
              <CrossIcon />
            </CloseButton>
          </Container>
        </>
      )}
    </>
  );
};

export default AudioBar;
