import { useCallback } from 'react';
import styled from 'styled-components';

import { useSortByObjectField } from '../../../../common';
import { ReactComponent as AngleDown } from '../../../../assets/icons/angleDown.svg';
import { SelectablePlaylistButton } from '../../shared';
import { usePlaylists } from '../playlistsProvider';
import theme from '../../../../global/style/theme';

const StyledSelectablePlaylistButton = styled(SelectablePlaylistButton)`
  grid-template-columns: min-content 1fr;
  padding: 1.4rem 0;
`;

const StyledAngleDown = styled(AngleDown)`
  transform: rotate(270deg);
`;

const MusicChannel = ({ musicCollections, musicChannel }) => {
  const { dispatch } = usePlaylists();

  const sortedLists = useSortByObjectField(musicCollections, 'title');

  const handleOpenChannel = useCallback(() => {
    dispatch({
      selectedTunifyList: { musicCollections: sortedLists, musicChannel },
    });
  }, [sortedLists, musicChannel, dispatch]);

  return (
    <StyledSelectablePlaylistButton onClick={handleOpenChannel} type="button">
      <StyledAngleDown color={theme.dark300} height="1rem" width="1rem" />
      <p>{musicChannel.name}</p>
    </StyledSelectablePlaylistButton>
  );
};

export default MusicChannel;
