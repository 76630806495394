import { Header, PlaylistTable } from './cards';
import SpotifyImportProvider from './spotifyImportProvider';

const SpotifyImportPage = () => {
  return (
    <SpotifyImportProvider>
      <Header />
      <PlaylistTable />
    </SpotifyImportProvider>
  );
};

export default SpotifyImportPage;
