import { useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../../../common';
import { ReactComponent as ZoneLargeIcon } from '../../../../../assets/icons/zoneLarge.svg';

const Container = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  align-items: center;
  gap: 1.2rem;
  border: 1px solid ${({ theme }) => theme.stroke};
  border-radius: 8px;
  padding: 1.2rem 1.6rem;
`;

const InfoContainer = styled.div`
  display: grid;
`;

const ZoneName = styled.p`
  font-size: 1.6rem;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
`;

const OrganizationLocationName = styled.p`
  font-size: 1.2rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.dark300};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
`;

const CoupleButton = styled(Button)``;

const ZoneRow = ({
  zoneId,
  zoneName,
  locationName,
  coupleZoneToSonos,
  isLoading,
  isDisabled,
}) => {
  const { t } = useTranslation();

  const handleCoupleZoneToSonos = useCallback(() => {
    coupleZoneToSonos(zoneId, zoneName, locationName);
  }, [zoneId, coupleZoneToSonos, zoneName, locationName]);

  return (
    <Container>
      <ZoneLargeIcon />
      <InfoContainer>
        <ZoneName>{zoneName}</ZoneName>
        <OrganizationLocationName>{locationName}</OrganizationLocationName>
      </InfoContainer>
      <CoupleButton
        isDiv
        size="medium"
        isDisabled={isDisabled}
        isLoading={isLoading}
        onClick={handleCoupleZoneToSonos}
      >
        {t('integrations:sonos.overview.couple')}
      </CoupleButton>
    </Container>
  );
};

export default ZoneRow;
