const theme = {
  background: '#f2f1f1',
  uiBackground: '#fbfbfb',
  stroke: '#dfe0e0',
  white: '#ffffff',
  babyBlue: '#96d4f8',
  berryBlue: '#7890c9',
  grapefruitRed: '#f6836a',
  lavenderPurple: '#b19dd0',
  mintGreen: '#49ce8f',
  plantGreen: '#22b580',
  sweetOrange: '#fea05d',
  waterBlue: '#75acff',
  dark100: '#d7d8db',
  dark200: '#afb2b6',
  dark300: '#868b92',
  dark400: '#5e656e',
  dark500: '#363e4a', // default dark
  dark600: '#2b323b',
  logoBlue: '#04b4e0',
  logoGreen: '#7bba58',
  transparent: 'transparent',
  zoneGrey: '#BFCBD2',
  zoneBlue: '#ACC6EE',
  zoneGreen: '#85CDAB',
  zoneOrange: '#EFB387',
  gradient: {
    sunset: ['#f6836a', '#fea05d'],
    nightfall: ['#7890c9', '#b19dd0'],
    blooming: ['#22b580', '#49ce8f'],
    clearsky: ['#75acff', '#96d4f8'],
    spring: ['#5CC6DD', '#36DCBE'],
    fade: ['#F4F4F4', '#FFFFFF'],
  },
  breakpoints: {
    small: 481,
    medium: 769,
    large: 1025,
    extraLarge: 1201,
    superLarge: 1681,
    ultraLarge: 1921,
  },
};

export default theme;
