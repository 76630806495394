import { useCallback } from 'react';
import styled, { css } from 'styled-components';
import ExternalModal from 'react-modal';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CrossIcon } from '../../assets/icons/close.svg';
import { Button } from '..';

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  overflow-y: hidden;

  ${({ $isWide }) =>
    $isWide
      ? css`
          overflow-y: visible;
          max-height: 100vh;

          @media (min-width: ${({ theme }) => theme.breakpoints.small}px) {
            border-radius: 1rem;
            max-height: calc(100vh - 2rem);
          }
        `
      : css`
          border-radius: 1rem;
          max-height: calc(100vh - 2rem);
        `}

  ${({ $hideHeader, theme }) =>
    $hideHeader &&
    css`
      grid-template-rows: 1fr min-content;
    `}
`;

const Header = styled.div`
  background-color: ${({ theme }) => theme.dark500};
  display: grid;
  align-items: center;
  grid-template-columns: 1fr min-content;
  gap: 2rem;
  padding: 1.6rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.white};
  font-family: 'Inter', sans-serif;
  font-size: 1.8rem;
  line-height: 1.4;
  font-weight: 600;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CloseIcon = styled(CrossIcon)`
  color: ${({ theme }) => theme.white};
`;

const Body = styled.div`
  background-color: ${({ theme }) => theme.white};
  padding: 1.6rem;
  overflow-y: auto;
  display: grid;
  gap: 2rem;

  ${({ $hideHeader }) =>
    $hideHeader &&
    css`
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    `}

  @media only screen and (min-width: ${({ theme }) =>
    theme.breakpoints.small}px) {
    padding: 2.4rem 1.6rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    ${({ $isWide }) =>
      $isWide &&
      css`
        overflow-y: visible;
        height: 100%;
      `}
  }
`;

const CloseButton = styled.button`
  background-color: inherit;
  height: 2.5rem;
  width: 2.5rem;
  border: none;
  cursor: pointer;
  padding: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Actions = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content;
  justify-items: end;
  align-items: center;
  column-gap: 1rem;
  row-gap: 1rem;
  padding: 1.6rem;
  background-color: ${({ theme }) => theme.uiBackground};
  border-top: 1px solid ${({ theme }) => theme.stroke};
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    column-gap: 2rem;
  }

  ${({ $withDelete }) =>
    $withDelete &&
    css`
      grid-template-columns: min-content 1fr;
      grid-template-rows: 1fr 1fr;

      @media only screen and (min-width: ${({ theme }) =>
          theme.breakpoints.small}px) {
        grid-template-columns: min-content 1fr min-content;
        grid-template-rows: 1fr;
      }
    `}
`;

const CancelButtonWrapper = styled.div`
  ${({ $withDelete }) =>
    $withDelete &&
    css`
      justify-self: start;
      grid-area: 2 / 1 / 2 / span 1;
      padding-left: 2.4rem;

      @media only screen and (min-width: ${({ theme }) =>
          theme.breakpoints.small}px) {
        justify-self: end;
        grid-area: 1 / 2 / 1 / span 1;
      }
    `}
`;

const ModalComponent = ({
  children,
  className,
  isOpen,
  onClose,
  onCancel,
  title,
  onAfterClose,
  hideHeader = false,
  isWide = false,
  onConfirm,
  requestLoading,
  cancelText,
  confirmText,
  cancelHighlightColor = 'grapefruitRed',
  confirmHighlightGradient = 'blooming',
  confirmDisabled,
  deleteDisabled,
  footer,
  withDelete,
  onDelete,
  deleteLoading,
  shouldCloseOnOverlayClick = true,
  isClosable = true,
}) => {
  const { t } = useTranslation();

  const memoizedOnClose = useCallback(
    (event) => {
      event.preventDefault();
      if (onClose) {
        onClose();
      }
    },
    [onClose]
  );

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel();
    } else {
      if (onClose) {
        onClose();
      }
    }
  }, [onClose, onCancel]);

  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;

  return (
    <ExternalModal
      ariaHideApp={false}
      bodyOpenClassName="modal-body-open"
      className={`modal-content${contentClassName}`}
      closeTimeoutMS={300}
      htmlOpenClassName="modal-html-open"
      isOpen={isOpen}
      onAfterClose={onAfterClose}
      onRequestClose={memoizedOnClose}
      overlayClassName={overlayClassName}
      portalClassName={className}
      shouldCloseOnOverlayClick={isClosable ? shouldCloseOnOverlayClick : false}
    >
      <Wrapper
        className="modal-wrapper"
        $hideHeader={hideHeader}
        $isWide={isWide}
      >
        {!hideHeader && (
          <Header className="modal-header">
            <Title>{title}</Title>
            {isClosable && (
              <CloseButton
                onClick={onClose}
                title={t('common:button.title.close')}
              >
                <CloseIcon />
              </CloseButton>
            )}
          </Header>
        )}
        <Body className="modal-body" $isWide={isWide} $hideHeader={hideHeader}>
          {children}
        </Body>
        {footer ?? (
          <Actions $withDelete={withDelete}>
            {withDelete ? (
              <Button
                isDisabled={deleteLoading || requestLoading || deleteDisabled}
                highlightColor="grapefruitRed"
                isLoading={deleteLoading}
                onClick={onDelete}
              >
                {t('common:button.delete')}
              </Button>
            ) : null}
            {isClosable && (
              <CancelButtonWrapper $withDelete={withDelete}>
                <Button
                  emphasis="secondary"
                  highlightColor={cancelHighlightColor}
                  isDisabled={requestLoading || deleteLoading}
                  onClick={handleCancel}
                >
                  {cancelText ?? t('common:button.cancel')}
                </Button>
              </CancelButtonWrapper>
            )}
            <Button
              isDisabled={requestLoading || deleteLoading || confirmDisabled}
              highlightGradient={confirmHighlightGradient}
              isLoading={requestLoading}
              onClick={onConfirm}
            >
              {confirmText ?? t('common:button.save')}
            </Button>
          </Actions>
        )}
      </Wrapper>
    </ExternalModal>
  );
};

const Modal = styled(ModalComponent)`
  ${({ shouldAnimate = true }) =>
    shouldAnimate
      ? `
    .ReactModal__Overlay {
      transition: opacity 300ms ease-in-out;
      opacity: 0;

      &--after-open {
        opacity: 1;
      }

      &--before-close {
        opacity: 0;
      }
    }
  `
      : ''}

  &__overlay {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: ${({ zIndex = 2000 }) =>
      zIndex}; /* Make sure modals are placed above anything else */
  }

  &__content {
    border: none;
    border-radius: 0;
    bottom: auto;
    font-weight: 300;
    left: 0;
    max-height: 100vh;
    max-height: 100dvh;
    max-width: 550px;
    min-width: 320px;
    outline: 0;
    overflow: auto;
    padding: 0;
    position: relative;
    right: 0;
    top: 0;
    width: 100%;

    ${({ isWide }) =>
      isWide &&
      css`
        @media (max-width: ${({ theme }) => theme.breakpoints.small}px) {
          min-width: unset;
          max-width: unset;
        }
        @media (min-width: ${({ theme }) => theme.breakpoints.large}px) {
          width: 95vw;
          max-width: unset;
        }
        @media (min-width: ${({ theme }) => theme.breakpoints.extraLarge}px) {
          width: 80vw;
        }

        @media (min-width: ${({ theme }) => theme.breakpoints.superLarge}px) {
          width: 70vw;
          max-width: 1681px;
        }
      `}
  }

  & .modal-wrapper {
    max-height: 100vh;
    max-height: 100dvh;
  }
`;

export default Modal;
