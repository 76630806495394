import gql from 'graphql-tag';

const GET_CONTACT_CALLS = gql`
  query getContactCalls(
    $offset: Int
    $limit: Int
    $userId: ID!
    $filterUser: Boolean!
  ) {
    closedContactCalls(offset: $offset, limit: $limit) @skip(if: $filterUser) {
      id
      summary
      contactCategoryId
      contactPersonId
      contactChannelId
      contactPersonFirstName
      contactPersonLastName
      contactPersonPhone
      contactPersonEmailAddress
      writeDate
      stage
      location {
        id
        name
      }
      agentName
    }
    contactCallsOfUser(userId: $userId, offset: $offset, limit: $limit)
      @include(if: $filterUser) {
      id
      summary
      contactCategoryId
      contactPersonId
      contactChannelId
      contactPersonFirstName
      contactPersonLastName
      contactPersonPhone
      contactPersonEmailAddress
      writeDate
      deadlineDate
      stage
      location {
        id
        name
      }
      agentName
    }
  }
`;

const GET_CONTACT_CATEGORIES = gql`
  query getContactCategories {
    contactCategories {
      id
      name
    }
  }
`;

const EDIT_CONTACT_CALL_INFORMATION = gql`
  mutation editContactCall(
    $contactCallId: ID!
    $contactCall: CreateContactCallInput!
  ) {
    editContactCall(id: $contactCallId, contactCall: $contactCall)
  }
`;

const EDIT_CONTACT_CALL = gql`
  mutation editContactCall(
    $contactCallId: ID!
    $contactCall: CreateContactCallInput!
    $hasFutureContactCall: Boolean!
    $futureContactCall: CreateContactCallInput!
  ) {
    editContactCall(id: $contactCallId, contactCall: $contactCall)
    createContactCall(contactCall: $futureContactCall)
      @include(if: $hasFutureContactCall)
  }
`;

const GET_CONTACT_CATEGORIES_AND_CHANNELS = gql`
  query getContactCategoriesAndChannels {
    contactCategories {
      id
      name
    }
    contactChannels {
      id
      name
    }
  }
`;

const ARCHIVE_CONTACT_CALL = gql`
  mutation archiveContactCall($id: ID!) {
    archiveContactCall(id: $id)
  }
`;

const GET_ORGANIZATIONS_AND_LOCATIONS_OF_USER = gql`
  query getOrganizationsAndLocationDetailsOfUser($userId: ID!) {
    organizationsOfUser(userId: $userId) {
      id
      name
      locations {
        id
        name
      }
    }
  }
`;

const GET_ZONES_COUNT_BY_ID = gql`
  query getZonesCountById {
    getZonesCountById
  }
`;

const GET_ACTIVE_ZONES_COUNT_BY_ID = gql`
  query getActiveZonesCountById {
    getActiveZonesCountById
  }
`;

const GET_ALL_SUBSCRIPTIONS_BY_ID = gql`
  query getAllSubscriptionsByUserId {
    getAllSubscriptionsByUserId {
      id
      code
      organizationName
      organizationId
      zoneName
      zoneId
      locationName
      locationId
      startDate
      endDate
      hasForeverRenewalOn
      subCreatePolicy
      invoiceCount
      recurringInterval
      recurringRuleType
      subscriptionLines {
        id
        priceSubtotal
        product {
          id
          shortName
          isAddon
          isPrepaid
          isForever
          isRoyaltyFree
          type
          registrationDescription
        }
      }
    }
  }
`;

export {
  GET_CONTACT_CALLS,
  GET_CONTACT_CATEGORIES,
  EDIT_CONTACT_CALL,
  GET_CONTACT_CATEGORIES_AND_CHANNELS,
  ARCHIVE_CONTACT_CALL,
  GET_ORGANIZATIONS_AND_LOCATIONS_OF_USER,
  EDIT_CONTACT_CALL_INFORMATION,
  GET_ZONES_COUNT_BY_ID,
  GET_ACTIVE_ZONES_COUNT_BY_ID,
  GET_ALL_SUBSCRIPTIONS_BY_ID,
};
