import { useTranslation } from 'react-i18next';

import { Page } from '../../../common';
import PageHeader from './pageHeader';
import ScheduledCallsPage from './scheduledCallsPage';

const ScheduledCalls = () => {
  const { t } = useTranslation();

  return (
    <Page title={t('customerSuccess:scheduledCalls.title')}>
      <PageHeader />
      <ScheduledCallsPage />
    </Page>
  );
};

export default ScheduledCalls;
