import { useMutation, useQuery } from '@apollo/react-hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { addMinutes, formatISO9075 } from 'date-fns';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as Yup from 'yup';

import {
  DateField,
  ErrorMessage,
  Modal,
  SelectField,
  StringField,
  TextField,
  TimeField,
  createTimeString,
  parseDateString,
  parseTimeStringWithDate,
  useSessionStorage,
} from '../../../../common';
import {
  SUPPORT_ADMIN_ROLE_PERMISSIONS,
  hasAllRequiredPermissions,
} from '../../../../global/auth';
import { useAuth } from '../../../../global/auth/newAuthProvider';
import {
  ARCHIVE_CONTACT_CALL,
  EDIT_CONTACT_CALL_INFORMATION,
  GET_CONTACT_CATEGORIES_AND_CHANNELS,
  GET_ORGANIZATIONS_AND_LOCATIONS_OF_USER,
} from '../api';
import { useCustomerSuccessHistory } from '../historyContext';

const Form = styled.form`
  display: grid;
  grid-gap: 2rem;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 1rem;
  width: 100%;
`;

const DuoColumn = styled.div`
  display: grid;
  grid-gap: 2.4rem 1.6rem;
  grid-template-columns: repeat(2, 1fr);
`;

const SectionLabel = styled.div`
  color: ${({ theme }) => theme.dark200};
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.6;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  width: fit-content;
  padding-top: 4.2rem;
`;

const schema = (t) =>
  Yup.object().shape({
    summary: Yup.string().required(
      t('common:form.validation.summary.required')
    ),
    contactCategoryId: Yup.object()
      .nullable(true)
      .required(t('common:form.validation.contactCategory.required')),
    contactChannelId: Yup.object()
      .nullable(true)
      .required(t('common:form.validation.contactChannel.required')),
    firstName: Yup.string(),
    lastName: Yup.string(),
    phone: Yup.string().required(
      t('common:form.validation.customerSuccessPhone.required')
    ),
    emailAddress: Yup.string()
      .email(t('common:form.validation.emailAddress.type'))
      .required(
        t('common:form.validation.customerSuccessEmailAddress.required')
      ),
    writeDate: Yup.string().required(t('common:form.validation.date.required')),
    writeTime: Yup.string().required(t('common:form.validation.time.required')),
    locationId: Yup.object().nullable(true),
  });

const EditContactModal = ({ isOpen, toggle, historyEntry, title }) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [impersonatorId] = useSessionStorage('impersonationId');
  const { setHasMore } = useCustomerSuccessHistory();

  const isSupportAdmin = hasAllRequiredPermissions(
    user,
    SUPPORT_ADMIN_ROLE_PERMISSIONS
  );

  const date = impersonatorId
    ? historyEntry?.stage === 'OPEN'
      ? historyEntry.deadlineDate
      : historyEntry.writeDate
    : historyEntry.deadlineDate;

  const writeDate = useMemo(() => (date ? parseDateString(date) : ''), [date]);

  const {
    loading: organizationsLoading,
    data: organizationsData,
    error: organizationsError,
  } = useQuery(GET_ORGANIZATIONS_AND_LOCATIONS_OF_USER, {
    variables: {
      userId: Number(historyEntry.contactPersonId),
    },
  });

  const locationOptions = useMemo(() => {
    if (!organizationsData) return [];
    const { organizationsOfUser } = organizationsData;

    const sortedOrganizations = organizationsOfUser.sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    const options = sortedOrganizations.reduce((acc, curr) => {
      const sortedLocations = curr.locations.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      const entries = sortedLocations.map((location) => ({
        value: Number(location.id),
        label: `${curr.name} - ${location.name}`,
      }));
      return [...acc, ...entries];
    }, []);

    return options;
  }, [organizationsData]);

  const {
    loading: contactCategoriesAndChannelsLoading,
    data: contactCategoriesAndChannelsData,
    error: contactCategoriesAndChannelsError,
  } = useQuery(GET_CONTACT_CATEGORIES_AND_CHANNELS, {
    fetchPolicy: 'cache-first',
  });

  const contactCategoriesOptions = useMemo(
    () =>
      contactCategoriesAndChannelsData?.contactCategories.map((category) => ({
        value: Number(category.id),
        label: category.name,
      })),
    [contactCategoriesAndChannelsData?.contactCategories]
  );

  const contactChannelOptions = useMemo(
    () =>
      contactCategoriesAndChannelsData?.contactChannels.map((channel) => ({
        value: Number(channel.id),
        label: channel.name,
      })),
    [contactCategoriesAndChannelsData?.contactChannels]
  );

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
  } = useForm({
    resolver: yupResolver(schema(t)),
    defaultValues: {
      summary: historyEntry.summary,
      contactCategoryId: historyEntry?.contactCategoryId
        ? contactCategoriesOptions?.find(
            (option) => option.value === Number(historyEntry.contactCategoryId)
          )
        : null,
      contactChannelId: historyEntry?.contactChannelId
        ? contactChannelOptions?.find(
            (option) => option.value === Number(historyEntry.contactChannelId)
          )
        : null,
      firstName: historyEntry.contactPersonFirstName,
      lastName: historyEntry.contactPersonLastName,
      phone: historyEntry.contactPersonPhone,
      emailAddress: historyEntry.contactPersonEmailAddress,
      writeDate,
      writeTime: writeDate ? createTimeString(writeDate, true) : '',
      locationId:
        parseInt(historyEntry?.location?.id) === -1
          ? null
          : locationOptions?.find(
              (option) => option.value === parseInt(historyEntry?.location?.id)
            ),
    },
  });

  useEffect(() => {
    if (contactChannelOptions && contactCategoriesOptions && locationOptions) {
      reset({
        ...getValues(),
        contactCategoryId: historyEntry?.contactCategoryId
          ? contactCategoriesOptions.find(
              (option) =>
                option.value === Number(historyEntry.contactCategoryId)
            )
          : null,
        contactChannelId: historyEntry?.contactChannelId
          ? contactChannelOptions.find(
              (option) => option.value === Number(historyEntry.contactChannelId)
            )
          : null,
        locationId:
          parseInt(historyEntry?.location?.id) === -1
            ? null
            : locationOptions.find(
                (option) =>
                  option.value === parseInt(historyEntry?.location?.id)
              ),
      });
    }
  }, [
    contactCategoriesOptions,
    contactChannelOptions,
    historyEntry.contactCategoryId,
    historyEntry.contactChannelId,
    historyEntry?.location?.id,
    locationOptions,
    reset,
    getValues,
  ]);

  const [editContactCall, { loading: requestLoading, error: requestError }] =
    useMutation(EDIT_CONTACT_CALL_INFORMATION, {
      onCompleted: () => {
        // The refetch query will only fetch the 0 ... LIMIT results,
        // we have to inform the infinite list that there are possibly more contact calls to be fetched
        setHasMore(true);
        toggle();
      },
      refetchQueries: ['getContactCalls'],
    });

  const onConfirm = useCallback(
    (values) => {
      let deadlineDate = null;
      const futureDate =
        typeof values.writeDate === 'string'
          ? new Date(values.writeDate)
          : values.writeDate;
      const date = parseTimeStringWithDate(
        values.writeTime.replace(':', ''),
        futureDate
      );
      deadlineDate = formatISO9075(addMinutes(date, date.getTimezoneOffset()));

      editContactCall({
        variables: {
          contactCallId: historyEntry?.id,
          contactCall: {
            summary: values.summary,
            contactCategoryId: Number(values.contactCategoryId?.value),
            contactChannelId: Number(values.contactChannelId?.value),
            contactPersonFirstName: values.firstName,
            contactPersonLastName: values.lastName,
            contactPersonPhone: values.phone,
            contactPersonEmailAddress: values.emailAddress,
            contactPersonId: Number(historyEntry?.contactPersonId),
            deadlineDate,
            locationId: Number(values.locationId?.value),
            agentId: user.impersonatorId,
            stage: historyEntry.stage,
          },
        },
      });
    },
    [
      editContactCall,
      historyEntry?.contactPersonId,
      historyEntry?.id,
      historyEntry.stage,
      user.impersonatorId,
    ]
  );

  const [archiveContactCall, { loading: archiveLoading, error: archiveError }] =
    useMutation(ARCHIVE_CONTACT_CALL, {
      refetchQueries: ['getContactCalls'],
      onCompleted: ({ archiveContactCall: isSuccess }) => {
        if (isSuccess) toggle();
      },
    });

  const handleArchiveContactCall = useCallback(() => {
    const { id } = historyEntry;
    archiveContactCall({
      variables: {
        id: Number(id),
      },
    });
  }, [archiveContactCall, historyEntry]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={toggle}
      title={title || t('customerSuccess:contact.title')}
      onConfirm={handleSubmit(onConfirm)}
      withDelete={!!isSupportAdmin}
      deleteLoading={archiveLoading}
      deleteDisabled={archiveError}
      requestLoading={requestLoading}
      onDelete={handleArchiveContactCall}
    >
      {/* No `onSubmit` necessary, because form does not contain a submit button. */}
      <Form>
        <TextField
          control={control}
          error={errors?.summary?.message}
          label={t('common:form.label.summary')}
          name="summary"
          rows={4}
          placeholder={t('common:form.placeholder.logContactSummary')}
        />
        <SelectField
          control={control}
          error={errors?.locationId?.message}
          isLoading={organizationsLoading}
          label={t('common:form.label.location')}
          name="locationId"
          options={locationOptions}
          placeholder={t('common:form.placeholder.location')}
        />
        <DuoColumn>
          <SelectField
            control={control}
            error={errors?.contactCategoryId?.message}
            isLoading={contactCategoriesAndChannelsLoading}
            label={t('common:form.label.contactCategory')}
            name="contactCategoryId"
            options={contactCategoriesOptions}
            placeholder={t('common:form.placeholder.contactCategory')}
          />
          <SelectField
            control={control}
            error={errors?.contactChannelId?.message}
            isLoading={contactCategoriesAndChannelsLoading}
            label={t('common:form.label.contactChannel')}
            name="contactChannelId"
            options={contactChannelOptions}
            placeholder={t('common:form.placeholder.contactChannel')}
          />
        </DuoColumn>
        <DuoColumn>
          <DateField
            control={control}
            error={errors?.writeDate?.message}
            label={t('common:form.label.date')}
            name="writeDate"
            placeholder={t('common:form.placeholder.date')}
          />
          <TimeField
            control={control}
            error={errors?.writeTime?.message}
            label={t('common:form.label.hour')}
            name="writeTime"
            placeholder={t('common:form.placeholder.hour')}
          />
        </DuoColumn>
        <SectionLabel>{t('common:form.group.contactDetails')}</SectionLabel>
        <DuoColumn>
          <StringField
            register={register}
            error={errors?.firstName?.message}
            label={t('common:form.label.firstName')}
            name="firstName"
            placeholder={t('common:form.placeholder.customerSuccessFirstName')}
          />
          <StringField
            register={register}
            error={errors?.lastName?.message}
            label={t('common:form.label.lastName')}
            name="lastName"
            placeholder={t('common:form.placeholder.customerSuccessLastName')}
          />
          <StringField
            register={register}
            error={errors?.phone?.message}
            label={t('common:form.label.phone')}
            name="phone"
            placeholder={t('common:form.placeholder.customerSuccessPhone')}
          />
          <StringField
            register={register}
            error={errors?.emailAddress?.message}
            label={t('common:form.label.emailAddress')}
            name="emailAddress"
            placeholder={t(
              'common:form.placeholder.customerSuccessEmailAddress'
            )}
          />
        </DuoColumn>
        {(requestError ||
          contactCategoriesAndChannelsError ||
          organizationsError ||
          archiveError) && (
          <StyledErrorMessage
            error={
              requestError ||
              contactCategoriesAndChannelsError ||
              organizationsError ||
              archiveError
            }
          />
        )}
      </Form>
    </Modal>
  );
};

export default EditContactModal;
