import { css } from 'styled-components';

const sizes = {
  primary: {
    small: css`
      height: 3.2rem;
      padding: 0 1.6rem;
      & p {
        font-size: 1.4rem;
      }
    `,
    medium: css`
      height: 4rem;
      padding: 0 2rem;
      & p {
        font-size: 1.4rem;
      }
    `,
    large: css`
      height: 4.8rem;
      padding: 0 2.4rem;
      & p {
        font-size: 1.6rem;
      }
    `,
  },
  secondary: {
    small: css`
      border-bottom: 1px solid ${({ theme }) => theme.waterBlue};
      padding-bottom: 0;
      line-height: 1.3;

      & p {
        font-size: 1.4rem;
      }
    `,
    medium: css`
      border-bottom: 2px solid ${({ theme }) => theme.waterBlue};
      padding-bottom: 0.2rem;
      line-height: 1.4;

      & p {
        font-size: 1.4rem;
      }
    `,
    large: css`
      border-bottom: 2px solid ${({ theme }) => theme.waterBlue};
      padding-bottom: 0.2rem;
      line-height: 1.4;

      & p {
        font-size: 1.6rem;
      }
    `,
  },
  outline: {
    small: css`
      height: 3.2rem;
      padding: 0 1.6rem;
      & p {
        font-size: 1.4rem;
      }
    `,
    medium: css`
      height: 4rem;
      padding: 0 2rem;
      & p {
        font-size: 1.4rem;
      }
    `,
    large: css`
      height: 4.8rem;
      padding: 0 2.4rem;
      & p {
        font-size: 1.6rem;
      }
    `,
  },
  icon: {
    medium: css`
      height: 3rem;
      width: 3rem;
      & p {
        font-size: 1.4rem;
      }
    `,
    large: css`
      height: 5rem;
      width: 5rem;
      & p {
        font-size: 1.6rem;
      }
    `,
  },
};

export default sizes;
