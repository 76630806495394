import { isFuture } from '../../../../common';

export const determineSubCreatePolicyForCartItem = (zone) => {
  if (zone.lastSubscription) {
    if (isFuture(zone.lastSubscription.endDate)) {
      return { subCreatePolicy: 'renew', originSubscriptionId: Number(zone.lastSubscription.id) };
    }
  }

  // If the zone never had a previous subscriptions, or if the
  // current subscription's end date is in the past, set no subCreate
  // policy. The back-end will interpret this as none existing.
  return { subCreatePolicy: undefined, originSubscriptionId: undefined };
};
