import { useMutation } from '@apollo/react-hooks';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  Button,
  ErrorMessage,
  GoBackButton,
  HiddenIngenicoForm,
  InfoCard,
  Page,
} from '../../../common';
import { TermsAndConditions } from '../../../common/termsAndConditions';
import config from '../../../config';
import { useAuth } from '../../../global/auth/newAuthProvider';
import { PREPARE_PAYMENT } from './api';
import { OrganizationDetails, PaymentOptions, SaleOrderTable } from './cards';
import { useSaleOrder } from './saleOrderContext';
import SaleOrderProvider from './saleOrderProvider';

const { BASE_URL } = config;

const StyledPage = styled(Page)`
  display: grid;
  grid-auto-rows: min-content 1fr min-content;
  height: 100%;
  overflow-y: auto;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    grid-template-rows: 1fr 8rem;
    grid-template-columns: 1fr 2fr;
  }
`;

const StyledButton = styled(Button)`
  & p {
    color: ${({ theme }) => theme.white};
  }
  &:hover {
    border-color: ${({ theme }) => theme.dark200};
  }
`;

const Left = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  justify-items: start;
  gap: 2rem;
  background-color: ${({ theme }) => theme.white};
  padding: 1.6rem;
  border: 1px solid ${({ theme }) => theme.stroke};

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    padding: 3.2rem;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    grid-area: 1 / 1 / span 2 / 1;
    overflow-y: auto;
    padding: 6.4rem 3.2rem 10rem 3.2rem;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    padding: 6.4rem 4.8rem 10rem 4.8rem;
  }
`;

const Right = styled.div`
  display: grid;
  grid-auto-rows: min-content;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    padding: 1.6rem;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    padding: 3.2rem;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    grid-area: 1 / 2 / 1 / 2;
    padding: 7.2rem 3.2rem;
    overflow-y: auto;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    padding: 7.2rem;
  }
`;

const Bottom = styled.div`
  min-height: 7rem;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background-color: ${({ theme }) => theme.dark500};
  z-index: 1;
  padding: 0 1.6rem;
  position: sticky;
  bottom: 0;
  left: 0;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    grid-area: 2 / 2 / 2 / 2;
    padding: 0 3.2rem;
  }
`;

const Title = styled.h1`
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1.4;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    font-size: 2.8rem;
  }
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin: 1rem 0;
`;

const StyledTermsAndConditions = styled(TermsAndConditions)`
  margin-top: 2rem;
`;

const SaleOrderContent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { organizationId, saleOrderId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const formRef = useRef(null);
  const {
    selectedPaymentMethod,
    alteredSaleOrder,
    organization,
    cartChangeHasError,
    cartIsChanging,
  } = useSaleOrder();

  const [istermsAndConditionsChecked, setIsTermsAndConditionsChecked] =
    useState(false);

  const [
    preparePayment,
    { loading: createPaymentLoading, error: createPaymentError },
  ] = useMutation(PREPARE_PAYMENT, {
    onCompleted: ({ preparePayment: preparePaymentResult }) => {
      if (
        preparePaymentResult.wireTransferReference &&
        preparePaymentResult.iban &&
        preparePaymentResult.bic
      ) {
        // / User selected wire transfer and will not be redirected to Ingenico
        const { amount, wireTransferReference, iban } = preparePaymentResult;
        navigate(
          '/payment-feedback/wire-transfer',
          {
            state: {
              amount,
              iban,
              wireTransferReference,
            },
          }
        );
      } else {
        formRef.current.PSPID.value = preparePaymentResult.ogonePspid;
        formRef.current.ORDERID.value = preparePaymentResult.reference;
        formRef.current.AMOUNT.value = Number(preparePaymentResult.amount);
        formRef.current.CURRENCY.value = preparePaymentResult.currency;
        formRef.current.SHASIGN.value = preparePaymentResult.ingenicoShaIn;
        formRef.current.ALIAS.value = preparePaymentResult.ogoneAlias;
        formRef.current.ALIASUSAGE.value = preparePaymentResult.ogoneAliasUsage;
        formRef.current.OWNERCTY.value = preparePaymentResult.ownerCountryCode;
        formRef.current.submit();
      }
    },
  });

  const handlePay = useCallback(() => {
    const inputs = formRef.current.querySelectorAll('input');
    const newPaymentValues = {};
    inputs.forEach((input) => {
      if (
        input.name !== 'PSPID' &&
        input.name !== 'ORDERID' &&
        input.name !== 'AMOUNT' &&
        input.name !== 'CURRENCY' &&
        input.name !== 'SHASIGN' &&
        input.name !== 'ALIAS' &&
        input.name !== 'ALIASUSAGE'
      ) {
        newPaymentValues[input.name] = input.value;
      }
    });

    preparePayment({
      variables: {
        ingenicoParams: newPaymentValues,
        saleOrderId: Number(alteredSaleOrder.id),
        paymentAcquirerId: Number(selectedPaymentMethod.paymentAcquirerId),
        paymentIconId: Number(selectedPaymentMethod.id),
      },
    });
  }, [preparePayment, alteredSaleOrder, selectedPaymentMethod]);

  const isPayDisabled = useMemo(
    () =>
      !istermsAndConditionsChecked ||
      !selectedPaymentMethod ||
      cartIsChanging ||
      cartChangeHasError ||
      alteredSaleOrder.saleOrderLines.length === 0,
    [
      istermsAndConditionsChecked,
      selectedPaymentMethod,
      cartIsChanging,
      cartChangeHasError,
      alteredSaleOrder.saleOrderLines.length,
    ]
  );

  const handleOnCancel = useCallback(() => {
    navigate('/subscriptions');
  }, [navigate]);

  useEffect(() => {
    const zoneIds = alteredSaleOrder.saleOrderLines.map((so) => so.zone.id).join(',');
    let paramContent = { zoneIds };
    if (alteredSaleOrder?.id) {
      paramContent = { ...paramContent, saleOrderId: alteredSaleOrder.id };
    }
    setSearchParams(paramContent);
  }, [alteredSaleOrder?.id, alteredSaleOrder?.saleOrderLines, setSearchParams]);

  const paramPlus = useMemo(() => searchParams.toString(), [searchParams]);

  const handleTermsAndConditionsChange = useCallback((event) => {
    setIsTermsAndConditionsChecked(event.target.checked);
  }, []);

  return (
    <>
      <StyledPage title={t('purchase:saleOrder.title')}>
        <Left>
          <GoBackButton
            to="/subscriptions"
            label={t('common:button.cancel')}
            title={t('common:button.title.cancel')}
          />
          <Title>{t('purchase:saleOrder.title')}</Title>
          <OrganizationDetails />
        </Left>
        <Right>
          {createPaymentError && (
            <StyledErrorMessage error={createPaymentError} />
          )}
          <InfoCard title={t('subscriptions:buyLastStepTitle')}>
            <SaleOrderTable />
            <PaymentOptions />
            <StyledTermsAndConditions
              onChange={handleTermsAndConditionsChange}
            />
          </InfoCard>
        </Right>
        <Bottom>
          <StyledButton emphasis="outline" onClick={handleOnCancel}>
            {t('common:button.cancel')}
          </StyledButton>
          <Button
            emphasis="primary"
            highlightGradient="blooming"
            isDisabled={isPayDisabled}
            isLoading={createPaymentLoading}
            onClick={handlePay}
          >
            {t('common:button.pay')}
          </Button>
        </Bottom>
      </StyledPage>
      {alteredSaleOrder && (
        <HiddenIngenicoForm
          ref={formRef}
          brand={selectedPaymentMethod?.brand || ''}
          cn={`${user.firstName} ${user.lastName}`}
          email={user.emailAddress}
          language={user.lang}
          orderId={alteredSaleOrder.id}
          ownerAddress={`${organization.streetName} ${organization.streetNumber}`}
          ownerCty={organization.countryId}
          ownerTown={organization.city}
          ownerZip={organization.zip}
          paramPlus={paramPlus}
          pm={selectedPaymentMethod?.pm || ''}
          redirectUrlOnAccept={`${BASE_URL}/payment-feedback/accept`}
          redirectUrlOnCancel={`${BASE_URL}/payment-feedback/cancel`}
          redirectUrlOnDecline={`${BASE_URL}/payment-feedback/decline`}
          redirectUrlOnException={`${BASE_URL}/payment-feedback/exception`}
        />
      )}
    </>
  );
};

const SaleOrderPage = () => {
  return (
    <SaleOrderProvider>
      <SaleOrderContent />
    </SaleOrderProvider>
  );
};

export default SaleOrderPage;
