import { useRef, useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import ReactSlider from 'react-slider';
import { format, secondsToMilliseconds } from 'date-fns';

import { useOnClickOutside } from '../../../../../../common';
import { Button } from '../../../../../../common/oldButton';
import { ReactComponent as RawPlayIcon } from '../../../../../../assets/icons/simplePlayControls/play.svg';
import { ReactComponent as RawSubPlayIcon } from '../../../../../../assets/icons/playControls/play.svg';
import { ReactComponent as RawPauseIcon } from '../../../../../../assets/icons/playControls/pause.svg';
import { ReactComponent as RawCrossIcon } from '../../../../../../assets/icons/close.svg';
import { ReactComponent as RawFastForwardIcon } from '../../../../../../assets/icons/playControls/fastForward.svg';
import { useTunifyBlue } from '../../tunifyBlueProvider';

const Container = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, min-content) 1fr repeat(2, min-content);
  align-items: center;
  gap: 0.8rem;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

const MainButtons = styled.div`
  display: grid;
  grid-auto-flow: column;
  padding-left: 0.1rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    padding-left: 0.2rem;
  }
`;

const iconCss = css`
  width: 1rem;
  height: 1rem;
  justify-self: center;

  & path {
    fill: ${({ theme }) => theme.dark300};
  }
`;

const PlayIcon = styled(RawPlayIcon)`
  ${iconCss}
  margin-left: 0.15rem;
`;

const SubPlayIcon = styled(RawSubPlayIcon)`
  ${iconCss}
`;

const PauseIcon = styled(RawPauseIcon)`
  ${iconCss}
`;

const FastForwardIcon = styled(RawFastForwardIcon)`
  ${iconCss}
  width: 1.1rem;
  height: 1.1rem;
`;

const WindBackIcon = styled(RawFastForwardIcon)`
  ${iconCss}
  width: 1.1rem;
  height: 1.1rem;
  transform: rotate(180deg);
`;

const CrossIcon = styled(RawCrossIcon)`
  ${iconCss}
  width: 0.9rem;
  height: 0.9rem;
`;

const Time = styled.p`
  margin: 0;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.dark300};
`;

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 0.4rem;
`;

const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background: ${({ index, invertColors, theme }) => {
    if (index === 2) {
      return theme.balck;
    }
    if (index === 1) {
      return invertColors ? theme.white : theme.stroke;
    }
    return theme.babyBlue;
  }};
  ${({ index }) =>
    index === 0 &&
    css`
      z-index: 10;
    `}
  border-radius: 999px;
`;

const AudioBar = ({ onClose, invertColors, isLoading }) => {
  const { t } = useTranslation();
  const buttonRef = useRef(null);

  const {
    isPlaying,
    progress,
    startStopAudio,
    changeSliderAudio,
    fastForwardAudio,
    windBackAudio,
    stopAudio,
    setProgressToCurrentTime,
    duration,
  } = useTunifyBlue();

  useOnClickOutside(buttonRef, stopAudio, 'mouseup');

  const Track = useCallback(
    (props, state) => (
      <StyledTrack {...props} index={state.index} invertColors={invertColors} />
    ),
    [invertColors]
  );

  useEffect(() => {
    let interval = null;
    if (!isLoading) {
      if (isPlaying) {
        interval = setInterval(() => {
          setProgressToCurrentTime();
        }, 200);
      } else if (!isPlaying && progress !== 0) {
        clearInterval(interval);
      }
    }
    return () => interval && clearInterval(interval);
  }, [isPlaying, progress, isLoading, setProgressToCurrentTime]);

  return (
    <>
      {isLoading ? (
        <Container ref={buttonRef} disabled={isLoading}>
          <MainButtons>
            <Button
              flavor="icon"
              title={t('broadcasts:card.table.play')}
              type="button"
            >
              <PlayIcon />
            </Button>
            <Button
              flavor="icon"
              title={t('broadcasts:card.table.play')}
              type="button"
            >
              <WindBackIcon />
            </Button>
            <Button
              flavor="icon"
              title={t('broadcasts:card.table.play')}
              type="button"
            >
              <FastForwardIcon />
            </Button>
          </MainButtons>
          <Time>0:00</Time>
          <StyledSlider
            defaultValue={0}
            max={1}
            onSliderClick={changeSliderAudio}
            renderTrack={Track}
            step={0.001}
            value={0}
          />
          <Time>0:00</Time>
          <Button
            flavor="icon"
            title={t('broadcasts:card.table.play')}
            type="button"
          >
            <CrossIcon />
          </Button>
        </Container>
      ) : (
        <>
          <Container ref={buttonRef} disabled={isLoading}>
            <MainButtons>
              <Button
                flavor="icon"
                onClick={startStopAudio}
                title={t('broadcasts:card.table.play')}
                type="button"
              >
                {isPlaying ? <PauseIcon /> : <SubPlayIcon />}
              </Button>
              <Button
                flavor="icon"
                onClick={windBackAudio}
                title={t('broadcasts:card.table.play')}
                type="button"
              >
                <WindBackIcon />
              </Button>
              <Button
                flavor="icon"
                onClick={fastForwardAudio}
                title={t('broadcasts:card.table.play')}
                type="button"
              >
                <FastForwardIcon />
              </Button>
            </MainButtons>
            <Time>
              {progress
                ? format(
                    new Date(secondsToMilliseconds(Math.round(progress))),
                    'm:ss'
                  )
                : '0:00'}
            </Time>
            <StyledSlider
              defaultValue={0}
              max={1}
              onSliderClick={changeSliderAudio}
              renderTrack={Track}
              step={0.001}
              value={duration ? progress / duration : 0}
            />
            <Time>
              {duration
                ? format(
                    new Date(secondsToMilliseconds(Math.round(duration))),
                    'm:ss'
                  )
                : '0:00'}
            </Time>
            <Button
              flavor="icon"
              onClick={onClose}
              title={t('broadcasts:card.table.play')}
              type="button"
            >
              <CrossIcon />
            </Button>
          </Container>
        </>
      )}
    </>
  );
};

export default AudioBar;
