import gql from 'graphql-tag';

const GET_PARTNER_ORGANIZATION = gql`
  query getPartnerOrganization($organizationId: ID!, $withOrganization: Boolean!) {
    organization(id: $organizationId) @include(if: $withOrganization) {
      id
      name
      vat
      streetName
      streetNumber
      zip
      city
      nextCommissionDate
    }
    partnerOrganization @skip(if: $withOrganization) {
      id
      name
      vat
      streetName
      streetNumber
      zip
      city
      nextCommissionDate
    }
  }
`;

const GET_PARTNER_PROGRAM = gql`
  query getPartnerProgram($organizationId: ID!) {
    partnerProgram(organizationId: $organizationId) {
      id
      description
      commissionTriggerMonths
      partnerProgramLines {
        id
        code
        musicLicense {
          id
          name
        }
      }
    }
  }
`;

const GET_PARTNER_COMMISSION_INVOICES = gql`
  query getPartnerCommissionInvoices($organizationId: ID!, $offset: Int!, $limit: Int!) {
    partnerCommissionInvoices(organizationId: $organizationId, offset: $offset, limit: $limit) {
      id
      createDate
      invoiceDate
      number
      totalCommission
      state
    }
  }
`;

const GET_PARTNER_COMMISSION_INVOICE_PDF = gql`
  query getPartnerCommissionInvoicePdf($partnerCommissionInvoiceId: ID!) {
    partnerCommissionInvoicePdf(partnerCommissionInvoiceId: $partnerCommissionInvoiceId)
  }
`;

const GET_PARTNER_CUSTOMERS_COUNT = gql`
  query getPartnerCustomersCount($organizationId: ID!) {
    activeCustomersCount: partnerCustomersCount(organizationId: $organizationId, filter: "active")
    winnerCustomersCount: partnerCustomersCount(organizationId: $organizationId, filter: "winner")
    qualifierCustomersCount: partnerCustomersCount(organizationId: $organizationId, filter: "qualifier")
    referredCustomersCount: partnerCustomersCount(organizationId: $organizationId, filter: "referred")
  }
`;

const GET_PARTNER_REFERRED_CUSTOMERS = gql`
  query getPartnerReferredCustomers($organizationId: ID!, $offset: Int!, $limit: Int!) {
    partnerReferredCustomers(organizationId: $organizationId, offset: $offset, limit: $limit) {
      id
      name
      createDate
      location {
        id
        name
      }
      currentSubscription {
        id
        subscriptionLines {
          id
          product {
            id
            name
            isAddon
          }
        }
      }
      lastSubscription {
        id
        endDate
        subscriptionLines {
          id
          product {
            id
            name
            isAddon
          }
        }
      }
    }
  }
`;

const GET_PARTNER_QUALIFIER_CUSTOMERS = gql`
  query getPartnerQualifierCustomers($organizationId: ID!, $offset: Int!, $limit: Int!) {
    partnerQualifierCustomers(organizationId: $organizationId, offset: $offset, limit: $limit) {
      id
      name
      createDate
      numberOfMonths
      location {
        id
        name
      }
      currentSubscription {
        id
        subscriptionLines {
          id
          product {
            id
            name
            isAddon
          }
        }
      }
      lastSubscription {
        id
        endDate
        subscriptionLines {
          id
          product {
            id
            name
            isAddon
          }
        }
      }
    }
  }
`;

const GET_PARTNER_WINNER_CUSTOMERS = gql`
  query getPartnerWinnerCustomers($organizationId: ID!, $offset: Int!, $limit: Int!) {
    partnerWinnerCustomers(organizationId: $organizationId, offset: $offset, limit: $limit) {
      id
      name
      createDate
      commissionDetails {
        finderCommissionAmount
        commissionProduct {
          name
        }
      }
      location {
        id
        name
      }
      lastSubscription {
        id
        endDate
        subscriptionLines {
          id
          product {
            id
            name
            isAddon
          }
        }
      }
    }
    winnerTotalCommission(organizationId: $organizationId)
  }
`;

export {
  GET_PARTNER_ORGANIZATION,
  GET_PARTNER_PROGRAM,
  GET_PARTNER_COMMISSION_INVOICES,
  GET_PARTNER_COMMISSION_INVOICE_PDF,
  GET_PARTNER_CUSTOMERS_COUNT,
  GET_PARTNER_REFERRED_CUSTOMERS,
  GET_PARTNER_QUALIFIER_CUSTOMERS,
  GET_PARTNER_WINNER_CUSTOMERS,
};
