import gql from 'graphql-tag';

const GET_ORGANIZATIONS_AND_ZONES_FOR_ORGANIZATION = gql`
  query getOrganizationsAndZonesForOrganization($withOrganization: Boolean!, $organizationId: ID!) {
    organizations {
      id
      name
    }

    organization(id: $organizationId) @include(if: $withOrganization) {
      ...organizationDetailsWithZones
    }
    initialOrganization: organizations(limit: 1) @skip(if: $withOrganization) {
      ...organizationDetailsWithZones
    }
  }

  fragment organizationDetailsWithZones on Organization {
    id
    name
    locations {
      id
      name
    }
    zones {
      id
      name
      locationId
    }
  }
`;

const COUPLE_ZONE_TO_SONOS = gql`
  mutation coupleZoneToSonos($zoneId: ID!, $linkCode: String!) {
    coupleZoneToSonos(zoneId: $zoneId, linkCode: $linkCode)
  }
`;

export { GET_ORGANIZATIONS_AND_ZONES_FOR_ORGANIZATION, COUPLE_ZONE_TO_SONOS };
