import styled from 'styled-components';

import { ReactComponent as AngleDown } from '../../../assets/icons/angleDown.svg';
import { ReactComponent as AngleUp } from '../../../assets/icons/angleUp.svg';
import { Button } from '../../button';
import { useToggle } from '../../hooks';
import theme from '../../../global/style/theme';

const Card = styled.div`
  width: 100%;
  max-width: 72rem;
  justify-self: center;
  display: grid;
  grid-template-rows: 7.4rem 1fr;
  border: 1px solid ${({ theme }) => theme.stroke};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.white};

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    grid-template-rows: 8.8rem 1fr;
  }
`;

const CardHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;
  padding: 0 1.6rem;
  border-radius: 8px 8px 0 0;
  background-color: ${({ theme }) => theme.uiBackground};
  border-bottom: 1px solid ${({ theme }) => theme.stroke};

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    padding: 0 4rem;
  }
`;

const CardBody = styled.div`
  display: grid;
  padding: 2rem 1.6rem;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    padding: 3.2rem 4rem;
  }
`;

const SectionLabel = styled.h2`
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.4;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    font-size: 2.4rem;
  }
`;

const InfoCard = ({ title, className, children, childrenHeader, withToggle }) => {
  const [isExpand, toggleIsExpand] = useToggle(true);

  return (
    <Card className={className}>
      <CardHeader>
        <SectionLabel>{title}</SectionLabel>
        {withToggle ? (
          <Button emphasis="icon" highlightColor="transparent" onClick={toggleIsExpand}>
            {isExpand ? (
              <AngleUp width="1.2rem" height="1.2rem" color={theme.dark200} />
            ) : (
              <AngleDown width="1.2rem" height="1.2rem" color={theme.dark200} />
            )}
          </Button>
        ) : (
          <div>{childrenHeader}</div>
        )}
      </CardHeader>
      {isExpand && <CardBody>{children}</CardBody>}
    </Card>
  );
};

export default InfoCard;
