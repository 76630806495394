export const getEquivalentOfAddon = (currentAddon, availableAddons, userWantsForever) => {
  const equivalentAddons = availableAddons.filter((availableAddon) => {
    const shortnameTest = availableAddon.shortName === currentAddon.shortName;
    const recurringIntervalTest =
      availableAddon.subscriptionTemplate.recurringInterval === currentAddon.subscriptionTemplate.recurringInterval;
    const foreverTest = availableAddon.isForever === userWantsForever;
    return shortnameTest && recurringIntervalTest && foreverTest;
  });

  if (equivalentAddons.length === 0) {
    throw new Error(
      `Did not find a [${userWantsForever ? 'forever' : 'fixed'}] equivalent of addon with id [${currentAddon.id}]`
    );
  }

  if (equivalentAddons.length > 1) {
    throw new Error(
      `Found multiple [${userWantsForever ? 'forever' : 'fixed'}] equivalents of addon with id [${currentAddon.id}]`
    );
  }

  return equivalentAddons[0];
};
