import { Fragment } from 'react';
import styled from 'styled-components';

import SaleOrderRow from './saleOrderRow';
import SaleOrderCostSummary from './saleOrderCostSummary';
import { useSaleOrder } from '../../saleOrderContext';

const Container = styled.div`
  display: grid;
  gap: 1rem;
  background-color: ${({ theme }) => theme.dark500};
  color: ${({ theme }) => theme.white};
  border-radius: 4px;
  padding: 1.4rem 2rem;
  margin-bottom: 2rem;
`;

const HorizontalRule = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.dark400};
`;

const SaleOrderTable = () => {
  const { initialSaleOrder } = useSaleOrder();

  return (
    <Container>
      {initialSaleOrder?.saleOrderLines?.map((saleOrderLine, index) => (
        <Fragment key={saleOrderLine.id}>
          {index > 0 ? <HorizontalRule /> : null}
          <SaleOrderRow saleOrderLine={saleOrderLine} />
        </Fragment>
      ))}
      <SaleOrderCostSummary />
    </Container>
  );
};

export default SaleOrderTable;
