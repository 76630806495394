import { buildCartItems } from './utils';

export const RenewPurchaseAction = {
  FETCHED_INITIAL_QUERY: 'FETCHED_INITIAL_QUERY',
  SELECT_ZONE: 'SELECT_ZONE',
  DESELECT_ZONE: 'DESELECT_ZONE',
  TOGGLE_FOREVER_PRODUCT_SWITCH: 'TOGGLE_FOREVER_PRODUCT_SWITCH',
  UPDATED_CART_ITEMS: 'UPDATED_CART_ITEMS',
  FETCHED_PAYMENT_OPTIONS: 'FETCHED_PAYMENT_OPTIONS',
  SELECT_PAYMENT_OPTION: 'SELECT_PAYMENT_OPTION',
  CLEAR_STATE: 'CLEAR_STATE',
};

export const initialRenewPurchaseState = {
  userInput: { selectedZones: [], selectedPaymentOption: null, wantsForever: true },
  organization: null,
  zones: [],
  availableProducts: [],
  availablePaidAddons: [],
  availablePaymentOptions: [],
  saleOrder: null,
  saleOrderProductIds: [],
  cartItemsToAdd: [],
  cartItemIdsToRemove: [],
  alternativeRenewalSaleOrder: null,
  alternativeRenewalSaleOrderProductIds: [],
  alternativeRenewalCartItemsToAdd: [],
  alternativeRenewalCartItemIdsToRemove: [],
};

export const renewPurchaseReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case RenewPurchaseAction.FETCHED_INITIAL_QUERY: {
      const {
        initialQuery: { organization, availablePaidAddons },
      } = payload;

      const cartItemsToAddFixed = buildCartItems(
        organization.zonesWithARenewableSubscription,
        organization.zonesWithARenewableSubscription,
        organization.availablePaidProducts,
        availablePaidAddons,
        false
      );

      const cartItemsToAddForever = buildCartItems(
        organization.zonesWithARenewableSubscription,
        organization.zonesWithARenewableSubscription,
        organization.availablePaidProducts,
        availablePaidAddons,
        true
      );

      const saleOrderProductIdsFixed = Array.from(new Set(cartItemsToAddFixed.map((cartItem) => cartItem.productId)));

      const saleOrderProductIdsForever = Array.from(new Set(cartItemsToAddForever.map((cartItem) => cartItem.productId)));

      return {
        ...state,
        userInput: {
          ...state.userInput,
          selectedZones: organization.zonesWithARenewableSubscription,
        },
        organization,
        zones: organization.zonesWithARenewableSubscription,
        availableProducts: organization.availablePaidProducts,
        availablePaidAddons,
        cartItemsToAdd: state.wantsForever ? cartItemsToAddForever : cartItemsToAddFixed,
        saleOrderProductIds: state.wantsForever ? saleOrderProductIdsForever : saleOrderProductIdsFixed,
        alternativeRenewalCartItemsToAdd: state.wantsForever ? cartItemsToAddFixed : cartItemsToAddForever,
        alternativeRenewalSaleOrderProductIds: state.wantsForever ? saleOrderProductIdsFixed : saleOrderProductIdsForever,
      };
    }

    case RenewPurchaseAction.SELECT_ZONE: {
      const { zone } = payload;

      const cartItemsToAdd = buildCartItems(
        [zone],
        state.zones,
        state.availableProducts,
        state.availablePaidAddons,
        state.wantsForever
      );

      const alternativeRenewalCartItemsToAdd = buildCartItems(
        [zone],
        state.zones,
        state.availableProducts,
        state.availablePaidAddons,
        !state.wantsForever
      );

      const saleOrderProductIds = Array.from(
        new Set([...state.saleOrderProductIds, ...cartItemsToAdd.map((cartItem) => cartItem.productId)])
      );

      const alternativeRenewalSaleOrderProductIds = Array.from(
        new Set([...state.alternativeRenewalSaleOrderProductIds, ...alternativeRenewalCartItemsToAdd.map((cartItem) => cartItem.productId)])
      );

      return {
        ...state,
        userInput: {
          ...state.userInput,
          selectedZones: [...state.userInput.selectedZones, zone],
        },
        cartItemsToAdd,
        saleOrderProductIds,
        alternativeRenewalSaleOrderProductIds,
      };
    }

    case RenewPurchaseAction.DESELECT_ZONE: {
      const { zone } = payload;

      const cartItemIdsToRemove = state.saleOrder.saleOrderLines
        .filter((saleOrderLine) => saleOrderLine.zone.id === zone.id)
        .map((saleOrderLine) => saleOrderLine.cartLineId);

      const alternativeRenewalCartItemIdsToRemove = state.alternativeRenewalSaleOrder.saleOrderLines
        .filter((saleOrderLine) => saleOrderLine.zone.id === zone.id)
        .map((saleOrderLine) => saleOrderLine.cartLineId);

      const remainingSaleOrderProductIds = state.saleOrder.saleOrderLines
        .filter((saleOrderLine) => !cartItemIdsToRemove.includes(saleOrderLine.cartLineId))
        .map((saleOrderLine) => Number(saleOrderLine.product.id));

      const alternativeRenewalRemainingSaleOrderProductIds = state.alternativeRenewalSaleOrder.saleOrderLines
        .filter((saleOrderLine) => !cartItemIdsToRemove.includes(saleOrderLine.cartLineId))
        .map((saleOrderLine) => Number(saleOrderLine.product.id));

      const saleOrderProductIds = Array.from(new Set(remainingSaleOrderProductIds));

      const alternativeRenewalSaleOrderProductIds = Array.from(new Set(alternativeRenewalRemainingSaleOrderProductIds));

      return {
        ...state,
        userInput: {
          ...state.userInput,
          selectedZones: state.userInput.selectedZones.filter((selectedZone) => selectedZone.id !== zone.id),
        },
        cartItemIdsToRemove,
        saleOrderProductIds,
        alternativeRenewalCartItemIdsToRemove,
        alternativeRenewalSaleOrderProductIds,
      };
    }

    case RenewPurchaseAction.SELECT_PAYMENT_OPTION: {
      const { paymentOption } = payload;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          selectedPaymentOption: paymentOption,
          wantsForever: paymentOption.supportsRecurringBilling,
        },
        ...paymentOption.supportsRecurringBilling !== state.userInput.wantsForever
          ? {
            cartItemIdsToRemove: state.alternativeRenewalCartItemIdsToRemove,
            cartItemsToAdd: state.alternativeRenewalCartItemsToAdd,
            saleOrderProductIds: state.alternativeRenewalSaleOrderProductIds,
            saleOrder: state.alternativeRenewalSaleOrder,
            alternativeRenewalCartItemIdsToRemove: state.cartItemIdsToRemove,
            alternativeRenewalCartItemsToAdd: state.cartItemsToAdd,
            alternativeRenewalSaleOrderProductIds: state.saleOrderProductIds,
            alternativeRenewalSaleOrder: state.saleOrder,
          }
          : null,
      };
    }

    case RenewPurchaseAction.TOGGLE_FOREVER_PRODUCT_SWITCH: {
      if (!state.userInput.selectedPaymentOption?.supportsRecurringBilling) {
        return state;
      }

      const { wantsForever } = payload;

      return {
        ...state,
        userInput: {
          ...state.userInput,
          wantsForever,
        },
        cartItemIdsToRemove: state.alternativeRenewalCartItemIdsToRemove,
        cartItemsToAdd: state.alternativeRenewalCartItemsToAdd,
        saleOrderProductIds: state.alternativeRenewalSaleOrderProductIds,
        saleOrder: state.alternativeRenewalSaleOrder,
        alternativeRenewalCartItemIdsToRemove: state.cartItemIdsToRemove,
        alternativeRenewalCartItemsToAdd: state.cartItemsToAdd,
        alternativeRenewalSaleOrderProductIds: state.saleOrderProductIds,
        alternativeRenewalSaleOrder: state.saleOrder,
      };
    }

    case RenewPurchaseAction.FETCHED_PAYMENT_OPTIONS: {
      const { paymentOptions } = payload;

      const selectedPaymentOption =
        state.userInput.selectedPaymentOption ||
        payload.paymentOptions.find(option => option.name.toLowerCase() === 'visa') ||
        payload.paymentOptions[0] ||
        null;

      return {
        ...state,
        availablePaymentOptions: paymentOptions,
        userInput: {
          ...state.userInput,
          selectedPaymentOption,
        },
      };
    }

    case RenewPurchaseAction.UPDATED_CART_ITEMS: {
      const { saleOrder, wantsForever } = payload;
      return {
        ...state,
        ...wantsForever === state.userInput.wantsForever ? {
          saleOrder,
          cartItemsToAdd: [],
          cartItemIdsToRemove: [],
        } : {
          alternativeRenewalSaleOrder: saleOrder,
          alternativeRenewalCartItemsToAdd: [],
          alternativeRenewalCartItemIdsToRemove: [],
        }
      };
    }

    case RenewPurchaseAction.CLEAR_STATE: {
      return initialRenewPurchaseState;
    }

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
