import gql from 'graphql-tag';

const GET_ORGANIZATIONS_WITH_ZONES_THAT_HAVE_A_RENEWABLE_SUBSCRIPTION = gql`
  query getOrganizationsWithZonesThatHaveARenewableSubscription {
    organizations {
      id
      name
      zonesWithARenewableSubscription {
        id
      }
      zonesWithEndingFreeTrail {
        id
        locationId
      }
    }
  }
`;

const GET_PARTNER_COMMISSION_NOTIFICATIONS = gql`
  query getPartnerCommissionNotifications {
    partnerCommissionNotifications {
      id
      number
      totalCommission
      organization {
        id
        name
      }
    }
  }
`;

const GET_PARTNER_COMMISSION_INVOICE_PDF = gql`
  query getPartnerCommissionInvoicePdf($partnerCommissionInvoiceId: ID!) {
    partnerCommissionInvoicePdf(
      partnerCommissionInvoiceId: $partnerCommissionInvoiceId
    )
  }
`;

export {
  GET_ORGANIZATIONS_WITH_ZONES_THAT_HAVE_A_RENEWABLE_SUBSCRIPTION,
  GET_PARTNER_COMMISSION_NOTIFICATIONS,
  GET_PARTNER_COMMISSION_INVOICE_PDF,
};
