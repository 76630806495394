import styled from 'styled-components';
import { Controller } from 'react-hook-form';

import Label from '../common/label';
import ErrorMessage from '../common/errorMessage';
import Slider from '../../slider';

const InputWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.4rem;
`;

const StyledLabel = styled(Label)`
  margin-bottom: 1.4rem;
`;

const SliderField = ({ name, max, control, required, label, disabled, error }) => {
  return (
    <InputWrapper>
      {label ? (
        <StyledLabel required={required} disabled={disabled}>
          {label}
        </StyledLabel>
      ) : null}
      <Controller
        control={control}
        name={name}
        render={({ field: { value, ...restField } }) => {
          return <Slider initial={value} max={max} {...restField} />;
        }}
      />
      {typeof error === 'string' ? <ErrorMessage>{error}</ErrorMessage> : null}
    </InputWrapper>
  );
};

export default SliderField;
