import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/react-hooks';

import { GET_COMMISSION_INVOICE_PDF } from '../api';
import config from '../../../config';

import { GridTable, Button, formatNumber, useMediaSize, formatDate, downloadBase64String } from '../../../common';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import { ReactComponent as SmallCheckmarkIcon } from '../../../assets/icons/smallCheckmark.svg';
import theme from '../../../global/style/theme';

const { COMMISSION_REQUEST_DETAILS_EXTERNAL_PAGE } = config;

const DesktopRow = styled(GridTable.Row)`
  grid-template-columns: 1fr 2.5rem;
  color: ${({ theme }) => theme.dark400};
`;

const TabletRow = styled(GridTable.Row)`
  grid-template-columns: 1fr 2.5rem;
  column-gap: 1.5rem;
  align-items: center;
  color: ${({ theme }) => theme.dark400};
`;

const MobileRow = styled(GridTable.Row)`
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  color: ${({ theme }) => theme.dark400};
  gap: 1rem;
`;

const Left = styled.div`
  display: grid;
  column-gap: 1.5rem;
  grid-template-rows: repeat(5, 1fr);
  align-items: center;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.small}px) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 0.1rem;
  background-color: ${({ theme }) => theme.dark100};
`;

const ApproveButton = styled(Button)`
  width: fit-content;
  & p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledButton = styled(Button)`
  height: min-content;
  padding: 0.6rem;
  background: none;

  &:hover::before {
    opacity: 0;
  }

  &:active::after {
    opacity: 0;
  }
`;

const DateValue = styled(GridTable.Value)`
  display: flex;
  align-items: center;
  gap: 0.6rem;
  color: ${({ theme }) => theme.dark300};

  & span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const LeftWrapper = styled.div`
  display: grid;
  padding: 0.2rem 0;
`;

const LeftRef = styled(GridTable.Value)`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.dark300};
`;

const MobileTop = styled(GridTable.Value)`
  padding-top: 0.4rem;
`;

const MobileName = styled.span`
  color: ${({ theme }) => theme.dark500};
`;

const MobileBottom = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr min-content;
  align-items: center;
  gap: 0.8rem;
`;

const CommissionRequestTableRow = ({ commissionRequest }) => {
  const { t, i18n } = useTranslation();
  const { isNarrowerThanSmall, isWiderThanSmall, isWiderThanLarge, isNarrowerThanLarge } = useMediaSize();

  const [isDownloadSuccess, setIsDownloadSuccess] = useState(false);

  const [getInvoicePdf, { loading, error }] = useLazyQuery(GET_COMMISSION_INVOICE_PDF, {
    onCompleted: ({ commissionInvoicePdf }) => {
      setIsDownloadSuccess(true);
      const fileName = `${commissionRequest.number || t('partnerManager:commissionRequests.commissionRequest')}.pdf`
        .split('/')
        .join('_');
      downloadBase64String(commissionInvoicePdf, fileName);
    },
    variables: { commissionInvoiceId: Number(commissionRequest.id) },
  });

  const commissionRequestUrl = COMMISSION_REQUEST_DETAILS_EXTERNAL_PAGE.replace('PLACEHOLDER', commissionRequest.id);

  const openLink = useCallback(() => {
    window.open(commissionRequestUrl, '_blank', 'noopener noreferrer');
  }, [commissionRequestUrl]);

  return (
    <>
      {isWiderThanLarge ? (
        <DesktopRow>
          <Left>
            <GridTable.Value>
              {formatDate(commissionRequest.createDate, i18n.language, {
                outputFormat: { day: 'numeric', month: 'long', year: 'numeric' },
              })}
            </GridTable.Value>
            <GridTable.Value>{commissionRequest.organization.name}</GridTable.Value>
            <GridTable.Value>{commissionRequest.number || '-'}</GridTable.Value>
            <GridTable.Value>€ {formatNumber(commissionRequest.totalCommission, i18n.language)}</GridTable.Value>
            {commissionRequest.invoiceDate ? (
              <DateValue>
                <SmallCheckmarkIcon width="1rem" height="1rem" color={theme.plantGreen} />
                <span>
                  {formatDate(commissionRequest.invoiceDate, i18n.language, {
                    outputFormat: { day: 'numeric', month: 'long', year: 'numeric' },
                  })}
                </span>
              </DateValue>
            ) : (
              <GridTable.Value>
                <ApproveButton
                  emphasis="primary"
                  highlightGradient="blooming"
                  size="small"
                  onClick={openLink}
                  title={t('partnerManager:commissionRequests.button.approve.title')}
                >
                  <p>{t('partnerManager:commissionRequests.button.approve.label')}</p>
                </ApproveButton>
              </GridTable.Value>
            )}
          </Left>
          <GridTable.Value>
            <StyledButton
              defaultActionColors
              isFail={error}
              isLoading={loading}
              isSuccess={isDownloadSuccess}
              onClick={getInvoicePdf}
              size="medium"
              title={t('common:button.title.download', {
                value: t('partnerManager:commissionRequests:commissionRequest'),
              })}
            >
              <DownloadIcon width="1.6rem" height="1.6rem" color={theme.dark300} />
            </StyledButton>
          </GridTable.Value>
        </DesktopRow>
      ) : null}

      {isWiderThanSmall && isNarrowerThanLarge ? (
        <TabletRow>
          <Left>
            <GridTable.Value>
              {formatDate(commissionRequest.createDate, i18n.language, {
                outputFormat: { day: 'numeric', month: 'long', year: 'numeric' },
              })}
            </GridTable.Value>
            <LeftWrapper>
              <GridTable.Value>{commissionRequest.organization.name}</GridTable.Value>
              <LeftRef>{commissionRequest.number || '-'}</LeftRef>
            </LeftWrapper>
            <GridTable.Value>€ {formatNumber(commissionRequest.totalCommission, i18n.language)}</GridTable.Value>
            {commissionRequest.invoiceDate ? (
              <DateValue>
                <SmallCheckmarkIcon width="1rem" height="1rem" color={theme.plantGreen} />
                <span>
                  {formatDate(commissionRequest.invoiceDate, i18n.language, {
                    outputFormat: { day: 'numeric', month: 'long', year: 'numeric' },
                  })}
                </span>
              </DateValue>
            ) : (
              <GridTable.Value>
                <ApproveButton
                  emphasis="primary"
                  highlightGradient="blooming"
                  size="small"
                  onClick={openLink}
                  title={t('partnerManager:commissionRequests.button.approve.title')}
                >
                  {t('partnerManager:commissionRequests.button.approve.label')}
                </ApproveButton>
              </GridTable.Value>
            )}
          </Left>
          <GridTable.Value>
            <StyledButton
              defaultActionColors
              isFail={error}
              isLoading={loading}
              isSuccess={isDownloadSuccess}
              onClick={getInvoicePdf}
              size="medium"
              title={t('common:button.title.download', {
                value: t('partnerManager:commissionRequests:commissionRequest'),
              })}
            >
              <DownloadIcon width="1.6rem" height="1.6rem" color={theme.dark300} />
            </StyledButton>
          </GridTable.Value>
        </TabletRow>
      ) : null}

      {isNarrowerThanSmall ? (
        <MobileRow>
          <MobileTop>
            {formatDate(commissionRequest.createDate, i18n.language, {
              outputFormat: { day: 'numeric', month: 'long', year: 'numeric' },
            })}
            &nbsp;&nbsp;&nbsp;
            <MobileName>{commissionRequest.organization.name}</MobileName>&nbsp;&nbsp;&nbsp;
            <LeftRef>{commissionRequest.number || '-'}</LeftRef>
          </MobileTop>
          <Divider />
          <MobileBottom>
            <GridTable.Value>€ {formatNumber(commissionRequest.totalCommission, i18n.language)}</GridTable.Value>
            {commissionRequest.invoiceDate ? (
              <DateValue>
                <SmallCheckmarkIcon width="1rem" height="1rem" color={theme.plantGreen} />
                <span>
                  {formatDate(commissionRequest.invoiceDate, i18n.language, {
                    outputFormat: { day: 'numeric', month: 'long', year: 'numeric' },
                  })}
                </span>
              </DateValue>
            ) : (
              <GridTable.Value>
                <ApproveButton
                  emphasis="primary"
                  highlightGradient="blooming"
                  size="small"
                  onClick={openLink}
                  title={t('partnerManager:commissionRequests.button.approve.title')}
                >
                  {t('partnerManager:commissionRequests.button.approve.label')}
                </ApproveButton>
              </GridTable.Value>
            )}
            <GridTable.Value>
              <StyledButton
                defaultActionColors
                isFail={error}
                isLoading={loading}
                isSuccess={isDownloadSuccess}
                onClick={getInvoicePdf}
                size="medium"
                title={t('common:button.title.download', {
                  value: t('partnerManager:commissionRequests:commissionRequest'),
                })}
              >
                <DownloadIcon width="1.6rem" height="1.6rem" color={theme.dark300} />
              </StyledButton>
            </GridTable.Value>
          </MobileBottom>
        </MobileRow>
      ) : null}
    </>
  );
};

export default CommissionRequestTableRow;
