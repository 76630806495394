import styled from 'styled-components';

const Container = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TableDataCell = ({ children, className }) => {
  return <Container className={className}>{children}</Container>;
};

export default TableDataCell;
