import { createGlobalStyle } from 'styled-components';

import theme from './theme';

const GoogleAutoCompleteStyle = createGlobalStyle`
  .pac-container {
    box-shadow: none;
    z-index: 100;
    overflow: hidden;
    border: none;
    transition: top 0.2s ease-in-out;
    border-radius: 4px;
    border: 1px solid ${theme.stroke};
  }

  .pac-item {
    width: 100%;
    overflow: hidden;
    border: none;
    list-style-type: none;
    font-family: 'Inter', sans-serif;
    font-size: 1.4rem;
    line-height: 1.4;
    transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
    border-bottom: 1px solid ${theme.stroke};
    padding: 1rem 1.6rem;
    display: flex;
    flex-direction: column;
    color: ${theme.dark300};
    cursor: pointer;

    &.pac-item-selected {
      background-color: ${theme.background};
    }

    &:hover {
      background-color: ${theme.background};
    }

    &:last-of-type {
      border: none;
    }
  }

  .pac-item-query {
    color: ${theme.dark500};
    font-size: 1.4rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .pac-icon {
    display: none;
  }

  .pac-matched {
    color: ${theme.waterBlue};
  }

  /* Disable Firefox default opacity for placeholders */
  ::-moz-placeholder {
    opacity: 1;
  }
`;

export default GoogleAutoCompleteStyle;
