import styled, { css } from 'styled-components';

export const ListButton = styled.button`
  width: 100%;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  line-height: 1.4;
  padding: 1.2rem 0;
  user-select: none;
  background-color: transparent;
  color: ${({ theme }) => theme.dark600};
  border: none;
  display: grid;
  grid-template-columns: min-content min-content;
  gap: 1.2rem;
  align-items: center;
  text-align: left;

  & p {
    font-family: 'Inter', sans-serif;
    font-size: 1.4rem;
    line-height: 1.4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const PlaylistButton = styled(ListButton)`
  & p {
    font-size: 0.95rem;
  }
`;

export const OptionButton = styled(ListButton)`
  padding: 0.8rem;

  &:hover {
    background-color: ${({ theme }) => theme.stroke};
    font-weight: 700;
    color: ${({ theme }) => theme.berryBlue};
  }
`;

export const SelectablePlaylistButton = styled(ListButton)`
  grid-template-columns: min-content 1fr;
  position: relative;
  padding: 0.6rem 1.2rem;

  & > div {
    transition: 0.2s background-color ease-in-out, 0.2s border-color ease-in-out;

    & svg {
      transition: 0.2s color ease-in-out;
    }
  }

  & p {
    color: ${({ theme }) => theme.dark400};
    line-height: 1.4;
  }

  ${({ selected }) =>
    selected
      ? css`
          & > div {
            background-color: ${({ theme }) => theme.dark600};
            border-color: ${({ theme }) => theme.dark600};

            & svg {
              color: ${({ theme }) => theme.white};
            }
          }
        `
      : css`
          &:hover > div {
            background-color: ${({ theme }) => theme.dark600};
            border-color: ${({ theme }) => theme.dark600};

            & svg {
              color: ${({ theme }) => theme.white};
            }
          }
        `}
`;

export const CollapsableButton = styled(ListButton)`
  grid-template-columns: 1fr min-content;
  color: ${({ theme }) => theme.dark600};
  background-color: transparent;
  border-bottom: 1px solid ${({ theme }) => theme.stroke};

  & p {
    font-size: 1.4rem;
    line-height: 1.4;
    font-weight: 600;
  }

  ${({ $collapsed }) =>
    !$collapsed &&
    css`
      border: none;
    `}
`;
