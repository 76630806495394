import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import styled from 'styled-components';

import { Modal, ErrorMessage } from '../../../../../common';
import { Button } from '../../../../../common/oldButton';
import StringField from '../../../../../common/oldFields/stringField';
import { EDIT_MUSIC_PROFILE, DELETE_MUSIC_PROFILE } from './api';
import {
  useUpdateMusicProfileCacheAfterUpdate,
  useUpdateMusicProfileCacheAfterDelete,
} from '../../cache';

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

const Right = styled.div`
  display: flex;
  justify-content: flex-end;

  & > :not(:first-child) {
    margin-left: 1rem;
  }
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin: 0 -1rem;
  width: 100%;
`;

const EditMusicProfileModal = ({
  onClose,
  isOpen,
  profile,
  removeProfileId,
  canDelete,
}) => {
  const { t } = useTranslation();

  const [name, setName] = useState(profile?.name ?? '');

  const handleUpdateMusicProfileCacheAfterUpdate =
    useUpdateMusicProfileCacheAfterUpdate();

  const [editMusicProfile, { loading, error }] = useMutation(
    EDIT_MUSIC_PROFILE,
    {
      update: handleUpdateMusicProfileCacheAfterUpdate,
      onCompleted: () => {
        onClose();
      },
    }
  );

  const handleUpdateMusicProfileCacheAfterDelete =
    useUpdateMusicProfileCacheAfterDelete({ profileId: profile?.id });

  const [deleteMusicProfile, { loading: loadingDelete, error: errorDelete }] =
    useMutation(DELETE_MUSIC_PROFILE, {
      update: handleUpdateMusicProfileCacheAfterDelete,
      onCompleted: () => {
        removeProfileId();
        onClose();
      },
    });

  const handleConfirm = useCallback(() => {
    editMusicProfile({
      variables: {
        id: profile?.id,
        name,
      },
    });
  }, [editMusicProfile, name, profile?.id]);

  const handleDelete = useCallback(() => {
    if (profile?.id) {
      deleteMusicProfile({
        variables: {
          id: profile.id,
        },
      });
    }
  }, [deleteMusicProfile, profile?.id]);

  const handleChange = useCallback(
    (event) => {
      setName(event.currentTarget?.value);
    },
    [setName]
  );

  return (
    <Modal
      footer={false}
      isOpen={isOpen}
      onClose={onClose}
      title={t('musicManagement:editMusicProfile.title')}
    >
      <StringField
        autoFocus
        label={t('common:form.label.name')}
        name="name"
        onChange={handleChange}
        value={name}
      />
      {error || errorDelete ? (
        <StyledErrorMessage error={error || errorDelete} />
      ) : null}
      <Footer>
        {canDelete ? (
          <Button
            emphasis="warning"
            isLoading={loadingDelete}
            onClick={handleDelete}
          >
            {t('common:button.delete')}
          </Button>
        ) : (
          <div />
        )}
        <Right>
          <Button
            emphasis="tertiary"
            flavor="link"
            onClick={onClose}
            title={t('common:button.title.cancel')}
          >
            {t('common:button.cancel')}
          </Button>
          <Button isLoading={loading} onClick={handleConfirm}>
            {t('common:button.save')}
          </Button>
        </Right>
      </Footer>
    </Modal>
  );
};

export default EditMusicProfileModal;
