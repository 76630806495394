import { Navigate } from 'react-router-dom';

import config from '../../config';

const withExperimental = (Component) => {
  const isMatch = Boolean(config[Component.EXPERIMENTAL]);

  const WrappedComponent = (props) => {
    if (!isMatch) return <Navigate to="/" />;

    return <Component {...props} />;
  };

  return WrappedComponent;
};

export default withExperimental;
