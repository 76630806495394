import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { LoadingIndicator, Page } from '../../common';
import { useAuth } from '../../global/auth/newAuthProvider';

const Center = styled.div`
  display: flex;
  justify-content: center;
`;

const ImpersonationPage = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const { impersonate } = useAuth();

  useEffect(() => {
    const loginAsAlias = async () => {
      await impersonate(id, searchParams.get('from'));
    };

    loginAsAlias();
  }, [id, impersonate, searchParams]);

  return (
    <Page title={t('global:impersonation.title')}>
      <Center>
        <LoadingIndicator />
      </Center>
    </Page>
  );
};

export default ImpersonationPage;
