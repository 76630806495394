import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import { GridTable, useMediaSize } from '../../../common';
import { usePartner } from '../partnerContext';
import WinnerTableRow from './winnerTableRow';
import { LIMIT } from '../partnerProvider';

const Header = styled(GridTable.Header)`
  border-bottom: 1px solid ${({ theme }) => theme.stroke};
`;

const Head = styled(GridTable.Head)`
  color: ${({ theme }) => theme.dark500};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.143;
`;

const WinnersTable = () => {
  const { t } = useTranslation();
  const {
    partnerWinnerCustomers,
    partnerWinnerCustomersLoading,
    partnerWinnerCustomersError,
    hasMoreWinnerCustomers,
    fetchMoreWinnerCustomers,
  } = usePartner();

  const { isWiderThanLarge } = useMediaSize();

  const [infiniteRef] = useInfiniteScroll({
    loading: partnerWinnerCustomersLoading,
    // Due to refetch we cannot know if there is more after the initial query,
    // we have to additionally check for this
    hasNextPage: partnerWinnerCustomers?.length >= LIMIT && hasMoreWinnerCustomers,
    onLoadMore: fetchMoreWinnerCustomers,
  });

  return (
    <GridTable ref={infiniteRef}>
      <>
        {isWiderThanLarge && (
          <Header templateColumns="repeat(5, 1fr)">
            <Head>{t('partner:registrations.tableHeader.registerDate')}</Head>
            <Head>{t('partner:registrations.tableHeader.name')}</Head>
            <Head>{t('partner:registrations.tableHeader.subscription')}</Head>
            <Head>{t('partner:registrations.tableHeader.endDate')}</Head>
            <Head>{t('partner:registrations.tableHeader.commission')}</Head>
          </Header>
        )}
        {(!partnerWinnerCustomers || partnerWinnerCustomers?.length === 0) && !partnerWinnerCustomersLoading && (
          <GridTable.Message>{t('partner:registrations.table.noWinnerCustomers')}</GridTable.Message>
        )}
        {partnerWinnerCustomers?.map((winnerCustomer) => (
          <WinnerTableRow key={winnerCustomer.id} winnerCustomer={winnerCustomer} />
        ))}
        <GridTable.LoadingIndicator isLoading={partnerWinnerCustomersLoading} />
        <GridTable.ErrorMessage error={partnerWinnerCustomersError} />
      </>
    </GridTable>
  );
};

export default WinnersTable;
