import { useMemo } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import styled from 'styled-components';

import { GET_ZONE } from './api';
import { LoadingIndicator, ErrorMessage } from '../../common';

const Container = styled.div`
  width: 100%;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin-top: 2.5rem;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin: 2rem;
  width: unset;
`;

const ZoneRedirectShortcut = () => {
  const navigate = useNavigate();
  const { zoneId } = useParams();
  const [searchParams] = useSearchParams();

  const redirectType = useMemo(() => {
    switch (searchParams.get('type')) {
      case 'change':
        return 'change';
      case 'purchase':
        return 'purchase/0';
      default:
        return 'change';
    }
  }, [searchParams]);

  const { error, loading } = useQuery(GET_ZONE, {
    variables: { zoneId },
    onCompleted: ({ zone }) => {
      navigate(
        `/organizations/${zone.location.organization.id}/locations/${zone.location.id}/zones/${zone.id}/${redirectType}`
      );
    },
  });

  return (
    <Container>
      {!loading && error ? (
        <StyledErrorMessage error={error} />
      ) : (
        <StyledLoadingIndicator />
      )}
    </Container>
  );
};

export default ZoneRedirectShortcut;
