import {
  determineAvailableAddonCategoriesForZone,
  getProductSubscriptionLineOfSubscriptionLines,
} from '../../../common';

export const addonCatalogueOptions = [
  {
    name: 'Paid',
    id: 'paid',
    description: 'De reguliere, betaalde add-ons van Tunify',
  },
  { name: 'Demo', id: 'demo', description: 'De demo add-ons van Tunify' },
  { name: 'Free', id: 'free', description: 'De free add-ons van Tunify' },
  { name: 'Trial', id: 'trial', description: 'De trial add-ons van Tunify' },
];

export const AddonsBuyAction = {
  FETCHED_ORGANIZATION_DETAILS: 'FETCHED_ORGANIZATION_DETAILS',
  SELECT_ADDON_CATALOGUE: 'SELECT_ADDON_CATALOGUE',
  FETCHED_ADDON_CATALOGUE: 'FETCHED_ADDON_CATALOGUE',
  SELECT_ADDON: 'SELECT_ADDON',
  DESELECT_ADDON: 'DESELECT_ADDON',
  FETCHED_ADDITIONAL_ZONES: 'FETCHED_ADDITIONAL_ZONES',
  SELECT_ZONE: 'SELECT_ZONE',
  DESELECT_ZONE: 'DESELECT_ZONE',
  SELECT_ALL_ZONES: 'SELECT_ALL_ZONES',
  DESELECT_ALL_ZONES: 'DESELECT_ALL_ZONES',
  FETCHED_PAYMENT_OPTIONS: 'FETCHED_PAYMENT_OPTIONS',
  SELECT_PAYMENT_OPTION: 'SELECT_PAYMENT_OPTION',
  FETCHED_SALE_ORDER: 'FETCHED_SALE_ORDER',
};

export const initialAddonsBuyState = {
  userInput: {
    selectedAddons: [],
    selectedAdditionalZones: [],
    selectedPaymentOption: null,
  },
  organizationDetails: null,
  saleOrder: null,
  saleOrderNeedsToBeFetched: true,
  additionalZones: [],
  availableAdditionalZones: [],
  paymentOptions: [],
  addonCatalogue: [],
  // Initially we select the paid addon catalogue,
  // as is the case without the addon catalogue step
  selectedAddonCatalogue: addonCatalogueOptions.find(
    (addonCatalogue) => addonCatalogue.id === 'paid'
  ),
};

export const AddonsBuyReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case AddonsBuyAction.SELECT_ADDON_CATALOGUE: {
      const { addonCatalogue } = payload;
      return {
        ...state,
        userInput: {
          ...state.userInput,
          selectedAddons: [],
          selectedAdditionalZones: [],
          selectedPaymentOption: null,
        },
        saleOrderNeedsToBeFetched: true,
        selectedAddonCatalogue: addonCatalogue,
      };
    }

    case AddonsBuyAction.FETCHED_ADDON_CATALOGUE: {
      const { addonCatalogue } = payload;

      return {
        ...state,
        addonCatalogue,
      };
    }

    case AddonsBuyAction.FETCHED_ORGANIZATION_DETAILS: {
      const { organizationDetails, zone } = payload;

      const currentProductCatalogue =
        zone.currentSubscription.subscriptionLines[0].product.type;

      return {
        ...state,
        organizationDetails,
        selectedAddonCatalogue: addonCatalogueOptions.find(
          (addonCatalogue) => addonCatalogue.id === currentProductCatalogue
        ),
      };
    }

    case AddonsBuyAction.SELECT_ADDON: {
      const { addon } = payload;

      const selectedAddons = [...state.userInput.selectedAddons, addon];

      const availableAdditionalZones = state.additionalZones?.filter(
        (additionalZone) => {
          const productSubscriptionLine =
            getProductSubscriptionLineOfSubscriptionLines(
              additionalZone.currentSubscription.subscriptionLines
            );
          const hasMatchingCatalogue =
            productSubscriptionLine.product.type ===
            state.selectedAddonCatalogue.id;
          const canBuyAddon =
            determineAvailableAddonCategoriesForZone(
              additionalZone,
              selectedAddons
            ).length > 0;
          return hasMatchingCatalogue && canBuyAddon;
        }
      );

      return {
        ...state,
        userInput: {
          ...state.userInput,
          selectedAddons,
          selectedAdditionalZones: [],
          selectedPaymentOption: null,
        },
        availableAdditionalZones: availableAdditionalZones ?? [],
        saleOrderNeedsToBeFetched: true,
      };
    }

    case AddonsBuyAction.DESELECT_ADDON: {
      const { addon } = payload;

      const selectedAddons = state.userInput.selectedAddons.filter(
        (selectedAddon) => selectedAddon.id !== addon.id
      );

      const availableAdditionalZones = state.additionalZones?.filter(
        (additionalZone) => {
          const productSubscriptionLine =
            getProductSubscriptionLineOfSubscriptionLines(
              additionalZone.currentSubscription.subscriptionLines
            );
          const hasMatchingCatalogue =
            productSubscriptionLine.product.type ===
            state.selectedAddonCatalogue.id;
          const canBuyAddon =
            determineAvailableAddonCategoriesForZone(
              additionalZone,
              selectedAddons
            ).length > 0;
          return hasMatchingCatalogue && canBuyAddon;
        }
      );

      return {
        ...state,
        userInput: {
          ...state.userInput,
          selectedAddons,
          selectedAdditionalZones: [],
          selectedPaymentOption: null,
        },
        saleOrderNeedsToBeFetched: true,
        availableAdditionalZones: availableAdditionalZones ?? [],
      };
    }

    case AddonsBuyAction.FETCHED_ADDITIONAL_ZONES: {
      const { additionalZones } = payload;
      return {
        ...state,
        additionalZones,
      };
    }

    case AddonsBuyAction.SELECT_ZONE: {
      const { zone } = payload;
      return {
        ...state,
        userInput: {
          ...state.userInput,
          selectedAdditionalZones: [
            ...state.userInput.selectedAdditionalZones,
            zone,
          ],
          selectedPaymentOption: null,
        },
        saleOrderNeedsToBeFetched: true,
      };
    }

    case AddonsBuyAction.DESELECT_ZONE: {
      const { zone } = payload;
      return {
        ...state,
        userInput: {
          ...state.userInput,
          selectedAdditionalZones:
            state.userInput.selectedAdditionalZones.filter(
              (selectedZone) => selectedZone.id !== zone.id
            ),
          selectedPaymentOption: null,
        },
        saleOrderNeedsToBeFetched: true,
      };
    }

    case AddonsBuyAction.SELECT_ALL_ZONES: {
      return {
        ...state,
        userInput: {
          ...state.userInput,
          selectedAdditionalZones: state.availableAdditionalZones,
          selectedPaymentOption: null,
        },
        saleOrderNeedsToBeFetched: true,
      };
    }

    case AddonsBuyAction.DESELECT_ALL_ZONES: {
      return {
        ...state,
        userInput: {
          ...state.userInput,
          selectedAdditionalZones: [],
          selectedPaymentOption: null,
        },
        saleOrderNeedsToBeFetched: true,
      };
    }

    case AddonsBuyAction.FETCHED_PAYMENT_OPTIONS: {
      const { paymentOptions } = payload;
      return {
        ...state,
        paymentOptions,
      };
    }

    case AddonsBuyAction.SELECT_PAYMENT_OPTION: {
      const { paymentOption } = payload;
      return {
        ...state,
        userInput: {
          ...state.userInput,
          selectedPaymentOption: paymentOption,
        },
      };
    }

    case AddonsBuyAction.FETCHED_SALE_ORDER: {
      const { saleOrder } = payload;
      return {
        ...state,
        saleOrder,
        saleOrderNeedsToBeFetched: false,
      };
    }

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
