import { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { GridTable } from '../../../../../common';
import { ReactComponent as AngleDown } from '../../../../../assets/icons/angleDown.svg';
import { ReactComponent as AngleUp } from '../../../../../assets/icons/angleUp.svg';
import { useCustomerSuccessSearch } from '../../searchContext';
import theme from '../../../../../global/style/theme';

const Header = styled(GridTable.Head)`
  display: flex;
  gap: 0.6rem;
  align-items: center;
  line-height: 1.4;
`;

const TableHeader = ({ text, orderKey }) => {
  const { state, changeOrder, searchCustomersCalled } = useCustomerSuccessSearch();

  const shouldShowCaret = useMemo(
    () => searchCustomersCalled && state.searchParams.order[0].value === orderKey,
    [state.searchParams.order, orderKey, searchCustomersCalled]
  );

  const sortDirectionIsDescending = useMemo(
    () => state.searchParams.order[0].order === 'DESC',
    [state.searchParams.order]
  );

  const onClick = useCallback(() => {
    changeOrder(orderKey);
  }, [changeOrder, orderKey]);

  return (
    <Header onClick={orderKey ? onClick : undefined}>
      {text}
      {shouldShowCaret ? (
        <>
          {sortDirectionIsDescending ? (
            <AngleUp width="1rem" height="1rem" color={theme.dark300} />
          ) : (
            <AngleDown width="1rem" height="1rem" color={theme.dark300} />
          )}
        </>
      ) : null}
    </Header>
  );
};

export default TableHeader;
