import { useTranslation } from 'react-i18next';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import styled from 'styled-components';

import { GridTable, useMediaSize } from '../../../../../common';
import { usePartner } from '../../../partnerContext';
import CommisionOverviewRow from './commissionOverviewRow';
import { LIMIT } from '../../../partnerProvider';

const EmptyMessage = styled(GridTable.Message)`
  background-color: unset;
  font-size: 1.2rem;
`;

const Header = styled(GridTable.Header)`
  padding: 0;
  border-bottom: 1px solid ${({ theme }) => theme.stroke};
`;

const Head = styled(GridTable.Head)`
  color: ${({ theme }) => theme.dark500};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.143;
`;

const CommisionOverviewCard = () => {
  const { t } = useTranslation();
  const {
    partnerCommissionInvoices,
    partnerCommissionInvoicesLoading,
    partnerCommissionInvoicesError,
    hasMoreCommissionInvoices,
    fetchMoreCommissionInvoices,
  } = usePartner();

  const { isWiderThanMedium } = useMediaSize();

  const [infiniteRef] = useInfiniteScroll({
    loading: partnerCommissionInvoicesLoading,
    // Due to refetch we cannot know if there is more after the initial query,
    // we have to additionally check for this
    hasNextPage: partnerCommissionInvoices?.length >= LIMIT && hasMoreCommissionInvoices,
    onLoadMore: fetchMoreCommissionInvoices,
  });

  return (
    <GridTable ref={infiniteRef}>
      <>
        {isWiderThanMedium && (
          <Header templateColumns="repeat(5, 1fr)">
            <Head>{t('partner:dashboard.table.dateOfSettlement')}</Head>
            <Head>{t('partner:dashboard.table.reference')}</Head>
            <Head>{t('partner:dashboard.table.commission')}</Head>
            <Head>{t('partner:dashboard.table.approved')}</Head>
            <Head />
          </Header>
        )}
        {(!partnerCommissionInvoices || partnerCommissionInvoices?.length === 0) &&
          !partnerCommissionInvoicesLoading && (
            <EmptyMessage>{t('partner:dashboard.table.noCommissionInvoices')}</EmptyMessage>
          )}
        {partnerCommissionInvoices?.map((commissionInvoice) => (
          <CommisionOverviewRow key={commissionInvoice.id} commissionInvoice={commissionInvoice} />
        ))}
        <GridTable.LoadingIndicator isLoading={partnerCommissionInvoicesLoading} />
        <GridTable.ErrorMessage error={partnerCommissionInvoicesError} />
      </>
    </GridTable>
  );
};

export default CommisionOverviewCard;
