import { useMemo, useCallback, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
  Page,
  LoadingIndicator,
  ErrorMessage,
  ErrorBoundary,
  SelectField,
} from '../../../common';
import { ZoneTable, SonosFailure, SonosSuccess } from './cards';
import { ReactComponent as Logo } from '../../../assets/icons/logoDark.svg';
import { GET_ORGANIZATIONS_AND_ZONES_FOR_ORGANIZATION } from './api';

const Container = styled.div`
  display: grid;
  padding: 1.5rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    padding: 2.2rem 3rem;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    padding: 3.2rem 4rem;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    padding: 4rem 4.8rem;
  }
`;

const Description = styled.p`
  max-width: 62rem;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.dark300};
  line-height: 1.4;
  padding: 1rem 0 3rem 0;
  text-align: left;
`;

const StyledLogo = styled(Logo)`
  width: 100%;
  height: 4rem;
  margin: 1rem 0 2rem 0;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    margin: 2rem 0 4rem 0;
  }
`;

const MaxWidth = styled.div`
  justify-self: center;
  min-width: 32rem;
  height: fit-content;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.white};
  padding: 2.8rem 1.6rem;
  border: 1px solid ${({ theme }) => theme.stroke};

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    padding: 4rem;
  }
`;

const Paper = styled.div`
  display: grid;
  text-align: center;
`;

const Overview = () => {
  const { t } = useTranslation();
  const [selectedOrganization, setSelectedOrganization] = useState();
  const [zoneNameToCouple, setZoneNameToCouple] = useState();
  const [couplingHasFailed, setCouplingHasFailed] = useState(false);
  const [couplingWasSuccessful, setCouplingWasSuccessful] = useState(false);

  const organizationQueryVariables = useMemo(() => {
    if (selectedOrganization) {
      return { organizationId: selectedOrganization, withOrganization: true };
    }
    return { organizationId: 'dontcare', withOrganization: false };
  }, [selectedOrganization]);

  const { loading, data, error } = useQuery(
    GET_ORGANIZATIONS_AND_ZONES_FOR_ORGANIZATION,
    {
      variables: organizationQueryVariables,
    }
  );

  const handleOnChangeOrganization = useCallback((organization) => {
    setSelectedOrganization(organization.value);
  }, []);

  const content = useMemo(() => {
    if (loading) return <LoadingIndicator />;
    if (error) return <ErrorMessage error={error} />;
    if (couplingHasFailed) {
      return (
        <SonosFailure
          setCouplingHasFailed={setCouplingHasFailed}
          zoneName={zoneNameToCouple}
        />
      );
    }
    if (couplingWasSuccessful) {
      return <SonosSuccess zoneName={zoneNameToCouple} />;
    }
    if (data) {
      const { organizations } = data;
      const organization = selectedOrganization
        ? data.organization
        : data.initialOrganization[0];

      const organizationOptions = organizations.map((organization) => ({
        label: organization.name,
        value: organization.id,
      }));

      const currentOrganization = organizationOptions.find(
        (profile) => profile.value === organization.id
      );

      return (
        <MaxWidth>
          <SelectField
            name="profile"
            options={organizationOptions}
            value={currentOrganization}
            onChange={handleOnChangeOrganization}
          />
          <Paper>
            <Description>
              {t('integrations:sonos.overview.description')}
            </Description>
            <ZoneTable
              locations={organization.locations}
              setCouplingHasFailed={setCouplingHasFailed}
              setCouplingWasSuccessful={setCouplingWasSuccessful}
              setZoneNameToCouple={setZoneNameToCouple}
              zones={organization.zones}
            />
          </Paper>
        </MaxWidth>
      );
    }
  }, [
    couplingHasFailed,
    couplingWasSuccessful,
    data,
    error,
    loading,
    selectedOrganization,
    handleOnChangeOrganization,
    t,
    zoneNameToCouple,
  ]);

  return (
    <Page title={t('integrations:sonos.overview.title')}>
      <ErrorBoundary>
        <Container>
          <StyledLogo />
          {content}
        </Container>
      </ErrorBoundary>
    </Page>
  );
};

export default Overview;
