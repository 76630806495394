import gql from 'graphql-tag';

export const GET_MUSIC_REPORT = gql`
  query getMusicReport($zoneIds: [ID!]!, $afterDateString: String!, $beforeDateString: String!) {
    musicGenreReport(afterDateString: $afterDateString, beforeDateString: $beforeDateString, zoneIds: $zoneIds) {
      musicGenres {
        id
        name
        value
      }
    }

    musicMoodReport(afterDateString: $afterDateString, beforeDateString: $beforeDateString, zoneIds: $zoneIds) {
      musicMoods {
        id
        name
        value
      }
    }

    musicPlaylistReport(afterDateString: $afterDateString, beforeDateString: $beforeDateString, zoneIds: $zoneIds) {
      musicPlaylists {
        id
        name
        value
      }
    }

    musicArtistReport(afterDateString: $afterDateString, beforeDateString: $beforeDateString, zoneIds: $zoneIds) {
      musicArtists {
        id
        name
        value
      }
    }

    musicSongReport(afterDateString: $afterDateString, beforeDateString: $beforeDateString, zoneIds: $zoneIds) {
      musicSongs {
        id
        name
        value
        songTitle
        songArtist
      }
    }

    musicChannelReport(afterDateString: $afterDateString, beforeDateString: $beforeDateString, zoneIds: $zoneIds) {
      musicChannels {
        id
        name
        value
      }
    }

    musicDecadeReport(afterDateString: $afterDateString, beforeDateString: $beforeDateString, zoneIds: $zoneIds) {
      musicDecades {
        id
        name
        value
      }
    }

    musicAgendaReport(afterDateString: $afterDateString, beforeDateString: $beforeDateString, zoneIds: $zoneIds) {
      musicAgendas {
        id
        name
        value
      }
    }

    musicApplicationReport(afterDateString: $afterDateString, beforeDateString: $beforeDateString, zoneIds: $zoneIds) {
      musicApplications {
        id
        name
        value
      }
    }

    musicColorReport(afterDateString: $afterDateString, beforeDateString: $beforeDateString, zoneIds: $zoneIds) {
      musicColors {
        id
        name
        value
        playDuration
      }
    }
  }
`;

export const GET_ZONES = gql`
  query getZones {
    zones {
      id
      name
      location {
        id
        name
        organization {
          id
          name
        }
      }
    }
  }
`;

export const GET_USER_IDS_FROM_EXTERNAL_ZONE_IDS = gql`
  query getUserIdsFromExternalZoneIds($zoneIds: [ID!]!) {
    userIdsFromExternalZoneIds(zoneIds: $zoneIds) {
      externalZoneId
      zoneId
    }
  }
`;
