import { useCallback, useState, Fragment } from 'react';
import styled, { css } from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import { Modal, ErrorMessage, Button } from '../../../../../common';
import { usePlaylists } from '../../playlistsProvider';
import SearchButton from './searchButton';
import { Tabs, PlaylistTypes } from '../../types';

const Container = styled.div`
  display: grid;
  grid-template-columns: min-content 10fr;
  grid-auto-rows: 1fr;
  align-items: center;
  row-gap: 0.4rem;
  padding-bottom: 1rem;
  overflow-x: hidden;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-bottom: 1rem;
`;

const Title = styled.div`
  height: 4.8rem;
  padding: 0 1rem 0 2rem;
  background-color: ${({ theme }) => theme.uiBackground};
  display: grid;
  align-items: center;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  border-radius: 2.4rem 0 0 2.4rem;

  & span {
    font-size: 1.4rem;
    line-height: 1.4;
  }

  ${({ isHovering }) =>
    isHovering &&
    css`
      color: ${({ theme }) => theme.white};
      background-color: ${({ theme }) => theme.waterBlue};
    `}
`;

const Bold = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.dark500};
  padding-left: 0.6rem;
`;

const infoTypes = [
  { title: 'title', type: 'searchOnTitle' },
  { title: 'group', type: 'searchOnGroup' },
  { title: 'year', type: 'year' },
  { title: 'bpm', type: 'bpm' },
  { title: 'genreDescription', type: 'style' },
  { title: 'mood', type: 'mood' },
  { title: 'dancingStyle', type: 'dancingstyle' },
  { title: 'duration', type: 'duration' },
];

const SongInfoModal = ({ isOpen, onClose, title, song, setTab }) => {
  const { t } = useTranslation();

  const { dispatch, errorSearchSongs } = usePlaylists();

  const [hoverStates, setHoverStates] = useState(
    new Array(infoTypes.length).fill(false)
  );

  const handleSearchRelatedSongs = useCallback(() => {
    if (song?.songId) {
      dispatch({
        selectedPlaylist: {
          title: (
            <Trans
              components={{ bold: <Bold /> }}
              i18nKey="musicManagement:playlists.searchResultsFor"
              defaults="Resultaten voor: <bold>{{result}}</bold>"
              values={{ result: song?.title }}
            />
          ),
        },
        selectedPlaylistType: PlaylistTypes.SEARCH,
        searchSongsIsSuggestion: true,
        searchSongsType: 'style',
        searchSongsSongId: song.songId,
      });
      if (setTab) {
        setTab(Tabs.SEARCH);
      }
    }
  }, [dispatch, song, setTab]);

  const handleOnHover = useCallback((index, value) => {
    setHoverStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = value;
      return newStates;
    });
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} footer={false}>
      <Container>
        {errorSearchSongs ? (
          <StyledErrorMessage error={errorSearchSongs} />
        ) : null}
        {infoTypes.map(({ title: filterTitle, type }, index) => (
          <Fragment key={filterTitle}>
            <Title isHovering={hoverStates[index]}>
              <span>
                {t(`musicManagement:playlists.songInfo.${filterTitle}`)}
              </span>
            </Title>
            <SearchButton
              index={index}
              onHover={handleOnHover}
              setTab={setTab}
              songId={song.songId}
              title={song[filterTitle]}
              type={type}
            />
          </Fragment>
        ))}
      </Container>
      <Button
        emphasis="primary"
        highlightColor="waterBlue"
        onClick={handleSearchRelatedSongs}
        title={t('musicManagement:playlists.songInfo.searchRelatedSongs')}
      >
        {t('musicManagement:playlists.songInfo.searchRelatedSongs')}
      </Button>
    </Modal>
  );
};

export default SongInfoModal;
