import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { GridTable, formatDate, getProductSubscriptionLineOfSubscriptionLines, useMediaSize } from '../../../common';
import { usePartner } from '../partnerContext';

const RowContainer = styled(GridTable.Row)`
  padding: 0.8rem 0;
  border-radius: unset;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.stroke};

  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: 'name months' 'subscription months';

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    grid-auto-flow: row;
    grid-template-columns: 1fr 1fr min-content;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: 'name subscription months' 'name endDate months';
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    grid-auto-flow: row;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
    grid-template-areas: 'creationDate name subscription endDate months';
  }
`;

const NumerOfMonths = styled(GridTable.Value)`
  grid-area: months;
  color: ${({ theme }) => theme.dark300};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.143;
`;

const Name = styled(GridTable.Value)`
  grid-area: name;
  color: ${({ theme }) => theme.dark500};
  font-family: 'Inter', sans-serif;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.5;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    color: ${({ theme }) => theme.dark300};
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.143;
  }
`;

const Subscription = styled(GridTable.Value)`
  grid-area: subscription;
  color: ${({ theme }) => theme.dark300};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.143;
`;

const CreationDate = styled(GridTable.Value)`
  grid-area: creationDate;
  color: ${({ theme }) => theme.dark300};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.143;
`;
const EndDate = styled(GridTable.Value)`
  grid-area: endDate;
  color: ${({ theme }) => theme.dark300};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.143;
`;

const QualifierTableRow = ({ qualifierCustomer }) => {
  const { i18n } = useTranslation();
  const { partnerProgram } = usePartner();

  const { isWiderThanMedium, isWiderThanLarge } = useMediaSize();

  const name = useMemo(() => `${qualifierCustomer.location.name} - ${qualifierCustomer.name}`, [qualifierCustomer]);

  const subscription = useMemo(() => {
    if (qualifierCustomer.currentSubscription) {
      const productSubscriptionLine = getProductSubscriptionLineOfSubscriptionLines(
        qualifierCustomer.currentSubscription.subscriptionLines
      );
      return productSubscriptionLine.product.name;
    }
    const productSubscriptionLine = getProductSubscriptionLineOfSubscriptionLines(
      qualifierCustomer.lastSubscription.subscriptionLines
    );
    return productSubscriptionLine.product.name;
  }, [qualifierCustomer]);

  return (
    <RowContainer>
      {isWiderThanLarge && (
        <CreationDate>
          {formatDate(qualifierCustomer.createDate, i18n.language, {
            outputFormat: { day: '2-digit', month: 'long', year: 'numeric' },
          })}
        </CreationDate>
      )}
      <Name>{name}</Name>
      <Subscription>{subscription}</Subscription>
      {isWiderThanMedium && (
        <EndDate>
          {formatDate(qualifierCustomer.lastSubscription.endDate, i18n.language, {
            outputFormat: { day: '2-digit', month: 'long', year: 'numeric' },
          })}
        </EndDate>
      )}
      <NumerOfMonths>
        {qualifierCustomer.numberOfMonths}/{partnerProgram?.commissionTriggerMonths}
      </NumerOfMonths>
    </RowContainer>
  );
};

export default QualifierTableRow;
