import { useRef, useCallback, useLayoutEffect } from 'react';
import styled, { css } from 'styled-components';

import Label from '../common/label';
import ErrorMessage from '../common/errorMessage';
import { Controller } from 'react-hook-form';

const InputWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;

const InputField = styled.textarea`
  font-family: 'Inter', sans-serif;
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.stroke};
  height: 100%;
  font-size: 1.4rem;
  line-height: 1.2;
  padding: 1.2rem 1.6rem;
  resize: none;
  min-height: 80px;
  max-height: 400px;
  transition: background-color 0.333s ease-in-out,
    border-color 0.333s ease-in-out;
  width: 100%;
  color: ${({ theme }) => theme.black};
  background-color: ${({ theme }) => theme.white};

  background: ${({ theme }) =>
    `linear-gradient(0deg, ${theme.gradient['fade'][0]} 0%,  ${theme.gradient['fade'][1]} 100%)`};

  &:hover {
    border-color: ${({ theme }) => theme.dark300};
  }
  &:active,
  :focus,
  :focus-visible {
    border-color: ${({ theme }) => theme.waterBlue};
    outline: ${({ theme }) => theme.waterBlue};
  }
  &:invalid {
    box-shadow: none;
  }

  &::placeholder {
    transition: color 0.2s ease-in-out;
    color: ${({ theme }) => theme.dark300};
  }

  /* Color Safari password icon */
  ::-webkit-credentials-auto-fill-button {
    background-color: ${({ theme }) => theme.waterBlue};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      background-color: ${({ theme }) => theme.white};
      color: ${({ theme }) => theme.stroke};
      border-color: ${({ theme }) => theme.stroke};
      &:hover {
        border-color: ${({ theme }) => theme.stroke};
      }
      &::placeholder {
        color: ${({ theme }) => theme.dark100};
      }
    `}
`;

const TextField = ({
  browserAutoComplete,
  className,
  disabled,
  error,
  label,
  placeholder,
  required,
  value,
  onBlur,
  onChange,
  onFocus,
  name,
  shouldFocus,
  control,
  ...restProps
}) => {
  const inputRef = useRef({});

  const focus = useCallback(() => {
    if (inputRef.current && inputRef.current.focus) {
      inputRef.current.focus();
    }
  }, []);

  useLayoutEffect(() => {
    if (shouldFocus) {
      focus();
    }
  }, [shouldFocus, focus]);

  return (
    <InputWrapper className={className}>
      {label && (
        <Label disabled={disabled} required={required}>
          {label}
        </Label>
      )}
      {control ? (
        <Controller
          control={control}
          name={name}
          render={({ field }) => {
            return (
              <InputField
                ref={inputRef}
                autoComplete={browserAutoComplete ? 'on' : 'off'}
                disabled={disabled}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                onFocus={onFocus}
                placeholder={placeholder}
                value={value}
                {...field}
                {...restProps}
              />
            );
          }}
        />
      ) : (
        <InputField
          ref={inputRef}
          autoComplete={browserAutoComplete ? 'on' : 'off'}
          disabled={disabled}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          placeholder={placeholder}
          value={value}
          {...restProps}
        />
      )}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </InputWrapper>
  );
};

TextField.displayName = 'TextField';

export default TextField;
