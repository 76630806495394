import { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Zone from './zone';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(22rem, 1fr));
  gap: 1rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    grid-template-columns: repeat(auto-fill, minmax(32rem, 1fr));
  }
`;

const EmptyTableMessage = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.dark200};
  padding: 1.4rem 0 1rem 0;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    padding: 1.2rem 1.6rem;
  }
`;

const Body = ({
  isExpand,
  zones,
  location,
  organization,
  allProducts,
  lastSeenPlayerDates,
  mappingExternalZoneIds,
}) => {
  const { t } = useTranslation();

  const tableBody = useMemo(() => {
    const zonesOfLocation = zones.filter(
      ({ locationId }) => locationId === Number(location.id)
    );
    if (zonesOfLocation.length === 0)
      return (
        <EmptyTableMessage>{t('subscriptions:tbNoZones')}</EmptyTableMessage>
      );
    return zonesOfLocation.map((zone) => (
      <Zone
        key={zone.id}
        allProducts={allProducts}
        lastSeen={
          lastSeenPlayerDates?.find(
            (player) =>
              player.id ===
              mappingExternalZoneIds.find(
                (externalZone) =>
                  Number(externalZone.externalZoneId) === Number(zone.id)
              )?.zoneId
          )?.timestamp
        }
        mappingExternalZoneIds={mappingExternalZoneIds}
        location={location}
        organization={organization}
        zone={zone}
      />
    ));
  }, [
    location,
    organization,
    t,
    zones,
    allProducts,
    mappingExternalZoneIds,
    lastSeenPlayerDates,
  ]);

  return isExpand ? <Container>{tableBody}</Container> : null;
};

export default Body;
