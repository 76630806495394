import gql from 'graphql-tag';

export const GET_MUSIC_PROFILES = gql`
  query getMusicProfiles($defaultBrandName: String!) {
    musicProfiles(defaultBrandName: $defaultBrandName) {
      id
      name
      brandId
      tunifyMusicProfileUserId
      externalRegionId
      musicLicenseId
    }
  }
`;

export const GET_ORGANIZATION_WITH_LOCATIONS = gql`
  query getOrganizationsWithLocations {
    organizations {
      id
      name
      locations {
        id
        name
        streetName
        streetNumber
        city
        zip
        organization {
          id
        }
      }
    }
  }
`;

export const GET_LOCATION_BY_ID = gql`
  query getLocationById($locationId: ID!) {
    location(id: $locationId) {
      id
      name
      zones {
        id
        name
        musicProfile {
          id
          name
        }
        location {
          id
        }
      }
    }
  }
`;

export const GET_DETAILED_ZONE_BY_ID = gql`
  query zone($zoneId: ID!) {
    zone(id: $zoneId) {
      id
      name
      musicProfile {
        id
        name
      }
      location {
        id
        organization {
          id
        }
      }
      currentSubscription {
        id
        startDate
        endDate
        hasForeverRenewalOn
        subCreatePolicy
        subscriptionLines {
          id
          product {
            id
            name
            shortName
            level
            isAddon
            type
            categoryId
            subscriptionTemplate {
              id
              recurringRuleBoundary
            }
          }
        }
      }
    }
  }
`;

export const CHANGE_MUSIC_PROFILE_OF_ZONES = gql`
  mutation changeMusicProfileOfZones($entries: [ChangeMusicProfileOfZoneInputData!]!) {
    changeMusicProfileOfZones(entries: $entries) {
      myTunifyZoneId
      musicProfileId
    }
  }
`;
