import gql from 'graphql-tag';

export const EDIT_MUSIC_PROFILE = gql`
  mutation editMusicProfile($id: ID!, $name: String!) {
    editMusicProfile(id: $id, name: $name) {
      id
      name
      brandId
      tunifyMusicProfileUserId
      externalRegionId
      musicLicenseId
    }
  }
`;

export const DELETE_MUSIC_PROFILE = gql`
  mutation deleteMusicProfile($id: ID!) {
    deleteMusicProfile(id: $id)
  }
`;
