import { closestCenter } from '@dnd-kit/core';
import { DroppableTypes } from './types';

export const customCollisionDetectionStrategy = (rects, rect) => {
  const trashRect = rects.filter(([id]) => id.includes(DroppableTypes.PLAYLIST));
  const intersectingTrashRect = closestCenter(trashRect, rect);

  if (intersectingTrashRect && rect?.offsetLeft < 260) {
    return intersectingTrashRect;
  }

  const otherRects = rects.filter(([id]) => !id.includes(DroppableTypes.PLAYLIST));

  return closestCenter(otherRects, rect);
};
