export const Tabs = {
  PLAYLIST: 'PLAYLIST',
  SEARCH: 'SEARCH',
  IMPORT: 'IMPORT',
  PLAYER: 'PLAYER',
};

export const PlaylistModalModes = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
  CREATE_WITH_SONGS: 'CREATE_WITH_SONGS',
};

export const DroppableTypes = {
  PLAYLIST: 'PLAYLIST',
  CREATE_PLAYLIST: 'CREATE_PLAYLIST',
};

export const PlaylistTypes = {
  CUSTOM: 'CUSTOM',
  TUNIFY: 'TUNIFY',
  SEARCH: 'SEARCH',
};
