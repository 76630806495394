import styled from 'styled-components';

import { SIZE, THICKNESS, COLOR } from './styles';

const DIMENSION = 44;

const HorizontallyCenteredDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  display: inline-block;
  ${(props) => {
    const size = SIZE[props.size];
    return `width: ${size}; height: ${size}`;
  }}
`;

const StyledSvg = styled.svg`
  display: block;
  animation: rotate 2s linear infinite;

  & circle {
    stroke: ${(props) => COLOR[props.color]};
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

/**
 * A customizable loading spinner.
 *
 * If the loading spinner is describing the loading progress of a particular region
 * of a page, you should use aria-describedby to point to the loading spinner,
 * and set the aria-busy attribute to true on that region until it has
 * finished loading.
 *
 * @param {('small'|'smallMedium'|'medium'|'large')} [size=medium] - The size of the loadingSpinner
 * @param {('thin'|'medium'|'thick')} [thickness=medium] - The thickness of the loadingSpinner
 * @param {('neutral'|'primary'|'white')} [color=neutral] - The color of the loadingSpinner
 * @param {string} className - Styled components passed-in prop
 */
const LoadingIndicator = ({
  size = 'medium',
  thickness = 'medium',
  color = 'neutral',
  className,
}) => {
  const strokeWidth = THICKNESS[thickness];

  return (
    <HorizontallyCenteredDiv>
      <Container className={className} size={size}>
        <StyledSvg
          color={color}
          preserveAspectRatio="none"
          viewBox={`${DIMENSION / 2} ${
            DIMENSION / 2
          } ${DIMENSION} ${DIMENSION}`}
        >
          <circle
            cx={DIMENSION}
            cy={DIMENSION}
            fill="none"
            r={(DIMENSION - strokeWidth) / 2}
            strokeWidth={strokeWidth}
          />
        </StyledSvg>
      </Container>
    </HorizontallyCenteredDiv>
  );
};

export default LoadingIndicator;
