import { useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Modal, ErrorMessage, useClearError, Button } from '../../../common';

const Message = styled.p`
  font-size: 1.4rem;
  line-height: 1.4;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
  padding: 1.6rem;
  background-color: ${({ theme }) => theme.uiBackground};
  border-top: 1px solid ${({ theme }) => theme.stroke};
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-bottom: 1rem;
`;

const DeleteModal = ({
  isOpen,
  onConfirm,
  onCancel,
  canDelete = true,
  cannotDeleteMessage,
  requestLoading,
  requestError,
  requestSuccess,
  successMessage,
  deleteMessage,
  requestSuccessMessage,
  cancelText,
  confirmText,
}) => {
  const { t } = useTranslation();

  const [error, clearError] = useClearError(requestError);

  const handleOnClose = useCallback(() => {
    if (!requestLoading) {
      clearError();
      onCancel();
    }
  }, [clearError, onCancel, requestLoading]);

  const content = useMemo(() => {
    if (requestSuccess) return <Message>{successMessage}</Message>;
    if (!canDelete) return <Message>{cannotDeleteMessage}</Message>;
    return <Message>{deleteMessage}</Message>;
  }, [
    canDelete,
    requestSuccess,
    successMessage,
    cannotDeleteMessage,
    deleteMessage,
  ]);

  return (
    <Modal
      emphasis="warning"
      isOpen={isOpen}
      onClose={handleOnClose}
      hideHeader
      footer={
        <Actions>
          {requestSuccess ? (
            <Button
              emphasis="secondary"
              isDisabled={requestLoading || !canDelete}
              onClick={handleOnClose}
            >
              {requestSuccessMessage}
            </Button>
          ) : (
            <>
              <Button
                emphasis="secondary"
                highlightColor="grapefruitRed"
                isDisabled={requestLoading}
                onClick={handleOnClose}
              >
                {cancelText ?? t('common:button.cancel')}
              </Button>
              <Button
                highlightColor="grapefruitRed"
                isDisabled={requestLoading || !canDelete}
                isLoading={requestLoading}
                onClick={onConfirm}
              >
                {confirmText ?? t('common:button.delete')}
              </Button>
            </>
          )}
        </Actions>
      }
    >
      {content}
      {error && <StyledErrorMessage error={error} />}
    </Modal>
  );
};

export default DeleteModal;
