import { useMemo, useCallback, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { COUPLE_ZONE_TO_SONOS } from '../../api';

import ZoneRow from './zoneRow';

const Wrapper = styled.div`
  display: grid;
  gap: 0.6rem;
`;

const ZoneTable = ({
  locations,
  zones,
  setCouplingWasSuccessful,
  setCouplingHasFailed,
  setZoneNameToCouple,
}) => {
  const [searchParams] = useSearchParams();
  const linkCode = searchParams.get('linkCode');
  const [zoneIdToCouple, setZoneIdToCouple] = useState();

  if (!linkCode)
    throw new Error(
      'No link code in query parameters, the user must have navigated directly to this URL'
    );

  const locationNamesById = useMemo(
    () =>
      locations.reduce((acc, curr) => {
        acc[curr.id] = curr.name;
        return acc;
      }, {}),
    [locations]
  );

  const [coupleZoneToSonos, { loading }] = useMutation(COUPLE_ZONE_TO_SONOS, {
    onCompleted: () => {
      setCouplingWasSuccessful(true);
    },
    onError: () => {
      setCouplingHasFailed(true);
    },
  });

  const handleCoupleZoneToSonos = useCallback(
    (zoneId, zoneName, locationName) => {
      setZoneIdToCouple(zoneId);
      setZoneNameToCouple(`${locationName} - ${zoneName}`);
      coupleZoneToSonos({ variables: { zoneId, linkCode } });
    },
    [coupleZoneToSonos, linkCode, setZoneNameToCouple]
  );

  return (
    <Wrapper>
      {zones.map((zone) => (
        <ZoneRow
          key={zone.id}
          coupleZoneToSonos={handleCoupleZoneToSonos}
          isDisabled={loading && zoneIdToCouple !== zone.id}
          isLoading={loading && zoneIdToCouple === zone.id}
          locationName={locationNamesById[zone.locationId]}
          zoneId={zone.id}
          zoneName={zone.name}
        />
      ))}
    </Wrapper>
  );
};

export default ZoneTable;
