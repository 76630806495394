import { determineAvailableMusicLicenses } from '../../../../common';
import { determineAvailableColors } from './determineAvailableColors';
import { determineAvailableSubscriptionTemplates } from './determineAvailableSubscriptionTemplates';
import { determineSelectedProduct } from './determineSelectedProduct';

const getTheSameMusicLicense = (currentMusicLicense, availableMusicLicenses) => {
  const musicLicense = availableMusicLicenses.find(
    (availableMusicLicense) => availableMusicLicense.id === currentMusicLicense.id
  );

  if (!musicLicense) {
    throw new Error(
      [
        `When switching from forever to fixed product or vice versa,`,
        `the same music license [${currentMusicLicense.id}]`,
        `for the product could not been found.`,
      ].join(' ')
    );
  }
  return musicLicense;
};

const getTheSameColor = (currentColor, availableColors) => {
  const color = availableColors.find((availableColor) => availableColor.id === currentColor.id);

  if (!color) {
    throw new Error(
      [
        `When switching from forever to fixed product or vice versa,`,
        `the same color [${currentColor.id}]`,
        `for the product could not been found.`,
      ].join(' ')
    );
  }
  return color;
};

const getSubscriptionTemplateWithSameRecurringInterval = (
  currentSubscriptionTemplate,
  availableSubscriptionTemplates
) => {
  const subscriptionTemplate = availableSubscriptionTemplates.find(
    (availableSubscriptionTemplate) =>
      availableSubscriptionTemplate.recurringInterval === currentSubscriptionTemplate.recurringInterval
  );

  if (!subscriptionTemplate) {
    throw new Error(
      [
        `When switching from forever to fixed product or vice versa,`,
        `a subscription template with the same recurringInterval,`,
        `[${currentSubscriptionTemplate.recurringInterval}]`,
        `for the product could not been found.`,
      ].join(' ')
    );
  }

  return subscriptionTemplate;
};

export const switchFromForeverToFixedProductAndViceVersa = (currentProduct, productCatalogue) => {
  const currentProductIsForever = currentProduct.isForever;

  const availableMusicLicenses = determineAvailableMusicLicenses(productCatalogue, !currentProductIsForever);
  const musicLicense = getTheSameMusicLicense(currentProduct.musicLicense, availableMusicLicenses);

  const availableColors = determineAvailableColors(productCatalogue, [musicLicense.id], !currentProductIsForever);
  const color = getTheSameColor(currentProduct.color, availableColors);

  const availableSubscriptionTemplates = determineAvailableSubscriptionTemplates(
    productCatalogue,
    [musicLicense.id],
    [color.id],
    !currentProductIsForever
  );
  const subscriptionTemplate = getSubscriptionTemplateWithSameRecurringInterval(
    currentProduct.subscriptionTemplate,
    availableSubscriptionTemplates
  );

  const newProduct = determineSelectedProduct(
    productCatalogue,
    [musicLicense.id],
    [color.id],
    [subscriptionTemplate.id],
    !currentProductIsForever
  );

  return newProduct;
};
