import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button, formatNumber, useSessionStorage } from '../../../common';
import {
  PARTNER_MANAGER_ROLE_PERMISSIONS,
  SUPPORT_ADMIN_ROLE_PERMISSIONS,
  hasAllRequiredPermissions,
} from '../../../global/auth';
import { useAuth } from '../../../global/auth/newAuthProvider';
import { useAddonsBuy } from './addonsBuyContext';

const StyledButton = styled(Button)`
  & p {
    color: ${({ theme }) => theme.white};
  }
  &:hover {
    border-color: ${({ theme }) => theme.dark200};
  }
`;

const Footer = ({
  hasPreviousStep,
  goToPreviousStep,
  goToNextStep,
  hasNextStep,
  canProceedToNextStep,
  goToNextStepLoading,
}) => {
  const { t, i18n } = useTranslation();
  const { user: userInfo } = useAuth();

  const { saleOrder } = useAddonsBuy();

  const [impersonationId] = useSessionStorage('impersonationId');
  const isSupportAdmin = hasAllRequiredPermissions(
    userInfo,
    SUPPORT_ADMIN_ROLE_PERMISSIONS
  );
  const isPartnerManager = hasAllRequiredPermissions(
    userInfo,
    PARTNER_MANAGER_ROLE_PERMISSIONS
  );

  const nextButtonLabel = useMemo(() => {
    if (hasNextStep) {
      return t('common:button.next');
    }
    return (isSupportAdmin || isPartnerManager) && impersonationId
      ? t('common:button.confirm')
      : saleOrder?.amountTotal
      ? `${t('common:button.pay')} - €${formatNumber(
          saleOrder.amountTotal,
          i18n.language
        )}`
      : t('common:button.pay');
  }, [
    hasNextStep,
    isSupportAdmin,
    isPartnerManager,
    impersonationId,
    t,
    saleOrder?.amountTotal,
    i18n.language,
  ]);

  const nextButtonTitle = useMemo(() => {
    if (hasNextStep) {
      return t('common:button.title.goTo', {
        value: t('common:button.title.nextStep'),
      });
    }
    return (isSupportAdmin || isPartnerManager) && impersonationId
      ? t('common:button.title.confirm')
      : t('common:button.title.goTo', { value: t('common:button.title.pay') });
  }, [isSupportAdmin, isPartnerManager, impersonationId, hasNextStep, t]);

  return (
    <>
      <StyledButton
        emphasis="outline"
        highlightColor="dark400"
        onClick={goToPreviousStep}
        title={
          hasPreviousStep
            ? t('common:button.title.goTo', {
                value: t('common:button.title.previousStep'),
              })
            : t('common:button.title.cancel')
        }
      >
        {hasPreviousStep
          ? t('common:button.previous')
          : t('common:button.cancel')}
      </StyledButton>
      <StyledButton
        emphasis="primary"
        highlightGradient={hasNextStep ? 'nightfall' : 'blooming'}
        isDisabled={!canProceedToNextStep}
        isLoading={goToNextStepLoading}
        onClick={goToNextStep}
        title={nextButtonTitle}
      >
        {nextButtonLabel}
      </StyledButton>
    </>
  );
};

export default Footer;
