export const Tabs = {
  GENERAL: 'GENERAL',
  TUNIFY_GREEN: 'TUNIFY_GREEN',
  TUNIFY_BLUE: 'TUNIFY_BLUE',
  THEME_MUSIC: 'THEME_MUSIC',
};

export const DropdownOptionTypes = {
  PROFILE: 'PROFILE',
  ZONE: 'ZONE',
};

export const AddonTabType = {
  BROADCASTS: 'broadcasts',
  MUSIC_MANAGEMENT: 'musicmanagement',
};
