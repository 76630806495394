import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { GET_TUNIFY_CALENDAR_GROUPS } from './api';

export const useUpdateChannelCacheAfterUpdate = ({ musicProfileId, calendarGroupId, calendarId }) => {
  const { i18n } = useTranslation();

  return useCallback(
    (cache, { data }) => {
      const { tunifyCalendarGroups } = cache.readQuery({
        query: GET_TUNIFY_CALENDAR_GROUPS,
        variables: {
          musicProfileId,
          language: i18n.language,
        },
      });

      const visible = data?.updateCalendarVisibility?.visible ?? false;

      const updatedCalendars = tunifyCalendarGroups.map((channelGroup) => {
        if (Number(calendarGroupId) === Number(channelGroup.id)) {
          return {
            ...channelGroup,
            calendars: channelGroup.calendars.map((calendar) => {
              if (Number(calendarId) === Number(calendar.calendarId)) {
                return {
                  ...calendar,
                  properties: {
                    ...calendar.properties,
                    visible,
                  },
                };
              }
              return calendar;
            }),
          };
        }

        return channelGroup;
      });

      cache.writeQuery({
        query: GET_TUNIFY_CALENDAR_GROUPS,
        variables: {
          musicProfileId,
          language: i18n.language,
        },
        data: {
          tunifyCalendarGroups: updatedCalendars,
        },
      });
    },
    [musicProfileId, calendarGroupId, calendarId, i18n.language]
  );
};

export const useUpdateChannelGroupCacheAfterUpdate = ({ musicProfileId, calendarGroupId, shouldUpdateChildren }) => {
  const { i18n } = useTranslation();

  return useCallback(
    (cache, { data }) => {
      const { tunifyCalendarGroups } = cache.readQuery({
        query: GET_TUNIFY_CALENDAR_GROUPS,
        variables: {
          musicProfileId,
          language: i18n.language,
        },
      });

      const visible = data?.updateCalendarGroupVisibility?.visible ?? false;

      const updatedCalendarGroups = tunifyCalendarGroups.map((channelGroup) => {
        if (Number(calendarGroupId) === Number(channelGroup.id)) {
          const updatedChannelGroup = {
            ...channelGroup,
            properties: {
              ...channelGroup.properties,
              visible,
            },
          };
          if (shouldUpdateChildren) {
            const updatedChildren = channelGroup.calendars.map((calendar) => {
              return { ...calendar, properties: { ...calendar.properties, visible } };
            });
            return { ...updatedChannelGroup, calendars: updatedChildren };
          }
          return updatedChannelGroup;
        }

        return channelGroup;
      });

      cache.writeQuery({
        query: GET_TUNIFY_CALENDAR_GROUPS,
        variables: {
          musicProfileId,
          language: i18n.language,
        },
        data: {
          tunifyCalendarGroups: updatedCalendarGroups,
        },
      });
    },
    [musicProfileId, calendarGroupId, i18n.language, shouldUpdateChildren]
  );
};
