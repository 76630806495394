import gql from "graphql-tag";

const LANGUAGE_CODES = gql`
  query languageCodes {
    languages {
      code
    }
  }
`;

export { LANGUAGE_CODES };
