import { useMutation, useQuery } from '@apollo/react-hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { addMinutes, formatISO9075, getHours, getMinutes, set } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as Yup from 'yup';

import {
  DateField,
  ErrorMessage,
  Modal,
  SelectField,
  StringField,
  TextField,
  TimeField,
  useSessionStorage,
} from '../../../common';
import { useAuth } from '../../../global/auth/newAuthProvider';
import { useGlobalState } from '../../../global/state';
import {
  CREATE_CONTACT_CALL,
  GET_CONTACT_CATEGORIES_AND_CHANNELS,
  GET_ORGANIZATIONS_AND_LOCATIONS_OF_USER,
} from './api';

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 1rem;
  width: 100%;
`;

const ErrorWrapper = styled.div`
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    grid-column: span 3;
  }
`;

const GridForm = styled.form`
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    display: grid;
    grid-column-gap: 2rem;
    grid-template-columns: 1fr min-content 1fr;
    align-items: flex-start;
  }
`;

const ContactContainer = styled.div`
  display: grid;
  grid-gap: 2rem;
`;

const DetailsContainer = styled.div`
  display: grid;
  grid-gap: 2rem;
`;

const DuoColumn = styled.div`
  display: grid;
  grid-gap: 2.4rem 1.6rem;
  grid-template-columns: 1fr;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const SectionLabel = styled.div`
  color: ${({ theme }) => theme.dark200};
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.6;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  width: fit-content;
  padding-top: 4.2rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    display: none;
  }
`;

const Divider = styled.div`
  display: none;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    display: grid;
    width: 2px;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.stroke};
    height: 100%;
  }
`;

const ScheduleCallForm = ({ toggle, isOpen }) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const { logContact, setLogContact } = useGlobalState();
  const [impersonatorId] = useSessionStorage('impersonationId');

  const schema = Yup.object().shape({
    summary: Yup.string().required(
      t('common:form.validation.summary.required')
    ),
    contactCategoryId: Yup.object()
      .nullable(true)
      .required(t('common:form.validation.contactCategory.required')),
    contactChannelId: Yup.object()
      .nullable(true)
      .required(t('common:form.validation.contactChannel.required')),
    firstName: Yup.string(),
    lastName: Yup.string(),
    phone: Yup.string().required(
      t('common:form.validation.customerSuccessPhone.required')
    ),
    emailAddress: Yup.string()
      .email(t('common:form.validation.emailAddress.type'))
      .required(
        t('common:form.validation.customerSuccessEmailAddress.required')
      ),
    locationId: Yup.object().nullable(true),
  });

  const {
    data: contactCategoriesAndChannelsData,
    loading: contactCategoriesAndChannelsLoading,
    error: contactCategoriesAndChannelsError,
  } = useQuery(GET_CONTACT_CATEGORIES_AND_CHANNELS);

  const contentCategoryOptions = useMemo(
    () => [
      ...(contactCategoriesAndChannelsData?.contactCategories?.map(
        (category) => ({
          value: category.id,
          label: category.name,
        })
      ) ?? []),
    ],
    [contactCategoriesAndChannelsData?.contactCategories]
  );

  const contentChannelOptions = useMemo(
    () => [
      ...(contactCategoriesAndChannelsData?.contactChannels?.map((channel) => ({
        value: channel.id,
        label: channel.name,
      })) ?? []),
    ],
    [contactCategoriesAndChannelsData?.contactChannels]
  );

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      summary: logContact?.summary ?? '',
      contactCategoryId:
        contentCategoryOptions.find(
          (option) => option.value === logContact?.contactCategoryId
        ) ?? null,
      contactChannelId:
        contentChannelOptions.find(
          (option) => option.value === logContact?.contactChannelId
        ) ?? null,
      locationId:
        (logContact?.locationId && logContact?.locationId.toString()) ?? null,
      contactDate: new Date(),
      contactTime: new Date(),
      firstName: logContact?.firstName ?? user.firstName,
      lastName: logContact?.lastName ?? user.lastName,
      phone: logContact?.phone ?? user.phone,
      emailAddress: logContact?.emailAddress ?? user.emailAddress,
    },
  });

  const [createContactCall, { loading: requestLoading, error: requestError }] =
    useMutation(CREATE_CONTACT_CALL, {
      onCompleted: () => {
        setLogContact({});
        toggle();
        reset();
      },
      refetchQueries: ['getContactCalls', 'getOpenContactCalls'],
    });

  const onConfirm = useCallback(
    (values) => {
      let deadlineDate = null;

      const datefiedTime =
        typeof values.contactTime === 'string'
          ? set(new Date(), {
              hours: values.contactTime.slice(0, 2),
              minutes: values.contactTime.slice(3, 5),
            })
          : values.contactTime;

      const date = set(new Date(values.contactDate), {
        hours: getHours(datefiedTime),
        minutes: getMinutes(datefiedTime),
      });
      deadlineDate = formatISO9075(addMinutes(date, date.getTimezoneOffset()));

      createContactCall({
        variables: {
          contactCall: {
            summary: values.summary,
            contactCategoryId: Number(values.contactCategoryId?.value),
            contactChannelId: Number(values.contactChannelId?.value),
            contactPersonFirstName: values.firstName,
            contactPersonLastName: values.lastName,
            contactPersonPhone: values.phone,
            contactPersonEmailAddress: values.emailAddress,
            // `userId` will be the id of the impersonated user (when in impersonated session)
            contactPersonId: Number(impersonatorId),
            locationId: Number(values.locationId?.value),
            agentId: user.impersonatorId,
            deadlineDate,
            stage: 'OPEN',
          },
        },
      });
    },
    [createContactCall, impersonatorId, user]
  );

  const handleCancel = useCallback(() => {
    setLogContact({});
    toggle();
    reset();
  }, [setLogContact, reset, toggle]);

  const {
    data: organizationsData,
    error: organizationsError,
    loading: organizationsLoading,
  } = useQuery(GET_ORGANIZATIONS_AND_LOCATIONS_OF_USER, {
    variables: {
      userId: Number(user.id),
    },
  });

  const locationOptions = useMemo(() => {
    if (!organizationsData) return [];
    const { organizationsOfUser } = organizationsData;

    const sortedOrganizations = organizationsOfUser.sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    const options = sortedOrganizations.reduce((acc, curr) => {
      const sortedLocations = curr.locations.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      const entries = sortedLocations.map((location) => ({
        value: location.id,
        label: `${curr.name} - ${location.name}`,
      }));
      return [...acc, ...entries];
    }, []);

    return options;
  }, [organizationsData]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCancel}
      title={t('common:button.scheduleCall')}
      isWide
      onConfirm={handleSubmit(onConfirm)}
      requestLoading={requestLoading}
    >
      {/* No `onSubmit` necessary, because form does not contain a submit button. */}
      <GridForm>
        <ContactContainer>
          <SelectField
            control={control}
            error={errors?.locationId?.message}
            isLoading={organizationsLoading}
            label={t('common:form.label.location')}
            name="locationId"
            options={locationOptions}
            placeholder={t('common:form.placeholder.location')}
          />
          <TextField
            control={control}
            error={errors?.summary?.message}
            label={t('common:form.label.summary')}
            name="summary"
            rows={4}
            placeholder={t('common:form.placeholder.logContactSummary')}
          />
          <DuoColumn>
            <SelectField
              control={control}
              error={errors?.contactCategoryId?.message}
              isLoading={contactCategoriesAndChannelsLoading}
              label={t('common:form.label.contactCategory')}
              name="contactCategoryId"
              options={contentCategoryOptions}
              placeholder={t('common:form.placeholder.contactCategory')}
            />
            <SelectField
              control={control}
              error={errors?.contactChannelId?.message}
              isLoading={contactCategoriesAndChannelsLoading}
              label={t('common:form.label.contactChannel')}
              name="contactChannelId"
              options={contentChannelOptions}
              placeholder={t('common:form.placeholder.contactChannel')}
            />
            <DateField
              control={control}
              disablePastDaySelection
              disableWeekendDaySelection
              error={errors?.contactDate?.message}
              label={t('common:form.label.date')}
              name="contactDate"
              placeholder={t('common:form.placeholder.date')}
            />
            <TimeField
              control={control}
              error={errors?.contactTime?.message}
              label={t('common:form.label.hour')}
              name="contactTime"
              placeholder={t('common:form.placeholder.hour')}
            />
          </DuoColumn>
        </ContactContainer>
        <Divider />
        <DetailsContainer>
          <SectionLabel>{t('common:form.group.contactDetails')}</SectionLabel>
          <DuoColumn>
            <StringField
              register={register}
              error={errors?.firstName?.message}
              label={t('common:form.label.firstName')}
              name="firstName"
              placeholder={t(
                'common:form.placeholder.customerSuccessFirstName'
              )}
            />
            <StringField
              register={register}
              error={errors?.lastName?.message}
              label={t('common:form.label.lastName')}
              name="lastName"
              placeholder={t('common:form.placeholder.customerSuccessLastName')}
            />
            <StringField
              register={register}
              error={errors?.phone?.message}
              label={t('common:form.label.phone')}
              name="phone"
              placeholder={t('common:form.placeholder.customerSuccessPhone')}
            />
            <StringField
              register={register}
              error={errors?.emailAddress?.message}
              label={t('common:form.label.emailAddress')}
              name="emailAddress"
              placeholder={t(
                'common:form.placeholder.customerSuccessEmailAddress'
              )}
            />
          </DuoColumn>
        </DetailsContainer>
        <ErrorWrapper>
          {(requestError ||
            contactCategoriesAndChannelsError ||
            organizationsError) && (
            <StyledErrorMessage
              error={
                requestError ||
                contactCategoriesAndChannelsError ||
                organizationsError
              }
            />
          )}
        </ErrorWrapper>
      </GridForm>
    </Modal>
  );
};

export default ScheduleCallForm;
