import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { GET_TUNIFY_CHANNEL_GROUPS } from './api';

export const useUpdateChannelCacheAfterUpdate = ({ musicProfileId, musicChannelGroupId, musicChannelId }) => {
  const { i18n } = useTranslation();

  return useCallback(
    (cache, { data }) => {
      const { tunifyChannelGroups } = cache.readQuery({
        query: GET_TUNIFY_CHANNEL_GROUPS,
        variables: {
          musicProfileId,
          language: i18n.language,
        },
      });

      const visible = data?.updateMusicChannelVisibility?.visible ?? false;

      const updatedChannels = tunifyChannelGroups.map((channelGroup) => {
        if (Number(musicChannelGroupId) === Number(channelGroup.id)) {
          return {
            ...channelGroup,
            musicChannels: channelGroup.musicChannels.map((channel) => {
              if (Number(musicChannelId) === Number(channel.id)) {
                return {
                  ...channel,
                  properties: {
                    ...channel.properties,
                    visible,
                  },
                };
              }
              return channel;
            }),
          };
        }

        return channelGroup;
      });

      cache.writeQuery({
        query: GET_TUNIFY_CHANNEL_GROUPS,
        variables: {
          musicProfileId,
          language: i18n.language,
        },
        data: {
          tunifyChannelGroups: updatedChannels,
        },
      });
    },
    [musicProfileId, musicChannelGroupId, musicChannelId, i18n.language]
  );
};

export const useUpdateChannelGroupCacheAfterUpdate = ({
  musicProfileId,
  musicChannelGroupId,
  shouldUpdateChildren,
}) => {
  const { i18n } = useTranslation();

  return useCallback(
    (cache, { data }) => {
      const { tunifyChannelGroups } = cache.readQuery({
        query: GET_TUNIFY_CHANNEL_GROUPS,
        variables: {
          musicProfileId,
          language: i18n.language,
        },
      });

      const visible = data?.updateMusicChannelGroupVisibility?.visible ?? false;

      const updatedChannelGroups = tunifyChannelGroups.map((channelGroup) => {
        if (Number(musicChannelGroupId) === Number(channelGroup.id)) {
          const updatedChannelGroup = {
            ...channelGroup,
            properties: {
              ...channelGroup.properties,
              visible,
            },
          };
          if (shouldUpdateChildren) {
            const updatedChildren = channelGroup.musicChannels.map((musicChannel) => {
              return { ...musicChannel, properties: { ...musicChannel.properties, visible } };
            });
            return { ...updatedChannelGroup, musicChannels: updatedChildren };
          }
          return updatedChannelGroup;
        }

        return channelGroup;
      });

      cache.writeQuery({
        query: GET_TUNIFY_CHANNEL_GROUPS,
        variables: {
          musicProfileId,
          language: i18n.language,
        },
        data: {
          tunifyChannelGroups: updatedChannelGroups,
        },
      });
    },
    [musicProfileId, i18n.language, shouldUpdateChildren, musicChannelGroupId]
  );
};
