import { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';

import { LoadingIndicator } from '../../../../../common';
import { ReactComponent as ArrowRight } from '../../../../../assets/icons/arrowRight.svg';
import { ReactComponent as Add } from '../../../../../assets/icons/add.svg';
import { CategoryModal } from '../modals';
import { PlaylistButton, CollapsableButton } from '../../../shared';
import Channel from './channel';
import { useTunifyBlue } from '../tunifyBlueProvider';
import { CategoryModalModes } from '../types';

const Container = styled.div`
  display: grid;
  grid-template-rows: min-content fit-content;
  ${({ collapsed }) => collapsed && 'opacity: 0.7;'}
`;

const Wrapper = styled(motion.div)`
  overflow-y: auto;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin: 1rem 0;
`;

const StyledArrowRight = styled(ArrowRight)`
  transform: ${({ $collapsed }) =>
    $collapsed ? 'rotate(90deg)' : 'rotate(270deg)'};
  margin-right: 0.5rem;
`;

const StyledPlaylistButton = styled(PlaylistButton)`
  & p {
    color: ${({ theme }) => theme.babyBlue};
  }
  & svg path {
    fill: ${({ theme }) => theme.babyBlue};
  }
  ${({ isOver, theme }) =>
    isOver &&
    css`
      background-color: ${theme.dark400};
    `}
`;

const Channels = ({ name, openedCollapsible, setOpenedCollapsible }) => {
  const { t } = useTranslation();

  const {
    dispatch,
    customChannels,
    loadingCustomPlaylists,
    loadingFavouritePlaylist,
    modalMode,
  } = useTunifyBlue();

  const collapsed = openedCollapsible !== 'custom';

  const handleOpenCreatePlaylist = useCallback(() => {
    dispatch({ modalMode: CategoryModalModes.CREATE });
  }, [dispatch]);

  const handleCloseCategory = useCallback(() => {
    dispatch({ modalMode: null });
  }, [dispatch]);

  const handleOpenCollapsible = useCallback(() => {
    if (collapsed) {
      setOpenedCollapsible('custom');
    } else {
      setOpenedCollapsible('');
    }
  }, [setOpenedCollapsible, collapsed]);

  return (
    <Container collapsed={collapsed}>
      <CollapsableButton
        $collapsed={collapsed}
        onClick={handleOpenCollapsible}
        type="button"
      >
        <p>{name}</p>
        <StyledArrowRight
          $collapsed={collapsed}
          height="0.7rem"
          width="0.7rem"
        />
      </CollapsableButton>
      <AnimatePresence>
        {!collapsed ? (
          <Wrapper
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            initial={{ height: 0 }}
            transition={{ ease: 'easeInOut', duration: 0.3 }}
          >
            <StyledPlaylistButton
              onClick={handleOpenCreatePlaylist}
              type="button"
            >
              <Add height="0.7rem" width="0.7rem" />
              <p>{t('musicManagement:playlists.list.new')}</p>
            </StyledPlaylistButton>
            {loadingCustomPlaylists || loadingFavouritePlaylist ? (
              <StyledLoadingIndicator color="primary" size="small" />
            ) : null}
            {customChannels?.length > 0 && !loadingCustomPlaylists
              ? customChannels.map((channel) => (
                  <Channel key={channel.id} channel={channel} />
                ))
              : null}
          </Wrapper>
        ) : null}
      </AnimatePresence>
      <CategoryModal
        isOpen={!!modalMode}
        mode={modalMode}
        toggle={handleCloseCategory}
      />
    </Container>
  );
};

export default Channels;
