import styled from 'styled-components';
import { ReactComponent as GiftsIcon } from '../../../assets/icons/navigation/gifts.svg';

import { useMutation, useQuery } from '@apollo/react-hooks';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useCopyClipboard from 'react-use-clipboard';
import { ReactComponent as FacebookIcon } from '../../../assets/icons/facebook.svg';
import { ReactComponent as WhatsAppIcon } from '../../../assets/icons/whatsApp.svg';
import { Button, LinkButton } from '../../../common';
import StringField from '../../../common/fields/stringField';
import config from '../../../config';
import { useAuth } from '../../../global/auth/newAuthProvider';
import {
  GET_CUSTOMER_CODE,
  SEND_INVITATION_EMAIL,
} from '../../gifts/overview/api';

const BASE_URL = config.BASE_URL;

const Header = styled.div`
  width: 100%;

  background-color: #22b580;
  padding: 28px 24px;

  border-radius: 8px 8px 0 0;

  display: flex;
  align-items: center;
  gap: 16px;

  p {
    color: white;
    font-size: 24px;
    font-weight: 800;
    line-height: 32px;
  }
`;

const GiftContainer = styled.div`
  width: 40px;
  height: 40px;

  svg,
  rect {
    fill: white;
  }
`;

const Card = styled.div`
  background-color: white;
  border-radius: 0 0 8px 8px;
  padding: 24px 24px 32px 24px;
  width: 100%;
  height: fit-content;
  border: 1px solid #dfe0e0;
  border-top: unset;
`;

const InfoText = styled.p`
  color: #868b92;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 24px;
`;

const Bold = styled.p`
  color: #363e4a;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 16px;
`;

const FlexContainerSpaceBetween = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  & > div {
    width: 100%;
  }

  button > div {
    padding: 12px 70px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

const Divider = styled.div`
  border-top: 1px solid #dfe0e0;
  margin: 24px 0 32px 0;
  width: 100%;
`;

const StyledStringField = styled(StringField)`
  input[type='text'][disabled] {
    color: #868b92 !important;
  }
`;

const StyledLinkButton = styled(LinkButton)`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.stroke};
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  gap: 0.8rem;
  padding: 0 1.6rem;
  transition: border-color 0.2s ease-in-out;

  &:hover {
    border-width: 1px;
  }
`;

export const Invite = () => {
  const { t } = useTranslation();

  const { user } = useAuth();

  const [isSuccess, setIsSuccess] = React.useState();

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      emails: '',
    },
  });

  const {
    loading: customerCodeLoading,
    error: customerCodeError,
    data: customerCodeData,
  } = useQuery(GET_CUSTOMER_CODE, {
    fetchPolicy: 'cache-first',
  });

  const link = `${BASE_URL}/onboard/invite?member-code=${customerCodeData?.memberGetMemberCustomerCode}`;

  const [, setClipboard] = useCopyClipboard(link, { successDuration: 4000 });

  const [sendInvitationEmail, { loading: isLoading, error }] = useMutation(
    SEND_INVITATION_EMAIL,
    {
      onCompleted: () => {
        setIsSuccess(true);
      },
    }
  );

  const handleFormSubmit = React.useCallback(
    async (data) => {
      setIsSuccess(false);
      const emailAdressesData = data.emails.replace(/,+/g, '')?.split(' ');
      const emailAdresses = Array.isArray(emailAdressesData)
        ? emailAdressesData
        : [data.emails];
      const matchedItems = emailAdresses.filter((email) =>
        email.match(
          // eslint-disable-next-line no-control-regex
          /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
        )
      );
      if (matchedItems.length === emailAdresses.length) {
        await sendInvitationEmail({
          variables: {
            senderId: user.id,
            senderLang: user.lang?.split('-')?.join('_') ?? '',
            emailAdresses,
          },
        });
        setValue('emails', '');
      } else {
        setError('emails', {
          message: t('common:form.validation.emailAddress.type'),
        });
      }
    },
    [sendInvitationEmail, setError, setValue, t, user.id, user.lang]
  );

  return (
    <div>
      <Header>
        <GiftContainer>
          <GiftsIcon />
        </GiftContainer>
        <p>{t('purchase:free.months.title')}</p>
      </Header>
      <Card>
        <InfoText>{t('purchase:free.months.info')}</InfoText>
        <Bold>{t('gifts:overview.cards.registrationLink.description')}</Bold>
        <FlexContainerSpaceBetween>
          <StyledStringField
            error={customerCodeError && 'Er ging iets mis'}
            value={customerCodeLoading ? 'Laden...' : link}
            disabled
          />
          <Button onClick={setClipboard} size="large">
            {t('common:button.copy')}
          </Button>
        </FlexContainerSpaceBetween>
        <Divider />
        <InfoText>{t('purchase:free.months.emails.info')}</InfoText>
        <Bold>{t('gifts:overview.cards.registrationLink.emails')}</Bold>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <FlexContainerSpaceBetween>
            <StringField
              name="emails"
              register={register}
              placeholder={t(
                'gifts:overview.cards.registrationLink.emailsPlaceholder'
              )}
              error={errors?.emails?.message}
            />
            <Button
              type="submit"
              size="large"
              isSuccess={isSuccess}
              isLoading={isLoading}
              isFail={Boolean(error)}
            >
              {t('common:button.send')}
            </Button>
          </FlexContainerSpaceBetween>
        </form>
        <Divider />
        <InfoText>{t('purchase:free.months.other.platforms.info')}</InfoText>
        <FlexContainer>
          <StyledLinkButton
            emphasis="outline"
            size="medium"
            href={`https://wa.me/?text=${encodeURIComponent(link)}`}
            title={t('common:shareViaWhatsApp')}
          >
            <WhatsAppIcon />
            <p>{t('common:whatsApp')}</p>
          </StyledLinkButton>
          <StyledLinkButton
            emphasis="outline"
            size="medium"
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
              link
            )}`}
            title={t('common:shareViaFacebook')}
          >
            <FacebookIcon />
            <p>{t('common:facebook')}</p>
          </StyledLinkButton>
        </FlexContainer>
      </Card>
    </div>
  );
};
