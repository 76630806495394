import { useQuery } from '@apollo/react-hooks';
import { useCallback, useMemo, useState } from 'react';

import { extractErrorCodeFromApolloError, useLocalStorage } from '../../common';
import { useAuth } from '../../global/auth/newAuthProvider';
import {
  GET_PARTNER_COMMISSION_INVOICES,
  GET_PARTNER_CUSTOMERS_COUNT,
  GET_PARTNER_ORGANIZATION,
  GET_PARTNER_PROGRAM,
  GET_PARTNER_QUALIFIER_CUSTOMERS,
  GET_PARTNER_REFERRED_CUSTOMERS,
  GET_PARTNER_WINNER_CUSTOMERS,
} from './api';
import { PartnerContext } from './partnerContext';

export const LIMIT = 10;

const partnerCategories = [
  {
    id: 'commission',
    i18nKey: 'partner:dashboard.commission',
    isDisabled: false,
  },
  {
    id: 'registrations',
    i18nKey: 'partner:dashboard.registrations',
    isDisabled: false,
  },
  {
    id: 'referredCustomers',
    i18nKey: 'partner:dashboard.referredCustomers',
    isDisabled: false,
  },
];

const PartnerProvider = ({ children }) => {
  const { user } = useAuth();

  const [selectedCategory, setSelectedCategory] = useState(
    partnerCategories[0]
  );

  const [queryRetryCount, setQueryRetryCount] = useState(0);

  const [hasMoreCommissionInvoices, setHasMoreCommissionInvoices] =
    useState(true);
  const [
    fetchMoreCommissionInvoicesError,
    setFetchMoreCommissionInvoicesError,
  ] = useState(null);

  const [hasMoreReferredCustomers, setHasMoreReferredCustomers] =
    useState(true);
  const [fetchMoreReferredCustomersError, setFetchMoreReferredCustomersError] =
    useState(null);

  const [hasMoreQualifierCustomers, setHasMoreQualifierCustomers] =
    useState(true);
  const [
    fetchMoreQualifierCustomersError,
    setFetchMoreQualifierCustomersError,
  ] = useState(null);

  const [hasMoreWinnerCustomers, setHasMoreWinnerCustomers] = useState(true);
  const [fetchMoreWinnerCustomersError, setFetchMoreWinnerCustomersError] =
    useState(null);

  const [
    memoryOrganizationId,
    setMemoryOrganizationId,
    removeMemoryOrganizationId,
  ] = useLocalStorage(`${user.id}_lastViewedPartnerOrg`, '');

  const organizationQueryVariables = useMemo(() => {
    if (memoryOrganizationId) {
      return { organizationId: memoryOrganizationId, withOrganization: true };
    }
    return { organizationId: 'dontcare', withOrganization: false };
  }, [memoryOrganizationId]);

  const {
    data: partnerOrganizationdata,
    error: partnerOrganizationError,
    loading: partnerOrganizationLoading,
    refetch: partnerOrganizationRefetch,
  } = useQuery(GET_PARTNER_ORGANIZATION, {
    variables: organizationQueryVariables,
    onError: (apolloError) => {
      const apolloErrorCode = extractErrorCodeFromApolloError(apolloError);
      // Only refetch when the organization is not found and we didn't retry before
      if (
        apolloErrorCode === 'ORGANIZATION_NOT_FOUND' &&
        queryRetryCount === 0
      ) {
        setQueryRetryCount((prev) => prev + 1);
        removeMemoryOrganizationId();
        partnerOrganizationRefetch({
          organizationId: 'dontcare',
          withOrganization: false,
        });
      }
    },
    onCompleted: (data) => {
      if (!memoryOrganizationId) {
        setMemoryOrganizationId(data.partnerOrganization.id);
      }
    },
  });

  const {
    loading: partnerProgramLoading,
    data: partnerProgramdata,
    error: partnerProgramError,
  } = useQuery(GET_PARTNER_PROGRAM, {
    skip: !memoryOrganizationId,
    variables: { organizationId: memoryOrganizationId },
    fetchPolicy: 'cache-first',
  });

  const {
    loading: partnerCommissionInvoicesLoading,
    data: partnerCommissionInvoicesData,
    error: partnerCommissionInvoicesError,
    fetchMore: fetchMoreCommissionInvoices,
  } = useQuery(GET_PARTNER_COMMISSION_INVOICES, {
    skip: !memoryOrganizationId,
    notifyOnNetworkStatusChange: true,
    variables: {
      organizationId: memoryOrganizationId,
      offset: 0,
      limit: LIMIT,
    },
    fetchPolicy: 'cache-first',
  });

  const {
    loading: partnerCustomersCountLoading,
    data: partnerCustomersCountData,
    error: partnerCustomersCountError,
  } = useQuery(GET_PARTNER_CUSTOMERS_COUNT, {
    skip: !memoryOrganizationId,
    variables: { organizationId: memoryOrganizationId },
    fetchPolicy: 'cache-first',
  });

  const {
    loading: partnerReferredCustomersLoading,
    data: partnerReferredCustomersData,
    error: partnerReferredCustomersError,
    fetchMore: fetchMoreReferredCustomers,
  } = useQuery(GET_PARTNER_REFERRED_CUSTOMERS, {
    skip: !memoryOrganizationId,
    notifyOnNetworkStatusChange: true,
    variables: {
      organizationId: memoryOrganizationId,
      offset: 0,
      limit: LIMIT,
    },
    fetchPolicy: 'cache-first',
  });

  const {
    loading: partnerQualifierCustomersLoading,
    data: partnerQualifierCustomersData,
    error: partnerQualifierCustomersError,
    fetchMore: fetchMoreQualifierCustomers,
  } = useQuery(GET_PARTNER_QUALIFIER_CUSTOMERS, {
    skip: !memoryOrganizationId,
    notifyOnNetworkStatusChange: true,
    variables: {
      organizationId: memoryOrganizationId,
      offset: 0,
      limit: LIMIT,
    },
    fetchPolicy: 'cache-first',
  });

  const {
    loading: partnerWinnerCustomersLoading,
    data: partnerWinnerCustomersData,
    error: partnerWinnerCustomersError,
    fetchMore: fetchMoreWinnerCustomers,
  } = useQuery(GET_PARTNER_WINNER_CUSTOMERS, {
    skip: !memoryOrganizationId,
    notifyOnNetworkStatusChange: true,
    variables: {
      organizationId: memoryOrganizationId,
      offset: 0,
      limit: LIMIT,
    },
    fetchPolicy: 'cache-first',
  });

  const handleFetchMoreReferredCustomers = useCallback(async () => {
    if (partnerReferredCustomersError || fetchMoreReferredCustomersError) {
      // Don't refetch when an error occured
      return;
    }

    try {
      await fetchMoreReferredCustomers({
        variables: {
          offset: partnerReferredCustomersData.partnerReferredCustomers.length,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          setHasMoreReferredCustomers(
            fetchMoreResult.partnerReferredCustomers.length === LIMIT
          );
          return {
            ...prev,
            partnerReferredCustomers: [
              ...prev.partnerReferredCustomers,
              ...fetchMoreResult.partnerReferredCustomers,
            ],
          };
        },
      });
    } catch (e) {
      // Although fetchMore is the way to go for pagination,
      // it provides no easy to use mechanism for error handling.
      // You have to catch the error yourself and set an error state.
      setFetchMoreReferredCustomersError(e);
    }
  }, [
    fetchMoreReferredCustomers,
    fetchMoreReferredCustomersError,
    partnerReferredCustomersData,
    partnerReferredCustomersError,
  ]);

  const handleFetchMoreQualifierCustomers = useCallback(async () => {
    if (partnerQualifierCustomersError || fetchMoreQualifierCustomersError) {
      // Don't refetch when an error occured
      return;
    }

    try {
      await fetchMoreQualifierCustomers({
        variables: {
          offset:
            partnerQualifierCustomersData.partnerQualifierCustomers.length,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          setHasMoreQualifierCustomers(
            fetchMoreResult.partnerQualifierCustomers.length === LIMIT
          );
          return {
            ...prev,
            partnerQualifierCustomers: [
              ...prev.partnerQualifierCustomers,
              ...fetchMoreResult.partnerQualifierCustomers,
            ],
          };
        },
      });
    } catch (e) {
      // Although fetchMore is the way to go for pagination,
      // it provides no easy to use mechanism for error handling.
      // You have to catch the error yourself and set an error state.
      setFetchMoreQualifierCustomersError(e);
    }
  }, [
    fetchMoreQualifierCustomers,
    fetchMoreQualifierCustomersError,
    partnerQualifierCustomersData,
    partnerQualifierCustomersError,
  ]);

  const handleFetchMoreWinnerCustomers = useCallback(async () => {
    if (partnerWinnerCustomersError || fetchMoreWinnerCustomersError) {
      // Don't refetch when an error occured
      return;
    }

    try {
      await fetchMoreWinnerCustomers({
        variables: {
          offset: partnerWinnerCustomersData.partnerWinnerCustomers.length,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          setHasMoreWinnerCustomers(
            fetchMoreResult.partnerWinnerCustomers.length === LIMIT
          );
          return {
            ...prev,
            partnerWinnerCustomers: [
              ...prev.partnerWinnerCustomers,
              ...fetchMoreResult.partnerWinnerCustomers,
            ],
          };
        },
      });
    } catch (e) {
      // Although fetchMore is the way to go for pagination,
      // it provides no easy to use mechanism for error handling.
      // You have to catch the error yourself and set an error state.
      setFetchMoreWinnerCustomersError(e);
    }
  }, [
    fetchMoreWinnerCustomers,
    fetchMoreWinnerCustomersError,
    partnerWinnerCustomersData,
    partnerWinnerCustomersError,
  ]);

  const handleFetchMoreCommissionInvoices = useCallback(async () => {
    if (partnerCommissionInvoicesError || fetchMoreCommissionInvoicesError) {
      // Don't refetch when an error occured
      return;
    }

    try {
      await fetchMoreCommissionInvoices({
        variables: {
          offset:
            partnerCommissionInvoicesData.partnerCommissionInvoices.length,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          setHasMoreCommissionInvoices(
            fetchMoreResult.partnerCommissionInvoices.length === LIMIT
          );
          return {
            ...prev,
            partnerCommissionInvoices: [
              ...prev.partnerCommissionInvoices,
              ...fetchMoreResult.partnerCommissionInvoices,
            ],
          };
        },
      });
    } catch (e) {
      // Although fetchMore is the way to go for pagination,
      // it provides no easy to use mechanism for error handling.
      // You have to catch the error yourself and set an error state.
      setFetchMoreCommissionInvoicesError(e);
    }
  }, [
    fetchMoreCommissionInvoices,
    fetchMoreCommissionInvoicesError,
    partnerCommissionInvoicesData,
    partnerCommissionInvoicesError,
  ]);

  const contextValue = useMemo(
    () => ({
      partnerOrganization:
        partnerOrganizationdata?.organization ||
        partnerOrganizationdata?.partnerOrganization,
      partnerProgram: partnerProgramdata?.partnerProgram,
      partnerOrganizationError,
      partnerCommissionInvoices:
        partnerCommissionInvoicesData?.partnerCommissionInvoices,
      partnerProgramLoading:
        partnerProgramLoading || partnerOrganizationLoading,
      partnerProgramError,
      partnerCommissionInvoicesError:
        partnerCommissionInvoicesError || fetchMoreCommissionInvoicesError,
      partnerCommissionInvoicesLoading,
      partnerCustomersCount: partnerCustomersCountData,
      partnerCustomersCountLoading,
      partnerCustomersCountError,
      partnerReferredCustomers:
        partnerReferredCustomersData?.partnerReferredCustomers,
      partnerReferredCustomersError:
        partnerReferredCustomersError || fetchMoreReferredCustomersError,
      partnerReferredCustomersLoading,
      partnerQualifierCustomers:
        partnerQualifierCustomersData?.partnerQualifierCustomers,
      partnerQualifierCustomersError:
        partnerQualifierCustomersError || fetchMoreQualifierCustomersError,
      partnerQualifierCustomersLoading,
      partnerWinnerCustomers:
        partnerWinnerCustomersData?.partnerWinnerCustomers,
      partnerWinnerCustomersError:
        partnerWinnerCustomersError || fetchMoreWinnerCustomersError,
      partnerWinnerCustomersLoading,
      fetchMoreReferredCustomers: handleFetchMoreReferredCustomers,
      hasMoreReferredCustomers,
      fetchMoreQualifierCustomers: handleFetchMoreQualifierCustomers,
      hasMoreQualifierCustomers,
      fetchMoreWinnerCustomers: handleFetchMoreWinnerCustomers,
      hasMoreWinnerCustomers,
      fetchMoreCommissionInvoices: handleFetchMoreCommissionInvoices,
      hasMoreCommissionInvoices,
      winnerTotalCommission: partnerWinnerCustomersData?.winnerTotalCommission,
      partnerCategories,
      selectedCategory,
      setSelectedCategory,
    }),
    [
      partnerOrganizationdata,
      partnerProgramdata,
      partnerProgramLoading,
      partnerCommissionInvoicesData,
      partnerProgramError,
      partnerOrganizationError,
      partnerOrganizationLoading,
      partnerCommissionInvoicesLoading,
      partnerCommissionInvoicesError,
      partnerCustomersCountData,
      partnerCustomersCountError,
      partnerCustomersCountLoading,
      partnerReferredCustomersData,
      partnerReferredCustomersError,
      partnerReferredCustomersLoading,
      partnerQualifierCustomersData,
      partnerQualifierCustomersLoading,
      partnerQualifierCustomersError,
      partnerWinnerCustomersData,
      partnerWinnerCustomersLoading,
      partnerWinnerCustomersError,
      handleFetchMoreReferredCustomers,
      fetchMoreReferredCustomersError,
      hasMoreReferredCustomers,
      handleFetchMoreQualifierCustomers,
      fetchMoreQualifierCustomersError,
      hasMoreQualifierCustomers,
      handleFetchMoreWinnerCustomers,
      fetchMoreWinnerCustomersError,
      hasMoreWinnerCustomers,
      handleFetchMoreCommissionInvoices,
      fetchMoreCommissionInvoicesError,
      hasMoreCommissionInvoices,
      selectedCategory,
      setSelectedCategory,
    ]
  );

  return (
    <PartnerContext.Provider value={contextValue}>
      {children}
    </PartnerContext.Provider>
  );
};

export default PartnerProvider;
