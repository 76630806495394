import { useState, useMemo, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useMediaSize, capitalize } from '../../../../common';
import { Button } from '../../../../common/oldButton';
import { CustomChannels } from './customChannels';
import { TunifyChannels } from './tunifyChannels';
import { useTunifyBlue } from './tunifyBlueProvider';
import { ReactComponent as Trash } from '../../../../assets/icons/delete.svg';
import { ReactComponent as Edit } from '../../../../assets/icons/edit.svg';
import { GroupCollection } from './groupCollection';
import { CategoryTypes } from './types';

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0.2rem;
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
  background-color: ${({ theme }) => theme.white};

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    grid-template-columns: 18rem 1fr;
  }
`;

const ListContainerWrapper = styled.div`
  display: grid;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    border-right: 0.1rem solid ${({ theme }) => theme.dark200};
  }
`;

const ListContainer = styled.div`
  max-height: calc(100vh - 22rem);
  height: min-content;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    max-height: calc(100vh - 19.5rem);
  }
`;

const InfoWrapper = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  padding: 0.7rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    padding: 2rem 1rem 1rem 1rem;
  }
`;

const SearchInfo = styled.span`
  color: ${({ theme }) => theme.dark300};
  font-size: 0.9rem;
  padding-bottom: 0.5rem;
`;

const MainHeader = styled.div`
  margin: 0;
  display: grid;
  grid-template-columns: 1fr min-content min-content;
  gap: 0.5rem;
  align-items: center;
  padding: 1rem 1.2rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    margin: 1rem 1.2rem 0.5rem 1rem;
    padding: 0;
  }
`;

const StyledButton = styled(Button)`
  padding: 0;
  margin: 0;

  & svg:hover {
    path {
      fill: ${({ theme }) => theme.waterBlue};
    }
  }
`;

const Main = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: min-content 1fr;
`;

const MainTitle = styled.h3`
  font-size: 1.1rem;
  font-weight: 800;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const MainBody = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.stroke};
  padding: 1rem;
`;

const Spacing = styled.div`
  height: 1.5rem;
`;

const TunifyBlue = () => {
  const { t } = useTranslation();

  const { selectedChannel, selectedChannelType } = useTunifyBlue();
  const { isNarrowerThanLarge } = useMediaSize();

  const [openedCollapsible, setOpenedCollapsible] = useState('tunify');

  const groupedMusicCollection = useMemo(() => {
    return (
      selectedChannel?.musicCollections.reduce(
        (resultArray, collection, index) => {
          const groupIndex = Math.floor(index / 4);
          resultArray[groupIndex] = [].concat(
            resultArray[groupIndex] || [],
            collection
          );
          return resultArray; // Each 4 items get grouped in an array "[[a, b], [c, d], ...]"
        },
        []
      ) ?? []
    );
  }, [selectedChannel?.musicCollections]);

  return (
    <Container>
      {!isNarrowerThanLarge || !selectedChannel?.id ? (
        <ListContainerWrapper>
          <ListContainer>
            <CustomChannels
              name={t('musicManagement:tunifyBlue.myChannels')}
              openedCollapsible={openedCollapsible}
              setOpenedCollapsible={setOpenedCollapsible}
            />
            <TunifyChannels
              name={t('musicManagement:tunifyBlue.tunifyChannels')}
              openedCollapsible={openedCollapsible}
              setOpenedCollapsible={setOpenedCollapsible}
            />
          </ListContainer>
        </ListContainerWrapper>
      ) : null}
      {!isNarrowerThanLarge || selectedChannel?.id ? (
        <>
          {selectedChannel ? (
            <Main>
              <MainHeader>
                <MainTitle>{capitalize(selectedChannel.name)}</MainTitle>
                {selectedChannelType === CategoryTypes.CUSTOM ? (
                  <>
                    <StyledButton flavor="icon">
                      <Edit height="1rem" width="2rem" />
                    </StyledButton>
                    <StyledButton flavor="icon">
                      <Trash height="1rem" width="2rem" />
                    </StyledButton>
                  </>
                ) : null}
              </MainHeader>
              <MainBody>
                {selectedChannel &&
                  groupedMusicCollection?.map((musicCollectionGroup, index) => (
                    <Fragment key={index}>
                      <GroupCollection
                        musicCollectionGroup={musicCollectionGroup}
                        selectedChannelType={selectedChannelType}
                      />
                      <Spacing />
                    </Fragment>
                  ))}
              </MainBody>
            </Main>
          ) : (
            <InfoWrapper>
              <SearchInfo>
                {t('musicManagement:tunifyBlue.channels.info1')}
              </SearchInfo>
              <SearchInfo>
                {t('musicManagement:tunifyBlue.channels.info2')}
              </SearchInfo>
              <SearchInfo>
                {t('musicManagement:tunifyBlue.channels.info3')}
              </SearchInfo>
            </InfoWrapper>
          )}
        </>
      ) : null}
    </Container>
  );
};

export default TunifyBlue;
