import { useMemo } from 'react';
import styled from 'styled-components';

import PageHeader from './pageHeader';
import { usePartner } from './partnerContext';
import CommissionTab from './commission';
import { PartnerReferredCustomersPage } from './referredCustomers';
import { PartnerRegistrationsPage } from './registrations';

const Container = styled.div`
  height: calc(100vh - 7rem);
  display: grid;
  grid-template-rows: min-content 1fr;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.small}px) {
    height: 100vh;
  }
`;

const Overview = () => {
  const { selectedCategory } = usePartner();

  const renderTabContent = useMemo(() => {
    switch (selectedCategory.id) {
      case 'commission':
        return <CommissionTab />;
      case 'registrations':
        return <PartnerRegistrationsPage />;
      case 'referredCustomers':
        return <PartnerReferredCustomersPage />;
      default:
        throw new Error(`Unknown partner category: ${selectedCategory.id}`);
    }
  }, [selectedCategory.id]);

  return (
    <Container>
      <PageHeader />
      {renderTabContent}
    </Container>
  );
};

export default Overview;
