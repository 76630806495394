import { useMemo } from 'react';

const useSortByObjectField = (lists, sortBy) =>
  useMemo(
    () =>
      lists?.slice()?.sort((a, b) => {
        if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) {
          return -1;
        }
        if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) {
          return 1;
        }
        return 0;
      }),
    [lists, sortBy]
  );

export default useSortByObjectField;
