import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useSessionStorage } from '../../../common';
import {
  PARTNER_MANAGER_ROLE_PERMISSIONS,
  SUPPORT_ADMIN_ROLE_PERMISSIONS,
  hasAllRequiredPermissions,
} from '../../../global/auth';
import { useAuth } from '../../../global/auth/newAuthProvider';
import { useAddonsBuy } from './addonsBuyContext';
import {
  AdditionalZonesStep,
  AddonCatalogueStep,
  AddonSelectionStep,
  ConfirmWithoutPaymentStep,
  PaymentStep,
} from './steps';

export const usePurchaseSteps = () => {
  const { organizationId, locationId, zoneId, currentStep } = useParams();
  const {
    availableAdditionalZones,
    availableZonesError,
    availableZonesLoading,
    selectedAddons,
    selectedAddonCatalogue,
    selectedAdditionalZones,
  } = useAddonsBuy();
  const navigate = useNavigate();
  const { user: userInfo } = useAuth();

  const { t } = useTranslation();

  const [impersonationId] = useSessionStorage('impersonationId');
  const isSupportAdmin = hasAllRequiredPermissions(
    userInfo,
    SUPPORT_ADMIN_ROLE_PERMISSIONS
  );
  const isPartnerManager = hasAllRequiredPermissions(
    userInfo,
    PARTNER_MANAGER_ROLE_PERMISSIONS
  );

  const goToNextStep = useCallback(() => {
    // The currentStep variable of useParams is still outdated in this callback.
    // Need to make use of the window location pathname to get the real, current step.
    const [realCurrentStep] = window.location.pathname.split('/').slice(-1);
    navigate(
      `/organizations/${organizationId}/locations/${locationId}/zones/${zoneId}/purchase/add-ons/${
        Number(realCurrentStep) + 1
      }`,
      { state: { from: Number(realCurrentStep) } }
    );
  }, [navigate, organizationId, locationId, zoneId]);

  // These states can be updated via callbacks in the current step component
  const [canProceedToNextStep, setCanProceedToNextStep] = useState(true);
  const [goToNextStepAction, setGoToNextStepAction] = useState(
    () => goToNextStep
  );
  const [goToNextStepLoading, setGoToNextStepLoading] = useState(false);
  const [goToNextStepError, setGoToNextStepError] = useState(null);

  const goToPreviousStep = useCallback(() => {
    if (Number(currentStep) === 0) {
      navigate('/add-ons');
    } else {
      navigate(
        `/organizations/${organizationId}/locations/${locationId}/zones/${zoneId}/purchase/add-ons/${
          Number(currentStep) - 1
        }`,
        { state: { from: Number(currentStep) } }
      );
    }
  }, [navigate, currentStep, organizationId, locationId, zoneId]);

  const stepsInfo = useMemo(() => {
    const purchaseSteps = [];

    if ((isSupportAdmin || isPartnerManager) && impersonationId) {
      purchaseSteps.push({
        title: t('addons:buyStep0Title'),
        shortTitle: t('addons:buyStep0Title'),
        description: t('addons:buyStep0Description'),
        selection: selectedAddonCatalogue?.name,
        Component: (
          <AddonCatalogueStep
            setGoToNextStepError={setGoToNextStepError}
            setGoToNextStepLoading={setGoToNextStepLoading}
          />
        ),
      });
    }

    purchaseSteps.push({
      title: t('addons:addonSelectionStep:title'),
      shortTitle: t('addons:addonSelectionStep:title'),
      description: t('addons:addonSelectionStep:description'),
      selection: selectedAddons?.map((addon) => addon?.shortName)?.join(', '),
      Component: (
        <AddonSelectionStep
          key="addonSelectionStep"
          setCanProceedToNextStep={setCanProceedToNextStep}
        />
      ),
    });
    // Only add this step if there are additional zones to buy an addon for
    if (
      availableAdditionalZones.length > 0 ||
      availableZonesLoading ||
      availableZonesError
    ) {
      purchaseSteps.push({
        title: t('addons:buyStep4Title'),
        shortTitle: t('addons:buyStep4Title'),
        description: t('addons:buyStep4Description'),
        selection:
          selectedAdditionalZones.length > 0
            ? t('addons:buyStep4TitleSelection', {
                count: selectedAdditionalZones.length,
              })
            : undefined,
        Component: <AdditionalZonesStep key="additionalSubscriptionStep" />,
      });
    }

    if ((isSupportAdmin || isPartnerManager) && impersonationId) {
      purchaseSteps.push({
        title: t('subscriptions:buyLastStepTitle'),
        shortTitle: t('subscriptions:buyLastStepTitle'),
        Component: (
          <ConfirmWithoutPaymentStep
            key="confirmWithoutPaymentStep"
            goToNextStep={goToNextStep}
            setCanProceedToNextStep={setCanProceedToNextStep}
            setGoToNextStepAction={setGoToNextStepAction}
            setGoToNextStepError={setGoToNextStepError}
            setGoToNextStepLoading={setGoToNextStepLoading}
          />
        ),
      });
    } else {
      purchaseSteps.push({
        title: t('subscriptions:buyLastStepTitle'),
        shortTitle: t('subscriptions:buyLastStepTitle'),
        Component: (
          <PaymentStep
            key="paymentStep"
            goToNextStep={goToNextStep}
            setCanProceedToNextStep={setCanProceedToNextStep}
            setGoToNextStepAction={setGoToNextStepAction}
            setGoToNextStepError={setGoToNextStepError}
            setGoToNextStepLoading={setGoToNextStepLoading}
          />
        ),
      });
    }

    return purchaseSteps;
  }, [
    isSupportAdmin,
    isPartnerManager,
    impersonationId,
    t,
    selectedAddons,
    availableAdditionalZones.length,
    availableZonesLoading,
    availableZonesError,
    selectedAddonCatalogue?.name,
    selectedAdditionalZones.length,
    goToNextStep,
  ]);

  const values = useMemo(
    () => ({
      goToNextStepError,
      canProceedToNextStep,
      goToNextStepAction,
      goToPreviousStep,
      currentStep: Number(currentStep),
      goToNextStepLoading,
      hasPreviousStep: Number(currentStep) > 0,
      hasNextStep: Number(currentStep) < stepsInfo.length - 1,
      stepsInfo,
    }),
    [
      canProceedToNextStep,
      currentStep,
      goToNextStepAction,
      goToNextStepError,
      goToNextStepLoading,
      goToPreviousStep,
      stepsInfo,
    ]
  );

  return values;
};
