import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import SelectButton from './selectButton';
import Label from '../common/label';
import ErrorMessage from '../common/errorMessage';

const Wrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;

const Grid = styled.div`
  display: grid;
  gap: 0.8rem;
  grid-template-columns: repeat(7, 4.8rem);
  padding-top: 0.2rem;
`;

const DaysField = ({ label, required, error, onChange, value }) => {
  const { t } = useTranslation();

  const handleChange = useCallback(
    (id) => {
      onChange({ ...value, [id]: !value[id] });
    },
    [onChange, value]
  );

  return (
    <Wrapper>
      {label ? <Label required={required}>{label}</Label> : null}
      <Grid>
        <SelectButton id={1} isActive={value[1]} onClick={handleChange}>
          <span>{t('glossary:day1').slice(0, 2).toLowerCase()}</span>
        </SelectButton>
        <SelectButton id={2} isActive={value[2]} onClick={handleChange}>
          <span>{t('glossary:day2').slice(0, 2).toLowerCase()}</span>
        </SelectButton>
        <SelectButton id={3} isActive={value[3]} onClick={handleChange}>
          <span>{t('glossary:day3').slice(0, 2).toLowerCase()}</span>
        </SelectButton>
        <SelectButton id={4} isActive={value[4]} onClick={handleChange}>
          <span>{t('glossary:day4').slice(0, 2).toLowerCase()}</span>
        </SelectButton>
        <SelectButton id={5} isActive={value[5]} onClick={handleChange}>
          <span>{t('glossary:day5').slice(0, 2).toLowerCase()}</span>
        </SelectButton>
        <SelectButton id={6} isActive={value[6]} onClick={handleChange}>
          <span>{t('glossary:day6').slice(0, 2).toLowerCase()}</span>
        </SelectButton>
        <SelectButton id={7} isActive={value[7]} onClick={handleChange}>
          <span>{t('glossary:day7').slice(0, 2).toLowerCase()}</span>
        </SelectButton>
      </Grid>
      {typeof error === 'string' ? <ErrorMessage>{error}</ErrorMessage> : null}
    </Wrapper>
  );
};

export default DaysField;
