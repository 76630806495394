import styled, { css } from 'styled-components';

import Label from '../common/label';
import ErrorMessage from '../common/errorMessage';

const InputField = styled.input`
  width: 100%;
  height: 4.8rem;
  outline: none;
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.stroke};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  line-height: 1.2;
  padding: 0 1.6rem;
  color: ${({ theme }) => theme.dark500};
  background-color: transparent;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out,
    color 0.2s ease-in-out;

  background: ${({ theme }) =>
    `linear-gradient(0deg, ${theme.gradient['fade'][0]} 0%,  ${theme.gradient['fade'][1]} 100%)`};

  &:hover {
    border-color: ${({ theme }) => theme.dark300};
  }

  &:active,
  :focus {
    border-color: ${({ theme }) => theme.waterBlue};
  }

  &::placeholder {
    transition: color 0.2s ease-in-out;
    color: ${({ theme }) => theme.dark300};
  }

  /* Color Safari password icon */
  ::-webkit-credentials-auto-fill-button {
    background-color: ${({ theme }) => theme.waterBlue};
  }

  ${({ disabled, isSafari }) =>
    disabled &&
    css`
      cursor: default;
      color: ${({ theme }) => (isSafari ? theme.dark400 : theme.dark100)};
      border-color: ${({ theme }) => theme.stroke};

      &:hover {
        border-color: ${({ theme }) => theme.stroke};
      }

      &::placeholder {
        color: ${({ theme }) => theme.dark100};
      }
    `}

  ${({ error }) =>
    error &&
    css`
      background-color: ${({ theme }) => `${theme.grapefruitRed}29`};
      border-color: ${({ theme }) => theme.grapefruitRed};
      color: ${({ theme }) => theme.grapefruitRed};

      &:active,
      :focus {
        border-color: ${({ theme }) => theme.grapefruitRed};
        color: ${({ theme }) => theme.grapefruitRed};
      }
    `}
`;

const isSafari =
  /constructor/i.test(window.HTMLElement) ||
  (function (p) {
    return p.toString() === '[object SafariRemoteNotification]';
  })(
    !window['safari'] ||
      // eslint-disable-next-line no-undef
      (typeof safari !== 'undefined' && safari.pushNotification)
  );

const StringField = ({
  className,
  disabled,
  error,
  label,
  required,
  name,
  type = 'text',
  onContainerClick,
  register = () => {},
  ...rest
}) => {
  return (
    <div className={className} onClick={onContainerClick}>
      {label ? (
        <Label htmlFor={name} disabled={disabled} required={required}>
          {label}
        </Label>
      ) : null}
      <InputField
        id={name}
        name={name}
        error={error}
        type={type}
        disabled={disabled}
        isSafari={isSafari}
        {...register(name)}
        {...rest}
      />
      {typeof error === 'string' ? <ErrorMessage>{error}</ErrorMessage> : null}
    </div>
  );
};

export default StringField;
