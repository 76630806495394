import { useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ErrorMessage, LoadingIndicator } from '../../../../../common';
import { Button } from '../../../../../common/oldButton';
import StringField from '../../../../../common/oldFields/stringField';
import { Dropdown } from '../../../../../common/oldFields/dropdownField';

const StyledDropdown = styled(Dropdown)`
  margin: 0;
  margin-bottom: 1.5rem;

  & > div > div {
    max-height: 8rem;
  }
`;

const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;

  & > :not(:first-child) {
    margin-left: 1rem;
  }
`;

const ConfigureStep = ({
  onNext,
  onClose,
  // values
  name,
  regionId,
  categoryId,
  industryId,
  // setters
  setName,
  setRegionId,
  setCategoryId,
  setIndustryId,
  // loading states
  loadingRegions,
  loadingMusicLicenses,
  loadingIndustries,
  // options
  regions,
  musicLicenses,
  industries,
  error,
}) => {
  const { t } = useTranslation();

  const handleChange = useCallback((event) => setName(event.currentTarget?.value), [setName]);
  const handleChangeCategory = useCallback((id) => setCategoryId(id), [setCategoryId]);
  const handleChangeRegion = useCallback((id) => setRegionId(id), [setRegionId]);
  const handleChangeIndustry = useCallback((id) => setIndustryId(id), [setIndustryId]);

  return (
    <>
      <p>{t('musicManagement:configureMusicProfile.info')}</p>
      {error ? <ErrorMessage error={error} /> : null}
      {loadingMusicLicenses || loadingRegions || loadingIndustries ? (
        <LoadingIndicator />
      ) : (
        <>
          <StringField
            autoFocus
            label={t('common:form.label.name')}
            name="name"
            onChange={handleChange}
            placeholder={t('common:form.label.name')}
            value={name}
          />
          <StyledDropdown
            currentOptionId={categoryId}
            isLoading={loadingMusicLicenses}
            label={t('musicManagement:configureMusicProfile.category')}
            name="category"
            onChange={handleChangeCategory}
            options={musicLicenses}
            placeholder={t('common:dropdown.selectX', {
              label: t('musicManagement:configureMusicProfile.category').toLowerCase(),
            })}
          />
          <StyledDropdown
            currentOptionId={regionId}
            isLoading={loadingRegions}
            label={t('musicManagement:addMusicProfile.region')}
            name="region"
            onChange={handleChangeRegion}
            options={regions}
            placeholder={t('common:dropdown.selectX', {
              label: t('musicManagement:addMusicProfile.region').toLowerCase(),
            })}
          />
          <p>{t('musicManagement:addMusicProfile.detailsInfo')}</p>
          <StyledDropdown
            currentOptionId={industryId}
            isLoading={loadingIndustries}
            label={t('musicManagement:configureMusicProfile.community')}
            name="community"
            onChange={handleChangeIndustry}
            options={industries}
            placeholder={t('common:dropdown.selectX', {
              label: t('musicManagement:configureMusicProfile.community').toLowerCase(),
            })}
          />
        </>
      )}
      <Footer>
        <Button emphasis="secondary" flavor="link" onClick={onClose} type="button">
          {t('common:button.notNow')}
        </Button>
        <Button emphasis="secondary" flavor="flat" onClick={onNext} title={t('common:button.title.save')} type="button">
          {t('common:button.next')}
        </Button>
      </Footer>
    </>
  );
};

export default ConfigureStep;
