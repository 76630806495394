import { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LogsHistoryLarge } from '../../../assets/icons/logsHistoryLarge.svg';
import theme from '../../../global/style/theme';
import { Button, useSessionStorage } from '../../../common';
import { useCustomerSuccessHistory } from './historyContext';

const Container = styled.div`
  display: grid;
  row-gap: 2.4rem;
  grid-template-columns: 1fr;
  background-color: ${({ theme }) => theme.white};
  padding: ${({ useFilter }) => (useFilter ? '1.5rem 1.5rem 0' : '1.5rem')};
  border-bottom: 1px solid ${({ theme }) => theme.stroke};

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    padding: ${({ useFilter }) => (useFilter ? '2.2rem 3rem 0rem 3rem' : '2.2rem 3rem 2.2rem 3rem')};
    row-gap: 3rem;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    padding: ${({ useFilter }) => (useFilter ? '2.6rem 4rem 0rem 4rem' : '2.6rem 4rem 2.6rem 4rem')};
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.extraLarge}px) {
    padding: ${({ useFilter }) => (useFilter ? ' 3rem 4.8rem 0rem 4.8rem' : ' 3rem 4.8rem 3rem 4.8rem')};
  }
`;

const TitleContainer = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  gap: 1.6rem;
  grid-column: span 2;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    grid-column: unset;
  }
`;

const Logo = styled.div`
  width: 4rem;
  height: 4rem;
  display: grid;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    135deg,
    ${({ theme }) => theme.gradient['sunset'][0]} 0%,
    ${({ theme }) => theme.gradient['sunset'][1]} 100%
  );
  border-radius: 4px;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    width: 4.8rem;
    height: 4.8rem;
  }
`;

const StyledLogsHistoryLarge = styled(LogsHistoryLarge)`
  width: 2.6rem;
  height: 2.6rem;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    width: 3.2rem;
    height: 3.2rem;
  }
`;

const Title = styled.h1`
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    font-size: 2.8rem;
  }
`;

const Bottom = styled.div`
  display: grid;
  gap: 3rem;
  grid-template-columns: repeat(2, min-content);
  overflow-x: auto;
`;

const TabButton = styled(Button)`
  white-space: nowrap;
  padding-bottom: 1rem;
  border-width: 4px;
  color: ${({ theme }) => theme.dark500};
  font-weight: 600;

  ${({ selected }) =>
    selected
      ? css``
      : css`
          border: none;
          padding-bottom: 1.4rem;
        `}
`;

const PageHeader = () => {
  const { t } = useTranslation();
  const [impersonationId] = useSessionStorage('impersonationId');
  const { selectedHistoryCategory, setSelectedHistoryCategory, useFilter } = useCustomerSuccessHistory();

  const handleSelect = useCallback(
    (category) => {
      setSelectedHistoryCategory(category);
    },
    [setSelectedHistoryCategory]
  );

  return (
    <Container useFilter={useFilter}>
      <TitleContainer>
        <Logo>
          <StyledLogsHistoryLarge color={theme.white} />
        </Logo>
        <Title>{t('customerSuccess:history.title')}</Title>
      </TitleContainer>
      {impersonationId && (
        <Bottom>
          <TabButton
            emphasis="secondary"
            selected={selectedHistoryCategory === 'contact'}
            onClick={() => handleSelect('contact')}
          >
            {t('customerSuccess:history.contact')}
          </TabButton>
          <TabButton
            emphasis="secondary"
            selected={selectedHistoryCategory === 'subscription'}
            onClick={() => handleSelect('subscription')}
          >
            {t('customerSuccess:history.subscriptions')}
          </TabButton>
        </Bottom>
      )}
    </Container>
  );
};

export default PageHeader;
