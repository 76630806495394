import { Route } from 'react-router';
import {
  CustomerSuccessHistoryPage,
  CustomerSuccessScheduledCallsPage,
  CustomerSuccessSearchPage,
} from '../../../modules/customerSuccess';

export const CustomerSuccessRouter = [
  <Route path="search" element={<CustomerSuccessSearchPage />} />,
  <Route path="history" element={<CustomerSuccessHistoryPage />} />,
  <Route
    path="scheduled-calls"
    element={<CustomerSuccessScheduledCallsPage />}
  />,
];
