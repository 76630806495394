import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
  formatDate,
  determineAvailableAddonCategoriesForZone,
  formatNumber,
  CheckboxCard,
} from '../../../../../common';
import { useAddonsBuy } from '../../addonsBuyContext';
import { calculatePriceForZone } from '../../utils';

const Container = styled.div`
  display: grid;
  gap: 0.6rem;
`;

const SubLabel = styled.div`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.dark300};
  line-height: 1.4;
`;

const Label = styled.h3`
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & > span {
    color: ${({ theme }) => theme.dark300};
    font-weight: 500;
  }
`;

const SideInfo = styled.span`
  font-size: 1.2rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.dark300};
  padding-top: 0.8rem;

  & > span {
    color: ${({ theme }) => theme.dark500};
    font-weight: 600;
  }
`;

const ZoneCard = ({ zone, handleSelectZone, isSelected }) => {
  const { t, i18n } = useTranslation();
  const { selectedAddons, availableAddons } = useAddonsBuy();

  const onSelectZone = useCallback(() => {
    handleSelectZone(zone);
  }, [zone, handleSelectZone]);

  const availableAddonCategoriesOfZone = useMemo(
    () =>
      determineAvailableAddonCategoriesForZone(zone, selectedAddons)
        .map((addon) => addon.shortName)
        .join(', '),
    [zone, selectedAddons]
  );

  const price = useMemo(
    () => calculatePriceForZone(zone, selectedAddons, availableAddons),
    [zone, selectedAddons, availableAddons]
  );

  if (price === null) {
    // At this point, the price could not be calculated, because the add-on is unavailable for this zone.
    return null;
  }

  return (
    <CheckboxCard
      isSelected={isSelected}
      onClick={onSelectZone}
      title={
        <Label>
          {zone.location.name} <span>| {zone.name}</span>
        </Label>
      }
    >
      <Container>
        <SubLabel>
          {availableAddonCategoriesOfZone} {t('glossary:to').toLowerCase()}{' '}
          {formatDate(zone.currentSubscription.endDate, i18n)}
        </SubLabel>
        <SideInfo>
          <span>€ {formatNumber(price, i18n.language)}</span> {t('glossary:exclusiveVat')}
        </SideInfo>
      </Container>
    </CheckboxCard>
  );
};

export default ZoneCard;
