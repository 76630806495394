import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { addMinutes, formatISO9075 } from 'date-fns';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  ErrorMessage,
  StringField,
  TextField,
  DateField,
  TimeField,
  SwitchField,
  SelectField,
  Modal,
  getBrowserTimeZone,
  parseTimeStringWithDate,
} from '../../../common';
import { useGlobalState } from '../../../global/state';
import {
  GET_LANGUAGES,
  GET_CONTACT_CATEGORIES_AND_CHANNELS,
  GET_COUNTRIES,
  CREATE_LEAD,
} from './api';

const GridForm = styled.form`
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    display: grid;
    grid-column-gap: 2rem;
    grid-template-columns: repeat(2, 1fr min-content) 1fr;
    align-items: flex-start;
  }
`;

const ContactContainer = styled.div`
  display: grid;
  grid-gap: 2rem;
`;

const DetailsContainer = styled.div`
  display: grid;
  grid-gap: 2rem;
`;

const RecallContainer = styled.div`
  display: grid;
  grid-gap: 2rem;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 1rem;
  width: 100%;
`;

const DuoColumn = styled.div`
  display: grid;
  grid-gap: 2.4rem 1.6rem;
  grid-template-columns: repeat(2, 1fr);
`;

const SectionLabel = styled.div`
  color: ${({ theme }) => theme.dark200};
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.6;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  width: fit-content;
  padding-top: 4.2rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    display: none;
  }
`;

const StyledSwitchField = styled(SwitchField)`
  margin-bottom: 1.5rem;
  & > div {
    margin-left: 0;
  }
`;

const Divider = styled.div`
  display: none;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    display: grid;
    width: 2px;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.stroke};
    height: 100%;
  }
`;

const NewLeadForm = ({ isOpen, toggle }) => {
  const { t } = useTranslation();
  const { newLead, setNewLead } = useGlobalState();

  const schema = Yup.object().shape({
    country: Yup.object().nullable(true),
    summary: Yup.string().required(
      t('common:form.validation.summary.required')
    ),
    contactCategoryId: Yup.object()
      .nullable(true)
      .required(t('common:form.validation.contactCategory.required')),
    contactChannelId: Yup.object()
      .nullable(true)
      .required(t('common:form.validation.contactChannel.required')),
    firstName: Yup.string().required(
      t('common:form.validation.customerSuccessFirstName.required')
    ),
    lastName: Yup.string().required(
      t('common:form.validation.customerSuccessLastName.required')
    ),
    phone: Yup.string().required(
      t('common:form.validation.customerSuccessPhone.required')
    ),
    emailAddress: Yup.string()
      .email(t('common:form.validation.emailAddress.type'))
      .required(
        t('common:form.validation.customerSuccessEmailAddress.required')
      ),
    organizationName: Yup.string().required(
      t('common:form.validation.organizationName.required')
    ),
    organizationCity: Yup.string().required(
      t('common:form.validation.city.required')
    ),
    futureContactDate: Yup.string()
      .nullable(true)
      .when('hasFutureContact', {
        is: true,
        then: Yup.string()
          .nullable(true)
          .required(t('common:form.validation.date.required')),
      }),
    futureContactTime: Yup.string()
      .nullable(true)
      .when('hasFutureContact', {
        is: true,
        then: Yup.string()
          .nullable(true)
          .required(t('common:form.validation.hour.required')),
      }),
    futureContactCategoryId: Yup.object()
      .nullable(true)
      .when('hasFutureContact', {
        is: true,
        then: Yup.object()
          .nullable(true)
          .required(t('common:form.validation.contactCategory.required')),
      }),
    callAssignment: Yup.string()
      .nullable(true)
      .when('hasFutureContact', {
        is: true,
        then: Yup.string()
          .nullable(true)
          .required(t('common:form.validation.callAssignment.required')),
      }),
  });

  const {
    loading: contactCategoriesAndChannelsLoading,
    data: contactCategoriesAndChannelsData,
    error: contactCategoriesAndChannelsError,
  } = useQuery(GET_CONTACT_CATEGORIES_AND_CHANNELS, {
    fetchPolicy: 'cache-first',
  });

  const contentCategoryOptions = useMemo(
    () => [
      ...(contactCategoriesAndChannelsData?.contactCategories?.map(
        (category) => ({
          value: category.id,
          label: category.name,
        })
      ) ?? []),
    ],
    [contactCategoriesAndChannelsData?.contactCategories]
  );

  const contentChannelOptions = useMemo(
    () => [
      ...(contactCategoriesAndChannelsData?.contactChannels?.map((channel) => ({
        value: channel.id,
        label: channel.name,
      })) ?? []),
    ],
    [contactCategoriesAndChannelsData?.contactChannels]
  );

  const { loading: languagesLoading, data: languagesData } = useQuery(
    GET_LANGUAGES,
    {
      fetchPolicy: 'cache-first',
    }
  );

  const languageOptions = useMemo(
    () =>
      languagesData?.languages.map((language) => ({
        value: Number(language.id),
        label: language.name,
      })),
    [languagesData]
  );

  // Show no data on error in dropdown
  const { loading: loadingCountries, data: dataCountries } =
    useQuery(GET_COUNTRIES);

  const countryOptions = useMemo(
    () =>
      dataCountries?.countries.map((country) => ({
        value: Number(country.id),
        label: country.name,
      })),
    [dataCountries]
  );

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      country: newLead?.countryId ?? '',
      summary: newLead?.summary ?? '',
      contactCategoryId:
        contentCategoryOptions?.find(
          (option) => option.value === newLead?.contactCategoryId
        ) ?? null,
      contactChannelId:
        contentChannelOptions?.find(
          (option) => option.value === newLead?.contactChannelId
        ) ?? null,
      firstName: newLead?.firstName ?? '',
      lastName: newLead?.lastName ?? '',
      phone: newLead?.phone ?? '',
      emailAddress: newLead?.emailAddress ?? '',
      languageId:
        languageOptions?.find(
          (option) => option.value === newLead?.languageId
        ) ?? '',
      organizationName: newLead?.organizationName ?? '',
      organizationCity: newLead?.organizationCity ?? '',
      hasFutureContact: newLead?.hasFutureContact ?? false,
      futureContactDate: newLead?.futureContactDate ?? null,
      futureContactTime: newLead?.futureContactTime ?? null,
      futureContactCategoryId:
        contentCategoryOptions?.find(
          (option) => option.value === newLead?.futureContactCategoryId
        ) ?? null,
      callAssignment: newLead?.callAssignment ?? '',
    },
  });

  const hasFutureContact = watch('hasFutureContact');

  const [createLead, { loading: requestLoading, error: requestError }] =
    useMutation(CREATE_LEAD, {
      onCompleted: () => {
        setNewLead({});
        toggle();
      },
    });

  const onConfirm = useCallback(
    (values) => {
      let deadlineDate = null;
      if (hasFutureContact) {
        const futureDate =
          typeof values.futureContactDate === 'string'
            ? new Date(values.futureContactDate)
            : values.futureContactDate;

        const date = parseTimeStringWithDate(
          values.futureContactTime.replace(':', ''),
          futureDate
        );
        deadlineDate = formatISO9075(
          addMinutes(date, date.getTimezoneOffset())
        );
      }

      createLead({
        variables: {
          lead: {
            countryId: Number(values.country?.value),
            summary: values.summary,
            contactCategoryId: Number(values.contactCategoryId?.value),
            contactChannelId: Number(values.contactChannelId?.value),
            contactPersonFirstName: values.firstName,
            contactPersonLastName: values.lastName,
            contactPersonPhone: values.phone,
            contactPersonEmailAddress: values.emailAddress,
            contactOrganizationName: values.organizationName,
            contactOrganizationCity: values.organizationCity,
            contactLangId: Number(values.languageId?.value),
            deadlineDate,
            callAssignment: values.callAssignment,
            futureContactCategoryId: Number(
              values.futureContactCategoryId?.value
            ),
            timeZone: getBrowserTimeZone(),
          },
        },
      });
    },
    [createLead, hasFutureContact]
  );

  const handleClose = useCallback(() => {
    setNewLead({});
    toggle();
  }, [toggle, setNewLead]);

  const changeHasFutureContact = useCallback(
    (isChecked) => {
      setValue('hasFutureContact', isChecked);
    },
    [setValue]
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={t('customerSuccess:lead.title')}
      isWide
      requestLoading={requestLoading}
      onConfirm={handleSubmit(onConfirm)}
    >
      {/* No `onSubmit` necessary, because form does not contain a submit button. */}
      <GridForm>
        <ContactContainer>
          <SelectField
            control={control}
            error={errors?.country?.message}
            isLoading={loadingCountries}
            label={t('common:form.label.country')}
            name="country"
            options={countryOptions}
            placeholder={t('common:form.placeholder.country')}
          />
          <TextField
            control={control}
            error={errors?.summary?.message}
            label={t('common:form.label.summary')}
            name="summary"
            rows={4}
            placeholder={t('common:form.placeholder.logContactSummary')}
          />
          <DuoColumn>
            <SelectField
              control={control}
              error={errors?.contactCategoryId?.message}
              isLoading={contactCategoriesAndChannelsLoading}
              label={t('common:form.label.contactCategory')}
              name="contactCategoryId"
              options={contentCategoryOptions}
              placeholder={t('common:form.placeholder.contactCategory')}
            />
            <SelectField
              control={control}
              error={errors?.contactChannelId?.message}
              isLoading={contactCategoriesAndChannelsLoading}
              label={t('common:form.label.contactChannel')}
              name="contactChannelId"
              options={contentChannelOptions}
              placeholder={t('common:form.placeholder.contactChannel')}
            />
          </DuoColumn>
        </ContactContainer>
        <Divider />
        <DetailsContainer>
          <SectionLabel>{t('common:form.group.contactDetails')}</SectionLabel>
          <DuoColumn>
            <StringField
              register={register}
              error={errors?.firstName?.message}
              label={t('common:form.label.firstName')}
              name="firstName"
              placeholder={t(
                'common:form.placeholder.customerSuccessFirstName'
              )}
            />
            <StringField
              register={register}
              error={errors?.lastName?.message}
              label={t('common:form.label.lastName')}
              name="lastName"
              placeholder={t('common:form.placeholder.customerSuccessLastName')}
            />
            <StringField
              register={register}
              error={errors?.phone?.message}
              label={t('common:form.label.phone')}
              name="phone"
              placeholder={t('common:form.placeholder.customerSuccessPhone')}
            />
            <StringField
              register={register}
              error={errors?.emailAddress?.message}
              label={t('common:form.label.emailAddress')}
              name="emailAddress"
              placeholder={t(
                'common:form.placeholder.customerSuccessEmailAddress'
              )}
            />
            <StringField
              register={register}
              error={errors?.organizationName?.message}
              label={t('common:form.label.organizationName')}
              name="organizationName"
              placeholder={t(
                'common:form.placeholder.customerSuccessOrganizationName'
              )}
            />
            <StringField
              register={register}
              error={errors?.organizationCity?.message}
              label={t('common:form.label.city')}
              name="organizationCity"
              placeholder={t('common:form.placeholder.customerSuccessCity')}
            />
            <SelectField
              control={control}
              error={errors?.languageId?.message}
              isLoading={languagesLoading}
              label={t('common:form.label.lang')}
              name="languageId"
              options={languageOptions}
              placeholder={t('common:form.placeholder.lang')}
              maxMenuHeight={100}
            />
          </DuoColumn>
        </DetailsContainer>
        <Divider />
        <RecallContainer>
          <SectionLabel>{t('common:form.group.planNewContact')}</SectionLabel>
          <StyledSwitchField
            checked={hasFutureContact}
            label={t('common:form.label.hasFutureContact')}
            onChange={changeHasFutureContact}
          />
          <DuoColumn>
            <DateField
              control={control}
              disabled={!hasFutureContact}
              disablePastDaySelection
              disableWeekendDaySelection
              error={errors?.futureContactDate?.message}
              label={t('common:form.label.date')}
              name="futureContactDate"
              placeholder={t('common:form.placeholder.date')}
            />
            <TimeField
              control={control}
              disabled={!hasFutureContact}
              error={errors?.futureContactTime?.message}
              label={t('common:form.label.hour')}
              name="futureContactTime"
              placeholder={t('common:form.placeholder.hour')}
            />
          </DuoColumn>
          <SelectField
            control={control}
            disabled={!hasFutureContact}
            error={errors?.futureContactCategoryId?.message}
            label={t('common:form.label.contactCategory')}
            name="futureContactCategoryId"
            options={contentCategoryOptions}
            placeholder={t('common:form.placeholder.contactCategory')}
          />
          <TextField
            control={control}
            disabled={!hasFutureContact}
            error={errors?.callAssignment?.message}
            label={t('common:form.label.callAssignment')}
            name="callAssignment"
            rows={4}
            placeholder={t('common:form.placeholder.callAssignment')}
          />
        </RecallContainer>
        {(requestError || contactCategoriesAndChannelsError) && (
          <StyledErrorMessage
            error={requestError || contactCategoriesAndChannelsError}
          />
        )}
      </GridForm>
    </Modal>
  );
};

export default NewLeadForm;
