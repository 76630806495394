import { useMutation } from '@apollo/react-hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import qs from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';

import { ReactComponent as LogoBlack } from '../../../assets/icons/logoDark.svg';
import {
  Button,
  ErrorMessage,
  VerificationCodeField,
  capitalize,
  extractErrorCodeFromApolloError,
} from '../../../common';
import { SonosFailure, SonosSuccess } from '../../sonos/overview/cards';
import { COUPLE_ZONE_BY_CODE_TO_SONOS } from './api';

const Page = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const Centered = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoginSection = styled.section`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

const StyledTunifyLogo = styled(LogoBlack)`
  width: 100%;
  height: 5rem;
  margin-bottom: 4rem;
  align-self: end;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    margin-bottom: 8rem;
  }
`;

const NotRegisteredNote = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;

  & > p {
    font-size: 1.4rem;
    line-height: 1.4;
    padding-bottom: 0.4rem;
  }
`;

const FormWrapper = styled.div`
  display: grid;
  gap: 2rem;
`;

const ZoneCodeWrapper = styled.div`
  padding: 1.6rem 0 2rem 0;
  background-color: ${({ theme }) => theme.background};
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.stroke};
  margin: 1rem 0 1.5rem 0;
`;

const StyledForm = styled.form`
  width: 100%;
  max-width: 420px;
`;

const CenterInForm = styled.div`
  margin: auto;
  width: fit-content;
`;

const LoginForm = ({
  isLoading,
  requestError,
  onSubmit,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const schema = Yup.object().shape({
    zoneCode: Yup.string(),
  });

  const {
    control,
    setFocus,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { zoneCode: '' },
  });

  const navigateToRegister = useCallback(() => {
    navigate('/register');
  }, [navigate]);
  
  const focusVerificationInput = useCallback(() => {
    setFocus('zoneCode');
  }, [setFocus]);
  
  const openInstructions = useCallback(() => {
    window.open(t('sonos:playerCodeLocation.link'), '_blank');
  }, [t]);

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <FormWrapper>
        {requestError ? <ErrorMessage error={requestError} /> : null}
        <ZoneCodeWrapper onClick={focusVerificationInput}>
          <VerificationCodeField
            label={t('auth:login.playerCode')}
            name="zoneCode"
            setFocus={setFocus}
            control={control}
            inverse
          />
        </ZoneCodeWrapper>
        <CenterInForm>
          {/* This should be an A-tag, but apparently we don't do that in this project. */}
          <Button
            emphasis="secondary"
            size="medium"
            onClick={openInstructions}
            title={t('sonos:playerCodeLocation.titleText')}
          >
            {t('sonos:playerCodeLocation.label')}
          </Button>
        </CenterInForm>
        <Button
          isLoading={isLoading}
          title={t('sonos:button.linkDevice.titleText')}
          type="submit"
        >
          {t('sonos:button.linkDevice.label')}
        </Button>
        <NotRegisteredNote>
          <p>{t('auth:login.notRegisteredNote')}</p>
          <Button
            emphasis="secondary"
            size="medium"
            onClick={navigateToRegister}
            title={capitalize(
              t('common:button.title.goTo', {
                value: t('common:button.title.registration'),
              })
            )}
          >
            {t('common:button.register')}
          </Button>
        </NotRegisteredNote>
      </FormWrapper>
    </StyledForm>
  );
};

const SonosPlayerCodePage = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [requestError, setRequestError] = useState();
  const location = useLocation();
  const navigate = useNavigate();

  const [couplingWasSuccessful, setCouplingWasSuccessful] = useState(false);
  const [couplingHasFailed, setCouplingHasFailed] = useState(false);
  const [couplingErrorMessage, setCouplingErrorMessage] = useState(
    t('integrations:sonos.failure.descriptionWithoutZone')
  );

  const [coupleZoneByCodeToSonos] = useMutation(COUPLE_ZONE_BY_CODE_TO_SONOS, {
    onCompleted: () => {
      setCouplingWasSuccessful(true);
    },
    onError: (error) => {
      const graphQLErrorCode = extractErrorCodeFromApolloError(error);
      if (
        graphQLErrorCode === 'SONOS_CODE_NOT_FOUND' ||
        graphQLErrorCode === 'ZONE_CODE_NOT_FOUND'
      ) {
        setCouplingErrorMessage(t(`error:${graphQLErrorCode}`));
      }
      setCouplingHasFailed(true);
    },
  });

  const queryParams = qs.parseUrl(window.location.href).query;

  useEffect(() => {
    const { state } = location;
    if (state) {
      setRequestError(state.error);
      // Clear history state so on a hard refresh the page will not show the error
      navigate('', { replace: true });
    }
  }, [navigate, location]);

  const handleSubmit = useCallback(
    async (loginCredentials) => {
      setIsLoading(true);
      setRequestError(null);
      try {
        await coupleZoneByCodeToSonos({
          variables: {
            zoneCode: loginCredentials.zoneCode,
            linkCode:
              (queryParams?.linkCode ||
                qs.parseUrl(queryParams?.from)?.query?.linkCode) ??
              null,
          },
        });
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        setRequestError(e);
      }
    },
    [queryParams, coupleZoneByCodeToSonos]
  );

  return couplingHasFailed || couplingWasSuccessful ? (
    <Centered>
      {couplingHasFailed ? (
        <SonosFailure
          errorMessage={couplingErrorMessage}
          setCouplingHasFailed={setCouplingHasFailed}
        />
      ) : null}
      {couplingWasSuccessful ? <SonosSuccess /> : null}
    </Centered>
  ) : (
    <Page>
      <Helmet>
        <title>{t('sonos:auth.title')} | MyTunify</title>
      </Helmet>
      <LoginSection>
        <StyledTunifyLogo />
        <LoginForm
          isLoading={isLoading}
          onSubmit={handleSubmit}
          requestError={requestError}
        />
      </LoginSection>
    </Page>
  );
};

export default SonosPlayerCodePage;
