import { useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { useVirtual } from 'react-virtual';

import {
  Modal,
  ItemMeasurer,
  LoadingIndicator,
} from '../../../../../../common';
import { GET_MUSIC_COLLECTION_AS_PLAYLIST } from '../api';
import { useTunifyBlue } from '../../tunifyBlueProvider';
import { Song } from './song/song';

const ScrollContainer = styled.div`
  height: calc(100vh - 20rem);
  display: grid;
  grid-auto-rows: min-content;
  overflow-y: auto;
  user-select: none;
`;

const VirtualRow = styled.div`
  height: ${({ $totalSize }) => $totalSize}px;
  width: 100%;
  position: relative;
`;

const SongWrapper = styled.div.attrs(
  // This fixes an issue with vscode-styled-components
  (props) => ({
    style: {
      top: `${props.start}px`,
    },
  })
)`
  width: 100%;
  position: absolute;
  left: 0;
`;

const Info = styled.div`
  color: ${({ theme }) => theme.dark300};
  font-size: 0.9rem;
  padding: 1.2rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    padding: 0;
  }
`;

const CollectionSongsModal = ({ isOpen, onClose, title, musicCollection }) => {
  const { t } = useTranslation();

  const { selectedChannel, musicProfile } = useTunifyBlue();

  const parentRef = useRef();

  const { loading, data } = useQuery(GET_MUSIC_COLLECTION_AS_PLAYLIST, {
    variables: {
      musicProfileId: musicProfile.tunifyMusicProfileUserId,
      musicChannelId: selectedChannel.id,
      musicCollectionId: musicCollection.id,
    },
    skip:
      !musicProfile?.tunifyMusicProfileUserId ||
      !selectedChannel?.id ||
      !musicCollection?.id,
  });

  const rowVirtualizer = useVirtual({
    size: data?.musicCollectionAsPlaylist?.audioFiles?.length,
    parentRef,
    overscan: 10,
  });

  const songs = data?.musicCollectionAsPlaylist?.audioFiles ?? [];

  return (
    <Modal footer={false} isOpen={isOpen} onClose={onClose} title={title}>
      <ScrollContainer ref={parentRef}>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <VirtualRow $totalSize={rowVirtualizer.totalSize}>
            {rowVirtualizer.virtualItems.length > 0 ? (
              rowVirtualizer.virtualItems.map((virtualRow) => (
                <ItemMeasurer
                  key={songs[virtualRow.index].id}
                  Component={SongWrapper}
                  measure={virtualRow.measureRef}
                  start={virtualRow.start}
                >
                  <Song
                    id={songs[virtualRow.index].id}
                    index={virtualRow.index}
                    song={songs[virtualRow.index]}
                  />
                </ItemMeasurer>
              ))
            ) : (
              <Info>{t('musicManagement:playlists.noSongs')}</Info>
            )}
          </VirtualRow>
        )}
      </ScrollContainer>
    </Modal>
  );
};

export default CollectionSongsModal;
