import { Navigate } from 'react-router';
import { useAuth } from './newAuthProvider';

export const AuthGuardPermissions = ({ permissions, children }) => {
  const { user } = useAuth();

  if (
    user.permissions.length === 0 ||
    !permissions.every((permission) => user.permissions.includes(permission))
  ) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};
