import { useCallback, useEffect, useState } from 'react';

const useSessionStorage = (key, initialValue) => {
  const [value, setValue] = useState(
    () => window.sessionStorage.getItem(key) || initialValue
  );

  const setItem = (newValue) => {
    setValue(newValue);
    window.sessionStorage.setItem(key, newValue);
    window.dispatchEvent(new Event('storage'));
  };

  useEffect(() => {
    const newValue = window.sessionStorage.getItem(key);
    if (value !== newValue) {
      setValue(newValue || initialValue);
    }
  }, [key, value, initialValue]);

  const handleStorage = useCallback(
    (event) => {
      if (event.key === key && event.newValue !== value) {
        setValue(event.newValue || initialValue);
      }
    },
    [initialValue, key, value]
  );

  useEffect(() => {
    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, [handleStorage]);

  return [value, setItem];
};

export default useSessionStorage;
