import { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useToggle, Button } from '../../../common';
import LocationListTable from './locationListTable';
import FilterMessage from './filterMessage';
import { ReactComponent as AngleDownIcon } from '../../../assets/icons/angleDown.svg';
import { ReactComponent as AngleUpIcon } from '../../../assets/icons/angleUp.svg';
import theme from '../../../global/style/theme';

const Top = styled.div`
  padding: 2rem 1.5rem 0 1.5rem;
  overflow-y: auto;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    padding: 2rem 3rem 0 3rem;
  }
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    padding: 2rem 4rem 0 4rem;
  }
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    padding: 2rem 4.8rem 0 4.8rem;
  }
`;

const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;
  justify-items: end;
  gap: 1.2rem;
  padding-bottom: 1rem;
`;

const NoResults = styled.p`
  color: ${({ theme }) => theme.dark300};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.143;
  text-align: center;
`;

const Table = ({
  organizationWithSubscriptions,
  allProducts,
  searchFilter,
  searchValue,
  resetFilters,
}) => {
  const { t } = useTranslation();

  const [expandAllZones, toggleExpandAllZones] = useToggle(true);

  const filteredLocations = useMemo(() => {
    if (organizationWithSubscriptions?.locations) {
      if (searchFilter === 'all') {
        const locationIds = organizationWithSubscriptions.zones
          .filter((zone) =>
            zone.name.toLowerCase().includes(searchValue.toLowerCase())
          )
          .map((zone) => zone.locationId);
        return organizationWithSubscriptions.locations.filter(
          (location) =>
            location.name.toLowerCase().includes(searchValue.toLowerCase()) ||
            location.city.toLowerCase().includes(searchValue.toLowerCase()) ||
            location.streetName
              .toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            location.streetNumber
              .toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            location.zip.toLowerCase().includes(searchValue.toLowerCase()) ||
            locationIds.includes(parseInt(location.id, 10))
        );
      }
      if (searchFilter === 'location') {
        return organizationWithSubscriptions.locations.filter((location) =>
          location.name.toLowerCase().includes(searchValue.toLowerCase())
        );
      }
      if (searchFilter === 'place') {
        return organizationWithSubscriptions.locations.filter(
          (location) =>
            location.city.toLowerCase().includes(searchValue.toLowerCase()) ||
            location.streetName
              .toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            location.streetNumber
              .toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            location.zip.toLowerCase().includes(searchValue.toLowerCase())
        );
      }
      if (searchFilter === 'zone') {
        const locationIds = organizationWithSubscriptions.zones
          .filter((zone) =>
            zone.name.toLowerCase().includes(searchValue.toLowerCase())
          )
          .map((zone) => zone.locationId);
        return organizationWithSubscriptions.locations.filter((location) =>
          locationIds.includes(parseInt(location.id, 10))
        );
      }
    }
    return organizationWithSubscriptions?.locations;
  }, [
    organizationWithSubscriptions?.locations,
    organizationWithSubscriptions?.zones,
    searchFilter,
    searchValue,
  ]);

  const filteredZones = useMemo(() => {
    if (organizationWithSubscriptions?.zones) {
      if (searchFilter === 'all') {
        const locationIds = organizationWithSubscriptions.locations
          .filter(
            (location) =>
              location.name.toLowerCase().includes(searchValue.toLowerCase()) ||
              location.city.toLowerCase().includes(searchValue.toLowerCase()) ||
              location.streetName
                .toLowerCase()
                .includes(searchValue.toLowerCase()) ||
              location.streetNumber
                .toLowerCase()
                .includes(searchValue.toLowerCase()) ||
              location.zip.toLowerCase().includes(searchValue.toLowerCase())
          )
          .map((location) => parseInt(location.id, 10));
        return organizationWithSubscriptions.zones.filter(
          (zone) =>
            zone.name.toLowerCase().includes(searchValue.toLowerCase()) ||
            locationIds.includes(zone.locationId)
        );
      }
      if (searchFilter === 'zone') {
        return organizationWithSubscriptions.zones.filter((zone) =>
          zone.name.toLowerCase().includes(searchValue.toLowerCase())
        );
      }
    }
    return organizationWithSubscriptions?.zones;
  }, [
    organizationWithSubscriptions?.locations,
    organizationWithSubscriptions?.zones,
    searchFilter,
    searchValue,
  ]);

  return (
    <>
      {searchFilter && searchValue ? (
        <Top>
          <FilterMessage
            resetFilters={resetFilters}
            searchFilter={searchFilter}
            searchValue={searchValue}
          />
        </Top>
      ) : (
        <div />
      )}
      <Top>
        {filteredLocations.length !== 0 && (
          <ButtonWrapper>
            <Button
              emphasis="secondary"
              size="small"
              onClick={toggleExpandAllZones}
            >
              {expandAllZones
                ? t('subscriptions:thHideAllSubscriptions')
                : t('subscriptions:thShowAllSubscriptions')}
            </Button>
            {expandAllZones ? (
              <AngleUpIcon color={theme.dark300} />
            ) : (
              <AngleDownIcon color={theme.dark300} />
            )}
          </ButtonWrapper>
        )}
        {filteredLocations.length === 0 && (
          <NoResults>{t('subscriptions:noResultsWithFilter')}</NoResults>
        )}
        {filteredLocations?.map((location) => (
          <LocationListTable
            key={location.id}
            allProducts={allProducts}
            expandAllZones={expandAllZones}
            location={location}
            locationsLength={filteredLocations.length}
            organization={organizationWithSubscriptions}
            zones={filteredZones}
          />
        ))}
      </Top>
    </>
  );
};

export default Table;
