import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Modal, ErrorMessage } from '../../../../../common';
import { Button } from '../../../../../common/oldButton';
import StringField from '../../../../../common/oldFields/stringField';

const P = styled.p`
  margin: 0;
  padding-bottom: 2rem;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  > :not(:first-child) {
    margin-left: 1rem;
  }
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 2rem;
  width: 100%;
`;

const DeleteModal = ({
  isOpen,
  onConfirm,
  onCancel,
  requestLoading,
  requestError,
  title,
  description,
}) => {
  const { t } = useTranslation();

  const [name, setName] = useState('');
  const [error, setError] = useState('');

  const handleOnClose = useCallback(() => {
    if (!requestLoading) {
      onCancel();
    }
  }, [requestLoading, onCancel]);

  const handleConfirm = useCallback(() => {
    if (name === 'ABCD') {
      setName('');
      onConfirm();
    } else {
      setError(t('common:button.typeToConfirm'));
    }
  }, [name, onConfirm, t]);

  const handleChange = useCallback((event) => {
    setError('');
    setName(event.currentTarget?.value);
  }, []);

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        handleConfirm();
      }
    },
    [handleConfirm]
  );

  return (
    <Modal footer={false} isOpen={isOpen} onClose={handleOnClose} title={title}>
      <P>{description}</P>
      <StringField
        error={error}
        label={t('common:button.typeToConfirm')}
        name="confirmation"
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        value={name}
      />
      {requestError && <StyledErrorMessage error={requestError} />}
      <Actions>
        <Button
          emphasis="warning"
          flavor="link"
          isDisabled={requestLoading}
          onClick={handleOnClose}
          title={t('common:button.title.cancel')}
          type="button"
        >
          {t('common:button.cancel')}
        </Button>
        <Button
          emphasis="warning"
          flavor="flat"
          isDisabled={requestLoading}
          isLoading={requestLoading}
          onClick={handleConfirm}
          title={t('common:button.title.remove')}
        >
          {t('common:button.delete')}
        </Button>
      </Actions>
    </Modal>
  );
};

export default DeleteModal;
