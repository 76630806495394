import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  Modal,
  ErrorMessage,
  LoadingIndicator,
  Button,
} from '../../../../../common';
import PlaylistRow from './playlistRow';
import { usePlaylists } from '../../playlistsProvider';
import { ReactComponent as AddIcon } from '../../../../../assets/icons/smallAdd.svg';
import { PlaylistModalModes } from '../../types';

const Instructions = styled.p`
  font-size: 1.4rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.dark400};
`;

const NoPlaylistsMessage = styled.p`
  font-size: 1.4rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.dark400};
`;

const Container = styled.div`
  display: grid;
  gap: 0.4rem;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-bottom: 1rem;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin-bottom: 1rem;
`;

const StyledButton = styled(Button)`
  width: 100%;
  height: 5.8rem;
  padding-left: 1rem;
  display: grid;
  grid-template-columns: min-content 1fr;
  text-align: left;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.dark400};
  border-color: ${({ theme }) => theme.dark100};

  &:hover {
    color: ${({ theme }) => theme.dark400};
    border-color: ${({ theme }) => theme.dark300};
  }
`;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.waterBlue};
  margin-right: 1rem;
  min-height: 3rem;
  min-width: 3rem;
`;

const StyledAddIcon = styled(AddIcon)`
  width: 1.2rem;
  height: 1.2rem;

  & path {
    fill: ${({ theme }) => theme.white};
  }
`;

const PlaylistSelectionModal = ({
  isOpen,
  onClose,
  title,
  instructions,
  songs,
}) => {
  const { t } = useTranslation();

  const {
    dispatch,
    addSongsToCustomPlaylist,
    selectedZone,
    loadingAddSongs,
    customPlaylists,
    favouritePlaylist,
    loadingCustomPlaylists,
    errorCustomPlaylists,
  } = usePlaylists();

  const [selected, setSelected] = useState([]);

  const handleConfirm = useCallback(async () => {
    if (selected?.id) {
      dispatch({
        chosenPlaylistId: selected.id,
        chosenSongIds: songs.map((song) => song.songId),
        selectedSongs: [],
        isSongSelectionActivated: false,
      });
      const playlists = [...customPlaylists, favouritePlaylist];
      const currentPlaylistTimestamp = playlists.find(
        (playlist) => Number(playlist.id) === Number(selected.id)
      )?.timestamp;
      if (currentPlaylistTimestamp) {
        await addSongsToCustomPlaylist({
          variables: {
            zoneId: selectedZone.id,
            playlistId: selected.id,
            timestamp: currentPlaylistTimestamp,
            songIds: songs.map((song) => song.songId),
            duplicateHandling: 'ERROR',
          },
        });
      }
      onClose();
    }
  }, [
    selectedZone.id,
    songs,
    addSongsToCustomPlaylist,
    onClose,
    dispatch,
    selected,
    customPlaylists,
    favouritePlaylist,
  ]);

  const handleClose = useCallback(() => {
    setSelected([]);
    onClose();
  }, [onClose]);

  const togglePlaylistCheckBox = useCallback(
    (id) => {
      const playlists = [...customPlaylists, favouritePlaylist];
      setSelected(playlists.find((zone) => zone.id === id));
    },
    [customPlaylists, favouritePlaylist, setSelected]
  );

  const handleOpenCreatePlaylist = useCallback(() => {
    dispatch({ modalMode: PlaylistModalModes.CREATE });
  }, [dispatch]);

  const favouriteList = {
    id: favouritePlaylist.id,
    title: t('musicManagement:playlists.list.favorites'),
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={title}
      requestLoading={loadingAddSongs}
      onConfirm={handleConfirm}
      confirmDisabled={selected.length === 0}
    >
      {errorCustomPlaylists ? (
        <StyledErrorMessage error={errorCustomPlaylists} />
      ) : null}
      {loadingCustomPlaylists ? <StyledLoadingIndicator /> : null}
      {customPlaylists && customPlaylists.length > 0 ? (
        <Instructions>{instructions}</Instructions>
      ) : null}
      {customPlaylists.length > 0 ? (
        <Container>
          <PlaylistRow
            playlist={favouriteList}
            selected={selected}
            togglePlaylistCheckBox={togglePlaylistCheckBox}
          />
          {customPlaylists.map((playlist) => (
            <PlaylistRow
              key={playlist.id}
              playlist={playlist}
              selected={selected}
              togglePlaylistCheckBox={togglePlaylistCheckBox}
            />
          ))}
        </Container>
      ) : (
        <NoPlaylistsMessage>
          {t('musicManagement:playlists.noPlaylists')}
        </NoPlaylistsMessage>
      )}
      <StyledButton emphasis="outline" isDiv onClick={handleOpenCreatePlaylist}>
        <Circle>
          <StyledAddIcon />
        </Circle>
        {t('musicManagement:playlists.list.new')}
      </StyledButton>
    </Modal>
  );
};

export default PlaylistSelectionModal;
