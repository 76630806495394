import { useState, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useMutation } from '@apollo/react-hooks';

import {
  useMediaSize,
  LoadingIndicator,
  ErrorMessage,
  MusicListSelectionModal,
} from '../../../../common';
import { Button } from '../../../../common/oldButton';
import { Dropdown } from '../../../../common/oldFields/dropdownField';
import SwitchField from '../../../../common/oldFields/switchField';
import { useGeneral } from './generalProvider';
import { ReactComponent as AddonsIcon } from '../../../../assets/icons/navigation/addOnLarge.svg';
import { DropdownOptionTypes } from '../types';
import {
  UPDATE_MUSIC_PROFILE_TEMPLATE_ID,
  UPDATE_EXPLICIT_CONTENT_ALLOWED,
  UPDATE_LOCAL_CHANGES_ALLOWED,
  EDIT_ZONE,
} from './api';

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 3rem;
  display: grid;
  grid-template-rows: 1fr 6rem 1fr;
  position: relative;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
  background-color: ${({ theme }) => theme.white};

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    grid-template-columns: 1fr 6rem 1fr;
    grid-template-rows: 1fr;
  }
`;

const Title = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.dark300};
`;

const Left = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  gap: 1.6rem;
`;

const Line = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${({ theme }) => theme.dark400};
  justify-self: center;
`;

const LineHorizontal = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.dark400};
  align-self: center;
`;

const Right = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  gap: 1.6rem;
`;

const StyledDropdown = styled(Dropdown)`
  margin: 0;

  & > div > div {
    max-height: 8rem;
  }
`;

const StyledSwitchField = styled(SwitchField)`
  & > div {
    margin-left: 0;
  }
`;

const Subtitle = styled.h3`
  font-size: 1rem;
  margin: 0;
  font-weight: 600;
`;

const Description = styled.p`
  font-size: 1rem;
  margin: 0;
  padding: 0.6rem 0;
`;

const AddonBlock = styled.div`
  background-color: ${({ theme }) => theme.stroke};
  border-radius: 0.4rem;
  padding: 2rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 1rem;
`;

const AddonInfo = styled.div`
  display: grid;
  gap: 0.3rem;
`;

const StyledAddonsIcon = styled(AddonsIcon)`
  min-width: 3rem;
  width: 3rem;
  height: 3rem;

  & path {
    fill: ${({ theme }) => theme.dark300};
  }
`;

const LinkButton = styled.button`
  width: fit-content;
  border: none;
  background-color: inherit;
  cursor: pointer;
  padding: 0;
  outline: none;
  color: ${({ theme }) => theme.berryBlue};
  border-bottom: 1px solid ${({ theme }) => theme.waterBlue};
  transition: color 0.5s ease;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  width: 100%;
  margin-bottom: 1rem;
`;

const StartListButton = styled(Button)`
  width: fit-content;
  justify-self: flex-end;
  height: 2rem;
  border: 2px solid ${({ theme }) => theme.waterBlue};
  & p {
    text-transform: none;
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.large - 1}px) {
    &:hover,
    &:focus {
      height: 2rem;
      border: 2px solid ${({ theme }) => theme.waterBlue};
      & p {
        text-transform: none;
      }
    }
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    justify-self: flex-start;
    border: none;
  }
`;

const StartListTitle = styled.p`
  font-weight: 600;
  margin: 0.4rem 0 0.6rem 0;
`;

const General = ({
  selectedType,
  zonesWithActiveSubscription,
  refetchZonesWithActiveSubscription,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isWiderThanMedium } = useMediaSize();
  const {
    loadingRegions,
    regions,
    selectedProfile,
    musicProfileSettings,
    loadingMusicProfileSettings,
    loadingMusicProfileTemplates,
    musicProfileTemplates,
    musicLicenses,
    loadingProducts,
    detailedSelectedStartList,
    updateDefaultMusicSelection,
    loadingUpdateDefaultMusicSelection,
    errorUpdateDefaultMusicSelection,
    tunifyChannels,
    tunifyCalendars,
  } = useGeneral();

  const [regionId, setRegionId] = useState('');
  const [catalogId, setCatalogId] = useState('');
  const [templateId, setTemplateId] = useState('');
  const [isOptimizedProfileEnabled, setOptimizedProfileEnabled] =
    useState(false);
  const [isExplicitContentEnabled, setExplicitContentEnabled] = useState(false);
  const [isEditMusicLocalEnabled, setEditMusicLocalEnabled] = useState(false);
  const [isStartListEnabled, setStartListEnabled] = useState(false);
  const [isStartListModalOpen, setStartListModalOpen] = useState(false);

  const [
    updateMusicProfileTemplateId,
    { loading: loadingUpdateTemplateId, error: errorUpdateTemplateId },
  ] = useMutation(UPDATE_MUSIC_PROFILE_TEMPLATE_ID);

  const [
    updateExplicitContentAllowed,
    {
      loading: loadingUpdateExplicitContentAllowed,
      error: errorUpdateExplicitContentAllowed,
    },
  ] = useMutation(UPDATE_EXPLICIT_CONTENT_ALLOWED);

  const [
    updateLocalChangesAllowed,
    {
      loading: loadingUpdateLocalChangesAllowed,
      error: errorUpdateLocalChangesAllowed,
    },
  ] = useMutation(UPDATE_LOCAL_CHANGES_ALLOWED);

  const [editZone, { loading: editZoneLoading, error: editZoneError }] =
    useMutation(EDIT_ZONE, {
      onCompleted: () => {
        refetchZonesWithActiveSubscription();
      },
    });

  const isProfile = selectedType === DropdownOptionTypes.PROFILE;

  const selectedActiveProfile =
    !isProfile &&
    zonesWithActiveSubscription?.find(
      (zone) => zone.id === selectedProfile?.id
    );

  const currentZoneMusicLicenseId = !isProfile
    ? selectedActiveProfile?.currentSubscription?.subscriptionLines?.[0]
        ?.product?.musicLicenseId
    : null;

  const currentZoneRegionId = !isProfile
    ? selectedActiveProfile?.musicRegionId
    : null;

  useEffect(() => {
    if (musicProfileSettings) {
      setEditMusicLocalEnabled(
        musicProfileSettings?.localChangesAllowed ?? false
      );
      setExplicitContentEnabled(
        musicProfileSettings?.explicitContentAllowed ?? false
      );
      setOptimizedProfileEnabled(
        Boolean(musicProfileSettings?.musicProfileTemplateId) ?? false
      );
      setStartListEnabled(
        Boolean(musicProfileSettings?.defaultMusicSelection) ?? false
      );
      if (musicProfileSettings?.musicProfileTemplateId) {
        setTemplateId(musicProfileSettings.musicProfileTemplateId);
      }
    }
  }, [musicProfileSettings]);

  useEffect(() => {
    setRegionId(
      selectedProfile?.externalRegionId || currentZoneRegionId?.toString()
    );
    setCatalogId(
      selectedProfile?.musicLicenseId || currentZoneMusicLicenseId?.toString()
    );
  }, [selectedProfile, currentZoneMusicLicenseId, currentZoneRegionId]);

  const handleBuyNow = useCallback(() => {
    navigate('/add-ons');
  }, [navigate]);

  const handleChangeTemplate = useCallback(
    (id) => {
      setTemplateId(id);
      updateMusicProfileTemplateId({
        variables: {
          id: selectedProfile.tunifyMusicProfileUserId,
          templateId: id,
        },
      });
    },
    [selectedProfile?.tunifyMusicProfileUserId, updateMusicProfileTemplateId]
  );

  const handleChangeOptimizedProfile = useCallback(
    (value) => {
      setOptimizedProfileEnabled(value);
      if (!value) {
        updateMusicProfileTemplateId({
          variables: { id: selectedProfile.tunifyMusicProfileUserId },
        });
      }
    },
    [updateMusicProfileTemplateId, selectedProfile?.tunifyMusicProfileUserId]
  );

  const handleUpdateExplicitContent = useCallback(
    (value) => {
      setExplicitContentEnabled(value);
      updateExplicitContentAllowed({
        variables: { id: selectedProfile.tunifyMusicProfileUserId, value },
      });
    },
    [updateExplicitContentAllowed, selectedProfile?.tunifyMusicProfileUserId]
  );

  const handleChangeLocalMusic = useCallback(
    (value) => {
      setEditMusicLocalEnabled(value);
      updateLocalChangesAllowed({
        variables: { id: selectedProfile.tunifyMusicProfileUserId, value },
      });
    },
    [updateLocalChangesAllowed, selectedProfile?.tunifyMusicProfileUserId]
  );

  const handleChangeStartList = useCallback(
    (value) => {
      setStartListEnabled(value);
      if (!value) {
        updateDefaultMusicSelection({
          variables: {
            id: selectedProfile.tunifyMusicProfileUserId,
          },
        });
      }
    },
    [updateDefaultMusicSelection, selectedProfile?.tunifyMusicProfileUserId]
  );

  const handleChangeRegion = useCallback(
    (id) => {
      setRegionId(id);
      editZone({
        variables: {
          zoneId: selectedProfile?.id,
          editZone: {
            name: selectedProfile?.name,
            musicRegionId: id,
          },
        },
      });
    },
    [editZone, selectedProfile]
  );

  const closeStartListModal = useCallback(() => {
    setStartListModalOpen(false);
  }, []);

  const openStartListModal = useCallback(() => {
    setStartListModalOpen(true);
  }, []);

  const handleSubmitStartListModal = useCallback(
    async ({ id, flow }) => {
      const activeMode = { MOOD: 'MusicChannel', AGENDA: 'Calendar' }[flow];
      await updateDefaultMusicSelection({
        variables: {
          id: selectedProfile.tunifyMusicProfileUserId,
          musicSelection: {
            id,
            activeMode,
          },
        },
      });
      closeStartListModal();
    },
    [
      closeStartListModal,
      selectedProfile?.tunifyMusicProfileUserId,
      updateDefaultMusicSelection,
    ]
  );

  const templateOptions = useMemo(
    () => musicProfileTemplates?.map(({ id, name }) => ({ id, name })) ?? [],
    [musicProfileTemplates]
  );

  const musicLicenseOptions = useMemo(
    () => musicLicenses?.map(({ id, name }) => ({ id, name })) ?? [],
    [musicLicenses]
  );

  return loadingMusicProfileSettings ? (
    <LoadingIndicator />
  ) : (
    <>
      {errorUpdateTemplateId ||
      errorUpdateExplicitContentAllowed ||
      errorUpdateLocalChangesAllowed ||
      editZoneError ||
      errorUpdateDefaultMusicSelection ? (
        <StyledErrorMessage
          error={
            errorUpdateTemplateId ||
            errorUpdateExplicitContentAllowed ||
            errorUpdateLocalChangesAllowed ||
            editZoneError ||
            errorUpdateDefaultMusicSelection
          }
        />
      ) : null}
      <Container>
        <Left>
          <Title>{t('musicManagement:general.left.title')}</Title>
          <StyledDropdown
            currentOptionId={regionId}
            disabled={isProfile}
            isLoading={loadingRegions || editZoneLoading}
            label={t('musicManagement:addMusicProfile.region')}
            name="region"
            onChange={handleChangeRegion}
            options={regions}
          />
          <StyledDropdown
            currentOptionId={catalogId}
            disabled
            isLoading={loadingProducts}
            label={t('musicManagement:general.catalog')}
            name="catalog"
            onChange={setCatalogId}
            options={musicLicenseOptions}
          />
          {isProfile ? (
            <>
              <div>
                <StyledSwitchField
                  checked={isOptimizedProfileEnabled}
                  isDisabled={loadingUpdateTemplateId}
                  label={t('musicManagement:general.optimizedMusicProfile')}
                  onChange={handleChangeOptimizedProfile}
                />
                <Description>
                  {t(
                    'musicManagement:general.optimizedMusicProfileDescription'
                  )}
                </Description>
                <StyledDropdown
                  currentOptionId={templateId}
                  disabled={!isOptimizedProfileEnabled}
                  isLoading={
                    loadingMusicProfileTemplates || loadingUpdateTemplateId
                  }
                  name="optimizedProfile"
                  onChange={handleChangeTemplate}
                  options={templateOptions}
                />
              </div>
              <div>
                <StyledSwitchField
                  checked={isExplicitContentEnabled}
                  isDisabled={loadingUpdateExplicitContentAllowed}
                  label={t('musicManagement:general.explicitContent')}
                  onChange={handleUpdateExplicitContent}
                />
                <Description>
                  {t('musicManagement:general.explicitContentDescription')}
                </Description>
              </div>
            </>
          ) : null}
        </Left>
        {isWiderThanMedium ? <Line /> : <LineHorizontal />}
        {isProfile ? (
          <Right>
            <Title>{t('musicManagement:general.right.title')}</Title>
            <div>
              <StyledSwitchField
                checked={isEditMusicLocalEnabled}
                isDisabled={loadingUpdateLocalChangesAllowed}
                label={t('musicManagement:general.editMusicLocal')}
                onChange={handleChangeLocalMusic}
              />
              <Description>
                {t('musicManagement:general.editMusicLocalDescription')}
              </Description>
            </div>
            <div>
              <StyledSwitchField
                checked={isStartListEnabled}
                label={t('musicManagement:general.startList')}
                onChange={handleChangeStartList}
              />
              <Description>
                {t('musicManagement:general.startListDescription')}
              </Description>
              <StartListTitle>
                {(detailedSelectedStartList?.title ||
                  detailedSelectedStartList?.name) ??
                  ''}
              </StartListTitle>
              <StartListButton
                emphasis="tertiary"
                isDisabled={!isStartListEnabled}
                onClick={openStartListModal}
              >
                <p>{t('musicManagement:general.startListButton')}</p>
              </StartListButton>
            </div>
          </Right>
        ) : (
          <Right>
            <AddonBlock>
              <StyledAddonsIcon />
              <AddonInfo>
                <Title>{t('musicManagement:general.profiles.title')}</Title>
                <Description>
                  {t('musicManagement:general.profiles.description')}
                </Description>
                <LinkButton
                  onClick={handleBuyNow}
                  title={t('musicManagement:general.buyNow')}
                >
                  {t('subscriptions:buySubscription')}
                </LinkButton>
              </AddonInfo>
            </AddonBlock>
            <Title>{t('musicManagement:general.extra.title')}</Title>
            <div>
              <Subtitle>{t('musicManagement:general.editMusicLocal')}</Subtitle>
              <Description>
                {t('musicManagement:general.editMusicLocalDescription')}
              </Description>
            </div>
            <div>
              <Subtitle>{t('musicManagement:general.startList')}</Subtitle>
              <Description>
                {t('musicManagement:general.startListDescription')}
              </Description>
            </div>
          </Right>
        )}
      </Container>
      {isStartListModalOpen ? (
        <MusicListSelectionModal
          flows={['MOOD', 'AGENDA']}
          isOpen={isStartListModalOpen}
          isSubmitting={loadingUpdateDefaultMusicSelection}
          onClose={closeStartListModal}
          onSubmit={handleSubmitStartListModal}
          title={t('musicManagement:general.changeStartList')}
          tunifyCalendars={tunifyCalendars}
          tunifyChannels={tunifyChannels}
        />
      ) : null}
    </>
  );
};

export default General;
