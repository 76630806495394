import styled from 'styled-components';

import Label from '../common/label';
import ErrorMessage from '../common/errorMessage';
import { DropZone } from '../..';

const InputWrapper = styled.div`
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;

  background: ${({ theme }) =>
    `linear-gradient(0deg, ${theme.gradient['fade'][0]} 0%,  ${theme.gradient['fade'][1]} 100%)`};
`;

const DropZoneField = ({
  label,
  disabled,
  required,
  error,
  accept,
  placeholder,
  onDropAccepted,
  isLoading,
  maxSize,
  acceptedFiles,
  name,
}) => {
  return (
    <InputWrapper>
      {label ? (
        <Label required={required} disabled={disabled}>
          {label}
        </Label>
      ) : null}
      <DropZone
        accept={accept}
        acceptedFiles={acceptedFiles}
        disabled={disabled}
        isLoading={isLoading}
        maxSize={maxSize}
        name={name}
        onDropAccepted={onDropAccepted}
        placeholder={placeholder}
      />
      {typeof error === 'string' ? <ErrorMessage>{error}</ErrorMessage> : null}
    </InputWrapper>
  );
};

export default DropZoneField;
