import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SearchUsersLarge } from '../../../assets/icons/navigation/searchUsersLarge.svg';
import theme from '../../../global/style/theme';

const Container = styled.div`
  display: grid;
  row-gap: 2.4rem;
  grid-template-columns: 1fr;
  background-color: ${({ theme }) => theme.white};
  padding: 1.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.stroke};

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    padding: 2.2rem 3rem 2.2rem 3rem;
    row-gap: 3rem;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    padding: 2.6rem 4rem 2.6rem 4rem;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.extraLarge}px) {
    padding: 3rem 4.8rem 3rem 4.8rem;
  }
`;

const TitleContainer = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  gap: 1.6rem;
  grid-column: span 2;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    grid-column: unset;
  }
`;

const Logo = styled.div`
  width: 4rem;
  height: 4rem;
  display: grid;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    135deg,
    ${({ theme }) => theme.gradient['nightfall'][0]} 0%,
    ${({ theme }) => theme.gradient['nightfall'][1]} 100%
  );
  border-radius: 4px;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    width: 4.8rem;
    height: 4.8rem;
  }
`;

const StyledSearchUsersLarge = styled(SearchUsersLarge)`
  width: 2.6rem;
  height: 2.6rem;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    width: 3.2rem;
    height: 3.2rem;
  }
`;

const Title = styled.h1`
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    font-size: 2.8rem;
  }
`;

const PageHeader = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <TitleContainer>
        <Logo>
          <StyledSearchUsersLarge color={theme.white} />
        </Logo>
        <Title>{t('customerSuccess:cardHeader')}</Title>
      </TitleContainer>
    </Container>
  );
};

export default PageHeader;
