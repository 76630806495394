import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ErrorMessage, formatDate } from '../../../../../common';
import { useAuth } from '../../../../../global/auth/newAuthProvider';
import { GET_ACTIVE_ZONES_COUNT_BY_ID, GET_ZONES_COUNT_BY_ID } from '../../api';
import { useCustomerSuccessHistory } from '../../historyContext';

const Label = styled.p`
  color: ${({ theme }) => theme.dark200};
  font-family: 'Inter', sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.333;
  margin-bottom: 0.5rem;
`;

const Text = styled.p`
  color: ${({ theme }) => theme.white};
  font-family: 'Inter', sans-serif;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.714;
`;

const Card = styled.div`
  background-color: ${({ theme }) => theme.dark500};
  border-radius: 8px;
  padding: 1.2rem 1.6rem;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  margin-bottom: 2rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

const RegistrationDateContainer = styled.div`
  grid-column: span 2;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    grid-column: unset;
  }
`;

const FilterCard = () => {
  const { t, i18n } = useTranslation();
  const { user } = useAuth();
  const { useFilter } = useCustomerSuccessHistory();
  const { data: zonesCountData, error: zonesCountError } = useQuery(
    GET_ZONES_COUNT_BY_ID
  );
  const { data: activeZonesCountData, error: activeZonesCountError } = useQuery(
    GET_ACTIVE_ZONES_COUNT_BY_ID
  );

  return useFilter ? (
    <Card>
      <RegistrationDateContainer>
        <Label>{t('customerSuccess:history.registrationDate')}</Label>
        <Text>
          {user.registrationDate
            ? formatDate(user.registrationDate, i18n.language, {
                outputFormat: {
                  day: '2-digit',
                  month: 'long',
                  year: 'numeric',
                },
              })
            : '-'}
        </Text>
      </RegistrationDateContainer>
      <div>
        <Label>{`# ${t('customerSuccess:history.zones')}`}</Label>
        {zonesCountError ? (
          <ErrorMessage error={zonesCountError} />
        ) : (
          <Text>{zonesCountData?.getZonesCountById ?? 0}</Text>
        )}
      </div>
      <div>
        <Label>{`# ${t('customerSuccess:history.activeZones')}`}</Label>
        {activeZonesCountError ? (
          <ErrorMessage error={activeZonesCountError} />
        ) : (
          <Text>{activeZonesCountData?.getActiveZonesCountById ?? 0}</Text>
        )}
      </div>
    </Card>
  ) : null;
};

export default FilterCard;
