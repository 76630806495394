import { useEffect, useMemo } from 'react';
import styled from 'styled-components';

import { determineAddonCategoriesToShow } from '../../../../../common';
import { useAddonsBuy } from '../../addonsBuyContext';
import AddonCard from './addOnCard';
import AddonNotAvailableCard from './addonNotavailablecard';

const Addons = styled.div`
  display: grid;
  gap: 1.2rem;
`;

const AddonSelectionStep = ({ setCanProceedToNextStep }) => {
  const { availableAddons, handleSelectAddon, selectedAddons } = useAddonsBuy();

  useEffect(() => {
    if (selectedAddons.length > 0) {
      setCanProceedToNextStep(true);
    } else {
      setCanProceedToNextStep(false);
    }
    return () => {
      setCanProceedToNextStep(true);
    };
  }, [selectedAddons, setCanProceedToNextStep]);

  const addonsToShow = useMemo(() => determineAddonCategoriesToShow(availableAddons), [availableAddons]);

  return (
    <Addons>
      {addonsToShow.map((addon) =>
        addon.saleOk ? (
          <AddonCard
            key={addon.id}
            addon={addon}
            handleSelectAddon={handleSelectAddon}
            isSelected={!!selectedAddons.find((selectedAddon) => selectedAddon.id === addon.id)}
          />
        ) : (
          <AddonNotAvailableCard key={addon.id} addon={addon} />
        )
      )}
    </Addons>
  );
};

export default AddonSelectionStep;
