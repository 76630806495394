import gql from 'graphql-tag';

export const SEND_PAGE_UPDATE = gql`
  mutation sendPageUpdate(
    $eventUrl: String!
    $email: String!
    $country: String
    $ip: String
    $agent: String
  ) {
    sendPageUpdate(
      eventUrl: $eventUrl
      email: $email
      country: $country
      ip: $ip
      agent: $agent
    ) {
      events_received
    }
  }
`;
