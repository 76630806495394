import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useClipboard from 'react-use-clipboard';
import styled from 'styled-components';
import { useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';

import {
  Modal,
  ErrorMessage,
  LoadingIndicator,
  VerificationCodeField,
  BasicButton,
  LinkButton,
} from '../../../../../common';
import { ReactComponent as RawSuccessIcon } from '../../../../../assets/icons/smallCheckmark.svg';
import { ReactComponent as RawCopyIcon } from '../../../../../assets/icons/copy.svg';
import { ReactComponent as FacebookIcon } from '../../../../../assets/icons/facebook.svg';
import { ReactComponent as WhatsAppIcon } from '../../../../../assets/icons/whatsApp.svg';
import theme from '../../../../../global/style/theme';

import { CREATE_CODE } from '../../../api';

const StyledErrorMessage = styled(ErrorMessage)`
  width: 100%;
  margin-bottom: 1rem;
`;

const CodeContainer = styled.div`
  height: 16rem;
  padding: 1.6rem 0 2rem 0;
  background-color: ${({ theme }) => theme.background};
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.stroke};
  display: grid;
  gap: 1rem;
  justify-items: center;
  align-items: center;
`;

const LocationZone = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.dark200};
  font-weight: 700;
  padding: 0 0.8rem;
  margin: 0;
  align-self: end;
`;

const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledBasicButton = styled(BasicButton)`
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: 0.8rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    background-color: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.stroke};
    border-radius: 4px;
    padding: 1rem;
    gap: 0;
    min-height: 3.8rem;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    background-color: transparent;
    border: none;
    padding: 0;
    gap: 0.8rem;
    min-height: unset;
  }

  & p {
    border-bottom: 1px solid ${({ theme }) => theme.stroke};
    color: ${({ theme }) => theme.dark400};

    @media only screen and (min-width: ${({ theme }) =>
        theme.breakpoints.medium}px) {
      display: none;
    }

    @media only screen and (min-width: ${({ theme }) =>
        theme.breakpoints.large}px) {
      display: block;
    }
  }
`;

const Column = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 0.8rem;
`;

const Description = styled.p`
  color: ${({ theme }) => theme.dark300};
  font-size: 1.4rem;
  line-height: 1.4;
`;

const StyledLinkButton = styled(LinkButton)`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.stroke};
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  gap: 0.8rem;
  padding: 0 1.6rem;
  transition: border-color 0.2s ease-in-out;

  &:hover {
    border-width: 1px;
  }
`;

const ZoneSelectionModal = ({ isOpen, toggle, zone, locationName }) => {
  const { t } = useTranslation();

  const { control, setValue, watch } = useForm({
    defaultValues: { code: '' },
  });

  const watchCode = watch('code');

  const [createCode, { loading: createCodeLoading, error: createCodeError }] =
    useMutation(CREATE_CODE, {
      variables: { zoneId: zone.id },
      onCompleted: (data) => {
        setValue('code', data.createCode.code);
      },
    });

  useEffect(() => {
    createCode();
  }, [createCode]);

  const [isCopied, copyToClipboard] = useClipboard(watchCode, {
    successDuration: 4000,
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={toggle}
      title={t('common:button.newPlayerCode')}
      footer={<></>}
    >
      {createCodeError && <StyledErrorMessage error={createCodeError} />}
      <CodeContainer>
        {createCodeLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <LocationZone>{`${locationName} - ${zone.name}`}</LocationZone>
            <VerificationCodeField name="code" control={control} disabled />
          </>
        )}
      </CodeContainer>
      <BottomContainer>
        {watchCode ? (
          <Column>
            <Description>{t('glossary:share')}:</Description>
            <StyledLinkButton
              emphasis="outline"
              size="medium"
              href={`https://wa.me/?text=${watchCode}`}
              title={t('common:shareViaWhatsApp')}
            >
              <WhatsAppIcon />
              <p>{t('common:whatsApp')}</p>
            </StyledLinkButton>
            <StyledLinkButton
              emphasis="outline"
              size="medium"
              href={`https://www.facebook.com/sharer/sharer.php?u=${watchCode}`}
              title={t('common:shareViaFacebook')}
            >
              <FacebookIcon />
              <p>{t('common:facebook')}</p>
            </StyledLinkButton>
          </Column>
        ) : (
          <div />
        )}
        <StyledBasicButton
          disabled={!watchCode}
          onClick={copyToClipboard}
          title={t('common:button.title.copyLink')}
        >
          {isCopied ? (
            <RawSuccessIcon
              width="1.6rem"
              height="1.1rem"
              color={theme.dark300}
            />
          ) : (
            <RawCopyIcon color={theme.dark300} />
          )}
          <p>{t('common:button.copy')}</p>
        </StyledBasicButton>
      </BottomContainer>
    </Modal>
  );
};

export default ZoneSelectionModal;
