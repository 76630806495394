import { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { CheckboxField } from '../../../common';
import Slider from './slider';

const CheckboxWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1.5rem;
  column-gap: 1rem;
  padding: 1rem 0;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const LineWrapper = styled.div`
  padding: 2rem 0;
  border-top: 0.1rem solid ${({ theme }) => theme.stroke};

  &:last-child {
    padding-bottom: 0.2rem;
  }
`;

const contextTypes = {
  ChangeableIntervalParameter: 'ChangeableIntervalParameter',
  ChangeableSelectionParameter: 'ChangeableSelectionParameter',
};

const selectionTypes = {
  TEXT_CHECKBOX_SELECTION: 'TEXT_CHECKBOX_SELECTION',
  TEXT_SLIDER_RANGE_SELECTION: 'TEXT_SLIDER_RANGE_SELECTION',
};

const MemoizedCheckbox = ({
  value,
  disable,
  onChange,
  checkboxIndex,
  selected,
  color,
}) => {
  const handleChangeCheckbox = useCallback(() => {
    onChange(checkboxIndex);
  }, [checkboxIndex, onChange]);

  return (
    <CheckboxField
      disable={disable}
      label={value}
      onChange={handleChangeCheckbox}
      checked={selected}
      color={color}
    />
  );
};

const ChangeableParameter = ({
  changeableParameter,
  index,
  disable,
  changeableParameters,
  dispatch,
}) => {
  const handleChangeSlider = useCallback(
    (value) => {
      // Need a deep clone, otherwise state does not trigger changes
      const newParams = JSON.parse(JSON.stringify(changeableParameters));
      newParams[index] = {
        ...newParams[index],
        lowerSelection: value[0],
        upperSelection: value[1],
      };
      dispatch({
        changeableParameters: newParams,
      });
    },
    [changeableParameters, index, dispatch]
  );

  const handleChangeCheckboxSlider = useCallback(
    ([leftPosition, rightPosition]) => {
      // Need a deep clone, otherwise state does not trigger changes
      const newParams = JSON.parse(JSON.stringify(changeableParameters));
      const min = leftPosition;
      const diff = rightPosition - leftPosition;
      const selectedArray = Array.from(
        Array(diff),
        (_, currentPosition) => min + currentPosition
      );
      newParams[index].value = newParams[index].value.map((prevValue, i) => ({
        value: prevValue.value,
        selected: selectedArray.includes(i),
      }));
      dispatch({
        changeableParameters: newParams,
      });
    },
    [changeableParameters, index, dispatch]
  );

  const handleChangeCheckbox = useCallback(
    (checkboxIndex) => {
      // Need a deep clone, otherwise state does not trigger changes
      const newParams = JSON.parse(JSON.stringify(changeableParameters));
      newParams[index].value = newParams[index].value.map((prevValue, i) => ({
        value: prevValue.value,
        selected:
          i === checkboxIndex ? !prevValue.selected : prevValue.selected,
      }));
      dispatch({
        changeableParameters: newParams,
      });
    },
    [changeableParameters, index, dispatch]
  );

  const thumbLabels = useMemo(
    () =>
      changeableParameter.type === contextTypes.ChangeableIntervalParameter &&
      changeableParameter.minimum > 7
        ? [
            `${changeableParameter.lowerSelection} ${changeableParameter.metadataDisplayMinimum}`,
            `${changeableParameter.upperSelection} ${changeableParameter.metadataDisplayMaximum}`,
          ]
        : undefined,
    [changeableParameter]
  );

  const sectionLabels = useMemo(
    () =>
      changeableParameter.type === contextTypes.ChangeableSelectionParameter &&
      changeableParameter.metadataDisplayType ===
        selectionTypes.TEXT_SLIDER_RANGE_SELECTION
        ? changeableParameter.value.map(({ value }) => value)
        : undefined,
    [changeableParameter]
  );

  const sliderValue = useMemo(
    () =>
      changeableParameter.type === contextTypes.ChangeableIntervalParameter
        ? [
            changeableParameter.lowerSelection,
            changeableParameter.upperSelection,
          ]
        : undefined,
    [changeableParameter]
  );

  const sliderLabels = useMemo(
    () =>
      changeableParameter.type === contextTypes.ChangeableIntervalParameter &&
      changeableParameter.minimum <= 7
        ? [
            changeableParameter.metadataDisplayMinimum,
            changeableParameter.name,
            changeableParameter.metadataDisplayMaximum,
          ]
        : undefined,
    [changeableParameter]
  );

  const marks = useMemo(
    () =>
      changeableParameter.type === contextTypes.ChangeableIntervalParameter &&
      changeableParameter.minimum <= 7
        ? Array.from(Array(changeableParameter.maximum), (_, i) => i + 0.5)
        : undefined,
    [changeableParameter]
  );

  const valueOfSelectionSlider = useMemo(() => {
    if (
      changeableParameter.type === contextTypes.ChangeableSelectionParameter &&
      changeableParameter.metadataDisplayType ===
        selectionTypes.TEXT_SLIDER_RANGE_SELECTION
    ) {
      const firstSelected = changeableParameter.value.findIndex(
        ({ selected }) => selected === true
      );
      const lastSelected =
        changeableParameter.value
          ?.map(({ selected }) => selected)
          .lastIndexOf(true) + 1;
      return [firstSelected, lastSelected];
    }
    return null;
  }, [changeableParameter]);

  if (changeableParameter.type === contextTypes.ChangeableIntervalParameter) {
    if (changeableParameter.minimum <= 7) {
      return (
        <LineWrapper>
          <Slider
            disable={disable}
            marks={marks}
            max={changeableParameter.maximum + 0.5}
            min={changeableParameter.minimum - 0.5}
            onChange={handleChangeSlider}
            sliderLabels={sliderLabels}
            step={changeableParameter.step}
            value={sliderValue}
            minDistance={1}
          />
        </LineWrapper>
      );
    } else {
      return (
        <LineWrapper>
          <Slider
            disable={disable}
            max={changeableParameter.maximum}
            min={changeableParameter.minimum}
            onChange={handleChangeSlider}
            sliderLabels={sliderLabels}
            step={changeableParameter.step}
            thumbLabels={thumbLabels}
            value={sliderValue}
          />
        </LineWrapper>
      );
    }
  }

  if (changeableParameter.type === contextTypes.ChangeableSelectionParameter) {
    if (
      changeableParameter.metadataDisplayType ===
      selectionTypes.TEXT_SLIDER_RANGE_SELECTION
    ) {
      return (
        <LineWrapper>
          <Slider
            disable={disable}
            max={changeableParameter.value.length}
            minDistance={1}
            onChange={handleChangeCheckboxSlider}
            sectionLabels={sectionLabels}
            value={valueOfSelectionSlider}
          />
        </LineWrapper>
      );
    }

    if (
      changeableParameter.metadataDisplayType ===
      selectionTypes.TEXT_CHECKBOX_SELECTION
    ) {
      return (
        <LineWrapper>
          <CheckboxWrapper>
            {changeableParameter.value.map(
              ({ value, selected }, checkboxIndex) => (
                <MemoizedCheckbox
                  key={value}
                  checkboxIndex={checkboxIndex}
                  disable={disable}
                  onChange={handleChangeCheckbox}
                  selected={selected}
                  value={value}
                  color="waterBlue"
                />
              )
            )}
          </CheckboxWrapper>
        </LineWrapper>
      );
    }
  }

  return null;
};

export default ChangeableParameter;
