import { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';

import { BasicButton, CheckboxField } from '../..';

const Container = styled(BasicButton)`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.2rem;
  margin: 1px;
  padding: 1.6rem;
  border: solid 1px ${({ theme }) => theme.stroke};
  border-radius: 8px;
  transition: border-color 0.2s ease-in-out;

  ${({ isActive, theme }) =>
    isActive &&
    css`
      margin: 0;
      border: solid 2px ${theme.waterBlue};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: ${({ $selectBoxLeft }) => ($selectBoxLeft ? 'min-content 1fr' : '1fr min-content')};
  align-items: center;
  gap: 1.2rem;
`;

const MainContainer = styled.div`
  display: grid;
`;

const StyledCheckboxField = styled(CheckboxField)`
  pointer-events: none;
`;

const CheckboxCard = ({
  id,
  activeCheckboxCardId,
  isSelected = false,
  onClick,
  children,
  className,
  isDisabled,
  selectBoxLeft,
  title,
}) => {
  const handleOnClick = useCallback(() => {
    onClick(id);
  }, [id, onClick]);

  const isActive = useMemo(
    () =>
      activeCheckboxCardId
        ? Array.isArray(activeCheckboxCardId)
          ? activeCheckboxCardId.includes(id)
          : id === activeCheckboxCardId
        : isSelected,
    [activeCheckboxCardId, id, isSelected]
  );

  return (
    <Container
      className={className}
      disabled={isDisabled}
      selectBoxLeft={selectBoxLeft}
      isActive={isActive}
      onClick={handleOnClick}
    >
      <Header $selectBoxLeft={selectBoxLeft}>
        {selectBoxLeft ? <StyledCheckboxField checked={isActive} color="waterBlue" /> : null}
        {title}
        {!selectBoxLeft ? <StyledCheckboxField checked={isActive} color="waterBlue" /> : null}
      </Header>
      {children ? <MainContainer>{children}</MainContainer> : null}
    </Container>
  );
};

export default CheckboxCard;
