import { useMemo, forwardRef, Children } from 'react';
import styled from 'styled-components';

import TableHeader from './tableHeader';
import TableHeaderCell from './tableHead';
import TableRow from './tableRow';
import TableValue from './tableValue';
import TableLoadingIndicator from './tableLoadingIndicator';
import TableErrorMessage from './tableErrorMessage';
import TableMessage from './tableMessage';

const Container = styled.div`
  display: grid;
  width: 100%;
`;

const GridTable = forwardRef(({ children, className }, ref) => {
  const hasTitle = useMemo(
    () =>
      !!Children.toArray(children).find(
        (child) => child?.type?.displayName === 'TableTitle'
      ),
    [children]
  );

  return (
    <Container ref={ref} className={className} hasTitle={hasTitle}>
      {children}
    </Container>
  );
});

GridTable.Header = TableHeader;
GridTable.Head = TableHeaderCell;
GridTable.Row = TableRow;
GridTable.Value = TableValue;
GridTable.Message = TableMessage;
GridTable.LoadingIndicator = TableLoadingIndicator;
GridTable.ErrorMessage = TableErrorMessage;

export default GridTable;
