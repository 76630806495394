import { useTranslation } from 'react-i18next';

import { Button } from '../../common';
import { Center, Title, ButtonGroup, Description, MaxWidth, Paper, Icon, Cross } from './styles';

const Failure = ({ title, description, onAgain, onCancel }) => {
  const { t } = useTranslation();

  return (
    <Center>
      <MaxWidth>
        <Paper>
          <Icon>
            <Cross />
          </Icon>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </Paper>
        <ButtonGroup>
          <Button onClick={onAgain} title={t('common:button.tryAgain')}>
            {t('common:button.tryAgain')}
          </Button>
          <Button emphasis="outline" onClick={onCancel} title={t('common:button.title.cancel')}>
            {t('common:button.cancel')}
          </Button>
        </ButtonGroup>
      </MaxWidth>
    </Center>
  );
};

export default Failure;
