import { useMemo } from 'react';
import styled from 'styled-components';

import {
  determineAddonCategoriesToShow,
  ErrorMessage,
  LoadingIndicator,
} from '../../../../common';
import { usePurchase } from '../purchaseContext';
import { AddonCard } from '../cards';
import NotAvailableAddonCard from '../cards/notAvailableAddonCard';

const Addons = styled.div`
  display: grid;
  gap: 1.2rem;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-bottom: 1rem;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin-bottom: 1rem;
`;

const AddonsStep = () => {
  const {
    availableAddons,
    addonsLoading,
    addonsError,
    handleSelectAddon,
    userInput: { selectedAddons },
  } = usePurchase();

  const addonsToShow = useMemo(
    () => determineAddonCategoriesToShow(availableAddons),
    [availableAddons]
  );

  return (
    <>
      {addonsError ? <StyledErrorMessage error={addonsError} /> : null}
      {addonsLoading ? <StyledLoadingIndicator /> : null}
      <Addons>
        {addonsToShow.map((addon) =>
          addon.saleOk ? (
            <AddonCard
              key={addon.id}
              addon={addon}
              handleSelectAddon={handleSelectAddon}
              isSelected={selectedAddons.find(
                (selectedAddon) => selectedAddon.id === addon.id
              )}
            />
          ) : (
            <NotAvailableAddonCard key={addon.id} addon={addon} />
          )
        )}
      </Addons>
    </>
  );
};

export default AddonsStep;
