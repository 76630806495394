export const calculateCheapestPricePerMonthForColor = (colorId, productCatalogue, selectedMusicLicenseId) => {
  const availableProducts = productCatalogue.filter(
    (product) => product.musicLicense.id === selectedMusicLicenseId && product.color.id === colorId
  );

  const productPricesPerMonth = availableProducts.map(
    (product) => product.price / product.subscriptionTemplate.recurringInterval
  );

  const cheapestPricePerMonth = productPricesPerMonth.reduce((acc, curr) => (curr < acc ? curr : acc));

  return cheapestPricePerMonth.toFixed(2);
};
