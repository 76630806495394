import { useCallback } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { useMediaSize } from '../../../../common';
import { Song } from './song';
import { PlaylistTypes } from '../types';
import { usePlaylists } from '../playlistsProvider';

export const DraggableSong = ({ id, selectedPlaylistType, song, ...props }) => {
  const { isNarrowerThanMedium } = useMediaSize();

  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    data: {
      songId: song?.songId,
    },
    disabled: isNarrowerThanMedium,
  });

  const { dispatch, selectedSongs, stopAudio } = usePlaylists();

  const onClickSong = useCallback(() => {
    if (selectedSongs.find((s) => s.id === song.id)) {
      dispatch({
        selectedSongs: selectedSongs.filter((s) => s.id !== song.id),
        isSongSelectionActivated:
          selectedSongs.filter((s) => s.id !== song.id).length !== 0,
      });
    } else {
      dispatch({
        selectedSongs: [...selectedSongs, song],
        isSongSelectionActivated: true,
      });
    }
    stopAudio();
  }, [dispatch, song, selectedSongs, stopAudio]);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Song
      {...props}
      ref={setNodeRef}
      ghost={isDragging}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      onClickSong={onClickSong}
      song={song}
      style={selectedPlaylistType === PlaylistTypes.CUSTOM ? style : null}
    />
  );
};
