import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  GridTable,
  formatDate,
  getProductSubscriptionLineOfSubscriptionLines,
  useMediaSize,
} from '../../../common';

const RowContainer = styled(GridTable.Row)`
  margin: 0 1.2rem;
  padding: 0.8rem;
  border-radius: unset;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.stroke};

  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: 'name status' 'subscription status';

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    grid-auto-flow: row;
    grid-template-columns: 2fr 2fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: 'name subscription status' 'name endDate status';
  }
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    grid-auto-flow: row;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
    grid-template-areas: 'creationDate name subscription endDate status';
  }
`;

const Status = styled(GridTable.Value)`
  grid-area: status;
  color: ${({ theme }) => theme.dark300};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.143;
`;

const Name = styled(GridTable.Value)`
  grid-area: name;
  color: ${({ theme }) => theme.dark500};
  font-family: 'Inter', sans-serif;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.5;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    color: ${({ theme }) => theme.dark300};
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.143;
  }
`;

const Subscription = styled(GridTable.Value)`
  grid-area: subscription;
  color: ${({ theme }) => theme.dark300};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.143;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    color: ${({ theme }) => theme.dark400};
  }
`;

const CreationDate = styled(GridTable.Value)`
  grid-area: creationDate;
  color: ${({ theme }) => theme.dark300};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.143;
`;
const EndDate = styled(GridTable.Value)`
  grid-area: endDate;
  color: ${({ theme }) => theme.dark300};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.143;
`;

const ReferredCustomerTableRow = ({ referredCustomer }) => {
  const { t, i18n } = useTranslation();

  const { isWiderThanLarge, isWiderThanMedium } = useMediaSize();

  const name = useMemo(
    () => `${referredCustomer.location.name} - ${referredCustomer.name}`,
    [referredCustomer]
  );

  console.log(referredCustomer);

  const subscription = useMemo(() => {
    if (referredCustomer.currentSubscription) {
      const productSubscriptionLine =
        getProductSubscriptionLineOfSubscriptionLines(
          referredCustomer.currentSubscription?.subscriptionLines
        );
      return productSubscriptionLine?.product.name;
    }
    const productSubscriptionLine =
      getProductSubscriptionLineOfSubscriptionLines(
        referredCustomer.lastSubscription?.subscriptionLines
      );
    return productSubscriptionLine?.product.name;
  }, [referredCustomer]);

  const statusText = useMemo(() => {
    if (referredCustomer.currentSubscription) {
      return t('partner:referredCustomers.table.active');
    }
    return t('partner:referredCustomers.table.inactive');
  }, [referredCustomer, t]);

  return (
    <RowContainer>
      {isWiderThanLarge && (
        <CreationDate>
          {formatDate(referredCustomer.createDate, i18n.language)}
        </CreationDate>
      )}
      <Name>{name}</Name>
      <Subscription>{subscription}</Subscription>
      {isWiderThanMedium && (
        <EndDate>
          {formatDate(referredCustomer.lastSubscription.endDate, i18n.language)}
        </EndDate>
      )}
      <Status>{statusText}</Status>
    </RowContainer>
  );
};

export default ReferredCustomerTableRow;
