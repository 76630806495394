import { useMemo, useCallback, useState } from 'react';
import styled from 'styled-components';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';

import { SaleOrderContext } from './saleOrderContext';
import { GET_ORGANIZATION_AND_SALE_ORDER, ADD_ITEMS_TO_CART, REMOVE_ITEMS_FROM_CART } from './api';
import { LoadingIndicator, ErrorMessage } from '../../../common';
import { determineSubCreatePolicyForCartItem } from './utils';

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin-top: 3rem;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 3rem;
`;

const SaleOrderProvider = ({ children }) => {
  const { organizationId, saleOrderId } = useParams();

  const [initialSaleOrder, setInitialSaleOrder] = useState(null);
  // We don't modify the initial sale order,
  // all changes are made to the altered sale order.
  const [alteredSaleOrder, setAlteredSaleOrder] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  const [addItemsToCart, { loading: addItemsToCartLoading, error: addItemsToCartError }] = useMutation(
    ADD_ITEMS_TO_CART,
    {
      onCompleted: ({ addItemsToCart: addItemsToCartResult }) => {
        setAlteredSaleOrder(addItemsToCartResult);
      },
    }
  );

  const {
    data: pageData,
    loading: pageDataLoading,
    error: pageDataError,
  } = useQuery(GET_ORGANIZATION_AND_SALE_ORDER, {
    variables: { organizationId, saleOrderId },
    onCompleted: ({ saleOrder }) => {
      setInitialSaleOrder(saleOrder);
      // Make a copy of the initial sale order and store the result
      // in the alteredSaleOrder variable.
      addItemsToCart({
        variables: {
          organizationId,
          cartItems: saleOrder.saleOrderLines.map((saleOrderLine) => ({
            locationId: Number(saleOrderLine.location.id),
            zoneId: Number(saleOrderLine.zoneWithDetailedSubscriptions.id),
            productId: Number(saleOrderLine.product.id),
            productQuantity: 1,
            ...determineSubCreatePolicyForCartItem(saleOrderLine.zoneWithDetailedSubscriptions),
          })),
        },
      });
    },
  });

  const [removeItemsFromCart, { loading: removeItemsFromCartLoading, error: removeItemsFromCartError }] = useMutation(
    REMOVE_ITEMS_FROM_CART,
    {
      onCompleted: (data) => {
        setAlteredSaleOrder(data.removeItemsFromCart);
      },
    }
  );

  const handleRemoveItemFromCart = useCallback(
    (cartItemId) => {
      removeItemsFromCart({
        variables: {
          organizationId,
          cartId: alteredSaleOrder.id,
          cartItemIds: [cartItemId],
        },
      });
    },
    [removeItemsFromCart, organizationId, alteredSaleOrder]
  );

  const handleAddItemToCart = useCallback(
    (cartItem) => {
      addItemsToCart({
        variables: {
          organizationId,
          cartId: alteredSaleOrder.id,
          cartItems: [cartItem],
        },
      });
    },
    [addItemsToCart, organizationId, alteredSaleOrder]
  );

  const contextValue = useMemo(
    () => ({
      organization: pageData?.organization,
      initialSaleOrder,
      alteredSaleOrder,
      addItemToCart: handleAddItemToCart,
      removeItemFromCart: handleRemoveItemFromCart,
      cartIsChanging: addItemsToCartLoading || removeItemsFromCartLoading,
      cartChangeHasError: addItemsToCartError || removeItemsFromCartError,
      setSelectedPaymentMethod,
      selectedPaymentMethod,
    }),
    [
      pageData,
      handleAddItemToCart,
      handleRemoveItemFromCart,
      addItemsToCartLoading,
      removeItemsFromCartLoading,
      addItemsToCartError,
      removeItemsFromCartError,
      initialSaleOrder,
      setSelectedPaymentMethod,
      selectedPaymentMethod,
      alteredSaleOrder,
    ]
  );

  const content = useMemo(() => {
    if (pageDataError) return <StyledErrorMessage withPadding error={pageDataError} />;
    if (pageDataLoading || !alteredSaleOrder) return <StyledLoadingIndicator />;
    return children;
  }, [pageDataError, pageDataLoading, children, alteredSaleOrder]);

  return <SaleOrderContext.Provider value={contextValue}>{content}</SaleOrderContext.Provider>;
};

export default SaleOrderProvider;
