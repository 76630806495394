import styled from 'styled-components';

import { ReactComponent as LogoDark } from '../../assets/icons/logoDark.svg';

import { LoadingIndicator } from '../../common';

const Center = styled.div`
  width: 100%;
  height: 100vh;
  @supports (height: 100svh) {
    height: 100svh;
  }
  display: grid;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
`;

const StyledLogo = styled(LogoDark)`
  margin-bottom: 2rem;
`;

const LoadingPage = () => (
  <Center>
    <Wrapper>
      <StyledLogo width="16rem" height="6rem" />
      <LoadingIndicator size="large" thickness="medium" />
    </Wrapper>
  </Center>
);

export default LoadingPage;
