import styled from 'styled-components';

import { useSaleOrder } from '../../saleOrderContext';

const Container = styled.div`
  display: none;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    width: 100%;
    background-color: ${({ theme }) => theme.dark500};
    border-radius: 4px;
    padding: 2.4rem;
    display: grid;
    gap: 2rem;
  }
`;

const MobileContainer = styled.div`
  display: grid;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    display: none;
  }
`;

const Title = styled.h4`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.dark500};
  font-weight: 600;
  line-height: 1.2;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    color: ${({ theme }) => theme.white};
  }
`;

const OrganizationDetails = () => {
  const { organization } = useSaleOrder();

  return (
    <>
      <Container>
        <Title>{organization?.name}</Title>
      </Container>
      <MobileContainer>
        <Title>{organization?.name}</Title>
      </MobileContainer>
    </>
  );
};

export default OrganizationDetails;
