import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Modal } from '../../../common';
import { useAuth } from '../../../global/auth/newAuthProvider';

const ModalDescription = styled.p`
  color: ${({ theme }) => theme.dark300};
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.4;
`;

const EarlySignOutModal = ({ cancelLabel, confirmLabel, isOpen, onCancel, title }) => {
  const { t } = useTranslation();
  const { logout } = useAuth();

  const confirm = useCallback(() => logout(), [logout]);

  return (
    <Modal
      cancelHighlightColor="nightfall"
      cancelText={cancelLabel}
      confirmHighlightGradient="nightfall"
      confirmText={confirmLabel}
      isOpen={isOpen}
      onCancel={onCancel}
      onConfirm={confirm}
      title={title}
    >
      <ModalDescription>{t('common:signOut.early.description')}</ModalDescription>
    </Modal>
  );
};

export const useEarlySignOutModal = ({ title, confirmLabel, cancelLabel }) => {
  const [isOpen, setIsOpen] = useState(false);
  const openEarlySignOutModal = useCallback(() => {
    setIsOpen(true);
  }, []);
  const closeEarlySignOutModal = useCallback(() => {
    setIsOpen(false);
  }, []);
  return {
    openEarlySignOutModal,
    earlySignOutModal: (
      <EarlySignOutModal
        cancelLabel={cancelLabel}
        confirmLabel={confirmLabel}
        isOpen={isOpen}
        onCancel={closeEarlySignOutModal}
        title={title}
      />
    ),
  };
};
