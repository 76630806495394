import {
  determineAvailableAddonCategoriesForZone,
  getProductSubscriptionLineOfSubscriptionLines,
} from '../../../../common';
import { getAddonCategories } from './getAddonCategories';

const getAddonIdsForZones = (selectedZones, selectedAddons, addonCategories) => {
  const addonIdsForZones = selectedZones.reduce((acc, curr) => {
    const productOfZone = getProductSubscriptionLineOfSubscriptionLines(
      curr.currentSubscription.subscriptionLines
    ).product;

    const availableAddonCategories = determineAvailableAddonCategoriesForZone(curr, selectedAddons);

    const addonIds = availableAddonCategories.map((addonCategory) => {
      const addonCategoryOptions = addonCategories[addonCategory.shortName];
      const addonCategoryOption = addonCategoryOptions.find(
        (addon) => addon.subscriptionTemplate.id === productOfZone.subscriptionTemplate.id
      );
      return addonCategoryOption.id;
    });

    return { ...acc, ...{ [curr.id]: addonIds } };
  }, {});

  return addonIdsForZones;
};

export const buildCartItems = (selectedZones, selectedAddons, availableAddons) => {
  const addonCategories = getAddonCategories(availableAddons);

  const addonIdsForZones = getAddonIdsForZones(selectedZones, selectedAddons, addonCategories);

  const cartItems = selectedZones.map((zone) => {
    const addonsForZones = addonIdsForZones[zone.id].map((addonId) => {
      return {
        zoneId: Number(zone.id),
        locationId: Number(zone.location.id),
        productId: Number(addonId),
        productQuantity: 1,
        subCreatePolicy: 'upsell',
        originSubscriptionId: Number(zone.currentSubscription.id),
      };
    });

    return addonsForZones;
  });

  return cartItems.flat();
};
