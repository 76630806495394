// The blue one
const PRIMARY = '#04B4E0';
const PRIMARY_HOVER = '#1A8BA7';
const PRIMARY_FOCUS = '#1A5563';
const PRIMARY_ACTIVE = '#1A5563';

// The green one
const SECONDARY = '#7BBA58';
const SECONDARY_HOVER = '#598242';
const SECONDARY_FOCUS = '#395529';
const SECONDARY_ACTIVE = '#395529';

// The orange one
const WARNING = '#F55325';
const WARNING_HOVER = '#B33714';
const WARNING_FOCUS = '#6C1717';
const WARNING_ACTIVE = '#6C1717';

// Tertiary
const TERTIARY = '#F4F6FC';
const TERTIARY_HOVER = '#DDE2F1';
const TERTIARY_HOVER_COLOR = '#385C96';
const TERTIARY_ACTIVE = '#4A75BB';
const TERTIARY_DISABLED = '#F4F6FC';
const TERTIARY_LINK_EMPHASIS = '#2E384D';

const DISABLED = '#BFC5D2';

const LIGHT_GREY = '#BFC5D2';
const WHITE = '#ffffff';

const colors = {
  primary: {
    flat: `
      background-color: ${PRIMARY};
      border: none;
      color: ${WHITE};

      &:hover {
        background-color: ${PRIMARY_HOVER};
        border: none;
        color: ${WHITE};
      }

      &:focus {
        background-color: ${PRIMARY_FOCUS};
        border: none;
        color: ${WHITE};
      }

      &:active{
        background-color: ${PRIMARY_ACTIVE};
        border: none;
        color: ${WHITE};
      }

      &:disabled {
        background-color: ${DISABLED};
        border: none;
        color: ${WHITE};
      }
    `,
    icon: `
      background: none;
      border: 1px solid transparent;
      & path {
        transition: 250ms linear;
      }

      &:hover, &:focus {
        border: 1px solid ${WHITE};
      }

      &:active {
        background-color: ${WHITE};
        & path {
          fill: ${PRIMARY};
        }
      }
    `,
    outline: `
      background: none;
      border: 1px solid ${PRIMARY};
      color: ${PRIMARY};

      &:hover {
        background: none;
        border: 1px solid ${PRIMARY_HOVER};
        color: ${PRIMARY_HOVER};
      }

      &:focus {
        background: none;
        border: 1px solid ${PRIMARY_FOCUS};
        color: ${PRIMARY_FOCUS};
      }

      &:active {
        background: none;
        border: 1px solid ${PRIMARY_ACTIVE};
        color: ${PRIMARY_ACTIVE};
      }

      &:disabled {
        background: none;
        border: 1px solid ${DISABLED};
        color: ${DISABLED};
      }
    `,
  },
  secondary: {
    flat: `
      background-color: ${SECONDARY};
      border: none;
      color: ${WHITE};

      &:hover {
        background-color: ${SECONDARY_HOVER};
        border: none;
        color: ${WHITE};
      }

      &:focus {
        background-color: ${SECONDARY_FOCUS};
        border: none;
        color: ${WHITE};
      }

      &:active {
        background-color: ${SECONDARY_ACTIVE};
        border: none;
        color: ${WHITE};
      }

      &:disabled {
        background-color: ${DISABLED};
        border: none;
        color: ${WHITE};
      }
    `,
    icon: `
      background: none;
      border: 1px solid transparent;
      & path {
        transition: 250ms linear;
      }

      &:hover, &:focus {
        border: 1px solid ${LIGHT_GREY};
        & path {
          fill: ${PRIMARY};
        }
      }

      &:active {
        background-color: ${PRIMARY};
        & path {
          fill: ${WHITE};
        }
      }
    `,
    link: `
      background: none;
      border: none;
      color: ${SECONDARY};

      &:hover {
        background: none;
        border: none;
        color: ${SECONDARY_HOVER};

        &::before {
          background: ${SECONDARY_HOVER};
        }
      }

      &:focus {
        background: none;
        border: none;
        color: ${SECONDARY_FOCUS};

        &::before {
          background: ${SECONDARY_ACTIVE};
        }
      }

      &:active {
        background: none;
        border: none;
        color: ${SECONDARY_ACTIVE};
      }

      &:disabled {
        background: none;
        border: none;
        color: ${DISABLED};

        &::before {
          background: ${DISABLED};
        }
      }

      &::before {
        background: ${SECONDARY};
      }
    `,
    outline: `
      background: none;
      border: 1px solid ${SECONDARY};
      color: ${SECONDARY};

      &:hover {
        background: none;
        border: 1px solid ${SECONDARY_HOVER};
        color: ${SECONDARY_HOVER};
      }

      &:focus {
        background: none;
        border: 1px solid ${SECONDARY_FOCUS};
        color: ${SECONDARY_FOCUS};
      }

      &:active {
        background: none;
        border: 1px solid ${SECONDARY_ACTIVE};
        color: ${SECONDARY_ACTIVE};
      }

      &:disabled {
        background: none;
        border: 1px solid ${DISABLED};
        color: ${DISABLED};
      }
    `,
  },
  tertiary: {
    flat: `
      background-color: ${TERTIARY};
      border: none;
      font-weight: 700;
      color: ${TERTIARY_ACTIVE};
      letter-spacing: 0;

      &:hover {
        background-color: ${TERTIARY_HOVER};
        border: none;
        font-weight: 700;
        color: ${TERTIARY_HOVER_COLOR};
      }

      &:active {
        background-color: ${TERTIARY_ACTIVE};
        border: none;
        font-weight: 700;
        color: ${WHITE};
      }

      &:disabled {
        background-color: ${TERTIARY_DISABLED};
        border: none;
        font-weight: 700;
        color: ${DISABLED};
      }
    `,
    link: `
      background: none;
      border: none;
      color: ${TERTIARY_ACTIVE};

      &:hover {
        background: none;
        border: none;
        color: ${TERTIARY_LINK_EMPHASIS};
      }

      &:focus {
        background: none;
        border: none;
        color: ${TERTIARY_LINK_EMPHASIS};
      }

      &:active {
        background: none;
        border: none;
        color: ${TERTIARY_LINK_EMPHASIS};
      }

      &:disabled {
        background: none;
        border: none;
        color: ${DISABLED};

        &::before {
          background: ${DISABLED};
        }
      }

      &::before {
        background: ${PRIMARY};
      }
    `,
  },

  warning: {
    flat: `
      background-color: ${WARNING};
      border: none;
      color: ${WHITE};

      &:hover {
        background-color: ${WARNING_HOVER};
        border: none;
        color: ${WHITE};
      }

      &:focus {
        background-color: ${WARNING_FOCUS};
        border: none;
        color: ${WHITE};
      }

      &:active {
        background-color: ${WARNING_ACTIVE};
        border: none;
        color: ${WHITE};
      }

      &:disabled {
        background-color: ${DISABLED};
        border: none;
        color: ${WHITE};
      }
    `,
    outline: `
      background: none;
      border: 1px solid ${WARNING};
      color: ${WARNING};

      &:hover {
        background: none;
        border: 1px solid ${WARNING_HOVER};
        color: ${WARNING_HOVER};
      }

      &:focus {
        background: none;
        border: 1px solid ${WARNING_FOCUS};
        color: ${WARNING_FOCUS};
      }

      &:active {
        background: none;
        border: 1px solid ${WARNING_ACTIVE};
        color: ${WARNING_ACTIVE};
      }

      &:disabled {
        background: none;
        border: 1px solid ${DISABLED};
        color: ${DISABLED};
      }
    `,
    link: `
      background: none;
      border: none;
      color: ${WARNING};

      &:hover {
        background: none;
        border: none;
        color: ${WARNING_HOVER};

        &::before {
          background: ${WARNING_HOVER};
        }
      }

      &:focus {
        background: none;
        border: none;
        color: ${WARNING_FOCUS};

        &::before {
          background: ${WARNING_FOCUS};
        }
      }

      &:active {
        background: none;
        border: none;
        color: ${WARNING_ACTIVE};
      }

      &:disabled {
        background: none;
        border: none;
        color: ${DISABLED};

        &::before {
          background: ${DISABLED};
        }
      }

      &::before {
        background: ${WARNING};
      }
    `,
  },
};

export default colors;
