import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Modal, ErrorMessage, LoadingIndicator, CheckboxField } from '../../..';

import ZoneRow from './zoneRow';

const Instructions = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.dark300};
  margin: 1rem 0;
`;

const Header = styled.div`
  display: grid;
  justify-content: end;
  padding-right: 1.8rem;
`;

const NoZonesMessage = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.dark300};
  line-height: 1.4;
`;

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  gap: 1rem;
`;

const ZoneSelectionModal = ({
  isOpen,
  zones = [],
  onConfirm,
  onClose,
  isLoading,
  error,
  title,
  instructions,
  defaultSelected,
  actionIsLoading,
  isMulti = false,
  noZonesMessage,
}) => {
  const { t } = useTranslation();

  const [validationError, setValidationError] = useState(false);

  const defaultValue = useMemo(
    () => (isMulti && !defaultSelected ? [] : defaultSelected),
    [isMulti, defaultSelected]
  );

  const [selected, setSelected] = useState(defaultValue);

  useEffect(() => {
    if (defaultSelected) {
      setSelected(defaultSelected);
    }
  }, [defaultSelected]);

  const toggleAllZonesCheckBoxes = useCallback(() => {
    setSelected((prev) =>
      prev.length !== zones.length || prev.length === 0 ? zones : []
    );
  }, [zones, setSelected]);

  const handleConfirm = useCallback(() => {
    if (selected.length === 0) {
      setValidationError(true);
      return;
    }
    setValidationError(false);
    onConfirm(selected);
  }, [onConfirm, selected]);

  const handleClose = useCallback(() => {
    if (defaultValue) {
      setSelected(defaultValue);
    }
    onClose();
  }, [onClose, defaultValue]);

  const handleClickZone = useCallback(
    (id) => {
      if (isMulti) {
        setSelected((prev) =>
          prev.some((zone) => zone.id === id)
            ? prev.filter((zone) => zone.id !== id)
            : [...prev, zones.find((zone) => zone.id === id)]
        );
      } else {
        setSelected(zones.find((zone) => zone.id === id));
      }
    },
    [zones, isMulti, setSelected]
  );

  const isChecked = useMemo(
    () => isMulti && zones.length > 0 && selected.length === zones.length,
    [isMulti, selected, zones]
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={title}
      onConfirm={handleConfirm}
      onCancel={handleClose}
      confirmText={t('common:button.confirm')}
      confirmHighlightGradient="nightfall"
      cancelHighlightColor="lavenderPurple"
      requestLoading={actionIsLoading}
    >
      {zones && zones.length > 0 && <Instructions>{instructions}</Instructions>}
      {error ? <ErrorMessage error={error} /> : null}

      {isLoading ? <LoadingIndicator /> : null}
      {zones.length > 0 ? (
        <>
          {isMulti ? (
            <Header>
              <CheckboxField
                onClick={toggleAllZonesCheckBoxes}
                checked={isChecked}
                reverse
                color="waterBlue"
                label={t('integrations:spotify.import.thAllZones')}
              />
            </Header>
          ) : null}
          <Wrapper>
            {zones.map((zone) => (
              <ZoneRow
                key={zone.id}
                isMulti={isMulti}
                onClickZone={handleClickZone}
                selected={selected}
                zone={zone}
              />
            ))}
          </Wrapper>
        </>
      ) : (
        <NoZonesMessage>
          {noZonesMessage ??
            t('integrations:spotify.import.zoneSelectionModalNoZones')}
        </NoZonesMessage>
      )}
      {validationError ? (
        <ErrorMessage error={t('musicManagement:no.zone.selected')} />
      ) : null}
    </Modal>
  );
};

export default ZoneSelectionModal;
