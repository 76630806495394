import { forwardRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import PlaylistRow from './playlistRow';
import { LoadingIndicator, ErrorMessage } from '../../../../../common';
import { useSpotifyImport } from '../../spotifyImportContext';

const Container = styled.div`
  display: grid;
  gap: 0.4rem;
`;

const Description = styled.p`
  justify-self: center;
  padding: 2rem 0 4rem 0;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.dark300};
  line-height: 1.4;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin: 1rem;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  width: 100%;
`;

const PlaylistBody = forwardRef(({ playlistStatusesByIds }, ref) => {
  const { t } = useTranslation();
  const { playlists, playlistsLoading, playlistsError, playlistsLoadingMore } = useSpotifyImport();

  if (playlistsLoading && playlists.length === 0) {
    return <StyledLoadingIndicator />;
  }

  if (playlists.length === 0 && playlistsError) {
    return <StyledErrorMessage error={playlistsError} />;
  }

  return (
    <Container ref={ref}>
      {playlists.length === 0 ? <Description>{t('integrations:spotify.import.tbNoResults')}</Description> : null}
      {playlists.map((playlist) => (
        <PlaylistRow key={playlist.id} playlist={playlist} playlistStatusesByIds={playlistStatusesByIds} />
      ))}
      {playlistsError ? <StyledErrorMessage error={playlistsError} /> : null}
      {playlistsLoadingMore ? <StyledLoadingIndicator /> : null}
    </Container>
  );
});

export default PlaylistBody;
