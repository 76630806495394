import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import theme from '../../global/style/theme';

const StyledNavLink = styled(NavLink)`
  height: 3.2rem;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.dark500};
  padding-left: 0.8rem;

  & > p {
    text-decoration: none;
    font-size: 1.4rem;
    font-weight: 500;
    padding-left: 1.2rem;
    color: ${({ theme }) => theme.dark100};
    transition: color 0.2s ease-in-out;
  }

  &.active {
    & > p {
      color: ${({ theme }) => theme.white};
    }
    & > div {
      background-color: ${({ theme }) => theme.white};
      border-color: ${({ theme }) => theme.white};
    }
  }

  &:hover {
    & > p {
      color: ${({ theme }) => theme.white};
    }
    & > div {
      border-color: ${({ theme }) => theme.white};
    }
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    justify-content: center;
    padding-left: 0;
    & > p {
      display: none;
    }
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.extraLarge}px) {
    justify-content: flex-start;
    padding-left: 0.8rem;
    & > p {
      display: block;
    }
  }
`;

const IconWrapper = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.dark300};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: ${({ theme }) => theme.dark200};
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
`;

const NavItem = ({ text, Icon, show = true, ...props }) =>
  show ? (
    <StyledNavLink {...props}>
      <IconWrapper>
        <Icon color={theme.dark300} width="1.6rem" height="1.6rem" />
      </IconWrapper>
      <p>{text}</p>
    </StyledNavLink>
  ) : null;

export default NavItem;
