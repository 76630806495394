import styled from 'styled-components';
import { useCallback } from 'react';

import theme from '../../global/style/theme';

const Container = styled.div`
  display: inline;
  position: relative;
  & > span {
    background-color: ${theme.dark600};
    color: ${theme.white};
    border-radius: 8px;
    position: absolute;
    left: ${({ $isLeft }) => $isLeft ? 'unset' : '100%'};
    right: ${({ $isLeft }) => $isLeft ? '100%' : 'unset'};
    top: -50%;
    transition: all 0.2s ease-out;
    transform: scale(0);
    transform-origin: ${({ $isLeft }) => $isLeft ? 'right' : 'left'} center;
    padding: 1rem 1rem;
    width: max-content;
    max-width: calc(100vw - 10rem);
    z-index: 100;
    line-height: 1.2;
  }
  &:hover {
    & > span {
      transform: scale(1);
    }
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    & > span {
      max-width: 20rem;
    }
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.superLarge}px) {
    & > span {
      max-width: 30rem;
    }
  }
`;
const Tooltip = ({
  label,
  children,
  className,
  preventClickThrough,
  direction = 'left',
}) => {
  const prevent = useCallback((event) => {
    if (preventClickThrough) {
      event.stopPropagation();
    }
  }, [preventClickThrough]);
  return (
    <Container
      className={className}
      onClick={prevent}
      $isLeft={direction === 'left'}
    >
      <span>{label}</span>
      {children}
    </Container>
  );
};
export default Tooltip;
