import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { Button, capitalize } from '..';
import theme from '../../global/style/theme';
import { ReactComponent as RawPlayIcon } from '../../assets/icons/simplePlayControls/play.svg';

const PlayerButton = styled(Button)`
  height: 3.8rem;
  padding-left: 0.4rem;
  display: grid;
  grid-template-columns: min-content 1fr;
  border-width: 1px;
  white-space: nowrap;
  color: ${({ theme }) => theme.dark300};
  border-color: ${({ theme }) => theme.dark300};

  ${({ isLoading }) =>
    isLoading &&
    css`
      padding: 0 1.2rem;
    `}

  &:hover {
    border-width: 1px;
    color: ${({ theme }) => theme.white};
    border-color: ${({ theme }) => theme.dark100};
  }
`;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.waterBlue};
  margin-right: 1rem;
  min-height: 2.8rem;
  min-width: 2.8rem;
`;

const PlayIcon = styled(RawPlayIcon)`
  width: 1rem;
  height: 1rem;
  margin-left: 0.15rem;
`;

const WidePlayerButton = ({ className, isLoading, onClick }) => {
  const { t } = useTranslation();

  return (
    <PlayerButton
      className={className}
      emphasis="outline"
      size="medium"
      title={capitalize(
        t('common:button.title.goTo', {
          value: t('common:button.title.player'),
        })
      )}
      onClick={onClick}
      isLoading={isLoading}
    >
      <Circle>
        <PlayIcon color={theme.waterBlue} />
      </Circle>
      {t('common:button.goToPlayer')}
    </PlayerButton>
  );
};

export default WidePlayerButton;
