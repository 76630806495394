const production = {
  BASE_URL: 'https://my.tunify.com',
  API_BASE_URL: 'https://mytunify-api.tunify.com/graphql',
  AUTH0_DOMAIN: 'auth.tunify.com',
  AUTH0_AUDIENCE: 'https://my.tunify.com/api',
  AUTH0_CLIENT_ID: 'ewfMbbSrAqSpo8xN9cY7kRm6XEN45aY9',
  AUTH0_CONNECTION: 'Username-Password-Authentication',
  // Language
  LANGUAGE_COOKIE_KEY_EXTERNAL: 'www_user_lang', // Suggestion made by www.tunify.com
  LANGUAGE_COOKIE_KEY_INTERNAL: 'tunify_user_lang', // Managed by my.tunify.com and what actually gets applied
  LANGUAGE_IS_EXPLICITLY_SET_COOKIE_KEY: 'is_www_user_region_set',
  LANGUAGE_COOKIE_DOMAIN: '.tunify.com',
  LANGUAGE_FALLBACK: 'en-US',
  LANGUAGE_COOKIE_MAX_AGE: 2592000, // 1 month (based on the max age of the Tunify Language Webhook)
  // Intercom
  INTERCOM_APP_ID: 'bh3blita',
  INTERCOM_SHOULD_INITIALIZE: true,
  // Ingenico
  INGENICO_ACTION: 'https://secure.ogone.com/ncol/prod/orderstandard_utf8.asp',
  // Google Maps API
  // INFO:
  // * GCP Project
  //    -Name: Mytunify
  //     ID: 279313
  // * Owner: Tunify - koen.vanderstraeten@tunify.com
  GOOGLE_MAPS_API_KEY: 'AIzaSyCYBqZ6_3VIQgiQJduQzWyUFbKaZInMFMA',
  GOOGLE_TAG_MANAGER_ID: 'GTM-MFFHJWFM',
  ENABLE_VIES_VAT_CHECK: false,
  ENABLE_EXTENDED_VAT_CHECK: true,
  PARTNER_REFERRAL_COOKIE_KEY: 'registration_code',
  PARTNER_REFERRAL_COOKIE_DOMAIN: '.tunify.com',
  PARTNER_REFERRAL_COOKIE_MAX_AGE: 2592000, // 30 days
  CUSTOMER_REFERRAL_COOKIE_KEY: 'customer_code',
  CUSTOMER_REFERRAL_COOKIE_DOMAIN: '.tunify.com',
  CUSTOMER_REFERRAL_COOKIE_MAX_AGE: 2592000, // 30 days,
  SPOTIFY_CLIENT_ID: '932f51aa74d24c209fdefb4dd0948b34',
  SPOTIFY_LOGIN_REDIRECT_URL:
    'https://my.tunify.com/integrations/spotify/import',
  SPOTIFY_BASE_URL: 'https://api.spotify.com/v1',
  ENABLE_GTM: true,
  IPSTACK_BASE_URL: 'https://api.ipstack.com',
  IPSTACK_API_KEY: '0a164b8f9b4ac3d1c579ff83137ae7e0',
  SHOW_MISSING_I18N_KEYS: false,
  SAVE_MISSING_I18N_KEYS: false,
  UPDATE_MISSING_I18N_KEYS: false,
  LOCIZE_PROJECT_ID: '8bf5fba2-8c74-4670-9292-0f1b6fed6334',
  LOCIZE_API_KEY: '',
  COMMISSION_REQUEST_DETAILS_EXTERNAL_PAGE:
    'https://tunify.odoo.com/web#id=PLACEHOLDER&action=480&active_id=2&model=account.move&view_type=form&menu_id=222',
  MESSAGES_ADDON_CATEGORY_ID: 7,
  USE_EXPERIMENTAL_MUSIC_MANAGEMENT_MODULE: true,
  SHOW_APP_VERSION: false,
};

export default production;
