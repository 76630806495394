import { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { formatDate, determineAvailableAddonCategoriesForZone, formatNumber } from '../../../../common';
import { calculatePriceForZone } from '../utils';

const ItemContainer = styled.div`
  display: grid;
  gap: 1rem;
`;

const ItemTitle = styled.p`
  font-size: 1.6rem;
  line-height: 1.4;
  font-weight: 600;
  color: ${({ theme }) => theme.white};

  & > span {
    color: ${({ theme }) => theme.dark300};
    font-weight: 500;
  }
`;

const Text = styled.p`
  font-size: 1.4rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.dark100};
`;

const RightText = styled(Text)`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.white};

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    justify-self: flex-end;
    white-space: nowrap;
  }
`;

const PurchaseRow = ({ zone, selectedAddons, availableAddons }) => {
  const { i18n } = useTranslation();

  const availableAddonCategories = useMemo(
    () => determineAvailableAddonCategoriesForZone(zone, selectedAddons),
    [zone, selectedAddons]
  );

  const price = useMemo(
    () => calculatePriceForZone(zone, selectedAddons, availableAddons),
    [zone, selectedAddons, availableAddons]
  );

  return (
    <ItemContainer>
      <ItemTitle>
        {zone.location.name} <span>| {zone.name}</span>
      </ItemTitle>
      <div>
        <Text>{availableAddonCategories.map((addon) => addon.shortName).join(', ')}</Text>
        <Text>{formatDate(zone.currentSubscription.endDate, i18n.language)}</Text>
      </div>
      <RightText>€ {formatNumber(price, i18n.language)}</RightText>
    </ItemContainer>
  );
};

export default PurchaseRow;
