import { useCallback, useMemo, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { GET_PARTNERS, GET_COMMISSION_INVOICES } from './api';
import { PartnerManagerContext } from './partnerManagerContext';

export const LIMIT = 4;

const PartnerManagerProvider = ({ children }) => {
  const [hasMorePartners, setHasMorePartners] = useState(true);
  const [fetchMorePartnersError, setFetchMorePartnersError] = useState(null);

  const [hasMoreCommissionInvoices, setHasMoreCommissionInvoices] = useState(true);
  const [fetchMoreCommissionInvoicesError, setFetchMoreCommissionInvoicesError] = useState(null);

  const {
    loading: partnersLoading,
    data: partnersData,
    error: partnersError,
    fetchMore: fetchMorePartners,
  } = useQuery(GET_PARTNERS, {
    notifyOnNetworkStatusChange: true,
    variables: { offset: 0, limit: LIMIT },
    fetchPolicy: 'cache-and-network',
  });

  const {
    loading: commissionInvoicesLoading,
    data: commissionInvoicesData,
    error: commissionInvoicesError,
    fetchMore: fetchMoreCommissionInvoices,
  } = useQuery(GET_COMMISSION_INVOICES, {
    notifyOnNetworkStatusChange: true,
    variables: { offset: 0, limit: LIMIT },
    fetchPolicy: 'cache-and-network',
  });

  const handleFetchMorePartners = useCallback(async () => {
    if (partnersError || fetchMorePartnersError) {
      // Don't refetch when an error occured
      return;
    }

    try {
      await fetchMorePartners({
        variables: {
          offset: partnersData.partners.length,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          setHasMorePartners(fetchMoreResult.partners.length === LIMIT);
          return {
            ...prev,
            partners: [...prev.partners, ...fetchMoreResult.partners],
          };
        },
      });
    } catch (e) {
      // Although fetchMore is the way to go for pagination,
      // it provides no easy to use mechanism for error handling.
      // You have to catch the error yourself and set an error state.
      setFetchMorePartnersError(e);
    }
  }, [fetchMorePartners, fetchMorePartnersError, partnersData, partnersError]);

  const handleFetchMoreCommissionInvoices = useCallback(async () => {
    if (commissionInvoicesError || fetchMoreCommissionInvoicesError) {
      // Don't refetch when an error occured
      return;
    }

    try {
      await fetchMoreCommissionInvoices({
        variables: {
          offset: commissionInvoicesData.commissionInvoices.length,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          setHasMoreCommissionInvoices(fetchMoreResult.commissionInvoices.length === LIMIT);
          return {
            ...prev,
            commissionInvoices: [...prev.commissionInvoices, ...fetchMoreResult.commissionInvoices],
          };
        },
      });
    } catch (e) {
      // Although fetchMore is the way to go for pagination,
      // it provides no easy to use mechanism for error handling.
      // You have to catch the error yourself and set an error state.
      setFetchMoreCommissionInvoicesError(e);
    }
  }, [fetchMoreCommissionInvoices, fetchMoreCommissionInvoicesError, commissionInvoicesData, commissionInvoicesError]);

  const contextValue = useMemo(
    () => ({
      partnersLoading,
      partners: partnersData?.partners,
      partnersError: partnersError || fetchMorePartnersError,
      commissionRequestsLoading: commissionInvoicesLoading,
      commissionRequests: commissionInvoicesData?.commissionInvoices,
      commissionRequestsError: commissionInvoicesError || fetchMoreCommissionInvoicesError,
      fetchMorePartners: handleFetchMorePartners,
      hasMorePartners,
      fetchMoreCommissionRequests: handleFetchMoreCommissionInvoices,
      hasMoreCommissionRequests: hasMoreCommissionInvoices,
    }),
    [
      partnersLoading,
      partnersData,
      partnersError,
      commissionInvoicesData,
      commissionInvoicesError,
      commissionInvoicesLoading,
      fetchMorePartnersError,
      hasMorePartners,
      handleFetchMorePartners,
      fetchMoreCommissionInvoicesError,
      hasMoreCommissionInvoices,
      handleFetchMoreCommissionInvoices,
    ]
  );

  return <PartnerManagerContext.Provider value={contextValue}>{children}</PartnerManagerContext.Provider>;
};

export default PartnerManagerProvider;
