import gql from 'graphql-tag';

const LANGUAGES = gql`
  query languages {
    languages {
      id
      code
      name
    }
  }
`;

const GET_ALL_ROLES = gql`
  query getAllRoles {
    allRoles {
      id
      name
    }
  }
`;

const GET_ME = gql`
  query getMe {
    me {
      id
      roles {
        id
        name
      }
      lang
      is_social
      firstName
      lastName
    }
  }
`;

const EDIT_USER = gql`
  mutation editUser(
    $user: EditUserInput!
    $userId: ID!
    $roleIds: [String!]!
    $editUserRoles: Boolean!
  ) {
    editUser(user: $user) {
      success
    }
    setUserRoles(userId: $userId, roleIds: $roleIds)
      @include(if: $editUserRoles)
  }
`;

const GET_USER_BY_ID = gql`
  query GetUserById($odooId: ID!) {
    getUserById(odooId: $odooId) {
      id
      firstName
      lastName
      emailAddress
      emailAddress
      lang
      phone
      registrationCountry
      is_social
    }
  }
`;

export { LANGUAGES, GET_ALL_ROLES, GET_ME, EDIT_USER, GET_USER_BY_ID };
