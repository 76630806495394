import { useCallback, useState, useMemo, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { useMutation } from '@apollo/react-hooks';

import { useSortByObjectField, CheckboxField } from '../../../../../common';
import { SelectablePlaylistButton } from '../../../shared';
import Calendar from './calendar';
import { UPDATE_CALENDAR_GROUP_VISIBILITY } from '../api';
import { useUpdateChannelGroupCacheAfterUpdate } from '../cache';

const Container = styled.div`
  display: grid;
  ${({ collapsed }) => collapsed && 'opacity: 0.7;'}
`;

const StyledSelectablePlaylistButton = styled(SelectablePlaylistButton)`
  padding: 0.85rem 0.5rem 0.85rem 1.5rem;

  &:after {
    width: 100%;
  }
`;

const Channels = ({ channel, musicProfile, calendars, openedSubCollapsible, setOpenedSubCollapsible }) => {
  const sortedCalendars = useSortByObjectField(calendars, 'name');

  const firstRender = useRef(true);

  const [isSelected, setIsSelected] = useState(channel?.properties?.visible ?? false);

  const areChildrenActive = useMemo(
    () => calendars.some((calendar) => calendar.properties.visible === true),
    [calendars]
  );

  const areAllChildrenActive = useMemo(
    () => calendars.every((calendar) => calendar.properties.visible === true),
    [calendars]
  );

  const handleUpdateChannelGroupCacheAfterUpdate = useUpdateChannelGroupCacheAfterUpdate({
    musicProfileId: musicProfile.tunifyMusicProfileUserId,
    calendarGroupId: channel.id,
    shouldUpdateChildren: false,
  });

  const handleUpdateChannelGroupWithChildrenCacheAfterUpdate = useUpdateChannelGroupCacheAfterUpdate({
    musicProfileId: musicProfile.tunifyMusicProfileUserId,
    calendarGroupId: channel.id,
    shouldUpdateChildren: true,
  });

  const [updateCalendarGroupVisibility] = useMutation(UPDATE_CALENDAR_GROUP_VISIBILITY, {
    update: handleUpdateChannelGroupCacheAfterUpdate,
    onCompleted: (data) => {
      setIsSelected(data?.updateCalendarGroupVisibility?.visible);
    },
  });

  useLayoutEffect(() => {
    if (firstRender.current) {
      return;
    }
    // Update the parent checkbox depending on all the children checkbox statuses
    setIsSelected(areChildrenActive);
    updateCalendarGroupVisibility({
      update: handleUpdateChannelGroupCacheAfterUpdate,
      variables: {
        musicProfileId: musicProfile.tunifyMusicProfileUserId,
        calendarGroupId: channel.id,
        visible: areChildrenActive,
      },
    });
  }, [
    areChildrenActive,
    channel.id,
    handleUpdateChannelGroupCacheAfterUpdate,
    musicProfile.tunifyMusicProfileUserId,
    updateCalendarGroupVisibility,
  ]);

  const collapsed = openedSubCollapsible !== channel.id;

  const handleOpenCollapsible = useCallback(() => {
    if (collapsed) {
      setOpenedSubCollapsible(channel.id);
    } else {
      setOpenedSubCollapsible('');
    }
  }, [setOpenedSubCollapsible, collapsed, channel]);

  const handleClickCheckbox = useCallback((event) => event.stopPropagation(), []);

  const handleChangeCheckbox = useCallback(() => {
    updateCalendarGroupVisibility({
      update: handleUpdateChannelGroupWithChildrenCacheAfterUpdate,
      variables: {
        musicProfileId: musicProfile.tunifyMusicProfileUserId,
        calendarGroupId: channel.id,
        visible: !isSelected,
        childrenIds: sortedCalendars.map((calendar) => calendar.calendarId),
      },
    });
    setIsSelected(!isSelected);
  }, [
    musicProfile,
    channel,
    isSelected,
    updateCalendarGroupVisibility,
    handleUpdateChannelGroupWithChildrenCacheAfterUpdate,
    sortedCalendars,
  ]);

  return (
    <Container>
      <StyledSelectablePlaylistButton onClick={handleOpenCollapsible} type="button">
        <p>{channel.name}</p>
        <CheckboxField
          onChange={handleChangeCheckbox}
          onClick={handleClickCheckbox}
          checked={isSelected || areAllChildrenActive}
        />
      </StyledSelectablePlaylistButton>
      <AnimatePresence>
        {!collapsed ? (
          <motion.div
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            initial={{ height: 0 }}
            transition={{ ease: 'easeInOut', duration: 0.3 }}
          >
            {sortedCalendars?.length > 0
              ? sortedCalendars.map((calendar) => (
                  <Calendar key={calendar.calendarId} calendar={calendar} channel={channel} />
                ))
              : null}
          </motion.div>
        ) : null}
      </AnimatePresence>
    </Container>
  );
};

export default Channels;
