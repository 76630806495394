import { useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Modal, ErrorMessage, Button } from '../../../../common';

const Description = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.dark300};
  line-height: 1.4;
`;

const Actions = styled.div`
  display: grid;
  justify-items: end;
  align-items: center;
  column-gap: 1rem;
  row-gap: 1rem;
  padding: 1.6rem;
  background-color: ${({ theme }) => theme.uiBackground};
  border-top: 1px solid ${({ theme }) => theme.stroke};
  grid-template-columns: min-content 1fr;
  grid-template-rows: 1fr 1fr;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    grid-template-columns: min-content 1fr min-content;
    grid-template-rows: 1fr;
  }
`;

const CancelButtonWrapper = styled.div`
  justify-self: end;
  grid-area: 2 / 2 / 2 / span 1;
  padding-left: 2.4rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    justify-self: end;
    grid-area: 1 / 2 / 1 / span 1;
  }
`;

const StyledButton = styled(Button)`
  margin-right: 1rem;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 2rem;
`;

const DuplicateModal = ({
  isOpen,
  onConfirm,
  onCancel,
  requestLoading,
  requestError,
  title,
  description,
}) => {
  const { t } = useTranslation();

  const handleOnClose = useCallback(() => {
    if (!requestLoading) {
      onCancel();
    }
  }, [requestLoading, onCancel]);

  const handleDoNotAdd = useCallback(() => {
    onConfirm('DO_NOT_ADD');
  }, [onConfirm]);

  const handleAdd = useCallback(() => {
    onConfirm('ADD');
  }, [onConfirm]);

  return (
    <Modal
      footer={
        <Actions>
          <StyledButton
            emphasis="secondary"
            highlightColor="grapefruitRed"
            isDisabled={requestLoading}
            onClick={handleOnClose}
            title={t('common:button.title.cancel')}
            type="button"
          >
            {t('common:button.cancel')}
          </StyledButton>
          <CancelButtonWrapper>
            <Button
              highlightColor="logoGreen"
              isDisabled={requestLoading}
              isLoading={requestLoading}
              onClick={handleDoNotAdd}
              title={t('common:button.skip')}
            >
              {t('common:button.skip')}
            </Button>
          </CancelButtonWrapper>

          <Button
            highlightColor="logoBlue"
            isDisabled={requestLoading}
            isLoading={requestLoading}
            onClick={handleAdd}
            title={t('glossary:add')}
          >
            {t('glossary:add')}
          </Button>
        </Actions>
      }
      isOpen={isOpen}
      onClose={handleOnClose}
      title={title}
    >
      <Description>{description}</Description>
      {requestError ? <StyledErrorMessage error={requestError} /> : null}
    </Modal>
  );
};

export default DuplicateModal;
