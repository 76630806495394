import { useMemo } from 'react';

import theme from '../../global/style/theme';
import useWindowSize from './useWindowSize';

const useMediaSize = () => {
  const { width = theme.breakpoints.medium } = useWindowSize();

  const isNarrowerThanSmall = useMemo(() => width < theme.breakpoints.small, [width]);
  const isWiderThanSmall = !isNarrowerThanSmall;

  const isNarrowerThanMedium = useMemo(() => width < theme.breakpoints.medium, [width]);
  const isWiderThanMedium = !isNarrowerThanMedium;

  const isNarrowerThanLarge = useMemo(() => width < theme.breakpoints.large, [width]);
  const isWiderThanLarge = !isNarrowerThanLarge;

  const isNarrowerThanExtraLarge = useMemo(() => width < theme.breakpoints.extraLarge, [width]);
  const isWiderThanExtraLarge = !isNarrowerThanExtraLarge;

  return {
    isNarrowerThanSmall,
    isWiderThanSmall,
    isNarrowerThanMedium,
    isWiderThanMedium,
    isNarrowerThanLarge,
    isWiderThanLarge,
    isNarrowerThanExtraLarge,
    isWiderThanExtraLarge,
  };
};

export default useMediaSize;
