import { useLayoutEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import { SelectCard, formatNumber, getTunifyProductColor } from '../../../../common';
import { usePurchase } from '../purchaseContext';
import { calculateCheapestPricePerMonthForColor } from '../utils';

const Wrapper = styled.div`
  display: grid;
  gap: 1.6rem;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Price = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.dark400};
  line-height: 1.4;

  & > span {
    font-weight: 600;
    color: ${({ theme }) => theme.dark500};
  }
`;

const Label = styled.h3`
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ColorLabel = styled.span`
  color: ${({ color }) => getTunifyProductColor(color)};
`;

const SubLabel = styled.div`
  font-size: 1.4rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.dark300};
  padding-top: 1.2rem;
`;

const ColorInfo = styled.div`
  background-color: ${({ theme }) => theme.dark500};
  padding: 1.6rem;
  border-radius: 4px;
`;

const ColorTitle = styled.div`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.stroke};
  padding-bottom: 1.2rem;
  line-height: 1.3;
`;

const Options = styled.div`
  display: grid;
  gap: 1.2rem;
`;

const ColorFeatures = styled.div`
  font-size: 1.2rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.white};

  svg {
    display: none;
  }

  > ul {
    list-style-type: disc;
    padding-left: 1.8rem;
  }
`;

const ColorStep = ({ goToNextStep, goToPreviousStep }) => {
  const {
    availableColors,
    productCatalogue,
    handleSelectColor,
    userInput: { selectedColor, selectedMusicLicense },
  } = usePurchase();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { currentStep } = useParams();

  useLayoutEffect(() => {
    if (!selectedColor && availableColors.length > 0) {
      const initialSelectedColor = availableColors.reduce((acc, curr) =>
        acc.selectionOrder < curr.selectionOrder ? acc : curr
      );
      handleSelectColor(initialSelectedColor);
    }
    if (availableColors.length === 1) {
      const previousStep = location.state.from;
      if (currentStep > previousStep) {
        goToNextStep();
      } else {
        goToPreviousStep();
      }
    }
  }, [availableColors, handleSelectColor, selectedColor, goToNextStep, location, currentStep, goToPreviousStep]);

  const handleSelect = useCallback(
    (id) => {
      if (id !== selectedColor?.id) {
        const color = availableColors.find((c) => c.id === id);
        handleSelectColor(color);
      }
    },
    [selectedColor, handleSelectColor, availableColors]
  );

  const selectOptions = useMemo(
    () =>
      availableColors.map((color) => (
        <SelectCard
          activeSelectCardId={selectedColor?.id}
          id={color.id}
          key={color.id}
          onClick={handleSelect}
          title={
            <Label>
              Tunify <ColorLabel color={color.name}>{color.name}</ColorLabel>
            </Label>
          }
        >
          <Wrapper>
            <div>
              <Price>
                {t('subscriptions:cheapestPricePerMonthPart1')}{' '}
                <span>
                  €{' '}
                  {formatNumber(
                    calculateCheapestPricePerMonthForColor(color.id, productCatalogue, selectedMusicLicense.id),
                    i18n.language
                  )}
                </span>
                {t('subscriptions:cheapestPricePerMonthPart2')}
              </Price>
              <SubLabel>{color.description}</SubLabel>
            </div>
            <ColorInfo>
              <ColorTitle>{t('subscriptions:buyStep2Included')}</ColorTitle>
              <ColorFeatures dangerouslySetInnerHTML={{ __html: color.features }} />
            </ColorInfo>
          </Wrapper>
        </SelectCard>
      )),
    [availableColors, handleSelect, i18n.language, selectedColor, t, productCatalogue, selectedMusicLicense]
  );

  return <Options>{selectOptions}</Options>;
};

export default ColorStep;
