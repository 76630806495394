import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { useLazyQuery } from '@apollo/react-hooks';

import { GridTable, useMediaSize } from '../../../../../../../common';
import { Button } from '../../../../../../../common/oldButton';
import { ReactComponent as RawPlayIcon } from '../../../../../../../assets/icons/simplePlayControls/play.svg';
import { GET_SONG_MEDIA_URL } from '../../api';
import { useTunifyBlue } from '../../../tunifyBlueProvider';
import Equalizer from '../../../../../shared/equalizer';
import AudioBar from '../audioBar';

const Container = styled.div`
  display: grid;
`;

const StyledGridRow = styled(GridTable.Row)`
  min-height: 3rem;
  grid-template-columns: min-content 1fr min-content;
  align-items: center;
  padding: 0.6rem 0.6rem 0.6rem 0.4rem;
  border: none;
  & svg:hover {
    path {
      fill: ${({ theme }) => theme.babyBlue};
    }
  }
  gap: 0.5rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    grid-template-columns: min-content 1fr 1fr 1fr min-content;
    padding: 0 0.6rem 0 0;
    gap: 0;
  }
  ${({ odd, theme }) =>
    odd
      ? css`
          background-color: ${theme.stroke};

          @media only screen and (min-width: ${({ theme }) =>
              theme.breakpoints.large}px) {
            border-top: 1px solid ${theme.stroke};
            border-bottom: 1px solid ${theme.stroke};
            & :hover {
              border-color: ${theme.dark400};
            }
          }
        `
      : ''}
`;

const PlayerWrapper = styled.div`
  grid-template-columns: 1fr;
  background-color: ${({ theme }) => theme.white};
  padding: 0.6rem 0.4rem 0.6rem 0.5rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    grid-template-columns: 1fr;
    padding: 0 0.3rem 0 0;
    border: none;
  }

  ${({ odd, theme }) =>
    odd
      ? css`
          background-color: ${theme.stroke};

          @media only screen and (min-width: ${({ theme }) =>
              theme.breakpoints.large}px) {
            border-top: 1px solid ${theme.stroke};
            border-bottom: 1px solid ${theme.stroke};
          }
        `
      : ''}
`;

const MainWrapper = styled.div`
  display: grid;
  width: fit-content;
`;

const Bottom = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto 1fr;
  gap: 0.8rem;
`;

const Year = styled(GridTable.Value)`
  user-select: none;
  color: ${({ theme }) => theme.dark300};
`;

const MarkValue = styled.div`
  height: 100%;
  padding: 0 1rem;
  display: grid;
  align-content: center;
`;

const StyledValue = styled(GridTable.Value)`
  user-select: none;
`;

const Title = styled(StyledValue)`
  font-weight: 600;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    font-weight: 400;
  }
`;

const StyledButton = styled(Button)`
  margin-left: 0.2rem;
  border-color: transparent;
`;

const PlayIcon = styled(RawPlayIcon)`
  width: 1rem;
  height: 1rem;
  justify-self: center;
  margin-left: 0.15rem;
`;

const EqualizerWrapper = styled.div`
  width: 2.7rem;
  display: grid;
  align-items: center;
  justify-content: center;
  padding-left: 0.1rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    padding-left: 0.2rem;
  }
`;

const MiddleWrapper = styled.div`
  display: contents;
`;

export const Song = ({ song, index }) => {
  const { t } = useTranslation();
  const {
    dispatch,
    currentSongPlaying,
    stopAudio,
    setAudioSrc,
    setIsPlaying,
    isPlaying,
  } = useTunifyBlue();

  const { isNarrowerThanLarge } = useMediaSize();

  const playerIsOpen = currentSongPlaying === song.id;

  const [getSongMediaUrl, { loading: loadingSongUrl }] = useLazyQuery(
    GET_SONG_MEDIA_URL,
    {
      onCompleted: (data) => {
        setAudioSrc(data?.songMediaUrl);
        setIsPlaying(true);
      },
    }
  );

  const handleClickStart = useCallback(
    (event) => {
      event.stopPropagation();
      getSongMediaUrl({
        variables: {
          songId: song.id,
        },
      });
      dispatch({
        currentSongPlaying: song.id,
      });
    },
    [song, dispatch, getSongMediaUrl]
  );

  return (
    <Container>
      <StyledGridRow odd={Boolean((index + 1) % 2)}>
        {playerIsOpen ? (
          <EqualizerWrapper>
            <Equalizer active={!loadingSongUrl && isPlaying} />
          </EqualizerWrapper>
        ) : (
          <StyledButton
            flavor="icon"
            onClick={handleClickStart}
            title={t('broadcasts:card.table.play')}
            type="button"
          >
            <PlayIcon />
          </StyledButton>
        )}
        <MiddleWrapper>
          {isNarrowerThanLarge ? (
            <MainWrapper>
              <Title>{song?.title ?? '-'}</Title>
              <Bottom>
                <StyledValue>{song?.group ?? '-'}</StyledValue>
                <Year>{song?.year ?? '-'}</Year>
              </Bottom>
            </MainWrapper>
          ) : (
            <>
              <MarkValue>
                <StyledValue>{song?.title ?? '-'}</StyledValue>
              </MarkValue>
              <MarkValue>
                <StyledValue>{song?.group ?? '-'}</StyledValue>
              </MarkValue>
              <MarkValue>
                <StyledValue>{song?.year ?? '-'}</StyledValue>
              </MarkValue>
            </>
          )}
        </MiddleWrapper>
      </StyledGridRow>

      {playerIsOpen ? (
        <PlayerWrapper odd={Boolean((index + 1) % 2)}>
          <AudioBar
            invertColors={Boolean((index + 1) % 2)}
            isLoading={loadingSongUrl}
            onClose={stopAudio}
          />
        </PlayerWrapper>
      ) : null}
    </Container>
  );
};
