import PartnerManagerProvider from '../partnerManagerProvider';
import PartnersTable from './partnersTable';

const PartnersPage = () => (
  <PartnerManagerProvider>
    <PartnersTable />
  </PartnerManagerProvider>
);

export default PartnersPage;
