export const determineAvailableColors = (productCatalogue, selectedMusicLicenseIds, userWantsForeverProduct) => {
  const availableProducts = productCatalogue.filter((product) => {
    return userWantsForeverProduct === 'either' || product.isForever === userWantsForeverProduct;
  });

  const productsWithSelectedMusicLicenseId = availableProducts.filter(
    (product) => selectedMusicLicenseIds.includes(product.musicLicense.id)
  );

  const availableColorsById = productsWithSelectedMusicLicenseId.reduce((acc, curr) => {
    if (!acc[curr.color.id]) {
      return { ...acc, [curr.color.id]: curr.color };
    }
    return acc;
  }, {});

  const availableColors = Object.keys(availableColorsById).map((colorId) => availableColorsById[colorId]);

  return availableColors;
};
