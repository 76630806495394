import { useMemo, useCallback } from 'react';
import styled from 'styled-components';

import { SelectCard, CheckboxCard } from '../../..';
import { ReactComponent as ZoneIcon } from '../../../../assets/icons/zoneLarge.svg';

const CheckboxContainer = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  gap: 1rem;
`;

const Info = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content;
  gap: 0.2rem;
`;

const OrganizationLocationName = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.dark300};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;

  & span {
    color: ${({ theme }) => theme.dark100};
    padding: 0 0.8rem;
  }
`;

const ZoneName = styled.p`
  font-size: 1.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
`;

const ZoneRow = ({ zone, selected, onClickZone, isMulti }) => {
  const isChecked = useMemo(
    () => (isMulti ? selected.some((selectedZone) => selectedZone.id === zone.id) : selected?.id === zone.id),
    [selected, zone, isMulti]
  );

  const handleRowClick = useCallback(() => {
    onClickZone(zone.id);
  }, [onClickZone, zone]);

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        handleRowClick();
      }
    },
    [handleRowClick]
  );

  return isMulti ? (
    <CheckboxCard
      key={zone.id}
      isSelected={isChecked}
      onClick={handleRowClick}
      title={
        <CheckboxContainer>
          <ZoneIcon />
          <Info>
            <ZoneName>{zone.name}</ZoneName>
            <OrganizationLocationName>
              {zone.locationName}
              <span>|</span>
              {zone.organizationName}
            </OrganizationLocationName>
          </Info>
        </CheckboxContainer>
      }
      onKeyPress={handleKeyPress}
      tabIndex="0"
    />
  ) : (
    <SelectCard
      key={zone.id}
      activeSelectCardId={isChecked ? zone.id : null}
      id={zone.id}
      onClick={handleRowClick}
      title={
        <CheckboxContainer>
          <ZoneIcon />
          <Info>
            <ZoneName>{zone.name}</ZoneName>
            <OrganizationLocationName>
              {zone.locationName}
              <span>|</span>
              {zone.organizationName}
            </OrganizationLocationName>
          </Info>
        </CheckboxContainer>
      }
      onKeyPress={handleKeyPress}
      tabIndex="0"
    />
  );
};

export default ZoneRow;
