import styled from 'styled-components';
import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { set, getMonth, addWeeks } from 'date-fns';

import {
  CheckboxCard,
  formatNumber,
  getTunifyProductColor,
  formatDate,
} from '../../../../../common';
import { usePurchase } from '../../purchaseContext';
import { getEquivalentOfAddon } from '../../utils';

const Options = styled.div`
  display: grid;
  gap: 1.2rem;
`;

const SubLabel = styled.div`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.dark300};
  line-height: 1.4;
`;

const Label = styled.h3`
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & > span {
    color: ${({ theme }) => theme.dark300};
    font-weight: 500;
  }
`;

const SideInfo = styled.span`
  font-size: 1.2rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.dark300};
  padding-top: 0.8rem;

  & > span {
    color: ${({ theme }) => theme.dark500};
    font-weight: 600;
  }
`;

const ValidUntilLabel = styled.p`
  color: ${({ theme }) => theme.dark500};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.143;
  margin: 1rem 0;

  & span {
    color: ${({ theme }) => theme.dark300};
  }
`;

const SectionHeader = styled.div`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid ${({ theme }) => theme.stroke};
  line-height: 0;
  margin: 3rem 0;

  span {
    background: ${({ theme }) => theme.white};
    padding: 0 2rem;
    color: ${({ theme }) => theme.dark200};
    font-family: 'Inter', sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
    text-align: center;
  }
`;

const CurrentSubContainer = styled.div`
  background-color: ${({ theme }) => theme.uiBackground};
  border: 1px solid ${({ theme }) => theme.stroke};
  border-radius: 4px;
  padding: 1rem;
`;

const CurrentSubTitle = styled.p`
  color: ${({ theme }) => theme.dark300};
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
  line-height: 1.6;
  text-transform: uppercase;
  margin-bottom: 1rem;
`;

const ZonesWithSubscription = () => {
  const { t, i18n } = useTranslation();
  const {
    availableAdditionalZones,
    selectedProduct,
    organizationDetails,
    handleSelectAdditionalZone,
    availableAddons,
    userInput: { selectedAdditionalZones, selectedAddons },
  } = usePurchase();

  const handleSelect = useCallback(
    (zoneId) => {
      const additionalZone = availableAdditionalZones.find(
        (az) => az.id === zoneId
      );
      handleSelectAdditionalZone(additionalZone);
    },
    [handleSelectAdditionalZone, availableAdditionalZones]
  );

  const addonsToBuy = useMemo(
    () =>
      selectedAddons.map((addon) =>
        getEquivalentOfAddon(
          addon,
          availableAddons,
          selectedProduct.subscriptionTemplate.id
        )
      ),
    [selectedAddons, availableAddons, selectedProduct]
  );

  const totalPrice = useMemo(
    () =>
      selectedProduct.price +
      addonsToBuy.reduce((acc, curr) => acc + curr.price, 0),
    [addonsToBuy, selectedProduct]
  );

  const periodText = useMemo(() => {
    const { recurringRuleType, recurringInterval } =
      selectedProduct.subscriptionTemplate;

    return recurringRuleType === 'monthly'
      ? t('glossary:monthWithCount', { count: recurringInterval })
      : t('glossary:weekWithCount', { count: recurringInterval });
  }, [t, selectedProduct]);

  const zonesWithSubscription = useMemo(
    () =>
      availableAdditionalZones
        .filter((zone) => zone?.currentSubscription?.id)
        .map((zone) => {
          const location = organizationDetails.locations.find(
            (loc) => loc.id === zone.location.id
          );
          const currentProduct =
            zone?.currentSubscription?.subscriptionLines[0]?.product;

          const futureExpireDate =
            selectedProduct.subscriptionTemplate?.recurringRuleType ===
            'monthly'
              ? set(new Date(zone?.currentSubscription?.endDate), {
                  month:
                    getMonth(new Date(zone?.currentSubscription?.endDate)) +
                    selectedProduct.subscriptionTemplate?.recurringInterval,
                })
              : addWeeks(
                  new Date(zone?.currentSubscription?.endDate),
                  selectedProduct.subscriptionTemplate?.recurringInterval
                );
          return (
            <CheckboxCard
              key={zone.id}
              activeCheckboxCardId={selectedAdditionalZones.map(
                (additionalZone) => additionalZone.id
              )}
              id={zone.id}
              onClick={handleSelect}
              title={
                <Label>
                  {location.name} <span>| {zone.name}</span>
                </Label>
              }
            >
              <SubLabel>
                Tunify{' '}
                <span
                  style={{
                    color: getTunifyProductColor(
                      selectedProduct.color?.name?.toLowerCase() || ''
                    ),
                  }}
                >
                  {selectedProduct.color?.name || '-'}
                </span>{' '}
                {periodText}, {selectedProduct.musicLicense?.name || '-'}
              </SubLabel>
              <SideInfo>
                <span>€ {formatNumber(totalPrice, i18n.language)}</span>{' '}
                {t('glossary:exclusiveVat')}
              </SideInfo>
              <ValidUntilLabel>
                <span>{t('subscriptions:thValidUntil')} </span>
                {formatDate(futureExpireDate, i18n.language)}
              </ValidUntilLabel>
              <CurrentSubContainer>
                <CurrentSubTitle>
                  {t('subscriptions:currentSubscription')}
                </CurrentSubTitle>
                <SubLabel>
                  Tunify{' '}
                  <span
                    style={{
                      color: getTunifyProductColor(
                        currentProduct.color?.name?.toLowerCase() || ''
                      ),
                    }}
                  >
                    {currentProduct.color?.name || '-'}
                  </span>{' '}
                  {periodText}, {currentProduct.musicLicense?.name || '-'}
                </SubLabel>
                <ValidUntilLabel>
                  <span>{t('subscriptions:thValidUntil')} </span>
                  {formatDate(
                    zone?.currentSubscription?.endDate,
                    i18n.language
                  )}
                </ValidUntilLabel>
              </CurrentSubContainer>
            </CheckboxCard>
          );
        }),
    [
      availableAdditionalZones,
      organizationDetails.locations,
      selectedProduct.subscriptionTemplate?.recurringRuleType,
      selectedProduct.subscriptionTemplate?.recurringInterval,
      selectedProduct.color?.name,
      selectedProduct.musicLicense?.name,
      selectedAdditionalZones,
      handleSelect,
      periodText,
      totalPrice,
      i18n.language,
      t,
    ]
  );

  return (
    <Options>
      <SectionHeader>
        <span>{t('purchase:zonesWithSubscription')}</span>
      </SectionHeader>
      <Options>{zonesWithSubscription}</Options>
    </Options>
  );
};

export default ZonesWithSubscription;
