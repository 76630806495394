import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/react-hooks';
import { useDebounce } from 'react-use';

import {
  Modal,
  LoadingIndicator,
  ErrorMessage,
  Button,
} from '../../../../../common';
import {
  GET_MUSIC_COLLECTION_TWEAK_STRENGTH_BY_ZONE,
  GET_SONGS_OF_MUSIC_COLLECTION,
} from '../../api';
import { usePlaylists } from '../../playlistsProvider';
import { ChangeableParameter } from '../../../shared';
import { ReactComponent as ResetIcon } from '../../../../../assets/icons/reset.svg';
import theme from '../../../../../global/style/theme';

const ModalContainer = styled.div`
  overflow-y: auto;
  display: grid;
`;

const Info = styled.p`
  color: ${({ theme }) => theme.dark400};
  font-size: 1.4rem;
  line-height: 1.4;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin-top: 1rem;
`;

const LeftWrapper = styled.div`
  display: grid;
  gap: 0.3rem;
`;

const LineWrapper = styled.div`
  padding-bottom: 2.6rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
`;

const BarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2rem;
`;

const BarItem = styled.div`
  width: 0.9rem;
  height: 1.8rem;
  background-color: ${({ color, isLoading, theme }) => {
    if (isLoading) {
      return theme.background;
    }
    return color ?? theme.background;
  }};
  transition: 0.2s background-color ease-in-out;
`;

const RightWrapper = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  gap: 0.8rem;
`;

const SettingButton = styled(Button)`
  border-color: ${({ theme }) => theme.stroke};
  border-width: 1px;
  padding-bottom: 0;
`;

const TweakStatus = {
  NORMAL: 'Normal',
  WARNING: 'Warning',
  ERROR: 'Error',
};

const CollectionSettingsModal = ({
  isOpen,
  onClose,
  title,
  selectedPlaylist,
  zone,
  loading,
  data,
  error,
}) => {
  const { t } = useTranslation();

  const { changeableParameters, dispatch } = usePlaylists();

  // On error just show nothing instead of message
  const [
    getMusicCollectionTweakStrengthByZone,
    { data: dataTweakStrength, loading: loadingTweakStrength },
  ] = useLazyQuery(GET_MUSIC_COLLECTION_TWEAK_STRENGTH_BY_ZONE, {
    onError: (err) => {
      console.error(err);
    },
  });

  useDebounce(
    () => {
      if (
        zone?.id &&
        selectedPlaylist?.musicCollectionId &&
        changeableParameters
      ) {
        getMusicCollectionTweakStrengthByZone({
          variables: {
            zoneId: zone.id,
            musicCollectionId: selectedPlaylist.musicCollectionId,
            changeableParameters,
          },
        });
      }
    },
    500,
    [zone?.id, selectedPlaylist?.musicCollectionId, changeableParameters]
  );

  const [
    getSongsOfMusicCollection,
    { loading: loadingUpdate, error: errorUpdate },
  ] = useLazyQuery(GET_SONGS_OF_MUSIC_COLLECTION, {
    onCompleted: (dataSongs) => {
      if (dataSongs?.songsOfMusicCollection) {
        dispatch({
          songs: dataSongs.songsOfMusicCollection.map((song, index) => ({
            ...song,
            id: `${song.id}${index}`,
            songId: song.id,
          })),
        });
      }
      onClose();
    },
  });

  const handleUpdateSettings = useCallback(() => {
    if (
      zone?.id &&
      selectedPlaylist?.musicChannelId &&
      selectedPlaylist?.musicCollectionId &&
      changeableParameters
    ) {
      getSongsOfMusicCollection({
        variables: {
          zoneId: zone.id,
          musicChannelId: selectedPlaylist.musicChannelId,
          musicCollectionId: selectedPlaylist.musicCollectionId,
          changeableParameters,
        },
      });
    }
  }, [
    zone?.id,
    selectedPlaylist?.musicChannelId,
    selectedPlaylist?.musicCollectionId,
    changeableParameters,
    getSongsOfMusicCollection,
  ]);

  const handleClickPutBack = useCallback(() => {
    if (data?.musicCollectionContextByZone?.defaultChangeableParameter) {
      const paramsWithoutTypename =
        data.musicCollectionContextByZone.defaultChangeableParameter.map(
          ({ __typename, ...parameters }) => ({
            ...parameters,
            value: parameters?.value?.map(
              ({ __typename: typenameValue, ...value }) => value
            ),
          })
        );
      dispatch({
        changeableParameters: paramsWithoutTypename,
      });
    }
  }, [data?.musicCollectionContextByZone, dispatch]);

  const tweakStrengthBarLength = 25;

  const tweakStrengthBarItems = useMemo(() => {
    if (dataTweakStrength?.musicCollectionTweakStrengthByZone) {
      const highlightedBlocksAmount = Math.round(
        (dataTweakStrength.musicCollectionTweakStrengthByZone.percentage /
          100) *
          tweakStrengthBarLength
      );
      const unHighlightedBlocksAmount =
        tweakStrengthBarLength - highlightedBlocksAmount;

      const highlightColor = (() => {
        if (
          dataTweakStrength.musicCollectionTweakStrengthByZone.status ===
          TweakStatus.WARNING
        )
          return theme.sweetOrange;
        if (
          dataTweakStrength.musicCollectionTweakStrengthByZone.status ===
          TweakStatus.ERROR
        )
          return theme.grapefruitRed;
        return theme.babyBlue;
      })();

      const highlightedArray = Array(highlightedBlocksAmount).fill(
        highlightColor
      );
      const unHighlightedArray = Array(unHighlightedBlocksAmount).fill(
        theme.background
      );

      return [...highlightedArray, ...unHighlightedArray];
    }
    return Array(tweakStrengthBarLength).fill(theme.background);
  }, [dataTweakStrength?.musicCollectionTweakStrengthByZone]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      requestLoading={loadingUpdate}
      onConfirm={handleUpdateSettings}
      confirmText={t('common:button.save')}
      shouldCloseOnOverlayClick={false}
    >
      <ModalContainer>
        {errorUpdate || error ? (
          <ErrorMessage error={errorUpdate || error} />
        ) : null}
        {loading ? (
          <StyledLoadingIndicator />
        ) : (
          <>
            <LineWrapper>
              <LeftWrapper>
                <Info>
                  {t(
                    'musicManagement:playlists.collectionSettings.amountOfSongs'
                  )}
                </Info>
                <BarWrapper>
                  {tweakStrengthBarItems.map((color, index) => (
                    <BarItem
                      key={index}
                      color={color}
                      isLoading={loadingTweakStrength}
                    />
                  ))}
                </BarWrapper>
              </LeftWrapper>
              <RightWrapper>
                <ResetIcon
                  width="1.5rem"
                  height="1.5rem"
                  color={theme.dark300}
                />
                <SettingButton
                  emphasis="secondary"
                  size="medium"
                  onClick={handleClickPutBack}
                  title={t(
                    'musicManagement:tunifyBlue.collectionSettings.putBackTitle'
                  )}
                >
                  <p>
                    {t(
                      'musicManagement:tunifyBlue.collectionSettings.putBackShort'
                    )}
                  </p>
                </SettingButton>
              </RightWrapper>
            </LineWrapper>
            {changeableParameters.map((changeableParameter, index) => (
              <ChangeableParameter
                key={index}
                changeableParameter={changeableParameter}
                changeableParameters={changeableParameters}
                disable={loadingUpdate}
                dispatch={dispatch}
                index={index}
              />
            ))}
          </>
        )}
      </ModalContainer>
    </Modal>
  );
};

export default CollectionSettingsModal;
