import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

const TopContainer = styled.div`
  position: relative;

  &>:not(.max-height-container-child) {
    display: none !important;
  }
`;

const CaseContainer = styled.div`
  width: 100%;
  ${({ $supported, $hidden }) => $supported
    ? css`
      position: absolute;
      left: 0;
      top: 0;
      ${$hidden && css`
        visibility: hidden;
      `}
    `
    : $hidden && css`
      display: none;
    `
  }
`;

const MaxChildHeightContext = createContext(() => {
  throw new Error('Missing max-child-height context.');
});

const isSupported = typeof ResizeObserver === 'function';

export const MaxChildHeightContainer = ({ children }) => {
  const containerRef = useRef(null);

  const [heightMap] = useState(new Map());

  const updateChildHeight = useCallback((element) => {
    if (containerRef.current) {
      heightMap.set(element, element.offsetHeight);
      const totalHeight = Math.max(...heightMap.values());
      containerRef.current.style.height = `${totalHeight}px`;
    }
  }, [heightMap]);

  return (
    <TopContainer ref={containerRef}>
      <MaxChildHeightContext.Provider value={updateChildHeight}>
        {children}
      </MaxChildHeightContext.Provider>
    </TopContainer>
  );
};

export const MaxChildHeightCase = ({ children, visible }) => {
  const containerRef = useRef(null);

  const updateChildHeight = useContext(MaxChildHeightContext);

  useEffect(() => {
    if (!isSupported) return;
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        updateChildHeight(entry.target);
      }
    });
    observer.observe(containerRef.current);
    updateChildHeight(containerRef.current);
    return () => {
      observer.disconnect();
    };
  }, [updateChildHeight]);

  return (
    <CaseContainer
      ref={containerRef}
      className="max-height-container-child"
      $hidden={!visible}
      $supported={isSupported}
    >
      {children}
    </CaseContainer>
  );
};
