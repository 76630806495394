import styled, { css } from 'styled-components';
import { useDropzone } from 'react-dropzone';

import { LoadingIndicator } from '..';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const UploadContainer = styled.div`
  height: 100%;
  min-height: 12rem;
  position: relative;
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.stroke};

  ${({ $disabled }) =>
    !$disabled &&
    css`
      cursor: pointer;
    `}
`;

const P = styled.p`
  color: ${({ theme }) => theme.dark200};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 1rem;
  font-size: 1.2rem;
  line-height: 1.4;
  margin: 0;
  width: 90%;
  user-select: none;
`;

const File = styled.p`
  font-size: 1.4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  width: 90%;
  text-align: center;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  text-align: center;
`;

const DropZone = ({
  accept,
  placeholder,
  onDropAccepted,
  isLoading = false,
  maxSize,
  acceptedFiles,
  name,
  disabled,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept,
    onDropAccepted,
    maxSize,
    disabled,
  });

  return (
    <Container>
      <UploadContainer $disabled={disabled} {...getRootProps()}>
        <input name={name} {...getInputProps()} />
        {isLoading && <StyledLoadingIndicator />}
        {!isLoading &&
          acceptedFiles?.length &&
          acceptedFiles.map((file) => <File key={file.id || file.lastModified}>{file.name}</File>)}
        {!isLoading && !acceptedFiles?.length && <P>{placeholder}</P>}
      </UploadContainer>
    </Container>
  );
};

export default DropZone;
