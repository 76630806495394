import config from '../../../config';

const { SPOTIFY_BASE_URL } = config;

const fetchTracksFromUrl = async (url, spotifyAccessToken) => {
  const response = await window.fetch(url, {
    headers: {
      Authorization: `Bearer ${spotifyAccessToken}`,
    },
  });

  if (!response.ok) {
    const requestFetchError = new Error('Request fetch error');
    requestFetchError.status = response.status;
    throw requestFetchError;
  }

  const data = await response.json();

  return data.items.map((item) => {
    const { track } = item;
    return {
      id: track.id === undefined ? null : track.id, // Explicitly cast undefined values to null
      title: track.name,
      artist: track.artists.map((artist) => artist.name).join(', '),
      albums: track.album.name,
      duration: track.duration_ms,
      isrc:
        track.external_ids.isrc === undefined ? null : track.external_ids.isrc, // Explicitly cast undefined values to null
    };
  });
};

const buildPlaylistTrackUrls = (playlist) => {
  const numberOfTracksInPlaylist = playlist.tracks.total;
  // Maximum 100 per request due to Spotify API limiting
  const numberOfSubsequentFetches = Math.ceil(numberOfTracksInPlaylist / 100);
  const playlistTrackUrls = [];
  for (let i = 0; i < numberOfSubsequentFetches; i += 1) {
    const offset = i * 100;
    playlistTrackUrls.push(
      `${SPOTIFY_BASE_URL}/playlists/${playlist.id}/tracks?offset=${offset}&limit=100`
    );
  }
  return playlistTrackUrls;
};

export const fetchTracksForPlaylists = async (
  playlists,
  spotifyAccessToken
) => {
  const playlistsWithTracks = await Promise.all(
    playlists.map(async (playlist) => {
      const playlistTrackUrls = buildPlaylistTrackUrls(playlist);

      // Getting the tracks for every playlist will run sequentially
      // to avoid the 429 Too many requests from Spotify's rate limiter
      const playlistTracks = await playlistTrackUrls.reduce(
        async (acc, curr) => {
          const previousTrackGroup = await acc;
          const currentTrackGroup = await fetchTracksFromUrl(
            curr,
            spotifyAccessToken
          );
          return previousTrackGroup.concat(currentTrackGroup);
        },
        Promise.resolve([])
      );

      return {
        id: playlist.id,
        name: playlist.name,
        tracks: playlistTracks,
      };
    })
  );

  return playlistsWithTracks;
};

export const fetchSpotifyUserCallback = (url, spotifyAccessToken) => {
  return async () => {
    const response = await fetch(`${url}/me`, {
      headers: { Authorization: `Bearer ${spotifyAccessToken}` },
    });

    if (!response.ok) {
      const requestFetchError = new Error('Request fetch error');
      requestFetchError.status = response.status;
      requestFetchError.name = 'GET_SPOTIFY_USER_ERROR';

      throw requestFetchError;
    }

    const data = await response.json();

    return data;
  };
};

export const fetchSpotifyPlaylistsCallback = (
  url,
  spotifyAccessToken,
  limit
) => {
  return async ({ pageParam = 0 }) => {
    const response = await window.fetch(
      `${url}/me/playlists?offset=${pageParam}&limit=${limit}`,
      {
        headers: { Authorization: `Bearer ${spotifyAccessToken}` },
      }
    );

    if (!response.ok) {
      const requestFetchError = new Error('Request fetch error');
      requestFetchError.status = response.status;
      requestFetchError.name = 'GET_SPOTIFY_PLAYLIST_ERROR';

      throw requestFetchError;
    }

    const data = await response.json();

    return data;
  };
};
