import { useState, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../../../common';
import ChannelStep from './channelStep';

const steps = ['channel', 'subChannel', 'collection'];

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;

  & > :not(:first-child) {
    margin-left: 1rem;
  }

  margin-top: 1rem;
`;

const ContentWrapper = styled.div`
  padding: 1rem 0;
`;

const Mood = ({ onClose, setFlow, isSubmitting, onSubmit, tunifyChannels, flow }) => {
  const { t } = useTranslation();

  const [step, setStep] = useState(steps[0]);
  const [channelId, setChannelId] = useState('');
  const [subChannelId, setSubChannelId] = useState('');
  const [collectionId, setCollectionId] = useState('');

  const handleGoBack = useCallback(() => {
    const currentIndex = steps.findIndex((currentStep) => currentStep === step);
    if (currentIndex < 1) {
      setFlow('');
    } else {
      setStep(steps[currentIndex - 1]);
    }
  }, [step, setFlow]);

  const handleGoNext = useCallback(() => {
    const currentIndex = steps.findIndex((currentStep) => currentStep === step);
    if (currentIndex < steps.length - 1) {
      setStep(steps[currentIndex + 1]);
    }
  }, [step]);

  const handleSubmit = useCallback(() => {
    onSubmit({
      id: collectionId,
      flow,
    });
  }, [onSubmit, flow, collectionId]);

  return (
    <ContentWrapper>
      {step === steps[0] ? (
        <ChannelStep
          goBack={handleGoBack}
          next={handleGoNext}
          setChannelId={setChannelId}
          tunifyChannels={tunifyChannels}
        />
      ) : null}
      {step === steps[1] ? (
        <ChannelStep
          channelId={channelId}
          goBack={handleGoBack}
          isSubChannel
          next={handleGoNext}
          setSubChannelId={setSubChannelId}
          tunifyChannels={tunifyChannels}
        />
      ) : null}
      {step === steps[2] ? (
        <ChannelStep
          channelId={channelId}
          collectionId={collectionId}
          goBack={handleGoBack}
          isCollection
          next={handleGoNext}
          setCollectionId={setCollectionId}
          subChannelId={subChannelId}
          tunifyChannels={tunifyChannels}
        />
      ) : null}
      <Footer>
        <Button emphasis="secondary" onClick={onClose} type="button">
          {t('common:button.cancel')}
        </Button>
        <Button
          emphasis="primary"
          isDisabled={step !== steps[steps.length - 1]}
          isLoading={isSubmitting}
          onClick={handleSubmit}
          title={t('common:button.title.save')}
          type="submit"
        >
          {t('common:button.save')}
        </Button>
      </Footer>
    </ContentWrapper>
  );
};

export default Mood;
