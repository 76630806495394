import styled from 'styled-components';

import ErrorMessage from '../errorMessage';

const StyledErrorMessage = styled(ErrorMessage)`
  width: 100%;
`;

const TableErrorMessage = ({ error }) => (error ? <StyledErrorMessage error={error} /> : null);

export default TableErrorMessage;
