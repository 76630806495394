import gql from 'graphql-tag';

export const GET_MUSIC_PROFILES = gql`
  query getMusicProfiles($defaultBrandName: String!) {
    musicProfiles(defaultBrandName: $defaultBrandName) {
      id
      name
      brandId
      tunifyMusicProfileUserId
      externalRegionId
      musicLicenseId
    }
  }
`;

export const GET_ZONES = gql`
  query getZones {
    zones {
      id
      name
      musicProfile {
        id
      }
      location {
        id
        name
        organization {
          id
          name
        }
      }
    }
  }
`;

export const GET_ZONES_WITH_ACTIVE_SUBSCRIPTION = gql`
  query getZonesWithAnActiveSubscription {
    zonesWithAnActiveSubscription {
      id
      name
      location {
        id
        name
        organization {
          id
          name
        }
      }
      musicRegionId
      musicProfile {
        id
      }
      currentSubscription {
        id
        subscriptionLines {
          id
          product {
            id
            isAddon
            type
            categoryId
            musicLicenseId
          }
        }
      }
    }
  }
`;
