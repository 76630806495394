import styled from 'styled-components';

const P = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${({ lines }) => lines}; /* number of lines to show */
  -webkit-box-orient: vertical;
`;

const LineClamp = ({ lines = 2, children }) => <P lines={lines}>{children}</P>;

export default LineClamp;
