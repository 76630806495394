import { Trans, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { useCustomerReferral } from '../../../global/customerReferral';

import { useQuery } from '@apollo/react-hooks';
import React from 'react';
import { ErrorMessage, LoadingIndicator } from '../../../common';
import {
  GET_CUSTOMER_PROGRAM,
  GET_PRODUCT,
  GET_PROGRAM_LINE_REGISTRATION_TEXT,
} from '../../auth/register/api';
import { useAuth } from '../../../global/auth/newAuthProvider';

const Container = styled.div`
  align-items: center;
  display: flex;
  height: 42rem;
  padding-bottom: calc(4.8rem + 24px); /* Button size + logo margin */
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

const StyledText = styled.p`
  font-size: 14px;
  color: #363e4a;
  text-align: center;
`;

const StyledButton = styled.button`
  text-decoration: none;
  background: none;
  border: none;
  padding: 0;
  display: inline;
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  line-height: 1.4;
  color: #86b6ff;
  letter-spacing: 0;
  line-height: 1.2rem;
  cursor: pointer;
  transition: color 0.5s ease;
  outline: none;
  text-transform: lowercase;

  &:hover,
  :focus {
    color: ${({ theme }) => theme.dark600};
  }
`;

const StyledGoNext = styled.button`
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;

  color: white;
  border-radius: 9999px;
  border: none;

  z-index: 1;
  transition: 0.2s color ease-in-out, 0.2s background-color ease-in-out,
    0.2s border-color ease-in-out;

  height: 4.8rem;
  padding: 0 2.4rem;
  font-size: 1.6rem;

  background: #7890c9;
  background: linear-gradient(135deg, #7890c9 0%, #b19dd0 100%);

  &::before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 5rem;
    background-color: #7890c9;
    z-index: -2;
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
  }

  &::after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 5rem;
    background-color: #b19dd0;
    z-index: -1;
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
  }

  &:hover::before {
    opacity: 1;
  }

  &:active::after {
    opacity: 1;
  }
`;

const commercialType = 'commercial';
const rightsFreeType = 'rights-free';
const subscriptionTypeKey = 'subscription-type';

export const LandingPage = () => {
  const { t } = useTranslation();

  const { login } = useAuth();

  const [searchParams, setSearchParams] = useSearchParams();

  const { memberCode, registrationCode } = useCustomerReferral();
  const productCode = searchParams.get('product-code');
  const subscriptionType = searchParams.get(subscriptionTypeKey);
  const isCommercial = subscriptionType === commercialType;

  const { data, loading, error } = useQuery(GET_CUSTOMER_PROGRAM, {
    skip: !memberCode,
    variables: { customerCode: memberCode },
  });

  const {
    loading: productLoading,
    error: productError,
    data: productData,
  } = useQuery(GET_PRODUCT, {
    skip: Boolean(registrationCode) || Boolean(memberCode),
    variables: { barcode: productCode },
  });

  const {
    loading: programLineRegistrationTextLoading,
    error: programLineRegistrationTextError,
    data: programLineRegistrationTextData,
  } = useQuery(GET_PROGRAM_LINE_REGISTRATION_TEXT, {
    skip: !registrationCode,
    variables: { code: registrationCode },
  });

  const reducedLoadingState = React.useMemo(
    () => productLoading || programLineRegistrationTextLoading || loading,
    [loading, productLoading, programLineRegistrationTextLoading]
  );

  const reducedErrorState = React.useMemo(
    () => productError || programLineRegistrationTextError || error,
    [error, productError, programLineRegistrationTextError]
  );

  const html = React.useMemo(() => {
    if (productData || programLineRegistrationTextData) {
      return productData
        ? productData.productByBarcode.registrationDescription
        : programLineRegistrationTextData.partnerProgramLineRegistrationText;
    }
  }, [productData, programLineRegistrationTextData]);

  const toggleIsCommercial = React.useCallback(() => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set(subscriptionTypeKey, isCommercial ? rightsFreeType : commercialType);
    setSearchParams(newParams);
  }, [isCommercial, searchParams, setSearchParams]);

  const hasCustomerProgramData = Boolean(data && data.customerProgram);

  React.useEffect(() => {
    if (![commercialType, rightsFreeType].includes(subscriptionType) && hasCustomerProgramData) {
      const newParams = new URLSearchParams(searchParams);
      newParams.set(subscriptionTypeKey, commercialType);
      setSearchParams(newParams);
    }
  }, [hasCustomerProgramData, searchParams, setSearchParams, subscriptionType]);

  const handleGoNext = React.useCallback(() => {
    login({
      authorizationParams: {
        screen_hint: 'signup',
        tunify_memberCode: memberCode,
        tunify_registrationCode: registrationCode,
        tunify_productCode: productCode,
        tunify_productId: isCommercial
          ? data?.customerProgram.commercialProductId
          : data?.customerProgram.royaltyFreeProductId,
      },
    });
  }, [
    data?.customerProgram.commercialProductId,
    data?.customerProgram.royaltyFreeProductId,
    isCommercial,
    login,
    memberCode,
    productCode,
    registrationCode,
  ]);

  if (reducedErrorState)
    return <StyledErrorMessage error={reducedLoadingState} />;

  if (reducedLoadingState && !reducedErrorState && !data)
    return <LoadingIndicator />;

  return (
    <Container>
      <StyledText dangerouslySetInnerHTML={{ __html: html }} />
      {hasCustomerProgramData && (
        <StyledText>
          <Trans
            components={{
              toggle: <StyledButton onClick={toggleIsCommercial} />,
              bold: <p style={{ fontWeight: 800, display: 'inline' }} />,
            }}
            i18nKey={isCommercial ? 'auth:memberGetMember.commercial' : 'auth:memberGetMember.rightfree'}
            values={{
              referrer: data.customerProgram.referrerFullName,
              count:
                data.customerProgram.commercialProductRecurringInterval,
            }}
          />
        </StyledText>
      )}
      <StyledGoNext onClick={handleGoNext}>{t('common:button.next')}</StyledGoNext>
    </Container>
  );
};
