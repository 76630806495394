import { useCallback, useMemo, useEffect } from 'react';
import styled from 'styled-components';

import { SelectCard } from '../../../../common';
import { useAddonsBuy } from '../addonsBuyContext';
import { addonCatalogueOptions } from '../addonsBuyReducer';

const Label = styled.h2`
  font-size: 1.8rem;
  line-height: 1.5;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SubLabel = styled.div`
  font-size: 1.4rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.dark300};
`;

const Options = styled.div`
  display: grid;
  gap: 1.2rem;
`;

const AddonCatalogueStep = ({ setGoToNextStepLoading, setGoToNextStepError }) => {
  const { handleSelectAddonCatalogue, selectedAddonCatalogue, addonCatalogueLoading, addonCatalogueError } =
    useAddonsBuy();

  useEffect(() => {
    setGoToNextStepLoading(addonCatalogueLoading);
    setGoToNextStepError(addonCatalogueError);
    return () => {
      setGoToNextStepError(null);
    };
  }, [setGoToNextStepError, setGoToNextStepLoading, addonCatalogueError, addonCatalogueLoading]);

  const handleSelect = useCallback(
    (id) => {
      if (id !== selectedAddonCatalogue?.id) {
        const addonCatalogue = addonCatalogueOptions.find((pc) => pc.id === id);
        handleSelectAddonCatalogue(addonCatalogue);
      }
    },
    [selectedAddonCatalogue, handleSelectAddonCatalogue]
  );

  const selectOptions = useMemo(
    () =>
      addonCatalogueOptions.map((addonCatalogue) => (
        <SelectCard
          key={addonCatalogue.id}
          activeSelectCardId={selectedAddonCatalogue?.id}
          id={addonCatalogue.id}
          isDisabled={selectedAddonCatalogue?.id !== addonCatalogue.id}
          onClick={handleSelect}
          title={<Label>{addonCatalogue.name}</Label>}
        >
          <SubLabel>{addonCatalogue.description}</SubLabel>
        </SelectCard>
      )),
    [handleSelect, selectedAddonCatalogue]
  );

  return <Options>{selectOptions}</Options>;
};

export default AddonCatalogueStep;
