export const getEquivalentOfAddon = (addonCategory, availableAddons, subscriptionTemplateId) => {
  const equivalentAddons = availableAddons.filter((availableAddon) => {
    const shortnameTest = availableAddon.shortName === addonCategory.shortName;
    const subscriptionTemplateIdTest = availableAddon.subscriptionTemplate.id === subscriptionTemplateId;
    return shortnameTest && subscriptionTemplateIdTest;
  });

  if (equivalentAddons.length === 0) {
    throw new Error(
      `Did not find an equivalent of addon with id [${addonCategory.id}] and subscription template with id [${subscriptionTemplateId}]`
    );
  }

  if (equivalentAddons.length > 1) {
    throw new Error(
      `Found multiple equivalents of addon with id [${addonCategory.id}] and subscription template with id [${subscriptionTemplateId}]`
    );
  }

  return equivalentAddons[0];
};
