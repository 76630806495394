import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useMediaSize } from '../../../../common';
import { CustomChannels } from './customChannels';
import { TunifyChannels } from './tunifyChannels';
import { useTunifyGreen } from './tunifyGreenProvider';
import AgendaDetails from './agendaDetails';

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0.2rem;
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
  background-color: ${({ theme }) => theme.white};

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    grid-template-columns: 18rem 1fr;
  }
`;

const ListContainerWrapper = styled.div`
  display: grid;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    border-right: 0.1rem solid ${({ theme }) => theme.dark200};
  }
`;

const ListContainer = styled.div`
  max-height: calc(100vh - 22rem);
  height: min-content;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    max-height: calc(100vh - 19.5rem);
  }
`;

const InfoWrapper = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  padding: 0.7rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    padding: 2rem 1rem 1rem 1rem;
  }
`;

const SearchInfo = styled.span`
  color: ${({ theme }) => theme.dark300};
  font-size: 0.9rem;
  padding-bottom: 0.5rem;
`;

const TunifyGreen = () => {
  const { t } = useTranslation();

  const { selectedCalendar } = useTunifyGreen();

  const [openedCollapsible, setOpenedCollapsible] = useState('tunify');

  const { isNarrowerThanLarge } = useMediaSize();

  return (
    <Container>
      {!isNarrowerThanLarge || !selectedCalendar?.calendarId ? (
        <ListContainerWrapper>
          <ListContainer>
            <CustomChannels
              name={t('musicManagement:tunifyGreen.myAgenda')}
              openedCollapsible={openedCollapsible}
              setOpenedCollapsible={setOpenedCollapsible}
            />
            <TunifyChannels
              name={t('musicManagement:tunifyGreen.tunifyAgenda')}
              openedCollapsible={openedCollapsible}
              setOpenedCollapsible={setOpenedCollapsible}
            />
          </ListContainer>
        </ListContainerWrapper>
      ) : null}
      {!isNarrowerThanLarge || selectedCalendar?.calendarId ? (
        <>
          {selectedCalendar ? (
            <AgendaDetails />
          ) : (
            <InfoWrapper>
              <SearchInfo>
                {t('musicManagement:tunifyGreen.channels.info1')}
              </SearchInfo>
              <SearchInfo>
                {t('musicManagement:tunifyGreen.channels.info2')}
              </SearchInfo>
              <SearchInfo>
                {t('musicManagement:tunifyGreen.channels.info3')}
              </SearchInfo>
            </InfoWrapper>
          )}
        </>
      ) : null}
    </Container>
  );
};

export default TunifyGreen;
