import { useCallback } from 'react';
import styled from 'styled-components';

import { capitalize } from '../../../../common';
import { ReactComponent as Playlists } from '../../../../assets/icons/navigation/playlists.svg';
import { SelectablePlaylistButton } from '../../shared';
import { usePlaylists } from '../playlistsProvider';
import { PlaylistTypes } from '../types';
import theme from '../../../../global/style/theme';

const Box = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  border: 1px solid ${({ theme }) => theme.stroke};
  border-radius: 4px;
  display: grid;
  align-items: center;
  justify-content: center;
`;

const MusicCollection = ({ musicCollection, musicChannelId }) => {
  const { dispatch, selectedPlaylist } = usePlaylists();

  const handleClickListItem = useCallback(() => {
    dispatch({
      selectedPlaylistType: PlaylistTypes.TUNIFY,
      selectedPlaylist: {
        id: musicCollection.id,
        title: musicCollection.title,
        musicCollectionId: musicCollection.id,
        musicChannelId,
      },
    });
  }, [dispatch, musicCollection, musicChannelId]);

  return (
    <SelectablePlaylistButton
      key={musicCollection.id}
      onClick={handleClickListItem}
      selected={selectedPlaylist?.id === musicCollection.id}
      type="button"
      value={musicCollection.id}
    >
      <Box>
        <Playlists color={theme.dark400} height="1.6rem" width="1.6rem" />
      </Box>
      <p>{capitalize(musicCollection.title)}</p>
    </SelectablePlaylistButton>
  );
};

export default MusicCollection;
