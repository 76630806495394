import PartnerManagerProvider from '../partnerManagerProvider';
import CommissionRequestsTable from './commissionRequestsTable';

const PartnerManagerCommissionRequestsPage = () => (
  <PartnerManagerProvider>
    <CommissionRequestsTable />
  </PartnerManagerProvider>
);

export default PartnerManagerCommissionRequestsPage;
