import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useMediaSize } from '../../../../../common';
import { usePartner } from '../../../partnerContext';
import StatsItem from './statsItem';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  grid-template-rows: 1fr min-content 1fr;
  grid-gap: 1rem;
  border-radius: 8px;
  background: ${({ theme }) => theme.gradient.nightfall[0]};
  background: ${({ theme }) => `linear-gradient(
    155deg,
    ${theme.gradient.nightfall[0]} 0%,
    ${theme.gradient.nightfall[1]} 100%
  )`};
  padding: 2.4rem;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    grid-template-columns: repeat(4, 1fr min-content);
    grid-template-rows: unset;
    grid-gap: 2rem;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    grid-gap: 3rem;
  }
`;

const VerticalDivider = styled.div`
  background-color: ${({ theme }) => theme.white};
  opacity: 0.2;
  width: 2px;
`;
const HorizontalDivider = styled.div`
  background-color: ${({ theme }) => theme.white};
  opacity: 0.2;
  height: 2px;
  grid-column-start: span 3;
`;

const StatsCard = () => {
  const { t } = useTranslation();
  const { partnerCustomersCount } = usePartner();

  const { isWiderThanMedium, isNarrowerThanMedium } = useMediaSize();

  return (
    <Grid>
      <StatsItem label={t('partner:qualifiers')} value={partnerCustomersCount?.qualifierCustomersCount} />
      {(isWiderThanMedium || isNarrowerThanMedium) && <VerticalDivider />}
      <StatsItem label={t('partner:winners')} value={partnerCustomersCount?.winnerCustomersCount} />
      {isWiderThanMedium && <VerticalDivider />}
      {isNarrowerThanMedium && <HorizontalDivider />}
      <StatsItem label={t('partner:activeCustomers')} value={partnerCustomersCount?.activeCustomersCount} />
      {(isWiderThanMedium || isNarrowerThanMedium) && <VerticalDivider />}
      <StatsItem label={t('partner:totalCustomers')} value={partnerCustomersCount?.referredCustomersCount} />
    </Grid>
  );
};

export default StatsCard;
