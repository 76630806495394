import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { GridTable, formatDate } from '../../../common';
import { ReactComponent as AngleDown } from '../../../assets/icons/angleDown.svg';
import { ReactComponent as AngleUp } from '../../../assets/icons/angleUp.svg';
import theme from '../../../global/style/theme';

const DesktopRow = styled(GridTable.Row)`
  display: none;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    display: grid;
    color: ${({ theme }) => theme.dark400};
  }
`;

const TabletRow = styled(GridTable.Row)`
  display: none;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    display: grid;
    ${({ templateColumns }) =>
      templateColumns && `grid-template-columns: ${templateColumns};`}
    column-gap: 1.5rem;
    align-items: center;
    color: ${({ theme }) => theme.dark400};
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    display: none;
  }
`;

const MobileRow = styled(GridTable.Row)`
  display: grid;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  color: ${({ theme }) => theme.dark400};

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    display: none;
  }
`;

const AmountWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1rem;
  align-items: center;
  cursor: pointer;
`;

const AmountValue = styled.div`
  display: grid;
  grid-template-columns: min-content min-content;
  gap: 0.6rem;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.stroke};
  background-color: ${({ theme }) => theme.uiBackground};
  padding: 0.4rem;
  border-radius: 4px;
  font-size: 1.6rem;
`;

const Head = styled(GridTable.Value)`
  color: ${({ theme }) => theme.dark200};
`;

const BoldValue = styled(GridTable.Value)`
  font-weight: 600;
`;

const LeftWrapper = styled.div`
  display: grid;
  padding: 0.2rem 0;
`;

const LeftDate = styled(GridTable.Value)`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.dark300};
`;

const MobileTop = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;
  gap: 1rem;
  cursor: pointer;

  ${({ isOpen }) =>
    isOpen &&
    css`
      padding-bottom: 0.8rem;
    `}
`;

const PartnerTableRow = ({ partner }) => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const impersonateUser = useCallback(() => {
    navigate(`/impersonation/user/${partner.ownerId}?from=/partner`);
  }, [navigate, partner]);

  const toggleCard = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  return (
    <>
      <DesktopRow
        onClick={impersonateUser}
        templateColumns="2fr 2fr 1fr 1fr 1fr"
      >
        <GridTable.Value>{partner.name}</GridTable.Value>
        <GridTable.Value>
          {formatDate(partner.nextCommissionDate, i18n.language, {
            outputFormat: { day: 'numeric', month: 'long', year: 'numeric' },
          })}
        </GridTable.Value>
        <GridTable.Value>{partner.qualifierCustomersCount}</GridTable.Value>
        <GridTable.Value>{partner.winnerCustomersCount}</GridTable.Value>
        <GridTable.Value>{partner.referredCustomersCount}</GridTable.Value>
      </DesktopRow>
      <TabletRow onClick={impersonateUser} templateColumns="2fr 1fr 1fr 1fr">
        <LeftWrapper>
          <GridTable.Value>{partner.name}</GridTable.Value>
          <LeftDate>
            {formatDate(partner.nextCommissionDate, i18n.language, {
              outputFormat: { day: 'numeric', month: 'long', year: 'numeric' },
            })}
          </LeftDate>
        </LeftWrapper>
        <GridTable.Value>{partner.qualifierCustomersCount}</GridTable.Value>
        <GridTable.Value>{partner.winnerCustomersCount}</GridTable.Value>
        <GridTable.Value>{partner.referredCustomersCount}</GridTable.Value>
      </TabletRow>
      <MobileRow>
        <MobileTop onClick={toggleCard} isOpen={isOpen}>
          <LeftWrapper>
            <BoldValue>{partner.name}</BoldValue>
            <LeftDate>
              {formatDate(partner.nextCommissionDate, i18n.language, {
                outputFormat: {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                },
              })}
            </LeftDate>
          </LeftWrapper>
          {isOpen ? (
            <AngleUp width="1.2rem" height="1.2rem" color={theme.dark200} />
          ) : (
            <AngleDown width="1.2rem" height="1.2rem" color={theme.dark200} />
          )}
        </MobileTop>
        {isOpen ? (
          <AmountWrapper onClick={impersonateUser}>
            <AmountValue>
              <Head>
                {t('partnerManager:partners.tableHeader.qualifiers')
                  .charAt(0)
                  .toUpperCase()}
              </Head>
              <GridTable.Value>
                {partner.qualifierCustomersCount}
              </GridTable.Value>
            </AmountValue>
            <AmountValue>
              <Head>
                {t('partnerManager:partners.tableHeader.winners')
                  .charAt(0)
                  .toUpperCase()}
              </Head>
              <GridTable.Value>{partner.winnerCustomersCount}</GridTable.Value>
            </AmountValue>
            <AmountValue>
              <Head>
                {t('partnerManager:partners.tableHeader.referredCustomers')
                  .charAt(0)
                  .toUpperCase()}
              </Head>
              <GridTable.Value>
                {partner.referredCustomersCount}
              </GridTable.Value>
            </AmountValue>
          </AmountWrapper>
        ) : null}
      </MobileRow>
    </>
  );
};

export default PartnerTableRow;
