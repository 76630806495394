import gql from 'graphql-tag';

const GET_ZONE = gql`
  query getZone($zoneId: ID!) {
    zone(id: $zoneId) {
      id
      name
      location {
        id
        organization {
          id
        }
      }
    }
  }
`;

export { GET_ZONE };
