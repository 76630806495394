import { useMutation } from '@apollo/react-hooks';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled, { css } from 'styled-components';

import {
  ErrorMessage,
  HiddenIngenicoForm,
  LoadingIndicator,
  SelectCard,
  SwitchField,
} from '../../../../common';
import { TermsAndConditions } from '../../../../common/termsAndConditions';
import config from '../../../../config';
import { useAuth } from '../../../../global/auth/newAuthProvider';
import { CONFIRM_SALE_ORDER_WITHOUT_PAYMENT, PREPARE_PAYMENT } from '../api';
import { PurchaseOverview } from '../cards';
import { usePurchase } from '../purchaseContext';
import { MaxChildHeightCase, MaxChildHeightContainer } from '../../../../common/maxChildHeightContainer';
import { ReactComponent as InfoIcon } from '../../../../assets/icons/smallInfo.svg';

const { BASE_URL } = config;

const Container = styled.div`
  display: grid;
  gap: 2rem;
`;

const Text = styled.div`
  font-size: 1.2rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.dark200};
`;

const SelectCardWrapper = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  gap: 1rem;
`;

const Label = styled.h3`
  font-size: 1.4rem;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Options = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Image = styled.img`
  border-radius: 4px;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 2rem;
`;

const InvisibleContainer = styled.div`
  ${({ $hidden}) => $hidden && css`
    visibility: hidden;
  `}
`;

const InfoDiv = styled.div`
  display: flex;
  font-size: 1.4rem;
  line-height: 1.4;
`;

const BlackenedInfoIcon = styled(InfoIcon)`
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 1.4rem;
  margin-top: 0.28rem;
  path {
    stroke: currentColor;
  }
`;

const ConfirmWithoutPaymentStep = ({
  setGoToNextStepAction,
  setGoToNextStepLoading,
  setGoToNextStepError,
  goToNextStep,
  setCanProceedToNextStep,
}) => {
  const {
    saleOrderLoading,
    saleOrderError,
    userInput: { selectedPaymentOption, wantsForeverProduct },
    handleToggleForeverSwitch,
    buildSaleOrder,
    selectedProductCatalogue,
    saleOrder,
    paymentOptionsLoading,
    paymentOptionsError,
    availablePaymentOptions,
    handleSelectPaymentOption,
    organizationDetails,
    selectedAdditionalZones,
    currentZone,
    selectedProduct,
  } = usePurchase();

  const [isTermsAndConditionsChecked, setIsTermsAndConditionsChecked] =
    useState(selectedProductCatalogue.id !== 'paid');

  const navigate = useNavigate();
  const { organizationId, locationId, zoneId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const { user } = useAuth();

  const formRef = useRef(null);

  useEffect(() => {
    // Whenever this step is mounted, keep the sale order up-to-date
    if (selectedProduct?.id && currentZone?.id) {
      buildSaleOrder();
    }
  }, [buildSaleOrder, currentZone?.id, selectedProduct?.id]);

  const [confirmSaleOrderWithoutPayment] = useMutation(
    CONFIRM_SALE_ORDER_WITHOUT_PAYMENT,
    {
      variables: { saleOrderId: saleOrder?.id },
      onCompleted: () => {
        setGoToNextStepLoading(false);
        setGoToNextStepError(null);
        navigate('/subscriptions');
      },
      onError: (err) => {
        setGoToNextStepLoading(false);
        setGoToNextStepError(err);
      },
      refetchQueries: ['getSubscriptionsForOrganizationAndOrganizations'],
      awaitRefetchQueries: true,
    }
  );

  const [preparePayment] = useMutation(PREPARE_PAYMENT, {
    onCompleted: ({ preparePayment: preparePaymentResult }) => {
      setGoToNextStepError(null);
      if (preparePaymentResult.wireTransferReference) {
        // User selected wire transfer and will not be redirected to Ingenico
        const { amount, wireTransferReference, iban } = preparePaymentResult;
        navigate(
          `/organizations/${organizationId}/locations/${locationId}/zones/${zoneId}/purchase/wire-transfer`,
          {
            state: { amount, iban, wireTransferReference },
          }
        );
      } else {
        formRef.current.PSPID.value = preparePaymentResult.ogonePspid;
        formRef.current.ORDERID.value = preparePaymentResult.reference;
        formRef.current.AMOUNT.value = Number(preparePaymentResult.amount);
        formRef.current.CURRENCY.value = preparePaymentResult.currency;
        formRef.current.SHASIGN.value = preparePaymentResult.ingenicoShaIn;
        formRef.current.ALIAS.value = preparePaymentResult.ogoneAlias;
        formRef.current.ALIASUSAGE.value = preparePaymentResult.ogoneAliasUsage;
        formRef.current.OWNERCTY.value = preparePaymentResult.ownerCountryCode;
        formRef.current.submit();
      }
    },
    onError: (err) => {
      setGoToNextStepLoading(false);
      setGoToNextStepError(err);
    },
  });

  const handlePay = useCallback(() => {
    const inputs = formRef.current.querySelectorAll('input');
    const newPaymentValues = {};
    inputs.forEach((input) => {
      if (
        input.name !== 'PSPID' &&
        input.name !== 'ORDERID' &&
        input.name !== 'AMOUNT' &&
        input.name !== 'CURRENCY' &&
        input.name !== 'SHASIGN' &&
        input.name !== 'ALIAS' &&
        input.name !== 'ALIASUSAGE'
      ) {
        newPaymentValues[input.name] = input.value;
      }
    });
    preparePayment({
      variables: {
        ingenicoParams: newPaymentValues,
        saleOrderId: Number(saleOrder.id),
        paymentAcquirerId: Number(selectedPaymentOption.paymentAcquirerId),
        paymentIconId: Number(selectedPaymentOption.id),
      },
    });
  }, [preparePayment, selectedPaymentOption, saleOrder]);

  useEffect(() => {
    setGoToNextStepAction(() => () => {
      setGoToNextStepLoading(true);
      if (selectedProductCatalogue.id === 'paid') {
        handlePay();
      } else {
        confirmSaleOrderWithoutPayment();
      }
    });
    return () => {
      setGoToNextStepError(null);
      setGoToNextStepAction(() => goToNextStep);
    };
  }, [
    setGoToNextStepAction,
    setGoToNextStepError,
    goToNextStep,
    confirmSaleOrderWithoutPayment,
    setGoToNextStepLoading,
    selectedProductCatalogue,
    handlePay,
  ]);

  useEffect(() => {
    if (
      !isTermsAndConditionsChecked ||
      saleOrderLoading ||
      saleOrderError ||
      paymentOptionsLoading ||
      paymentOptionsError ||
      (selectedProductCatalogue.id === 'paid' && !selectedPaymentOption)
    ) {
      setCanProceedToNextStep(false);
    } else {
      setCanProceedToNextStep(true);
    }

    return () => {
      setCanProceedToNextStep(true);
    };
  }, [
    setCanProceedToNextStep,
    saleOrderLoading,
    saleOrderError,
    selectedProductCatalogue,
    selectedPaymentOption,
    paymentOptionsLoading,
    paymentOptionsError,
    isTermsAndConditionsChecked,
  ]);

  const handleSelect = useCallback(
    (id) => {
      if (id !== selectedPaymentOption?.id) {
        const paymentOption = availablePaymentOptions.find(
          (po) => po.id === id
        );
        handleSelectPaymentOption(paymentOption);
      }
    },
    [selectedPaymentOption, handleSelectPaymentOption, availablePaymentOptions]
  );

  useEffect(() => {
    let paramContent = {
      zoneIds: selectedAdditionalZones.map((az) => az.id).concat(zoneId).join(','),
    };
    if (saleOrder?.id) {
      paramContent = { ...paramContent, saleOrderId: saleOrder.id };
    }
    setSearchParams(paramContent);
  }, [saleOrder?.id, selectedAdditionalZones, setSearchParams, zoneId]);

  const paramPlus = useMemo(() => searchParams.toString(), [searchParams]);

  const handleTermsAndConditionsChange = useCallback((event) => {
    setIsTermsAndConditionsChecked(event.target.checked);
  }, []);

  const selectOptions = useMemo(
    () =>
      availablePaymentOptions.map((paymentOption) => (
        <SelectCard
          key={paymentOption.id}
          activeSelectCardId={selectedPaymentOption?.id}
          id={paymentOption.id}
          onClick={handleSelect}
          title={
            <SelectCardWrapper>
              <Image src={`data:image/png;base64,${paymentOption.image}`} />
              <Label>{paymentOption.name}</Label>
            </SelectCardWrapper>
          }
        ></SelectCard>
      )),
    [availablePaymentOptions, handleSelect, selectedPaymentOption]
  );

  return selectedProductCatalogue.id === 'paid' ? (
    <>
      <Container>
        <PurchaseOverview />

        {paymentOptionsError ? <ErrorMessage error={paymentOptionsError} /> : null}
        {paymentOptionsLoading ? <LoadingIndicator /> : null}
        {!paymentOptionsLoading && !paymentOptionsError ? (
          availablePaymentOptions.length === 0 ? (
            <ErrorMessage error={t('subscriptions:noPaymentOptions')} />
          ) : (
            <Options>{selectOptions}</Options>
          )
        ) : null}

        {!paymentOptionsError && !paymentOptionsLoading && availablePaymentOptions.length > 0 ? (
          <>
            <MaxChildHeightContainer>
              <MaxChildHeightCase visible={selectedPaymentOption?.supportsRecurringBilling}>
                <SwitchField
                  checked={wantsForeverProduct}
                  isDisabled={paymentOptionsLoading || saleOrderLoading}
                  onChange={handleToggleForeverSwitch}
                  label={t('subscriptions:isForeverLabel')}
                />
                <Text>{t('subscriptions:isForeverNote')}</Text>
              </MaxChildHeightCase>
              <MaxChildHeightCase visible={!selectedPaymentOption?.supportsRecurringBilling}>
                <InfoDiv>
                  <BlackenedInfoIcon />
                  {t('subscriptions:recurringBillingUnsupported')}
                </InfoDiv>
                <InvisibleContainer $hidden={!selectedPaymentOption?.isWireTransfer}>
                  <StyledErrorMessage error={t('subscriptions:warningIsWireTransfer')} />
                </InvisibleContainer>
              </MaxChildHeightCase>
            </MaxChildHeightContainer>

            <TermsAndConditions onChange={handleTermsAndConditionsChange} />
          </>
        ): null}
      </Container>
      <HiddenIngenicoForm
        ref={formRef}
        brand={(selectedPaymentOption && selectedPaymentOption.brand) || ''}
        cn={`${user.firstName} ${user.lastName}`}
        email={user.emailAddress}
        language={user.lang}
        orderId={(saleOrder && saleOrder.id) || ''}
        organizationId={organizationId}
        ownerAddress={`${organizationDetails.streetName} ${organizationDetails.streetNumber}`}
        ownerCty={organizationDetails.countryId}
        ownerTown={organizationDetails.city}
        ownerZip={organizationDetails.zip}
        paramPlus={paramPlus}
        pm={(selectedPaymentOption && selectedPaymentOption.pm) || ''}
        redirectUrlOnAccept={`${BASE_URL}/payment-feedback/accept`}
        redirectUrlOnCancel={`${BASE_URL}/payment-feedback/cancel`}
        redirectUrlOnDecline={`${BASE_URL}/payment-feedback/decline`}
        redirectUrlOnException={`${BASE_URL}/payment-feedback/exception`}
      />
    </>
  ) : (
    <Text>{t('subscriptions:confirmWithoutPaymentStep:warning')}</Text>
  );
};

export default ConfirmWithoutPaymentStep;
