import { createContext, useContext, useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';

import { GET_TUNIFY_CALENDAR_GROUPS } from './api';
import { CategoryTypes } from './types';

const tunifyGreenReducer = (state, payload) => {
  return {
    ...state,
    ...payload,
  };
};

const initialTunifyGreenState = {
  selectedCategory: null,
  selectedCalendarType: CategoryTypes.TUNIFY,
  selectedCalendar: null,
  overId: null,
};

const TunifyGreenContext = createContext();

export const TunifyGreenManager = ({ children, profile }) => {
  const { i18n } = useTranslation();
  const [state, dispatch] = useReducer(tunifyGreenReducer, initialTunifyGreenState);

  const { data: dataTunifyCalendarGroups, loading: loadingTunifyCalendarGroups } = useQuery(
    GET_TUNIFY_CALENDAR_GROUPS,
    {
      variables: {
        musicProfileId: profile.tunifyMusicProfileUserId,
        language: i18n.language,
      },
    }
  );

  const value = useMemo(
    () => ({
      ...state,
      dispatch,
      tunifyCalendars: dataTunifyCalendarGroups?.tunifyCalendarGroups ?? [],
      loadingTunifyCalendars: loadingTunifyCalendarGroups,
      musicProfile: profile,
    }),
    [state, dataTunifyCalendarGroups?.tunifyCalendarGroups, loadingTunifyCalendarGroups, profile]
  );

  return <TunifyGreenContext.Provider value={value}>{children}</TunifyGreenContext.Provider>;
};

export const useTunifyGreen = () => {
  return useContext(TunifyGreenContext);
};
