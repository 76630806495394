import { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../../common';
import { ReactComponent as AddonsLarge } from '../../../../assets/icons/navigation/addOnLarge.svg';
import { ReactComponent as InfoIcon } from '../../../../assets/icons/infoBasic.svg';
import theme from '../../../../global/style/theme';
import { useAddonsOverview } from '../addonsOverviewContext';

const Container = styled.div`
  display: grid;
  gap: 2.4rem;
  background-color: ${({ theme }) => theme.white};
  padding: 1.5rem 1.5rem 0 1.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.stroke};

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    padding: 2.2rem 3rem 0 3rem;
    gap: 3rem;
  }
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    padding: 3.2rem 4rem 0 4rem;
  }
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    padding: 4rem 4.8rem 0 4.8rem;
  }
`;

const TitleContainer = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  align-items: center;
  gap: 1.6rem;
  grid-column: span 2;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    grid-column: unset;
    grid-template-columns: min-content 1fr min-content 2fr;
  }
`;

const Top = styled.div`
  display: grid;
  row-gap: 1rem;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
`;

const Logo = styled.div`
  width: 4rem;
  height: 4rem;
  display: grid;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.dark500};
  border-radius: 4px;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    width: 4.8rem;
    height: 4.8rem;
  }
`;

const StyledAddonsLarge = styled(AddonsLarge)`
  width: 2.6rem;
  height: 2.6rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    width: 3.2rem;
    height: 3.2rem;
  }
`;

const StyledInfoIcon = styled(InfoIcon)`
  cursor: pointer;
  width: 2rem;
  height: 2rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const Title = styled.h1`
  font-size: 2.4rem;
  font-weight: 600;
  white-space: nowrap;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    font-size: 2.8rem;
  }
`;

const Bottom = styled.div`
  display: grid;
  gap: 3rem;
  grid-template-columns: repeat(2, max-content);
  overflow-x: auto;
`;

const TabButton = styled(Button)`
  white-space: nowrap;
  padding-bottom: 1rem;
  border-width: 4px;
  color: ${({ theme }) => theme.dark500};
  font-weight: 600;

  ${({ selected }) =>
    selected
      ? css``
      : css`
          border: none;
          padding-bottom: 1.4rem;
        `}
`;

const Description = styled.p`
  display: none;
  font-size: 1rem;
  font-family: 'Inter';
  line-height: 1.6;
  color: ${({ theme }) => theme.dark300};

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    display: flex;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    font-size: 1.4rem;
    line-height: 1.5;
  }
`;

const StyledLink = styled.a`
  padding: 1rem;
`;

const PageHeader = () => {
  const { t } = useTranslation();
  const {
    addonsCategories,
    selectedAddonsCategory,
    setSelectedAddonsCategory,
  } = useAddonsOverview();

  const handleSelect = useCallback(
    (id) => {
      if (id !== selectedAddonsCategory.id) {
        setSelectedAddonsCategory(
          addonsCategories.find((bcc) => bcc.id === id)
        );
      }
    },
    [addonsCategories, selectedAddonsCategory.id, setSelectedAddonsCategory]
  );

  return (
    <Container>
      <Top>
        <TitleContainer>
          <Logo>
            <StyledAddonsLarge color={theme.white} />
          </Logo>
          <Title>{t('addons:title')}</Title>
          <StyledLink
            href="https://www.tunify.com/en-gb/blog/tunify-news/mytunify-creating-the-perfect-music-experience-for-your-business-has-never-been-easier/"
            rel="noopener noreferrer"
            target="_blank"
            title={t('common:moreInfo')}
          >
            <StyledInfoIcon color={theme.dark300} />
          </StyledLink>
          <Description>{t('addons:overview.description')}</Description>
        </TitleContainer>
      </Top>
      <Bottom>
        {addonsCategories.map((broadcastsCategory) =>
          !broadcastsCategory.isDisabled ? (
            <TabButton
              key={broadcastsCategory.id}
              emphasis="secondary"
              selected={selectedAddonsCategory.id === broadcastsCategory.id}
              onClick={() => handleSelect(broadcastsCategory.id)}
            >
              {t(broadcastsCategory.i18nKey)}
            </TabButton>
          ) : null
        )}
      </Bottom>
    </Container>
  );
};

export default PageHeader;
