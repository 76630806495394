import { determineAvailableMusicLicenses } from '../../../common';
import { partitionArray } from '../../../common/partitionArray';
import {
  determineAvailableColors,
  determineAvailableSubscriptionTemplates,
  determineSelectedProduct,
} from './utils';

export const productCatalogueOptions = [
  {
    name: 'Paid',
    id: 'paid',
    description: 'De reguliere, betaalde producten van Tunify',
  },
  { name: 'Demo', id: 'demo', description: 'De demo producten van Tunify' },
  { name: 'Free', id: 'free', description: 'De free producten van Tunify' },
  { name: 'Trial', id: 'trial', description: 'De trial producten van Tunify' },
];

export const PurchaseAction = {
  FETCHED_ORGANIZATION_DETAILS: 'FETCHED_ORGANIZATION_DETAILS',
  SELECT_PRODUCT_CATALOGUE: 'SELECT_PRODUCT_CATALOGUE',
  FETCHED_PRODUCT_CATALOGUE: 'FETCHED_PRODUCT_CATALOGUE',
  SELECT_MUSIC_LICENSE: 'SELECT_MUSIC_LICENSE',
  SELECT_COLOR: 'SELECT_COLOR',
  SELECT_SUBSCRIPTION_TEMPLATE_GROUP: 'SELECT_SUBSCRIPTION_TEMPLATE_GROUP',
  SELECT_ADDON: 'SELECT_ADDON',
  DESELECT_ADDON: 'DESELECT_ADDON',
  SELECT_ADDITIONAL_ZONE: 'SELECT_ADDITIONAL_ZONE',
  DESELECT_ADDITIONAL_ZONE: 'DESELECT_ADDITIONAL_ZONE',
  EDIT_ORGANIZATION_DETAILS: 'EDIT_ORGANIZATION_DETAILS',
  SELECT_PAYMENT_OPTION: 'SELECT_PAYMENT_OPTION',
  TOGGLE_FOREVER_PRODUCT_SWITCH: 'TOGGLE_FOREVER_PRODUCT_SWITCH',
  FETCHED_PAYMENT_OPTIONS: 'FETCHED_PAYMENT_OPTIONS',
  FETCHED_SALE_ORDER: 'FETCHED_SALE_ORDER',
  FETCHED_ADD_ONS: 'FETCHED_ADD_ONS',
};

export const initialPurchaseState = {
  userInput: {
    selectedMusicLicense: null,
    selectedColor: null,
    selectedSubscriptionTemplateGroup: null,
    selectedAddons: [],
    selectedAdditionalZones: [],
    selectedPaymentOption: null,
    wantsForeverProduct: true,
  },
  saleOrderNeedsToBeFetched: true,
  saleOrder: null,
  alternativeRenewalSaleOrder: null,
  selectedProduct: null,
  alternativeRenewalProduct: null,
  productCatalogue: [],
  organizationDetails: null,
  availableMusicLicenses: [],
  availableColors: [],
  availablePaymentOptions: [],
  availableSubscriptionTemplates: [],
  availableSubscriptionTemplateGroups: [],
  availableAdditionalZones: [],
  availableAddons: [],
  // Initially we select the paid product catalogue,
  // as is the case without the product catalogue step
  selectedProductCatalogue: productCatalogueOptions.find(
    (pc) => pc.id === 'paid'
  ),
};

export const purchaseReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case PurchaseAction.SELECT_PRODUCT_CATALOGUE:
      return {
        ...state,
        userInput: {
          ...state.userInput,
          selectedMusicLicense: null,
          selectedColor: null,
          selectedSubscriptionTemplateGroup: null,
          selectedAddons: [],
          // The product catalogues demo, free and trial have only fixed products,
          // so set the wantsForeverProduct userInput to false
          wantsForeverProduct: payload.productCatalogue.id === 'paid',
        },
        saleOrderNeedsToBeFetched: true,
        saleOrder: null,
        alternativeRenewalSaleOrder: null,
        selectedProduct: null,
        availableColors: [],
        availableSubscriptionTemplates: [],
        availableSubscriptionTemplateGroups: [],
        availablePaymentOptions: [],
        selectedProductCatalogue: payload.productCatalogue,
      };

    case PurchaseAction.FETCHED_PRODUCT_CATALOGUE:
      return {
        ...state,
        productCatalogue: payload.productCatalogue,
        availableMusicLicenses: determineAvailableMusicLicenses(
          payload.productCatalogue,
          'either'
        ),
      };

    case PurchaseAction.FETCHED_ORGANIZATION_DETAILS:
      return {
        ...state,
        organizationDetails: payload.organizationDetails,
        availableAdditionalZones: payload.organizationDetails.zones.filter(
          ({ id }) => id !== payload.currentZoneId
        ),
      };

    case PurchaseAction.FETCHED_ADD_ONS:
      return {
        ...state,
        availableAddons: payload.availableAddons,
      };

    case PurchaseAction.SELECT_MUSIC_LICENSE:
      return {
        ...state,
        userInput: {
          ...state.userInput,
          selectedMusicLicense: payload.musicLicense,
          selectedColor: null,
          selectedSubscriptionTemplateGroup: null,
          selectedAddons: [],
        },
        saleOrderNeedsToBeFetched: true,
        saleOrder: null,
        alternativeRenewalSaleOrder: null,
        selectedProduct: null,
        availableColors: determineAvailableColors(
          state.productCatalogue,
          [payload.musicLicense.id],
          'either'
        ),
        availableSubscriptionTemplates: [],
        availableSubscriptionTemplateGroups: [],
        availablePaymentOptions: [],
      };

    case PurchaseAction.SELECT_COLOR: {
      const availableSubscriptionTemplates = determineAvailableSubscriptionTemplates(
        state.productCatalogue,
        [state.userInput.selectedMusicLicense.id],
        [payload.color.id],
        'either'
      );
      const partitions = partitionArray(
        availableSubscriptionTemplates,
        (template) => `${JSON.stringify(template.recurringInterval)}:${JSON.stringify(template.recurringRuleType)}`
      );
      return {
        ...state,
        userInput: {
          ...state.userInput,
          selectedColor: payload.color,
          selectedSubscriptionTemplateGroup: null,
          selectedAddons: [],
        },
        saleOrderNeedsToBeFetched: true,
        saleOrder: null,
        alternativeRenewalSaleOrder: null,
        selectedProduct: null,
        availableSubscriptionTemplates,
        availableSubscriptionTemplateGroups: Object.keys(partitions).map((key) => partitions[key]),
        availablePaymentOptions: [],
      };
    }

    case PurchaseAction.SELECT_SUBSCRIPTION_TEMPLATE_GROUP: {
      const selectedProduct = determineSelectedProduct(
        state.productCatalogue,
        [state.userInput.selectedMusicLicense.id],
        [state.userInput.selectedColor.id],
        payload.subscriptionTemplateGroup.map(template => template.id),
        state.userInput.wantsForeverProduct
      );
      const otherProduct = (() => {
        if (state.selectedProductCatalogue?.id !== 'paid') {
          return null;
        }
        try {
          return determineSelectedProduct(
            state.productCatalogue,
            [state.userInput.selectedMusicLicense.id],
            [state.userInput.selectedColor.id],
            payload.subscriptionTemplateGroup.map(template => template.id),
            !state.userInput.wantsForeverProduct
          );
        } catch (error) {
          console.error('Failed to switch products.', error);
          return null;
        }
      })();

      return {
        ...state,
        userInput: {
          ...state.userInput,
          selectedSubscriptionTemplateGroup: payload.subscriptionTemplateGroup,
          selectedAddons: [],
        },
        saleOrderNeedsToBeFetched: true,
        saleOrder: null,
        alternativeRenewalSaleOrder: null,
        selectedProduct,
        alternativeRenewalProduct: otherProduct,
        availablePaymentOptions: [],
      };
    }

    case PurchaseAction.SELECT_ADDON:
      return {
        ...state,
        userInput: {
          ...state.userInput,
          selectedAddons: [...state.userInput.selectedAddons, payload.addon],
        },
        saleOrderNeedsToBeFetched: true,
        saleOrder: null,
        alternativeRenewalSaleOrder: null,
      };

    case PurchaseAction.DESELECT_ADDON:
      return {
        ...state,
        userInput: {
          ...state.userInput,
          selectedAddons: state.userInput.selectedAddons.filter(
            (selectedAddon) => selectedAddon.id !== payload.addon.id
          ),
        },
        saleOrderNeedsToBeFetched: true,
        saleOrder: null,
        alternativeRenewalSaleOrder: null,
      };

    case PurchaseAction.SELECT_ADDITIONAL_ZONE:
      return {
        ...state,
        userInput: {
          ...state.userInput,
          selectedAdditionalZones:
            state.userInput.selectedAdditionalZones.concat(
              payload.additionalZone
            ),
        },
        saleOrderNeedsToBeFetched: true,
        saleOrder: null,
        alternativeRenewalSaleOrder: null,
      };

    case PurchaseAction.DESELECT_ADDITIONAL_ZONE:
      return {
        ...state,
        userInput: {
          ...state.userInput,
          selectedAdditionalZones:
            state.userInput.selectedAdditionalZones.filter(
              (zone) => zone.id !== payload.additionalZone.id
            ),
        },
        saleOrderNeedsToBeFetched: true,
        saleOrder: null,
        alternativeRenewalSaleOrder: null,
      };

    case PurchaseAction.EDIT_ORGANIZATION_DETAILS:
      // Whenever the country or VAT has changed, create a new sale order
      // because the old sale order will still have the old organization details.
      const countryHasChanged =
        state.organizationDetails.countryId !==
        payload.organizationDetails.countryId;
      const vatHasChanged =
        state.organizationDetails.vat !== payload.organizationDetails.vat;

      return {
        ...state,
        organizationDetails: {
          ...state.organizationDetails,
          ...payload.organizationDetails,
        },
        saleOrderNeedsToBeFetched:
          countryHasChanged || vatHasChanged
            ? true
            : state.saleOrderNeedsToBeFetched,
        availablePaymentOptions:
          countryHasChanged
            ? []
            : state.availablePaymentOptions,
        saleOrder: countryHasChanged || vatHasChanged ? null : state.saleOrder,
        alternativeRenewalSaleOrder: countryHasChanged || vatHasChanged ? null : state.alternativeRenewalSaleOrder,
      };

    case PurchaseAction.SELECT_PAYMENT_OPTION:
      return {
        ...state,
        userInput: {
          ...state.userInput,
          wantsForeverProduct: payload.paymentOption.supportsRecurringBilling,
          selectedPaymentOption: payload.paymentOption,
        },
        ...payload.paymentOption.supportsRecurringBilling !== state.userInput.wantsForeverProduct ? {
          saleOrderNeedsToBeFetched: !state.alternativeRenewalSaleOrder,
          saleOrder: state.alternativeRenewalSaleOrder,
          alternativeRenewalSaleOrder: state.saleOrder,
          selectedProduct: state.alternativeRenewalProduct,
          alternativeRenewalProduct: state.selectedProduct,
        } : null,
      };

    case PurchaseAction.TOGGLE_FOREVER_PRODUCT_SWITCH:
      if (state.userInput.selectedPaymentOption && !state.userInput.selectedPaymentOption.supportsRecurringBilling) {
        return state;
      }
      return {
        ...state,
        userInput: {
          ...state.userInput,
          wantsForeverProduct: payload.wantsForeverProduct,
        },
        saleOrderNeedsToBeFetched: !state.alternativeRenewalSaleOrder,
        saleOrder: state.alternativeRenewalSaleOrder,
        alternativeRenewalSaleOrder: state.saleOrder,
        selectedProduct: state.alternativeRenewalProduct,
        alternativeRenewalProduct: state.selectedProduct,
      };

    case PurchaseAction.FETCHED_PAYMENT_OPTIONS:
      const selectedPaymentOption =
        state.selectedPaymentOption ||
        payload.paymentOptions.find(option => option.name.toLowerCase() === 'visa') ||
        payload.paymentOptions[0] ||
        null;
      return {
        ...state,
        userInput: {
          ...state.userInput,
          selectedPaymentOption,
          wantsForeverProduct: selectedPaymentOption?.supportsRecurringBilling || false,
        },
        availablePaymentOptions: payload.paymentOptions,
      };

    case PurchaseAction.FETCHED_SALE_ORDER: {
      if (payload.productId !== state.selectedProduct?.id) {
        return state;
      }
      const forSelected = Boolean(payload.wantedForeverProduct) === Boolean(state.userInput.wantsForeverProduct);
      return {
        ...state,
        saleOrder: forSelected ? payload.saleOrder : state.saleOrder,
        alternativeRenewalSaleOrder: forSelected ? state.alternativeRenewalSaleOrder : payload.saleOrder,
        saleOrderNeedsToBeFetched: false,
      };
    }

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
