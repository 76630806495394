import styled, { css } from 'styled-components';

const Container = styled.span`
  font-size: 1.2rem;
  line-height: 1.2;
  color: ${({ theme }) => theme.dark400};
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
`;

const TableHead = ({ children, className, ...props }) => {
  return (
    <Container className={className} {...props}>
      {children}
    </Container>
  );
};

export default TableHead;
