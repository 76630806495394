import { useCallback, useMemo, useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import styled from 'styled-components';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import {
  ErrorMessage,
  LoadingIndicator,
  useBlocker,
  Modal,
  Button,
  useToggle,
} from '../../../../../common';
import {
  GET_ORGANIZATION_WITH_LOCATIONS,
  CHANGE_MESSAGE_PROFILE_OF_ZONES,
} from './api';
import Footer from '../../footer';
import LocationCard from './cards/locationCard';
import { ReactComponent as AngleDownIcon } from '../../../../../assets/icons/angleDown.svg';
import { ReactComponent as AngleUpIcon } from '../../../../../assets/icons/angleUp.svg';
import theme from '../../../../../global/style/theme';

const StyledErrorMessage = styled(ErrorMessage)`
  width: 100%;
  margin-top: 2rem;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin-top: 2rem;
`;

const StyledForm = styled.form`
  display: grid;
  grid-template-rows: 1fr max-content;
  height: 100%;
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr max-content;
  height: 100%;
`;

const ContainerSizer = styled.div`
  position: relative;
`;

const ContentWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: 0 1.5rem;
  position: absolute;
  width: 100%;
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    padding: 0 2.4rem;
  }
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    padding: 0 4.6rem;
  }
`;

const ModalDescription = styled.p`
  color: ${({ theme }) => theme.dark300};
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.4;
`;

const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;
  justify-items: end;
  gap: 1.2rem;
  padding: 2rem 0 1rem 0;
`;

const AddonsBroadcastsTab = () => {
  const { t } = useTranslation();
  const formMethods = useForm();

  const [forceRender, setForceRender] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [expandAllLocations, toggleExpandAllLocations] = useToggle(true);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [isRedirect, setIsRedirect] = useState(false);

  const handleBlockAction = useCallback(
    (action) => {
      // if path is the same as current don't open the modal
      if (formMethods.formState.isSubmitSuccessful) {
        setIsOpen(false);
        setIsRedirect(false);
        action.retry();
        return;
      }

      if (isRedirect) {
        action.retry();
      } else {
        if (action.location.pathname === `/add-ons`) {
          setIsRedirect(false);
          return;
        }
        setRedirectUrl(action.location.pathname);
        setIsOpen(true);
      }
    },
    [formMethods.formState.isSubmitSuccessful, isRedirect]
  );

  const isFieldsDirty = useMemo(() => {
    return (
      formMethods.formState.isDirty ||
      Object.keys(formMethods.formState.dirtyFields).length !== 0
    );
  }, [formMethods.formState.isDirty, formMethods.formState.dirtyFields]);

  useBlocker(handleBlockAction, isFieldsDirty);

  useEffect(() => {
    // Clears the "isDirty" after a successful submit
    if (formMethods.formState.isSubmitted) {
      formMethods.reset(formMethods.getValues());
    }
  }, [
    formMethods.formState.isSubmitted,
    formMethods.reset,
    formMethods.getValues,
    formMethods,
  ]);

  const {
    data: organizationData,
    loading: organizationLoading,
    error: organizationError,
  } = useQuery(GET_ORGANIZATION_WITH_LOCATIONS);

  const allLocations = useMemo(() => {
    if (!organizationData?.organizations) {
      return [];
    }
    return organizationData?.organizations?.reduce((prev, cur) => {
      return [...prev, ...cur.locations];
    }, []);
  }, [organizationData?.organizations]);

  const [
    changeMessageProfileOfZones,
    {
      loading: changeMessageProfileOfZonesLoading,
      error: changeMessageProfileOfZonesError,
    },
  ] = useMutation(CHANGE_MESSAGE_PROFILE_OF_ZONES);

  const handleSave = useCallback(
    (values) => {
      // Currently there is no other way then updating all zones from the opened location
      const zones = [].concat.apply([], Object.values(values));
      const normalizedEntries = zones
        .filter((zone) => zone?.messageProfile?.value)
        .map((changedZone) =>
          changedZone?.messageProfile?.value === '-1'
            ? { zoneId: changedZone.zoneId, messageProfileId: null }
            : {
                zoneId: changedZone.zoneId,
                messageProfileId: changedZone.messageProfile.value,
              }
        );
      changeMessageProfileOfZones({
        variables: { entries: normalizedEntries },
      });
      setIsOpen(false);
      if (redirectUrl) {
        setIsRedirect(true);
      }
    },
    [changeMessageProfileOfZones, redirectUrl]
  );

  const renderLocations = useMemo(() => {
    if (organizationError) {
      return <StyledErrorMessage error={organizationError} />;
    }
    if (organizationLoading && allLocations.length <= 0) {
      return <StyledLoadingIndicator size="large" thickness="medium" />;
    }
    return allLocations.map((location) => (
      <LocationCard
        key={location.id}
        location={location}
        expandAllLocations={expandAllLocations}
        forceRender={forceRender}
        setForceRender={setForceRender}
      />
    ));
  }, [
    allLocations,
    organizationError,
    organizationLoading,
    forceRender,
    expandAllLocations,
  ]);

  const handleCancel = useCallback(() => {
    formMethods.reset();
    setForceRender(true);
    setIsOpen(false);
    setIsRedirect(true);
  }, [formMethods]);

  if (isRedirect) {
    return <Navigate to={redirectUrl ?? `/add-ons`} />;
  }

  return (
    <FormProvider {...formMethods}>
      <StyledForm>
        <Container>
          <ContainerSizer>
            <ContentWrapper>
              {renderLocations.length !== 0 && (
                <ButtonWrapper>
                  <Button
                    emphasis="secondary"
                    size="small"
                    onClick={toggleExpandAllLocations}
                  >
                    {expandAllLocations
                      ? t('addons:thHideAllAddons')
                      : t('addons:thShowAllAddons')}
                  </Button>
                  {expandAllLocations ? (
                    <AngleUpIcon color={theme.dark300} />
                  ) : (
                    <AngleDownIcon color={theme.dark300} />
                  )}
                </ButtonWrapper>
              )}
              {renderLocations}
            </ContentWrapper>
          </ContainerSizer>
          {changeMessageProfileOfZonesError && (
            <StyledErrorMessage error={changeMessageProfileOfZonesError} />
          )}
        </Container>
        {isFieldsDirty && (
          <Footer
            isSubmitting={changeMessageProfileOfZonesLoading}
            onCancel={handleCancel}
            onCancelDisabled={!isFieldsDirty}
            onSave={formMethods.handleSubmit(handleSave)}
            onSaveDisabled={!isFieldsDirty}
          />
        )}
      </StyledForm>
      <Modal
        isOpen={isOpen}
        hideHeader
        onConfirm={formMethods.handleSubmit(handleSave)}
        onCancel={handleCancel}
        requestLoading={changeMessageProfileOfZonesLoading}
      >
        <ModalDescription>{t('addons:changes')}</ModalDescription>
      </Modal>
    </FormProvider>
  );
};

export default AddonsBroadcastsTab;
