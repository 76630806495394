import { Fragment } from 'react';
import styled from 'styled-components';

import OrderRow from './orderRow';
import PriceSummary from './priceSummary';
import { useRenewPurchase } from './renewPurchaseContext';

const Container = styled.div`
  display: grid;
  gap: 1rem;
  background-color: ${({ theme }) => theme.dark500};
  color: ${({ theme }) => theme.white};
  border-radius: 4px;
  padding: 1.4rem 2rem;
  margin-bottom: 1rem;
`;

const HorizontalRule = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.dark400};
`;

const OrderTable = () => {
  const { zones } = useRenewPurchase();

  return (
    <Container>
      {zones.map((zone, index) => (
        <Fragment key={zone.id}>
          {index > 0 ? <HorizontalRule /> : null}
          <OrderRow zone={zone} />
        </Fragment>
      ))}
      <PriceSummary />
    </Container>
  );
};

export default OrderTable;
