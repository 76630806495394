import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ReactComponent as InfoBasicIcon } from '../../../assets/icons/infoBasic.svg';
import { Button } from '../../../common';
import theme from '../../../global/style/theme';

const Container = styled.div`
  width: 100%;
  height: fit-content;
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.stroke};
  border-radius: 8px;
  display: grid;
  padding: 2.4rem;
  gap: 2.4rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Left = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  gap: 1.2rem;
`;

const Right = styled.div`
  height: min-content;
  display: grid;
  grid-auto-rows: min-content;
  gap: 1rem;
  background-color: ${({ theme }) => theme.uiBackground};
  border: 1px solid ${({ theme }) => theme.stroke};
  border-radius: 4px;
  padding: 1.5rem;
`;

const TopRight = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  gap: 0.8rem;
`;

const Title = styled.p`
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.4;
`;

const Subtitle = styled.p`
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.4;
  color: ${({ theme }) => theme.dark400};
`;

const Body = styled.p`
  font-size: 1.4rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.dark300};
`;

const SubBody = styled(Body)`
  font-size: 1.2rem;
`;

const StyledButton = styled(Button)`
  width: fit-content;
`;

const NoOrangeInfo = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleBuyNow = useCallback(() => {
    navigate('/subscriptions');
  }, [navigate]);

  return (
    <Container>
      <Left>
        <Title>{t('subscriptions:playlists.noOrangeSubscription.title')}</Title>
        <Body>{t('subscriptions:playlists.noOrangeSubscription.info')}</Body>
        <StyledButton onClick={handleBuyNow}>
          {t('subscriptions:playlists.seeSubscriptions', {
            defaultValue: 'Abonnementen bekijken',
          })}
        </StyledButton>
      </Left>
      <Right>
        <TopRight>
          <InfoBasicIcon color={theme.dark300} height="2rem" width="2rem" />
          <Subtitle>
            {t('subscriptions:playlists.noOrangeSubscription.playlistsTitle')}
          </Subtitle>
        </TopRight>
        <SubBody>
          {t('subscriptions:playlists.noOrangeSubscription.playlistsInfo')}
        </SubBody>
      </Right>
    </Container>
  );
};

export default NoOrangeInfo;
