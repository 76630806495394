import { useMutation, useQuery } from '@apollo/react-hooks';
import { useQuery as useQueryTS } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ErrorMessage,
  LoadingIndicator,
  Modal,
  predictViewerCountryCode,
} from '../../../common';
import { useAuth } from '../../../global/auth/newAuthProvider';
import { GET_INDUSTRIES_AND_COUNTRIES } from '../../../modules/auth/register/api';
import { COMPLETE_REGISTRATION, GET_PRESENT_ORGANISATION_DATA } from './api';
import { ProfileModalForm } from './form';
import { useEarlySignOutModal } from './_earlySignOutModal';
import { useLanguageGetter } from '../../../modules/languagePicker/languageGetterProvider';

export const ProfileModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  const { refetch, user } = useAuth();

  const [isRequestLoading, setIsRequestLoading] = React.useState();

  const formRef = React.useRef(undefined);

  const { data: industriesAndCountries } = useQuery(
    GET_INDUSTRIES_AND_COUNTRIES
  );

  const getLanguage = useLanguageGetter();

  const { data: countryData, isLoading: countryDataLoading } = useQueryTS({
    queryKey: ['viewCountryCode', industriesAndCountries],
    queryFn: async () => {
      if (!industriesAndCountries) return null;

      const predictedCode = await predictViewerCountryCode(getLanguage());

      if (!predictedCode) return null;

      const countryFound = industriesAndCountries.countries.find(
        (country) => country.code.toLowerCase() === predictedCode.toLowerCase()
      );

      if (!countryFound) return null;

      return {
        value: countryFound.id,
        label: countryFound.name,
      };
    },
  });

  const [completeRegistrationMutation, { error }] = useMutation(
    COMPLETE_REGISTRATION,
    {
      onError: () => setIsRequestLoading(false),
    }
  );

  const { data: organisationData, loading: isOrganisationDataLoading } =
    useQuery(GET_PRESENT_ORGANISATION_DATA, {
      variables: { userId: user.id },
    });

  const countryDropdownOptions = React.useMemo(() => {
    if (!industriesAndCountries) return [];

    return industriesAndCountries.countries.map((country) => ({
      value: country.id,
      label: country.name,
    }));
  }, [industriesAndCountries]);

  const sectorDropdownOptions = React.useMemo(() => {
    if (!industriesAndCountries) return [];

    return industriesAndCountries.industries.map((industry) => ({
      value: industry.id,
      label: industry.name,
    }));
  }, [industriesAndCountries]);

  const defaultFormValues = React.useMemo(() => {
    const organization = organisationData?.organizations[0];

    if (!organization || countryDataLoading) return;

    return {
      organisationName: organization.name,
      streetName: organization.locations[0].streetName,
      streetNumber: organization.locations[0].streetNumber,
      zip: organization.locations[0].zip,
      city: organization.locations[0].city,
      phone: organization.locations[0].phone,
      sector: sectorDropdownOptions.find(
        (entry) => entry.value === `${organization.locations[0].industryId}`
      ),
      country: countryData,
    };
  }, [
    countryData,
    countryDataLoading,
    organisationData?.organizations,
    sectorDropdownOptions,
  ]);

  const onSubmit = React.useCallback(
    async (values) => {
      setIsRequestLoading(true);
      await completeRegistrationMutation({
        variables: {
          input: {
            organisationName: values.organisationName,
            zip: values.zip,
            street: values.streetName,
            number: values.streetNumber,
            country: Number(values.country.value),
            sector: Number(values.sector.value),
            phoneNumber: values.phone,
            city: values.city,
          },
        },
      });

      await refetch({ cacheMode: 'off' });
      setIsRequestLoading(false);
      onClose();
    },
    [completeRegistrationMutation, onClose, refetch]
  );

  const handleConfirm = React.useCallback(() => {
    formRef.current?.requestSubmit();
  }, []);

  const { earlySignOutModal, openEarlySignOutModal } = useEarlySignOutModal({
    cancelLabel: t('common:signOut.early.profileCompletion.cancel'),
    confirmLabel: t('common:signOut.early.profileCompletion.confirm'),
    title: t('common:complete.profile'),
  });

  return (
    <Modal
      isOpen={isOpen}
      isClosable
      requestLoading={isRequestLoading}
      title={t('common:complete.profile')}
      onConfirm={handleConfirm}
      onClose={openEarlySignOutModal}
    >
      {isOrganisationDataLoading || !defaultFormValues ? (
        <LoadingIndicator />
      ) : (
        <>
          <ProfileModalForm
            sectorOptions={sectorDropdownOptions}
            countryOptions={countryDropdownOptions}
            defaultValues={defaultFormValues}
            submit={onSubmit}
            ref={formRef}
          />
          {error && <ErrorMessage error={error} />}
        </>
      )}
      {earlySignOutModal}
    </Modal>
  );
};
