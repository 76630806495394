import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { GridTable } from '../../../../../common';
import TableBody from './tableBody';
import TableHeader from './tableHeader';

const Container = styled.div`
  padding: 2rem 1.5rem;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    padding: 2rem 3rem;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    padding: 2rem 4rem;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.extraLarge}px) {
    padding: 2rem 4.8rem;
  }
`;

const Header = styled(GridTable.Header)`
  display: none;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    display: grid;
    ${({ templateColumns }) => templateColumns && `grid-template-columns: ${templateColumns};`}
    column-gap: 1.5rem;
    align-items: center;
  }
`;

const templateColumns = '1rem repeat(2, 2fr) 1fr repeat(4, 2fr)';

const ResultsTable = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <GridTable>
        <Header templateColumns={templateColumns}>
          <TableHeader orderKey="" text="" />
          <TableHeader orderKey="organizationName" text={t('customerSuccess:search.thOrganization')} />
          <TableHeader orderKey="locationName" text={t('customerSuccess:search.thLocation')} />
          <TableHeader orderKey="locationCity" text={t('customerSuccess:search.thCity')} />
          <TableHeader orderKey="contactName" text={t('customerSuccess:search.thContactPerson')} />
          <TableHeader orderKey="contactPhone" text={t('customerSuccess:search.thPhone')} />
          <TableHeader orderKey="actionName" text={t('customerSuccess:search.thAction')} />
          <TableHeader orderKey="actionDate" text={t('customerSuccess:search.thActionDate')} />
        </Header>
        <TableBody />
      </GridTable>
    </Container>
  );
};

export default ResultsTable;
