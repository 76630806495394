import { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import ResultRow from './tableRow';
import { LoadingIndicator, GridTable } from '../../../../../common';
import { useCustomerSuccessSearch } from '../../searchContext';

const Container = styled.div``;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin: 1rem;
`;

const TableBody = () => {
  const { t } = useTranslation();
  const { state, searchCustomersLoading, searchCustomersCalled, searchCustomersError, loadMoreCustomers } =
    useCustomerSuccessSearch();

  const [infiniteRef] = useInfiniteScroll({
    loading: searchCustomersLoading,
    hasNextPage: state.hasMore,
    onLoadMore: loadMoreCustomers,
  });

  const content = useMemo(() => {
    if (!searchCustomersCalled) return <GridTable.Message>{t('customerSuccess:search.tbNotCalled')}</GridTable.Message>;
    if (searchCustomersLoading && searchCustomersCalled && state.searchResults.length === 0) {
      return <StyledLoadingIndicator />;
    }
    if (state.searchResults.length === 0 && searchCustomersError) {
      return <GridTable.ErrorMessage error={searchCustomersError} />;
    }
    return (
      <Container>
        {state.searchResults.length === 0 && (
          <GridTable.Message>{t('customerSuccess:search.tbNoResults')}</GridTable.Message>
        )}
        {state.searchResults.map((customer) => (
          <ResultRow key={JSON.stringify(customer)} customer={customer} />
        ))}
        {searchCustomersLoading && <StyledLoadingIndicator />}
        {searchCustomersError && <GridTable.ErrorMessage error={searchCustomersError} />}
        <div ref={infiniteRef} />
      </Container>
    );
  }, [searchCustomersError, searchCustomersLoading, infiniteRef, state.searchResults, t, searchCustomersCalled]);

  return content;
};

export default TableBody;
