import { useCallback, useEffect } from 'react';

const useOnClickOutside = (ref, action, eventType = 'mousedown') => {
  const onClickOutsideRef = useCallback(
    (event) => {
      if (ref.current && !ref.current.contains(event.target) && action) {
        action();
      }
    },
    [ref, action]
  );

  useEffect(() => {
    document.addEventListener(eventType, onClickOutsideRef);
    return () => {
      document.removeEventListener(eventType, onClickOutsideRef);
    };
  });
};

export default useOnClickOutside;
