import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { GET_MUSIC_PROFILES, GET_ZONES_WITH_ACTIVE_SUBSCRIPTION } from './api';

export const useUpdateMusicProfileCacheAfterCreate = () => {
  const { t } = useTranslation();

  return useCallback(
    (cache, mutationResult) => {
      const { musicProfiles } = cache.readQuery({
        query: GET_MUSIC_PROFILES,
        variables: {
          defaultBrandName: t('config:broadcasts.defaultBrandName'),
        },
      });
      const {
        data: { createMusicProfile },
      } = mutationResult;

      const data = { musicProfiles: [...musicProfiles, createMusicProfile] };

      cache.writeQuery({
        query: GET_MUSIC_PROFILES,
        variables: {
          defaultBrandName: t('config:broadcasts.defaultBrandName'),
        },
        data,
      });
    },
    [t]
  );
};

export const useUpdateMusicProfileCacheAfterUpdate = () => {
  const { t } = useTranslation();

  return useCallback(
    (cache, mutationResult) => {
      const { musicProfiles } = cache.readQuery({
        query: GET_MUSIC_PROFILES,
        variables: {
          defaultBrandName: t('config:broadcasts.defaultBrandName'),
        },
      });
      const {
        data: { editMusicProfile },
      } = mutationResult;

      const updatedMusicProfiles = musicProfiles.map((musicProfile) => {
        return Number(musicProfile.id) === Number(editMusicProfile.id) ? editMusicProfile : musicProfile;
      });
      const data = { musicProfiles: updatedMusicProfiles };

      cache.writeQuery({
        query: GET_MUSIC_PROFILES,
        variables: {
          defaultBrandName: t('config:broadcasts.defaultBrandName'),
        },
        data,
      });
    },
    [t]
  );
};

export const useUpdateMusicProfileCacheAfterDelete = ({ profileId }) => {
  const { t } = useTranslation();

  return useCallback(
    (cache) => {
      const { musicProfiles } = cache.readQuery({
        query: GET_MUSIC_PROFILES,
        variables: {
          defaultBrandName: t('config:broadcasts.defaultBrandName'),
        },
      });

      const data = { musicProfiles: musicProfiles.filter((profile) => Number(profile.id) !== Number(profileId)) };

      cache.writeQuery({
        query: GET_MUSIC_PROFILES,
        variables: {
          defaultBrandName: t('config:broadcasts.defaultBrandName'),
        },
        data,
      });
    },
    [t, profileId]
  );
};

export const updateZonesCacheAfterMutation = (cache, mutationResult) => {
  const { zonesWithAnActiveSubscription } = cache.readQuery({
    query: GET_ZONES_WITH_ACTIVE_SUBSCRIPTION,
  });

  const {
    data: { changeMusicProfileOfZones },
  } = mutationResult;

  const updatedZones = zonesWithAnActiveSubscription.map((originalZone) => {
    const mutatedOriginalZone = changeMusicProfileOfZones.find(
      (mutatedZone) => mutatedZone.myTunifyZoneId === originalZone.id
    );

    return mutatedOriginalZone
      ? {
          ...originalZone,
          ...{ musicProfile: { id: mutatedOriginalZone.musicProfileId, __typename: 'MusicProfile' } },
        }
      : originalZone;
  });

  const data = {
    zonesWithAnActiveSubscription: updatedZones,
  };

  cache.writeQuery({
    query: GET_ZONES_WITH_ACTIVE_SUBSCRIPTION,
    data,
  });
};
