export const Action = {
  SEARCH: 'search',
  NEW_SEARCH_RESULTS: 'new-search-results',
  INITIAL_QUERY_COMPLETED: 'intitial-query-completed',
  CHANGE_ORDER: 'change-order',
};

export const CUSTOMER_SEARCH_LIMIT = 10;
export const DEFAULT_SORT_VALUE = 'locationName';
export const DEFAULT_SORT_ORDER = 'ASC';

export const initialSearchState = {
  searchParams: {
    pagination: { limit: CUSTOMER_SEARCH_LIMIT, offset: 0 },
    order: [{ value: DEFAULT_SORT_VALUE, order: DEFAULT_SORT_ORDER }],
    searchPackage: {
      organization: { name: '%', vat: '%' },
      location: { name: '%', city: '%' },
      zone: { name: '%' },
      industryId: null,
      withActiveSubscriptionsOnly: true,
      contact: { name: '%', phone: '%', email: '%' },
    },
  },
  searchResults: [],
  countries: [],
  industries: [],
  hasMore: true,
};

export const searchReducer = (state, action) => {
  switch (action.type) {
    case Action.SEARCH: {
      return {
        ...state,
        searchResults: [],
        searchParams: {
          ...state.searchParams,
          searchPackage: action.payload,
          pagination: { offset: 0, limit: CUSTOMER_SEARCH_LIMIT },
          order: [{ value: DEFAULT_SORT_VALUE, order: DEFAULT_SORT_ORDER }],
        },
      };
    }
    case Action.NEW_SEARCH_RESULTS: {
      const hasMore = action.payload.length === CUSTOMER_SEARCH_LIMIT;
      const newState = {
        ...state,
        hasMore,
        searchParams: {
          ...state.searchParams,
          pagination: {
            ...state.searchParams.pagination,
            offset: state.searchParams.pagination.offset + CUSTOMER_SEARCH_LIMIT,
          },
        },
        searchResults: [...state.searchResults, ...action.payload],
      };
      return newState;
    }
    case Action.CHANGE_ORDER: {
      return {
        ...state,
        searchResults: [],
        searchParams: {
          ...state.searchParams,
          pagination: { offset: 0, limit: CUSTOMER_SEARCH_LIMIT },
          order: action.payload,
        },
      };
    }
    case Action.INITIAL_QUERY_COMPLETED: {
      const { countries, industries } = action.payload;
      return { ...state, countries, industries };
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
